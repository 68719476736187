import React from 'react'
import FAQArea from './FAQArea'

function EducationFAQ() {
    const faqData = [
        {
          "title": "Are there open positions in the education field?",
          "content": "As long as there are kids, there will be a need for people to teach them. This means job security for professionals with an education degree. In addition, public schools often benefit from teacher unions that help keep good educators in the classroom.\n\nAccording to the Bureau of Labor Statistics, elementary teachers expect to see a 4% increase in the number of jobs by 2029. The same is true for high school teachers. Those employed in post-secondary education can expect a 9% increase in demand during that time frame. Teachers are much needed, and that is a definite perk to the profession.\n\n"
        },
        {
          "title": "How can a degree in education be rewarding?",
          "content": "You Get to Help Students:\n\nAs an educator, your job is to help students succeed, both academically as well as socially and emotionally. This is highly rewarding and can help motivate you to go to work each day. Every day is different, as well, as you work with students to get through problems that impact their learning and development.\n\nYou Make a Difference:\n\nHelping students succeed means you are making a difference, not just in the lives of the students in your classroom, but also in society as a whole. Society is only as strong as its future leaders will be, and as an educator, you are growing and developing those future leaders. Every time you go to work, you are making a difference in the lives of the students you see, and that is highly rewarding.\n\nSeeing Students Learn New Skills:\n\nAs you teach, you have the chance to watch students learn and grasp new skills. Many teachers describe the “light bulb” moment that happens when a student finally grasps a difficult concept. That is a highly rewarding experience, and something you get to see on an almost daily basis as an educator.\n\nIt doesn’t matter if you are teaching pre-calc to seniors or reading to kindergarten students, that chance to see the information you are presenting finally “click” makes all the hard work of an education major worthwhile.\n\n"
        },
        {
          "title": "What are the different levels of education degrees?",
          "content": "Education degrees vary depending on the educational level and specialization. Here are some common types of education degrees:\n\n    + Associate’s Degree in Education\n    + Bachelor’s Degree in Education (B.Ed.)\n    + Master’s Degree in Education (M.Ed.)\n    + Doctorate in Education (Ed.D. or Ph.D.)\n\nEach level prepares educators for different roles within the education system, from classroom teaching to administrative and policy-making positions.\n\n"
        },
        {
          "title": "Can education degrees lead to non-teaching careers?",
          "content": "Yes, an education degree can lead to a variety of careers outside of traditional classroom teaching. Graduates can pursue roles such as:\n\n    + Educational Consultant\n    + Curriculum Developer\n    + Instructional Coordinator\n    + School Counselor\n    + Education Policy Analyst\n\nThese roles leverage the skills and knowledge gained in an education degree program to improve educational outcomes in different ways.\n\n"
        },
        {
          "title": "What skills are developed in an education degree program?",
          "content": "Education degree programs aim to equip future educators with a diverse set of skills, including:\n\n    + Lesson Planning and Curriculum Design\n    + Classroom Management\n    + Educational Psychology\n    + Assessment and Evaluation Techniques\n    + Educational Technology\n    + Special Education\n    + Literacy and Language Development\n\nThese skills are critical for effectively fostering learning and development in students of all ages.\n\n"
        },
        {
          "title": "How is technology impacting education degrees?",
          "content": "Technology is playing an increasingly significant role in education degrees, as future teachers must be prepared to integrate technology into their teaching practices. Degree programs now often include:\n\n    + Instruction on digital literacy and media.\n    + Training in educational software and classroom technology tools.\n    + Online teaching methods for remote education.\n    + The study of technology’s impact on student learning and engagement.\n\nAs technology evolves, so does the curriculum in education degree programs to prepare educators for the digital classroom.\n\n"
        }
      ]

  return (
    <>
      <FAQArea 
        title={"Commonly Asked Questions About Education Degrees"}
        faqData={faqData}
      ></FAQArea>
    </>
  )
}

export default EducationFAQ