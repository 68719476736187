import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import "./EventTile.css"
import { eventsData, openEventsData } from '../../DataProcessing/EventsData'
import Tile from '../Tile/Tile'

function EventTile({image, title, description, link, eventKey, customClass}) {

  const [localEventData, setLocalEventData] = useState()
  useEffect(()=>{
    setLocalEventData(openEventsData[eventKey])
  },[eventKey])

  return (
    <Tile
      customClass={customClass}
      image={localEventData?.image}
      title={localEventData?.title || title}
      description={localEventData?.description || description}
      link={localEventData?.link || link}
    >
    </Tile>
  )
}

export default EventTile