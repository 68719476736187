import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { combinedProgramData } from '../../Data/ProgramData'

function ProgramTile({programKey}) {

    const [programData, setProgramData] = useState({})
    useEffect(()=>{
        getProgramData()
    },[programKey])
    function getProgramData(){
        setProgramData(combinedProgramData[programKey])
    }

    return (
        <div className='programTile' key={programKey}>
            <div className='programType'>
                <img src={programData?.typImg}></img>
                <div className='programTypeText'>
                    {programData?.type}
                </div>
            </div>
            <div className='programName'>
                {programData?.name}
            </div>
            <div className='programDescription'>
                {programData?.description}
            </div>
            <Link to={"/program-detail/" + programKey}>
                <button className='programTilebutton'>More Details</button>
            </Link>
        </div>
    )
}

export default ProgramTile