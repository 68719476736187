// Videos
// video compersser https://www.veed.io/    https://www.veed.io/tools/video-compressor    https://www.veed.io/edit/eec7094a-0df2-4eab-9e9b-c9158084548a/compress?source=%2Ftools%2Fvideo-compressor
import physicsVideo from "../Images/physics_veed.mp4"
import moleculesVideo from "../Images/molecules_veed.mp4"
import musclesVideo from "../Images/muscles_webm.webm"
import wordsVideo from "../Images/words_cut2_veed.mp4"

// Tile Images
import physicsTile from "../Images/physicsTile.png"
import moleculesTile from "../Images/moleculesTile.png"
import musclesTile from "../Images/musclesTile.png"
import wordsTile from "../Images/wordsTile.png"

// Video Covers
import physicsVideoCover from "../Images/physicsVideoPoster.png"
import moleculesVideoCover from "../Images/singingMoleculesVideoCover.png"
import musclesVideoCover from "../Images/musclesVideoCover.png"
import wordsVideoCover from "../Images/wordsVideoCover.png"


export const allResearchData = {

    Konstantin: {
        title: "Singing Molecules",
        videoUrl: moleculesVideo,
        videoPoster: moleculesVideoCover,
        tilePoster: moleculesTile,
        descriptionShort: "Professor Konstantin Vodopyanov's research deciphers molecular vibrations, impacting fields from chemistry to medicine through 'molecular melodies'.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | February 7, 2022",
        content: (
            <> 
                <div className='eventsPageTitle'>The Melody of Molecules with Konstantin Vodopyanov</div>
                <h3>What is the significance of molecular vibrations?</h3>
                <p>Each molecule in our universe is in constant motion, vibrating in a unique pattern. These vibrations are not just random movements; they are like a signature tune of the molecule. By studying these vibrations, we can gain insights into the molecular structure and properties, leading to advancements in various scientific fields.</p>
                <h3>How do molecular vibrations relate to electromagnetic waves?</h3>
                <p>When molecules vibrate, they emit electromagnetic waves. These waves are essentially the 'songs' of molecules. This phenomenon is crucial in spectroscopy, where we analyze the spectrum of light absorbed and emitted by substances. It allows us to identify and understand the composition and behavior of different molecules.</p>
                <h3>Why is this concept important in the real world?</h3>
                <p>Understanding molecular vibrations and the resulting electromagnetic waves has practical applications in numerous areas, from identifying unknown substances in chemistry to developing new pharmaceuticals in medicine. It's a fundamental concept that helps us decode the molecular world, leading to innovations and discoveries that enhance our understanding of everything from basic chemistry to complex biological processes.</p>
            </>
        )
    },
    "Enrique-Del-Barco": {
        title: "Quantum Physics for the Future",
        videoUrl: physicsVideo,
        videoPoster: physicsVideoCover,
        tilePoster: physicsTile,
        descriptionShort: "Professor of Physics Enrique Del Barco’s research examines potential developments for more efficient technology that uses less energy.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | April 26, 2022",
        content: (
            <>
                <div className='eventsPageTitle'>Physics Research with Enrique Del Barco</div>
                <h3>Why are you interested in this research?</h3>
                <p>Understanding how the microscopic world functions is almost bucolic, as the laws governing this world (quantum mechanics) are absolutely unimaginable from our classical world perspective but explain the most fundamental phenomena with unnumerable repercussions in our day-to-day lives.</p>
                <h3 >Who inspires you to conduct your research?</h3>
                <p>My students. I reflect myself in my students, from high school to the Ph.D. level. They remind me of my youngest self, when I looked at the world with amusement and was looking to understand how everything works. I see this in my students’ faces when they are in the lab trying to unveil the next secret of the microscopic world.</p>
                <h3>Why is this research important?</h3>
                <p>Our research in nanoscale spintronics has strong potential to represent a breakthrough in technology. To provide an example, spintronics-based circuitry may end consuming one thousand times less energy than the most advanced electronic technology. Only this would represent a revolution, as currently energy consumption by electronic circuits (including computers) represents one of the most important expenses of energy in the world, contributing significantly to our climate change. Decreasing this by a thousand would be amazing!</p>
            </>
        )
    },
    Harmon: {
        title: "Slowing Aging With Resistance Training",
        videoUrl: musclesVideo,
        videoPoster: musclesVideoCover,
        tilePoster: musclesTile,
        descriptionShort: "Dr. Kylie Harmon's research innovates aging muscle health through strength training, aiming to improve life quality for the elderly.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | November 22, 2021",
        content: (
            <>
                <div className='eventsPageTitle'>Advancing Muscle Health with Kylie Harmon, PhD</div>
                <h3>What motivates your research on muscle aging and neuromuscular plasticity?</h3>
                <p>As we age, our muscles naturally lose strength and flexibility, impacting overall health and quality of life. My research is driven by the desire to understand and counteract these changes. By exploring how strength training influences muscle aging and neuromuscular plasticity, I aim to contribute to healthier, more active aging for everyone.</p>
                <h3>How does your research impact strength training routines?</h3>
                <p>My work involves developing targeted strength training programs that are specifically designed to slow muscle aging and enhance neuromuscular adaptability. These programs are not just for athletes but are tailored to individuals of various ages and health backgrounds, providing valuable insights for healthcare professionals in creating effective and personalized training routines.</p>
                <h3>What do you hope to achieve with your research?</h3>
                <p>The ultimate goal of my research is to revolutionize how we approach muscle health as we age. By providing evidence-based strength training methodologies, I aim to assist healthcare professionals in devising routines that not only maintain muscle strength but also improve neuromuscular responsiveness. This, in turn, can lead to prolonged independence and a better quality of life for the elderly population.</p>
            </>
        )
    },
    Sellnow: {
        title: "The Right Words At The Right Time",
        videoUrl: wordsVideo,
        videoPoster: wordsVideoCover,
        tilePoster: wordsTile,
        descriptionShort: "Professor Timothy Sellnow's research focuses on crisis communication, developing strategies to effectively manage emergencies and influence public perception during critical moments.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | January 28, 2022",
        content: (
            <>
                <div className='eventsPageTitle'>Q&A with Professor Timothy Sellnow</div>
                <h3>Why are you passionate about crisis communication?</h3>
                <p>Communication during a crisis isn't just about conveying information; it's about shaping perceptions and influencing behavior in critical moments. The right words can calm fears, provide clarity, and foster trust. My passion lies in understanding how strategic communication can mitigate the impact of crises and aid in effective management and resolution.</p>
                <h3>How does your research contribute to this field?</h3>
                <p>My research revolves around creating, testing, and refining messages to identify the most effective language during different stages of a crisis. By analyzing various communication strategies and their outcomes, we can develop guidelines that help organizations and authorities respond more effectively during emergencies, ultimately saving lives and resources.</p>
                <h3>What impact do you hope your research will have?</h3>
                <p>I aspire to see my research being applied in real-world scenarios, contributing to more efficient crisis management. By equipping communicators with evidence-based strategies, I hope to enhance the ability of organizations and governments to respond to crises with messages that not only inform but also reassure and guide the public during times of uncertainty and distress.</p>                
            </>
        )
    },
}