import React, { useState } from 'react'
import "./Components.css"
import logo from "../Images/Whalley-Text-Logo.png"
import menuIcon from "../Images/hamburger-menu-icon-w.png"
import { Link } from 'react-router-dom'
import { scrollTop } from '../Global/Functions'

function TopNav() {

  const [menuOpen, setMenuOpen] = useState()

  function closeMenu(){
    setMenuOpen(false)
  }

  return (
    <div className='topNav'>
        <Link to={"/"} title="Whalley University Home" onClick={scrollTop}>
            <div className='logo'>
              <img src={logo}></img>
          </div>
        </Link>
        <div className='topNavButtons'>
            <Link to={"/"}>Home</Link>
            <Link to={"/programs"}>Programs</Link>
            <Link to={"/events"} onClick={closeMenu}>Events</Link>
          <Link to={"/student-portal"} onClick={closeMenu}>Student Portal</Link>
            <Link to={"/apply"}>Apply Now</Link>
            <Link to={"/contact"}>Contact</Link>
        </div>
        <div className='topNavButtonsMenu' onClick={()=>setMenuOpen(!menuOpen)}>
          <img src={menuIcon} title="Menu"></img>
        </div>
        <div className={'topNavButtonsMenuButtons '+(menuOpen ? " topNavButtonsMenuButtonsOpen":"")}>
          <Link to={"/"} onClick={closeMenu}>Home</Link>
          <Link to={"/programs"} onClick={closeMenu}>Programs</Link>
          <Link to={"/events"} onClick={closeMenu}>Events</Link>
          <Link to={"/student-portal"} onClick={closeMenu}>Student Portal</Link>
          <Link to={"/apply"} onClick={closeMenu}>Apply Now</Link>
          <Link to={"/contact"} onClick={closeMenu}>Contact</Link>
        </div>
    </div>
  )
}

export default TopNav