import React, { useRef, useState } from 'react'
import { coursesString } from './coursesRaw'
import { json } from 'react-router-dom'
import "./DataProcessing.css"
import { programStringRaw } from './programsStringRaw'
// import { businessBAProgramTiles, itBAProgramTiles } from './CourseTiles'
import { programsJsonExported } from './programsJson'
// Assume the program arrays are imported or defined above this function
import { checkAllProgramsObject } from './CourseTiles';
// import { businessBAProgramTiles, businessMAProgramTiles, businessCEProgramTiles, itBAProgramTiles, itMAProgramTiles, itCEProgramTiles, healthcareBAProgramTiles, healthcareMAProgramTiles, healthcareCEProgramTiles, educationBAProgramTiles, educationMAProgramTiles, combinedProgramData, allCoursesObject, allDescriptions, allDescriptionsCheck } from '../Data/ProgramData';
import { combineAllProgramDataGenerated, combineProgramDataGenerated, programDataNamesGenerated } from '../Data/ScratchPad'
import CollapasableRow from '../Components/CollapasableRow'
import { coursesData, coursesData1, coursesData2, coursesData3, coursesData4, coursesData5, coursesData7 } from './AllCoursesTemp'
// import { coursesData, coursesData1, coursesData2, coursesData3, coursesData4 } from './AllCoursesTemp'
import * as allCoursesObjects from "./AllCoursesTemp"

function Parser() {


    const [stringifiedJson, setStringifiedJson] = useState()
    const [stringifiedJson2, setStringifiedJson2] = useState()

    // ================================================================================
    // #region

//     // Parse the courses string into a json. Format courseNumber - course name - description (can be multiple lines)
//     function parseCoursesString(){
//         // Split by newline
//         let splitCoursesString = coursesString.split("\n")

//         // The objec that will hold all of the courses
//         let coursesObject = {}
//         // The variables that will hold the cululative data that will be stored in the object when a new course is detected 
//         let courseNumber = ""
//         let courseName = ""
//         let courseDescription = ""

//         let c = 0
//         splitCoursesString.forEach(string => {
//             // let string = splitCoursesString[c]
//             // If the string has -s it is courseNumber - courseName - courseDescription start
//             if(string.includes(" - ")){
                
//                 // Get the parts of the string that may be for the new course
//                 let splitString = string.split(" - ")

//                 // Checks to make sure this is really a new course (course numbers are only 4 characters, if its larger than 5 it is not a new course but just a - in the description)
//                 if(splitString[0].length > 5){
//                     // If it is not a new course it is part of the description for the current course
//                     courseDescription += " " + string

//                 }
//                 // If it is a new course save it and start on the next one
//                 else{
//                     // Save the course data variables in the courses object and reset the variables
//                     coursesObject[courseNumber] = {
//                         name: courseName,
//                         description: courseDescription,
//                     }
//                     courseNumber = ""
//                     courseName = ""
//                     courseDescription = ""
    
    
//                     // Get the new course number, new course name, and first part of the description
//                     courseNumber = splitString[0]
//                     courseName = splitString[1]
//                     courseDescription = splitString[2]

//                 }

//             }
//             // If there are no ' - 's in the string it is part of the course description so add it
//             else{
//                 courseDescription += " "+string
//             }

//         })

//         // console.log("coursesObject: ")
//         // console.log(coursesObject)
//         // console.log("number of courses: "+Object.entries(coursesObject).length)

//         // Put the json in state as a string to display for copt and paste
//         setStringifiedJson(JSON.stringify(coursesObject, undefined, 2))
//     }
//     /*
//         if there is a word with 4 letters at the start of the split by \n string or
//         CCN Course Number Course Description CUs Term
//         it is part of a table

//         the ccn is a course describer like an id
//         the courseNumber corresponds with the courses JSON
//         description is a very short description
//         CUs is the number of credits
//         Term is the term the student is expected to take the course for that program

//         put the CCN, short description, and CUs in the coursesJson
//         put the courseNumber and term in the program object
//         along with program name and description

//         so go through each line and if it is part of a table (and not the title row) add those lines to the object
//         else there is a program name and description to get
//         and when a new one is detected put that data into the object and restart

//         program names appear to always be only one line

//         ctrl +h for © Western Governors University 9/27/23 ... (with use regular expressions on)




//         This currently partially works to parse programCoursesCumulative which is needed to add to the coursesJson
//             the CUs and term are showing as part of the description though
//         but the program parsing does not currently work

//         There are course names at the bottom of pages ex: BSCCMCL 202207
//         BSCCMCL 202207

//     */
//     let programsJson = {}
//     let programName = ""
//     let programDescription = ""
//     // courseNumber: { CCN, shortDescription, CUs, Term} is accumulated then reset for each program
//     let programCourses = {

//     }
//     // Has all of the courses found in all of the programs
//     let programCoursesCumulative = {

//     }
    
//     let justParsedRow = true
//     function parseProgramsString(){
//         // Look at each line, figure out what it is, and add it to objects accordingly
//         let splitProgramStrings = programStringRaw.split("\n")

//         splitProgramStrings.forEach(string => {

//             // console.log(string.toUpperCase() === string)
//             // This is a table title string, can be severl for one table becasue of page breaks, no need to include it in the resulting json
//             if(string === "CCN Course Number Course Description CUs Term"){
//                 justParsedRow = true

//             }
//             else if (stringIsRow(string)){
//                 let rowData = rowStringData(string)

//             // If this line is in a table get the row data and add it to the object (row items have a CCN with 4 characters as the first thing)
//             // else if(string.split(" ")[0].length === 4 || string.split(" ")[0].length === 3){
//             // else if(string.toUpperCase() === string){
//                 // Flag to show just parsed a row, if 
//                 justParsedRow = true

//                 // let courseRow = string.split(" ")
//                 // let CCN = courseRow[0] + " " + courseRow[1]
//                 // let courseNumber = courseRow[2]
//                 // let shortCourseDescription = ""
//                 // // Default to 3 after testing
//                 // let CUs
//                 // // Set a default after testing
//                 // let term

//                 // // There may be several substrings that are part of the short description, if they are not a number they are part of the description
//                 // let descriptionEnded = false
//                 // let gotCUs = false
//                 // courseRow.forEach((substring, index) => {
//                 //     // These indicies are known and obtained
//                 //     if(index < 3) return

//                 //     // If this is part of the description add it
//                 //     if(descriptionEnded || Number.isNaN(Number.parseInt(substring))){
//                 //         shortCourseDescription += " "+substring
//                 //     }
//                 //     // If it is not part of the description get the CUs then the term
//                 //     else{
//                 //         // The first one is CUs, the second is program term
//                 //         // descriptionEnded = true
//                 //         if(!gotCUs){
//                 //             CUs = Number.parseInt(substring)
//                 //             gotCUs = true
//                 //         }else{
//                 //             term = Number.parseInt(substring)
//                 //         }
//                 //     }

//                 // })
//                 // Add the row to the program courses object
//                 programCourses[rowData.courseNumber] = rowData
//                 // {
//                 //     CCN: CCN,
//                 //     shortCourseDescription: shortCourseDescription,
//                 //     CUs: CUs,
//                 //     term: term,
//                 // }
//                 programCoursesCumulative[rowData.courseNumber] = rowData
//                 // {
//                 //     CCN: CCN,
//                 //     shortCourseDescription: shortCourseDescription,
//                 //     CUs: CUs,
//                 //     // term: term,
//                 // }
//             }
//             // If it is not one of those it is a program name or description 
//             else{
//                 // If this is right after a row it is the title, also save the data for the program
//                 if(justParsedRow){
//                     if(programName.length > 0)
//                         // Save the current data
//                         programsJson[programName] = {
//                             programName: programName,
//                             programDescription: programDescription,
//                             programCourses: programCourses,

//                         }

//                     // Reset the data
//                     programName = ""
//                     programDescription = ""
//                     programCourses = {}

//                     // Start collecting new data
//                     programName = string

//                 }
//                 // Else its part of the description
//                 else{
//                     programDescription += " " + string
//                 }
//                 justParsedRow = false
//             }
//         })

//         console.log("programsJson")
//         console.log(programsJson)
//         console.log("programCoursesCumulative")
//         console.log(programCoursesCumulative)
//         setStringifiedJson(JSON.stringify(programCoursesCumulative, undefined, 2))
//         // setStringifiedJson(JSON.stringify(programsJson, undefined, 2))
//     }

//     let justGotDescription = true
//     let programID = ""
//     let first = true




function stringIsRow(string){
    let substrings = string.split(" ")
    if(substrings[0].toUpperCase() === substrings[0]){
        console.log("row string: "+string)
        return true
    }
    else
        return false
}



//     /*
    
//         in courses
//         English Language Learning – M.A.

//         in json
//         Master of Arts, English Language Learning (PreK-12)

//         want each program from the tiles in the programs page to link to a program in the programsJson
//         so the courses can be displayed

//         it would be wayy to much to copy each one by hand

//         this is somewhat easier, maybe will get the conversions by hand, need to make sure the data is valid and useable before sinking that much time
//         can use a few programsJson objects as samplet data
//         maybe make a couple conversions by hand and use them to see if that is even what the best thing to do is

//     */
//     function nameConversion(){
//         // businessBAProgramTiles.forEach(tile => {
//         // itBAProgramTiles.forEach(tile => {

//         //     // return
//         //     let name = tile?.name
        
//         //     name = name.replaceAll(" – B.S.","")
//         //     name = name.replaceAll("– B.S. Business Administration", "")
//         //     name = name.replaceAll("Business Administration", "")
//         //     name = name.replaceAll("IT", "Information Technology")
//         //     console.log("searching for: "+name)

//         //     let nameComponents = name.split(" ")
//         //     if(name.includes("B.S."))
//         //         nameComponents.push("Bachelor of Science")
//         //     if(name.includes("M.S."))
//         //         nameComponents.push("Master of Science")
//         //     if(name.includes("IT"))
//         //         nameComponents.push("Information Technology")

//         //     /*
//         //          might have to split the words in the tiles name and find the programsJson item that has the highest number of matching words for a best guess
//         //         once the names are linked any tile name will have access to all of the data associated with the programsJson object
//         //         including all of the courses and the description

//         //         the description can be the top of the program more info page
//         //         the courses will be shown in the program more info page with a short course description and an expandable section with a longer course description 

//         //         present on page 49 of wgu pdf, not in programsJson, so some are not included.. maybe not parsing correctly
//         //         Bachelor of Science Business Administration, Accounting

//         //     */


//         //     let c = 0
//         //     let highestMatchCount = 0
//         //     let bestGuess = "none found"
//         //     Object.entries(programsJsonExported).forEach(([programKey, programJson]) => {
//         //         if(typeof programJson?.name !== "string") return

//         //         let matchCount = 0
//         //         let programNameComponents = programJson.name.split(" ")
//         //         programNameComponents.forEach(programMameComonent => {
//         //             nameComponents.forEach(tileNameComponent => {
//         //                 if(tileNameComponent === programMameComonent){
//         //                     matchCount ++
//         //                 }
//         //             })
//         //         })
//         //         if(matchCount > highestMatchCount){
//         //             bestGuess = programJson.name
//         //             highestMatchCount = matchCount
//         //         }


    
//         //         // if(typeof programJson?.name === "string" && programJson?.name.includes(name)){
//         //         //     console.log("found match: "+programJson.name)
//         //         //     foundMatch = true
//         //         // }
//         //         // c++
//         //     })
//         //     console.log("buest guess: "+bestGuess+" highestMatchCount: "+highestMatchCount)

//         //     // if(!foundMatch)
//         //     // console.log("searched "+c+" entries, no match found")
//         // })
//     }



//     function checkAllPrograms(){
    
//         // checkAllProgramsObject(businessBAProgramTiles, "businessBAProgramTiles")
//         // checkAllProgramsObject(businessMAProgramTiles, "businessMAProgramTiles")
//         // checkAllProgramsObject(educationBAProgramTiles, "educationBAProgramTiles")
//         // checkAllProgramsObject(educationMAProgramTiles, "educationMAProgramTiles")
//     }

//     function createAllProgramTypes(){
//         let allProgramsObject = compileAndMergeProgramData()
//         setStringifiedJson(JSON.stringify(allProgramsObject, undefined, 2))
//     }

//     // Function to generate a unique key from the program name
// const generateKeyFromName = (name) => name.replace(/\s+/g, '-').toLowerCase();

// // Function to merge program types for duplicated program entries
// const mergeProgramTypes = (programs) => {
//   const merged = {};
//   Object.keys(programs).forEach(key => {
//     if (!merged[key]) {
//       merged[key] = programs[key];
//     } else {
//       merged[key].programTypes = Array.from(new Set([...merged[key].programTypes, ...programs[key].programTypes]));
//     }
//   });
//   return merged;
// };

// // Function to compile and merge all program data
// const compileAndMergeProgramData = () => {
//   const allProgramTiles = [
//     // ...businessBAProgramTiles,
//     // ...businessMAProgramTiles,
//     // ...businessCEProgramTiles,
//     // ...itBAProgramTiles,
//     // ...itMAProgramTiles,
//     // ...itCEProgramTiles,
//     // ...healthcareBAProgramTiles,
//     // ...healthcareMAProgramTiles,
//     // ...healthcareCEProgramTiles,
//     // ...educationBAProgramTiles,
//     // ...educationMAProgramTiles
//   ];

//   const allProgramsCompiled = allProgramTiles.reduce((accumulator, program) => {
//     const key = generateKeyFromName(program.name);
//     const programType = program.type.trim().toLowerCase().replace(/\s+/g, '-');

//     if (!accumulator[key]) {
//       accumulator[key] = {
//         ...program,
//         degreeLevel: program.name.includes('M.S.') || program.name.includes('MBA') || program.name.includes('M.A.') || program.name.includes('M.Ed.') ? 'MA' : program.name.includes('B.S.') || program.name.includes('B.A.') ? 'BA' : 'Certificate',
//         programTypes: [programType],
//       };
//     } else {
//       if (!accumulator[key].programTypes.includes(programType)) {
//         accumulator[key].programTypes.push(programType);
//       }
//     }

//     return accumulator;
//   }, {});

//   return mergeProgramTypes(allProgramsCompiled);
// };


// function addProgramDataToObject(){
//     // let combinedData = {}
//     // let res1 = combineProgramDataGenerated(combinedData, businessBAProgramTiles, "bachelors", "business")
//     // let res2 = combineProgramDataGenerated(res1, itBAProgramTiles, "bachelors", "it")

//     // let res3 = combineProgramDataGenerated(res2, itBAProgramTiles, "masters", "it")
//     // console.log("res3")
//     // console.log(res3)
//     // displayJson(res3)
// }





//     function combileProgramArrays(){
//         // let returnObject = combineAllProgramDataGenerated([ businessBAProgramTiles, businessMAProgramTiles, businessCEProgramTiles, 
//         //     itBAProgramTiles, itMAProgramTiles, itCEProgramTiles, 
//         //     healthcareBAProgramTiles, healthcareMAProgramTiles, healthcareCEProgramTiles, 
//         //     educationBAProgramTiles, educationMAProgramTiles])
//         // displayJson(returnObject)
//     }

//     function arrayToKeys(){
//         // let programDataNamesGeneratedResult = programDataNamesGenerated(educationMAProgramTiles, "business")
//         // displayJson(programDataNamesGeneratedResult)

//         // programDataNamesGeneratedResult.forEach(key => {
//         //     console.log("key "+key+" data")
//         //     console.log(combinedProgramData[key])
//         // })
//     }

    



//     function combineCoursesObjects(){
//         // let resultObject = {}
  
//         // // return
//         // let arrayOfObjects = [allCoursesObject, ...Object.values(allCoursesObjects)]
//         // arrayOfObjects.forEach(object => {
//         //     Object.entries(object).forEach(([key, object]) => {
//         //         // if ther eis an object at theat key with a description keep that one
//         //         if(resultObject[key]?.description) return
//         //         // else set the object at that key to the new object 
//         //         resultObject[key] = object
//         //     })
//         // })
//         // displayJson(resultObject)
//     }
//     function numberOfCourses(){
//         // console.log(Object.entries(allCoursesObject).length)
//     }

//     function courseDescriptionParser(){
//         let textToParse = getInputAreaValue()
//         let strings = textToParse.split("\n")
//         let checkedStrings = []
//         // Make an array of strings with each one representing a course, its descriptoin and its name
//         strings.forEach(string => {
//             // This is a footnote, not part of the data
//             if(string.includes("©")) return

//             // If this is the first line push it to the array
//             if(isCourseDescriptionFirstLine(string))
//                 checkedStrings.push(string)
//             // Else add it to the previous string becasue it is a part of it
//             else
//                 checkedStrings[checkedStrings.length - 1] = checkedStrings[checkedStrings.length - 1] +" " + string
        
//         }) 

//         // Generate an object with the course ID as a key and the description as an attribute
//         let coursesDescriptionObject = {}
//         checkedStrings.forEach(string => {
//             // Split the string up into substrings to extract the parts
//             let substrings = string.split(" ")

//             // Get the course ID
//             let courseID = substrings.splice(0, 1)

//             // Extract the course name
//             let dashCount = 0
//             let courseName = ""
//             let counter = 0
//             while(dashCount < 2 && counter < 20){
//                 let substring = substrings[counter]
//                 if(substring === "-"){
//                     // remove it and increment the dash count (there is one dash, then the name which can be many substrings, then a second dash)
//                     substrings.splice(0, 1)
//                     dashCount++
//                 }
//                 else{
//                     // remove it and add the substring to the course name
//                     substrings.splice(0, 1)
//                     courseName += substring + " "
//                     if(courseName.length > 200){
//                         console.log("==================")
//                         console.log("Course Name Error")
//                         return
//                     }
//                 }

//             }
        

//             // The remainder of the substrings will be the course description
//             let courseDescription = ""
//             substrings.forEach(subString => {
//                 courseDescription += subString + " "
//             });
//             coursesDescriptionObject[courseID] = {
//                 courseName: courseName,
//                 description: courseDescription 
//             }
//         })

//         displayJson(coursesDescriptionObject)
//     }
//     function isCourseDescriptionFirstLine(string){
//         let substrings = string.split(" ")
//         if(substringCount(substrings, "-") < 2)
//             return false
//         if(!isUpperCase(substrings[0]))
//             return false
//         return true

//     }

    // function parseProgramsString2(){
    //     let justParsedRow = false
    //     let first = false
    //     let programName = ""
    //     let programDescription = ""
    //     let programCourses = {}
    //     let programsJson = {}
    //     let programCoursesCumulative = {}
    //     let programID = ""

    //     let programStringRaw = getInputAreaValue()

    //     // Look at each line, figure out what it is, and add it to objects accordingly
    //     let splitProgramStrings = programStringRaw.split("\n")

    //     splitProgramStrings.forEach((string, index) => {
    //         // Get the string type (row, programID, or text (name or description part))
    //         let stringType = stringTypeFunction(string)

    //         // If it is a text type it is either a name or description part
    //         if(stringType === "text"){
    //             // If got a row this is the next text line, so a program name 
    //             if(justParsedRow){
    //                 // If its not the first time through save the data and reset the variables
    //                 if(!first){
    //                 // if(true){
    //                     console.log("saving: ")
    //                     console.log({
    //                         name: programName,
    //                         description: programDescription,
    //                         courses: programCourses,
    //                     })
    //                     // Save 
    //                     programsJson[programID] = {
    //                         name: programName,
    //                         description: programDescription,
    //                         courses: programCourses,
    //                     }

    //                     // Reset
    //                     programName = ""
    //                     programDescription = ""
    //                     programCourses = {}
    //                 }
                    
    //                 programName = string

    //             }
    //             // Else its part of the description
    //             else{
    //                 programDescription += " "+string
    //             }
    //             justParsedRow = false
    //         }
    //         // If this is a row get the data and add it to the appropriate objects
    //         if(stringType === "row"){
    //             let rowData = rowStringData(string)
    //             programCourses[rowData.courseNumber] = rowData
    //             programCoursesCumulative[rowData.courseNumber] = rowData
    //             justParsedRow = true
    //         }
    //         if(stringType === "programID"){
    //             programID = string
                
    //         }

    //         first = false

    //         // If this is the last string save
    //         if(index === (splitProgramStrings.length - 1)){
    //             // Save 
    //             programsJson[programID] = {
    //                 name: programName,
    //                 description: programDescription,
    //                 courses: programCourses,
    //             }
    //         }
            

    //     })

    //     setStringifiedJson(JSON.stringify(programCoursesCumulative, undefined, 2))
    //     // setStringifiedJson(JSON.stringify(programsJson, undefined, 2))
    // }

    /*
        if there are >5 lines and the second substring can be parsed into a number it is a row
        ex: EDUC 2215 D093 Assessing Impact on Student Learning 3 5
        
        if there are 2 substrings and the second is a number it is the program ID
        ex: BSSEMG 202007

        else it is a title or description

        row can have multiple lines. ex:
        BUS 2061 D079 Business Environment Applications II: Process, Logistics, and
        Operations
        2 5

        parse with an ai tool... duh

    */
    // function stringTypeFunction(string){
    //     if(string === "CCN Course Number Course Description CUs Term") return "row title"
    //     if(string.includes("Total CUs")) return "total CUs line"

    //     let substrings = string.split(" ")
    //     if(stringIsANumber(substrings[1])){
    //         if(substrings.length > 5)
    //             return "row"
    //         else if(substrings[0].length > 2)
    //             return "programID"
    //     }
    //     return "text"
    // }
    
    // function rowStringData(string){
    //     let returnData = {
    //         description: "",
    //     }

    //     let substrings = string.split(" ")
    //     returnData.CCN = substrings[0] + " " + substrings [1]
    //     returnData.courseNumber = substrings[2]
    //     let c = 3
    //     while(c < substrings.length && Number.isNaN(Number.parseInt(substrings[c]))){
    //         returnData.description += " "+substrings[c]
    //         c++
    //     }
    //     if(c < substrings.length){
    //         returnData.CUs = Number.parseInt(substrings[c - 1])
    //         returnData.term = Number.parseInt(substrings[c])
    //     }

    //     return returnData
         
    // }
    // #endregion

    // ================================================================================
    // #region create an object with a program's courses based on text
   

    function parseCoursesTextRows(){

        let inputAreaValue = getInputAreaValue()

        let strings = inputAreaValue.split("\n")

        // If any of thie strings have less than 5 " " seperated items it is part of the previsous string
        let checkedStrings = []
        strings.forEach(string => {
            let subStrings = string.split(" ")
            // If the string has less than 5 " " seperated items add it to the previous string
            if(subStrings.length < 5){
                checkedStrings[checkedStrings.length - 1] = checkedStrings[checkedStrings.length - 1] + " " + string 
            }
            // Else it is an entire data row so push it to the checkedStrings array
            else
                checkedStrings.push(string)

        })

        let programCoursesObject = {}
        let coursesObject = {}
        checkedStrings.forEach(string => {

            // For non-data rows
            if(string.includes("©")) return
            if(string.includes("Course Number")) return

            // Example: ITSW 3151 D480 Software Design and Quality Assurance 3 10
            let splitString = string.split(" ")
        
            // CCN is the first 2 items
            let CCN = splitString.splice(0, 1)
            CCN += " "+splitString.splice(0, 1)
            // console.log("splitString after CCN")
            // console.log(splitString)


            // Course ID is the next one (first index because the CCN was removed)
            let courseID = ""+splitString.splice(0, 1)
        
            // Term is the last index
            let term = splitString.splice(splitString.length - 1, 1)
        
            // Term is the second to last index (and term was removed so not it is the last index)
            let CUs = ""+splitString.splice(splitString.length - 1, 1)

            // All of the remaining substrings are part of the course name 
            let courseName = ""
            splitString.forEach(substring => {
                courseName += substring + " "
            })


            // Compile the object for this course
            let courseObject = {
                courseID: courseID, 
                CCN: CCN,
                name: courseName,
                CUs: CUs,
            }

            // Put it in the courses object
            coursesObject[courseID] = courseObject 

            // Add this course to the program object
            if(!programCoursesObject["term"+term])
                programCoursesObject["term"+term] = []
            programCoursesObject["term"+term].push(courseID)

        })

        // Put the course object and programCoursesObject into the output areas
        setStringifiedJson(`courses: `+JSON.stringify(programCoursesObject, undefined, 2))
        setStringifiedJson2("export const coursesData = "+JSON.stringify(coursesObject, undefined, 2).toString())

    }

    // #endregion parse Parse an object of programs based on text

    // ================================================================================
    // #region Combination functions

    function combineCoursesAndDescriptions(){
        
        // let compiledObject = {}
        // // Go through the allCoursesObject, if there is a description use it as it is, if not look for the descriptoin in the all descriptions object 
        // Object.entries(allCoursesObject).forEach(([key, object]) => {
        //     // if the object is there and has a description leave it as it is
        //     if(compiledObject[key] && compiledObject[key].description){

        //     }
        //     // else add it
        //     else{
        //         compiledObject[key] = {...object}
        //     }
        // })

        // // Look through the all descriptions object. If the course is not present in the compiled object add it
        // Object.entries(allDescriptions).forEach(([key, object]) => {
        //     // If its there with a description leave it along
        //     if(compiledObject[key]?.description){
                
        //     }
        //     // If its there with no description add the description
        //     else if(compiledObject[key]){
        //         compiledObject[key].description = object.description
        //     }
        //     // if its not there at all add it
        //     else{
        //         compiledObject[key] = {...object}
        //     }
            
        // })

        // console.log("Comiled a courses object with descriptions with "+Object.entries(compiledObject).length+" items")

        // // Display the object to be saved
        // displayJson(compiledObject)

    }

    // #endregion Combination functions

    // ================================================================================
    // #region Check Functions
    
    function courseDescriptionsLength(){
        // console.log(Object.entries(allDescriptionsCheck).length)
    }

    // #endregion Check Functions

    // ================================================================================
    // #region General Helper Functions

    function substringCount(array, checkString){
        let count = 0
        array.forEach(item => {
            if(item === checkString)
                count++
        })
        return count
    }    
    function isUpperCase(str) {
        return str === str.toUpperCase();
    }
    function displayJson(json){
        setStringifiedJson(JSON.stringify(json, undefined, 2))
    }
    function displayJson2(json){
        setStringifiedJson2(JSON.stringify(json, undefined, 2))
    }
    function getInputAreaValue(){
        let inputArea = document.getElementById("parserTextArea")
        if(!inputArea) return "Input Area Not Found"
        let value = inputArea.value
        return value
    }
    function copyToClipboard(elementID){
        let textArea = document.getElementById(elementID)
        if(!textArea) return
        textArea.focus();
        textArea.select();

        try{
            var successful = document.execCommand('copy')
            if(!successful){
                // console.log("copy error")
            }
        }catch{
            // console.log("copy error catch")
        }

    }

    // #endregion General Helper Functions

  return (
    <div className='page'>
        <div className='pageInnerWidth'>
            <button onClick={parseCoursesTextRows}>Parse</button>
            {/* <button onClick={combineCoursesAndDescriptions}>Parse</button> */}
            {/* <button onClick={checkAllPrograms}>Check</button> */}
            <CollapasableRow title="Input Area">
                <textarea id={"parserTextArea"} className='parserTextArea'></textarea>
            </CollapasableRow>
            <CollapasableRow title="Output 1">
                <button onClick={()=>copyToClipboard("output1")}>Copy</button>
                <div className='jsonStringArea'>
                    {/* <pre id='output1'>
                        {stringifiedJson}
                    </pre> */}
                    <textarea value={stringifiedJson} id='output1' className='fullHW'></textarea>
                </div>
            </CollapasableRow>
            <CollapasableRow title="Output 2">
                <button onClick={()=>copyToClipboard("output2")}>Copy</button>
                <div className='jsonStringArea'>
                    <textarea value={stringifiedJson2} id='output2' className='fullHW'></textarea>
                </div>
            </CollapasableRow>
        </div>
    </div>
  )
}




export default Parser