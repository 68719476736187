import React, { useState } from 'react'
import "./InputForm.css"

/*
    inputs is an object containing data that determines what king of inputs are shown
    submitAction recieves an object with all of the input values

    ex:
    inputs = [
        {
            label: "Name",
            key: "name",
            type: "text",
            width: "full",
            required: true,
        },
        ...
    ]
    
*/
function InputForm({title, inputs, submitAction, showOnSubmitElement, customFormBoxClass}) {

    const [message, setMessage] = useState()
    const [submitted, setSubmitted] = useState()

    function submit(){
        // gather the input vlues based on the object
        let inputValuesObject = {}
        let inputAlertName
        let inputAlert
        inputs.forEach(inputObject => {
            
            let key = inputObject.key
            let inputValue = document.getElementById("input"+key)?.value
            if(inputValue && inputValue !== "")
                inputValuesObject[key] = inputValue
            else if(inputObject.required)
                if(inputObject.inputAlert)
                    inputAlert = inputObject.inputAlert
                else
                    inputAlertName = inputObject.label 
        });

        if(inputAlert)
            setMessage(inputAlert)
        else if(inputAlertName)
            setMessage("Please check "+inputAlertName+" input.")
        else{
            // Send object with input values to submitAction 
            submitAction(inputValuesObject)
            setSubmitted(true)
        }
    }

    function inputDisplay(inputObject){
        if(inputObject.type === "textarea")
            return(
                // <textarea placeholder={inputObject.label} id={'input'+inputObject.key} className={'input'+inputObject.key} ></textarea>
                <textarea placeholder={inputObject.label} id={'input'+inputObject.key} className={inputObject?.class || "inputFull"} key={'input'+inputObject.key} ></textarea>
            )
        else if(inputObject.type === "select")
            return(
                // <select id={'input'+inputObject.key} className={'input'+inputObject.key}>
                <select id={'input'+inputObject.key} className={inputObject?.class || "inputFull"} key={'input'+inputObject.key}>
                    {inputObject?.options?.map((optionData, index) => (
                        <>
                            {typeof(optionData === "string") ?
                                <option key={'option'+optionData + index} className={'option'+optionData + index}> 
                                    {optionData} 
                                </option>
                                :
                                <option  key={'option'+optionData.value + index} value={optionData.value} className={'option'+optionData + index}> 
                                    {optionData.label} 
                                </option>
                            }
                        </>
                    ))}
                </select>
            )
        else
            return(
                // <input placeholder={inputObject.label} id={'input'+inputObject.key} className={'input'+inputObject.key}></input>
                <input placeholder={inputObject.label} id={'input'+inputObject.key} className={inputObject?.class || "inputFull"} key={'input'+inputObject.key}></input>
            )
    }

  return (
        <>
            {submitted? 
                <div className={'formBox '+customFormBoxClass}>
                    {showOnSubmitElement}
                </div>
                :    
                <div className='formBox'>
                    {title && <div className='formBoxTitle'>{title}</div>}
                    {inputs.map((inputObject, index) => (
                        // <div className='inlineBlock'>{inputDisplay(inputObject)}</div>
                        // <span key={"input"+inputObject.key+index+Math.random()}>{inputDisplay(inputObject)}</span>
                        <>{inputDisplay(inputObject)}</>
                    ))}
                    {message && 
                        <div className='errorMessage'>
                            {message}
                        </div>
                    }
                    <div className='bottomButtons formSubmitButtons'>
                        <button>Clear</button>
                        <button onClickCapture={submit}>Submit</button>
                    </div>
                </div>
            }
        </>
  )
}

InputForm.defaultProps = {
    // title: "Message Form",
    submitAction: (inputData => {console.log("Input form data:"); console.log(inputData);}),
    showOnSubmitElement: (
        <div className='formSubmittedBoxContainer'>
            <div className='formSubmittedBox'>
                Form Submitted!
            </div>
        </div>
    ),
    inputs: [
        {
            label: "Name",
            key: "name",
            type: "text", // Default
            class: "inputFull", // Default
            required: true,
        },
        {
            label: "Phone",
            key: "phone",
            type: "text",
            // The width of the input element
            class: "inputHalf",
            // Submit will not work and error message will show if this is rquired and user does no provide an input
            required: true,
            // Custom message that will show if the input does not meet requirements
            inputAlert: "Please check phone input. (minimum 7 digits)"
        },
        {
            label: "Email",
            key: "email",
            type: "text",
            class: "inputHalf",
            required: true,
        },
        {
            label: "Select",
            key: "selectExample",
            type: "select",
            class: "inputFull",
            required: true,
            options: [
                {
                    value: "option1",
                    label: "Option 1"
                },
                {
                    value: "option2",
                    label: "Option 2"
                },
            ]
        },
        {
            label: "Message",
            key: "message",
            type: "textarea",
            class: "inputFull",
            required: true,
        },
    ],
    
}

export default InputForm