import { Link } from 'react-router-dom'
import React from 'react'
import "./Tile.css"

function WideTile({image, title, title2, description, link, customClass}) {

  return (
    <div className={'wideTile '+(customClass ? customClass : "")}>
        <img src={image} className='wideTileImage'></img>
        <div className='wideTileText'>
            {title && <div className='wideTileTitle'>{title}</div>}
            {title2 && <div className='tileTitle2'>{title}</div>}
            <pre>
                {description}
            </pre>
        </div>
        {link &&
            <Link to={link}>
                <button className='wideTileReadMoreButton'>Read More</button>
            </Link>
        }
    </div>
  )
}

export default WideTile