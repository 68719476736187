import businessIcon from "../Images/businessIcon.png"
import healthcareIcon from "../Images/healthcareIcon.png"
import itIcon from "../Images/itIcon.png"
import educationIcon from "../Images/educationIcon.png"

    // ================================================================================
    // #region seperated program tile arrays (depreciated but still used)

    // #endregion seperated program tile arrays (depreciated)

    // All of the programs in an object with procedurally generated keys based on program type and name
    export const combinedProgramData = {
        "B-BMBSBA": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Business Administration Management – B.S.",
            "description": "This finance program is career-focused so you will gain relevant...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Master Business Essentials with a B.S. in Business Management",
                "description": `The B.S. in Business Management offers a comprehensive foundation in key business principles, including finance, marketing, operations, and leadership. Designed with career focus in mind, it equips students with practical skills relevant to the business world.\n\n    Learn to analyze business problems, develop strategies, and lead teams effectively. The curriculum combines theoretical knowledge with real-world application, preparing you for diverse roles in business management.\n\n    Graduates will be ready to take on challenges in various business settings, equipped with the knowledge and skills to drive organizational success. Ideal for aspiring business professionals seeking a versatile and dynamic career in business management.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "C716",
                  "C458"
                ],
                "term2": [
                  "D351",
                  "C483",
                  "D270",
                  "D078"
                ],
                "term3": [
                  "D082",
                  "D265",
                  "D196",
                  "D268"
                ],
                "term4": [
                  "D081",
                  "D076",
                  "C273",
                  "D352"
                ],
                "term5": [
                  "QHT1",
                  "C955",
                  "D354",
                  "D355"
                ],
                "term6": [
                  "D253",
                  "C957",
                  "D077",
                  "D353"
                ],
                "term7": [
                  "D099",
                  "D266",
                  "D089",
                  "C717"
                ],
                "term8": [
                  "C182",
                  "D079",
                  "C720",
                  "C165"
                ],
                "term9": [
                  "C723",
                  "D080",
                  "D361",
                  "C721"
                ],
                "term10": [
                  "C722",
                  "D333",
                  "QGT1"
                ]
              }
        },
        "B-HRMBSBA": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Human Resource Management – B.S. Business Administration",
            "description": "A rewarding business degree program...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Lead Organizational Success with a B.S. in Human Resource Management",
                "description": `The B.S. in Human Resource Management focuses on developing skills essential for managing a company's most valuable asset – its people. Learn about strategic HR planning, recruitment, training, compensation, and employment law.\n\n    Gain insights into organizational behavior, employee relations, and performance management. The program integrates HR theory with practical applications, preparing you to manage and lead teams effectively.\n\n    Graduates are ready for roles in HR management, talent acquisition, or employee relations, contributing to the development of a productive and positive workplace. Ideal for those who want to drive change and nurture talent in an organization.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "D351",
                  "C168"
                ],
                "term2": [
                  "D352",
                  "C483",
                  "D269",
                  "D078"
                ],
                "term3": [
                  "D082",
                  "C720",
                  "D196",
                  "D268"
                ],
                "term4": [
                  "D081",
                  "D076",
                  "C273",
                  "D354"
                ],
                "term5": [
                  "D270",
                  "D089",
                  "D355",
                  "D253"
                ],
                "term6": [
                  "C716",
                  "C955",
                  "D080",
                  "D353"
                ],
                "term7": [
                  "D356",
                  "C458",
                  "C957",
                  "D077"
                ],
                "term8": [
                  "C717",
                  "C165",
                  "D100",
                  "D266",
                  "D079"
                ],
                "term9": [
                  "D357",
                  "C723",
                  "C722",
                  "D361"
                ],
                "term10": [
                  "C721",
                  "D358",
                  "D359",
                  "D360"
                ]
              }
        },
        "B-MBSBA": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Marketing – B.S. Business Administration",
            "description": "For those who want to lead brands and steer consumer markets...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Shape Consumer Markets with a B.S. in Marketing",
                "description": `The B.S. in Marketing empowers you to lead brands and steer consumer markets. Learn about marketing strategy, brand management, digital marketing, market research, and consumer behavior.\n\n    Develop skills in creating effective marketing campaigns, using data analytics to inform decisions, and understanding the digital marketing landscape. The program offers real-world projects and case studies, providing hands-on experience in marketing.\n\n    Graduates will be prepared for roles in marketing management, brand strategy, or digital marketing, ready to influence market trends and consumer behavior. Ideal for those with a creative and strategic mindset aiming to excel in the dynamic field of marketing.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "D077",
                  "D265"
                ],
                "term2": [
                  "VZT1",
                  "C458",
                  "D270",
                  "D078"
                ],
                "term3": [
                  "D082",
                  "D388",
                  "D174",
                  "C716"
                ],
                "term4": [
                  "D081",
                  "D196",
                  "C273",
                  "D175"
                ],
                "term5": [
                  "D268",
                  "D076",
                  "C483",
                  "QHT1"
                ],
                "term6": [
                  "D253",
                  "C955",
                  "D089",
                  "D098"
                ],
                "term7": [
                  "D176",
                  "D080",
                  "C957",
                  "D177"
                ],
                "term8": [
                  "C165",
                  "D266",
                  "D351",
                  "C721"
                ],
                "term9": [
                  "D079",
                  "D099",
                  "D198",
                  "C723",
                  "C722"
                ],
                "term10": [
                  "D361",
                  "D178"
                ]
              }
        },
        "B-ABSBA": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Accounting – B.S. Business Administration",
            "description": "Have a knack for numbers or fine-tuning finances?...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Excel in Financial Stewardship with a B.S. in Accounting",
                "description": `The B.S. in Accounting is perfect for those with a knack for numbers and a passion for finance. The program covers financial accounting, managerial accounting, auditing, and taxation.\n\n    Gain expertise in financial analysis, regulatory compliance, and accounting ethics. Learn to prepare financial statements, conduct audits, and make informed business decisions based on financial data.\n\n    Graduates will be ready for roles in accounting, financial analysis, or auditing, equipped with the skills to ensure financial accuracy and integrity in various business settings. Ideal for those aiming to become financial stewards in the business world.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "D196",
                  "C168"
                ],
                "term2": [
                  "D082",
                  "C955",
                  "C455",
                  "C717"
                ],
                "term3": [
                  "C100",
                  "D102",
                  "C957",
                  "C483"
                ],
                "term4": [
                  "D100",
                  "D075",
                  "C237",
                  "D081",
                  "C723"
                ],
                "term5": [
                  "D078",
                  "D076",
                  "C464",
                  "C232",
                  "D079"
                ],
                "term6": [
                  "C121",
                  "D101",
                  "D216",
                  "D253"
                ],
                "term7": [
                  "C273",
                  "D089",
                  "D103",
                  "C233"
                ],
                "term8": [
                  "D077",
                  "D104",
                  "D217",
                  "C165"
                ],
                "term9": [
                  "C720",
                  "D105",
                  "C456",
                  "C236"
                ],
                "term10": [
                  "D361",
                  "D080",
                  "D215"
                ]
              }
        },
        "B-IMBSBA": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "IT Management – B.S. Business Administration",
            "description": "IT managers are needed in nearly every organization:...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Bridge Technology and Business with a B.S. in IT Management",
                "description": `The B.S. in IT Management combines information technology and business management principles. This program is ideal for those looking to lead in the IT field, focusing on areas like IT project management, system analysis, and technology planning.\n\n    Learn to align IT strategies with business goals, manage IT teams, and oversee technological projects. The curriculum blends IT technical skills with leadership and management training.\n\n    Graduates are equipped for roles in IT management, system analysis, or project leadership, ready to drive technological innovation in nearly every type of organization. Ideal for individuals who want to excel at the intersection of IT and business.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "D075",
                  "C168"
                ],
                "term2": [
                  "C483",
                  "D078",
                  "C455",
                  "C720"
                ],
                "term3": [
                  "C722",
                  "D196",
                  "D082",
                  "C121"
                ],
                "term4": [
                  "C724",
                  "C464",
                  "C456",
                  "C165"
                ],
                "term5": [
                  "C273",
                  "C955",
                  "D089",
                  "C179"
                ],
                "term6": [
                  "D100",
                  "D079",
                  "D076",
                  "C172",
                  "C957"
                ],
                "term7": [
                  "C100",
                  "D077",
                  "D080",
                  "C721"
                ],
                "term8": [
                  "C232",
                  "D081",
                  "D253",
                  "C175"
                ],
                "term9": [
                  "C234",
                  "D361",
                  "C236",
                  "C233"
                ],
                "term10": [
                  "C723",
                  "PFIT",
                  "QFT1"
                ]
              }
        },
        "B-HABS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Healthcare Administration – B.S.",
            "description": "You can become a healthcare industry leader:...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Lead in Healthcare with a B.S. in Healthcare Administration",
                "description": `The B.S. in Healthcare Administration prepares you to become a leader in the healthcare industry. Learn about healthcare policy, system management, patient care dynamics, and healthcare finance.\n\n    Develop skills in strategic planning, resource management, and legal aspects of healthcare administration. The program offers insights into the unique challenges of managing healthcare organizations.\n\n    Graduates will be ready for roles in healthcare management, policy analysis, or health services administration, contributing to the efficiency and effectiveness of healthcare delivery. Ideal for those seeking to make a significant impact in the healthcare sector.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "C430",
                  "C168"
                ],
                "term2": [
                  "C483",
                  "C455",
                  "C425",
                  "D078"
                ],
                "term3": [
                  "C720",
                  "C456",
                  "C426",
                  "D082"
                ],
                "term4": [
                  "C464",
                  "D196",
                  "C427",
                  "D081"
                ],
                "term5": [
                  "D100",
                  "C121",
                  "D076",
                  "C428",
                  "D253"
                ],
                "term6": [
                  "C716",
                  "C955",
                  "D089",
                  "C429"
                ],
                "term7": [
                  "C100",
                  "D077",
                  "C957",
                  "C431"
                ],
                "term8": [
                  "D080",
                  "C273",
                  "C165",
                  "C236"
                ],
                "term9": [
                  "C232",
                  "D361",
                  "C723",
                  "C722"
                ],
                "term10": [
                  "C432",
                  "C721",
                  "C439"
                ]
              }
        },
        "B-FBS": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Finance – B.S.",
            "description": "This finance program is career-focused so you will gain relevant...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Navigate Financial Success with a B.S. in Finance",
                "description": `The B.S. in Finance is a career-focused program that prepares you for roles in financial management, investment, and analysis. Learn about corporate finance, investment strategies, risk management, and financial markets.\n\n    Gain practical skills in financial analysis and decision-making, using the latest tools and technologies in finance. The program includes real-world financial projects and case studies, providing hands-on experience in finance.\n\n    Graduates will be equipped for careers in financial analysis, investment management, or corporate finance, ready to navigate financial success in various business contexts. Ideal for those passionate about the financial aspects of business and eager to make informed economic decisions.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "D363",
                  "D265"
                ],
                "term2": [
                  "D082",
                  "C955",
                  "D269",
                  "C717"
                ],
                "term3": [
                  "D198",
                  "D196",
                  "C957",
                  "D253"
                ],
                "term4": [
                  "D351",
                  "D388",
                  "D081",
                  "C723"
                ],
                "term5": [
                  "D078",
                  "D076",
                  "C483",
                  "D075"
                ],
                "term6": [
                  "D079",
                  "D366",
                  "D216",
                  "C458"
                ],
                "term7": [
                  "D077",
                  "D089",
                  "D362",
                  "D352"
                ],
                "term8": [
                  "C720",
                  "D364",
                  "C722",
                  "C165"
                ],
                "term9": [
                  "C273",
                  "D365",
                  "D368",
                  "D268"
                ],
                "term10": [
                  "D267",
                  "D367",
                  "D080",
                  "D369"
                ]
              }
        },
        "B-SCaOMBS": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Supply Chain and Operations Management - B.S.",
            "description": "This supply chain and operations management program will prepare...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Optimize Business Processes with a B.S. in Supply Chain and Operations Management",
                "description": `The B.S. in Supply Chain and Operations Management prepares you to optimize business processes and streamline supply chain management. Learn about logistics, procurement, production planning, and quality control.\n\n    Develop skills in analyzing and improving operational efficiency, managing supply chain networks, and implementing sustainable practices. The program includes practical experiences in supply chain and operations scenarios.\n\n    Graduates are ready for roles in supply chain management, logistics coordination, or operations analysis, equipped to enhance the efficiency and effectiveness of business operations. Ideal for those interested in the behind-the-scenes dynamics of product and service delivery.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "D388",
                  "D468"
                ],
                "term2": [
                  "C722",
                  "D269",
                  "C483",
                  "D198"
                ],
                "term3": [
                  "D078",
                  "C955",
                  "D467",
                  "C458"
                ],
                "term4": [
                  "D082",
                  "D466",
                  "C273",
                  "D196"
                ],
                "term5": [
                  "D268",
                  "D465",
                  "D089",
                  "C717"
                ],
                "term6": [
                  "C165",
                  "D464",
                  "D076",
                  "D253"
                ],
                "term7": [
                  "C721",
                  "D080",
                  "D470",
                  "C957"
                ],
                "term8": [
                  "D267",
                  "D361",
                  "D471",
                  "D469"
                ],
                "term9": [
                  "D265",
                  "D472",
                  "D428",
                  "D473"
                ]
              }
        },
        "B-HIMBS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Health Information Management – B.S.",
            "description": "A program designed for future leaders in HIM...",
            "degreeLevel": "bachelors",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Become a Leader in Health Information Management",
                "description": `The B.S. in Health Information Management prepares future leaders in the HIM field. Learn about managing healthcare data, medical coding, and the legal aspects of health information. Understand the technology that powers health information systems and data analysis.\n\n    Gain skills in information governance, privacy, and security, essential in today’s digital healthcare environment. The program combines healthcare knowledge with business and information technology, aligning health information management with broader organizational goals.\n\n    Graduates are ready for roles in healthcare data management, compliance, and information governance, playing a crucial role in healthcare organizations. Ideal for those interested in the intersection of healthcare, business, and IT.`
            },
            courses: {
                "term1": [
                  "D389",
                  "D269",
                  "C799",
                  "C784"
                ],
                "term2": [
                  "D265",
                  "D268",
                  "C190",
                  "D270"
                ],
                "term3": [
                  "C804",
                  "D203",
                  "C816",
                  "C180"
                ],
                "term4": [
                  "C802",
                  "C803",
                  "D198",
                  "C957"
                ],
                "term5": [
                  "D190",
                  "C810",
                  "C801",
                  "C963"
                ],
                "term6": [
                  "C805",
                  "D398",
                  "C815",
                  "C808"
                ],
                "term7": [
                  "D254",
                  "C807",
                  "C811",
                  "C812"
                ],
                "term8": [
                  "C813",
                  "D033",
                  "D255",
                  "D257"
                ],
                "term9": [
                  "D256",
                  "D258",
                  "D259",
                  "D260"
                ]
              }
        },
        "B-MoBA": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Master of Business Administration",
            "description": "The flexible MBA program you need, focused on business management,...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Elevate Your Career with a Flexible MBA",
                "description": `The Master of Business Administration program offers a comprehensive curriculum focused on business management, leadership, and strategic thinking. Designed for working professionals, it provides flexibility to balance work and study.\n\n    Covering areas such as finance, marketing, operations, and human resources, the program develops a broad understanding of business operations. Enhance your skills in decision-making, problem-solving, and leadership, preparing for higher-level management roles.\n\n    Graduates are equipped to take on leadership positions in various industries, contributing strategic vision and effective management to organizations. Ideal for professionals seeking to advance their careers in business management.`
            },
            courses: {
                "term1": [
                  "C200",
                  "C202",
                  "C204"
                ],
                "term2": [
                  "C212",
                  "C213",
                  "C206"
                ],
                "term3": [
                  "C214",
                  "C207",
                  "C215"
                ],
                "term4": [
                  "C211"
                ],
                "term": [
                  "C216"
                ]
              }
        },
        "B-MaLMS": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Management and Leadership – M.S.",
            "description": "A master's degree focused on change management, innovation, and...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Master Change Management and Leadership",
                "description": `The M.S. in Management and Leadership focuses on change management, innovation, and leading organizational development. Develop skills in strategic thinking, effective communication, and leadership in various organizational contexts.\n\n    Explore theories of leadership and management, and learn to apply them in practice. Gain insights into leading teams, managing change, and driving innovation within organizations.\n\n    Graduates are prepared to take on leadership roles, effectively manage change, and drive organizational success. Ideal for professionals aspiring to lead teams and organizations through dynamic business landscapes.`
            },
            courses: {
                "term1": [
                  "C200",
                  "C203",
                  "C202"
                ],
                "term2": [
                  "C201",
                  "C204",
                  "C206"
                ],
                "term3": [
                  "C205",
                  "C208",
                  "C209"
                ],
                "term4": [
                  "C210"
                ]
              }
        },
        "B-MHM": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "MBA Healthcare Management",
            "description": "Prepare for a career leading private or public healthcare organizations....",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Lead in Healthcare with an MBA in Healthcare Management",
                "description": `The MBA in Healthcare Management prepares you for leadership roles in healthcare organizations. Gain comprehensive knowledge of healthcare systems, policy, and management. Learn about healthcare finance, operations, and strategic planning in a healthcare context.\n\n    The program combines business management principles with healthcare expertise, preparing you for roles that require understanding both sectors. Focus on managing healthcare organizations effectively, dealing with unique challenges in the healthcare industry.\n\n    Graduates are ready for executive roles in healthcare administration, policy, or management, equipped to lead healthcare organizations towards efficiency and quality care. Ideal for professionals aspiring to combine business acumen with healthcare management.`
            },
            courses: {
                "term1": [
                  "C200",
                  "C215",
                  "AFT2"
                ],
                "term2": [
                  "C213",
                  "C214",
                  "C206"
                ],
                "term3": [
                  "C207",
                  "C212",
                  "AMT2"
                ],
                "term4": [
                  "C211",
                  "C219"
                ]
              }
        },
        "B-MITM": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "MBA Information Technology Management",
            "description": "Earn the college degree that can move your career into IT upper...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Advance Your IT Career with an MBA in IT Management",
                "description": `The MBA in Information Technology Management is designed for professionals aiming to move into upper management roles within IT. The program combines business management skills with IT expertise. Learn about IT strategy, project management, and technology leadership.\n\n    Develop skills in managing IT resources, aligning IT with business goals, and leading technology-driven organizational change. The curriculum covers latest trends and practices in IT management, preparing you to lead effectively in the tech-driven business environment.\n\n    Graduates are prepared for high-level roles such as IT directors, technology managers, or CIOs, bridging the gap between business and technology. Ideal for IT professionals with a vision to lead in the technology sector.`
            },
            courses: {
                "term1": [
                  "C200",
                  "MGT2",
                  "C202"
                ],
                "term2": [
                  "C212",
                  "MMT2",
                  "C213"
                ],
                "term3": [
                  "C206",
                  "C214",
                  "C207"
                ],
                "term4": [
                  "C211",
                  "C218"
                ]
              }
        },
        "B-AMS": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Accounting – M.S.",
            "description": "Become better qualified to pursue a greater variety of public and private...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Excel in Advanced Accounting Practices with an M.S. in Accounting",
                "description": `The M.S. in Accounting is designed for those seeking to deepen their expertise in accounting. The program covers advanced topics in financial accounting, managerial accounting, auditing, and taxation.\n\n    Gain skills in financial analysis, regulatory compliance, ethical accounting practices, and strategic financial decision-making. The curriculum is designed to prepare you for the complexities of accounting in modern business environments.\n\n    Graduates are well-equipped for careers in accounting, financial management, or auditing, ready to pursue a greater variety of public and private accounting roles. Ideal for accounting professionals seeking advanced knowledge and career progression in the field.`
            },
            courses: {
                "term1": [
                  "C254",
                  "D251",
                  "C253"
                ],
                "term2": [
                  "C214",
                  "C209",
                  "C243"
                ],
                "term3": [
                  "D252",
                  "D250",
                  "C204"
                ],
                "term4": [
                  "C239"
                ]
              }
        },
        "B-MMS": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Marketing – M.S.",
            "description": "Two marketing master's degree options with a focus on either...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Specialize in Marketing with an M.S. in Marketing",
                "description": `The M.S. in Marketing offers specialized education in marketing, focusing on areas such as digital marketing, brand management, or market research. Develop advanced skills in creating marketing strategies, understanding consumer behavior, and leveraging digital tools.\n\n    The program combines theoretical knowledge with practical application, allowing you to work on real-world marketing projects. Learn to analyze market trends, design effective marketing campaigns, and measure their success.\n\n    Graduates are prepared for roles in marketing management, brand strategy, or digital marketing, equipped to steer consumer markets and brand development. Ideal for professionals aiming to excel in the dynamic and creative field of marketing.`
            },
            courses: {
                "term1": [
                  "C200",
                  "D263",
                  "D373"
                ],
                "term2": [
                  "D374",
                  "D375",
                  "D376"
                ],
                "term3": [
                  "D377",
                  "D379",
                  "D380"
                ],
                "term4": [
                  "D381",
                  "D384"
                ]
              }
        },
        "B-HRMMS": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Human Resource Management – M.S.",
            "description": "A rewarding master's degree in human resource management...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Master the Art of Human Capital Management with an M.S. in Human Resource Management",
                "description": `The M.S. in Human Resource Management is designed for those seeking advanced expertise in managing an organization's most valuable asset – its people. Learn about strategic HR planning, talent management, employment law, and organizational development.\n\n    Develop skills in workforce planning, employee engagement, and performance management. The program includes case studies and practical experiences, aligning human resource strategies with overall business objectives.\n\n    Graduates are prepared for senior roles in HR management, ready to lead HR initiatives that contribute to organizational success. Ideal for HR professionals seeking to advance their careers and make impactful contributions in human resources.`
            },
            courses: {
                "term1": [
                  "C200",
                  "C201",
                  "C202"
                ],
                "term2": [
                  "D432",
                  "C204",
                  "C207"
                ],
                "term3": [
                  "D433",
                  "D435",
                  "D434"
                ],
                "term4": [
                  "D436"
                ]
              }
        },
        "B-AITBsaMsD": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Accelerated Information Technology Bachelor's and Master's Degree",
            "description": "Earn both your bachelor’s in IT and master’s in IT management at a faster...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Fast-Track Your IT Career with an Accelerated Bachelor's and Master's Degree",
                "description": `This accelerated program allows you to earn both a bachelor’s degree in IT and a master’s in IT management more quickly than taking them separately. It's designed for students who want to fast-track their education and career in information technology.\n\n    The program covers a wide range of IT topics, from foundational knowledge to advanced concepts in IT management. Gain skills in network security, database management, and strategic IT planning.\n\n    Graduates will be ready for leadership roles in IT, equipped with both technical expertise and management skills. Ideal for ambitious students seeking to rapidly advance their IT careers and take on managerial roles.`
            },
            courses: {
                "term1": [
                  "D265",
                  "D322",
                  "D198",
                  "C165"
                ],
                "term2": [
                  "C483",
                  "D336",
                  "D269",
                  "D268"
                ],
                "term3": [
                  "D315",
                  "D270",
                  "C955",
                  "D317"
                ],
                "term4": [
                  "C484",
                  "C268",
                  "D316",
                  "C277"
                ],
                "term5": [
                  "D199",
                  "D278",
                  "C957",
                  "D276"
                ],
                "term6": [
                  "C777",
                  "C773",
                  "C963"
                ],
                "term7": [
                  "C683",
                  "D333",
                  "D426",
                  "D427"
                ],
                "term8": [
                  "D282",
                  "D325",
                  "D329",
                  "D281"
                ],
                "term9": [
                  "C948",
                  "C954",
                  "C962",
                  "C783"
                ],
                "term10": [
                  "C769"
                ]
              }
        },
        "B-DAMS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Data Analytics – M.S.",
            "description": "Lead businesses with strong analysis skills...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Lead with Data-Driven Insights with an M.S. in Data Analytics",
                "description": `The M.S. in Data Analytics equips you to lead businesses with strong analysis skills. Learn to extract meaningful insights from complex data sets using statistical methods, predictive modeling, and data mining.\n\n    Gain proficiency in data analytics tools and technologies, and understand how to apply data analytics in business decision-making. The program includes real-world projects, providing hands-on experience in data analysis.\n\n    Graduates are prepared for roles such as data analyst, data scientist, or business intelligence analyst, ready to drive strategic decisions through data-driven insights. Ideal for professionals seeking to specialize in the rapidly growing field of data analytics.`
            },
            courses: {
                "term1": [
                  "D204",
                  "D205",
                  "D206"
                ],
                "term2": [
                  "D207",
                  "D208",
                  "D209"
                ],
                "term3": [
                  "D210",
                  "D211",
                  "D212"
                ],
                "term4": [
                  "D213",
                  "D214"
                ]
              }
        },
        "B-MoHA": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Master of Healthcare Administration",
            "description": "A master's focused on managing comprehensive, value-based care, directly...",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Navigate Healthcare Leadership with a Master's in Healthcare Administration",
                "description": `The Master of Healthcare Administration program focuses on managing comprehensive, value-based care. Gain in-depth knowledge of healthcare systems, policy, and leadership. Learn about healthcare finance, operations, and strategic management in a healthcare context.\n\n    The program prepares you to navigate the complexities of healthcare delivery and to lead healthcare organizations effectively. It combines healthcare expertise with management skills, making it ideal for those aspiring to be healthcare leaders.\n\n    Graduates are prepared for executive roles in healthcare administration, ready to implement strategies that improve patient care and organizational efficiency. Ideal for professionals seeking to lead in the evolving healthcare sector.`
            },
            courses: {
                "term1": [
                  "D509",
                  "D510",
                  "D511"
                ],
                "term2": [
                  "D512",
                  "D513",
                  "D514"
                ],
                "term3": [
                  "D515",
                  "D516",
                  "D517"
                ],
                "term4": [
                  "D518",
                  "D519",
                  "D520"
                ]
              }
        },
        // Removed
        // "B-ETaIDME": {
        //     "type": "EDUCATION",
        //     "typImg": educationIcon,
        //     "name": "Education Technology and Instructional Design – M.Ed.",
        //     "description": "The M.Ed. in Education Technology and Instructional Design from Whalley is for...",
        //     "degreeLevel": "masters",
        //     "programTypes": ["business"],
        //     "detailInformation": {
        //         "title": "Enhance Learning with a M.Ed. in Education Technology and Instructional Design",
        //         "description": `The M.Ed. in Education Technology and Instructional Design is for educators who want to integrate technology effectively into their teaching practices. Learn about the latest educational technologies, instructional design theories, and digital learning environments.\n\n    Develop skills in creating engaging, technology-enhanced learning experiences and in evaluating the effectiveness of educational technologies. The program includes practical application of instructional design principles in educational settings.\n\n    Graduates are prepared for roles such as instructional designers, educational technologists, or e-learning developers, enhancing learning experiences through innovative technology. Ideal for educators and professionals passionate about the intersection of education and technology.`
        //     }
        // },
        "B-ITMMS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Information Technology Management – M.S.",
            "description": "A degree for experienced IT professionals ready to lead....",
            "degreeLevel": "masters",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Advance IT Leadership with an M.S. in Information Technology Management",
                "description": `The M.S. in Information Technology Management is designed for experienced IT professionals ready to step into leadership roles. The program focuses on aligning IT strategies with business goals, managing IT projects, and leading technology initiatives.\n\n    Gain skills in strategic planning, IT governance, and risk management. Understand the impact of emerging technologies on business and how to leverage them for organizational advantage.\n\n    Graduates are prepared for senior roles such as IT managers, directors of technology, or CIOs, ready to lead IT departments and drive technological innovation. Ideal for IT professionals with a vision to lead in the tech-driven business environment.`
            },
            courses: {
                "term1": [
                  "C200",
                  "MGT2",
                  "C202"
                ],
                "term2": [
                  "C212",
                  "MMT2",
                  "C213"
                ],
                "term3": [
                  "C206",
                  "C214",
                  "C207"
                ],
                "term4": [
                  "C211",
                  "C218"
                ]
              }
        },
        // TODO add courses (ok to have no courses on certs)
        "B-BL": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Business Leadership",
            "description": "The skillsets offered in this certificate are particularly valuable for emerging leaders who want to...",
            "degreeLevel": "certificate",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Develop Leadership Excellence with a Certificate in Business Leadership",
                "description": `The Business Leadership certificate program is designed for emerging leaders. Develop key leadership skills such as strategic thinking, effective communication, and team management. Learn about organizational behavior, change management, and leadership strategies.\n\n    The program is ideal for professionals looking to enhance their leadership capabilities and to take on more significant roles in their organizations. Gain practical insights and tools to lead teams effectively, drive organizational goals, and foster a positive work culture.\n\n    This certificate is perfect for those starting their leadership journey or existing leaders seeking to refine their leadership skills.`
            }
        },
        // TODO add courses (ok to have no courses on certs)
        "B-FfSiB": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Fundamentals for Success in Business",
            "description": "You will consider and demonstrate knowledge in the nature of work, teams, organizational culture, leadership, and...",
            "degreeLevel": "certificate",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Lay the Foundation for Business Success",
                "description": `The Fundamentals for Success in Business certificate program covers essential concepts for business success. Learn about the nature of work, team dynamics, organizational culture, and leadership principles.\n\n    Develop a solid understanding of business operations, effective communication, and team collaboration. The program offers insights into managing organizational change and developing leadership skills.\n\n    This certificate is ideal for individuals seeking to understand the core aspects of successful business practices and to develop foundational skills for business management and leadership.`
            }
        },
        // TODO add courses (ok to have no courses on certs)
        "B-PMP": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Personality Management & Psychology",
            "description": "Understand the people you lead by completing this certificate program...",
            "degreeLevel": "certificate",
            "programTypes": ["business"],
            "detailInformation": {
                "title": "Navigate Leadership Dynamics with a Certificate in Personality Management & Psychology",
                "description": `The Personality Management & Psychology certificate program provides insights into understanding the people you lead. Learn about personality theories, psychological principles in leadership, and emotional intelligence.\n\n    Develop skills in interpersonal communication, team motivation, and conflict resolution. The program is tailored for leaders and managers seeking to enhance their people management skills and to build effective teams.\n\n    Ideal for professionals aiming to excel in leadership by mastering the art of managing diverse personalities and fostering a positive and productive workplace.`
            }
        },
        // TODO add courses (ok to have no courses on certs)
        "B-ETRM": {
            "type": "BUSINESS",
            "typImg": businessIcon,
            "name": "Effective Time & Resource Management",
            "description": "Time and resource management can make the difference between...",
            "degreeLevel": "certificate",
            "programTypes": [
                "business"
            ],
            "detailInformation": {
                "title": "Master the Art of Time and Resource Allocation for Business Success",
                "description": `The Certificate in Effective Time & Resource Management is designed for professionals seeking to enhance their skills in managing time and resources effectively in a business environment. This program emphasizes the importance of strategic planning, prioritization, and efficient resource allocation in achieving business objectives.\n
                    In this course, you will learn the fundamental principles of time management, including setting realistic goals, creating effective to-do lists, and understanding the value of time. The program also covers techniques for avoiding common time-wasting traps and strategies for overcoming procrastination.\n
                    A critical component of this certificate is resource management. You will explore various methods for resource allocation, budgeting, and leveraging technology to maximize efficiency. The course also delves into team management and how to inspire and motivate your team to achieve better time management and resource utilization.\n
                    Upon completion, participants will have a solid foundation in managing time and resources more efficiently, leading to increased productivity, better decision-making, and ultimately, greater success in their professional lives. This certificate is ideal for those in management roles, aspiring leaders, and anyone who wants to develop their skill set in these crucial areas of business management.`
            }
        },
        "I-CSBS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Computer Science – B.S.",
            "description": "Problem solvers and math lovers needed! Your task:...",
            "degreeLevel": "bachelors",
            "programTypes": [
                "it"
            ],
            detailInformation: {
                title: "Combine Engineering, Science and Math to Create Software Solutions to Real-World Issues",
                description: `A BS in Computer Science degree emphasizes the mathematical and theoretical foundations of computing, rather than teaching specific technologies. It provides the foundation you need to break into some of the most exciting and profitable careers. Prepare for opportunities in a variety of fields, including robotics, computer gaming, virtual reality, computer vision, media convergence, digital, evolutionary computing, computer architecture and so much more.\n\n     Here, you’ll learn from faculty who have internationally recognized expertise in fundamental and application areas. Your coursework offers opportunities to study phenomena connected with computers and computation, including software (algorithms and data structures), hardware (design of computers) and many diverse applications of computational thinking and techniques.\n\n        All computer science majors must pass the Computer Science Foundation Exam in order to advance to upper-level coursework. Nationally, only UCF’s computer science program uses a test this way to qualify its students. The exam covers problem solving techniques, algorithms, abstraction, proofs and language skills. Tests are held each semester, and the exam helps ensure the success of our students. It’s also a resume builder and a feature many industry partners highlight as a primary reason they want to hire our computer science graduates.`
            },
            courses: {
                "term1": [
                  "D322",
                  "C955",
                  "D315",
                  "D278"
                ],
                "term2": [
                  "D426",
                  "D276",
                  "C958",
                  "C867"
                ],
                "term3": [
                  "D199",
                  "C458",
                  "C959",
                  "D197"
                ],
                "term4": [
                  "C960",
                  "D268",
                  "C952",
                  "D270"
                ],
                "term5": [
                  "D427",
                  "C963",
                  "D198",
                  "D286"
                ],
                "term6": [
                  "D287",
                  "C949",
                  "D288",
                  "D281"
                ],
                "term7": [
                  "D387",
                  "D333",
                  "D430",
                  "C191"
                ],
                "term8": [
                  "C950",
                  "C683",
                  "D336",
                  "D284"
                ],
                "term": [
                  ""
                ],
                "term9": [
                  "D326",
                  "D370",
                  "D339",
                  "C951"
                ],
                "term10": [
                  "D480",
                  "C964"
                ]
            },
        },
        "I-CaIABS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Cybersecurity and Information Assurance – B.S.",
            "description": "Protect your career and earning potential with this degree....",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Secure Digital Assets and Network Systems with Advanced Cybersecurity Knowledge",
                "description": `The B.S. in Cybersecurity and Information Assurance program is designed to equip students with the skills needed to protect digital assets and secure network systems. The program combines theoretical knowledge with practical application, focusing on latest technologies and best practices in cybersecurity. \n\n    Students will learn about network security, cryptography, risk management, and incident response. The program also covers legal and ethical issues in cybersecurity, preparing graduates to tackle challenges in diverse sectors. Hands-on learning opportunities, including labs and projects, will prepare students for roles as cybersecurity analysts, information security officers, and network security engineers.`
            },
            courses: {
                "term1": [
                  "D265",
                  "D322",
                  "D270",
                  "D430"
                ],
                "term2": [
                  "D317",
                  "D316",
                  "C841"
                ],
                "term3": [
                  "C955",
                  "D372",
                  "C957",
                  "D315"
                ],
                "term4": [
                  "C458",
                  "D325",
                  "C844"
                ],
                "term5": [
                  "D329",
                  "D431",
                  "D336"
                ],
                "term6": [
                  "D426",
                  "D427",
                  "C683",
                  "D281"
                ],
                "term7": [
                  "C845",
                  "D334",
                  "D324"
                ],
                "term8": [
                  "D278",
                  "D335",
                  "C963",
                  "D333"
                ],
                "term9": [
                  "C843",
                  "D340",
                  "D268"
                ],
                "term10": [
                  "D320",
                  "D332",
                  "C769"
                ]
            }
        },
        "I-DABS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Data Analytics – B.S.",
            "description": "Lean into data, and walk away with a cutting-edge degree:...",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Transform Data into Actionable Insights and Drive Business Strategy",
                "description": `This B.S. in Data Analytics program provides a solid foundation in the field of data science and analytics. The curriculum focuses on data mining, statistical analysis, and data visualization, and how to apply these skills in real-world situations. Students will learn to use advanced tools and technologies to analyze large datasets, and to interpret and communicate their findings effectively. The program is designed for those who are passionate about data and want to use it to drive business decisions. Graduates will be equipped for roles in business intelligence, data analysis, and data management across various industries.`
            },
            courses: {
                "term1": [
                  "D491",
                  "D370",
                  "D278",
                  "D388",
                  "D426"
                ],
                "term2": [
                  "C721",
                  "D427",
                  "C955",
                  "C683"
                ],
                "term3": [
                  "D326",
                  "D265",
                  "D335",
                  "D315"
                ],
                "term4": [
                  "C957",
                  "D197",
                  "D428",
                  "D276",
                  "D269"
                ],
                "term5": [
                  "D333",
                  "D282",
                  "D386",
                  "C458"
                ],
                "term6": [
                  "D492",
                  "D420",
                  "D421",
                  "D493",
                  "D268"
                ],
                "term7": [
                  "D199",
                  "D324",
                  "D494",
                  "D495"
                ],
                "term8": [
                  "C963",
                  "C949",
                  "D496"
                ],
                "termand": [
                  "D246"
                ],
                "term": [
                  "",
                  ""
                ],
                "term9": [
                  "D497",
                  "D498",
                  "D499",
                  "D500",
                  "D501"
                ],
                "term10": [
                  "D372",
                  "D502"
                ]
              }
        },
        "I-NEaSBS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Network Engineering and Security – B.S.",
            "description": "Launch your career in designing, securing, and optimizing complex networks....",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Build and Secure Robust Network Infrastructures for Modern Enterprises",
                "description": `The B.S. in Network Engineering and Security program is tailored to provide a comprehensive understanding of network design and security. Students will gain in-depth knowledge of network architecture, protocols, and security measures.\n\n    The program covers a range of topics, including wireless networks, cloud networking, and cybersecurity. Through hands-on learning experiences, students will develop skills to design, implement, and manage secure and efficient network solutions. This program prepares graduates for roles such as network engineers, systems administrators, and network security specialists in various industries.`
            },
            courses: {
                "term1": [
                  "D265",
                  "D322",
                  "D198",
                  "C165"
                ],
                "term2": [
                  "C483",
                  "D336",
                  "D269",
                  "D268"
                ],
                "term3": [
                  "D315",
                  "D270",
                  "C955",
                  "D317"
                ],
                "term4": [
                  "C484",
                  "C268",
                  "D316",
                  "C277"
                ],
                "term5": [
                  "D199",
                  "D278",
                  "C957",
                  "D276"
                ],
                "term6": [
                  "C777",
                  "C773",
                  "C963"
                ],
                "term7": [
                  "C724",
                  "C683",
                  "D324",
                  "D333"
                ],
                "term8": [
                  "D426",
                  "D427",
                  "D282",
                  "C850"
                ],
                "term9": [
                  "D325",
                  "D339",
                  "D329",
                  "D281"
                ],
                "term10": [
                  "C769"
                ],
                "term": [
                  ""
                ]
              }
        },
        "I-IMBSBA": {
            "type": "INFORMATION TECHNOLOGY ",
            "typImg": itIcon,
            "name": "IT Management – B.S. Business Administration",
            "name2": "Bachelor of Science Business Administration, Information Technology Management",
            "description": "IT managers are needed in nearly every organization:...",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Develop Strategic Leadership Skills in IT and Business Management",
                "description": `The B.S. in IT Management – Business Administration program bridges the gap between information technology and business leadership. This program provides a blend of IT technical skills and business acumen. Students will learn about IT infrastructure, information systems management, and business strategy.\n\n    The curriculum also includes topics in project management, financial management, and organizational behavior. Graduates will be equipped to lead IT departments, manage technology-driven projects, and align IT strategies with business goals. This degree is ideal for those aspiring to become IT managers, systems analysts, or business analysts in tech-driven organizations.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "D075",
                  "C168"
                ],
                "term2": [
                  "C483",
                  "D078",
                  "C455",
                  "C720"
                ],
                "term3": [
                  "C722",
                  "D196",
                  "D082",
                  "C121"
                ],
                "term4": [
                  "C724",
                  "C464",
                  "C456",
                  "C165"
                ],
                "term5": [
                  "C273",
                  "C955",
                  "D089",
                  "C179"
                ],
                "term6": [
                  "D100",
                  "D079",
                  "D076",
                  "C172",
                  "C957"
                ],
                "term7": [
                  "C100",
                  "D077",
                  "D080",
                  "C721"
                ],
                "term8": [
                  "C232",
                  "D081",
                  "D253",
                  "C175"
                ],
                "term9": [
                  "C234",
                  "D361",
                  "C236",
                  "C233"
                ],
                "term10": [
                  "C723",
                  "PFIT",
                  "QFT1"
                ]
              }
        },
        "I-SEBS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Software Engineering – B.S.",
            "description": "You're a creative and tech enthusiast who wants the bigger opportunities...",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Harness Creativity and Technical Skills for Developing Innovative Software Solutions",
                "description": `Software Engineering at the bachelor's level blends computer science and engineering principles to develop effective, user-centric software. The program emphasizes designing, testing, and refining software for diverse applications.\n\n    Students will explore various programming languages, software architecture, and user interface design. Collaborative projects and hands-on experiences form an integral part of the curriculum, simulating real-world software development scenarios.\n\n    The program also covers the ethical and social aspects of software engineering, ensuring graduates understand the broader impact of technology. With this degree, graduates can pursue roles such as software developers, systems analysts, and application developers in various industries, including tech, finance, and healthcare.`
            },
            courses: {
                "term1": [
                  "D322",
                  "C963",
                  "D199",
                  "C683"
                ],
                "term2": [
                  "D276",
                  "D339",
                  "C458",
                  "D315"
                ],
                "term3": [
                  "D426",
                  "D270",
                  "D386",
                  "D427"
                ],
                "term4": [
                  "D372",
                  "D197",
                  "D282",
                  "D278",
                  "C955"
                ],
                "term5": [
                  "D324",
                  "C957",
                  "D335",
                  "D333"
                ],
                "term6": [
                  "D336",
                  "C949",
                  "D370",
                  "D277"
                ],
                "term7": [
                  "D280",
                  "D284",
                  "D286",
                  "D287"
                ],
                "term8": [
                  "D279",
                  "D479",
                  "D288",
                  "D387"
                ],
                "term9": [
                  "D480",
                  "D326",
                  "D385",
                  "D308"
                ],
                "term10": [
                  "D424"
                ]
              }
        },
        "I-CCBS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Cloud Computing – B.S.",
            "description": "A three-track program designed to arm you with the certifications and...",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Master the Technology that Powers the Modern Digital World",
                "description": `The B.S. in Cloud Computing program prepares students for the rapidly evolving world of cloud technology. Courses cover cloud infrastructure, virtualization, data storage, and network security.\n\n    Students will gain hands-on experience with popular cloud platforms like AWS, Azure, and Google Cloud, learning to deploy and manage cloud solutions for businesses. The program also emphasizes cloud architecture, data management, and cloud security, ensuring a well-rounded skill set.\n\n    Project-based learning and real-world case studies ensure that graduates are ready to tackle challenges in cloud computing roles, making them valuable assets in tech companies, financial institutions, and government agencies.`
            },
            courses: {
                "term1": [
                  "D322",
                  "C955",
                  "D269",
                  "D317"
                ],
                "term2": [
                  "D316",
                  "C957",
                  "D315",
                  "C190"
                ],
                "term3": [
                  "D325",
                  "D334",
                  "D329"
                ],
                "term4": [
                  "D278",
                  "D276",
                  "D335",
                  "C963"
                ],
                "term5": [
                  "D281",
                  "D268",
                  "D282",
                  "D318"
                ],
                "term6": [
                  "D324",
                  "D426",
                  "D330",
                  "D411"
                ],
                "term7": [
                  "D427",
                  "D320",
                  "D199",
                  "D338"
                ],
                "term8": [
                  "D370",
                  "D319",
                  "D336",
                  "D321"
                ],
                "term9": [
                  "D341",
                  "C165",
                  "D333",
                  "D337"
                ],
                "term10": [
                  "D339",
                  "D342"
                ]
              }
        },
        "I-ITBS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Information Technology – B.S.",
            "description": "Award-winning coursework and value-add certifications make this online...",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Equip Yourself with Diverse IT Skills for a Dynamic Technological Landscape",
                "description": `The B.S. in Information Technology program covers a broad range of IT concepts, including hardware, software, networks, and databases. Students learn to apply these concepts to solve real-world problems.\n\n    The curriculum includes courses on network security, web development, and IT project management. Students also have the opportunity to earn industry-recognized certifications, adding value to their resumes.\n\n    With a focus on practical skills and hands-on learning, graduates are prepared for various roles in IT departments, such as system administrators, network engineers, and IT support specialists.`
            },
            courses: {
                "term1": [
                  "D265",
                  "D322",
                  "D198",
                  "C165"
                ],
                "term2": [
                  "C483",
                  "D336",
                  "D269",
                  "D268"
                ],
                "term3": [
                  "D315",
                  "D270",
                  "C955",
                  "D317"
                ],
                "term4": [
                  "C484",
                  "C268",
                  "D316",
                  "C277"
                ],
                "term5": [
                  "D199",
                  "D278",
                  "C957",
                  "D276"
                ],
                "term6": [
                  "C777",
                  "C773",
                  "C963"
                ],
                "term7": [
                  "C724",
                  "C683",
                  "D324",
                  "D333"
                ],
                "term8": [
                  "D426",
                  "D427",
                  "D282",
                  "C850"
                ],
                "term9": [
                  "D325",
                  "D339",
                  "D329",
                  "D281"
                ],
                "term10": [
                  "C769"
                ]
              }
        },
        "I-AITBsaMsD": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Accelerated Information Technology Bachelor's and Master's Degree",
            "description": "Earn both your bachelor’s in IT and master’s in IT management at a faster...",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Fast-Track Your IT Education and Career with an Accelerated Dual-Degree Program",
                "description": `This accelerated program is designed for ambitious students who want to complete both their bachelor's and master's degrees in Information Technology in a reduced timeframe.\n\n    The curriculum integrates undergraduate and graduate-level courses, providing a seamless transition from foundational IT concepts to advanced IT management strategies.\n\n    The program emphasizes leadership in IT, project management, and advanced technical skills. Graduates are well-equipped to take on leadership roles in IT departments or pursue careers in IT consultancy, systems analysis, and more.`
            },
            courses: {
                "term1": [
                  "D265",
                  "D322",
                  "D198",
                  "C165"
                ],
                "term2": [
                  "C483",
                  "D336",
                  "D269",
                  "D268"
                ],
                "term3": [
                  "D315",
                  "D270",
                  "C955",
                  "D317"
                ],
                "term4": [
                  "C484",
                  "C268",
                  "D316",
                  "C277"
                ],
                "term5": [
                  "D199",
                  "D278",
                  "C957",
                  "D276"
                ],
                "term6": [
                  "C777",
                  "C773",
                  "C963"
                ],
                "term7": [
                  "C683",
                  "D333",
                  "D426",
                  "D427"
                ],
                "term8": [
                  "D282",
                  "D325",
                  "D329",
                  "D281"
                ],
                "term9": [
                  "C948",
                  "C954",
                  "C962",
                  "C783"
                ],
                "term10": [
                  "C769",
                  "LZT2",
                  "C929",
                  "C927"
                ],
                "term11": [
                "C928",
                "MBT2",
                "C498"
                ]
              }
        },
        "I-HIMBS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Health Information Management – B.S.",
            "description": "A program designed for future leaders in HIM....",
            "degreeLevel": "bachelors",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Bridge the Gap Between Healthcare and Information Technology",
                "description": `The B.S. in Health Information Management program combines healthcare knowledge with IT skills. Students learn about electronic health records, health data management, and medical coding.\n\n    The curriculum emphasizes the importance of data in healthcare decision-making and patient care. Courses cover topics like health informatics, data privacy, and healthcare quality management.\n\n    Graduates are prepared for roles in healthcare facilities, where they manage patient data, ensure compliance with healthcare regulations, and contribute to improving patient outcomes.`
            },
            courses: {
                "term1": [
                  "D389",
                  "D269",
                  "C799",
                  "C784"
                ],
                "term2": [
                  "D265",
                  "D268",
                  "C190",
                  "D270"
                ],
                "term3": [
                  "C804",
                  "D203",
                  "C816",
                  "C180"
                ],
                "term4": [
                  "C802",
                  "C803",
                  "D198",
                  "C957"
                ],
                "term5": [
                  "D190",
                  "C810",
                  "C801",
                  "C963"
                ],
                "term6": [
                  "C805",
                  "D398",
                  "C815",
                  "C808"
                ],
                "term7": [
                  "D254",
                  "C807",
                  "C811",
                  "C812"
                ],
                "term8": [
                  "C813",
                  "D033",
                  "D255",
                  "D257"
                ],
                "term9": [
                  "D256",
                  "D258",
                  "D259",
                  "D260"
                ]
              }
        },
        "I-CIAMS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Cybersecurity & Information Assurance – M.S.",
            "description": "Become the authority on keeping infrastructures and information safe....",
            "degreeLevel": "masters",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Advanced Expertise in Securing Information Systems and Networks",
                "description": `The M.S. in Cybersecurity & Information Assurance program delves deeper into the strategies and technologies used to protect sensitive information and secure systems.\n\n    Advanced courses cover topics like ethical hacking, digital forensics, and cryptography. Students engage in practical, hands-on projects that simulate real-world security challenges.\n\n    Graduates are prepared for high-level careers in cybersecurity, including roles as information security analysts, cybersecurity consultants, and chief information security officers.`
            },
            courses: {
                "term1": [
                  "D481",
                  "D482",
                  "D483"
                ],
                "term2": [
                  "D484",
                  "D485"
                ],
                "term3": [
                  "D486",
                  "D487",
                  "D488"
                ],
                "term4": [
                  "D489"
                ],
                "term": [
                  "D490"
                ]
              }
        },
        "I-DAMS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Data Analytics – M.S.",
            "description": "Lead businesses with strong analysis skills:....",
            "degreeLevel": "masters",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Master Data-Driven Decision Making for Business Insights",
                "description": `The M.S. in Data Analytics program focuses on advanced techniques in data analysis, machine learning, and statistical modeling.\n\n    The curriculum includes case studies and projects that address real-world data challenges, preparing students to translate data insights into strategic business decisions.\n\n    Graduates are equipped for roles in data science, business intelligence, and analytics consultancy, contributing to data-driven strategies in diverse sectors.`
            },
            courses: {
                "term1": [
                  "D204",
                  "D205",
                  "D206"
                ],
                "term2": [
                  "D207",
                  "D208",
                  "D209"
                ],
                "term3": [
                  "D210",
                  "D211",
                  "D212"
                ],
                "term4": [
                  "D213",
                  "D214"
                ],
              }
        },
        "I-ITMMS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Information Technology Management – M.S.",
            "description": "A degree for experienced IT professionals ready to lead....",
            "degreeLevel": "masters",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Lead Technological Innovation with Strategic Management Skills",
                "description": `This M.S. program equips experienced IT professionals with the skills to manage complex IT projects and drive technological innovation.\n\n    The program covers IT governance, risk management, and emerging technologies. Students also develop leadership skills to effectively manage IT teams and projects.\n\n    Graduates are well-positioned for leadership roles in IT management, systems analysis, and IT project management, driving technological strategies in organizations.`
            },
            courses: {
                "term1": [
                  "C948",
                  "C954",
                  "LZT2"
                ],
                "term2": [
                  "C928",
                  "C929",
                  "C927",
                  "MBT2"
                ],
                "term3": [
                  "C783",
                  "C962",
                  "C498"
                ],
              }
        },
        "I-MITM": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "MBA Information Technology Management",
            "description": "Earn the college degree that can move your career into IT upper...",
            "degreeLevel": "masters",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Elevate Your Career with a Unique Blend of Business Acumen and IT Expertise",
                "description": `The MBA in Information Technology Management combines business leadership principles with IT strategic planning.\n\n    The program covers business management topics and how they intersect with IT, including finance, marketing, and operations, seen through an IT lens.\n\n    This degree prepares graduates for executive roles, where they oversee IT operations, align IT strategies with business goals, and drive innovation in tech-driven industries.`
            },
            courses: {
                "term1": [
                  "C200",
                  "MGT2",
                  "C202"
                ],
                "term2": [
                  "C212",
                  "MMT2",
                  "C213"
                ],
                "term3": [
                  "C206",
                  "C214",
                  "C207"
                ],
                "term4": [
                  "C211",
                  "C218"
                ],
              }
        },
        // TODO add courses (ok for cert)
        "I-RWD": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "React Web Developer",
            "description": "Earn proficiency in the popular web framework that allows you to...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Master the Art of Building Dynamic Web Applications with React",
                "description": `React Web Development Certificate equips you with the skills to create responsive and dynamic user interfaces. You'll learn how to leverage React's powerful ecosystem, including JSX, Redux, and React Router, to build efficient web applications. React's component-based architecture not only enhances the development process but also improves the overall performance of web applications.\n\n    Gain hands-on experience by building real-world projects that integrate with modern back-end technologies. Learn the intricacies of state management and how to efficiently handle data within your applications. Throughout the course, you will be mentored by industry experts who will guide you through best practices and advanced techniques in React development.\n\n    Completing this program not only gives you a certificate but also a portfolio of projects demonstrating your ability to future employers. You'll be equipped with the necessary skills to build and manage large-scale applications, making you a valuable asset in the rapidly evolving field of web development.`
            }
        },
        // TODO add courses (ok for cert)
        "I-JF": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Javascript Fundamentals",
            "description": "Javascript is the language of the web, this certificates shows proficiency in...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Develop Essential Web Development Skills with JavaScript Fundamentals",
                "description": `JavaScript Fundamentals Certificate offers a comprehensive introduction to the core concepts and practical applications of JavaScript in web development. The course covers everything from basic syntax to advanced features like asynchronous programming, closures, and event-driven programming.\n\n    Learn how to interact with the DOM, make your websites interactive, and create smooth user experiences. The course also delves into modern JavaScript frameworks and libraries, equipping you with the skills needed to work on a variety of web development projects.\n\n    This certificate is designed for beginners and intermediate developers who want to solidify their understanding of JavaScript. It includes hands-on exercises and projects that will help you apply what you've learned in real-world scenarios. By the end of the course, you'll have a strong foundation in JavaScript, ready to take on more advanced studies or jump into professional web development.`
            }
        },
        // TODO add courses (ok for cert)
        "I-SMP": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Scum Master Practitioner",
            "description": "Facilitate a productive environment to enable software engineering teams to...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Become an Agile Leader with Scrum Master Practitioner Certificate",
                "description": `The Scrum Master Practitioner Certificate program provides an in-depth understanding of Agile and Scrum methodologies. It prepares you to effectively manage and lead projects in fast-paced, collaborative environments.\n\n    Learn to facilitate daily stand-ups, sprint reviews, and retrospectives, while maintaining the overall productivity and motivation of the team. The course covers essential Scrum artifacts and roles, providing a comprehensive framework for agile project management.\n\n    The program is ideal for professionals seeking to transition into leadership roles in software development and IT project management. Through practical exercises and real-world scenarios, you'll develop the skills necessary to guide teams towards successful project completion and continuous improvement in the agile landscape.`
            }
        },
        // TODO add courses (ok for cert)
        "I-UGDF": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Unity Game Developer Fundamentals",
            "description": "Develop and deploy games across mobile, desktop, VR/AR, consoles, or the web, and...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Craft Engaging Gaming Experiences with Unity Game Developer Fundamentals",
                "description": `This certificate program offers a comprehensive introduction to game development using Unity, a leading game engine. Learn the fundamentals of game design, including 3D modeling, animation, scripting, and user interface design.\n\n    The course emphasizes hands-on learning as you work on projects ranging from simple 2D games to immersive 3D experiences. Understand the principles of game physics, interactive gameplay elements, and optimizing performance for various platforms.\n\n    Whether you're a beginner or an aspiring game developer, this program provides the foundational skills needed to start creating your own games. Upon completion, you'll have a portfolio of games and a strong understanding of the Unity engine, ready to enter the exciting world of game development.`
            }
        },
        // TODO add maybe: Certificate: Web Application Deployment and Support
        // TODO add courses (ok for cert)
        "I-UGDAP": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Unity Game Developer Advanced Practices",
            "description": "Advanced practices in Unity 3D such as shaders, AI, and...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Elevate Your Game Development Skills with Advanced Unity Techniques",
                "description": `The Unity Game Developer Advanced Practices certificate takes your game development skills to the next level. Dive deep into advanced topics like custom shaders, AI programming, networked game play, and virtual reality development.\n\n    This course is designed for individuals with a basic understanding of Unity who want to specialize in specific areas of game development. Learn from industry veterans about cutting-edge techniques in graphics, physics, and game design.\n\n    Hands-on projects will have you applying these new skills to create more complex and engaging game experiences. This advanced program not only enhances your technical skills but also prepares you to tackle challenging roles in the gaming industry.`
            }
        },
        // TODO add courses (ok for cert)
        "I-GFA": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "Google Firebase Architect",
            "description": "Google's firebase framework allows for rapid development and deployment...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Become an Expert in Rapid App Development with Google Firebase",
                "description": `The Google Firebase Architect certificate program equips you with the skills to build and deploy robust web and mobile applications using Firebase, Google's comprehensive app development platform. Learn how to leverage Firebase's real-time database, authentication, analytics, and hosting services to create dynamic, scalable applications.\n\n    This course offers a blend of theoretical knowledge and practical application, teaching you to integrate Firebase services into your development workflow. Learn how to manage user data securely, understand user behavior with analytics, and deploy applications seamlessly.\n\n    Ideal for developers looking to specialize in Firebase or enhance their app development toolkit, this program provides hands-on experience in building real-world applications. By the end of the course, you'll have mastered Firebase's suite of tools and services, making you a valuable asset in the field of application development.`
            }
        },
        // TODO add courses (ok for cert)
        "I-CA": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "CompTIA A+",
            "description": "The CompTIA A+ certification is designed to help you land an entry-level position in IT by ensuring...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Lay the Foundation for a Successful IT Career with CompTIA A+",
                "description": `CompTIA A+ certification is a foundational course for those looking to begin a career in IT. This program covers essential IT skills and knowledge needed to perform tasks on personal computers, mobile devices, laptops, and operating systems.\n\n    The course is structured to provide both theoretical and practical understanding of hardware, networking, security, and troubleshooting. Learn how to install, maintain, customize, and operate personal computers.\n\n    This certificate is ideal for entry-level IT professionals or those looking to validate their existing skills. The hands-on approach ensures that you are well-equipped to handle various IT scenarios, making you a valuable asset in the tech industry.`
            }
        },
        // TODO add courses (ok for cert)
        "I-CP": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "CompTIA Project+",
            "description": "CompTIA Project+ is designed to teach IT pros the entry-level skills they need to...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Master Project Management in IT with CompTIA Project+",
                "description": `CompTIA Project+ certification focuses on equipping IT professionals with the fundamental project management skills necessary to effectively plan, implement, and complete IT projects. The course covers essential concepts in project lifecycle, resource management, documentation, and stakeholder communication.\n\n    This program is tailored for IT professionals who are looking to enhance their project management skills or transition into a project management role. The curriculum includes best practices in managing small to medium-sized projects within the IT domain.\n\n    By completing this certification, you gain the ability to lead IT projects confidently, ensuring they are completed within budget, on time, and meeting project specifications. It's an ideal stepping stone for IT professionals aiming for a career in project management.`
            }
        },
        // TODO add courses (ok for cert)
        "I-CD": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "CompTIA Data+",
            "description": "Support of data-driven decisions through mining and manipulating data to...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Unlock the Power of Data with CompTIA Data+",
                "description": `CompTIA Data+ certification is designed to provide foundational knowledge and skills in data analysis, management, and interpretation. This course emphasizes the importance of data in decision-making and covers various aspects of data analytics including data mining, manipulation, and visualization.\n\n    Learn to analyze and interpret complex datasets, identify trends, and make data-driven decisions. The program also covers data governance and compliance, ensuring you understand how to handle data responsibly and ethically.\n\n    Ideal for professionals looking to enter the field of data analytics or enhance their existing data management skills, this course provides practical experience in working with real-world data sets. It prepares you for roles that require a solid understanding of data analytics, making you an asset in any data-driven organization.`
            }
        },
        // TODO add courses (ok for cert)
        "I-CC": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "CompTIA Cloud+",
            "description": "CompTIA Cloud+ shows you have the expertise needed to deploy and automate secure cloud environments and...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Specialize in Cloud Computing with CompTIA Cloud+",
                "description": `CompTIA Cloud+ certification is designed for IT professionals seeking to develop and validate their cloud computing skills. The course covers cloud models, virtualization, infrastructure, security, resource management, and business continuity.\n\n    Learn how to implement and maintain cloud technologies and infrastructures, including the deployment and automation of secure cloud environments. The program focuses on a mix of cloud computing and virtualization technologies skills.\n\n    This certificate is ideal for IT professionals looking to specialize in cloud technology or enhance their skills in cloud infrastructure management. By the end of the course, you will have a thorough understanding of cloud computing and the ability to manage cloud-based services effectively.`
            }
        },
        // TODO add courses (ok for cert)
        "I-CN": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "CompTIA Net+",
            "description": "Learn to troubleshoot, configure and manage both wired and wireless networks found in...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Build a Strong Networking Foundation with CompTIA Net+",
                "description": `CompTIA Net+ certification is a comprehensive course designed to teach the skills needed to troubleshoot, configure, and manage networks. It covers various types of networks, protocols, and security standards.\n\n    The course offers a deep dive into the fundamentals of networking, including wired and wireless networks, and the latest technologies in network infrastructure. Learn how to manage network devices and implement network security, standards, and protocols.\n\n    This certification is suited for professionals looking to establish a career in network administration or enhance their networking skills. The hands-on approach ensures that you gain practical experience in managing and securing networks, preparing you for real-world networking scenarios.`
            }
        },
        // TODO add courses (ok for cert)
        "I-CS": {
            "type": "INFORMATION TECHNOLOGY",
            "typImg": itIcon,
            "name": "CompTIA Sec+",
            "description": "Install and configure systems to secure applications, networks, and devices...",
            "degreeLevel": "certificate",
            "programTypes": ["it"],
            "detailInformation": {
                "title": "Secure IT Systems and Networks with CompTIA Sec+",
                "description": `CompTIA Sec+ certification provides a thorough understanding of the principles of cybersecurity and best practices. The course is designed to equip you with the skills to install, configure, and secure applications, networks, and devices.\n\n    Dive into topics such as network security, compliance, operational security, vulnerabilities, threats, and data security. Learn how to identify and mitigate security risks and implement security protocols.\n\n    This certification is ideal for IT professionals who are looking to specialize in cybersecurity or enhance their understanding of IT security. By the end of the course, you will be well-equipped to handle the security challenges in today's digital landscape, making you a vital asset in any IT security team.`
            }
        },
        // Courses from Bachelor of Science, Nursing
        "H-NRtBOBS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Nursing (RN-to-BSN Online) – B.S.",
            "description": "A BSN degree program for registered nurses (RNs) seeking the added...",
            "degreeLevel": "bachelors",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Advance Your Nursing Career with an Online RN-to-BSN Program",
                "description": `The RN-to-BSN program is designed for registered nurses looking to further their education and advance their careers. This online program offers flexibility for working professionals, focusing on enhancing clinical reasoning and analytical skills.\n\n    Gain a deeper understanding of patient care, healthcare systems, and evidence-based practice. The curriculum covers critical areas such as community health, leadership, and management in nursing.\n\n    Completing this BSN program equips you with advanced nursing skills and opens up opportunities for leadership roles in healthcare. The program is structured to help you balance work, life, and education, making it ideal for nurses seeking career advancement without pausing their professional lives.`
            },
            courses: {
                "term1": [
                  "C494"
                ],
                "term2": [
                  "D235",
                  "C784",
                  "D269",
                  "D312"
                ],
                "term3": [
                  "D268",
                  "D313",
                  "C180",
                  "D198"
                ],
                "term4": [
                  "C273",
                  "D311",
                  "D266",
                  "D202"
                ],
                "term5": [
                  "D218",
                  "D219",
                  "D236",
                  "D220"
                ],
                "term6": [
                  "D221",
                  "D222",
                  "D223",
                  "D224"
                ],
                "term7": [
                  "D225",
                  "D226"
                ],
              }
        },
        "H-HaHSBS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Health and Human Services – B.S.",
            "description": "A health degree program for students who are committed to making a...",
            "degreeLevel": "bachelors",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Empower Communities with a Degree in Health and Human Services",
                "description": `The B.S. in Health and Human Services prepares students to address the needs of individuals, families, and communities. The program emphasizes a holistic approach to health, focusing on prevention, wellness, and the social determinants of health.\n\n    Develop skills in case management, advocacy, and community engagement. The curriculum integrates theory and practice, preparing you to work in diverse settings such as community organizations, healthcare facilities, and government agencies.\n\n    Graduates of this program are equipped to create meaningful change, promoting health and well-being across various populations. This degree paves the way for a fulfilling career in service and advocacy within the healthcare sector.`
            },
            courses: {
                "term1": [
                  "D389",
                  "D269",
                  "D268",
                  "C458"
                ],
                "term2": [
                  "D265",
                  "D390",
                  "D391",
                  "C190"
                ],
                "term3": [
                  "D202",
                  "D458",
                  "D392",
                  "C180"
                ],
                "term4": [
                  "C784",
                  "D393",
                  "D394",
                  "D203"
                ],
                "term5": [
                  "C804",
                  "D395",
                  "D396",
                  "D397"
                ],
                "term6": [
                  "D398",
                  "D399",
                  "D400",
                  "C805"
                ],
                "term7": [
                  "D401",
                  "D402",
                  "D403",
                  "D404"
                ],
                "term8": [
                  "D254",
                  "D406",
                  "D407"
                ],
                "termHealthcare": [
                  "D405"
                ],
                "term": [
                  "",
                  ""
                ],
                "term9": [
                  "D408",
                  "D409",
                  "D410"
                ]
              }
        },
        "H-HIMBS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Health Information Management – B.S.",
            "description": "A program designed for future leaders in HIM...",
            "degreeLevel": "bachelors",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Lead the Future of Healthcare with a Degree in Health Information Management",
                "description": `The B.S. in Health Information Management (HIM) combines healthcare, business, and information technology. It prepares you to manage health data and information resources in various healthcare settings.\n\n    Learn the latest in health informatics and information management, including data analytics, electronic health records, privacy, and security. The program focuses on the strategic management of health information and prepares you to play a critical role in the delivery of healthcare services.\n\n    As a graduate, you will be eligible for roles such as health information manager, data analyst, or compliance officer in healthcare facilities. This degree provides a strong foundation for a career in the rapidly evolving field of health information technology.`
            },
            courses: {
                "term1": [
                  "D389",
                  "D269",
                  "C799",
                  "C784"
                ],
                "term2": [
                  "D265",
                  "D268",
                  "C190",
                  "D270"
                ],
                "term3": [
                  "C804",
                  "D203",
                  "C816",
                  "C180"
                ],
                "term4": [
                  "C802",
                  "C803",
                  "D198",
                  "C957"
                ],
                "term5": [
                  "D190",
                  "C810",
                  "C801",
                  "C963"
                ],
                "term6": [
                  "C805",
                  "D398",
                  "C815",
                  "C808"
                ],
                "term7": [
                  "D254",
                  "C807",
                  "C811",
                  "C812"
                ],
                "term8": [
                  "C813",
                  "D033",
                  "D255",
                  "D257"
                ],
                "term9": [
                  "D256",
                  "D258",
                  "D259",
                  "D260"
                ]
              }
        },
        "H-HABS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Healthcare Administration – B.S.",
            "description": "You can become a healthcare industry leader:...",
            "degreeLevel": "bachelors",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Become a Leader in Healthcare with a Degree in Healthcare Administration",
                "description": `The B.S. in Healthcare Administration program offers the knowledge and skills needed to manage and lead healthcare organizations effectively. Learn about healthcare policy, finance, ethics, and law, as well as how to navigate the complex healthcare environment.\n\n    Develop leadership and management skills that are essential for running healthcare facilities, including hospitals, clinics, and community health organizations. The program combines theoretical knowledge with practical application, preparing you for real-world challenges in healthcare management.\n\n    Graduates are well-equipped to pursue careers in healthcare management and administration, playing vital roles in shaping the future of healthcare delivery. This degree opens up opportunities in various healthcare settings, from small clinics to large hospital systems.`
            },
            courses: {
                "term1": [
                  "C715",
                  "D072",
                  "C430",
                  "C168"
                ],
                "term2": [
                  "C483",
                  "C455",
                  "C425",
                  "D078"
                ],
                "term3": [
                  "C720",
                  "C456",
                  "C426",
                  "D082"
                ],
                "term4": [
                  "C464",
                  "D196",
                  "C427",
                  "D081"
                ],
                "term5": [
                  "D100",
                  "C121",
                  "D076",
                  "C428",
                  "D253"
                ],
                "term6": [
                  "C716",
                  "C955",
                  "D089",
                  "C429"
                ],
                "term7": [
                  "C100",
                  "D077",
                  "C957",
                  "C431"
                ],
                "term8": [
                  "D080",
                  "C273",
                  "C165",
                  "C236"
                ],
                "term9": [
                  "C232",
                  "D361",
                  "C723",
                  "C722"
                ],
                "term10": [
                  "C432",
                  "C721",
                  "C439"
                ]
              }
        },
        "H-NPBS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Nursing (Prelicensure) – B.S.",
            "description": "A one-of-a-kind nursing program that prepares you to be an RN and a...",
            "degreeLevel": "bachelors",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Start Your Nursing Career with a Prelicensure B.S. in Nursing",
                "description": `The B.S. in Nursing (Prelicensure) program is designed for individuals seeking to become registered nurses. This comprehensive program covers all essential aspects of nursing, from basic to advanced patient care, along with clinical decision-making skills.\n\n    Experience hands-on learning in clinical settings, coupled with classroom theoretical instruction. The curriculum is structured to provide a solid foundation in medical, surgical, and specialty nursing practice.\n\n    Graduates of this program will be prepared to take the NCLEX-RN exam and enter the nursing profession with confidence. This degree is a stepping stone for a rewarding career in healthcare, providing compassionate and competent care to diverse populations.`
            },
            courses: {
                "term1": [
                  "D265",
                  "D269",
                  "C165",
                  "D268"
                ],
                "term2": [
                  "D425",
                  "D312",
                  "C180",
                  "D266",
                  "C784"
                ],
                "term3": [
                  "D313",
                  "D311"
                ],
                "term4": [
                  "C957",
                  "D202",
                  "D236"
                ]
              }
        },
        "H-NLMBtMMS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Nursing – Leadership & Management (BSN-to-MSN) – M.S.",
            "description": "For registered nurses with a bachelor's degree who are ready for...",
            "degreeLevel": "masters",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Elevate Nursing Practice with a Master's in Leadership and Management",
                "description": `The BSN-to-MSN in Nursing Leadership and Management is tailored for RNs who aim to take on leadership roles in healthcare. This program builds on your BSN foundation, focusing on advanced leadership, management, and organizational skills.\n\n    Study healthcare policy, finance, and quality improvement, and learn to lead teams effectively in diverse healthcare settings. The program emphasizes the development of strategic thinking and decision-making skills necessary for nursing leaders.\n\n    This MSN degree prepares you for high-level roles such as nurse manager, healthcare administrator, or policy maker. It is ideal for nurses aspiring to lead and make impactful changes in the healthcare system.`
            },
            courses: {
                "term1": [
                  "D024",
                  "D025",
                  "D026",
                  "D029"
                ],
                "term2": [
                  "D030",
                  "D031",
                  "D027"
                ],
                "term3": [
                  "D028",
                  "D155",
                  "D156",
                  "D157"
                ],
                "term4": [
                  "D158",
                  "D159",
                  "D160",
                  "D161"
                ]
              }
        },
        "H-NNIBtMMS": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Nursing – Nursing Informatics (BSN-to-MSN) – M.S.",
            "description": "A nursing degree program that empowers data-driven nurses who already have...",
            "degreeLevel": "masters",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Transform Healthcare with a Master's in Nursing Informatics",
                "description": `The BSN-to-MSN in Nursing Informatics blends nursing science with information technology to improve patient care and outcomes. This program equips you with the skills to manage healthcare data and use technology to enhance nursing practice.\n\n    Learn about health informatics, data analysis, and information management systems. The curriculum focuses on the application of technology in healthcare settings, including electronic health records and patient care technologies.\n\n    This degree prepares you for roles such as clinical informatics nurse, health IT consultant, or informatics analyst. It is ideal for nurses who want to play a pivotal role in the digital transformation of healthcare and improve patient care through technology.`
            },
            courses: {
                "term1": [
                  "D024",
                  "D029",
                  "D025",
                  "D026"
                ],
                "term2": [
                  "D030",
                  "D031",
                  "D027"
                ],
                "term3": [
                  "D028",
                  "C790",
                  "C797",
                  "C792"
                ],
                "term4": [
                  "C798",
                  "C854",
                  "C855"
                ]
              }
        },
        "H-PMHNPBtMMSN": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Psychiatric Mental Health Nurse Practitioner (BSN-to-MSN) – M.S. Nursing",
            "description": "This program for BSNs who have an active, unencumbered RN license prepares...",
            "degreeLevel": "masters",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Specialize in Mental Health Care with a MSN in Psychiatric Nursing",
                "description": `The BSN-to-MSN program in Psychiatric Mental Health Nurse Practitioner prepares RNs for specialized roles in mental health care. Gain expertise in diagnosing and treating psychiatric disorders, providing therapy, and prescribing medication.\n\n    Learn evidence-based practices for managing mental health issues across the lifespan. The curriculum covers psychotherapy, psychopharmacology, and holistic approaches to mental health care.\n\n    This program prepares graduates for advanced practice roles in diverse settings, including mental health clinics, hospitals, and private practice. It’s ideal for nurses looking to specialize in psychiatric and mental health nursing, providing compassionate and comprehensive care to those with mental health needs.`
            },
            courses: {
                "term1": [
                  "D024",
                  "D115",
                  "D025"
                ],
                "term2": [
                  "D026",
                  "D029",
                  "D116"
                ],
                "term3": [
                  "D030",
                  "D031",
                  "D117"
                ],
                "term4": [
                  "D343",
                  "D344",
                  "D345"
                ],
                "term5": [
                  "D346",
                  "D347",
                  "D348"
                ],
                "term6": [
                  "D349",
                  "D350"
                ]
              }
        },
        "H-MoHA": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Master of Healthcare Administration",
            "description": "A master's focused on managing comprehensive, value-based care, directly...",
            "degreeLevel": "masters",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Lead Healthcare Organizations with a Master's in Healthcare Administration",
                "description": `The Master of Healthcare Administration program is designed for aspiring healthcare leaders. Learn to manage comprehensive, value-based care and navigate complex healthcare systems.\n\n    The curriculum covers healthcare policy, law, ethics, financial management, and technology. Gain skills in strategic planning, decision-making, and organizational leadership.\n\n    This degree prepares you for executive roles in healthcare, equipping you to improve healthcare delivery and outcomes. Ideal for individuals looking to lead hospitals, healthcare systems, and public health organizations.`
            },
            courses: {
                "term1": [
                  "D509",
                  "D510",
                  "D511"
                ],
                "term2": [
                  "D512",
                  "D513",
                  "D514"
                ],
                "term3": [
                  "D515",
                  "D516",
                  "D517"
                ],
                "term4": [
                  "D518",
                  "D519",
                  "D520"
                ]
              }
        },
        "H-MHM": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "MBA Healthcare Management",
            "description": "Prepare for a career leading private or public healthcare organizations....",
            "degreeLevel": "masters",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Drive Innovation in Healthcare with an MBA in Healthcare Management",
                "description": `The MBA in Healthcare Management combines business acumen with healthcare expertise. Develop skills in strategic management, finance, and healthcare operations.\n\n    Learn to lead healthcare organizations effectively, focusing on innovation, quality care, and efficiency. The program addresses the unique challenges of the healthcare industry, including regulatory environments and ethical considerations.\n\n    Graduates are prepared for leadership roles in various healthcare settings, equipped with the knowledge to make impactful decisions in healthcare management. Ideal for professionals seeking to advance their career in healthcare leadership.`
            },
            courses: {
                "term1": [
                  "C200",
                  "C215",
                  "AFT2"
                ],
                "term2": [
                  "C213",
                  "C214",
                  "C206"
                ],
                "term3": [
                  "C207",
                  "C212",
                  "AMT2"
                ],
                "term4": [
                  "C211",
                  "C219"
                ]
              }
        },
        // Removed
        // "H-NEPMsC": {
        //     "type": "HEALTHCARE",
        //     "typImg": healthcareIcon,
        //     "name": "Nursing Education – Post-Master's Certificate",
        //     "description": "A specialty certificate in nursing education for nurses with MSNs....",
        //     "degreeLevel": "masters",
        //     "programTypes": ["healthcare"],
        //     "detailInformation": {
        //         "title": "Excel in Nursing Education with a Post-Master's Certificate",
        //         "description": `This Post-Master's Certificate in Nursing Education is designed for nurses with MSNs to specialize in educational roles. Develop skills in curriculum design, teaching strategies, and educational technology.\n\n    Learn about the principles of adult education, assessment methods, and program evaluation. The program prepares you to educate the next generation of nurses in academic and clinical settings.\n\n    Ideal for MSN-prepared nurses aspiring to become nursing educators, clinical instructors, or faculty members. This certificate opens doors to roles in nursing education and training, shaping future nursing professionals.`
        //     }
        // },
        // "H-PMHNPPMsC": {
        //     "type": "HEALTHCARE",
        //     "typImg": healthcareIcon,
        //     "name": "Psychiatric Mental Health Nurse Practitioner – Post-Master's Certificate",
        //     "description": "This program is for current RNs who already have earned an MSN and are...",
        //     "degreeLevel": "masters",
        //     "programTypes": ["healthcare"],
        //     "detailInformation": {
        //         "title": "Advance Your Practice in Psychiatric Nursing with a Post-Master's Certificate",
        //         "description": `This Post-Master's Certificate in Psychiatric Mental Health Nurse Practitioner is for MSN-prepared RNs looking to specialize in psychiatric care. Develop advanced skills in mental health assessment, diagnosis, and treatment.\n\n    The program covers psychotherapeutic techniques, psychopharmacology, and care of patients with psychiatric disorders. Learn to provide holistic mental health care across various settings.\n\n    Ideal for nurses seeking to expand their practice to include psychiatric and mental health services. This certificate prepares you for advanced practice roles, enhancing your ability to meet the mental health needs of diverse populations.`
        //     },
        //     courses: {
        //         "term1": [
        //           "D024",
        //           "D115",
        //           "D025"
        //         ],
        //         "term2": [
        //           "D026",
        //           "D029",
        //           "D116"
        //         ],
        //         "term3": [
        //           "D030",
        //           "D031",
        //           "D117"
        //         ],
        //         "term4": [
        //           "D343",
        //           "D344",
        //           "D345"
        //         ],
        //         "term5": [
        //           "D346",
        //           "D347",
        //           "D348"
        //         ],
        //         "term6": [
        //           "D349",
        //           "D350"
        //         ]
        //       }
        // },
        // Removed
        // "H-NLaMPMsC": {
        //     "type": "HEALTHCARE",
        //     "typImg": healthcareIcon,
        //     "name": "Nursing Leadership and Management – Post-Master's Certificate",
        //     "description": "A certificate for registered nurses with a master's degree in nursing who...",
        //     "degreeLevel": "masters",
        //     "programTypes": ["healthcare"],
        //     "detailInformation": {
        //         "title": "Elevate Your Leadership in Nursing with a Post-Master's Certificate",
        //         "description": `The Post-Master's Certificate in Nursing Leadership and Management is for nurses with MSNs aiming to take on leadership roles. Enhance your skills in managing healthcare teams, policy-making, and strategic planning.\n\n    The program focuses on healthcare finance, human resources, and organizational behavior. Learn to lead with confidence in various healthcare settings, improving patient care and operational efficiency.\n\n    Ideal for nurses seeking to advance into managerial or executive positions. This certificate prepares you to lead nursing teams, manage healthcare services, and contribute to the broader healthcare system.`
        //     }
        // },
        "H-FNPPMsC": {
            "type": "HEALTHCARE",
            "typImg": healthcareIcon,
            "name": "Family Nurse Practitioner – MS",
            "description": "This is a program for individuals who already have an BSN and are looking...",
            "degreeLevel": "masters",
            "programTypes": ["healthcare"],
            "detailInformation": {
                "title": "Specialize in Family Care with a Master's in Family Nurse Practitioner",
                "description": `The MS in Family Nurse Practitioner is for BSN-prepared nurses who wish to focus on family care. The program provides advanced training in primary care for patients of all ages.\n\n    Learn to manage acute and chronic illnesses, conduct comprehensive health assessments, and provide preventive care. The curriculum covers pharmacology, pathophysiology, and clinical decision-making.\n\n    This certificate prepares nurses for advanced practice roles in family health, offering the skills to provide holistic care to individuals and families in diverse healthcare settings.`
            },
            courses: {
                "term1": [
                  "D024",
                  "D115",
                  "D025"
                ],
                "term2": [
                  "D026",
                  "D116",
                  "D029"
                ],
                "term3": [
                  "D030",
                  "D117",
                  "D118"
                ],
                "term4": [
                  "D031",
                  "D119",
                  "D120",
                  "D121"
                ],
                "term5": [
                  "D122",
                  "D123",
                  "D124"
                ]
              }
        },
        "E-EEBA": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Elementary Education – B.A.",
            "description": "A teacher certification program for aspiring elementary teachers....",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Shape Young Minds with a Bachelor's in Elementary Education",
                "description": `The B.A. in Elementary Education is designed for individuals aspiring to become elementary school teachers. This program equips you with the skills to teach and nurture young students, covering subjects like mathematics, science, and language arts.\n\n    Learn effective teaching methodologies, classroom management, and child psychology. The curriculum includes hands-on experience through student teaching internships in diverse elementary school settings.\n\n    Graduates are prepared for teacher certification and will possess the knowledge and skills needed to make a positive impact on students' early educational experiences. Ideal for those who are passionate about shaping the foundational years of children's education.`
            },
            courses: {
                "term1": [
                  "D024",
                  "D115",
                  "D025"
                ],
                "term2": [
                  "D026",
                  "D116",
                  "D029"
                ],
                "term3": [
                  "D030",
                  "D117",
                  "D118"
                ],
                "term4": [
                  "D031",
                  "D119",
                  "D120",
                  "D121"
                ],
                "term5": [
                  "D122",
                  "D123",
                  "D124"
                ]
              }
        },
        "E-MEMGBS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Mathematics Education (Middle Grades) – B.S.",
            "description": "A teacher certification program for aspiring middle school math...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Inspire Future Mathematicians with a B.S. in Middle Grades Mathematics Education",
                "description": `This B.S. program in Mathematics Education for Middle Grades prepares you to become a mathematics teacher for grades 6-9. Focus on developing a deep understanding of middle school math concepts, pedagogy, and curriculum design.\n\n    Learn innovative teaching methods to engage and motivate young learners in mathematics. The program includes field experiences in middle school classrooms, allowing you to apply theoretical knowledge in real-world teaching scenarios.\n\n    Graduates will be qualified for middle school math teacher certification, ready to inspire the next generation of mathematicians and problem-solvers. This degree is ideal for those passionate about mathematics and educating young learners.`
            },
            courses: {
                "term1": [
                  "D097",
                  "C306",
                  "C100",
                  "C278"
                ],
                "term2": [
                  "D094",
                  "C121",
                  "C455",
                  "C165"
                ],
                "term3": [
                  "D090",
                  "C955",
                  "C646",
                  "D096"
                ],
                "term4": [
                  "D095",
                  "C280",
                  "C190",
                  "C683"
                ],
                "term5": [
                  "D091",
                  "D093",
                  "C972",
                  "C903"
                ],
                "term6": [
                  "C456",
                  "C464",
                  "D092",
                  "C963"
                ],
                "term7": [
                  "C282",
                  "C285",
                  "C730",
                  "C728"
                ],
                "term8": [
                  "C284",
                  "C965",
                  "C879",
                  "C930"
                ],
                "term9": [
                  "C315",
                  "C316",
                  "C317",
                  "C318",
                  "D148",
                  "D151",
                  "C341"
                ]
              }
        },
        "E-MESBS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Mathematics Education (Secondary) – B.S.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Educate High School Mathematicians with a B.S. in Secondary Mathematics Education",
                "description": `The B.S. in Mathematics Education for Secondary Grades prepares you to teach math to high school students. Gain a strong foundation in mathematics along with teaching strategies tailored for adolescent learners.\n\n    The curriculum covers advanced math topics, instructional planning, and assessment strategies. Engage in practicum experiences in high school settings to refine your teaching skills and classroom management.\n\n    This degree prepares you for secondary school math teacher certification, equipping you to contribute to the academic success of high school students. Ideal for individuals with a passion for mathematics and a commitment to secondary education.`
            },
            courses: {
                "term1": [
                  "D097",
                  "C278",
                  "C646",
                  "C100"
                ],
                "term2": [
                  "D094",
                  "C190",
                  "C280",
                  "C455"
                ],
                "term3": [
                  "D090",
                  "C456",
                  "C464",
                  "D096"
                ],
                "term4": [
                  "D095",
                  "C972",
                  "C362",
                  "C165"
                ],
                "term5": [
                  "C683",
                  "C283",
                  "D091",
                  "D093"
                ],
                "term6": [
                  "TQC1",
                  "C121",
                  "C656",
                  "D092"
                ],
                "term7": [
                  "C877",
                  "RKT1",
                  "C897",
                  "C730",
                  "C728"
                ],
                "term8": [
                  "QDT1",
                  "C885",
                  "C284",
                  "C879"
                ],
                "term9": [
                  "C881",
                  "C883",
                  "C932",
                  "C285"
                ],
                "term10": [
                  "C315",
                  "C316",
                  "C317",
                  "C318",
                  "D148",
                  "D151",
                  "C341"
                ]
              }
        },
        "E-SEMGBS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Science Education (Middle Grades) – B.S.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Nurture Young Scientists with a B.S. in Middle Grades Science Education",
                "description": `The B.S. in Science Education for Middle Grades is designed for those aspiring to teach science in grades 6-9. This program provides a comprehensive understanding of science subjects including biology, chemistry, physics, and earth science.\n\n    Learn effective teaching methods for middle school science, curriculum development, and classroom management. Engage in practical teaching experiences in diverse middle school settings.\n\n    Graduates will be prepared for teacher certification in middle grades science, ready to inspire and educate young students in the fascinating world of science. This degree is perfect for those who have a passion for science and teaching young adolescents.`
            },
            courses: {
                "term1": [
                  "C371",
                  "D097",
                  "C455",
                  "C165",
                  "C683"
                ],
                "term2": [
                  "D094",
                  "C278",
                  "C190",
                  "C456"
                ],
                "term3": [
                  "D090",
                  "C955",
                  "C464",
                  "D096"
                ],
                "term4": [
                  "D095",
                  "C100",
                  "C832",
                  "C121"
                ],
                "term5": [
                  "C890",
                  "D091",
                  "D093",
                  "C652"
                ],
                "term6": [
                  "RNT1",
                  "C963",
                  "D092",
                  "C894"
                ],
                "term7": [
                  "C925",
                  "C388",
                  "C902",
                  "C730"
                ],
                "term8": [
                  "C728",
                  "C965",
                  "C974",
                  "C937"
                ],
                "term9": [
                  "C319",
                  "C320",
                  "C321",
                  "C322",
                  "D150",
                  "D151",
                  "C341"
                ]
              }
        },
        "E-SESBSBS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Science Education (Secondary Biological Science) – B.S.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Teach the Wonders of Biology with a B.S. in Secondary Biological Science Education",
                "description": `The B.S. in Science Education with a focus on Secondary Biological Science prepares you to teach biology at the high school level. Dive into topics such as genetics, ecology, and human anatomy while learning pedagogical techniques specific to biological science.\n\n    The program includes coursework in biology and education, alongside hands-on teaching experiences in secondary school settings. Learn to develop engaging and informative biology curricula and effectively manage a high school classroom.\n\n    Graduates will be equipped for high school biology teacher certification, ready to inspire a new generation of students with the wonders of the biological world. Ideal for those passionate about biology and education.`
            },
            courses: {
                "term1": [
                  "C371",
                  "D097",
                  "C455",
                  "C190",
                  "C683"
                ],
                "term2": [
                  "D094",
                  "C278",
                  "C165",
                  "C955"
                ],
                "term3": [
                  "D090",
                  "C456",
                  "C464",
                  "D096"
                ],
                "term4": [
                  "D095",
                  "C832",
                  "C888",
                  "C121"
                ],
                "term5": [
                  "C100",
                  "D091",
                  "D093",
                  "C875"
                ],
                "term6": [
                  "C652",
                  "D092",
                  "C654",
                  "C388"
                ],
                "term7": [
                  "C890",
                  "C736",
                  "C900",
                  "C730"
                ],
                "term8": [
                  "C728",
                  "C940",
                  "C937"
                ],
                "term9": [
                  "C319",
                  "C320",
                  "C321",
                  "C322",
                  "D150",
                  "D151"
                ],
                "term": [
                  "C341"
                ]
              }
        },
        "E-SESCBS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Science Education (Secondary Chemistry) – B.S.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Explore the World of Chemistry with a B.S. in Secondary Chemistry Education",
                "description": `The B.S. in Science Education focusing on Secondary Chemistry equips you to become a high school chemistry teacher. Master core concepts in organic, inorganic, and physical chemistry, while learning how to effectively teach these topics.\n\n    The curriculum includes extensive chemistry coursework and educational theory, combined with practical teaching experiences in secondary schools.\n\n    Graduates will be prepared for teacher certification in high school chemistry, ready to educate and inspire students in the field of chemistry. This program is ideal for individuals passionate about chemistry and committed to educating the next generation.`
            },
            courses: {
                "term1": [
                  "C371",
                  "D097",
                  "C455",
                  "C165",
                  "C683"
                ],
                "term2": [
                  "D094",
                  "C278",
                  "C190",
                  "C373"
                ],
                "term3": [
                  "D090",
                  "C456",
                  "C374",
                  "C955"
                ],
                "term4": [
                  "D096",
                  "D095",
                  "BVT1",
                  "C464"
                ],
                "term5": [
                  "C646",
                  "C121",
                  "D091",
                  "D093"
                ],
                "term6": [
                  "C282",
                  "C100",
                  "D092",
                  "BWT1"
                ],
                "term7": [
                  "UQT1",
                  "C388",
                  "C624",
                  "C264"
                ],
                "term8": [
                  "C915",
                  "C730",
                  "C728",
                  "C941"
                ],
                "term9": [
                  "C937"
                ],
                "term10": [
                  "C319",
                  "C320",
                  "C321",
                  "C322",
                  "D150",
                  "D151",
                  "C341"
                ]
              }
        },
        "E-SESPBS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Science Education (Secondary Physics) – B.S.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Inspire Future Physicists with a B.S. in Secondary Physics Education",
                "description": `The B.S. in Science Education with a specialization in Secondary Physics prepares you to teach physics at the high school level. Gain in-depth knowledge of physics principles and theories, along with effective teaching strategies for adolescents.\n\n    The program combines rigorous physics coursework with educational psychology and pedagogical skills. Participate in practical teaching experiences in high school settings, applying your knowledge in real-world classrooms.\n\n    Upon completion, graduates will be ready for high school physics teacher certification, equipped to inspire and engage students in the study of physics. Ideal for those with a passion for physics and a desire to teach at the secondary level.`
            },
            courses: {
                "term1": [
                  "C371",
                  "D097",
                  "C278",
                  "C455",
                  "C955",
                  "C341"
                ],
                "term2": [
                  "D094",
                  "C190",
                  "C165",
                  "C683"
                ],
                "term3": [
                  "D090",
                  "C646",
                  "C876"
                ],
                "term4": [
                  "C456",
                  "D096",
                  "D095",
                  "C832"
                ],
                "term5": [
                  "C464",
                  "C282",
                  "BYT1",
                  "D091"
                ],
                "term6": [
                  "D093",
                  "C100",
                  "C283",
                  "BZT1"
                ],
                "term7": [
                  "D092",
                  "C121",
                  "DPT1",
                  "C388"
                ],
                "term8": [
                  "C738",
                  "C901",
                  "C730",
                  "C728"
                ],
                "term9": [
                  "C943",
                  "C937"
                ],
                "term10": [
                  "C319",
                  "C320",
                  "C321",
                  "C322",
                  "D150",
                  "D151"
                ]
              }
        },
        "E-SESESBS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Science Education (Secondary Earth Science) – B.S.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Explore the Planet with a B.S. in Secondary Earth Science Education",
                "description": `The B.S. in Science Education with a focus on Secondary Earth Science prepares you to teach Earth Science at the high school level. Dive into geology, meteorology, oceanography, and environmental science, while learning pedagogical techniques for secondary education.\n\n    The program includes a mix of earth science topics and educational theory, along with practical teaching experiences in secondary schools. Learn to inspire students with the wonders of our planet and the complexities of its environmental systems.\n\n    Graduates will be prepared for teacher certification in high school earth science, ready to educate and inspire the next generation of environmental stewards and scientists. Ideal for those passionate about earth sciences and education.`
            },
            courses: {
                "term1": [
                  "C371",
                  "D097",
                  "C455",
                  "C165",
                  "C683"
                ],
                "term2": [
                  "D094",
                  "C190",
                  "C278",
                  "C456"
                ],
                "term3": [
                  "D090",
                  "C464",
                  "C955",
                  "D096"
                ],
                "term4": [
                  "D095",
                  "C832",
                  "C121",
                  "C890"
                ],
                "term5": [
                  "D091",
                  "D093",
                  "RNT1",
                  "C100"
                ],
                "term6": [
                  "C649",
                  "C892",
                  "D092",
                  "C894"
                ],
                "term7": [
                  "C388",
                  "C263",
                  "C898",
                  "C730"
                ],
                "term8": [
                  "C728",
                  "C942",
                  "C937"
                ],
                "term9": [
                  "C319",
                  "C320",
                  "C321",
                  "C322",
                  "D150",
                  "D151",
                  "C341"
                ]
              }
        },
        "E-SEMtMBA": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Special Education (Mild to Moderate) – B.A.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Empower Diverse Learners with a B.A. in Special Education",
                "description": `The B.A. in Special Education (Mild to Moderate) is tailored for aspiring teachers who want to specialize in special education. Focus on strategies to support students with mild to moderate learning disabilities in inclusive and specialized settings.\n\n    Learn about individualized education plans (IEPs), adaptive teaching methods, and behavior management. The program includes hands-on experience through student teaching internships in diverse educational environments.\n\n    Graduates will be qualified for teacher certification in special education, equipped to make a significant difference in the lives of students with special needs. This degree is perfect for those committed to inclusive and equitable education for all learners.`
            },
            courses: {
                "term1": [
                  "D269",
                  "D090",
                  "D268",
                  "D199"
                ],
                "term2": [
                  "D097",
                  "D270",
                  "D125",
                  "D198",
                  "D267"
                ],
                "term3": [
                  "D094",
                  "D096",
                  "C165",
                  "C683"
                ],
                "term4": [
                  "D126",
                  "C190",
                  "D095",
                  "D002"
                ],
                "term5": [
                  "D127",
                  "D091",
                  "D093",
                  "D001"
                ],
                "term6": [
                  "D092",
                  "D003",
                  "D004",
                  "C909"
                ],
                "term7": [
                  "C365",
                  "C109",
                  "C730",
                  "D010"
                ],
                "term8": [
                  "C970",
                  "D005",
                  "Mild",
                  "Mild",
                  "D009"
                ],
                "termElementary": [
                  "D006"
                ],
                "termSecondary": [
                  "D007"
                ],
                "term9": [
                  "D012",
                  "D013",
                  "D014",
                  "D015"
                ],
                "term10": [
                  "D149",
                  "D151",
                  "C340"
                ]
              }
        },
        "E-SEaEEDLBA": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Special Ed and Elementary Ed (Dual Licensure) – B.A.",
            "description": "A teaching degree and teacher certification program for aspiring...",
            "degreeLevel": "bachelors",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Dual Expertise in Elementary and Special Education with a B.A.",
                "description": `The B.A. in Special Education and Elementary Education (Dual Licensure) program is designed for those seeking to teach both elementary education and special education. Gain comprehensive skills to teach in diverse and inclusive classrooms.\n\n    The curriculum includes foundational teaching methodologies for elementary education alongside specialized training for special education. Participate in field experiences in both elementary and special education settings.\n\n    Graduates will be prepared for dual certification, ready to provide high-quality education to a wide range of students, including those with special needs. Ideal for aspiring educators dedicated to a holistic and inclusive teaching approach.`
            },
            courses: {
                "term1": [
                  "D269",
                  "D090",
                  "D265",
                  "D268"
                ],
                "term2": [
                  "D097",
                  "D270",
                  "D125",
                  "D199",
                  "D267"
                ],
                "term3": [
                  "D094",
                  "D096",
                  "C165",
                  "C683"
                ],
                "term4": [
                  "D198",
                  "D126",
                  "D095",
                  "C963"
                ],
                "term5": [
                  "D002",
                  "D127",
                  "C190",
                  "D091"
                ],
                "term6": [
                  "D001",
                  "D093",
                  "D092",
                  "D003"
                ],
                "term7": [
                  "D004",
                  "C909",
                  "C365",
                  "C109"
                ],
                "term8": [
                  "C108",
                  "C104",
                  "C367",
                  "C105"
                ],
                "term9": [
                  "C732",
                  "C970",
                  "D005",
                  "Mild",
                  "Mild"
                ],
                "termElementary": [
                  "D006"
                ],
                "termSecondary": [
                  "D007"
                ],
                "term10": [
                  "C934"
                ],
                "termSpecial": [
                  "C311",
                  "C312",
                  "C313",
                  "C314"
                ],
                "term11": [
                  "1",
                  "3",
                  "4",
                  "6",
                  "D147",
                  "D151",
                  "C340"
                ]
              }
        },
        "E-ELMS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Educational Leadership – M.S.",
            "description": "A master's degree for current teachers looking to move into a...",
            "degreeLevel": "masters",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Transform Education Systems with an M.S. in Educational Leadership",
                "description": `The M.S. in Educational Leadership is designed for current teachers aiming to move into leadership roles within educational settings. Focus on developing skills in strategic planning, staff management, policy implementation, and educational reform.\n\n    Explore leadership theories, school finance, and legal aspects of educational administration. The program includes practical leadership experiences, preparing you to make impactful changes in education.\n\n    Graduates will be equipped to take on roles such as school principal, district administrator, or educational consultant, driving positive change in educational systems. Ideal for educators with a vision for improving educational practices and policies.`
            },
            courses: {
                "term1": [
                  "D016",
                  "D017",
                  "D020"
                ],
                "term2": [
                  "D019",
                  "D021",
                  "D018"
                ],
                "term3": [
                  "D022",
                  "D023",
                  "D036"
                ],
                "term4": [
                  "D034",
                  "D035",
                  "D037"
                ],
                "term5": [
                  "D038"
                ]
              }
        },
        "E-CaIMS": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Curriculum and Instruction – M.S.",
            "description": "A master's degree for those who have educational experience and...",
            "degreeLevel": "masters",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Advance Curricular Excellence with an M.S. in Curriculum and Instruction",
                "description": `The M.S. in Curriculum and Instruction is geared towards individuals with educational experience who wish to enhance their skills in curriculum design and instructional strategies. Focus on developing and implementing effective curricula that meet diverse learning needs.\n\n    Delve into instructional theory, assessment methods, and educational research. Learn to create engaging and inclusive learning experiences that promote student success.\n\n    Graduates will be ready to take on roles as curriculum specialists, instructional coordinators, or educational leaders, impacting teaching and learning practices positively. This degree is ideal for educators seeking to deepen their expertise in curriculum development and instructional design.`
            },
            courses: {
                "term1": [
                  "D182",
                  "D183",
                  "D179"
                ],
                "term2": [
                  "D187",
                  "D184",
                  "D185"
                ],
                "term3": [
                  "D186",
                  "D188",
                  "D180"
                ],
                "term4": [
                  "D181"
                ]
              }
        },
        // Removed
        // "E-ETaIDME": {
        //     "type": "EDUCATION",
        //     "typImg": educationIcon,
        //     "name": "Education Technology and Instructional Design – M.Ed.",
        //     "description": "The M.Ed. in Education Technology and Instructional Design from Whalley is for...",
        //     "degreeLevel": "masters",
        //     "programTypes": ["education"],
        //     "detailInformation": {
        //         "title": "Redefine Learning with a M.Ed. in Education Technology and Instructional Design",
        //         "description": `The M.Ed. in Education Technology and Instructional Design is for those looking to integrate technology into educational settings effectively. Learn about the latest educational technologies, digital tools, and instructional design principles.\n\n    Focus on creating technology-enhanced learning experiences, digital content development, and online education strategies. The program combines theoretical knowledge with practical application, including project-based learning.\n\n    Graduates will be prepared to take on roles as instructional designers, educational technologists, or e-learning coordinators, enhancing learning experiences through technology. Ideal for educators and professionals passionate about the intersection of education and technology.`
        //     }
        // },
        "E-MEMA": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Mathematics Education – M.A.",
            "description": "A master's degree for current teachers who want to specialize in...",
            "degreeLevel": "masters",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Specialize in Mathematics Teaching with an M.A. in Mathematics Education",
                "description": `The M.A. in Mathematics Education is designed for current teachers who want to specialize in mathematics teaching. Deepen your understanding of mathematical concepts and how to effectively teach them at various educational levels.\n\n    Explore advanced topics in mathematics education, curriculum development, and pedagogical strategies tailored to math instruction. Engage in research and analysis to improve mathematics teaching practices.\n\n    Graduates will be prepared to excel in roles such as mathematics teacher, curriculum coordinator, or mathematics education consultant, contributing to the advancement of mathematics education. Ideal for educators with a passion for mathematics and a desire to enhance their teaching expertise.`
            },
            courses: {
                "term1": [
                  "D269",
                  "D090",
                  "D265",
                  "D268",
                  "1",
                  "D307",
                  "D168"
                ],
                "term2": [
                  "D097",
                  "D270",
                  "D125",
                  "D199",
                  "D267",
                  "D169",
                  "D170",
                  "D171",
                  "D172"
                ],
                "term3": [
                  "D094",
                  "D096",
                  "C165",
                  "C683",
                  "D173",
                  "OPT2",
                  "D163",
                  "D162"
                ],
                "term4": [
                  "D198",
                  "D126",
                  "D095",
                  "C963",
                  "C880",
                  "C882",
                  "C884",
                  "OOT2"
                ],
                "term5": [
                  "D002",
                  "D127",
                  "C190",
                  "D091",
                  "C933"
                ],
                "term6": [
                  "D001",
                  "D093",
                  "D092",
                  "D003",
                  "D134",
                  "D135",
                  "D136",
                  "D137",
                  "C914",
                  "C347"
                ],
                "term7": [
                  "D004",
                  "C909",
                  "C365",
                  "C109"
                ],
                "term8": [
                  "C108",
                  "C104",
                  "C367",
                  "C105"
                ],
                "term9": [
                  "C732",
                  "C970",
                  "D005",
                  "Mild",
                  "Mild"
                ],
                "termElementary": [
                  "D006"
                ],
                "termSecondary": [
                  "D007"
                ],
                "term10": [
                  "C934"
                ],
                "termSpecial": [
                  "C311",
                  "C312",
                  "C313",
                  "C314"
                ],
                "term11": [
                  "C339",
                  "3",
                  "4",
                  "6",
                  "D147",
                  "D151",
                  "C340"
                ]
              }
        },
        "E-ELLMA": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "English Language Learning – M.A.",
            "description": "A master's degree for current teachers whose next career goal...",
            "degreeLevel": "masters",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Advance English Language Teaching with an M.A. in English Language Learning",
                "description": `The M.A. in English Language Learning is designed for current teachers aiming to specialize in teaching English to speakers of other languages (TESOL). Gain expertise in language acquisition theories, instructional strategies, and curriculum development for English language learners.\n\n    Learn to address the unique needs of students learning English as an additional language, incorporating culturally responsive teaching practices. The program includes practical teaching experiences, enhancing your ability to work effectively in diverse and multilingual classrooms.\n\n    Graduates will be prepared to take on roles such as ESL teachers, language program coordinators, or curriculum developers, contributing to the success of English language learners. Ideal for educators committed to fostering inclusive and effective English language education.`
            },
            courses: {
                "term1": [
                  "CUA1",
                  "LPA1",
                  "SLO1"
                ],
                "term2": [
                  "ASA1",
                  "NNA1",
                  "NMA1"
                ],
                "term3": [
                  "ELO1",
                  "C224",
                  "C225",
                  "FEA1"
                ],
                "term4": [
                  "C360"
                ]
              }
        },
        "E-SEMA": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Science Education – M.A.",
            "description": "A master's degree for current teachers who want to specialize in...",
            "degreeLevel": "masters",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Deepen Your Expertise in Science Teaching with an M.A. in Science Education",
                "description": `The M.A. in Science Education is tailored for current teachers looking to specialize in science teaching. Dive deep into pedagogical approaches specific to science education, covering topics such as inquiry-based learning and scientific literacy.\n\n    The program offers advanced training in teaching methods for biology, chemistry, physics, and environmental science. Engage in research and curriculum development to enhance science education practices.\n\n    Graduates will be equipped to take on roles such as science teachers, curriculum coordinators, or science education consultants, enhancing science learning experiences in educational settings. Ideal for science educators seeking to advance their teaching skills and contribute to the field of science education.`
            },
            courses: {
                "term1": [
                  "C670",
                  "C908",
                  "C672",
                  "C673"
                ],
                "term2": [
                  "RXT2",
                  "BVT2",
                  "BWT2",
                  "C389"
                ],
                "term3": [
                  "AIT2",
                  "C625",
                  "C267",
                  "C617"
                ],
                "term4": [
                  "C977",
                  "C871"
                ]
              }
        },
        "E-TSEMA": {
            "type": "EDUCATION",
            "typImg": educationIcon,
            "name": "Teaching, Special Education – M.A.",
            "description": "A master's degree for current or aspiring teachers looking to...",
            "degreeLevel": "masters",
            "programTypes": ["education"],
            "detailInformation": {
                "title": "Specialize in Inclusive Education with an M.A. in Teaching, Special Education",
                "description": `The M.A. in Teaching, Special Education, is designed for current or aspiring teachers who want to specialize in special education. The program focuses on developing skills and strategies to support students with diverse learning needs in inclusive settings.\n\n    Learn about individualized education planning, adaptive teaching techniques, and classroom accommodations. The curriculum includes a mix of theoretical knowledge and practical applications, providing hands-on experience in special education environments.\n\n    Graduates will be prepared for roles such as special education teachers, inclusion specialists, or educational consultants, playing a crucial role in ensuring equitable education for all students. Ideal for educators dedicated to supporting the academic and social-emotional needs of students with disabilities.`
            },
            courses: {
                "term1": [
                  "D307",
                  "D168",
                  "D169"
                ],
                "term2": [
                  "D228",
                  "D170",
                  "D171",
                  "D237"
                ],
                "term3": [
                  "D229",
                  "D230",
                  "C380",
                  "C910"
                ],
                "term4": [
                  "D163",
                  "D244",
                  "D173",
                  "D231"
                ],
                "term5": [
                  "D232",
                  "D233",
                  "D234",
                  "D238"
                ],
                "term6": [
                  "D239",
                  "D240",
                  "D241",
                  "D242"
                ],
                "term7": [
                  "D243",
                  "C347",
                  "D245"
                ]
              }
        },
    }

    // ================================================================================
    // #region key arrays for different groupints of programs

    export const businessBAProgramTileKeys = [
        "B-BMBSBA",
        "B-HRMBSBA",
        "B-MBSBA",
        "B-ABSBA",
        "B-IMBSBA",
        "B-HABS",
        "B-FBS",
        "B-SCaOMBS",
        "B-HIMBS"
    ]
    export const businessMAProgramTileKeys = [
        "B-MoBA",
        "B-MaLMS",
        "B-MHM",
        "B-MITM",
        "B-IMBSBA",
        "B-AMS",
        "B-MMS",
        "B-HRMMS",
        "B-AITBsaMsD",
        "B-DAMS",
        "B-MoHA",
        "B-ETaIDME",
        "B-ITMMS"
    ]
    export const businessCEProgramTileKeys = [
        "B-BL",
        "B-FfSiB",
        "B-PMP",
        "B-ETRM"
    ]
    export const itBAProgramTileKeys  = [
        "I-CSBS",
        "I-CaIABS",
        "I-DABS",
        "I-NEaSBS",
        "I-IMBSBA",
        "I-SEBS",
        "I-CCBS",
        "I-ITBS",
        "I-AITBsaMsD",
        "I-HIMBS"
    ]
    export const itMAProgramTileKeys  = [
        "I-CIAMS",
        "I-DAMS",
        "I-ITMMS",
        "I-AITBsaMsD",
        "I-MITM"
    ]
    export const itCEProgramTileKeys  = [
        "I-RWD",
        "I-JF",
        "I-SMP",
        "I-UGDF",
        "I-UGDAP",
        "I-UGDF",
        "I-GFA",
        "I-CA",
        "I-CP",
        "I-CD",
        "I-CC",
        "I-CN",
        "I-CS"
    ]
    export const healthcareBAProgramTileKeys  = [
        "H-NRtBOBS",
        "H-HaHSBS",
        "H-HIMBS",
        "H-HABS",
        "H-NPBS"
    ]
    export const healthcareMAProgramTileKeys  = [
        "H-NLMBtMMS",
        "H-NNIBtMMS",
        "H-PMHNPBtMMSN",
        "H-MoHA",
        "H-MHM"
    ]
    export const healthcareCEProgramTileKeys  = [
        "H-NEPMsC",
        "H-PMHNPPMsC",
        "H-NLaMPMsC",
        "H-FNPPMsC"
    ]
    export const educationBAProgramTileKeys  = [
        "E-EEBA",
        "E-MEMGBS",
        "E-MESBS",
        "E-SEMGBS",
        "E-SESBSBS",
        "E-SESCBS",
        "E-SESPBS",
        "E-SESESBS",
        "E-SEMtMBA",
        "E-SEaEEDLBA"
    ]
    export const educationMAProgramTileKeys  = [
        "E-ELMS",
        "E-CaIMS",
        "E-ETaIDME",
        "E-MEMA",
        "E-ELLMA",
        "E-SEMA",
        "E-TSEMA"
    ]

    // #endregion key arrays for different groupints of programs

    // ================================================================================
    // #region Courses

    export const allCoursesWithDescriptions = {
        "D322": {
          "courseID": "D322",
          "CCN": "ITEC 2002",
          "name": "Introduction to IT ",
          "CUs": "4",
          "description": "Introduction to IT examines information technology as a discipline and the various roles and functions of the IT department as business support. Students are presented with various IT disciplines including systems and services, network and security, scripting and programming, data management, and business of IT, with a survey of technologies in every area and how they relate to each other and to the business. "
        },
        "C955": {
          "courseID": "C955",
          "CCN": "MATH 1101",
          "name": "Applied Probability and Statistics ",
          "CUs": "3",
          "description": "Applied Probability and Statistics is designed to help students develop competence in the fundamental concepts of basic statistics including: introductory algebra and graphing; descriptive statistics; regression and correlation; and probability. Statistical data and probability are often used in everyday life, science, business, information technology, and educational settings to make informed decisions about the validity of studies and the effect of data on decisions. This course discusses what constitutes sound research design and how to appropriately model phenomena using statistical data. Additionally, the content covers simple probability calculations, based on events that occur in the business and IT industries. No prerequisites are required for this course. "
        },
        "D315": {
          "courseID": "D315",
          "CCN": "ITEC 2112",
          "name": "Network and Security - Foundations ",
          "CUs": "3",
          "description": "Foundations - Network and Security - Foundations introduces learners to the basic network systems and concepts related to networking technologies. Learners will gain skills in applying network security concepts for business continuity, data access, and confidentiality, and in identifying solutions for compliance with security guidance. "
        },
        "D278": {
          "courseID": "D278",
          "CCN": "ITSW 2113",
          "name": "Scripting and Programming - Foundations ",
          "CUs": "3",
          "description": "Foundations - Scripting and Programming - Foundations introduces programming basics such as variables, data types, flow control, and design concepts. The course is language-agnostic in nature, ending in a survey of languages, and introduces the distinction between interpreted and compiled languages. Learners will gain skills in identifying scripts for computer program requirements and in using fundamental programming elements as part of common computer programming tasks. Learners will also gain an understanding of the logic and outcome of simple algorithms. "
        },
        "D426": {
          "courseID": "D426",
          "CCN": "ITEC 2116",
          "name": "Data Management - Foundations ",
          "CUs": "3",
          "description": "Foundations - Data Management Foundations offers an introduction in creating conceptual, logical and physical data models.  Students gain skills in creating databases and tables in SQL-enabled database management systems, as well as skills in normalizing  databases. No prerequisites are required for this course "
        },
        "D276": {
          "CCN": "ITSW 2120",
          "courseID": "D276",
          "name": "Web Development Foundations",
          "description": "Introduces the fundamentals of web development, focusing on HTML, CSS, and basic web technologies.",
          "CUs": 3,
          "term": 2
        },
        "C958": {
          "CCN": "MATH 2100",
          "courseID": "C958",
          "name": "Calculus I",
          "description": "An introductory course in calculus, exploring the concepts of limits, derivatives, and integrals.",
          "CUs": 4,
          "term": 2
        },
        "D199": {
          "CCN": "GEOG 1312",
          "courseID": "D199",
          "name": "Introduction to Physical and Human Geography",
          "description": "Explores the physical aspects of the Earth and the human activities that shape our environment, emphasizing the relationship between nature and human society.",
          "CUs": 3,
          "term": 3
        },
        "C458": {
          "courseID": "C458",
          "CCN": "HLTH 1010",
          "name": "Health, Fitness, and Wellness ",
          "CUs": "4",
          "description": "Health, Fitness, and Wellness focuses on the importance and foundations of good health and physical fitness—particularly for children and adolescents—addressing health, nutrition, fitness, and substance use and abuse. "
        },
        "C959": {
          "CCN": "MATH 2800",
          "courseID": "C959",
          "name": "Discrete Mathematics I",
          "description": "Introduces discrete mathematics, focusing on topics like logic, set theory, and combinatorics, foundational for computer science and engineering.",
          "CUs": 4,
          "term": 3
        },
        "D197": {
          "CCN": "ITSW 2110",
          "courseID": "D197",
          "name": "Version Control",
          "description": "Covers the basics of version control systems, essential for managing changes in digital projects, particularly in software development.",
          "CUs": 1,
          "term": 3
        },
        "C960": {
          "CCN": "MATH 2810",
          "courseID": "C960",
          "name": "Discrete Mathematics II",
          "description": "Continues from Discrete Mathematics I, delving deeper into topics such as graph theory, number theory, and algorithmic thinking.",
          "CUs": 4,
          "term": 4
        },
        "D268": {
          "courseID": "D268",
          "CCN": "COMM 3015",
          "name": "Introduction to Communication: Connecting with Others ",
          "CUs": "3",
          "description": "Welcome to Introduction to Communication: Connecting with Others! It may seem like common knowledge that communication skills are important, and that communicating with others is inescapable in our everyday lives. While this may appear simplistic, the study of communication is actually complex, dynamic, and multifaceted. Strong communication skills are invaluable to strengthening a multitude of aspects of life. Specifically, this course will focus on communication in the professional setting, and present material from multiple vantage points, including communicating with others in a variety of contexts, across situations, and with diverse populations. Upon completion, you will have a deeper understanding of both your own and others’ communication behaviors, and a toolbox of effective behaviors to enhance your experience in the workplace. "
        },
        "C952": {
          "CCN": "ICSC 3120",
          "courseID": "C952",
          "name": "Computer Architecture",
          "description": "Examines the structure and functioning of computer systems, including processors, memory, and input/output interfaces.",
          "CUs": 3,
          "term": 4
        },
        "D270": {
          "courseID": "D270",
          "CCN": "ENGL 1712",
          "name": "Composition: Successful Self-Expression ",
          "CUs": "3",
          "description": "Welcome to Composition: Successful Self-Expression! In this course, you will focus on four main topics: professional writing for a cross-cultural audience, narrowing research topics and questions, researching for content to support a topic, and referencing research sources. Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the seven competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. You will demonstrate competency through a performance assessment. There is no prerequisite for this course and there is no specific technical knowledge needed. "
        },
        "D427": {
          "courseID": "D427",
          "CCN": "ITEC 2117",
          "name": "Data Management - Applications ",
          "CUs": "4",
          "description": "Applications - Data Management - Applications covers conceptual data modeling and introduces MySQL. Students will learn how to create simple to complex SELECT queries, including subqueries and joins, and how to use SQL to update and delete data. Topics covered in this course include exposure to MySQL; creating and modifying databases, tables, views, foreign keys and primary keys (FKs and PKs), and indexes; populating tables; and developing simple Select-From-Where (SFW) queries to complex 3+ table join queries. The following course is a prerequisite: Data Management - Foundations. "
        },
        "D198": {
          "courseNumber": "D198",
          "CCN": "HUMN 1020",
          "name": "Global Arts and Humanities",
          "description": "Provides an interdisciplinary overview of global artistic and humanistic traditions, examining their cultural, historical, and philosophical contexts.",
          "CUs": 3,
          "term": 5
        },
        "D286": {
          "courseNumber": "D286",
          "CCN": "ITSW 3172",
          "name": "Java Fundamentals",
          "description": "Covers the fundamental concepts and techniques of Java programming, focusing on object-oriented programming, data structures, and algorithm development.",
          "CUs": 3,
          "term": 5
        },
        "D287": {
          "courseNumber": "D287",
          "CCN": "ITSW 3173",
          "name": "Java Frameworks",
          "description": "Introduces popular Java frameworks and libraries, emphasizing their application in building robust and scalable software applications.",
          "CUs": 3,
          "term": 6
        },
        "C949": {
          "courseNumber": "C949",
          "CCN": "ICSC 2100",
          "name": "Data Structures and Algorithms I",
          "description": "Examines fundamental data structures and algorithms, focusing on their implementation, efficiency, and application in solving computational problems.",
          "CUs": 4,
          "term": 6
        },
        "D288": {
          "courseNumber": "D288",
          "CCN": "ITSW 3175",
          "name": "Back-End Programming",
          "description": "Focuses on server-side programming concepts and technologies, covering topics such as database connectivity, API development, and server scripting.",
          "CUs": 3,
          "term": 6
        },
        "D281": {
          "courseID": "D281",
          "CCN": "ITEC 3004",
          "name": "Linux Foundations ",
          "CUs": "3",
          "description": "Linux Foundations prepares learners for the LPI Linux Essentials certification, and is an introduction to Linux as an operating system as well as an introduction to open-source concepts and the basics of the Linux command line. Learners will gain skills in identifying the fundamentals of open-source software and to develop resources for data access and security. "
        },
        "D387": {
          "courseNumber": "D387",
          "CCN": "ITSW 3024",
          "name": "Advanced Java",
          "description": "Explores advanced concepts in Java programming, including multi-threading, networking, and GUI development, with a focus on enterprise-level applications.",
          "CUs": 3,
          "term": 7
        },
        "D333": {
          "courseID": "D333",
          "CCN": "HUMN 1101",
          "name": "Ethics in Technology ",
          "CUs": "3",
          "description": "Ethics in Technology examines the ethical considerations of technology use in the 21st century and introduces students to a decision-making process informed by ethical frameworks. Students will study specific cases related to important topics such as surveillance, social media, hacking, data manipulation, plagiarism and piracy, artificial intelligence, responsible innovation, and the digital divide. This course has no prerequisites. "
        },
        "D430": {
          "courseID": "D430",
          "CCN": "ITAS 2110",
          "name": "Fundamentals of Information Security ",
          "CUs": "3",
          "description": "This course lays the foundation for understanding terminology, principles, processes, and best practices of information security at local and global levels. It further provides an overview of basic security vulnerabilities and countermeasures for protecting information assets through planning and administrative controls within an organization. This course has no prerequisites. "
        },
        "C191": {
          "courseNumber": "C191",
          "CCN": "ITEC 2211",
          "name": "Operating Systems for Programmers",
          "description": "Focuses on the principles of operating systems with an emphasis on features important for programming, such as process management and file systems.",
          "CUs": 3,
          "term": 7
        },
        "C950": {
          "courseNumber": "C950",
          "CCN": "ICSC 3100",
          "name": "Data Structures and Algorithms II",
          "description": "Continues from Data Structures and Algorithms I, covering more complex algorithms and data structures, with a focus on algorithmic strategies and efficiency.",
          "CUs": 4,
          "term": 8
        },
        "C683": {
          "courseID": "C683",
          "CCN": "SCIE 1001",
          "name": "Natural Science Lab ",
          "CUs": "2",
          "description": "This course provides students an introduction to using the scientific method and engaging in scientific research to reach conclusions about the natural world. Students will design and carry out an experiment to investigate a hypothesis by gathering quantitative data. They will also research a specific ecosystem using academic sources and draw conclusions from their findings. "
        },
        "D336": {
          "courseID": "D336",
          "CCN": "ITEC 2113",
          "name": "Business of IT – Applications ",
          "CUs": "4",
          "description": "Business of IT - Applications examines Information Technology Infrastructure Library (ITIL®) terminology, structure, policies, and concepts. Focusing on the management of information technology (IT) infrastructure, development, and operations, learners will explore the core principles of ITIL practices for service management to prepare them for careers as IT professionals, business managers, and business process owners. This course has no prerequisites. "
        },
        "D284": {
          "courseNumber": "D284",
          "CCN": "ITSW 2226",
          "name": "Software Engineering",
          "description": "Covers the principles of software engineering, including software development life cycles, design patterns, and best practices in software design and testing.",
          "CUs": 4,
          "term": 8
        },
        "D326": {
          "courseNumber": "D326",
          "CCN": "DTMG 3179",
          "name": "Advanced Data Management",
          "description": "Delves into advanced topics in data management, including big data analytics, data integration, and the management of large-scale databases.",
          "CUs": 3,
          "term": 9
        },
        "D370": {
          "courseNumber": "D370",
          "CCN": "ITBU 2201",
          "name": "IT Leadership Foundations",
          "description": "Explores the foundational skills and concepts required for leadership roles in IT, including strategic planning, team management, and IT governance.",
          "CUs": 3,
          "term": 9
        },
        "D339": {
          "courseNumber": "D339",
          "CCN": "COMM 1115",
          "name": "Technical Communication",
          "description": "Focuses on the skills needed for effective communication in technical fields, covering technical writing, presentation skills, and communication strategies.",
          "CUs": 3,
          "term": 9
        },
        "C951": {
          "courseNumber": "C951",
          "CCN": "ICSC 3110",
          "name": "Introduction to Artificial Intelligence",
          "description": "Provides an overview of artificial intelligence concepts and applications, including machine learning, neural networks, and AI in problem-solving.",
          "CUs": 3,
          "term": 9
        },
        "D480": {
          "courseNumber": "D480",
          "CCN": "ITSW 3151",
          "name": "Software Design and Quality Assurance",
          "description": "Covers software design principles and quality assurance practices, focusing on creating reliable and maintainable software systems.",
          "CUs": 3,
          "term": 10
        },
        "C964": {
          "courseNumber": "C964",
          "CCN": "ICSC 3130",
          "name": "Computer Science Capstone",
          "description": "A culminating project that integrates and applies computer science knowledge and skills in a real-world scenario or research topic.",
          "CUs": 4,
          "term": 10
        },
        "D265": {
          "courseID": "D265",
          "CCN": "PHIL 1020",
          "name": "Critical Thinking: Reason and Evidence ",
          "CUs": "3",
          "description": "In this course you will learn key critical thinking concepts and how to apply them in the analysis and evaluation of reasons and evidence. The course examines the basic components of an argument, the credibility of evidence sources, the impact of bias, and how to construct an argument that provides good support for a claim. The course consists of an introduction and four major sections. Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the four competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. "
        },
        "D317": {
          "courseID": "D317",
          "CCN": "ITEC 2023",
          "name": "IT Applications ",
          "CUs": "4",
          "description": "IT Applications introduces skills in identifying operating systems and their configurations and in implementing security principles across devices and networks. Learners will also gain skills in troubleshooting software, security, and malware issues, and in implementing basic operational procedures in documentation, change management, compliance, and communication. The course will introduce basic disaster recovery and business continuity procedures, scripting basics, and remote access technology solutions. The course prepares learners for the CompTIA A+ Core 2 certification exam. "
        },
        "D316": {
          "courseID": "D316",
          "CCN": "ITEC 2013",
          "name": "IT Foundations ",
          "CUs": "4",
          "description": "IT Foundations provides learners with an understanding of personal computer components and their functions in a desktop system; a knowledge of computer data storage and retrieval; and skills in classifying, installing, configuring, optimizing, upgrading, and troubleshooting printers, laptops, portable devices, operating systems, networks, and system security. This course also gives learners the ability to recommend appropriate tools, diagnostic procedures, preventative maintenance, and troubleshooting techniques for personal computer components in a desktop system; strategies for identifying, preventing, and reporting safety hazards and environmental or human accidents in technological environments; and effective communication skills for interacting with colleagues and clients, including job-related professional behavior. The course prepares learners for the CompTIA A+ Core 1 certification exam. "
        },
        "C841": {
          "courseID": "C841",
          "CCN": "ITAS 3010",
          "name": "Legal Issues in Information Security ",
          "CUs": "4",
          "description": "Security information professionals have the role and responsibility for knowing and applying ethical and legal principles and processes that define specific needs and demands to assure data integrity within an organization. This course addresses the laws, regulations, authorities, and directives that inform the development of operational policies, best practices, and training to assure legal compliance and to minimize internal and external threats. Students analyze legal constraints and liability concerns that threaten information security within an organization and develop disaster recovery plans to assure business continuity. "
        },
        "D372": {
          "courseID": "D372",
          "CCN": "PHIL 1030",
          "name": "Introduction to Systems Thinking ",
          "CUs": "3",
          "description": "Introduction to Systems Thinking provides learners with the skills required to engage in a holistic systems-based approach to analyzing complex problems and solutions. This course introduces the foundational concepts and principles of systems thinking and provides opportunities to use a systems thinking approach to analyze and evaluate real-world case studies. The course will culminate with using systems thinking to develop a solution to an authentic complex problem. This course has no prerequisites, but general education math (C955 or C957) is preferred. Because the course is self-paced, learners may move through the material as quickly or as slowly as needed, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If learners have no prior knowledge of this material, they can expect to spend 30 to 40 hours on the course content. "
        },
        "C957": {
          "courseID": "C957",
          "CCN": "MATH 1200",
          "name": "Applied Algebra ",
          "CUs": "3",
          "description": "Applied Algebra is designed to help you develop competence in working with functions, the algebra of functions, and using some applied properties of functions. You will start learning about how we can apply different kinds of functions to relevant, real-life examples. From there, the algebra of several families of functions will be explored, including linear, polynomial, exponential, and logistic functions. You will also learn about relevant, applicable mathematical properties of each family of functions, including rate of change, concavity, maximizing/minimizing, and asymptotes. These properties will be used to solve problems related to your major and make sense of everyday living problems. Students should complete Applied Probability and Statistics or its equivalent prior to engaging in Applied Algebra. "
        },
        "D325": {
          "courseID": "D325",
          "CCN": "ITEC 3602",
          "name": "Networks ",
          "CUs": "4",
          "description": "Networks introduces skills in configuring networking components and a network infrastructure. Learners will gain skills in optimizing network operations for availability, performance, and security, and in troubleshooting network issues. The course prepares learners for the CompTIA Network+ certification exam. Network and Security - Foundations is a prerequisite for this course. "
        },
        "C844": {
          "courseID": "C844",
          "CCN": "ITAS 3040",
          "name": "Emerging Technologies in Cybersecurity ",
          "CUs": "4",
          "description": "The continual evolution of technology means that cybersecurity professionals must be able to analyze and evaluate new technologies in information security such as wireless, mobile, and internet technologies. Students review the adoption process that prepares an organization for the risks and challenges of implementing new technologies. This course focuses on comparison of evolving technologies to address the security requirements of an organization. Students learn underlying principles critical to the operation of secure networks and adoption of new technologies. "
        },
        "D329": {
          "courseID": "D329",
          "CCN": "ITEC 2112",
          "name": "Network and Security - Applications ",
          "CUs": "4",
          "description": "Applications - Network and Security - Applications prepares learners for the CompTIA Security+ certification exam. The course introduces learners to skills in identifying threats, attacks, and vulnerabilities to organizational security. The learner will also gain skills in designing security solutions for enterprise infrastructures and architectures, as well as in implementing security solutions across hardware, applications, and network services. Learners will be able to execute operations and incident response with tools, policies, forensics, and mitigation techniques, and to analyze information security controls, governance, risk, and compliance. "
        },
        "D431": {
          "courseID": "D431",
          "CCN": "ITAS 2140",
          "name": "Digital Forensics in Cybersecurity ",
          "CUs": "4",
          "description": "Digital Forensics in Cyber Security examines the relationships between incident categories, evidence handling, and incident management. This course teaches students to identify consequences associated with cyber threats and security laws using a variety of tools to recognize and recover from unauthorized, malicious activities and how to seek evidence that reveals who, what, when, where, and how threats compromise information. "
        },
        "C845": {
          "courseID": "C845",
          "CCN": "ITAS 3050",
          "name": "Information Systems Security ",
          "CUs": "4",
          "description": "IT security professionals must be prepared for the operational demands and responsibilities of security practitioners including authentication, security testing, intrusion detection and prevention, incident response and recovery, attacks and countermeasures, cryptography, and malicious code countermeasures. This course provides a comprehensive, up-to-date global body of knowledge that ensures students have the right information, security knowledge, and skills to be successful in IT operational roles to mitigate security concerns and guard against the impact of malicious activity. Students demonstrate how to manage and restrict access control systems; administer policies, procedures, and guidelines that are ethical and compliant with laws and regulations; implement risk management and incident handling processes; execute cryptographic systems to protect data; manage network security; and analyze common attack vectors and countermeasures to assure information integrity and confidentiality in various systems. This course prepares students for the Systems Security Certified Practitioner (ISC2 SSCP) certification exam. "
        },
        "D334": {
          "courseID": "D334",
          "CCN": "ITAS 2141",
          "name": "Introduction to Cryptography ",
          "CUs": "4",
          "description": "Introduction to Cryptography introduces skills in applying cryptography principles in alignment with organizational and information security guidelines. Students will determine requirements and techniques for cryptanalysis. This course builds skills in implementing encryption methods with symmetric and asymmetric algorithms. "
        },
        "D324": {
          "courseID": "D324",
          "CCN": "ITEC 2109",
          "name": "Business of IT - Project Management ",
          "CUs": "4",
          "description": "Project Management - In this course, students will build on industry standard concepts, techniques, and processes to develop a comprehensive foundation for project management activities. During a project's life cycle, students will develop the critical skills necessary to initiate, plan, execute, monitor, control, and close a project. Students will apply best practices in areas such as scope management, resource allocation, project planning, project scheduling, quality control, risk management, performance measurement, and project reporting. This course prepares students for the following certification exam: CompTIA Project+. "
        },
        "D335": {
          "courseID": "D335",
          "CCN": "ITSW 3126",
          "name": "Introduction to Programming in Python ",
          "CUs": "3",
          "description": "Introduction to Programming in Python introduces skills in creating Python scripts with basic programming concepts. Learners will be able to create control flow with functions and loops, and to implement code with packages, modules, and libraries. "
        },
        "C963": {
          "courseID": "C963",
          "CCN": "POLS 1030",
          "name": "American Politics and the US Constitution ",
          "CUs": "3",
          "description": "American Politics and the U.S. Constitution examines the evolution of representative government in the United States and the changing interpretations of the civil rights and civil liberties protected by the Constitution. This course will give candidates an understanding of the powers of the branches of the federal government, the continual tensions inherent in a federal system, the shifting relationship between state and federal governments, and the interactions between elected officials and the ever-changing electorate. This course will focus on such topics as the role of a free press in a democracy, the impact of changing demographics on American politics, and the debates over and expansion of civil rights. Upon completion of the course, candidates should be able to explain the basic functions of the federal government, describe the forces that shape American policy and politics, and be better prepared to participate in America’s civic institutions. This course has no prerequisite. "
        },
        "C843": {
          "courseID": "C843",
          "CCN": "ITAS 3030",
          "name": "Managing Information Security ",
          "CUs": "6",
          "description": "This course expands on fundamentals of information security by providing an in-depth analysis of the relationship between an information security program and broader business goals and objectives. Students develop knowledge and experience in the development and management of an information security program essential to ongoing education, career progression, and value delivery to enterprises. Students apply best practices to develop an information security governance framework, analyze mitigation in the context of compliance requirements, align security programs with security strategies and best practices, and recommend procedures for managing security strategies that minimize risk to an organization. "
        },
        "D340": {
          "courseID": "D340",
          "CCN": "ITAS 3021",
          "name": "Cyber Defense and Countermeasures ",
          "CUs": "4",
          "description": "Traditional defenses—such as firewalls, security protocols, and encryption—sometimes fail to stop attackers determined to access and compromise data. This course provides the fundamental skills to handle and respond to computer security incidents in an information system. The course addresses various underlying principles and techniques for detecting and responding to current and emerging computer security threats. Students learn how to leverage intelligence and threat detection techniques; analyze and interpret data; identify and address vulnerabilities; suggest preventative measures; effectively respond to and recover from incidents; and handle various types of incidents, risk assessment methodologies, and various laws and policies related to incident handling. This course prepares students for the CompTIA Cybersecurity Analyst (CySA+) certification exam. The following courses are prerequisites: Networks and Network and Security – Applications. "
        },
        "D320": {
          "courseID": "D320",
          "CCN": "ITCL 3202",
          "name": "Managing Cloud Security ",
          "CUs": "4",
          "description": "Managing Cloud Security prepares learners to safeguard cloud data with identity and access management and to implement secure solutions in cloud service models. Learners will be introduced to skills in identifying security policies and procedures for cloud applications and in implementing operational capabilities, procedures, and training in relation to organizational needs. Learners will also gain skills in conducting risk analysis and risk management in alignment with disaster recovery and business continuity plans and in identifying legal, compliance, and ethical concerns. "
        },
        "D332": {
          "courseID": "D332",
          "CCN": "ITAS 3080",
          "name": "Penetration Testing and Vulnerability Analysis ",
          "CUs": "4",
          "description": "Penetration Testing and Vulnerability Analysis introduces learners to the skills necessary to perform penetration testing and vulnerability management within an organization. Learners will gain skills in defining the scope and planning for procurement of penetration testing engagements and in performing cyber reconnaissance for information gathering and vulnerability identification. Learners will also gain skills in developing penetration testing techniques in exploitation of physical, digital, and social vulnerabilities, and to simulate attacks and responses on an organization's security infrastructure. Lastly, learners will gain skills in reporting the results of cybersecurity assessments with recommended actions. "
        },
        "C769": {
          "courseID": "C769",
          "CCN": "ITEC 4903",
          "name": "IT Capstone Written Project ",
          "CUs": "4",
          "description": "The capstone project consists of a technical work proposal, the proposal’s implementation, and a postimplementation report that describes the graduate’s experience in developing and implementing the capstone project. The capstone project should be presented and approved by the course instructor in relation to the graduate’s technical emphasis. "
        },
        "D491": {
          "courseID": "D491",
          "CCN": "DTAN 3100",
          "name": "Introduction to Analytics ",
          "CUs": "2",
          "description": "Analytics is the creative use of data and statistical modeling to tell a compelling story that not only drives strategic action, but also results in business value. Introduction to Analytics examines data analytics as a discipline and the various roles and functions within the field. You will expand your knowledge about what analytics is and develop a basic understanding of statistics, analysis, problem solving, and programming concepts. "
        },
        "D388": {
          "courseID": "D388",
          "CCN": "BUS 2250",
          "name": "Fundamentals of Spreadsheets and Data Presentations ",
          "CUs": "3",
          "description": "Fundamentals of Spreadsheets and Data Presentations offers learners an overview of the use of spreadsheet functions and methods for presenting data within spreadsheets. Learners will have the opportunity to explore features and uses of MS Excel and apply the tools to situations they may encounter while studying in their program. They will also be introduced to real world uses and tools to collect, organize and present data. "
        },
        "C721": {
          "courseID": "C721",
          "CCN": "MGMT 4400",
          "name": "Change Management ",
          "CUs": "3",
          "description": "Change Management provides an understanding of change and an overview of successfully managing change using various methods and tools. Emphasizing change theories and various best practices, this course covers how to recognize and implement change using an array of other effective strategies, including those related to innovation and leadership. Other topics include approaches to change, diagnosing and planning for change, implementing change, and sustaining change. "
        },
        "D428": {
          "courseID": "D428",
          "CCN": "PHIL 1110",
          "name": "Design Thinking for Business ",
          "CUs": "3",
          "description": "Design Thinking for Business examines the design thinking methodology for solving complex problems. This course introduces students to design thinking as a human-centered approach to problem-solving and innovation that draws upon empathy and creativity to develop solutions to complex problems. Students will explore the principles and stages of design thinking and analyze the use of design thinking in developing solutions through real-world scenarios. "
        },
        "D269": {
          "courseID": "D269",
          "CCN": "ENGL 1711",
          "name": "Composition: Writing with a Strategy ",
          "CUs": "3",
          "description": "Welcome to Composition: Writing with a Strategy! In this course, you will focus on three main topics: understanding purpose, context, and audience, writing strategies and techniques, and editing and revising. In addition, the first section, will offer review on core elements of the writing process, cross-cultural communication, as well as working with words and common standards and practices.        Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the seven competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. "
        },
        "D282": {
          "courseID": "D282",
          "CCN": "ITEC 2119",
          "name": "Cloud Foundations ",
          "CUs": "3",
          "description": "Cloud Foundations introduces learners to real-world issues and practical solutions to cloud computing. This course covers the business value of cloud computing, examining cloud types, the steps to successful cloud adoption, and the effect cloud adoption has on IT service management, as well as the risks and consequences of implementing cloud solutions. This course prepares learners for the AWS Certified Practitioner certification exam. There are no prerequisites for this course. "
        },
        "D386": {
          "courseID": "D386",
          "CCN": "ITEC 2022",
          "name": "Hardware and Operating Systems Essentials ",
          "CUs": "3",
          "description": "Hardware and Operating Systems prepares learners for concepts in software engineering by providing a foundation of understanding in computer architecture, the history of computing architectures, and operating systems. Additional topics covered include hardware and software stacks and how to choose appropriate hardware and software solutions to meet both functional and nonfunctional business requirements. "
        },
        "D492": {
          "courseID": "D492",
          "CCN": "DTAN 3200",
          "name": "Data Analytics - Applications ",
          "CUs": "4",
          "description": "Applications - Data Analytics Applications covers advanced concepts across the various phases of the data product lifecycle. You will learn to choose and apply appropriate techniques for data management and data manipulation, statistical analysis, visualization, and data governance concepts to satisfy business needs. "
        },
        "D420": {
          "courseID": "D420",
          "CCN": "MATH 2820",
          "name": "Discrete Math: Logic ",
          "CUs": "1",
          "description": "Discrete Math-Logic is designed to help students develop competence in the use of logic and proofs and Boolean Algebra and Boolean functions. Applied Probability and Statistics and Applied Algebra are prerequisites for this course. "
        },
        "D421": {
          "courseID": "D421",
          "CCN": "MATH 2830",
          "name": "Discrete Math: Functions and Relations ",
          "CUs": "1",
          "description": "Discrete Math: Functions and Relations is designed to help students develop competence in the use of abstract discrete structures fundamental to systems networking. In particular, this course will introduce students to set theory, finite sequences, series, and relations. Discrete Math: Logic, Applied Probability and Statistics, and Applied Algebra are prerequisites for this course. "
        },
        "D493": {
          "courseID": "D493",
          "CCN": "ITSW 2135",
          "name": "Scripting and Programming - Applications ",
          "CUs": "3",
          "description": "Applications - Scripting and Programming - Applications for undergraduates explores the various aspects of the Python programming language by examining its syntax, the development environment, and tools and techniques to solve some real-world problems. Introduction to Programming in Python is a prerequisite for this course.  "
        },
        "D494": {
          "courseID": "D494",
          "CCN": "DTMG 3351",
          "name": "Data and Information Governance ",
          "CUs": "2",
          "description": "Data and Information Governance provides learners with the knowledge that establishing rules of engagement, policies, procedures, and data stewardship is essential to exercising organizational control over—and extracting maximum value from— its data assets. Good data governance helps an organization lower costs, create efficiencies, and achieve its strategic goals and objectives. Data governance provides a framework for properly managing information across the entire data lifecycle and establishes strategies in support of disaster recovery and continuity of operations. This course will prepare IT professionals to assist their organization in the definition and implementation of best practices related to the planning and implementation of managed systems that meet business, technical, security, auditing, disaster recovery, and business continuity requirements. "
        },
        "D495": {
          "courseID": "D495",
          "CCN": "DTAN 3205",
          "name": "Big Data Foundations ",
          "CUs": "4",
          "description": "Big Data Foundations provides an in-depth introduction to big data concepts, terminology, and applications. You will learn the risks and challenges of working with extremely large data sets. The course introduces tools and techniques for working with big data. The course covers selection criteria for relational and non-relational data architectures and cloud-native data storage concepts. It also provides a historical perspective on the evolution of big data storage approaches. Data warehousing, data lakes, and data lakehouses are introduced, and design principles for each are explained. Learners design aspects of big data architecture and big data processing to address given business requirements. "
        },
        "D246": {
          "courseID": "D246",
          "CCN": "COMM 1721",
          "name": "Influential Communication through Visual ",
          "CUs": "Design",
          "description": "Influential Communication through Visual Design and Storytelling provides learners with foundational visual design and storytelling techniques to influence and create a lasting impression on audiences. Learners will first explore how human behavior is influenced by visuals and when to apply visual techniques to better communicate with audiences. Next, learners will learn techniques for creating compelling stories that create memorable images within the audience's mind. Ultimately, learners who master these skills will be well-positioned to apply their visual and storytelling techniques to not only better communicate their thoughts and ideas to an audience, but to also influence or motivate them.   "
        },
        "": {
          "courseID": "",
          "CCN": "4 8",
          "name": "",
          "CUs": ""
        },
        "D496": {
          "courseID": "D496",
          "CCN": "DTSC 3211",
          "name": "Introduction to Data Science ",
          "CUs": "4",
          "description": "Introduction to Data Science introduces the data analysis process and common statistical techniques necessary for the analysis of data. Students will ask questions that can be solved with a given data set, set up experiments, use statistics and data wrangling to test hypotheses, find ways to speed up their data analysis code, make their data set easier to access, and communicate their findings. "
        },
        "D497": {
          "courseID": "D497",
          "CCN": "DTMG 3221",
          "name": "Data Wrangling ",
          "CUs": "3",
          "description": "Data Wrangling elaborates on concepts covered in Introduction to Data Science, helping to develop skills crucial to the field of data science and analysis. It explores how to wrangle data from diverse sources and shape it to enable data-driven applications—a common activity in many data scientists' routine. Topics covered include gathering and extracting data from widely-used data formats, assessing the quality of data, and exploring best practices for data cleaning. "
        },
        "D498": {
          "courseID": "D498",
          "CCN": "DTAN 3211",
          "name": "Data Analysis with R ",
          "CUs": "2",
          "description": "Data Analysis with R focuses on exploratory data analysis (EDA) utilizing R. EDA is an approach for summarizing and visualizing the important characteristics of a data set. In this course you will develop skills in R programming to acquire and load data sets, create appropriate statistical summaries of data, and create data visualizations to help uncover and communicate insights about data using R.  "
        },
        "D499": {
          "courseID": "D499",
          "CCN": "DTSC 3221",
          "name": "Machine Learning ",
          "CUs": "3",
          "description": "Machine Learning presents the end-to-end process of investigating data through a machine learning lens. Topics covered include: supervised and unsupervised learning algorithms, features that best represent data, commonly-used machine learning algorithms, and methods for evaluating the performance of machine learning algorithms.   "
        },
        "D500": {
          "courseID": "D500",
          "CCN": "DTAN 3221",
          "name": "Data Visualization ",
          "CUs": "2",
          "description": "Data Visualization covers the application of design principles, human perception, color theory, and effective storytelling in the context of data visualization. It addresses presenting data to others and advancing technology with visualization tools enabling data scientists to share their findings and support organizational decision-making processes. Additionally, this course focuses on how to visually encode and present data to an audience.  "
        },
        "D501": {
          "courseID": "D501",
          "CCN": "DTSC 3300",
          "name": "Machine Learning DevOps ",
          "CUs": "2",
          "description": "Machine Learning DevOps focuses on the software engineering fundamentals needed to successfully streamline the deployment of data and machine learning models in a production-level environment. Students will build the DevOps skills required to automate the various aspects and stages of machine learning model building and monitoring over time. "
        },
        "D502": {
          "courseID": "D502",
          "CCN": "DTMG 3901",
          "name": "Data Analytics Capstone ",
          "CUs": "4",
          "description": "The Data Analytics Undergraduate Capstone challenges students to demonstrate competencies supporting all BSDA program outcomes. Students will identify an organizational need, plan and develop a data analytics product to serve that need, and document the process in a project proposal and data project report.  "
        },
        "C165": {
          "courseID": "C165",
          "CCN": "SCIE 1020",
          "name": "Integrated Physical Sciences ",
          "CUs": "3",
          "description": "This course provides students with an overview of the basic principles and unifying ideas of the physical sciences: physics, chemistry, and earth sciences. Course materials focus on scientific reasoning and practical, everyday applications of physical science concepts to help students integrate conceptual knowledge with practical skills. "
        },
        "C483": {
          "courseID": "C483",
          "CCN": "BUS 2301",
          "name": "Principles of Management ",
          "CUs": "4",
          "description": "Principles of Management provides students with an introductory look at the discipline of management and its context within the business environment. Students of this course build on previously mastered competencies by taking a more in-depth look at management as a discipline and how it differs from leadership while further exploring the importance of communication within business. This course provides students with a business generalist overview in the areas of strategic planning, total quality, entrepreneurship, conflict and change, human resource management, diversity, and organizational structure. "
        },
        "C484": {
          "courseID": "C484",
          "CCN": "BUS 2001",
          "name": "Organizational Behavior and Leadership ",
          "CUs": "3",
          "description": "Organizational Behavior and Leadership explores how to lead and manage effectively in diverse business environments. The course requires students to demonstrate the ability to apply organizational leadership theories and management strategies in a series of scenario-based problems. "
        },
        "C268": {
          "courseID": "C268",
          "CCN": "BUIT 2200",
          "name": "Spreadsheets ",
          "CUs": "3",
          "description": "The Spreadsheets course will help students become proficient in using spreadsheets to analyze business problems. Students will demonstrate competency in spreadsheet development and analysis for business/accounting applications (e.g., using essential spreadsheet functions, formulas, charts, etc.) "
        },
        "C277": {
          "courseID": "C277",
          "CCN": "MATH 1709",
          "name": "Finite Mathematics ",
          "CUs": "4",
          "description": "Finite Mathematics covers the knowledge and skills necessary to apply discrete mathematics and properties of number systems to model and solve real-life problems. Topics include sets and operations; prime and composite numbers; GCD and LCM; order of operations; ordering numbers; mathematical systems including modular arithmetic, arithmetic and geometric sequences, ratio and proportion, subsets of real numbers, logic and truth tables, graphs, and trees and networks. There are no prerequisites for this course. "
        },
        "C777": {
          "courseID": "C777",
          "CCN": "ITWD 3120",
          "name": "Web Development Applications ",
          "CUs": "6",
          "description": "This course builds upon a student's manual coding skills by teaching how to develop web documents and pages using the web development trifecta: Hypertext Markup Language version 5 (HTML5), Cascading Style Sheets version 3 (CSS3), and JavaScript. Students will utilize the skills learned in this course to create web documents and pages that easily adapt to display on both traditional and mobile devices. In addition, students will learn techniques for code validation and testing, form creation, inline form field validation, and mobile design for browsers and apps, including Responsive Web Design (RWD). "
        },
        "C773": {
          "courseID": "C773",
          "CCN": "ITWD 3110",
          "name": "User Interface Design ",
          "CUs": "4",
          "description": "This course covers tools and techniques employed in user interface design, including web and mobile applications. Concepts of clarity, usability, and detectability are included in this course, as well as other design elements such as color schemes, typography, and layout. Techniques like wireframing, usability testing, and SEO optimization are also covered. "
        },
        "C724": {
          "courseID": "C724",
          "CCN": "BUIT 3000",
          "name": "Information Systems Management ",
          "CUs": "3",
          "description": "Information Systems Management provides an overview of many facets of information systems applicable to business. The course explores the importance of viewing information technology (IT) as an organizational resource that must be managed, so that it supports or enables organizational strategy. "
        },
        "C850": {
          "courseID": "C850",
          "CCN": "ITEC 2950",
          "name": "Emerging Technologies ",
          "CUs": "2",
          "description": "The Emerging Technologies course examines emerging technologies, identifies the benefits and drawbacks of technology adoption, and provides students with a process to evaluate technologies. The course will examine three technologies that may have an impact on Information Technology services in the coming years. "
        },
        "D277": {
          "courseID": "D277",
          "CCN": "ITSW 2131",
          "name": "Front-End Web Development ",
          "CUs": "3",
          "description": "Front-End Web Development builds upon web design and development skills to teach students how to organize websites with navigational schemes and create reactive user web interfaces using cascading style sheets (CSS). In this course, students will implement data entry and data storage capabilities in a web design, as well as implement best practices in design, including user-centered design and usability. Web Development Foundations is a prerequisite for this course. "
        },
        "D280": {
          "courseID": "D280",
          "CCN": "ITSW 3151",
          "name": "JavaScript Programming ",
          "CUs": "3",
          "description": "JavaScript Programming introduces students to programming with JavaScript, including how to use JavaScript to enhance a website. This course covers how to use existing frameworks, assets, and web content to enhance website functionality, as well as how to use application programming interfaces (APIs) and web services to add data capabilities to web applications. User Interface Design and Development is a prerequisite to this course. "
        },
        "D279": {
          "courseID": "D279",
          "CCN": "ITSW 3110",
          "name": "User Interface Design ",
          "CUs": "3",
          "description": "This course covers tools and techniques employed in user interface design, including web and mobile applications. Concepts of clarity, usability, and detectability are included in this course, as well as other design elements such as color schemes, typography, and layout. Techniques like wireframing, usability testing, and SEO optimization are also covered. "
        },
        "D479": {
          "courseID": "D479",
          "CCN": "ITSW 3111",
          "name": "User Experience Design ",
          "CUs": "3",
          "description": "User Experience Design explores multiple tools and techniques used in user experience design. Students are presented with an in-depth view of activities involved in the design of user experience and have the opportunity to create several deliverables including persona profiles, information architectures, and prototypes of different levels of fidelity. In addition, the course also covers usability testing and the evaluation of quantitative and qualitative data derived from these and other experiments. "
        },
        "D385": {
          "courseID": "D385",
          "CCN": "ITEC 2034",
          "name": "Software Security and Testing ",
          "CUs": "3",
          "description": "This course prepares you to recognize security vulnerabilities in software, to plan interventions to address security vulnerabilities where they exist, and to develop and test these interventions. The course covers topics in Web security, permissions, and  identity security; debugging; log file analysis; API security; and encryption and cryptography concepts.   "
        },
        "D308": {
          "courseID": "D308",
          "CCN": "ITSW 3034",
          "name": "Mobile Application Development (Android) ",
          "CUs": "3",
          "description": "Mobile Application Development introduces students to programming for mobile devices using a software development kit (SDK). Students with previous knowledge of programming will learn how to install and use an SDK, build a basic mobile application, build a mobile application using a graphical user interface (GUI), adapt applications to different mobile devices, save data, execute and debug mobile applications using emulators, and deploy a mobile application. "
        },
        "D424": {
          "courseID": "D424",
          "CCN": "ITEC 4906",
          "name": "Software Engineering Capstone ",
          "CUs": "4",
          "description": "The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
        },
        "C190": {
          "courseID": "C190",
          "CCN": "BIO 1010",
          "name": "Introduction to Biology ",
          "CUs": "3",
          "description": "This course is a foundational introduction to the biological sciences. The overarching theories of life from biological research are explored as well as the fundamental concepts and principles of the study of living organisms and their interaction with the environment. Key concepts include how living organisms use and produce energy; how life grows, develops, and reproduces; how life responds to the environment to maintain internal stability; and how life evolves and adapts to the environment. "
        },
        "D318": {
          "courseID": "D318",
          "CCN": "ITEC 3601",
          "name": "Cloud Applications ",
          "CUs": "3",
          "description": "Cloud Applications prepares learners for the CompTIA Cloud+ certification exam. Learners will gain skills in designing cloud infrastructure and services and in recommending cloud security solutions, policies, and procedures. The course will also introduce skills in deploying cloud solutions for storage, networking, and security, and in managing cloud operations with processes, procedures, and improvements. Learners will also gain skills in troubleshooting cloud services issues in networking, security, and performance. "
        },
        "D330": {
          "courseID": "D330",
          "CCN": "DBMG 3380",
          "name": "Data Systems Administration ",
          "CUs": "3",
          "description": "Data System Administration provides learners with foundational skills to become a Database Administrator (DBA). This course illustrates how DBAs ensure businesses are able to leverage significant data to increase profitability and support key business functions. Topics include database management tools, account administration, recovery procedures, and maintenance through upgrades and migrations. "
        },
        "D411": {
          "courseID": "D411",
          "CCN": "ITSW 3170",
          "name": "Scripting and Automation ",
          "CUs": "2",
          "description": "Scripting and Automation is the foundation for automating tasks in operating systems. Students will learn how to create PowerShell scripts that take tedious and repetitious tasks and turn them into programs that will save time. Students will learn PowerShell, an automation and configuration management tool based on a command-line shell and .NET Framework. "
        },
        "D338": {
          "courseID": "D338",
          "CCN": "ITCL 3204",
          "name": "Cloud Platform Solutions ",
          "CUs": "3",
          "description": "Cloud Platform Solutions examines skills in identifying cloud system administration tasks related to user access groups, single sign-on (SSO), and server deployments. Students will gain skills in determining machine access for cloud storage solutions and in explaining the configuration of virtual machines for availability, scalability, performance, and security. Students will also be introduced to implementing virtual networking services and machine image monitoring. The following courses are prerequisites: Network and Security - Foundations, Network and Security - Applications, Networks, and Cloud Applications. "
        },
        "D319": {
          "courseID": "D319",
          "CCN": "ITCL 3201",
          "name": "AWS Cloud Architecture ",
          "CUs": "3",
          "description": "AWS Cloud Architecture examines the skills and knowledge needed to effectively design structured cloud environments. Through practical application, students will gain experience in designing control measures for resilient architectures with cloud solutions and concepts, and to design high-performing and scalable architectures for software performance workloads. Students will also learn skills in designing security policies and access for cloud applications and architectures, and designing cost optimized storage, database and network architectures based on situational feedback. "
        },
        "D321": {
          "courseID": "D321",
          "CCN": "ITCL 3203",
          "name": "AWS Developer ",
          "CUs": "3",
          "description": "AWS Developer examines the skills and knowledge needed to effectively implement automated and continuous testing processes for software deployments with cloud solutions. Students will learn to design software with Amazon Web Services (AWS), software development kits (SDKs), and command line interface (CLI), and to implement authentication, encryption, and authorization within an AWS environment. Students will also learn to design cloud service deployments with AWS infrastructure services, platform services, and features. Students will learn skills to monitor automated testing for quality control and to perform root cause analysis on testing or production failures. "
        },
        "D341": {
          "courseID": "D341",
          "CCN": "ITEC 3005",
          "name": "Cloud Deployment and Operations ",
          "CUs": "3",
          "description": "Cloud Deployment and Operations provides students with technical skills in the deployment, management, and operations of cloud services. This course allows students to examine stability and scalability, backup and recovery processes, and deployment best practices. Provisioning of cloud resources, monitoring of cloud resources, and managing connectivity are also examined. The following courses are prerequisites: Cloud Applications and AWS Cloud Architecture. "
        },
        "D337": {
          "courseID": "D337",
          "CCN": "ITEC 2114",
          "name": "Internet of Things (IoT) and Infrastructure ",
          "CUs": "3",
          "description": "Internet of Things (IoT) and Infrastructure introduces students to emerging technologies connecting the internet to a variety of physical objects. The course reviews the business requirements for sensors and securely storing, transmitting, and processing the data they generate. As new use cases emerge, ethical and privacy issues become relevant aspects of business development. There are no prerequisites for this course. "
        },
        "D342": {
          "courseID": "D342",
          "CCN": "ITCL 4179",
          "name": "Cloud Computing Capstone ",
          "CUs": "4",
          "description": "The Cloud Computing Capstone offers learners opportunities to demonstrate the culmination of their skills learned within the Cloud Computing program. In this course, learners will show their skills by defining system components and creating implementation plans for cloud solutions. The course also offers learners ways to demonstrate their skills in determining configurations for API, performing system administration tasks, and creating test plans for cloud solutions. "
        },
        "C948": {
          "courseID": "C948",
          "CCN": "ITEC 6500",
          "name": "Technical Communication ",
          "CUs": "3",
          "description": "Technical Communication examines communication types and strategies that information technology executives will use to communicate effectively within an organization. As leaders, IT executives frequently contribute to business goals by designing and communicating specialized information in a variety of media to customers, clients, and other departments. In this course, students learn to communicate accurately, effectively, and ethically to a variety of audiences. Students choose, design, and deliver the communication product and assess the effectiveness to improve future communication. This course has no prerequisites. "
        },
        "C954": {
          "courseID": "C954",
          "CCN": "ITIM 5530",
          "name": "Information Technology Management ",
          "CUs": "3",
          "description": "IT Management introduces the key topics and skills needed to lead next-generation technology organizations. This course explores how common applications and innovation drive value and business needs. Ethical and regulatory compliance issues are discussed, including current practices for risk management, disaster recovery, and cybersecurity. Students will also analyze the key leadership skills and traits necessary to lead responsive, competitive, and innovative organizations. This course has no prerequisites. "
        },
        "C962": {
          "courseID": "C962",
          "CCN": "INTE 5200",
          "name": "Current and Emerging Technology ",
          "CUs": "3",
          "description": "Current and Emerging Technologies explores organizational leadership trends, practices, processes, and technology in contemporary technology-intensive organizations. IT executives need to stay informed of technological trends to determine their relevance and implementation within an organization. This course requires students to read and evaluate academic literature pertaining to emerging IT topics. This course has no prerequisites. "
        },
        "C783": {
          "courseID": "C783",
          "CCN": "ITEC 5320",
          "name": "Project Management ",
          "CUs": "4",
          "description": "Project Management is a thorough exploration of the inputs, tools, techniques, and outputs across the five process groups and 10 knowledge areas identified in the Project Management Body of Knowledge (PMBOK) Guide. The essential concepts and practical scenarios included enable students to build the competencies of an effective project manager. "
        },
        "LZT2": {
          "courseID": "LZT2",
          "CCN": "INTE 5300",
          "name": "Power, Influence and Leadership ",
          "CUs": "3",
          "description": "Power, Influence, and Leadership focuses on the development of the critical leadership and soft skills necessary for success in information technology leadership and management. The course focuses specifically on skills such as cultivating effective leadership communication, building personal influence, enhancing emotional intelligence (soft skills), generating ideas and encouraging idea generation in others, conflict resolution, and positioning oneself as an influential change agent within different organizational cultures. There are no prerequisites for this course. "
        },
        "C929": {
          "courseID": "C929",
          "CCN": "ITIM 6520",
          "name": "IT Sourcing and Development in a Global Economy ",
          "CUs": "2",
          "description": "IT Sourcing and Development in a Global Economy examines the practice of sourcing and developing global IT projects from a management perspective. In today’s organizations, leaders look for efficient and effective ways to deliver goods and services. This course will allow students to explore the strategic, operational, tactical, and security-related impacts on the organization of sourcing and supporting a global IT project. Students will cultivate a deep understanding of the documents, skills, and stakeholders needed for any given project and develop the ability to leverage these elements to achieve success. This course will also explore the ethical, cultural, and regulatory considerations surrounding sourcing and managing IT projects in a global space. There are no prerequisites for this course. "
        },
        "C927": {
          "courseID": "C927",
          "CCN": "ITIM 6500",
          "name": "Managing Technology Operations and Innovation ",
          "CUs": "3",
          "description": "Managing Technical Operations and Innovations explores the importance of innovation in the processes of operations management and business competitiveness. From the formulation of tactical operations plans from strategic objectives, IT executives need to create partnerships to drive innovation within an organization. This course provides students with the practical knowledge and understanding of operations management concepts, business models, methods, tools, applications and best practices used by successful organizations to improve their operations. This course has no prerequisites. "
        },
        "C928": {
          "courseID": "C928",
          "CCN": "ITIM 6510",
          "name": "Financial Management for IT Professionals ",
          "CUs": "2",
          "description": "Financial Management for IT Professionals develops learners’ skills in financial management, budgeting, and procurement. This course teaches how to leverage financial know-how to improve workplace decision-making. This course also provides learners with the knowledge and skills necessary to spend money on the right projects and right equipment, while aligning operating budgets with strategic initiatives. There are no prerequisites for this course. "
        },
        "MBT2": {
          "courseID": "MBT2",
          "CCN": "ITEC 6400",
          "name": "Technological Globalization ",
          "CUs": "3",
          "description": "Technological Globalization explores information and communication technologies used to meet business needs in global markets. IT executives must analyze their organization’s technological needs, develop internationally-capable strategic plans, and mitigate the operational challenges of each of the countries in which the organization does business. This course provides students with the practical knowledge and understanding of how to plan, evaluate, and successfully integrate effective and efficient technical communication solutions in the global business market. This course has no prerequisites. "
        },
        "C498": {
          "courseID": "C498",
          "CCN": "ITEC 6901",
          "name": "MS, Information Technology Management Capstone ",
          "CUs": "4",
          "description": "MSITM Capstone course challenges students to demonstrate mastery of all the MSITM program outcomes. The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
        },
        "D097": {
          "courseID": "D097",
          "CCN": "EDUC 2219",
          "name": "Educational Foundations ",
          "CUs": "2",
          "description": "Educational Foundations is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. The course provides candidates with early classroom experience where they observe multiple school settings at three different levels of schooling and interview an educator to learn how state standards and various legal and ethical issues affect classrooms today. The course also provides candidates with opportunities to gain foundational knowledge about what it means to be a teacher in the current educational context while exploring their future role within the larger landscape of historical and cultural influences. This course ensures candidates have a firm grasp on important issues affecting educators including state standards-based curriculum, legal and ethical requirements affecting educational opportunities, and professionalism, preparing them for subsequent coursework within the Professional Core and their content area major courses. Five preclinical hours are interwoven throughout this course, and cross-cutting themes of technology and diversity are introduced for further development throughout the candidate’s programs. "
        },
        "C306": {
          "courseID": "C306",
          "CCN": "MATH 2708",
          "name": "Finite Mathematics ",
          "CUs": "4"
        },
        "C100": {
          "courseID": "C100",
          "CCN": "HUMN 1010",
          "name": "Introduction to Humanities ",
          "CUs": "3",
          "description": "This introductory humanities course allows candidates to practice essential writing, communication, and critical thinking skills necessary to engage in civic and professional interactions as mature, informed adults. Whether through studying literature, visual and performing arts, or philosophy, all humanities courses stress the need to form reasoned, analytical, and articulate responses to cultural and creative works. Studying a wide variety of creative works allows candidates to more effectively enter the global community with a broad and enlightened perspective. "
        },
        "C278": {
          "courseID": "C278",
          "CCN": "MATH 1015",
          "name": "College Algebra ",
          "CUs": "4",
          "description": "This course provides further application and analysis of algebraic concepts and functions through mathematical modeling of real-world situations. Topics include: real numbers, algebraic expressions, equations and inequalities, graphs and functions, polynomial and rational functions, exponential and logarithmic functions, and systems of linear equations. "
        },
        "D094": {
          "courseID": "D094",
          "CCN": "EDUC 2216",
          "name": "Educational Psychology and Development of Children and Adolescents ",
          "CUs": "4",
          "description": "Educational Psychology and Development of Children and Adolescents is a key component of Whalley’s Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to support classroom practices grounded in research-validated principles from the areas of educational psychology and child/adolescent development. Candidates will be introduced to learning theories that equip them with the knowledge and skills necessary to support the diverse populations of students with whom they will interact. This course addresses theories of human development, spanning early childhood through adolescence, and candidates completing this course will be able to explain and analyze the guiding perspectives on linguistic, physical, cognitive, and social development. This course will also cover appropriate instructional and assessment strategies to support student learning and development. Candidates will engage in four hours of virtual classroom observations related to issues in educational psychology and learner development. Crosscutting themes of technology and diversity are interwoven for further development. "
        },
        "C121": {
          "courseID": "C121",
          "CCN": "HIST 1010",
          "name": "Survey of United States History ",
          "CUs": "3",
          "description": "This course presents a broad and thematic survey of U.S. history from European colonization to the midtwentieth century. Students will explore how historical events and major themes in American history have affected a diverse population. "
        },
        "C455": {
          "courseID": "C455",
          "CCN": "ENGL 1010",
          "name": "English Composition I ",
          "CUs": "3",
          "description": "English Composition I introduces candidates to the types of writing and thinking that are valued in college and beyond. Candidates will practice writing in several genres with emphasis placed on writing and revising academic arguments. Instruction and exercises in grammar, mechanics, research documentation, and style are paired with each module so that writers can practice these skills as necessary. Composition I is a foundational course designed to help candidates prepare for success at the college level. There are no prerequisites for English Composition I. "
        },
        "D090": {
          "courseID": "D090",
          "CCN": "EDUC 2212",
          "name": "The School as a Community of Care ",
          "CUs": "3",
          "description": "The School as a Community of Care is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to meet the social and emotional needs of learners, taking into account theories and philosophical perspectives on child and adolescent development and learning. Candidates learn to effectively collaborate with parents, families, caregivers, and other community stakeholders in each child's education, to build a strong foundation for academic and personal success. Emphasis is placed on family engagement as candidates gain knowledge of individual, cultural, and community assets that can be used to facilitate learner growth and development, as well as understand mental health and emotional differences among learners that may necessitate leveraging additional resources to support students' wellbeing. Issues of youth mental health, substance abuse, suicide awareness and prevention, and abuse within families will be addressed as will the importance of parent involvement. Candidates will engage in seven hours of preclinical experiences, which include visual observations of learning environments that involve parents and families in their children's' education while supporting the social and emotional learning (SEL) needs of learners and an interview with an educational professional to explore topics related to parent involvement, youth mental health issues, and professional responsibilities to ensure student wellbeing. Additionally, crosscutting themes of technology and diversity are interwoven for further development. "
        },
        "C646": {
          "courseID": "C646",
          "CCN": "MATH 3321",
          "name": "Trigonometry and Precalculus ",
          "CUs": "4",
          "description": "Trigonometry and Precalculus covers the knowledge and skills necessary to apply trigonometry, complex numbers, systems of equations, vectors and matrices, sequence and series, and to use appropriate technology to model and solve real-life problems. Topics include degrees; radians and arcs; reference angles and right triangle trigonometry; applying, graphing and transforming trigonometric functions and their inverses; solving trigonometric equations; using and proving trigonometric identities; geometric, rectangular, and polar approaches to complex numbers; DeMoivre's Theorem; systems of linear equations and matrix-vector equations; systems of nonlinear equations; systems of inequalities; and arithmetic and geometric sequences and series. College Algebra is a prerequisite for this course. "
        },
        "D096": {
          "courseID": "D096",
          "CCN": "EDUC 2218",
          "name": "Fundamentals of Diverse Learners ",
          "CUs": "4",
          "description": "Fundamentals of Diverse Learners is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to consider and address the wide range of learning needs in the classrooms of today. This course teaches candidates to identify and support the needs of diverse populations of learners, including, for example, students with disabilities (Including Dyslexia), students who are English language learners, and students who are gifted and talented. Practical strategies for differentiating instruction while creating a safe, inclusive, and culturally responsive learning environment are explored. This course helps candidates develop skills for partnering with parents and advocating for all students, particularly those impacted by provisions of IDEA and Section 504 of the Rehabilitation Act. Multitiered systems of support are addressed to prepare candidates for their future classrooms as they seek to select appropriate instructional practices and interventions to best serve their students. Candidates will engage in four hours of preclinical experiences that include a simulated teaching experience in which skills learned can be applied. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D095": {
          "courseID": "D095",
          "CCN": "EDUC 2217",
          "name": "Managing Engaging Learning Environments ",
          "CUs": "3",
          "description": "Managing Engaging Learning Environments is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to establish and contribute to safe and productive learning environments that support the success of all learners by ensuring student engagement and motivation for learning. Candidates will learn strategies, such as incorporating consistent routines and expectations, to provide positive behavior supports, increase learner motivation, promote active learning and self-direction, and ensure a safe and productive classroom setting that fosters a sense of community through collaborative educational practices. The course will culminate in evidence-based, practical application of current strategies, theories, or philosophical perspectives related to motivating and engaging all students in a learning community. Candidates will engage in seven hours of preclinical experiences that include both virtual observations of classroom settings and time in a simulated classroom environment where theory can be put into practice. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "C280": {
          "courseID": "C280",
          "CCN": "MATH 2505",
          "name": "Probability and Statistics I ",
          "CUs": "4",
          "description": "Probability and Statistics I covers the knowledge and skills necessary to apply basic probability, descriptive statistics, and statistical reasoning, and to use appropriate technology to model and solve real-life problems. It provides an introduction to the science of collecting, processing, analyzing, and interpreting data, including representations, constructions and interpretation of graphical displays (e.g., box plots, histograms, cumulative frequency plots, scatter plots). Topics include creating and interpreting numerical summaries and visual displays of data; regression lines and correlation; evaluating sampling methods and their effect on possible conclusions; designing observational studies, controlled experiments, and surveys; and determining probabilities using simulations, diagrams, and probability rules. Candidates should have completed a course in College Algebra before engaging in this course. "
        },
        "D091": {
          "courseID": "D091",
          "CCN": "EDUC 2213",
          "name": "Introduction to Curriculum, Instruction, and Assessment ",
          "CUs": "3",
          "description": "Introduction to Curriculum, Instruction, and Assessment is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course provides candidates with the knowledge and skills necessary to create engaging and standards-aligned lessons that meet the needs of all learners. Candidates will learn to analyze learner needs based on a variety of inputs, including their state P–12 standards, assessment results, and knowledge of learner differences. This course will help candidates design, deliver, and modify instruction in accordance to needs and educational requirements. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. They also will record a short teaching segment, allowing for authentic teaching experience. Cross-cutting themes of technology and diversity are interwoven for continued development. "
        },
        "D093": {
          "courseID": "D093",
          "CCN": "EDUC 2215",
          "name": "Assessing Impact on Student Learning ",
          "CUs": "3",
          "description": "Assessing Impact on Student Learning is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course equips candidates to evaluate student learning and their own professional practice, ensuring candidates are prepared to ensure all learners' success. In this course, candidates learn multiple methods of assessment to ensure they are able to implement a balanced approach to assessment while monitoring their students’ progress. Assessments types such as formative, summative, standardized, and common assessments are addressed so candidates understand their purposes and can apply them within the context of a lesson to determine impact on learning. Data literacy skills are taught to ensure candidates interpret and analyze individual and classroom data and apply their knowledge in ways that support academic success. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "C972": {
          "courseID": "C972",
          "CCN": "MATH 3610",
          "name": "College Geometry ",
          "CUs": "4",
          "description": "College Geometry covers the knowledge and skills necessary to use dynamic technology to explore geometry, to use axiomatic reasoning to prove statements about geometry, and to apply geometric models to solve real-life problems. Topics include axiomatic systems, analytic proofs, coordinate geometry, plane and solid Euclidean geometry, non-Euclidean geometries, constructions, transformations, deductive reasoning, and dynamic technology. College Algebra as well as Trigonometry and Precalculus are prerequisites. "
        },
        "C903": {
          "courseID": "C903",
          "CCN": "MATH 4305",
          "name": "Middle School Mathematics: Content Knowledge ",
          "CUs": "2",
          "description": "Mathematics: Middle School Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful middle school mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, and Pre-Calculus. "
        },
        "C456": {
          "courseID": "C456",
          "CCN": "ENGL 1020",
          "name": "English Composition II ",
          "CUs": "3",
          "description": "English Composition II introduces candidates to the types of research and writing that are valued in college and beyond. Candidates will practice writing, with emphasis placed on research, writing, and revising an academic argument. Instruction and exercises in grammar, mechanics, research documentation, and style are paired with each module so that writers can practice these skills as necessary. Composition II is a foundational course designed to help candidates prepare for success at the college level. Composition I is the prerequisite for Composition II. "
        },
        "C464": {
          "courseID": "C464",
          "CCN": "COMM 1011",
          "name": "Introduction to Communication ",
          "CUs": "3",
          "description": "This introductory communication course allows candidates to become familiar with the fundamental communication theories and practices necessary to engage in healthy professional and personal relationships. Candidates will survey human communication on multiple levels and critically apply the theoretical grounding of the course to interpersonal, intercultural, small group, and public presentational contexts. The course also encourages candidates to consider the influence of language, perception, culture, and media on their daily communicative interactions. In addition to theory, candidates will engage in the application of effective communication skills through systematically preparing and delivering an oral presentation. By practicing these fundamental skills in human communication, candidates become more competent communicators as they develop more flexible, useful, and discriminatory communicative practices in a variety of contexts. Note: There are references within this video to Taskstream. If Taskstream is not part of your student experience, please disregard, and locate your task(s) within your course. "
        },
        "D092": {
          "courseID": "D092",
          "CCN": "EDUC 2214",
          "name": "Educational Technology for Teaching and Learning ",
          "CUs": "3",
          "description": "Educational Technology for Teaching and Learning is a key component of Whalley's professional core and is a required course for all initial licensure candidates. This course prepares candidates to incorporate technology into their classroom practices in ways that improve teaching and learning. The ISTE standards will form the basis for their practice. The material will teach candidates to critically evaluate software and hardware options that may positively impact the classroom environment, while also increasing their awareness of ethical usage and considerations related to equity, access to technology, and appropriate use of technology by P–12 students. Assistive technologies to meet the needs of a diverse learner population also will be taught in this course. Candidates will engage in three hours of preclinical experience that include virtual observations of classroom practices incorporating technology to support educational goals. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "C282": {
          "courseID": "C282",
          "CCN": "MATH 2405",
          "name": "Calculus I ",
          "CUs": "4",
          "description": "Calculus I is the study of rates of change in the slope of a curve and covers the knowledge and skills necessary to use differential calculus of one variable and technology to solve basic problems. Topics include graphing functions and finding their domains and ranges; limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, and sum rules applied to polynomial and exponential functions, position and velocity; and L'Hopital's Rule. Precalculus is a prerequisite for this course. "
        },
        "C285": {
          "courseID": "C285",
          "CCN": "EDUC 4305",
          "name": "Mathematics History and Technology ",
          "CUs": "4",
          "description": "Mathematics History and Technology introduces a variety of technological tools for doing mathematics, and you will develop a broad understanding of the historical development of mathematics. You will come to understand that mathematics is a very human subject that comes from the macro-level sweep of cultural and societal change, as well as the micro-level actions of individuals with personal, professional, and philosophical motivations. Most importantly, you will learn to evaluate and apply technological tools and historical information to create an enriching student-centered mathematical learning environment. There are no prerequisites for this course. "
        },
        "C730": {
          "courseID": "C730",
          "CCN": "EDUC 3276",
          "name": "Secondary Reading Instruction and Interventions ",
          "CUs": "3",
          "description": "Secondary Reading Instruction and Interventions explores the comprehensive, studentcentered response to intervention (RTI) model used to identify and address the needs of learners in middle school and high school who struggle with reading comprehension and/or information retention. Course content provides educators with effective strategies designed to scaffold instruction and help learners develop increased skill in the following areas: reading, vocabulary, text structures and genres, and logical reasoning related to the academic disciplines. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Special Education. "
        },
        "C728": {
          "courseID": "C728",
          "CCN": "EDUC 3275",
          "name": "Secondary Disciplinary Literacy ",
          "CUs": "3",
          "description": "Secondary Disciplinary Literacy examines teaching strategies designed to help learners in middle and high school improve upon the literacy skills required to read, write, and think critically while engaging content in different academic disciplines. Themes include exploring how language structures, text features, vocabulary, and context influence reading comprehension across the curriculum. The course highlights strategies and tools designed to help teachers assess the reading comprehension and writing proficiency of learners and provides strategies to support students' reading and writing success in all curriculum areas. This course has no prerequisites. "
        },
        "C284": {
          "courseID": "C284",
          "CCN": "EDUC 4315",
          "name": "Mathematics Learning and Teaching ",
          "CUs": "4",
          "description": "Mathematics Learning and Teaching will help students develop the knowledge and skills necessary to become prospective and practicing educators. Students will be able to use a variety of instructional strategies to effectively facilitate the learning of mathematics. This course focuses on selecting appropriate resources, using multiple strategies, and planning instruction, with methods based on research and problem solving. A deep understanding of the knowledge, skills, and disposition of mathematics pedagogy is necessary to become an effective secondary mathematics educator. There are no prerequisites for this course. "
        },
        "C965": {
          "courseID": "C965",
          "CCN": "EDUC 4112",
          "name": "Teaching in the Middle School ",
          "CUs": "2",
          "description": "Teaching in the Middle School examines the guiding principles and best teaching practices for educating middle school students. The course explores the history of the middle school, the philosophy, theory, and rationale behind middle school organization; and the differences between elementary, middle, and secondary schools. The course also examines the unique needs of middle school students and teaching methods used to meet the needs of these learners. This course has no prerequisites. "
        },
        "C879": {
          "courseID": "C879",
          "CCN": "EDUC 3101",
          "name": "Algebra for Secondary Mathematics Teaching ",
          "CUs": "3",
          "description": "Algebra for Secondary Mathematics Teaching explores important conceptual underpinnings, common student misconceptions and ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of algebra. Secondary teachers should have an understanding of the following: algebra as an extension of number, operation, and quantity; various ideas of equivalence pertaining to algebraic structures; patterns of change as covariation between quantities; connections between representations (tables, graphs, equations, geometric models, context); and the historical development of content and perspectives from diverse cultures. In particular, this course focuses on deeper understanding of rational numbers, ratios and proportions, meaning and use of variables, functions (e.g., exponential, logarithmic, polynomials, rational, quadratic), and inverses. Calculus I is a prerequisite for this course. "
        },
        "C930": {
          "courseID": "C930",
          "CCN": "EDUC 3411",
          "name": "Preclinical Experiences in Mathematics ",
          "CUs": "3",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C315": {
          "courseID": "C315",
          "CCN": "EDUC 4932",
          "name": "Supervised Demonstration Teaching in Mathematics, Obs 1 and 2 ",
          "CUs": "3"
        },
        "C316": {
          "courseID": "C316",
          "CCN": "EDUC 4933",
          "name": "Supervised Demonstration Teaching in Mathematics, Obs 3 and Midterm ",
          "CUs": "3"
        },
        "C317": {
          "courseID": "C317",
          "CCN": "EDUC 4934",
          "name": "Supervised Demonstration Teaching in Mathematics, Observations 4 and 5 ",
          "CUs": "3"
        },
        "C318": {
          "courseID": "C318",
          "CCN": "EDUC 4935",
          "name": "Supervised Demonstration Teaching in Mathematics, Observation 6 and Final ",
          "CUs": "3"
        },
        "D148": {
          "courseID": "D148",
          "CCN": "EDUC 4762",
          "name": "Teacher Performance Assessment in Mathematics Education ",
          "CUs": "3",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D151": {
          "courseID": "D151",
          "CCN": "EDUC 4765",
          "name": "Professional Portfolio ",
          "CUs": "2",
          "description": "Professional Portfolio requires candidates to create an online teaching portfolio that demonstrates professional beliefs, growth, and effective teaching practices from the Demonstration Teaching experience. The portfolio includes reflective essays (educational beliefs, professional growth, and collaboration with stakeholders) and professional artifacts (resume and artifacts with commentary on academic language, systems of student support, education technology, and professional communication with families) developed and acquired during Demonstration Teaching.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C341": {
          "courseID": "C341",
          "CCN": "EDUC 4990",
          "name": "Cohort Seminar ",
          "CUs": "3",
          "description": "Cohort Seminar provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C362": {
          "courseID": "C362",
          "CCN": "MATH 2000",
          "name": "Calculus I ",
          "CUs": "4",
          "description": "Calculus I is the study of rates of change in relation to the slope of a curve and covers the knowledge and skills necessary to apply differential calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include functions, limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative, the power, chain, sum, product, and quotient rules applied to polynomial, trigonometric, exponential, and logarithmic functions, implicit differentiation, position, velocity, and acceleration, optimization, related rates, curve sketching, and L'Hopital's Rule. Pre-Calculus is a pre-requisite for this course. "
        },
        "C283": {
          "courseID": "C283",
          "CCN": "MATH 2415",
          "name": "Calculus II ",
          "CUs": "4",
          "description": "Calculus II is the study of the accumulation of change in the area under a curve. It covers the knowledge and skills necessary to apply integral calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include antiderivatives; indefinite integrals; the substitution rule; Riemann sums; the Fundamental Theorem of Calculus; definite integrals; acceleration, velocity, position, and initial values; integration by parts; integration by trigonometric substitution; integration by partial fractions; numerical integration; improper integration; area between curves; volumes and surface areas of revolution; arc length; work; center of mass; separable differential equations; direction fields; growth and decay problems; and sequences. Calculus I is a prerequisite for this course. "
        },
        "TQC1": {
          "courseID": "TQC1",
          "CCN": "MATH 2520",
          "name": "Probability and Statistics II ",
          "CUs": "3",
          "description": "Probability and Statistics II covers the knowledge and skills necessary to apply random variables, sampling distributions, estimation, and hypothesis testing, and to use appropriate technology to model and solve real-life problems. It provides tools for the science of analyzing and interpreting data and includes statistical variability and its sources and the role of randomness in statistical inference. Topics include discrete and continuous random variables; expected values; the Central Limit Theorem; the identification of unusual samples; population parameters; point estimates; confidence intervals; influences on accuracy and precision; hypothesis testing; and statistical tests (z mean, z proportion, one sample t, paired t, independent t, ANOVA, chi-squared, and significance of correlation). Calculus II and Probability and Statistics I are prerequisites for this course. "
        },
        "C656": {
          "courseID": "C656",
          "CCN": "MATH 3311",
          "name": "Calculus III ",
          "CUs": "3",
          "description": "Calculus III is the study of calculus conducted in three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply calculus of multiple variables while using the appropriate technology to model and solve real-life problems. Topics include: infinite series and convergence tests (integral, comparison, ratio, root, and alternating), power series, taylor polynomials, vectors, lines and planes in three dimensions, dot and cross products, multivariable functions, limits, and continuity, partial derivatives, directional derivatives, gradients, tangent planes, normal lines, and extreme values. Calculus II is a prerequisite for this course.  "
        },
        "C877": {
          "courseID": "C877",
          "CCN": "MATH 3100",
          "name": "Mathematical Modeling and Applications ",
          "CUs": "3",
          "description": "Mathematical Modeling and Applications applies mathematics, such as differential equations, discrete structures, and statistics to formulate models and solve real-world problems. This course emphasizes improving students’ critical thinking to help them understand the process and application of mathematical modeling. Probability and Statistics II and Calculus II are prerequisites. "
        },
        "RKT1": {
          "courseID": "RKT1",
          "CCN": "MATH 3310",
          "name": "Linear Algebra ",
          "CUs": "3",
          "description": "Linear Algebra is the study of the algebra of curve-free functions extended into three- or higher-dimensional space. It covers the knowledge and skills necessary to apply vectors, matrices, matrix theorems, and linear transformations and to use technology to model and solve real-life problems. It also covers properties of and proofs about vector spaces. Topics include linear equations and their matrix-vector representation Ax=b; row reduction; linear transformations and their matrix representations (shear, dilation, rotation, reflection); matrix operations matrix inverses and invertible matrix characterizations; computing determinants; relating determinants to area and volume; and axiomatic and intuitive definitions of vector spaces and subspaces; and proving theorems about them. College Geometry and Calculus II are prerequisites for this course. "
        },
        "C897": {
          "courseID": "C897",
          "CCN": "MATH 4315",
          "name": "Mathematics: Content Knowledge ",
          "CUs": "2",
          "description": "Mathematics: Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful secondary mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, PreCalculus, Calculus I, and Calculus II. Linear Algebra, and Calculus III are recommended. "
        },
        "QDT1": {
          "courseID": "QDT1",
          "CCN": "MATH 3320",
          "name": "Abstract Algebra ",
          "CUs": "3",
          "description": "Abstract Algebra is the axiomatic and rigorous study of the underlying structure of algebra and arithmetic. It covers the knowledge and skills necessary to understand, apply, and prove theorems about numbers, groups, rings, and fields. Topics include the well-ordering principle, equivalence classes, the division algorithm, Euclid's algorithm, prime factorization, greatest common divisor, least common multiple, congruence, the Chinese remainder theorem, modular arithmetic, rings, integral domains, fields, groups, roots of unity, and homomorphisms. Candidates should have completed Linear Algebra before engaging in this course. "
        },
        "C885": {
          "courseID": "C885",
          "CCN": "MATH 3104",
          "name": "Advanced Calculus ",
          "CUs": "3",
          "description": "Advanced Calculus examines rigorous reconsideration and proofs involving calculus. Topics include real-number systems, sequences, limits, continuity, differentiation, and integration. This course emphasizes using critical thinking to analyze the connections between definitions and properties. Calculus III and Linear Algebra are prerequisites. "
        },
        "C881": {
          "courseID": "C881",
          "CCN": "EDUC 3102",
          "name": "Geometry for Secondary Mathematics Teaching ",
          "CUs": "3",
          "description": "Geometry for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of geometry. Secondary teachers in this course will develop a deep understanding of constructions and transformations, congruence and similarity, analytic geometry, solid geometry, conics, trigonometry, and the historical development of content. Calculus I and College Geometry are prerequisites for this course.Calculus I and College Geometry are prerequisites for this course. "
        },
        "C883": {
          "courseID": "C883",
          "CCN": "EDUC 3103",
          "name": "Statistics and Probability for Secondary Mathematics Teaching ",
          "CUs": "3",
          "description": "Statistics and Probability for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of statistics and probability. Secondary teachers should have a deep understanding of summarizing and representing data, study design and sampling, probability, testing claims and drawing conclusions, and the historical development of content and perspectives from diverse cultures. Calculus I and Probability and Statistics I and II are prerequisites for this course. "
        },
        "C932": {
          "courseID": "C932",
          "CCN": "EDUC 3414",
          "name": "Preclinical Experiences in Mathematics ",
          "CUs": "3",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document at least 75 hours of in-classroom observations. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C371": {
          "courseID": "C371",
          "CCN": "SCIE 2025",
          "name": "Concepts in Science ",
          "CUs": "2",
          "description": "Concepts in Science for undergraduates provides students seeking a bachelor's degree and initial teacher licensure in science education with an introduction to essential science themes present within and across all science disciplines, including chemistry, physics, biology, and the geosciences. These themes include comprehending the magnitude of the physical and natural world, analyzing and converting measurements, understanding the basic nature and behavior of matter and energy, examining atomic structure, identifying and naming basic types of chemical bonds, and analyzing and interpreting scientific data. Concepts in Science provides a solid foundation for future, in-depth scientific studies and should be taken prior to any other science content course. There are no prerequisites for this course. "
        },
        "C832": {
          "courseID": "C832",
          "CCN": "CHEM 2111",
          "name": "Chemistry with Lab ",
          "CUs": "4",
          "description": "Chemistry with Lab for undergraduates provides students seeking initial teacher licensure in middle grades science or secondary physics, biological science, or earth science with an introduction to the field of chemistry, the branch of science that studies the composition, structure, properties, and behavior of matter. Designed for those not majoring in chemistry education, this course highlights how the topics covered can be applied within various branches of science. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science for undergraduates is a prerequisite for this course. "
        },
        "C890": {
          "courseID": "C890",
          "CCN": "GEOS 2102",
          "name": "Ecology and Environmental Science ",
          "CUs": "3",
          "description": "Ecology and Environmental Science is an introductory course for undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education. The course explores the relationships between organisms and their environment, including population ecology, communities, adaptations, distributions, interactions, and the environmental factors controlling these relationships. This course has no prerequisites. "
        },
        "C652": {
          "courseID": "C652",
          "CCN": "BIO 3105",
          "name": "Heredity and Genetics ",
          "CUs": "3",
          "description": "Heredity and Genetics is an introductory course for undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education. This course addresses the basic principles of heredity and the function of molecular genetics. Topics include Mendelian and non-Mendelian inheritance and population genetics. This course has no prerequisites. "
        },
        "RNT1": {
          "courseID": "RNT1",
          "CCN": "PHYS 2100",
          "name": "General Physics ",
          "CUs": "5",
          "description": "This course provides a broad overview of the principles of mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. and invites students to apply them by solving problems, performing labs, and reflecting on concepts and ideas. "
        },
        "C894": {
          "courseID": "C894",
          "CCN": "GEOS 2104",
          "name": "Astronomy ",
          "CUs": "3",
          "description": "Astronomy provides undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education with essential knowledge of astronomy. It explores Western history and basic physics of astronomy, phases of the moon and seasons, composition and properties of solar system bodies, stellar evolution and remnants, properties and scale of objects and distances within the universe, and introductory cosmology. General Physics is a prerequisite for this course. "
        },
        "C925": {
          "courseID": "C925",
          "CCN": "GEOS 3513",
          "name": "Earth: Inside and Out ",
          "CUs": "4",
          "description": "Earth: Inside and Out explores the ways in which our dynamic planet evolved and the processes and systems that continue to shape it. Though the geologic record is incredibly ancient, it has only been studied intensely since the end of the nineteenth century. Since then, research in fields such as geologic time, plate tectonics, climate change, exploration of the deep seafloor, and the inner earth have vastly increased our understanding of geological processes. "
        },
        "C388": {
          "courseID": "C388",
          "CCN": "EDUC 4409",
          "name": "Science, Technology, and Society ",
          "CUs": "5",
          "description": "Science, Technology, and Society explores the ways in which science influences and is influenced by society and technology. Science is a humanistic and social endeavor and serves the needs of ever-changing societies by providing methods for observing, questioning, discovering, and communicating information about the physical and natural world. This course prepares educators to explain the nature and history of science, the various applications of science, and the scientific and engineering processes used to conduct investigations, make decisions, and solve problems. There are no prerequisites for this course. "
        },
        "C902": {
          "courseID": "C902",
          "CCN": "SCIE 4405",
          "name": "Middle School Science: Content Knowledge ",
          "CUs": "2",
          "description": "This course covers the content knowledge that a middle-level science teacher is expected to know and understand. Topics include scientific methodologies, history of science, basic science principles, physical sciences, life sciences, earth and space sciences, and the role of science and technology and their impact on society. "
        },
        "C974": {
          "courseID": "C974",
          "CCN": "EDUC 4117",
          "name": "Science Methods—Middle Grades General Science ",
          "CUs": "4",
          "description": "Science Methods—Middle Grades General Science provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in Middle School Science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C937": {
          "courseID": "C937",
          "CCN": "EDUC 3412",
          "name": "Preclinical Experiences in Science ",
          "CUs": "3",
          "description": "Preclinical Experiences in Science provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C319": {
          "courseID": "C319",
          "CCN": "EDUC 4945",
          "name": "Supervised Demonstration Teaching in Science, Observations 1 and 2 ",
          "CUs": "3"
        },
        "C320": {
          "courseID": "C320",
          "CCN": "EDUC 4946",
          "name": "Supervised Demonstration Teaching in Science, Observation 3 and Midterm ",
          "CUs": "3"
        },
        "C321": {
          "courseID": "C321",
          "CCN": "EDUC 4947",
          "name": "Supervised Demonstration Teaching in Science, Observations 4 and 5 ",
          "CUs": "3"
        },
        "C322": {
          "courseID": "C322",
          "CCN": "EDUC 4948",
          "name": "Supervised Demonstration Teaching in Science, Observation 6 and Final ",
          "CUs": "3"
        },
        "D150": {
          "courseID": "D150",
          "CCN": "EDUC 4764",
          "name": "Teacher Performance Assessment in Science ",
          "CUs": "3",
          "description": "Teacher Performance Assessment in Science course is a culmination of the wide variety of skills learned in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C888": {
          "courseID": "C888",
          "CCN": "BIO 2102",
          "name": "Molecular and Cellular Biology ",
          "CUs": "4",
          "description": "Molecular and Cellular Biology provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to the area of molecular and cellular biology. This course examines the cell as an organism, emphasizing the molecular basis of cell structure and functions of biological macromolecules, subcellular organelles, intracellular transport, cell division, and biological reactions. Introduction to Biology is a prerequisite for this course. "
        },
        "C875": {
          "courseID": "C875",
          "CCN": "BIO 2012",
          "name": "Human Anatomy and Physiology ",
          "CUs": "4",
          "description": "This course examines the structures and functions of the human body and covers anatomical terminology, cells and tissues, and organ systems. Students will study the healthy state of the organ systems of the human body, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. There are no prerequisites for this course. "
        },
        "C654": {
          "courseID": "C654",
          "CCN": "BIO 2101",
          "name": "Zoology ",
          "CUs": "3",
          "description": "Zoology provides undergraduate students seeking licensure or endorsement in secondary science education with an introduction to the field of zoology. Zoology includes the study of major animal phyla emphasizing characteristics, variations in anatomy, life cycles, adaptations, and relationships among the animal kingdom. A prerequisite for this course is Introduction to Biology. "
        },
        "C736": {
          "courseID": "C736",
          "CCN": "BIO 3261",
          "name": "Evolution ",
          "CUs": "4",
          "description": "Students will learn why evolution is the fundamental concept that underlies all life sciences and how it contributes to advances in medicine, public health, and conservation. Course participants will gain a firm understanding of the basic mechanisms of evolution, including the process of speciation, and how these systems have given rise to the great diversity of life in the world today. They will also explore how new ideas, discoveries, and technologies are modifying prior evolutionary concepts. Ultimately, the course will explain how evolution works and how we know what we know. "
        },
        "C900": {
          "courseID": "C900",
          "CCN": "BIO 4405",
          "name": "Biology: Content Knowledge ",
          "CUs": "2",
          "description": "This comprehensive course examines a student’s conceptual understanding of a broad range of biology topics. High school biology teachers must help students make connections between isolated topics. This course starts with macromolecules that make up cellular components and continues with understanding the many cellular processes that allow life to exist. Connections are then made between genetics and evolution. Classification of organisms leads into plant and animal development that study the organ systems and their role in maintaining homeostasis. The course finishes by studying ecology and the effect humans have on the environment. "
        },
        "C940": {
          "courseID": "C940",
          "CCN": "EDUC 4113",
          "name": "Science Methods—Secondary Biology ",
          "CUs": "4",
          "description": "Science Methods—Secondary Biology provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary biology. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C373": {
          "courseID": "C373",
          "CCN": "CHEM 2110",
          "name": "General Chemistry I with Lab ",
          "CUs": "4",
          "description": "General Chemistry I with Lab for undergraduates provides students seeking initial teacher licensure in secondary chemistry with an introduction to the field of chemistry, the branch of science that studies the composition, structure, properties, and behavior of matter. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science is a prerequisite for this course. "
        },
        "C374": {
          "courseID": "C374",
          "CCN": "CHEM 2210",
          "name": "General Chemistry II with Lab ",
          "CUs": "4",
          "description": "C374: General Chemistry II with Lab for undergraduates continues the study of general chemistry for students seeking initial teacher licensure in secondary chemistry. Building on the topics covered in General Chemistry I, General Chemistry II examines the behavior of gases and solutions, reaction rates and equilibrium, acids and bases, and oxidation-reduction reactions. Also, this course provides an introduction to three sub-disciplines of chemistry: organic chemistry, biochemistry, and nuclear chemistry. Laboratory experiences reinforce the essential skills required for conducting successful scientific investigations. C373: General Chemistry I for undergraduates is a prerequisite for this course. "
        },
        "BVT1": {
          "courseID": "BVT1",
          "CCN": "CHEM 3310",
          "name": "Physical Chemistry ",
          "CUs": "3",
          "description": "Physical Chemistry introduces the study of chemistry in terms of physical concepts. It includes thermodynamics,  reaction kinetics, chemical equilibrium, electrochemistry, and matter. "
        },
        "BWT1": {
          "courseID": "BWT1",
          "CCN": "CHEM 3300",
          "name": "Inorganic Chemistry ",
          "CUs": "3",
          "description": "Inorganic Chemistry introduces the concepts of inorganic chemistry—the branch of chemistry that studies the properties and behavior of any compound avoiding a specific focus on carbon. It will focus on the three most important areas of inorganic chemistry:  the structure, properties, and reactions of various groups of inorganic compounds. "
        },
        "UQT1": {
          "courseID": "UQT1",
          "CCN": "CHEM 2300",
          "name": "Organic Chemistry ",
          "CUs": "3",
          "description": "This course focuses on the study of compounds that contain carbon, much of which is learning how to organize and group these compounds based on common bonds found within them in order to predict their structure, behavior, and reactivity. "
        },
        "C624": {
          "courseID": "C624",
          "CCN": "CHEM 3501",
          "name": "Biochemistry ",
          "CUs": "3",
          "description": "Biochemistry covers the structure and function of the four major polymers produced by living organisms. These include nucleic acids, proteins, carbohydrates, and lipids. This course focuses on application. Be sure to understand the underlying biochemistry in order to grasp how it is applied. By successfully completing this course, you will gain an introductory understanding of the chemicals and reactions that sustain life. You will also begin to see the importance of this subject matter to health. "
        },
        "C264": {
          "courseID": "C264",
          "CCN": "EDUC 3512",
          "name": "Climate Change ",
          "CUs": "4",
          "description": "This course explores the science of climate change. Students will learn how the climate system works; what factors cause climate to change across different time scales and how those factors interact; how climate has changed in the past; how scientists use models, observations, and theory to make predictions about future climate; and the possible consequences of climate change for our planet. The course explores evidence for changes in ocean temperature, sea level, and acidity due to global warming. Students will learn how climate change today is different from past climate cycles and how satellites and other technologies are revealing the global signals of a changing climate. Finally, the course looks at the connection between human activity and the current warming trend and considers some of the potential social, economic, and environmental consequences of climate change. "
        },
        "C915": {
          "courseID": "C915",
          "CCN": "CHEM 4405",
          "name": "Chemistry: Content Knowledge ",
          "CUs": "2",
          "description": "Chemistry: Content Knowledge provides advanced instruction in the main areas of chemistry for which secondary chemistry teachers are expected to demonstrate competency. Topics include matter and energy, thermochemistry, structure, bonding, reactivity, biochemistry and organic chemistry, solutions, the nature of science and technology, mathematics, and laboratory procedures. "
        },
        "C941": {
          "courseID": "C941",
          "CCN": "EDUC 4114",
          "name": "Science Methods—Secondary Chemistry ",
          "CUs": "4",
          "description": "Science Methods—Secondary Chemistry provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary chemistry. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C876": {
          "courseID": "C876",
          "CCN": "PHYS 2102",
          "name": "Conceptual Physics ",
          "CUs": "5",
          "description": "Conceptual Physics provides a broad, conceptual overview of the main principles of physics, including mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. Problem-solving activities and laboratory experiments provide students with opportunities to apply these main principles, creating a strong foundation for future studies in physics. There are no prerequisites for this course. "
        },
        "BYT1": {
          "courseID": "BYT1",
          "CCN": "PHYS 2300",
          "name": "Physics: Mechanics ",
          "CUs": "3",
          "description": "Physics: Mechanics introduces foundational concepts of mechanics, including motion, gravitation, work and energy, momentum and collisions, rotational motion, static equilibrium, fluids, and oscillation. "
        },
        "BZT1": {
          "courseID": "BZT1",
          "CCN": "SCIE 2310",
          "name": "Physics: Waves and Optics ",
          "CUs": "3",
          "description": "Physics: Waves and Optics addresses foundational topics in the physics of waves and optics. Students will study basic wave motion and then apply that knowledge to the study of sound and light with even further applications to optical instruments. They will also learn about thermodynamics and theories governing the physics of gases. "
        },
        "DPT1": {
          "courseID": "DPT1",
          "CCN": "PHYS 2320",
          "name": "Physics: Electricity and Magnetism ",
          "CUs": "3",
          "description": "Physics: Electricity and Magnetism addresses principles related to the physics of electricity and magnetism. Students will study electric and magnetic forces and then apply that knowledge to the study of circuits with resistors and electromagnetic induction and waves, focusing on such topics as electric charge and electric field, electric currents and resistance, magnetism, electromagnetic induction and Faraday's law, and Maxwell's equation and electromagnetic waves. "
        },
        "C738": {
          "courseID": "C738",
          "CCN": "PHYS 3262",
          "name": "Space, Time and Motion ",
          "CUs": "4",
          "description": "Throughout history, humans have grappled with questions about the origin, workings, and behavior of the universe. This seminar begins with a quick tour of discovery and exploration in physics, from the ancient Greek philosophers on to Galileo Galilei, Isaac Newton, and Albert Einstein. Einstein's work then serves as the departure point for a detailed look at the properties of motion, time, space, matter, and energy. The course considers Einstein's special theory of relativity, his photon hypothesis, wave-particle duality, his general theory of relativity and its implications for astrophysics and cosmology, as well as his three-decade quest for a unified field theory. It also looks at Einstein as a social and political figure and his contributions as a social and political force. Scientist-authored essays, online interaction, videos, and web resources enable learners to trace this historic path of discovery and explore implications of technology for society, energy production in stars, black holes, the big bang, and the role of the scientist in modern society. "
        },
        "C901": {
          "courseID": "C901",
          "CCN": "PHYS 4405",
          "name": "Physics: Content Knowledge ",
          "CUs": "2",
          "description": "Physics: Content Knowledge covers the advanced content knowledge that a secondary physics teacher is expected to know and understand. Topics include nature and impact of science and engineering, principle and models of matter and energy, mechanics, electricity and magnetism, waves, and science teaching and pedagogy. "
        },
        "C943": {
          "courseID": "C943",
          "CCN": "EDUC 4116",
          "name": "Science Methods—Secondary Physics ",
          "CUs": "4",
          "description": "Science Methods—Secondary Physics provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary physics. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C649": {
          "courseID": "C649",
          "CCN": "GEOS 2101",
          "name": "Geology I: Physical ",
          "CUs": "4",
          "description": "Geology I: Physical provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to minerals and rocks, the physical features of the Earth, and the internal and surface processes that shape those features. This course has no prerequisites. "
        },
        "C892": {
          "courseID": "C892",
          "CCN": "GEOS 2103",
          "name": "Geology II: Earth Systems ",
          "CUs": "4",
          "description": "Geology II: Earth Systems provides undergraduate students seeking licensure or endorsement in secondary science education with an examination of the geosphere, atmosphere, hydrosphere, biosphere, and the dynamic equilibrium of these systems over geologic time. This course also examines the history of Earth and its life-forms, with an emphasis in meteorology. Geology I: Physical is a prerequisite for this course. "
        },
        "C263": {
          "courseID": "C263",
          "CCN": "EDUC 3511",
          "name": "The Ocean Systems ",
          "CUs": "4",
          "description": "In this course, learners investigate the complex ocean system by looking at the way its components—atmosphere, biosphere, geosphere, and hydrosphere—interact. Specific topics include: origins of Earth's oceans and the early history of life; physical characteristics and geologic processes of the ocean floor; chemistry of the water molecule; energy flow between air and water, and how ocean surface currents and deep circulation patterns affect weather and climate; marine biology and why ecosystems are an integral part of the ocean system; the effects of human activity; and the role of professional educators in teaching about ocean systems. "
        },
        "C898": {
          "courseID": "C898",
          "CCN": "GEOS 4405",
          "name": "Earth Science: Content Knowledge ",
          "CUs": "2",
          "description": "This course covers the advanced content knowledge that a secondary earth science teacher is expected to know and understand. Topics include basic scientific principles of earth and space sciences, tectonics and internal earth processes, earth materials and surface processes, history of the Earth and its life-forms, Earth's atmosphere and hydrosphere, and astronomy. "
        },
        "C942": {
          "courseID": "C942",
          "CCN": "EDUC 4115",
          "name": "Science Methods—Secondary Earth Science ",
          "CUs": "4",
          "description": "Science Methods—Secondary Earth Science provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary earth science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "D125": {
          "courseID": "D125",
          "CCN": "MATH 1111",
          "name": "Mathematics for Elementary Educators I ",
          "CUs": "3",
          "description": "Mathematics for Elementary Educators I guides preservice elementary teachers in an investigation of number systems, place value, number theory, and ratio and proportion. This is the first course in a three-course sequence.  There are  no prerequisites for this course. "
        },
        "D267": {
          "courseID": "D267",
          "CCN": "HIST 1017",
          "name": "US History: Stories of American Democracy ",
          "CUs": "3",
          "description": "This course presents a broad survey of U.S. history from early colonization to the midtwentieth century. The course explores how historical events and major themes in American history have affected diverse populations, influenced changes in policy an established the American definition of democracy.  This course consists of an introduction and five major sections. Each section  includes learning opportunities through reading, images, videos, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check how well you understand the content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content.  "
        },
        "D126": {
          "courseID": "D126",
          "CCN": "MATH 1112",
          "name": "Mathematics for Elementary Educators II ",
          "CUs": "3",
          "description": "Mathematics for Elementary Educators II engages preservice elementary school teachers in mathematical practices of algebraic reasoning. This course explores important algebraic topics such as patterns, expressions and equations, linear equations, inequalities, and functions. This is the second course in a three-course sequence.   "
        },
        "D002": {
          "courseID": "D002",
          "CCN": "SPED 3513",
          "name": "Professional, Ethical, and Legal Practices for Special Education ",
          "CUs": "3",
          "description": "Professional, Ethical, and Legal Practices for Special Education prepares candidates to practice within ethical and legal guidelines in day-to-day teaching, stakeholder interactions, and other complex situations. This course provides an overview of the professional ethics and standards from the Council for Exceptional Children (CEC), which guide candidates to act in a professionally conscientious manner. Candidates will explore the legal foundations and case laws related to special education to gain understanding of how legislation influences teaching and learning. This course is designed to be taken after successful completion of Fundamentals of Diverse Learners OR Fundamentals of Diversity, Inclusion, and Exceptional Learners. "
        },
        "D127": {
          "courseID": "D127",
          "CCN": "MATH 1113",
          "name": "Mathematics for Elementary Educators III ",
          "CUs": "3",
          "description": "Mathematics for Elementary Educators III engages preservice elementary teachers in important concepts in geometry, measurement, data analysis and statistics, and probability. This is the third course in a three-course sequence.   "
        },
        "D001": {
          "courseID": "D001",
          "CCN": "SPED 4512",
          "name": "Behavioral Support Strategies for K-12 Learners with Mild to Moderate Exceptionalities ",
          "CUs": "4",
          "description": "Behavioral Support Strategies for K–12 Learners with Mild to Moderate Exceptionalities prepares candidates to work effectively with students exhibiting behavior in the classroom that is below age and cultural norms. This course provides an overview of behavior disorders and their causes, as well as appropriate research-based intervention strategies, including positive behavior intervention and supports, multi-tiered systems of support (MTSS), applied behavior analysis, replacement behavior and reward strategies, culturally responsive practices, and data collection and assessment methods. After completing this course candidates emerge prepared to strategize and recommend adjustments to the learning environment that support positive behavior and student success in the classroom and beyond. This course also examines behavioral assessment and analysis, including the creation of functional behavior assessment (FBA) and the creation and monitoring of behavioral improvement plans (BIP) in an authentic learning environment. This course is designed to be taken after successful completion of Fundamentals of Diverse Learners or Fundamentals of Diversity Inclusion, and Exceptional Learners, Professional, Ethical, and Legal Practices for Special Education, and Managing Engaging Learning Environments or Classroom Management, Engagement, and Motivation. "
        },
        "D003": {
          "courseID": "D003",
          "CCN": "SPED 4514",
          "name": "Assessment in Special Education ",
          "CUs": "3",
          "description": "Assessment in Special Education prepares candidates to use multiple methods of assessment and data sources in making educational decisions about the student and the learning environment. This course is designed to help provide an understanding of how assessment data is used during screening in multitiered systems of support (MTSS), the eligibility process, the evaluation process, progress monitoring, and data-based instructional decision making. This course is designed to be taken after successful completion of Professional, Ethical, and Legal Practices for Special Education and Assessing Impact on Student Learning OR Educational Assessment. "
        },
        "D004": {
          "courseID": "D004",
          "CCN": "SPED 4515",
          "name": "Collaborating with Partners for Student Success ",
          "CUs": "3",
          "description": "Collaborating with Partners for Student Success prepares candidates to apply team processes and communication strategies to collaborate in a culturally responsive manner with families, paraeducators, and other professionals (within the school, other educational settings, and the community) to plan programs and access services for students with exceptionalities and their families. The course introduces ways to enhance parental involvement and family engagement while teaching families and students advocacy throughout the Individualized Education Program (IEP) and transition planning processes. This course also focuses on the components of the IEP and how the practice of effective communication and collaboration skills is key to the program's development and implementation. Candidates will engage in three hours of preclinical experiences that includes a simulated collaborative experience in which skills learned can be applied. This course is designed to be taken after successful completion of Professional, Ethical, and Legal Practices for Special Education OR Fundamentals of Diversity, Inclusion, and Exceptional Learners, and Assessment in Special Education. "
        },
        "C909": {
          "courseID": "C909",
          "CCN": "EDUC 4211",
          "name": "Elementary Reading Methods and Interventions ",
          "CUs": "3",
          "description": "Elementary Reading Methods and Interventions provides candidates with an in-depth look at best practices for developing reading and writing skills. Course content examines the science of reading, the stages of and approaches to literacy development, differentiation, technology integration, literacy assessment, and Multitiered Systems of Supports (MTSS), including the comprehensive response to intervention (RTI) model used to identify and address the needs of learners who struggle with reading comprehension. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment. "
        },
        "C365": {
          "courseID": "C365",
          "CCN": "EDUC 4220",
          "name": "Language Arts Instruction and Intervention ",
          "CUs": "3",
          "description": "Language Arts Instruction and Intervention helps students learn how to implement effective language arts instruction and intervention in the elementary classroom. Topics include written and spoken English, expanding students' knowledge, literature rich environments, differentiated instruction, technology for reading and writing, assessment strategies for reading and writing, and strategies for developing academic language. There are no prerequisites for this course. "
        },
        "C109": {
          "courseID": "C109",
          "CCN": "EDUC 4230",
          "name": "Elementary Mathematics Methods ",
          "CUs": "3",
          "description": "Elementary Mathematics Methods helps students learn how to implement effective math instruction in the elementary classroom. Topics include differentiated math instruction, mathematical communication, mathematical tools for instruction, assessing math understanding, integrating math across the curriculum, critical thinking development, standards-based mathematics instruction, and mathematical models and representation. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "D010": {
          "courseID": "D010",
          "CCN": "EDUC 3279",
          "name": "Disciplinary Literacy ",
          "CUs": "3",
          "description": "Disciplinary Literacy examines teaching strategies designed to help candidates to develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help candidates distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and  Presentation AND Instructional Planning and Presentation in Special Education. "
        },
        "C970": {
          "courseID": "C970",
          "CCN": "EDUC 3211",
          "name": "Children’s Literature ",
          "CUs": "3",
          "description": "Children's Literature is an introduction to and exploration of children's literature. Students will consider and analyze children's literature as a lens through which to view the world. Students will experience multiple genres, historical perspectives, cultural representations, and current applications in the field of children's literature. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "D005": {
          "courseID": "D005",
          "CCN": "SPED 4516",
          "name": "Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities ",
          "CUs": "3",
          "description": "Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities introduces candidates to a repertoire of evidence-based instructional strategies to advance the learning of students with exceptionalities. The course focuses specifically on strategies for intensifying and individualizing instructional interventions; making instructional decisions based on progress-monitoring data; collaborating with general education teachers and paraeducators; teaching to mastery; promoting generalization of learning; and teaching students with exceptionalities how to use self-assessment, problem solving, and other cognitive strategies to organize critical content and meet their needs. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and Presentation AND Instructional Planning  and Presentation in Special Education. "
        },
        "D006": {
          "courseID": "D006",
          "CCN": "SPED 4517",
          "name": "Instructional Strategies and Technologies ",
          "CUs": "for",
          "description": "Instructional Strategies and Technologies for Elementary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for elementary students receiving special education services. The course includes cognitive and metacognitive strategies that elementary students can use to acquire new content knowledge and generalize skills across learning environments. It also provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math and English language arts lesson plans based on learner characteristics, performance data, and individualized education program (IEP) goals. In addition to discussing how to make appropriate accommodations, the course teaches candidates how to assess student learning through progress monitoring and apply intensive interventions when warranted. This course is designed to be taken after successful completion of Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities. "
        },
        "Mild": {
          "courseID": "Mild",
          "CCN": "Learners with",
          "name": "to Moderate Exceptionalit ",
          "CUs": "2"
        },
        "D007": {
          "courseID": "D007",
          "CCN": "SPED 4518",
          "name": "Instructional Strategies and Technologies ",
          "CUs": "for",
          "description": "Instructional Strategies and Technologies for Secondary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for use with secondary students receiving special education services. Strategies taught in this course focus on intensive instruction and making accommodations to secondary lesson plans in order to develop critical thinking and problem-solving skills to enhance acquisition of ageappropriate secondary content across academic disciplines. This course also promotes the achievement of Individualized Education Program (IEP) and transition goals for independent living and career preparation through demonstration of strategies that increase students' self-awareness, selfregulation, self-management, self-control, and self-esteem. This course is designed to be taken after successful completion of Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities. "
        },
        "D009": {
          "courseID": "D009",
          "CCN": "EDUC 3420",
          "name": "Preclinical Experiences in Special Education ",
          "CUs": "3",
          "description": "Pre-Clinical Experiences in Special Education provides candidates the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Candidates will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, candidates will be required to include a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "D012": {
          "courseID": "D012",
          "CCN": "EDUC 4047",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 1 and 2 ",
          "CUs": "3"
        },
        "D013": {
          "courseID": "D013",
          "CCN": "EDUC 4049",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 3 and Midterm ",
          "CUs": "3"
        },
        "D014": {
          "courseID": "D014",
          "CCN": "EDUC 4050",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 4 and 5 ",
          "CUs": "3"
        },
        "D015": {
          "courseID": "D015",
          "CCN": "EDUC 4051",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 6 and Final ",
          "CUs": "3"
        },
        "D149": {
          "courseID": "D149",
          "CCN": "EDUC 4763",
          "name": "Teacher Performance Assessment in Special Education ",
          "CUs": "3",
          "description": "Teacher Performance Assessment in Special Education course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C340": {
          "courseID": "C340",
          "CCN": "EDUC 4989",
          "name": "Cohort Seminar in Special Education ",
          "CUs": "3",
          "description": "Cohort Seminar in Special Education provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D016": {
          "courseID": "D016",
          "CCN": "EDUC 5288",
          "name": "Leadership Foundations and Ethics ",
          "CUs": "3",
          "description": "Leadership Foundations and Ethics presents candidates with a variety of leadership theories and strategies used by PK–12 educational leaders to develop, sustain, and evaluate a coherent system of academic and social supports that meet the full range of students' needs. Foundational knowledge addresses the importance of developing mission, vision, and core values in collaboration with faculty, staff, and the school community to advocate for student success. The course also covers communication strategies, interpersonal skills, and using data to build community, influence school culture, and manage change for continuous improvement. In addition, candidates are introduced to the significance of following professional ethical codes and the importance of modeling and advocating ethical behavior with all stakeholders. "
        },
        "D017": {
          "courseID": "D017",
          "CCN": "EDUC 5289",
          "name": "School Law ",
          "CUs": "3",
          "description": "School Law prepares candidates to understand the appropriate application of laws, rights, policies, and regulations to promote student success. The course emphasizes the importance of understanding the history of and relationship between federal and state laws, legal decisions, local education policies, and practices at the local school level to ensure compliance. The course further focuses on understanding the legal rights and protections provided for all students, including those with disabilities, as well as school staff. It also addresses curriculum and instruction that help stakeholders understand the possible effects these rights may have on administrative decisions. Candidates are also provided the opportunity to demonstrate their capability to evaluate legal consequences of administrative decisions. "
        },
        "D020": {
          "courseID": "D020",
          "CCN": "EDUC 5292",
          "name": "Cultural Competency and Social-Emotional Learning ",
          "CUs": "3",
          "description": "Cultural Competency and Social-Emotional Learning focuses on fostering cultural competence among professional educators by increasing knowledge of diverse learner populations, implementing culturally responsive pedagogy, and ensuring social justice and equity in the educational setting. Candidates also will participate in learning experiences designed to ensure they can lead efforts to meet the social and emotional learning needs of all learners, contributing to a school environment that builds learners' personal agency and academic success. Advocacy strategies are learned in this course, ensuring candidates possess the tools to positively impact school environments both locally and globally. This course has no prerequisites and candidates are strongly encouraged to take this early in their program. "
        },
        "D019": {
          "courseID": "D019",
          "CCN": "EDUC 5291",
          "name": "Data Literacy and Evidence-Based Practices ",
          "CUs": "3",
          "description": "Data Literacy and Evidence-Based Practices focuses on the development of data literacy skills educators need to improve the learning and development opportunities of K–12 students. Candidates will practice identifying educational problems and data types, generating data, analyzing data, making inferences and drawing conclusions, and creating action plans within their educational settings. Candidates will also learn best practices for data literacy, including continuous improvement planning, approaches to professional learning communities, and instructional decision-making processes. This course has no prerequisites. "
        },
        "D021": {
          "courseID": "D021",
          "CCN": "EDUC 5293",
          "name": "Leadership of Curriculum Design and Instruction ",
          "CUs": "3",
          "description": "Leadership of Curriculum Design and Instruction prepares candidates to evaluate and implement curricular programs and instructional methods observed at the school level. Candidates focus on the knowledge and skills needed to develop, align, and implement cohesive systems of curriculum, instruction, and assessment. Importance is placed on responding to student needs, embodying high expectations for student learning, aligning with academic standards within and across grade levels, and promoting students' academic success and social and emotional well-being. This course also covers the selection and use of appropriate technologies to monitor student progress and improve instruction support for assessment, data collection, management, and analysis. Candidates are prepared to build a professional culture of trust and collaboration to ensure they are able to work with school personnel in creating curricular programs and instructional methods that are engaging and challenging and relevant to student needs, experiences, and interests. This course is designed to be taken after successful completion of D017: School Law. "
        },
        "D018": {
          "courseID": "D018",
          "CCN": "EDUC 5290",
          "name": "Leading Inclusive Schools ",
          "CUs": "3",
          "description": "Leading Inclusive Schools covers a variety of topics that directly affect students who have been assessed and determined to need additional support or services to ensure their academic success and well-being. The course prepares candidates to understand and comply with applicable laws, rights, policies, and regulations as appropriate to address matters of equity, fairness, and student marginalization based on culture and language, disability, or giftedness. These include types of special education classifications and their significance, working with English learners (ELs), working with gifted and talented students, and using Multi-Tiered System of Supports (MTSS) frameworks to ensure optimum learning environments for diverse learners. This course will guide candidates in building a strong repertoire of skills and knowledge related to exceptional students. It will help them ensure that each student has equitable access to effective teachers; learning opportunities; academic, social, and behavioral support; and other resources necessary for success. This course is designed to be taken after successful completion of the School Law course. "
        },
        "D022": {
          "courseID": "D022",
          "CCN": "EDUC 5294",
          "name": "People and Talent in Educational Leadership ",
          "CUs": "3",
          "description": "People and Talent in Educational Leadership prepares candidates to understand and implement practices used to recruit, hire, and prepare school personnel to provide students with an optimal learning environment. Various school professional development practices, such as professional learning communities, collaborative learning communities, beginning teacher induction, and mentor programs, will be covered. Additionally the course covers methods to evaluate school personnel appropriately based on data-driven decisions; providing realistic and actionable feedback to school personnel to continuously drive improvement; engaging all school personnel in the use and evaluation of competing school-wide initiatives; creating and sustaining a professional culture of engagement and commitment by developing workplace conditions that promote employee development, well-being, and professional growth; and continuously supporting school personnel to improve their instructional practices through ongoing professional development. The candidate will also reflect on leadership standards in order to develop a personal professional growth plan. A prerequisite for this course is D017: School Law. "
        },
        "D023": {
          "courseID": "D023",
          "CCN": "EDUC 5295",
          "name": "School Financial Leadership ",
          "CUs": "3",
          "description": "School Financial Leadership focuses on financial policies, practices, and issues connected to PK–12 school operations. The course describes various sources of school funding, the impact these sources can have on managing school budgets, and the challenges connected to finances that are often encountered by school leaders to ensure equitable financial support for all students. Candidates learn how to analyze different types of school budgets and understand the principal's role in the budgetary process to ensure alignment to the school's mission, vision, and values. This course also identifies and explains various types of commonly used accounting regulations, rules, and professional ethical principles used to create, maintain, and evaluate school budgets to ensure the equitable and ethical use of financial resources. This course is designed to be taken after successful completion of D017: School Law. "
        },
        "D036": {
          "courseID": "D036",
          "CCN": "EDUC 5298",
          "name": "Practicum in Educational Leadership - Focus on Professional Practices ",
          "CUs": "3",
          "description": "Focus on Professional Practices - Practicum in Educational Leadership - Focus on Professional Practices provides candidates with an authentic, real-world work experience as an educational leader in a K–12 school environment. This is the first of a two-part experience designed to take place under the leadership and supervision of a practicing school principal or assistant principal at an approved practicum school site (K–12). This course includes an emphasis on the application of knowledge and skills to areas directly or indirectly affecting students. Collaboration within the school and local community is a focal point for this course. The course also includes the completion of assigned administrative duties in a K–12 setting, as defined by the candidate's state of residence, under the supervision of the cooperating administrator of the candidate's approved practicum site. Prior to enrolling in this practicum course, the candidate must complete a minimum of 18 CUs. "
        },
        "D034": {
          "courseID": "D034",
          "CCN": "EDUC 5296",
          "name": "Systems Management and School Operations ",
          "CUs": "3",
          "description": "Systems management and school operations instruct candidates on the operational aspects of school leadership that are essential to developing, monitoring, and evaluating school management, school systems, and services that address and support the needs of students and school personnel. Topics presented in this course include systems thinking; development, implementation, and evaluation of data-based strategic planning; and school improvement processes. Candidates will evaluate the use of appropriate operational technology and the development of communications systems that provide actionable information to internal and external stakeholders for use in classroom and school improvement and community engagement. Each of these topics emphasizes the importance of efficiently and effectively managing school resources to build, maintain, and evaluate a cohesive system of academic and organizational supports, services, extracurricular activities, and accommodations to meet the full range of needs for each student. Prerequisites for this course: Leadership Foundations and Ethics and School Law. "
        },
        "D035": {
          "courseID": "D035",
          "CCN": "EDUC 5297",
          "name": "Educational Inquiry ",
          "CUs": "3",
          "description": "Educational Inquiry focuses on practical problem solving. This course teaches candidates to use scholarly literature to inform their own practice. It also teaches candidates to engage in their own action research processes, which empowers educators to recognize opportunities for improvement and to systematically implement and evaluate changes. This course prepares candidates to conduct research for the capstone. Prerequisites for this course: Data Literacy and Evidence-Based Practices. "
        },
        "D037": {
          "courseID": "D037",
          "CCN": "EDUC 5299",
          "name": "Practicum in Educational Leadership - Focus on Instruction and Operations ",
          "CUs": "3",
          "description": "Focus on Instruction and Operations - Practicum in Educational Leadership - Focus on Instruction and Operations provides candidates with an authentic, real-world work experience as an educational leader in a K–12 school environment. This is the second of a two-part experience designed to take place under the leadership and supervision of a practicing school principal or assistant principal at an approved practicum school site (K–12). This course includes an emphasis on the application of knowledge and skills to areas affecting school operations and school personnel. The course also includes the completion of assigned administrative duties in a K–12 setting, as defined by the candidate's state of residence, under the supervision of the cooperating administrator of the candidate's approved practicum site. Prior to enrolling in this practicum course, the candidate must complete a minimum of 18 CUs. "
        },
        "D038": {
          "courseID": "D038",
          "CCN": "EDUC 5300",
          "name": "Educational Leadership Capstone ",
          "CUs": "3",
          "description": "Educational Leadership Capstone serves as the culminating experience of this degree program, uniting content area knowledge with the execution of a problem-based learning project. Under the guidance of program faculty, candidates will apply their data literacy and research skills authentically and to topics appropriate to the candidate's degree program and future career goals. Projects will include action research or program evaluation and the qualitative or quantitative research methods necessitated by the project's purpose. Prerequisites include Data Literacy and Educational Inquiry, as well as all content area courses and field experiences prescribed in one's area of study. This course is designed to be taken after successful completion of all courses with the exception of Educational Inquiry, which may be taken concurrently. "
        },
        "D182": {
          "courseID": "D182",
          "CCN": "EDUC 5062",
          "name": "The Reflective Practitioner ",
          "CUs": "3",
          "description": "The Reflective Practitioner defines what reflective teaching is and how accomplished teachers reflect meaningfully on their pedagogical choices to improve their practice. During this course, candidates will examine their teaching to determine how they can more effectively plan, facilitate, and evaluate learning. Candidates will also develop a professional growth plan and incorporate evidence-based practices that support the achievement of their professional goals. There are no prerequisites for this course. "
        },
        "D183": {
          "courseID": "D183",
          "CCN": "EDUC 5063",
          "name": "Designing Curriculum and Instruction I ",
          "CUs": "3",
          "description": "Designing Curriculum and Instruction I examines the influence that specific theories, design principles, and evaluation models have on the quality and effectiveness of a curriculum. During the course, candidates will conduct a needs analysis in order to determine the content that students need. The course requires candidates to learn how to define the scope and sequence of a curriculum to ensure vertical and horizontal alignment. This course will also teach how to map curriculum to address any gaps or unnecessary duplication within and across grade levels. There are no prerequisites for this course. "
        },
        "D179": {
          "courseID": "D179",
          "CCN": "EDUC 5061",
          "name": "Data-Informed Practices ",
          "CUs": "3",
          "description": "Data-Informed Practices focuses on the development of data literacy skills. This course teaches candidates about the different types of data, the benefits and limitations of those data types, and how they can use data to identify and solve problems and inform decisions. The course also teaches candidates how to locate, collect, and analyze data from relevant and credible sources, and how to draw conclusions from data in order to drive continuous improvement. There are no prerequisites for this course. "
        },
        "D187": {
          "courseID": "D187",
          "CCN": "EDUC 6302",
          "name": "Differentiated Instruction ",
          "CUs": "3",
          "description": "Differentiated Instruction examines how the classroom environment and students’ readiness levels, interests, and learning profiles influence learning. K–12 educators taking this course will acquire a deep understanding of their students in order to differentiate their curriculum, instruction, and assessments in response to individual students’ needs. This course will allow students to also learn how to effectively monitor and communicate students’ progress toward standards and adjust their practice as needed to empower students and nurture their abilities and aptitudes. As a result of their learning in this course, K–12 teachers will be prepared to act as catalysts for differentiation within their schools and districts. There are no prerequisites for this course. "
        },
        "D184": {
          "courseID": "D184",
          "CCN": "EDUC 5064",
          "name": "Standards-Based Assessment ",
          "CUs": "3",
          "description": "Standards-Based Assessment teaches candidates how to unpack academic standards to determine the essential learnings within the standards that should be assessed. This course teaches candidates how to determine, based on academic standards, which topics should be assessed and how to use proficiency statements to create and score standards-based assessments. This course also prepares candidates to analyze assessment data and develop a holistic assessment system for a specific subject and grade level. Differentiated Instruction is a prerequisite for this course. "
        },
        "D185": {
          "courseID": "D185",
          "CCN": "EDUC 5065",
          "name": "Designing Curriculum and Instruction II ",
          "CUs": "3",
          "description": "Designing Curriculum and Instruction II examines commonly used curriculum and instructional models and demonstrates how they can be used during the design process to achieve curricular and instructional goals. This course demonstrates how to design curriculum and instruction that leverages digital tools to facilitate deep, authentic learning and provides strategies for ensuring successful curriculum implementation. Designing Curriculum and Instruction I is a prerequisite for this course. "
        },
        "D186": {
          "courseID": "D186",
          "CCN": "EDUC 5066",
          "name": "Learning as a Science ",
          "CUs": "3",
          "description": "Learning as a Science examines how research from the field of learning sciences can be applied to improve teaching and learning. This course explains how teachers can create a sense of community by examining personal biases and establishing a culturally inclusive learning environment. The course also provides evidence-based strategies for improving motivation, increasing understanding and retention, and teaching social-emotional skills that students need to be successful socially and academically. There are no prerequisites for this course. "
        },
        "D188": {
          "courseID": "D188",
          "CCN": "EDUC 6303",
          "name": "The Collaborative Leader ",
          "CUs": "3",
          "description": "The Collaborative Leader demonstrates strategies teacher leaders can use to collaborate with other professionals, families, and communities to build strong relationships and improve school effectiveness. This course examines models of collaboration and the benefits and challenges of collaboration. It also examines the characteristics of effective professional development and explains how to collaboratively design effective professional development opportunities for educators. Finally, this course demonstrates how accomplished teachers can build relationships with families and the community to create a positive learning experience for students. There are no prerequisites for this course. "
        },
        "D180": {
          "courseID": "D180",
          "CCN": "EDUC 6300",
          "name": "Educational Research ",
          "CUs": "3",
          "description": "Educational Research focuses on practical problem solving. This course teaches candidates to use scholarly literature and current research to inform their own practice. It also empowers candidates to recognize opportunities for improvement and engage in action research to systematically implement and evaluate changes. This course prepares candidates to conduct research for the capstone. DataInformed Practices is a prerequisite for this course. "
        },
        "D181": {
          "courseID": "D181",
          "CCN": "EDUC 6301",
          "name": "MSCIN Capstone ",
          "CUs": "5",
          "description": "The Master of Science in Curriculum and Instruction Capstone is the culminating course of the degree. It unites content area knowledge with the completion of a research project or study. This course teaches candidates, under the guidance of program faculty, to apply their data literacy and research skills to topics related to curriculum and instruction and to their career goals. Projects for this course include action research or applied research through the necessary qualitative, quantitative, or mixed research methods. Prerequisites for this course include DataInformed Practices and Educational Research, as well as all prescribed courses in the candidates' area of study. Additionally, students wishing to add the Capstone with fewer than eight weeks remaining in the term must receive permission from the Program Chair. "
        },
        "D389": {
          "courseID": "D389",
          "CCN": "HLTH 2050",
          "name": "Learning Strategies in Higher Education ",
          "CUs": "4",
          "description": "Learning Strategies in Higher Education provides students with a toolbox of skills that will support student academic growth as they advance in their academic journey. Students will be introduced to the Whalley Library; how to use it and best practices for research strategies. Students will learn how to be professional in written communication and how to correctly use current APA format. In this course, students also will learn about setting goals, time-management, study strategies, making and keeping appointments, professional decorum, and test-taking skills. Learning these skills, strategies, and methods will establish an academic foundation for students to be successful in higher education. There are no prerequisites for this course. "
        },
        "C799": {
          "courseID": "C799",
          "CCN": "HIM 2011",
          "name": "Healthcare Ecosystems ",
          "CUs": "3",
          "description": "Healthcare Ecosystems explores the history and state of healthcare organizations in an ever-changing environment. This course covers how agencies influence healthcare delivery through legal, licensure, certification, and accreditation standards. The course will also discuss how new technologies and trends keep healthcare delivery innovative and current. "
        },
        "C784": {
          "courseID": "C784",
          "CCN": "MATH 1100",
          "name": "Applied Healthcare Statistics ",
          "CUs": "4",
          "description": "Applied Healthcare Probability and Statistics is designed to help develop competence in the fundamental concepts of basic mathematics, introductory algebra, and statistics and probability. These concepts include basic arithmetic with fractions and signed numbers; introductory algebra and graphing; descriptive statistics; regression and correlation; and probability. Statistical data and probability are now commonplace in the healthcare field. This course will help candidates make informed decisions about which studies and results are valid, which are not, and how those results affect your decisions. This course will give candidates background in what constitutes sound research design and how to appropriately model phenomena using statistical data. Additionally, this course guides candidates in calculating simple probabilities based on events which occur in the healthcare profession. This course will prepare candidates for studies at Whalley, as well as in the healthcare profession. "
        },
        "C804": {
          "courseID": "C804",
          "CCN": "HIM 2150",
          "name": "Medical Terminology ",
          "CUs": "3",
          "description": "Medical Terminology focuses on the basic components of medical terminology and how terminology is used when discussing various body structures and systems. Proper use of medical terminology is critical for accurate and clear communication among medical staff, health professionals, and patients. In addition to the systems of the body, this course will discuss immunity, infections, mental health, and cancer. "
        },
        "D203": {
          "courseID": "D203",
          "CCN": "BIO 1100",
          "name": "Fundamentals of Anatomy and Physiology ",
          "CUs": "3",
          "description": "Fundamentals of Anatomy and Physiology provides an overview of the structures and functions of organs and systems of the human body. This course will explore how the parts of the body systems work together to produce movement, transport nutrients, eliminate wastes, protect vital tissues and organs, regulate bodily functions, and support reproduction and growth, through videos, readings, exploratory learning, and practice activities. "
        },
        "C816": {
          "courseID": "C816",
          "CCN": "HIM 3205",
          "name": "Healthcare System Applications ",
          "CUs": "4",
          "description": "Healthcare System Applications introduces students to information systems. This course includes important topics related to management of information systems (MIS), such as system development and business continuity. The course also provides an overview of management tools and issue tracking systems. This course has no prerequisites. "
        },
        "C180": {
          "courseID": "C180",
          "CCN": "PSYC 1010",
          "name": "Introduction to Psychology ",
          "CUs": "3",
          "description": "In this course, students will develop an understanding of psychology and how it helps them better understand others and themselves. Students will learn general theories about psychological development, the structure of the brain, and how psychologists study behavior. They will gain an understanding of both normal and disordered psychological behaviors, as well as general applications of the science of psychology in society (such as personality typing and counseling). "
        },
        "C802": {
          "courseID": "C802",
          "CCN": "HLTH 3501",
          "name": "Foundations in Healthcare Information Management ",
          "CUs": "4",
          "description": "Foundations in Healthcare Information Management applies theories from business, IT, management, medicine, and consumer-centered healthcare skills. Students will learn to evaluate and analyze health information systems for implementation in health information management. There are no prerequisites for this course. "
        },
        "C803": {
          "courseID": "C803",
          "CCN": "HLTH 3315",
          "name": "Data Analytics and Information Governance ",
          "CUs": "4",
          "description": "Data Analytics and Information Governance explores the structure, methods, and approaches for using health information in the healthcare industry. By focusing on quality data collection, analytics, and industry regulations, students will examine tools that ensure quality data collection as well as to use data to improve quality of care. This course has no prerequisites. "
        },
        "D190": {
          "courseID": "D190",
          "CCN": "HIM 2002",
          "name": "Introduction to Healthcare IT Systems ",
          "CUs": "4",
          "description": "Introduction to Healthcare IT Systems introduces students to healthcare information technology as a discipline. Focusing on evaluating health information systems and collecting data, students will learn the various roles and functions of the health information manager in supporting the business of healthcare. This course introduces students to information technology as a discipline. This course also exposes students to the various roles and functions of the health information manager in supporting the business of healthcare. Students will learn through e-text readings, videos, case studies, several modules from LinkedIn Learning, knowledge checks, and unit quizzes. There are no prerequisites for this course. "
        },
        "C810": {
          "courseID": "C810",
          "CCN": "HIM 2104",
          "name": "Foundations in Healthcare Data Management ",
          "CUs": "3",
          "description": "Foundations in Healthcare Data Management introduces students to the concepts and terminology used in health data and health information management. This course teaches students how to apply data management and governance principles in the healthcare environment. The student will learn about electronic health records (EHR), legal considerations, information governance, data management, health information management (HIM), and secondary data sources. In addition to the e-text and numerous additional articles and video resources, the student will engage with case studies and knowledge checks to assist with learning. There are no prerequisites for this course. "
        },
        "C801": {
          "courseID": "C801",
          "CCN": "HIM 2215",
          "name": "Health Information Law and Regulations ",
          "CUs": "4",
          "description": "Health Information Law and Regulations prepares students to manage health information in compliance with legal guidelines and teaches how to respond to questions and challenges when legal issues occur. This course presents the types of situations occurring in health information management that could result in ethical dilemmas and establishes a foundation for work based on legal and ethical guidelines. "
        },
        "C805": {
          "courseID": "C805",
          "CCN": "HIM 2507",
          "name": "Pathophysiology ",
          "CUs": "3",
          "description": "Pathophysiology is an overview of the pathology and treatment of diseases in the human body and its systems. This course will explain the processes in the body that result in the signs and symptoms of disease, as well as therapeutic procedures in managing or curing the disease. The content draws on a knowledge of anatomy and physiology to understand how diseases manifest themselves and how they affect the body. "
        },
        "D398": {
          "courseID": "D398",
          "CCN": "HLTH 2422",
          "name": "Introduction to Pharmacology ",
          "CUs": "3",
          "description": "Introduction to Pharmacology will introduce learners to medication and supplement regulations and safety protocols. It provides an overview of the use, benefits, effects, and contraindications of commonly used drugs to treat conditions of the cardiovascular, respiratory, endocrine, nervous, and renal body systems. It also explores the types of anti-infective, antineoplastic, psychotropic drugs, and dietary supplements and their effects on the body. "
        },
        "C815": {
          "courseID": "C815",
          "CCN": "HIM 4511",
          "name": "Quality and Performance Management and Methods ",
          "CUs": "4",
          "description": "Quality and Performance Management and Methods examines quality initiatives within healthcare. Quality issues cover human resource management, employee performance, and patient safety. This course focuses on quality improvement initiatives and performance improvement with the health information management perspective. "
        },
        "C808": {
          "courseID": "C808",
          "CCN": "HIM 2515",
          "name": "Classification Systems ",
          "CUs": "4",
          "description": "Classification Systems provides a comprehensive approach to learning about medical coding classification, coding audits, and quality standards. Candidates will be exposed to electronic health record systems and leadership principles as they relate to management of ICD and CPT codes. There are no prerequisites for this course. "
        },
        "D254": {
          "courseID": "D254",
          "CCN": "HLTH 2110",
          "name": "Introduction to Medical Coding ",
          "CUs": "3",
          "description": "Introduction to Medical Coding provides students with the foundation for translating medical terminology into correct diagnosis and procedure codes. The course focuses on how diagnosis and procedure codes are used to accurately document medical records and inform accurate medical billing. This course introduces the Current Procedural Terminology (CPT), International Classification of Diseases (ICD-10-CM), ICD-10-PCS, and Healthcare Common Procedure Coding System (HCPCS) code sets as well as ethical considerations throughout processes in medical coding. There are no prerequisites for this course. "
        },
        "C807": {
          "courseID": "C807",
          "CCN": "HIM 3215",
          "name": "Healthcare Compliance ",
          "CUs": "3",
          "description": "Healthcare Compliance examines the role of the coding professional within healthcare information management. The course covers compliance plans, issues that arise with noncompliance, and management of internal and external audits. "
        },
        "C811": {
          "courseID": "C811",
          "CCN": "HIM 3701",
          "name": "Healthcare Financial Resource Management ",
          "CUs": "4",
          "description": "Healthcare Financial Resource Management examines financial practices within healthcare industries to promote effective management at department and organization levels. Focusing on financial processes associated with facility operations in the healthcare field, this course will analyze the impact of strategic financial planning and regulatory control processes. This course has no prerequisites. "
        },
        "C812": {
          "courseID": "C812",
          "CCN": "HIM 4610",
          "name": "Healthcare Reimbursement ",
          "CUs": "4",
          "description": "Healthcare Reimbursement explores financial practices within the healthcare industry as they relate to reimbursement policies. This course identifies how reimbursement systems impact the revenue cycle and a health information manager's role. This course has no prerequisites. "
        },
        "C813": {
          "courseID": "C813",
          "CCN": "HIM 4502",
          "name": "Healthcare Statistics and Research ",
          "CUs": "3",
          "description": "Healthcare Statistics and Research explores the use of statistical data to support process improvement through health information research. Health information management (HIM) professionals use information systems to gather, analyze, and present data in response to administrative and clinical needs. This course has no prerequisites. "
        },
        "D033": {
          "courseID": "D033",
          "CCN": "HLTH 2100",
          "name": "Healthcare Information Systems Management ",
          "CUs": "3",
          "description": "Healthcare Information Systems Management provides an overview of many facets of information systems in healthcare. This course explores how information technology (IT) is an organizational resource that must be managed so that it supports or enables healthcare organizational strategy. This course will discuss how decision support and communication are securely facilitated in the healthcare marketplace. This course also explores current and continuously evolving technologies, strategic thinking, and issues at the intersection of health information management and technology. "
        },
        "D255": {
          "courseID": "D255",
          "CCN": "HLTH 4905",
          "name": "Professional Practice Experience I: Technical ",
          "CUs": "3",
          "description": "The PPE I: Technical course allows you to use EHRGo, an electronic health record (EHR), to complete 42 structured activities to experience how an HIM professional uses an EHR. The selected activities meet AHIMA's Baccalaureate level competencies and by completing them you will earn 40 PPE hours.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D257": {
          "courseID": "D257",
          "CCN": "HLTH 3000",
          "name": "Healthcare Project Management ",
          "CUs": "4",
          "description": "Healthcare Project Management provides students with a comprehensive foundation for project management. The course focuses on project management methodologies, process improvement analysis, business case proposals, and creating project planning documents for health information management (HIM) projects. This course will prepare students to determine project scope and timelines, complete interdepartmental stakeholder analysis, identify project resources, examine constraints and risks, and contribute to positive project communication. "
        },
        "D256": {
          "courseID": "D256",
          "CCN": "HLTH 2120",
          "name": "Principles of Management in Health Information Management ",
          "CUs": "3",
          "description": "Principles of Management in HIM provides an introductory look at the discipline of management and its context within the health information management environment. This course provides an overview of management and leadership, management functions, human resource management, and communication strategies. The course gives students an opportunity to analyze how leadership and management principles are used to achieve department goals. This course has no prerequisites. "
        },
        "D258": {
          "courseID": "D258",
          "CCN": "HLTH 3100",
          "name": "Organizational Leadership in Healthcare ",
          "CUs": "3",
          "description": "Organizational Leadership in Healthcare provides students with an overview of the principles and practices leaders need in healthcare environments. The course focuses on organizational leadership theory, behaviors, culture, and teamwork. This course prepares students to apply leadership theories, principles of organizational culture development, techniques for building and leading teams, and conflict resolution strategies to support organizational goals. This course has no prerequisites. "
        },
        "D259": {
          "courseID": "D259",
          "CCN": "HLTH 4906",
          "name": "Professional Practice Experience II: Management ",
          "CUs": "4",
          "description": "The PPE II: Management course allows you to experience your future profession at the supervisory level. Any site where health information is used and you can be mentored by a department or facility manager is appropriate for PPE II.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D260": {
          "courseID": "D260",
          "CCN": "HIM 4507",
          "name": "Health Information Management Capstone ",
          "CUs": "4",
          "description": "The Health Information Management Capstone is the culmination of the student's degree program. The course is an opportunity for students to do an environmental scan focusing specifically on emerging issues and trends in the field of health information management (HIM) and to apply knowledge learned throughout the program to the problems and issues facing HIM professionals. The student will also develop a professional and educational development plan. At the end of the course, the student will complete an RHIA practice exam. This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "CUA1": {
          "courseID": "CUA1",
          "CCN": "EDUC 5260",
          "name": "Culture ",
          "CUs": "3",
          "description": "Focuses on the nature and role of culture and the importance of cultural groups and cultural identity. "
        },
        "LPA1": {
          "courseID": "LPA1",
          "CCN": "EDUC 5261",
          "name": "Language Production, Theory and Acquisition ",
          "CUs": "4",
          "description": "Language Production, Theory and Acquisition focuses on describing and understanding language and the development of language. It includes the study of acquisition theory, error correction strategies, and applied phonology. "
        },
        "SLO1": {
          "courseID": "SLO1",
          "CCN": "EDUC 5262",
          "name": "Theories of Second Language Acquisition and Grammar ",
          "CUs": "3",
          "description": "Theories of Second Language Learning Acquisition and Grammar covers content material in applied linguistics, including morphology, syntax, semantics, and grammar. Students will explore the role of dialect in the classroom, the connections between language and culture, and the theories of first and second language acquisition. "
        },
        "ASA1": {
          "courseID": "ASA1",
          "CCN": "EDUC 5264",
          "name": "Assessment Theory and Practice ",
          "CUs": "3",
          "description": "Assessment Theory and Practice focuses on issues central to assessment in the ELL environment, including high-stakes testing, standardized tests, placement and exit assessment, formative and summative assessments, and making adaptations in assessments to meet the needs of ELL students. "
        },
        "NNA1": {
          "courseID": "NNA1",
          "CCN": "EDUC 5263",
          "name": "Planning, Implementing, Managing Instruction ",
          "CUs": "4",
          "description": "Planning, Implementing, Managing Instruction focuses on a variety of philosophies and grade levels of English Language Learner (ELL) instruction. It includes the study of ELL listening and speaking, ELL reading and writing, specially designed academic instruction in English (SDAIE), and specific issues for various grade level instruction. "
        },
        "NMA1": {
          "courseID": "NMA1",
          "CCN": "EDUC 5265",
          "name": "Professional Role of the ELL Teacher ",
          "CUs": "2",
          "description": "The Professional Role of the ELL Teacher focuses on issues of professionalism for the English Language Learning teacher and leader. This includes program development, ethics, engagement in professional organizations, serving as a resource, and ELL advocacy. "
        },
        "ELO1": {
          "courseID": "ELO1",
          "CCN": "EDUC 6260",
          "name": "Subject Specific Pedagogy: ELL ",
          "CUs": "3",
          "description": "Subject Specific Pedagogy: ELL integrates aspects of pedagogy, assessment, and professionalism in English Language Learning (ELL). A student develops and assesses aspects of language curriculum development including second language instruction, methods of second language assessment, and legal policy issues.  "
        },
        "C224": {
          "courseID": "C224",
          "CCN": "EDUC 5111",
          "name": "Research Foundations ",
          "CUs": "2",
          "description": "The Research Foundations course focuses on the essential concepts in educational research, including quantitative, qualitative, mixed, and action research. This course also teaches students concepts about measurement and assessment, as well as strategies for obtaining warranted research results. "
        },
        "C225": {
          "courseID": "C225",
          "CCN": "EDUC 5112",
          "name": "Research Questions and Literature Review ",
          "CUs": "2",
          "description": "The Research Questions and Literature Reviews course focuses on how to conduct a thorough literature review that addresses and identifies important educational research topics, problems, and questions, and helps determine the appropriate kind of research and data needed to answer one's research questions and hypotheses. Research Foundations is a prerequisite for this course. "
        },
        "FEA1": {
          "courseID": "FEA1",
          "CCN": "EDUC 6261",
          "name": "Field Experience for ELL ",
          "CUs": "3",
          "description": "Field Experience for ELL is the field experience component of the English Language Learning program. In this experience, students are required to complete a minimum of 15 hours of video observations for both elementary and secondary levels. Additionally, a supervised teaching experience that is face-to-face with English language learners (ELL) according to the minimum time requirements of the student's state is required. The purpose of this course is to assess the ability of students, including their engagement in field experience activities, ability to reflect on and then plan standards-based instruction in ELL, and their ability to locate and effectively use resources for teaching ELL to meet the needs of their individual learners. "
        },
        "C360": {
          "courseID": "C360",
          "CCN": "EDUC 6754",
          "name": "Teacher Work Sample in English Language Learning ",
          "CUs": "1",
          "description": "The Teacher Work Sample is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills in this professional assessment. "
        },
        "C670": {
          "courseID": "C670",
          "CCN": "SCIE 5408",
          "name": "Concepts in Science ",
          "CUs": "1",
          "description": "Concepts in Science for graduates provides already-licensed teachers seeking an additional license or endorsement in science education with an introduction to essential science themes present within and across all science disciplines, including chemistry, physics, biology, and the geosciences. These themes include comprehending the magnitude of the physical and natural world, analyzing and converting measurements, understanding the basic nature and behavior of matter and energy, examining atomic structure, identifying and naming basic types of chemical bonds, and analyzing and interpreting scientific data. Concepts in Science provides a solid foundation for future, in-depth, scientific studies and should be taken prior to any other science content course. There are no prerequisites for this course. "
        },
        "C908": {
          "courseID": "C908",
          "CCN": "SCIE 5020",
          "name": "Integrated Physical Sciences ",
          "CUs": "2",
          "description": "This course provides students with an overview of the basic principles and unifying ideas of the physical sciences: physics, chemistry, and Earth sciences. Course materials focus on scientific reasoning and practical and everyday applications of physical science concepts to help students integrate conceptual knowledge with practical skills. "
        },
        "C672": {
          "courseID": "C672",
          "CCN": "CHEM 5409",
          "name": "General Chemistry I with Lab ",
          "CUs": "3",
          "description": "General Chemistry I with Lab for graduates provides an introduction to the field of chemistry to alreadylicensed teachers seeking an additional license or endorsement in secondary chemistry. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science is a prerequisite for this course. "
        },
        "C673": {
          "courseID": "C673",
          "CCN": "CHEM 5410",
          "name": "General Chemistry II with Lab ",
          "CUs": "3",
          "description": "General Chemistry II with Lab for graduates continues the study of general chemistry for already-licensed teachers seeking an additional license or endorsement in secondary chemistry. Building on the topics covered in General Chemistry I, General Chemistry II examines the behavior of gases and solutions, reaction rates and equilibrium, acids and bases, and oxidation-reduction reactions. Also, this course provides an introduction to three subdisciplines of chemistry: organic chemistry, biochemistry, and nuclear chemistry. Laboratory experiences reinforce the essential skills required for conducting successful scientific investigations. C672: General Chemistry I for graduates is a prerequisite for this course. "
        },
        "RXT2": {
          "courseID": "RXT2",
          "CCN": "MATH 5350",
          "name": "Precalculus and Calculus ",
          "CUs": "2",
          "description": "Precalculus and Calculus provides instruction in precalculus and calculus and applies them to examples found in both mathematics and science. Topics in precalculus include principles of trigonometry, mathematical modeling, and logarithmic, exponential, polynomial, and rational functions. Topics in calculus include conceptual knowledge of limit, continuity, differentiability, and integration. "
        },
        "BVT2": {
          "courseID": "BVT2",
          "CCN": "CHEM 5310",
          "name": "Physical Chemistry ",
          "CUs": "2",
          "description": "Physical Chemistry introduces the study of chemistry in terms of physical concepts. It  includes  thermodynamics,  reaction kinetics, chemical equilibrium, electrochemistry, and matter. "
        },
        "BWT2": {
          "courseID": "BWT2",
          "CCN": "CHEM 5300",
          "name": "Inorganic Chemistry ",
          "CUs": "2",
          "description": "Inorganic Chemistry introduces the concepts of inorganic chemistry—the branch of chemistry that studies the properties and behavior of any compound, avoiding a specific focus on carbon. It will focus on the three most important areas of inorganic chemistry:  the structure, properties, and reactions of various groups of inorganic compounds. "
        },
        "C389": {
          "courseID": "C389",
          "CCN": "EDUC 5409",
          "name": "Science, Technology, and Society ",
          "CUs": "2",
          "description": "Science, Technology, and Society explores the ways in which science influences and is influenced by society and technology. A humanistic and social endeavor, science serves the needs of ever-changing societies by providing methods for observing, questioning, discovering, and communicating information about the physical and natural world. This course prepares educators to explain the nature and history of science, the various applications of science, and the scientific and engineering processes used to conduct investigations, make decisions, and solve problems. There are no prerequisites for this course. "
        },
        "AIT2": {
          "courseID": "AIT2",
          "CCN": "CHEM 5250",
          "name": "Organic Chemistry ",
          "CUs": "2",
          "description": "Organic Chemistry focuses on the study of compounds that contain carbon, much of which is learning how to organize and group organic compounds in order to predict their structure, behavior, and reactivity based on common bonds found within an organic compound. "
        },
        "C625": {
          "courseID": "C625",
          "CCN": "SCIE 5501",
          "name": "Biochemistry ",
          "CUs": "2",
          "description": "Biochemistry covers the structure and function of the four major polymers produced by living organisms. These include nucleic acids, proteins, carbohydrates, and lipids. This course focuses on application and the underlying biochemistry in order to grasp how it is applied. This course will help students gain an introductory understanding of the chemicals and reactions that sustain life. Students will see the importance of this subject matter to health. "
        },
        "C267": {
          "courseID": "C267",
          "CCN": "EDUC 5512",
          "name": "Climate Change ",
          "CUs": "3",
          "description": "This course explores the science of climate change and covers how the climate system works; what factors cause climate to change across different time scales and how those factors interact; how climate has changed in the past; how scientists use models, observations, and theory to make predictions about future climate; and the possible consequences of climate change for our planet. The course explores evidence for changes in ocean temperature, sea level, and acidity due to global warming. It covers how climate change today is different from past climate cycles and how satellites and other technologies are revealing the global signals of a changing climate. Finally, the course looks at the connection between human activity and the current warming trend and considers some of the potential social, economic, and environmental consequences of climate change. "
        },
        "C617": {
          "courseID": "C617",
          "CCN": "CHEM 6405",
          "name": "Chemistry: Content Knowledge ",
          "CUs": "1",
          "description": "Chemistry: Content Knowledge provides advanced instruction in the main areas of chemistry for which secondary chemistry teachers are expected to demonstrate competency. Topics include matter and energy, thermochemistry, structure, bonding, reactivity, biochemistry and organic chemistry, solutions, the nature of science and technology, mathematics, and laboratory procedures. "
        },
        "C977": {
          "courseID": "C977",
          "CCN": "EDUC 5045",
          "name": "Science Methods—Secondary Chemistry ",
          "CUs": "3",
          "description": "Science Methods—Secondary Chemistry focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary chemistry. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C871": {
          "courseID": "C871",
          "CCN": "EDUC 6264",
          "name": "MA, Science Education Teacher Performance Assessment ",
          "CUs": "6",
          "description": "MA, Science Education Teacher Performance Assessment contains a comprehensive, original, research-based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision-making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
        },
        "D307": {
          "courseID": "D307",
          "CCN": "EDUC 5074",
          "name": "Educational Psychology and Human Development of Children and Adolescents ",
          "CUs": "4",
          "description": "Educational Psychology and Human Development of Children and Adolescents is a key component of Whalley’s Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to research-validated theories of human development and psychology, spanning from early childhood through adolescence, and their applications in teaching practice. Candidates will explore how linguistic, physical, cognitive, and social development influence the learning process and inform educational approaches. This course will also cover appropriate instructional and assessment strategies that can be used to support learning for developmentally diverse student populations. The course will culminate in analysis of learning theories related to educational psychology in order to develop a personal educational philosophy. Candidates will engage in four hours of preclinical experiences, which include virtual classroom observations from the perspective of educational psychology and learner development. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D168": {
          "courseID": "D168",
          "CCN": "EDUC 5055",
          "name": "Schools as Communities of Care ",
          "CUs": "2",
          "description": "Schools as Communities of Care is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to strategies for providing a culturally inclusive learning environment that meets the social and emotional needs of learners while taking into account theories and philosophical perspectives on child and adolescent development and learning. Emphasis is placed on fostering a collaborative relationship with families, caregivers, and community stakeholders, and on leveraging community resources to support each learner’s growth and well-being to build a strong foundation for their academic and personal success. Topics addressed include culturally responsive practice, social and emotional learning (SEL), youth mental health, substance abuse, suicide awareness and prevention, abuse within families, and professional responsibilities to ensure student wellbeing. The course will culminate in evidence-based, practical application of strategies that support the whole child in a community of care. Candidates will engage in seven hours of preclinical experiences, include virtual observations of learning environments that involve parents and families in their children's education and an interview with an educational professional. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D169": {
          "courseID": "D169",
          "CCN": "EDUC 5056",
          "name": "Essential Practices for Supporting Diverse Learners ",
          "CUs": "3",
          "description": "Fundamentals of Diverse Learners is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to consider and address the wide range of learning needs in the classrooms of today. This course teaches candidates to identify and support the needs of diverse populations of learners, including, for example, students with disabilities (Including Dyslexia), students who are English language learners, and students who are gifted and talented. Practical strategies for differentiating instruction while creating a safe, inclusive, and culturally responsive learning environment are explored. This course helps candidates develop skills for partnering with parents and advocating for all students, particularly those impacted by provisions of IDEA and Section 504 of the Rehabilitation Act. Multitiered systems of support are addressed to prepare candidates for their future classrooms as they seek to select appropriate instructional practices and interventions to best serve their students. Candidates will engage in four hours of preclinical experiences that include a simulated teaching experience in which skills learned can be applied. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D228": {
          "courseID": "D228",
          "CCN": "SPED 5290",
          "name": "Special Education Practices: Professional, Ethical and Legal Guidelines ",
          "CUs": "2",
          "description": "Special Education Practices: Professional, Ethical and Legal Guidelines prepares candidates to apply practice within ethical and legal guidelines in day-to-day teaching, stakeholder interactions, and other complex situations. This course provides an overview of the professional ethics and standards from the Council for Exceptional Children (CEC), which guide candidates to act in a professionally conscientious manner. This course also explores the transition planning requirements in IDEA, which include development of an individualized transition plan and ensures that planning is initiated in elementary (such as from K to elementary), middle school and continued through high school and post-secondary education. Candidates will explore the legal foundations and case laws related to special education to gain understanding of how legislation influences teaching and learning. Candidates will advocate for improved outcomes for students with exceptionalities and their families while addressing the unique needs of those with diverse social, cultural, and linguistic backgrounds. Candidates will engage in three hours of preclinical experiences, which include an interview with a special educator to gain insight on how these topics affect and inform teaching practice. This course is designed to be taken after successful completion of Essential Practices for Supporting Diverse Learners. "
        },
        "D170": {
          "courseID": "D170",
          "CCN": "EDUC 5057",
          "name": "Creating and Managing Engaging Learning Environments ",
          "CUs": "2",
          "description": "Creating and Managing Engaging Learning Environments is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with researchbased strategies and approaches to establishing and maintaining a safe and productive learning environment that supports the success and wellbeing of all P-12 learners. Topics addressed include consistent routines and expectations, student engagement, positive behavior support, motivation and its effect on student achievement, active learning and self-direction, and fostering a sense of community through collaboration. Candidates will design a classroom management plan for their future classroom based on theory and high-leverage practices for meeting the diverse needs of learners in a productive and collaborative learning environment. The course will culminate in evidence-based, practical application of current strategies to motivate and engage students in specific content areas. Candidates will engage in seven hours of preclinical experiences that include both virtual observations of classroom settings and time in a simulated classroom environment where theory can be put into practice. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D171": {
          "courseID": "D171",
          "CCN": "EDUC 5058",
          "name": "Curriculum, Instruction, and Assessment ",
          "CUs": "2",
          "description": "Curriculum, Instruction, & Assessment is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with theoretical foundations and strategies for creating engaging and standards-aligned lessons that meet the needs of all learners in the P-12 classroom. This course focuses on the interrelationship between curriculum, instruction, and assessment, with emphasis on the role of assessment and student data in planning, designing, delivering, and modifying instruction in accordance with diverse learner needs. This course will culminate in the application of evidence-based strategies related to the interdependence of and alignment among curriculum, instruction, and assessment in student-centered P-12 teaching and learning. Candidates will engage in three hours of preclinical experiences, which include conducting virtual classroom observations and recording a short teaching segment. Cross-cutting themes of technology and diversity are interwoven for continued development. "
        },
        "D237": {
          "courseID": "D237",
          "CCN": "SPED 5297",
          "name": "Mathematics Methods and Instruction for Students with Mild/Moderate Exceptionalities ",
          "CUs": "2",
          "description": "Mathematics Methods and Instruction for Students with Mild/Moderate Exceptionalities helps candidates learn how to implement effective math instruction in today’s diverse classrooms in both the elementary and secondary settings. Topics include differentiated math instruction, mathematical communication, mathematical tools for instruction, assessing math understanding, integrating math across the curriculum, critical thinking development, standards-based mathematics instruction, and mathematical models and representation for students with mild to moderate exceptionalities. "
        },
        "D229": {
          "courseID": "D229",
          "CCN": "SPED 5291",
          "name": "Management Strategies for Academic and Social Behavior ",
          "CUs": "3",
          "description": "Management Strategies for Academic and Social Behavior prepares candidates to work effectively with students exhibiting behavior in the classroom that is below age and cultural norms. This course provides an overview of behavior disorders and their causes, and appropriate research-based intervention strategies, including positive behavior intervention and supports, multitiered systems of support (MTSS), applied behavior analysis, replacement behavior and reward strategies, culturally responsive practices, and data collection and assessment methods. Candidates emerge prepared to strategize and recommend adjustments to the learning environment that support positive behavior and student success in the classroom and beyond. This course also examines behavioral assessment and analysis, including the creation of a functional behavior assessment (FBA) and the creation and monitoring of behavioral improvement plans (BIPs) in an authentic learning environment. The candidates will determine effective strategies to promote active student engagement, increase student motivation and opportunities to respond, and enhance self-regulation of student learning. This course is designed to be taken after successful completion of Creating and Managing Engaging Learning Environments. "
        },
        "D230": {
          "courseID": "D230",
          "CCN": "SPED 5292",
          "name": "Assessment and Evaluation Procedures in Special Education ",
          "CUs": "2",
          "description": "Assessment and Evaluation Procedures in Special Education prepares candidates to use multiple methods of assessment and data sources in making educational decisions about the student and the learning environment. This course is designed to help provide an understanding of how assessment data is used during screening in multitiered systems of support (MTSS), the eligibility process, the evaluation process, progress monitoring, and data-based instructional decision making. Candidates analyze informal assessments to determine how students access and demonstrate knowledge in the core curriculum. This course is designed to be taken by candidates after they have completed Special Education Practices: Professional, Ethical, and Legal Guidelines. "
        },
        "C380": {
          "courseID": "C380",
          "CCN": "EDUC 6380",
          "name": "Language Arts Instruction and Intervention ",
          "CUs": "2",
          "description": "Language Arts Instruction and Intervention helps students learn how to implement effective language arts instruction and intervention in the elementary classroom. Topics include written and spoken English, expanding students' knowledge, literature-rich environments, differentiated instruction, technology for reading and writing, assessment strategies for reading and writing, and strategies for developing academic language. There are no prerequisites for this course. "
        },
        "C910": {
          "courseID": "C910",
          "CCN": "EDUC 6207",
          "name": "Elementary Reading Methods and Interventions ",
          "CUs": "2",
          "description": "Elementary Reading Methods and Interventions provides candidates with an in-depth look at best practices for developing reading and writing skills. Course content examines the science of reading, the stages of and approaches to literacy development, differentiation, technology integration, literacy assessment, and Multitiered Systems of Supports (MTSS), including the comprehensive response to intervention (RTI) model used to identify and address the needs of learners who struggle with reading comprehension. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment. "
        },
        "D163": {
          "courseID": "D163",
          "CCN": "EDUC 5317",
          "name": "Secondary Reading Instruction and Interventions ",
          "CUs": "2",
          "description": "Secondary Reading Instruction and Intervention explores the comprehensive, studentcentered Response to Intervention (RTI) assessment and intervention model used to identify and address the needs of learners in middle school and high school who struggle with reading comprehension and/or information retention. Course content provides educators with effective strategies designed to scaffold instruction and help learners develop increased skill in the following areas: reading, vocabulary, text structures and genres, and logical reasoning related to the academic disciplines. This course has no prerequisites. "
        },
        "D244": {
          "courseID": "D244",
          "CCN": "SPED 5304",
          "name": "Disciplinary Literacy ",
          "CUs": "2",
          "description": "Disciplinary Literacy examines teaching strategies designed to help candidates to develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help candidates distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and  Presentation AND Instructional Planning and Presentation in Special Education. "
        },
        "D173": {
          "courseID": "D173",
          "CCN": "EDUC 5060",
          "name": "Using Educational Technology for Teaching and Learning ",
          "CUs": "2",
          "description": "Using Educational Technology for Teaching and Learning is a key component of Whalley's professional core and is a required course for all Master of Arts in Teaching candidates. This course presents strategies for integrating technology into classroom practices to improve instruction and student learning according to the International Society for Technology in Education (ISTE) standards. Candidates will evaluate digital tools and their potential classroom applications such as enhancing curriculum, enabling communication with students and families, and increasing student engagement. Topics covered include ethics, equity and access to technology, and appropriate use of technology by P–12 students. Assistive technologies to meet the needs of a diverse learner population also will be addressed. The course will culminate in evidence-based, practical application of current standards, strategies, theories, or philosophical perspectives related to the use of technology in teaching and learning. Candidates will engage in three hours of preclinical experience that include virtual observations of classroom practices incorporating technology to support educational goals. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D231": {
          "courseID": "D231",
          "CCN": "SPED 5293",
          "name": "Collaborative Techniques with Partners for Effective IEPs ",
          "CUs": "2",
          "description": "Collaborative Techniques with Partners for Effective IEPs prepares candidates to apply team processes and communication strategies to collaborate in a culturally responsive manner with families, paraeducators, and other professionals (within the school, other educational settings, and the community) to plan programs and access services for students with exceptionalities and their families. The course introduces ways to enhance parental involvement and family engagement while teaching families and students advocacy throughout the Individualized Education Program (IEP) and transition planning processes. Candidates will develop plans for transition services that focus on a coordinated set of student-centered activities designed to facilitate the student's movement from school to postschool activities, including post-secondary education. This course also focuses on the components of the IEP and how the practice of effective communication and collaboration skills is key to the program's development and implementation. The candidates will actively seek information from and about families and take primary responsibility for maintaining respectful, ongoing, open communication to jointly identify and meet learning goals that are informed by assessment data. Candidates will engage in three hours of preclinical experiences that includes a simulated collaborative experience in which skills learned can be applied. This course is designed to be taken after successful completion of Special Education Practices: Professional, Ethical and Legal Guidelines. "
        },
        "D232": {
          "courseID": "D232",
          "CCN": "SPED 5294",
          "name": "Special Education Methods of Instruction and Intervention ",
          "CUs": "2",
          "description": "Special Education Methods of Instruction and Intervention introduces candidates to a repertoire of evidence-based instructional strategies to advance the learning of students with exceptionalities. The course focuses specifically on strategies for intensifying and individualizing instructional interventions; making instructional decisions based on progress-monitoring data; collaborating with general education teachers and paraeducators; teaching to mastery; promoting generalization of learning; and teaching students with exceptionalities how to use self-assessment, problem solving, and other cognitive strategies to organize critical content and meet their needs. This course will also focus on the interrelationship between curriculum, instruction, and assessment, with emphasis on the role of assessment and student data in planning, designing, delivering, and modifying instruction in accordance with diverse learner needs. Candidates will know and understand how learning occurs, how students construct knowledge, acquire skills, and develop disciplined thinking processes. This course is designed to be taken after successful completion of Curriculum, Instruction, and Assessment, Mathematics Methods and Instruction for Students with Mild/Moderate Disabilities, and Language Arts Instruction and Interventions. "
        },
        "D233": {
          "courseID": "D233",
          "CCN": "SPED 5295",
          "name": "Designing Instruction for Elementary Learners with Mild to Moderate Exceptionalities ",
          "CUs": "2",
          "description": "Designing Instruction for Elementary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for elementary students receiving special education services. The course includes cognitive and metacognitive strategies that elementary students can use to acquire new content knowledge and generalize skills across learning environments. It also provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math, reading, and language arts lesson plans based on learner characteristics, performance data, and individualized education program (IEP) goals. In addition to discussing how to make appropriate accommodations, the course teaches candidates how to assess student learning through progress monitoring and apply intensive interventions when warranted. Candidates apply their understanding of academic subject content specifically focusing on reading, writing, and math curricula of the general curriculum to inform instructional decisions for individual with exceptionalities. Candidates design appropriate learning and performance accommodations and modifications for individuals with exceptional learning needs in academic subject matter content of the general curriculum curricula. This course is designed to be taken after successful completion of Special Education Methods of Instruction and Intervention. "
        },
        "D234": {
          "courseID": "D234",
          "CCN": "SPED 5296",
          "name": "Designing Instruction for Secondary Learners with Mild to Moderate Exceptionalities ",
          "CUs": "2",
          "description": "Designing Instruction for Secondary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for use with secondary students receiving special education services. Strategies taught in this course focus on intensive instruction and making accommodations to secondary lesson plans in order to develop critical thinking and problem-solving skills to enhance acquisition of age-appropriate secondary content across academic disciplines in math, reading and English/language arts. This course also promotes the achievement of Individualized Education Program (IEP) and transition goals for independent living, post-secondary education and career preparation through demonstration of strategies that increase students' self-awareness, self-regulation, self-management, self-control, and self-esteem. Because of the significant role that content specific subject matter knowledge plays at the secondary level, candidates will demonstrate a solid understanding of the subject matter content specifically focusing on math, reading, English/language arts to sufficiently assure that students with exceptionalities can meet state curriculum standards. Candidates design appropriate learning and performance accommodations and modifications for individuals with exceptional learning needs in academic subject matter content of the general curriculum. This course is designed to be taken after successful completion of Curriculum, Instruction, and Assessment. "
        },
        "D238": {
          "courseID": "D238",
          "CCN": "SPED 5298",
          "name": "Preclinical Experiences in Special Education ",
          "CUs": "2",
          "description": "Pre-Clinical Experiences in Special Education provides candidates the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Candidates will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, candidates will be required to include a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "D239": {
          "courseID": "D239",
          "CCN": "SPED 5299",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 1 and 2 ",
          "CUs": "2"
        },
        "D240": {
          "courseID": "D240",
          "CCN": "SPED 5300",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 3 and Midterm ",
          "CUs": "2"
        },
        "D241": {
          "courseID": "D241",
          "CCN": "SPED 5301",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 4 and 5 ",
          "CUs": "2"
        },
        "D242": {
          "courseID": "D242",
          "CCN": "SPED 5302",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 6 and Final ",
          "CUs": "2"
        },
        "D243": {
          "courseID": "D243",
          "CCN": "SPED 5303",
          "name": "Teacher Performance Assessment in Special Education ",
          "CUs": "1",
          "description": "Teacher Performance Assessment in Special Education course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C347": {
          "courseID": "C347",
          "CCN": "EDUC 5255",
          "name": "Professional Portfolio ",
          "CUs": "1",
          "description": "Professional Portfolio requires candidates to create an online teaching portfolio that demonstrates professional beliefs, growth, and effective teaching practices from the Demonstration Teaching experience. The portfolio includes reflective essays (educational beliefs, professional growth, and collaboration with stakeholders) and professional artifacts (resume and artifacts with commentary on academic language, systems of student support, education technology, and professional communication with families) developed and acquired during Demonstration Teaching.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D245": {
          "courseID": "D245",
          "CCN": "SPED 5305",
          "name": "Cohort Seminar in Special Education ",
          "CUs": "1",
          "description": "Cohort Seminar in Special Education provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C200": {
          "courseID": "C200",
          "CCN": "MGMT 5000",
          "name": "Managing Organizations and Leading People ",
          "CUs": "3",
          "description": "This course covers principles of effective management and leadership that maximize organizational performance. The following topics are included: the role and functions of a manager, analysis of personal leadership styles, approaches to self-awareness and self-assessment, and application of foundational leadership and management skills. "
        },
        "MGT2": {
          "courseID": "MGT2",
          "CCN": "ITM 5000",
          "name": "IT Project Management ",
          "CUs": "3",
          "description": "IT Project Management provides an overview of the Project Management Institute’s project management methodology. Topics cover various process groups and knowledge areas and application of knowledge in case studies for planning a project that has not started yet and monitoring/controlling a project that is already underway. "
        },
        "C202": {
          "courseID": "C202",
          "CCN": "HRM 5010",
          "name": "Managing Human Capital ",
          "CUs": "3",
          "description": "This course focuses on strategies and tools that managers use to maximize employee contribution and create organizational excellence. You will learn talent management strategies to motivate and develop employees as well as best practices to manage performance for added value. "
        },
        "C212": {
          "courseID": "C212",
          "CCN": "MKTG 5000",
          "name": "Marketing ",
          "CUs": "3",
          "description": "Marketing Fundamentals introduces students to principles of the marketing environment, social media, consumer behavior, marketing research, and market segmentation. Students will also explore marketing strategies that are related to products and services, distribution channels, promotions, sales, and pricing. "
        },
        "MMT2": {
          "courseID": "MMT2",
          "CCN": "ITM 6000",
          "name": "IT Strategic Solutions ",
          "CUs": "4",
          "description": "IT Strategic Solutions guides students in identifying strategic opportunities and emerging technologies through research and deciding on a system to support a growing company. Topics will include technology strategy; gap analysis; researching new technology; strengths, opportunities, weaknesses, and threats; ethics; risk mitigation; data security, communication plans; and globalization. "
        },
        "C213": {
          "courseID": "C213",
          "CCN": "ACCT 5000",
          "name": "Accounting for Decision Makers ",
          "CUs": "3",
          "description": "This course provides you with the accounting knowledge and skills to assess and manage a business. Topics include the accounting cycle, financial statements, taxes, and budgeting. This course will improve students’ ability to understand reports and use accounting information to plan and make sound business decisions. "
        },
        "C206": {
          "courseID": "C206",
          "CCN": "MGMT 6000",
          "name": "Ethical Leadership ",
          "CUs": "3",
          "description": "This course examines the ethical issues and dilemmas managers face. This course provides a framework for analysis of management-related ethical issues and decision-making action required for satisfactory resolution of these issues. "
        },
        "C214": {
          "courseID": "C214",
          "CCN": "FINC 6000",
          "name": "Financial Management ",
          "CUs": "3",
          "description": "This course covers practical approaches to analysis and decision-making in the administration of corporate funds, including capital budgeting, working capital management, and cost of capital. Topics include financial planning, management of working capital, analysis of investment opportunities, sources of long-term financing, government regulations, and global influences. This course will improve students’ ability to interpret financial statements and manage corporate finances. "
        },
        "C207": {
          "courseID": "C207",
          "CCN": "MGMT 6010",
          "name": "Data-Driven Decision Making ",
          "CUs": "3",
          "description": "This course presents critical problem-solving methodologies, including field research and data collection methods that enhance organizational performance. Topics include quantitative analysis, statistical and quality tools. You will improve your ability to use data to make informed decisions. "
        },
        "C211": {
          "courseID": "C211",
          "CCN": "ECON 5000",
          "name": "Global Economics for Managers ",
          "CUs": "3",
          "description": "This course examines how economic tools, techniques, and indicators can be used for solving organizational problems related to competitiveness, productivity, and growth. You will explore the management implications of a variety of economic concepts and effective strategies to make decisions within a global context. "
        },
        "C218": {
          "courseID": "C218",
          "CCN": "ITM 6900",
          "name": "MBA, Information Technology Management Capstone ",
          "CUs": "4",
          "description": "MBA Information Technology Management Capstone is the culminating course in the MBA ITM program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "D509": {
          "courseID": "D509",
          "CCN": "MHA 5110",
          "name": "Innovative Solutions in Healthcare Leadership ",
          "CUs": "3",
          "description": "Innovative Solutions in Health Administration provides an opportunity to explore healthcare innovations through comparison research, the application of disruptive leadership concepts, and advanced technology applications. Students will apply strategic innovation concepts to improve critical patient dissatisfiers in a healthcare setting. This course has no prerequisites. "
        },
        "D510": {
          "courseID": "D510",
          "CCN": "MHA 5220",
          "name": "Collaborative Leadership ",
          "CUs": "3",
          "description": "Collaborative Leadership provides an opportunity to apply collaborative leadership skills to better serve diverse communities. Students will develop a process innovation with community leaders in a diverse population emphasizing a cultural competence. This course has no prerequisites.   "
        },
        "D511": {
          "courseID": "D511",
          "CCN": "MHA 5310",
          "name": "Healthcare Models and Systems ",
          "CUs": "2",
          "description": "Healthcare Models and Systems provides an opportunity to analyze the evolution of healthcare models and systems. Students will apply administration strategies to manage organizational changes and community affiliations. This course has no prerequisites. "
        },
        "D512": {
          "courseID": "D512",
          "CCN": "MHA 5410",
          "name": "Quality Improvement in Healthcare ",
          "CUs": "3",
          "description": "Quality Improvement in Healthcare provides an opportunity to apply quality improvement principles and strategies in a high-volume Level 1 trauma center. Students will apply disruptive leadership strategies to implement quality-improvement procedures in a fast-paced healthcare environment. This course has no prerequisites. "
        },
        "D513": {
          "courseID": "D513",
          "CCN": "MHA 5510",
          "name": "Healthcare Financial Management ",
          "CUs": "3",
          "description": "Healthcare Financial Management provides an opportunity to apply strategic change management principles through the application of fiscal management and data analysis in a healthcare environment. The student will examine strategies to increase value, sustainability, and productivity in a patient-centric environment. This course has no prerequisites. "
        },
        "D514": {
          "courseID": "D514",
          "CCN": "MHA 5600",
          "name": "Analytical Methods of Healthcare Leaders ",
          "CUs": "2",
          "description": "Analytical Methods of Healthcare Leaders provides an opportunity to explore the use of predictive analysis and forecasting techniques to develop evidence-based decision making. Students will apply quality research and analytical analysis to inform decisions in a health management environment. This course has no prerequisites. "
        },
        "D515": {
          "courseID": "D515",
          "CCN": "MHA 6210",
          "name": "Enterprise Risk Management ",
          "CUs": "3",
          "description": "Enterprise Risk Management provides an opportunity to examine risk exposure and response, and risk mitigation within an integrated care delivery model. Students will apply practices to identify risks and develop sustainable corrective action plans. This course has no prerequisites. "
        },
        "D516": {
          "courseID": "D516",
          "CCN": "MHA 6310",
          "name": "Healthcare Information Technology ",
          "CUs": "2",
          "description": "Healthcare Information Technology provides an opportunity to examine the use of technology in data analysis and applications to improve outcomes in a patient-centered care environment. Students will apply strategic analysis to improve technology function and interoperability within a community healthcare cooperative. This course has no prerequisites. "
        },
        "D517": {
          "courseID": "D517",
          "CCN": "MHA 6410",
          "name": "Population Healthcare Coordination ",
          "CUs": "3",
          "description": "Population Healthcare Coordination provides an opportunity to examine population healthcare strategies and community collaboration to impact at-risk demographic groups. Students will apply strategic change management and data analysis to develop health initiatives for a large-scale population. This course has no prerequisites. "
        },
        "D518": {
          "courseID": "D518",
          "CCN": "MHA 6510",
          "name": "Challenges in Community Healthcare ",
          "CUs": "3",
          "description": "Challenges in Community Healthcare provides an opportunity to explore organizational leadership and administration as well as problem-solving methods to collaborate with community leaders in a high-stakes healthcare environment. Students will apply collaborative leadership skills and evidence-based practices as they develop community relationships to resolve critical issues in community health management. This course has no prerequisites.   "
        },
        "D519": {
          "courseID": "D519",
          "CCN": "MHA 6610",
          "name": "Integrated Healthcare Leadership and Administration ",
          "CUs": "3",
          "description": "Integrated Healthcare Leadership and Administration provides an opportunity to examine integrated healthcare delivery systems and person-centered care models for innovative solutions to critical challenges. The student will apply principles of collaborative leadership, disruptive change, and catalyst evaluation to develop a holistic integrated healthcare system. This course has no prerequisites. "
        },
        "D520": {
          "courseID": "D520",
          "CCN": "MHA 6910",
          "name": "Healthcare Leadership and Administration Capstone ",
          "CUs": "4",
          "description": "The capstone is a student-designed project intended to illustrate your ability to effect change in the industry and demonstrate competence in all five program outcomes: transformational leader, value innovator, tactical manager, analyst,  and integrated systems expert. Students are required to collaborate with leaders in the healthcare industry to identify opportunities for improvement in healthcare, propose a solution, and perform a business analysis to evaluate its feasibility. In addition, the capstone encourages work in the healthcare industry that will be showcased in the student’s collection of work and help solidify professional relationships in the industry. This course has no prerequisites. "
        },
        "D204": {
          "courseID": "D204",
          "CCN": "DTAN 5101",
          "name": "The Data Analytics Journey ",
          "CUs": "2",
          "description": "The Data Analytics Journey gives an overview of the entire analytics life cycle. Learners gain fluency in data analytics terminology, tools, and techniques. The course contextualizes the data analytics journey firmly with organizational metrics and requirements to position graduates to answer key questions for businesses and other employers. This course has no prerequisites. "
        },
        "D205": {
          "courseID": "D205",
          "CCN": "DTMG 5240",
          "name": "Data Acquisition ",
          "CUs": "3",
          "description": "Data Acquisition builds proficiency in Structured Query Language (SQL) and the initial stages of the data analytics lifecycle. The course introduces relational databases. Students gain concrete skills in data transference and database manipulation. There are no prerequisites. "
        },
        "D206": {
          "courseID": "D206",
          "CCN": "DTAN 5201",
          "name": "Data Cleaning ",
          "CUs": "3",
          "description": "Data Cleaning continues building proficiency in the data analytics life cycle with data preparation skills. This course addresses exploring, transforming, and imputing data as well as handling outliers. Learners write code to manipulate, structure, and clean data as well as to reduce features in data sets. The following courses are prerequisites: The Data Analytics Journey, and Data Acquisition. "
        },
        "D207": {
          "courseID": "D207",
          "CCN": "DTAN 5202",
          "name": "Exploratory Data Analysis ",
          "CUs": "3",
          "description": "Exploratory Data Analysis covers statistical principles supporting the data analytics life cycle. Students in this course compute and interpret measures of central tendency, correlations, and variation. The course introduces hypothesis testing, focusing on application for parametric tests, and addresses communication skills and tools to explain an analyst’s findings to others within an organization. Data Cleaning is a required prerequisite for this course. "
        },
        "D208": {
          "courseID": "D208",
          "CCN": "DTAN 5203",
          "name": "Predictive Modeling ",
          "CUs": "3",
          "description": "Predictive Modeling builds on initial data preparation, cleaning, and analysis, enabling students to make assertions vital to organizational needs. In this course, students conduct logistic regression and multiple regression to model the phenomena revealed by data. The course covers normality, homoscedasticity, and significance, preparing students to communicate findings and the limitations of those findings accurately to organizational leaders. Exploratory Data Analysis is a prerequisite for this course. "
        },
        "D209": {
          "courseID": "D209",
          "CCN": "DTAN 6250",
          "name": "Data Mining I ",
          "CUs": "3",
          "description": "Data Mining I expands predictive modeling into nonlinear dimensions, enhancing the capabilities and effectiveness of the data analytics lifecycle. In this course, learners implement supervised models—specifically classification and prediction data mining models—to unearth relationships among variables that are not apparent with more surface-level techniques. The course provides frameworks for assessing models’ sensitivity and specificity. D208 Predictive Modeling is a prerequisite to this course. "
        },
        "D210": {
          "courseID": "D210",
          "CCN": "DTAN 6204",
          "name": "Representation and Reporting ",
          "CUs": "3",
          "description": "Representation and Reporting focuses on communicating observations and patterns to diverse stakeholders, a key aspect of the data analytics life cycle. This course helps students gain communication and storytelling skills. It also covers data visualizations, audio representations, and interactive dashboards. The prerequisite for this course is Data Mining I. "
        },
        "D211": {
          "courseID": "D211",
          "CCN": "DTMG 6240",
          "name": "Advanced Data Acquisition ",
          "CUs": "2",
          "description": "Advanced Data Acquisition enhances theoretical and SQL skills in furthering the data analytics life cycle. This  course covers advanced SQL operations, aggregating data, and acquiring data from various sources in support of core organizational needs. The  prerequisite for this course is Representation and Reporting. "
        },
        "D212": {
          "courseID": "D212",
          "CCN": "DTAN 6250",
          "name": "Data Mining II ",
          "CUs": "3",
          "description": "Data Mining II adds vital tools to data analytics arsenal that incorporates unsupervised models. This course explains when,  how, and why to use these tools to best meet organizational needs. The prerequisite for this course is Advanced Data Acquisition. "
        },
        "D213": {
          "courseID": "D213",
          "CCN": "DTAN 6205",
          "name": "Advanced Data Analytics ",
          "CUs": "3",
          "description": "Advanced Data Analytics prepares students for career-long growth in steadily advancing tools and techniques and provides emerging concepts in data analysis. This course hones the mental and theoretical flexibility that will be required of analysts in the coming decades while grounding their approach firmly in ethical and organizational-need-focused practice. Topics include machine learning, neural networks, randomness, and unconventional data sources. Data Mining II is a prerequisite for this course. "
        },
        "D214": {
          "courseID": "D214",
          "CCN": "DTAN 6520",
          "name": "Data Analytics Graduate Capstone ",
          "CUs": "3",
          "description": "The Data Analytics Graduate Capstone allows students to apply the academic and professional abilities developed as a graduate student. This capstone challenges students to integrate skills and knowledge from several program domains into one project. Advanced Data Analytics is a prerequisite for this course. "
        },
        "C201": {
          "courseID": "C201",
          "CCN": "BUS 5000",
          "name": "Business Acumen ",
          "CUs": "3",
          "description": "The Business Acumen course introduces you to the operation of the business enterprise and the role of management in directing the activities of the business. You will examine the roles of management in the context of business functions such as marketing, operations, accounting, and finance. "
        },
        "D432": {
          "courseID": "D432",
          "CCN": "MHRM 5000",
          "name": "HR Compliance and Employee Relations ",
          "CUs": "3",
          "description": "HR Compliance and Employee Relations provides students with an in-depth understanding of the relevant laws, regulations, and ethical issues related to human resource (HR) compliance and risk management. It also explores how to resolve and improve employee relations issues to maintain a positive organizational culture in a diverse workplace. Topics include business laws and ethical considerations, employment and labor laws and regulations, and employee relations strategies to build and maintain a positive, healthy, and respectful work environment. "
        },
        "C204": {
          "courseID": "C204",
          "CCN": "MGMT 5010",
          "name": "Management Communication ",
          "CUs": "3",
          "description": "This course prepares students for the communication challenges in organizations. Topics examined include theories and strategies of communication, persuasion, conflict management, and ethics that enhance communication to various audiences. "
        },
        "D433": {
          "courseID": "D433",
          "CCN": "MHRM 6000",
          "name": "Talent Acquisition and Development ",
          "CUs": "3",
          "description": "Talent Acquisition and Development provides an in-depth look at the strategies used to attract, retain, and develop qualified talent in an organization. Students discover how to hire the right talent to meet the needs of the organization, how to orient and onboard new employees, and how to ensure employee excellence through learning and development and performance management strategies. "
        },
        "D435": {
          "courseID": "D435",
          "CCN": "MHRM 6020",
          "name": "HR Technology and People Analytics ",
          "CUs": "3",
          "description": "HR Technology and People Analytics introduces students to the types of human resource information systems (HRISs), applications, and platforms used to capture and manage employee data and the analytics used to make strategic decisions based on that data. Students will discover how to plan for the implementation of new human resource (HR) technology, present the plan to stakeholders to gain buy-in and support for the change, and train employees in the new systems. Students will also gain an understanding of how to pull and use data and people analytics for effective storytelling, decision-making, and leadership influence. "
        },
        "D434": {
          "courseID": "D434",
          "CCN": "MHRM 6010",
          "name": "Future Focused Total Rewards ",
          "CUs": "3",
          "description": "Future Focused Total Rewards examines discretionary and legally required approaches to compensation and benefits practices that compose an organization’s total rewards system. Students explore how to develop and communicate the components of a competitive total rewards strategy to prospective and existing employees while adhering to employment laws and aligning to an organization’s strategic goals and culture. "
        },
        "D436": {
          "courseID": "D436",
          "CCN": "MHRM 6030",
          "name": "Inclusive Workplace Culture Capstone ",
          "CUs": "3",
          "description": "Inclusive Workplace Culture Capstone provides students with the opportunity to work through the SHRM Inclusive Workplace Culture specialty credential course content, studying and analyzing how human resource (HR) professionals can integrate an inclusive approach to all HR functions such as talent acquisition, training and development, total rewards, and more. Students will complete a capstone project that synthesizes an inclusive approach to strategic HR practices for an organization to create an environment of true belonging for all employees, while simultaneously being prepared to complete the SHRM Inclusive Workplace Culture exam independently to earn a specialty credential and badge from SHRM. "
        },
        "D263": {
          "courseID": "D263",
          "CCN": "MGMT 5040",
          "name": "Frameworks for Strategic Decision-Making ",
          "CUs": "3",
          "description": "Frameworks for Strategic Decision-Making challenges students to use logistical reasoning, root cause analysis, and various problem-solving skills to drive improvement, develop relationships, influence others, and make decisions. This course addresses how to evaluate business problems, develop stakeholder-oriented solutions, and influence key stakeholders. It also promotes strategiclevel thinking and connection between business disciplines to drive outcomes. There are no prerequisites. "
        },
        "D373": {
          "courseID": "D373",
          "CCN": "MKTG 5010",
          "name": "Marketing in the Digital Era ",
          "CUs": "3",
          "description": "Marketing in the Digital Era examines the marketing skills needed to be an effective marketer in the 21st century. This course provides a company and consumer perspective to learn how consumer value is created while achieving organizational goals. Learners will gain knowledge in the core areas of marketing, including branding and products, consumers, communications, and technology. This course introduces learners to strategic marketing tools used to develop effective strategies for a relevant target market. Marketing in the Digital Era teaches learners about emerging topics, such as marketing automation, artificial intelligence, and data-driven communications, as well as skills needed to continue evolving as a successful marketing professional in the dynamic, ever-changing marketing environment. "
        },
        "D374": {
          "courseID": "D374",
          "CCN": "MKTG 5020",
          "name": "Market Research ",
          "CUs": "3",
          "description": "Market Research provides learners with a knowledge of the role of marketing research in strategic decision-making. Marketers need to systematically collect and analyze data to develop insights and make decisions. Learners demonstrate proficiency in the fundamentals of market research by practicing statistical methods for analyzing and acting on market data. This course teaches sampling techniques, survey development, data analysis, ethical considerations, and hypothesis testing. Learners will apply their knowledge using a market research simulation. Emphasis is placed on the interpretation and use of results to demonstrate how to communicate information. In this course, learners will also develop awareness for continually monitoring emerging and competitive trends given the dynamic digital marketing landscape. "
        },
        "D375": {
          "courseID": "D375",
          "CCN": "MKTG 5030",
          "name": "Marketing Communications and Storytelling ",
          "CUs": "3",
          "description": "Marketing Communications and Storytelling introduces learners to the principles of integrative marketing communications with an emphasis on storytelling and creativity. Storytelling skills enable marketers to build relationships by making connections with their intended audience. Digital storytelling integrates the use of technology to create a cohesive narrative across various platforms that evokes emotions and feelings about a brand. These efforts can lead to customer acquisition or conversion outcomes. In this course, the creative storytelling process begins with a clear SMART objective that drives strategy development, the creative direction, and the execution of an integrative marketing communication campaign. Campaign types, media channels, messages, timelines, and market development considerations are taught within the context of both digital and traditional application. Learners will also have the opportunity to complete the HubSpot Inbound Marketing certification to learn industry best practices and to enhance marketability within the marketing profession. "
        },
        "D376": {
          "courseID": "D376",
          "CCN": "MKTG 5040",
          "name": "Product, Price, and Customer Experience ",
          "CUs": "3",
          "description": "Product, Price, & Customer Experience teaches core marketing concepts used to create integrative marketing strategies that meet the needs of an organization and its customers. This course teaches topics of new product development, product management, value proposition, and customer experience management. The voice of the customer is taught to ensure marketers create a customer-centric culture within their organization to meet the needs, wants, and preference of their target market. Customer experience management practices are key focal points of this course to ensure meaningful customer interactions across the entire customer journey. "
        },
        "D377": {
          "courseID": "D377",
          "CCN": "MKTG 6000",
          "name": "Digital Marketing Foundations ",
          "CUs": "3",
          "description": "Digital Marketing Foundations provides learners with knowledge in the dynamic and evolving field of marketing that uses the internet and digital technologies to reach customers. This course provides foundational knowledge in digital marketing disciplines, including social media marketing, content marketing, marketing automation, search engine optimization (SEO), paid search (PPC), conversion rate optimization (CRO), mobile marketing, and web analytics. Learners explore how a digital marketing strategy is developed and executed, along with how digital marketing is integrated within an organization's overall strategy. This course provides learners with the opportunity to complete the HubSpot Marketing Software certification to learn marketing automation software that is used in industry to assist marketers in their digital marketing execution. Emphasis throughout the course is placed on industry best practices, and the course content aligns with the Online Marketing Certified Associate (OMCA) certification to prepare students for this third-party certification. "
        },
        "D379": {
          "courseID": "D379",
          "CCN": "MKTG 6020",
          "name": "Social Media Marketing ",
          "CUs": "3",
          "description": "Social Media Marketing teaches learners about social media from a business perspective and how social media can be used to increase a company’s brand awareness, generate leads, and build meaningful relationships with customers. Throughout this course, learners will develop a social media marketing strategy across various social platforms to create and distribute valuable and relevant content to specific audiences. Social Media Marketing teaches learners influencer marketing, employee advocacy, social selling, and social media analytics. Learners will gain hands-on experience using a simulation to create targeted social media advertisements, perform demographic targeting, implement social media content promotion strategies, and schedule content. In this course, learners will have the opportunity to complete HubSpot's Social Media Marketing certification to learn industry best practices in social media marketing and to enhance marketability within the marketing profession. "
        },
        "D380": {
          "courseID": "D380",
          "CCN": "MKTG 6030",
          "name": "Email Marketing ",
          "CUs": "3",
          "description": "Email Marketing examines the principles and techniques used to effectively manage email marketing campaigns. Email is used daily in both personal and business settings to communicate with others. In this course, learners will examine industry best practices of how to use email marketing as a digital strategy to achieve marketing goals. Learners will explore how to design an email marketing strategy, how to use email marketing for lead generation, how to design a lead nurture campaign, and how automation is used to manage email marketing distribution and campaigns. This course provides learners with an opportunity to explore how effective email messages are crafted and distributed. Industry best practices will be explored and learners have the opportunity to complete the HubSpot Email Marketing certification to enhance marketability within the marketing profession. "
        },
        "D381": {
          "courseID": "D381",
          "CCN": "MKTG 6040",
          "name": "E-Commerce and Marketing Analytics ",
          "CUs": "3",
          "description": "E-Commerce and Marketing Analytics teaches learners how to sell online, how to reach customers online, and how to measure campaign and website performance. Businesses and consumers actively engage in buying and selling products over the internet. Learners gain skills through the practical application of building and optimizing a Shopify website designed to promote and sell products to customers. The course explores the latest technology platforms with an emphasis on Google applications for hands-on experience. Learners have the opportunity to complete the Google Search Ads certification to gain skills used in practice and to enhance marketability within the marketing profession. "
        },
        "D384": {
          "courseID": "D384",
          "CCN": "MKTG 6070",
          "name": "Marketing Experiential Capstone ",
          "CUs": "3",
          "description": "Marketing Experiential Capstone is the capstone course for the program that provides learners with realworld applications to prepare them for the marketing industry. In this course, learners will integrate and apply marketing skills gained throughout the program by working with an organization on a marketing project. Marketers must effectively manage many relationships throughout their career with clients and team members in an organization, an agency, or their own marketing firm. This course allows students to apply their technical knowledge while also developing competencies in effective communication, collaboration, conflict management, project management, and time management power skills. Learners will explore their professional goals and develop a personal branding strategy to enhance their marketability and to strategically plan for their marketing career. "
        },
        "C254": {
          "courseID": "C254",
          "CCN": "ACCT 6000",
          "name": "Fraud and Forensic Accounting ",
          "CUs": "3",
          "description": "This course provides a framework for detecting and preventing financial statement fraud. Topics include the profession’s focus and legislation of fraud, revenue- and inventory-related fraud, and liability, asset, and inadequate disclosure fraud. "
        },
        "D251": {
          "courseID": "D251",
          "CCN": "ACCT 6201",
          "name": "Advanced Auditing ",
          "CUs": "3",
          "description": "Advanced Auditing reviews basic auditing concepts, including (1) planning the audit: identifying, assessing, and    responding to the risk of material misstatement; (2) specialized audit tools: attributes sampling, monetary unit sampling, and data analytic tools; (3) completing a quality audit; and (4) reporting on financial statement audits. The second part of the course dives into an application of auditing through (1) understanding how to audit an acquisition and payment cycle and (2) applying the knowledge learned through the acquisition and payment cycle to the revenue cycle in a performance assessment. "
        },
        "C253": {
          "courseID": "C253",
          "CCN": "ACCT 5300",
          "name": "Advanced Managerial Accounting ",
          "CUs": "3",
          "description": "This course introduces the complexity and functionality of managerial accounting systems within an organization. It covers the topics of product costing (including activity-based costing), decision-making (including capital budgeting), profitability analysis, budgeting, performance evaluation, and reporting related to managerial decision-making. This course provides the opportunity for a detailed study of how managerial accounting information supports the operational and strategic needs of an organization and how managers use accounting information for decision-making, planning, and controlling activities within organizations. "
        },
        "C209": {
          "courseID": "C209",
          "CCN": "MGMT 6050",
          "name": "Strategic Management ",
          "CUs": "3",
          "description": "This course focuses on models and practices of strategic management including developing and implementing both short- and long-term strategy and evaluating performance to achieve strategic goals and objectives. "
        },
        "C243": {
          "courseID": "C243",
          "CCN": "ACCT 5100",
          "name": "Advanced Financial Accounting ",
          "CUs": "3",
          "description": "This course builds upon your accounting knowledge by focusing on advanced financial accounting topics such as consolidations, partnership accounting, and international accounting. "
        },
        "D252": {
          "courseID": "D252",
          "CCN": "ACCT 6301",
          "name": "Accounting Research and Critical Thinking ",
          "CUs": "3",
          "description": "Accounting Research and Critical Thinking provides learners the skills and knowledge to research and add validity to accounting reports, resolution of issues, and procedural arguments: critical thinking, communication, research strategies, and database resources. "
        },
        "D250": {
          "courseID": "D250",
          "CCN": "ACCT 5201",
          "name": "Governmental and Nonprofit Accounting ",
          "CUs": "3",
          "description": "Governmental and Nonprofit Accounting provides learners with the skills and knowledge required to practice accounting for governmental and nonprofit entities: analyzing and recording transactions, financial statement preparation in accordance with Governmental Accounting Standards Board (GASB) standards, and communication. "
        },
        "C239": {
          "courseID": "C239",
          "CCN": "ACCT 6100",
          "name": "Advanced Tax Concepts ",
          "CUs": "3",
          "description": "This course is designed to enhance awareness of the complexities and sources of tax law and to measure and analyze the effect of various tax options. This course provides an overview of income taxes on individuals, corporations, associations, and corporate distributions, while emphasizing the role of taxes in business decisions and business strategy. Also examined will be federal tax laws applicable to individuals and corporations (and shareholders), including tax research, tax compliance, and tax planning. *Retired "
        },
        "C203": {
          "courseID": "C203",
          "CCN": "MGMT 5020",
          "name": "Becoming an Effective Leader ",
          "CUs": "3",
          "description": "This course explores major theories and approaches to leadership, leadership style evaluation, and personal leadership development while focusing on motivation, development, and achievement of others. You will learn how to influence followers, manage organizational culture, and enhance your effectiveness as a leader. "
        },
        "C205": {
          "courseID": "C205",
          "CCN": "MGMT 5030",
          "name": "Leading Teams ",
          "CUs": "3",
          "description": "This course helps students establish team objectives, align the team purpose with organizational goals, build credibility and trust, and develop the talents of individuals to enhance team performance. "
        },
        "C208": {
          "courseID": "C208",
          "CCN": "MGMT 6040",
          "name": "Change Management and Innovation ",
          "CUs": "3",
          "description": "This course provides an overview of change theories and innovation practices. This course will emphasize the role of leadership in influencing and managing change in response to challenges and opportunities facing organizations. "
        },
        "C210": {
          "courseID": "C210",
          "CCN": "MGMT 6910",
          "name": "Management and Leadership Capstone ",
          "CUs": "4",
          "description": "This course is the culminating assessment of the MSML curriculum that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of management and leadership development and practices. "
        },
        "C215": {
          "courseID": "C215",
          "CCN": "MGMT 6020",
          "name": "Operations Management ",
          "CUs": "3",
          "description": "This course focuses on the strategic importance of operations management to overall performance. This course also emphasizes principles of supply chain management relevant to a variety of business operations ranging from manufacturing goods to retail services. You will examine the various planning, control, and decision-making tools and techniques of the operations function. "
        },
        "C216": {
          "courseID": "C216",
          "CCN": "MGMT 6900",
          "name": "MBA Capstone 4 ",
          "CUs": "4",
          "description": "MBA Capstone is the culminating course in the MBA program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C715": {
          "courseID": "C715",
          "CCN": "MGMT 3000",
          "name": "Organizational Behavior ",
          "CUs": "3",
          "description": "Organizational Behavior and Leadership explores how to lead and manage effectively in diverse business environments. Students are asked to demonstrate the ability to apply organizational leadership theories and management strategies in a series of scenario-based problems. "
        },
        "D072": {
          "courseID": "D072",
          "CCN": "BUS 2010",
          "name": "Fundamentals for Success in Business ",
          "CUs": "3",
          "description": "This introductory course provides students with an overview of the field of business and a basic understanding of how management, organizational structure, communication, and leadership styles affect the business environment. It also introduces them to some of the power skills that help make successful business professionals, including time management, problem solving, emotional intelligence and innovation; while also teaching them the importance of ethics. This course gives students an opportunity to begin to explore their own strengths and passions in relation to the field while also acclimating them to the online competency-based environment. "
        },
        "D468": {
          "courseID": "D468",
          "CCN": "BUS 2780",
          "name": "Discovering Data ",
          "CUs": "3",
          "description": "Discovering Data introduces analytical concepts, processes, and tools used in the field of business analytics. This course explores the importance of asking effective questions to collect the right data. Students will examine using analytical thinking to organize, analyze, and share data to drive decision-making. This course provides students the opportunity to work toward the Google Data Analytics Professional Certificate and the Whalley Business Analysis Certificate. Introduction to Spreadsheets is a prerequisite for this course. "
        },
        "C722": {
          "courseID": "C722",
          "CCN": "MGMT 3400",
          "name": "Project Management ",
          "CUs": "3",
          "description": "Project Management prepares you to manage projects from start to finish within any organization structure. The course represents a view into different project-management methods and delves into topics such as project profiling and phases, constraints, building the project team, scheduling, and risk. You will be able to grasp the full scope of projects you may work with on in the future, and apply proper management approaches to complete a project. The course features practice in each of the project phases as you learn how to strategically apply project-management tools and techniques to help organizations achieve their goals. "
        },
        "D078": {
          "courseID": "D078",
          "CCN": "BUS 2060",
          "name": "Business Environment Applications I: Business Structures and Legal Environment ",
          "CUs": "2",
          "description": "Business Environment Applications 1 provides students with a generalist overview of the business environment and a deeper look at a number of topics that make up the non-discipline areas of business which are required for a business person to be successful within any business environment. The first part of the course focuses on knowledge about organizations and how people operate within organizations, including the areas of organizational theory, structure, and effectiveness. The course then looks at business from a legal perspective with an overview of the legal environment of business. The course will prepare the student to consider specific legal situations and to make legal and ethical decisions related to those situations. "
        },
        "D467": {
          "courseID": "D467",
          "CCN": "BUS 2770",
          "name": "Exploring Data ",
          "CUs": "3",
          "description": "Exploring Data builds proficiency with data, including the organization, preparation, transformation, cleaning, and verification of data. This course examines how to apply critical thinking, spreadsheet, and structured query language techniques to data management and decision-making. Students may simultaneously work toward the Google Data Analytics Professional Certificate and the Whalley Business Analysis Certificate. The course D468: Discovering Data, which also includes Google certificate materials, is a prerequisite. "
        },
        "D082": {
          "courseID": "D082",
          "CCN": "BUS 2090",
          "name": "Emotional and Cultural Intelligence ",
          "CUs": "3",
          "description": "Emotional and Cultural Intelligence focuses on key personal awareness skills that businesses request when hiring personnel. Key among those abilities is communication. Students will increase their skills in written, verbal, and nonverbal communication skills. The course then looks at three areas of personal awareness including emotional intelligence (EI), cultural awareness, and ethical selfawareness – building on previously acquired competencies and adding new ones. This course helps start students on a road of self-discovery, cultivating awareness to improve both as a business professional and personally. "
        },
        "D466": {
          "courseID": "D466",
          "CCN": "BUS 2760",
          "name": "Analyzing and Visualizing Data ",
          "CUs": "3",
          "description": "Analyzing and Visualizing Data examines the principles and techniques used to effectively analyze data to answer questions and share data through the art of visualization. This course explores how to use formulas and functions to perform calculations, how to design and create visualizations and dashboards in Tableau, and how to build an effective data presentation that considers limitations associated with the data and best practices for audience considerations. This course provides students with an opportunity to demonstrate an understanding of what is involved in the conversion and formatting of data and apply the use of functions and syntax to create SQL queries for combining data from multiple database tables. The course D467: Exploring Data, which also includes Google certificate materials, is a prerequisite. "
        },
        "C273": {
          "courseID": "C273",
          "CCN": "SOCG 1010",
          "name": "Introduction to Sociology ",
          "CUs": "3",
          "description": "This course teaches students to think like sociologists, or, in other words, to see and understand the hidden rules, or norms, by which people live, and how they free or restrain behavior. Students will learn about socializing institutions, such as schools and families, as well as workplace organizations and governments. Participants will also learn how people deviate from the rules by challenging norms and how such behavior may result in social change, either on a large scale or within small groups. "
        },
        "D196": {
          "courseID": "D196",
          "CCN": "ACCT 2020",
          "name": "Principles of Financial and Managerial Accounting ",
          "CUs": "3",
          "description": "Principles of Financial and Managerial Accounting provides students with an introduction to the discipline of accounting and its context within the business environment. In this course, students will learn to differentiate between financial, cost, and managerial accounting and where these accounting types fit into the business environment. This course will help students gain a fundamental knowledge of the budgeting process, how to analyze basic financial statements, and how to use spreadsheets to analyze data. This course provides students with a business generalist overview of the field of accounting and acts as a preview course for the accounting major. "
        },
        "D465": {
          "courseID": "D465",
          "CCN": "BUS 2750",
          "name": "Data Applications ",
          "CUs": "3",
          "description": "Data Applications examines the principles and techniques used to effectively analyze data to answer questions and provides foundational knowledge of R and RStudio environments. This course teaches students how to aggregate; how to format and adjust data using spreadsheets and SQL; how to use formulas and functions to perform calculations in SQL; how to organize, transform, clean, and analyze data in R; and how to create visualizations in R Markdown. Upon completion of this course, students will earn the Whalley Business Analysis Professional certificate and may also choose to earn the Google Data Analytics Professional certificate. "
        },
        "D089": {
          "courseID": "D089",
          "CCN": "ECON 2000",
          "name": "Principles of Economics ",
          "CUs": "3",
          "description": "Principles of Economics provides students with the knowledge they need to be successful managers, including basic economic theories related to markets and how markets function. This course starts by defining economics, differentiating between microeconomics and macroeconomics, and explaining the fundamental economic principles of each. It then looks at microeconomics and how it is used to make business and public policy decisions, including the principles of supply, demand, and elasticity, market efficiency, cost of production, and different market structures. The course finishes by looking at macroeconomics and how it is used to make business and public policy decisions, including measurement of macroeconomic variables, aggregate supply and demand, the concepts of an open economy, and how trade policies influence domestic and international markets. "
        },
        "C717": {
          "courseID": "C717",
          "CCN": "BUS 3000",
          "name": "Business Ethics ",
          "CUs": "3",
          "description": "Business Ethics is designed to enable students to identify the ethical and socially responsible courses of action available through the exploration of various scenarios in business. Students will also learn to develop appropriate ethics guidelines for a business. This course has no prerequisites. "
        },
        "D464": {
          "courseID": "D464",
          "CCN": "BUS 2740",
          "name": "Managing Operations ",
          "CUs": "3",
          "description": "Managing Operations examines management systems and processes to improve operating efficiency. In this course, students will be introduced to operations management concepts and will learn how analysis of these systems and processes can improve operating efficiency across the organization. Students will explore ethical and sustainable operations management strategies and will analyze operating processes for continuous improvement. "
        },
        "D076": {
          "courseID": "D076",
          "CCN": "BUS 2040",
          "name": "Finance Skills for Managers ",
          "CUs": "3",
          "description": "This course provides students with an introductory look at the discipline of finance and its context within the business environment. Students gain the knowledge to differentiate between personal and business finance and how they may overlap in a business environment. Students also gain a fundamental knowledge of financial forecasting and budgeting, statement analysis, and decision making. This course provides the student a business generalist overview of the field of finance and builds on previous acquired competencies related to using spreadsheets. "
        },
        "D253": {
          "courseID": "D253",
          "CCN": "MGMT 2700",
          "name": "Values-Based Leadership ",
          "CUs": "3",
          "description": "Values-Based Leadership guides students to learn by reflection, design, and scenario planning. Through a combination of theory, reflection, value alignment, and practice, the course helps students examine and understand values-based leadership and explore foundations in creating a culture of care. In this course, students are given the opportunity to identify and define their personal values through an assessment and reflection process. Students then evaluate business cases to practice mapping the influence of values on their own leadership. In this course, students also participate in scenario planning, where they can practice implementing their values in their daily routine (i.e., behaviors) and then in a leadership setting. The course illustrates how values-driven leadership is used in goal setting as well as problem-solving at an organizational level. There are no prerequisites for this course. "
        },
        "D080": {
          "courseID": "D080",
          "CCN": "BUS 2070",
          "name": "Managing in a Global Business Environment ",
          "CUs": "3",
          "description": "Managing in a Global Business Environment provides students with a generalist overview of business from a global perspective, while also developing basic skills and knowledge to help them make strategic decisions, communicate, and develop personal relationships in a global environment. Business today is by its very nature a global environment, and individuals working in business will experience the global nature of business as they progress through their careers. This course builds on previously acquired competencies by providing an overview of U.S. federal laws in relation to doing business in a global environment. "
        },
        "D470": {
          "courseID": "D470",
          "CCN": "BUS 3900",
          "name": "Transportation, Logistics, and Distribution ",
          "CUs": "3",
          "description": "Transportation, Logistics, and Distribution examines logistics and the planning and management of transportation and distribution. This course introduces requirements and risks, facilities and inventory, strategy and supply chain synchronicity, efficiencies and costs, and laws and regulations related to transportation, distribution, and logistics. Students will explore warehousing and warehouse management, inventory and logistics management, distribution, and supply chain management from the lens of transportation, logistics, and distribution. This course is aligned with the Certified in Logistics, Transportation, and Distribution (CLTD) certification from the Association for Supply Chain Management. "
        },
        "D361": {
          "courseID": "D361",
          "CCN": "BUS 2111",
          "name": "Business Simulation ",
          "CUs": "4",
          "description": "This course ties together all the skills and knowledge covered in the business courses and allows the student to prove their mastery of the competencies by applying them in a simulated business environment. This course will help take the student's knowledge and skills from the theoretical to applicable. "
        },
        "D471": {
          "courseID": "D471",
          "CCN": "BUS 3910",
          "name": "Global Supply Chain Management ",
          "CUs": "3",
          "description": "Global Supply Chain Management introduces a broad range of supply chain management concepts. Students consider supply chains and supply chain management strategies, including planning and design, risk management, and global and sustainable supply chain networks and management. Students learn about managing important relationships and their interdependencies. Students also investigate how supply chain costs affect consumers, quality and continuous improvement, and the role of the sales and operations planning (S&OP) process.    Portions of this course are aligned with aspects of ASCM APICS certification programs, namely the CSCP and the CPIM. "
        },
        "D469": {
          "courseID": "D469",
          "CCN": "BUS 3890",
          "name": "Quality, Continuous Improvement, and Lean Six Sigma ",
          "CUs": "3",
          "description": "Quality, Continuous Improvement, and Lean Six Sigma examines how organizations can measure and improve the quality of products, goods, and services. Students consider different dimensions and characteristics of quality and are introduced to a variety of strategies and tools that are used to manage quality and measure performance. This course explores how to apply continuous improvement processes by analyzing the Lean Six Sigma DMAIC (define, measure, analyze, improve, and control) method. C955: Applied Probability and Statistics is a prerequisite for this course. "
        },
        "D472": {
          "courseID": "D472",
          "CCN": "BUS 4900",
          "name": "21st Century Operations and Supply Chain ",
          "CUs": "3",
          "description": "21st Century Operations and Supply Chain explores modern issues in supply chain management. Building on the supply chain landscape introduced in prior courses, this course addresses more complex supply chain and operations issues. The course examines how disruptions transform operations and the supply chain and how to use qualitative and quantitative data to evaluate solutions. "
        },
        "D473": {
          "courseID": "D473",
          "CCN": "BUS 4910",
          "name": "Solutions Design and Visualization Capstone ",
          "CUs": "3",
          "description": "Solution Design and Visualization Capstone guides learners to synthesize and apply the skills learned throughout their business analytics, operations, and supply chain education. This course gives learners the opportunity to solve challenges in procurement, sales and operations planning (S&OP) processes, distribution, logistics and transportation faced by a fictional company. In the course capstone project, learners play the role of an operations and supply chain manager or consultant, redesigning the existing supply chain to implement lean processes and using Six Sigma methodology to improve efficiency and allow the company to bring new products or services to market faster. Learners in this course address a real operations and supply chain problem and design solutions, which they communicate in a report and a presentation. At the end of the course, learners will have an authentic experience they can add to their portfolio and show employers. Working through this capstone helps learners to understand how their knowledge interacts with real situations and how roles fit within the industry. The D472: 21st Century Operations and Supply Chain course is a prerequisite. "
        },
        "D363": {
          "courseID": "D363",
          "CCN": "FINC 2000",
          "name": "Personal Finance ",
          "CUs": "3",
          "description": "Personal Finance provides learners with an introduction to the discipline of finance from the perspective of the person, or family, rather than from the viewpoint of a business. In this course, learners will gain an understanding of financial literacy concepts, including personal budgeting and how to apply financial principles to achieve personal financial goals. Learners will identify various strategies to manage risks, to enhance postretirement income, and to accumulate and transfer wealth. Topics include record keeping, credit principles, cash flow, investment philosophy, monetary asset management, housing, and estate planning. This course provides learners with a general overview of personal finance and acts as a preview course for the finance major. There are no prerequisites for this course. "
        },
        "D351": {
          "courseID": "D351",
          "CCN": "HRM 2110",
          "name": "Functions of Human Resource Management ",
          "CUs": "3",
          "description": "This course provides an introduction to the management of human resources, which is the function within an organization that focuses on recruitment, management, and direction for the people who work in the organization. Students will be introduced to topics such as strategic workforce planning and talent acquisition; compensation and benefits; training and development; employee and labor relations; and occupational health, safety, and security. "
        },
        "D081": {
          "courseID": "D081",
          "CCN": "BUS 2080",
          "name": "Innovative and Strategic Thinking ",
          "CUs": "3",
          "description": "This course covers an important part of being a business professional: the knowledge and skills used in building and implementing business strategy. The course helps students build on previously acquired competencies in the areas of management, innovative thinking, and risk management while introducing them to the concepts and theories underpinning business strategy as a general business perspective. The course will help students gain skills in analyzing different business environments and in using quantitative literacy and data analysis in business strategy development and implementation. This course helps to provide students with a generalist overview of the area of business strategy. "
        },
        "C723": {
          "courseID": "C723",
          "CCN": "BUS 3100",
          "name": "Quantitative Analysis For Business ",
          "CUs": "3",
          "description": "Quantitative Analysis for Business explores various decision-making models, including expected value models, linear programming models, and inventory models. This course helps student learn to analyze data by using a variety of analytic tools and techniques to make better business decisions. In addition, it covers developing project schedules using the Critical Path Method. Other topics include calculating and evaluating formulas, measures of uncertainty, crash costs, and visual representation of decision-making models using electronic spreadsheets and graphs. This course has no prerequisites. "
        },
        "D075": {
          "courseID": "D075",
          "CCN": "BUS 2030",
          "name": "Information Technology Management Essentials ",
          "CUs": "3",
          "description": "Information Technology Management Essentials includes topics such as information systems analysis, database resource management, spreadsheet literacy, and computer literacy concepts. This course will help students understand the importance of information technology in an organization and apply databases to solve business problems. "
        },
        "D079": {
          "courseID": "D079",
          "CCN": "BUS 2061",
          "name": "Business Environment Applications II: Process, Logistics, and Operations ",
          "CUs": "2",
          "description": "Business Environment II: Logistics, Process, and Operations provides students with a generalist overview of the business environment as they explore themes of ethics, problem-solving, and innovative thinking. This course adds to the students’ business skills and knowledge in a number of professional areas. The first part of the course uncovers a series of business processes like project and risk management. The second part gives an introductory-level look at the specialized areas of operations management, supply chains, and logistics. The course finishes with models of change management and how to use them to overcome barriers in organizations. "
        },
        "D366": {
          "courseID": "D366",
          "CCN": "FINC 3103",
          "name": "Financial Statement Analysis ",
          "CUs": "3",
          "description": "Financial Statement Analysis discusses the concepts and provides tools for financial analysts to evaluate the financial elements of the firm as well as external factors to ultimately arrive at a valuation. You will learn a process to analyze data and the concepts where you can determine the quality of that data. This process provides a structure where ratios and company results are not looked at individually but as a whole in determining the worth of an enterprise, leading to an analysis-based valuation of the firm. "
        },
        "D216": {
          "courseID": "D216",
          "CCN": "ACCT 3350",
          "name": "Business Law for Accountants ",
          "CUs": "3",
          "description": "Business Law for Accountants is designed to provide the advanced accounting student an understanding of the legal environment and issues encountered in the profession. Topics include the Uniform Commercial Code (UCC), contracts, securities regulation, Sarbanes-Oxley Act, legal entities, ethics, agency, and bankruptcy. There are no prerequisites for the course. "
        },
        "D077": {
          "courseID": "D077",
          "CCN": "BUS 2050",
          "name": "Concepts in Marketing, Sales, and Customer Contact ",
          "CUs": "3",
          "description": "Concepts in Marketing, Sales, and Customer Contact introduces students to the discipline of marketing and its role within the strategic and operational environments of a business. This course covers fundamental knowledge in the area of marketing planning, including the marketing mix, while also describing basic concepts of brand management, digital marketing, customer relationship management, and personal selling and negotiating. All of this helps students identify the role of marketing within an organization. This course provides students with a business generalist overview of the field of marketing and an exploration of the marketing major. "
        },
        "D362": {
          "courseID": "D362",
          "CCN": "FINC 3100",
          "name": "Corporate Finance ",
          "CUs": "3",
          "description": "Corporate Finance is about business structures that set the environment for the day-to-day operations of a business. This course teaches learners about the common forms of business structures, the factors that business owners consider when they choose which structure to use, and the roles of shareholders and stakeholders. This course also teaches that managing the financial function involves capitalizing the company and evaluating capital budget techniques, including those that use the time value of money. Through this course, learners will learn how to calculate the cost to finance a business using the weighted average cost of capital, how to value stocks and bonds, and how to determine the value of the firm. The prerequisites for this course are D089 Principles of Economics, D366 Financial Statement Analysis, D216 Business Law for Accountants "
        },
        "D352": {
          "courseID": "D352",
          "CCN": "HRM 3110",
          "name": "Employment and Labor Law ",
          "CUs": "3",
          "description": "Employment and Labor Law reviews the legal and regulatory framework surrounding employment, including recruitment, termination, and discrimination law. The course topics include employment-at-will, EEO, ADA, OSHA, and other laws affecting the workplace. Students will learn to analyze current trends and issues in employment law and apply this knowledge to manage risk in employment relationships effectively. Functions of Human Resources and Introduction to Human Resources are recommended prior to Employment and Labor Law. "
        },
        "C720": {
          "courseID": "C720",
          "CCN": "MGMT 4100",
          "name": "Operations and Supply Chain Management ",
          "CUs": "3",
          "description": "Operations and Supply Chain Management provides a streamlined introduction to how organizations efficiently produce goods and services, determine supply chain management strategies, and measure performance. Emphasis is placed on integrative topics essential for managers in all disciplines, such as supply chain management, product development, and capacity planning. This course will guide students in analyzing processes, managing quality for both services and products, and measuring performance while creating value along the supply chain in a global environment. Topics include forecasting, product and service design, process design and location analysis, capacity planning, management of quality and quality control, inventory management, scheduling, supply chain management, and performance measurement. "
        },
        "D364": {
          "courseID": "D364",
          "CCN": "FINC 3101",
          "name": "Financial Management I ",
          "CUs": "3",
          "description": "This course covers basic financial management principles primarily targeted to the operations part of a business. The learner gains an understanding about the basic finance organization in an enterprise in support of the company's primary goal to increase corporate value for shareholders in an ethical way. Tools a finance professional might use in managing the cash and current assets are discussed along with cash budgeting and financial strategic planning. The Dupont equation is reviewed as the basis for analyzing and improving the performance of the enterprise to improve value. The learner will acquire knowledge about how forecasting models and financial instruments are used to optimize the working capital investment portfolio. Prerequisite for Financial Management I is Corporate Finance. "
        },
        "D365": {
          "courseID": "D365",
          "CCN": "FINC 3102",
          "name": "Financial Management II ",
          "CUs": "3",
          "description": "This course covers capital budgeting and long-term funding strategies. The course will delve into more advanced financial management principles primarily targeted toward corporate investment and capital planning. This course also explores an enterprise’s capital structure and how equity and long-term debt are used to finance and sustain long-term fixed asset projects. Decision methods, such as net present value, internal rate of return, and payback period, are discussed as techniques a finance professional might use in identifying and structuring the optimal capital budget. The learner will gain an understanding about equity capital, will assess financial markets, and will examine the differences in shareholder classifications and bonds. The course will teach how the dividend policy is devised and discover how the organization uses its corporate investment strategy to increase not only shareholder value but also corporate value for the shareholder. Prerequisites for Financial Management II are D196, Principles of Financial and Managerial Accounting; D076, Finance Skills for Managers; D363, Personal Finance; D362, Corporate Finance; D364, Financial Management I; and D366, Financial Statement Analysis. "
        },
        "D368": {
          "courseID": "D368",
          "CCN": "FINC 3105",
          "name": "Enterprise Risk Management ",
          "CUs": "3",
          "description": "Enterprise Risk Management provides learners with an introduction to the discipline of risk management from the perspective of an organization rather than from the viewpoint of a person. In this course, learners will learn risk management concepts, including risk tolerance, risk appetite, and how to utilize governance and compliance resources to achieve an effective risk management strategy. Throughout this course, learners will determine various strategies to identify, assess, monitor, and control risks and other threats to an organization. Topics include approaches to risk mitigation, generally accepted frameworks and standards adopted to manage risk, current environmental, societal, and governance matters of risk interest to an organization, disaster recovery plans, and insurance products. "
        },
        "D367": {
          "courseID": "D367",
          "CCN": "FINC 3104",
          "name": "Innovation in Finance ",
          "CUs": "3",
          "description": "Innovation in Finance provides students with an introduction to the technologies and product solutions that have disrupted the financial services industry. In this course, students will learn about the emerging financial technologies contributing to the evolution of lending, payments, wealth management, financial planning, and the insurance industry. This course will examine the role financial technology (FinTech) firms serve as financial disruptors and how these organizations are developed and supported, from start-up to scale. Throughout the course, students will identify the impact emerging technologies and FinTechs have on businesses, individuals, and society as a whole. Topics include emerging technology products and services, incubators, accelerator programs, FinTech ecosystems, and technologies that enable and facilitate disruption by emerging technologies. This course provides students with a general overview of financial innovation and serves as an integral component of the finance major. D076: Principles of Finance is a prerequisite for this course. "
        },
        "D369": {
          "courseID": "D369",
          "CCN": "FINC 3106",
          "name": "Finance Capstone ",
          "CUs": "3",
          "description": "This course is designed as a synthesis of the knowledge learners have acquired throughout the program. The course culminates in a performance assessment that requires learners to apply the competencies gained throughout the finance program. In this course, learners will draw upon the concepts and techniques introduced in the undergraduate finance program to perform a comprehensive financial analysis of an enterprise. In completing the course, learners will perform analyses with spreadsheet software to simulate a real-world experience of a finance career professional. "
        },
        "C430": {
          "courseID": "C430",
          "CCN": "HCM 3310",
          "name": "Healthcare Quality Improvement and Risk Management ",
          "CUs": "3",
          "description": "This course emphasizes principles of quality management and risk management in order to ensure safety, maximize patient outcomes, and continuously improve organizational outcomes. This course also examines the broader impact of organizational culture and its influence on productivity, quality, and risk. "
        },
        "C168": {
          "courseID": "C168",
          "CCN": "PHIL 3010",
          "name": "Critical Thinking and Logic ",
          "CUs": "3",
          "description": "Reasoning and Problem Solving helps candidates internalize a systematic process for exploring issues that takes them beyond an unexamined point of view and encourages them to become more self-aware thinkers by applying principles of problem identification and clarification, planning and information gathering, identifying assumptions and values, analyzing and interpreting information and data, reaching well-founded conclusions, and identifying the role of critical thinking in disciplines and professions. "
        },
        "C425": {
          "courseID": "C425",
          "CCN": "HCM 2110",
          "name": "Healthcare Delivery Systems, Regulation, and Compliance ",
          "CUs": "3",
          "description": "This course provides an overview of the U.S. healthcare system and focuses on developing an understanding of the various sectors and roles involved in this complex industry. Policy and compliance issues are also addressed to facilitate an appreciation for the highly regulated nature of healthcare delivery. "
        },
        "C426": {
          "courseID": "C426",
          "CCN": "HCM 2210",
          "name": "Healthcare Values and Ethics ",
          "CUs": "3",
          "description": "This course explores ethical standards and considerations common to the healthcare environment such as access to care, confidentiality, the allocation of limited resources, and billing practices. This course also focuses on the distinct value system associated with the healthcare industry, as well as the values of professionalism. "
        },
        "C427": {
          "courseID": "C427",
          "CCN": "HCM 2310",
          "name": "Technology Applications in Healthcare ",
          "CUs": "3",
          "description": "This course explores how technology continues to change and influence the healthcare industry. Practical managerial applications are explored as well as the legal, ethical, and practical aspects of access to health and disease information. Ensuring the protection of private health information is also emphasized. "
        },
        "D100": {
          "courseID": "D100",
          "CCN": "BUS 2140",
          "name": "Introduction to Spreadsheets ",
          "CUs": "1",
          "description": "The Introduction to Spreadsheets course will help students become proficient in using spreadsheets to analyze business problems. Students will demonstrate competency in spreadsheet development and analysis for business applications (e.g., using essential spreadsheet functions, formulas, tables, charts, etc.). Introduction to Spreadsheets has no prerequisites. "
        },
        "C428": {
          "courseID": "C428",
          "CCN": "HCM 3110",
          "name": "Financial Resource Management in Healthcare ",
          "CUs": "3",
          "description": "Financial Resource Management in Healthcare   This course examines the financial environment of the healthcare industry including principles involved in managed care. It also explores the revenue and expense structures for different sectors within the industry while emphasizing funding and reimbursement practices of healthcare. "
        },
        "C716": {
          "courseID": "C716",
          "CCN": "BUS 2600",
          "name": "Business Communication ",
          "CUs": "3",
          "description": "Business Communication is a survey course of communication skills needed in the business environment. Course content includes writing messages, reports, and résumés and delivering oral presentations. The course emphasizes communication processes, writing skills, message types, and presentation of data. The development of these skills is integrated with the use of technology. "
        },
        "C429": {
          "courseID": "C429",
          "CCN": "HCM 3210",
          "name": "Healthcare Operations Management ",
          "CUs": "3",
          "description": "This course builds upon basic principles of management, organizational behavior, and leadership. Specific processes and business principles for managing operations in interdependent and multi-disciplinary healthcare organizations are explored. Marketing strategies, communication skills, and the ability to establish and maintain relationships while ensuring productivity that is efficient, safe, and meets the needs of all stakeholders is emphasized. "
        },
        "C431": {
          "courseID": "C431",
          "CCN": "HCM 3410",
          "name": "Healthcare Research and Statistics ",
          "CUs": "3",
          "description": "This course builds upon an understanding of research methods and quantitative analysis. Concepts of population health, epidemiology, and evidence-based practices provide the foundation for understanding the importance of data for informing healthcare organizational decisions. "
        },
        "C236": {
          "courseID": "C236",
          "CCN": "HRM 3600",
          "name": "Compensation and Benefits ",
          "CUs": "3",
          "description": "Compensation and Benefits develops competence in the design and implementation of compensation and benefits systems in an organization. The total rewards perspective integrates tangible rewards (e.g., salary, bonuses) with employee benefits (e.g., health insurance, retirement plan) and intangible rewards (e.g., location, work environment). This perspective allows students to use all forms of rewards fairly and effectively to enable job satisfaction and organizational performance. There are no prerequisites. "
        },
        "C232": {
          "courseID": "C232",
          "CCN": "HRM 2100",
          "name": "Introduction to Human Resource Management ",
          "CUs": "3",
          "description": "This course provides an introduction to the management of human resources, the function within an organization that focuses on recruitment, management, and direction for the people who work in the organization. Students will be introduced to topics such as strategic workforce planning and employment; compensation and benefits; training and development; employee and labor relations; and occupational health, safety, and security. "
        },
        "C432": {
          "courseID": "C432",
          "CCN": "HCM 3510",
          "name": "Healthcare Management and Strategy ",
          "CUs": "3",
          "description": "This course builds upon basic principles of strategic management and explores healthcare organizational structures and processes. The importance of the collaborative nature and interrelationships among business functions is emphasized. Creating a healthcare vision and designing business plans within a healthcare environment is also examined. "
        },
        "C439": {
          "courseID": "C439",
          "CCN": "HCM 2910",
          "name": "Healthcare Management Capstone ",
          "CUs": "4",
          "description": "This course is the culminating experience and assessment of healthcare business administration. This course requires the student to integrate and synthesize managerial skills with healthcare knowledge, resulting in a high quality final project that demonstrates professional managerial proficiency. "
        },
        "D102": {
          "courseID": "D102",
          "CCN": "ACCT 2313",
          "name": "Financial Accounting ",
          "CUs": "3",
          "description": "Financial Accounting focuses on ways in which accounting principles are used in business operations. Students learn the basics of financial accounting, including how the accounting cycle is used to record business transactions under generally accepted accounting principles (GAAP). Students will also be introduced to the concepts of assets, liabilities, and equity. This course also presents bank reconciliation methods, balance sheets, and business ethics. Principles of Accounting is a prerequisite for this course. "
        },
        "C237": {
          "courseID": "C237",
          "CCN": "ACCT 3630",
          "name": "Taxation I ",
          "CUs": "3",
          "description": "This course focuses on the taxation of individuals. It provides an overview of income taxes of both individuals and business entities in order to enhance awareness of the complexities and sources of tax law and to measure and analyze the effect of various tax options. The course will introduce taxation of sole proprietorships. Students will learn principles of individual taxation and how to develop effective personal tax strategies for individuals. Students will also be introduced to tax research of complex taxation issues. "
        },
        "D101": {
          "courseID": "D101",
          "CCN": "ACCT 3314",
          "name": "Cost and Managerial Accounting ",
          "CUs": "3",
          "description": "Cost and Managerial Accounting focuses on the concepts and procedures needed to identify, collect, and interpret accounting data for management control and decision-making. Topics covered include budgeting, cost-volume-profit analysis, job costing, process costing, activity-based costing, standard costing, and differential analysis. Prerequisites include Principles of Accounting and Financial Accounting. "
        },
        "D103": {
          "courseID": "D103",
          "CCN": "ACCT 3611",
          "name": "Intermediate Accounting I ",
          "CUs": "3",
          "description": "Intermediate Accounting I is the first of three in-depth financial accounting courses for accounting majors. The course builds upon topics covered in Principles of Accounting and Financial Accounting. The course focuses on financial accounting and accounting standards; the conceptual framework of the U.S. generally accepted accounting principles (GAAP); the income statement, the statement of cash flows, and the balance sheet; cash and receivables; and inventory valuation. The prerequisite to this course is Financial Accounting. "
        },
        "C233": {
          "courseID": "C233",
          "CCN": "HRM 3100",
          "name": "Employment Law ",
          "CUs": "3",
          "description": "This course reviews the legal and regulatory framework surrounding employment, including recruitment, termination, and discrimination law. The course topics include employment-at-will, EEO, ADA, OSHA, and other laws affecting the workplace. This course covers how to analyze current trends and issues in employment law and apply this knowledge to manage risk effectively in the employment relationship. "
        },
        "D104": {
          "courseID": "D104",
          "CCN": "ACCT 3621",
          "name": "Intermediate Accounting II ",
          "CUs": "3",
          "description": "Intermediate Accounting II is the second of three in-depth financial accounting courses for accounting majors. The course focuses on acquisition and disposition of noncurrent assets; depreciation, impairments, and depletion; intangible assets; current liabilities and contingencies; long-term obligations; stockholders' equity; dilutive securities; and time value of money concepts. The prerequisite to this course is Intermediate Accounting I. "
        },
        "D217": {
          "courseID": "D217",
          "CCN": "ACCT 3360",
          "name": "Accounting Information Systems ",
          "CUs": "3",
          "description": "Accounting Information Systems (AIS for short) introduces students to AIS, with particular emphasis on the accountant’s role in management and financial reporting systems. Topics include transaction cycles and related information technology (IT) controls, data management, enterprise resource planning (ERP) and e-commerce systems, systems development and acquisition, documentation, and IT auditing. D103 Intermediate Accounting I and D104 Intermediate Accounting II are the prerequisites to this course. "
        },
        "D105": {
          "courseID": "D105",
          "CCN": "ACCT 3650",
          "name": "Intermediate Accounting III ",
          "CUs": "3",
          "description": "Intermediate Accounting III provides comprehensive coverage of investments, revenue recognition, accounting for income taxes, pension plans, and leases. This course completes the intermediate accounting journey. The course explores further advanced topics, including accounting changes and error analysis, full disclosure requirements in financial reporting, and interpretation of the statement of cash flows. Intermediate Accounting I and II are the prerequisites for this course. "
        },
        "D215": {
          "courseID": "D215",
          "CCN": "ACCT 3340",
          "name": "Auditing ",
          "CUs": "3",
          "description": "Auditing covers the entire auditing process. This course will help students gain an understanding of the different assurance services, the AICPA Code of Professional Conduct, and the conceptual framework for members in public practice. The course will teach students how to assess for audit risk, develop an audit strategy, and gain an understanding of the audit client. Audit evidence and a client’s system of internal control will be discussed in depth. The course requires students to assess risk response by identifying and evaluating tests of controls and substantive procedures. In addition, the course will have students evaluate risk response using data analytics and audit sampling for substantive tests. The course concludes with the completion of the audit through subsequent events, engagement wrap-up and management representation, and reporting on the audit with an unqualified audit report or a modification of the audit report. The prerequisites to this course are Intermediate Accounting I, II, and III, Accounting Information Systems, and Business Law for Accountants. "
        },
        "VZT1": {
          "courseID": "VZT1",
          "CCN": "BUSI 3731",
          "name": "Marketing Applications ",
          "CUs": "3",
          "description": "Marketing Applications allows students to apply their knowledge of core marketing principles by creating a comprehensive marketing plan. The plan will apply knowledge of the marketing planning process, market analysis, and the marketing mix (product, place, promotion, and price).  "
        },
        "D174": {
          "courseID": "D174",
          "CCN": "MKTG 2150",
          "name": "Marketing Management ",
          "CUs": "3",
          "description": "Marketing Management examines foundational marketing concepts. Marketing is ever-present in our daily lives and this course will help students understand how organizations use marketing activities to create value for their customers. Students will study the strategic marketing planning process and the marketing mix of product, price, place, and promotion. Students will gain knowledge about the market research process and how data are used to inform marketing decisions. Emphasis will be placed on ethical and sustainable marketing practices, along with a focus on service marketing in today’s service economy. This course will provide students with a basic marketing understanding to prepare them for specialized major courses. "
        },
        "D175": {
          "courseID": "D175",
          "CCN": "MKTG 3850",
          "name": "Consumer Behavior ",
          "CUs": "3",
          "description": "Consumer Behavior examines the buying behavior of consumers in the marketplace. Students will gain knowledge of consumer behavior theories and an understanding of how consumer behavior concepts apply to the consumer decision-making process. Students will learn how consumer insights are gained through the exploration of external social and cultural influences such as reference groups, family, and culture, as well as consumer influences such as needs, motivation, personality, and learning. The course also provides an interdisciplinary perspective, including psychology, sociology, anthropology, and economics, to better evaluate and predict consumer behavior. "
        },
        "QHT1": {
          "courseID": "QHT1",
          "CCN": "BUS 4400",
          "name": "Business Management Tasks ",
          "CUs": "3",
          "description": "Business Management Tasks addresses important concepts needed to effectively manage a business. Topics include understanding the cost-quality relationship, using various types of graphical charts in operations management, managing innovation, and developing strategies for working with individuals and groups. "
        },
        "D098": {
          "courseID": "D098",
          "CCN": "BUS 3120",
          "name": "Digital Marketing ",
          "CUs": "3",
          "description": "This course provides students with a knowledge of digital marketing and an introduction to specializations within digital marketing. Foundational knowledge in the areas of content marketing, digital advertising, search engine optimization, social media, web development  and analysis, and marketing automation is provided. Students gain a broad overview of digital marketing and an opportunity to explore specific areas of specialization within the field of digital marketing to understand how digital marketing is integrated within a firm’s overall marketing strategy. "
        },
        "D176": {
          "courseID": "D176",
          "CCN": "MKTG 3860",
          "name": "Content Marketing ",
          "CUs": "3",
          "description": "Content Marketing examines how organizations create and distribute marketing communications to attract and retain customers. Students will gain knowledge of the content planning process and how content marketing supports brand and organizational goals by learning how to create, distribute, promote, and measure relevant and valuable content. Students will learn content ideation and will write compelling copy that creates relationships with customers to build trust and enhance an organization’s reputation and authority. "
        },
        "D177": {
          "courseID": "D177",
          "CCN": "MKTG 3870",
          "name": "Brand Management ",
          "CUs": "3",
          "description": "Brand Management examines how brands provide value to both consumers and organizations. Brands are a part of a consumer’s everyday life and organization’s strategically plan, measure, and manage brands. In this course, students will apply the strategic brand management process using a customer-based brand equity model. Students will identify how brand strategies are used and how brand associations are leveraged to create a competitive advantage. Brand equity measurement systems are explored, including brand audits and tracking studies that use qualitative and quantitative brand research techniques. Students will construct a brand architecture strategy by identifying brand extension opportunities to develop an appropriate branding strategy in a global marketplace. Reputation-management strategies and crisis management techniques are also taught to assist in preserving and protecting an organization’s brand equity. "
        },
        "D266": {
          "courseID": "D266",
          "CCN": "HIST 1016",
          "name": "World History: Diverse Cultures and Global Connections ",
          "CUs": "3",
          "description": "This is World History: Diverse Cultures and Global Connections. In this course, you will focus on three main topics—cultural and religious diversity; pandemics; and the relationship of empires and nation states—as well as the skills of identifying root causes, explaining causes and effects, and analyzing complex systems. This course consists of an introduction and four major sections. Each section includes learning opportunities through reading, images, videos, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check how well you understand the content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content. "
        },
        "D099": {
          "courseID": "D099",
          "CCN": "BUS 3130",
          "name": "Sales Management ",
          "CUs": "3",
          "description": "This course provides students with knowledge on the sales profession, customer relationship management, and sales management functions. Students gain insights into the sales process, the relationship between sales and marketing, and the responsibilities of sales management within both business-to-consumer (B2C) and business-to-business (B2B) selling environments. "
        },
        "D178": {
          "courseID": "D178",
          "CCN": "BUS 3880",
          "name": "Marketing Strategy and Analytics ",
          "CUs": "3",
          "description": "Marketing Strategy and Analytics is the capstone course for the marketing major. The course provides students with the opportunity to demonstrate competencies developed throughout the program by engaging in the design, implementation, and analysis of a marketing strategy. Students are given business scenarios using simulations and case studies to apply critical-thinking and decisionmaking skills. Students will analyze the business environment and make decisions about market segmentation, buyer behavior, and the marketing mix. Students will demonstrate the relationship between strategy and analytics by using marketing analytics to report marketing campaign results and make recommendations. This course provides students with real-world application to prepare them for the marketing industry. "
        },
        "D354": {
          "courseID": "D354",
          "CCN": "HRM 3520",
          "name": "Talent Acquisition ",
          "CUs": "3",
          "description": "Talent Acquisition focuses on building a highly skilled workforce that meets organizational staffing needs by using effective strategies and tactics for recruiting, selecting, and onboarding employees. The learner will develop competency in critical skills related to talent acquisition, such as workforce planning, developing strategic recruiting plans, and ensuring effective selection strategies. Talent acquisition is a top skill for HR professionals, and successful talent acquisition practices lend to individual, team, and organizational success. "
        },
        "D355": {
          "courseID": "D355",
          "CCN": "HRM 3530",
          "name": "Total Rewards ",
          "CUs": "3",
          "description": "This course develops competence in the design and implementation of total rewards approaches in an organization. The total rewards perspective integrates tangible rewards (e.g., salary, bonuses) with employee benefits (e.g., health insurance, retirement plan) and intangible rewards (e.g., location, work environment). This perspective allows learners to use all forms of rewards fairly and effectively to enable job satisfaction and organizational performance. "
        },
        "D353": {
          "courseID": "D353",
          "CCN": "HRM 3510",
          "name": "Strategic Training and Development ",
          "CUs": "3",
          "description": "Strategic Training and Development focuses on the development of human capital (i.e., growing talent) by applying effective learning theories and practices for training and developing employees. The course will help develop essential skills for improving and empowering organizations through high-caliber training and development processes. "
        },
        "D356": {
          "courseID": "D356",
          "CCN": "HRM 3540",
          "name": "HR Technology ",
          "CUs": "3",
          "description": "HR Technology focuses on the usage of technology for strategic human resource management. The learner will develop competency in critical skills related to analyzing the value and application of the different types of human resource information systems (HRIS), managing HRIS implementations, electronic human resource management, and future trends and application of HR technology. HR professionals must be familiar with HR technology in order to provide effective and efficient HR practices for their organization and recommendations to leadership to invest in technology. An understanding of HR technology is also an in-demand skill for HR professionals across all industries. "
        },
        "D357": {
          "courseID": "D357",
          "CCN": "HRM 3550",
          "name": "Diversity, Equity, and Inclusion ",
          "CUs": "3",
          "description": "Diversity, Equity, and Inclusion examines the importance and impact of diversity in organizations through an understanding of the theoretical, background, and legislative foundations of diversity. This course will explore specific groups and categories of diversity, as well as global diversity and career paths in diversity. "
        },
        "D358": {
          "courseID": "D358",
          "CCN": "HRM 3560",
          "name": "Global Human Resource Management ",
          "CUs": "3",
          "description": "Global Human Resource Management explores the rapidly changing field of international human resource management (HRM) and examines a global perspective in relation to staffing, personnel management, strategy, and communications in a cross-cultural context. This course will help learners examine critical skills such as application of international employment law, labor standards, and ethics, as well as international application of human resources (HR) best practices in areas such as employee relations, global talent management, and future trends of international HRM. These skills lend to the success of HR professionals working to support organizations that operate in or within an international context and cross-culturally, as well as expanding the skillsets for those HR professionals interested in seeking a career as a global HR professional. There are no prerequisites for this course. "
        },
        "D359": {
          "courseID": "D359",
          "CCN": "HRM 3570",
          "name": "Agile HR ",
          "CUs": "3",
          "description": "Agile HR explores the concepts of Agile operations and Agile project management from the human resource management perspective. Learners will focus on design thinking, building value for employees, change management, adaptability, and strategic prioritization as part of the Agile skills in this course. Adaptability and resilience, while delivering value in a constantly changing world, are all critical skills for successful HR professionals and leaders. "
        },
        "D360": {
          "courseID": "D360",
          "CCN": "HRM 3100",
          "name": "HRM Capstone ",
          "CUs": "3",
          "description": "The learner synthesizes skills from across the human resource management (HRM) industry to demonstrate the ability to participate in and contribute value to the HR field. "
        },
        "AFT2": {
          "courseID": "AFT2",
          "CCN": "HCM 5000",
          "name": "Accreditation Audit ",
          "CUs": "4",
          "description": "Accreditation Audit covers regulatory audits, resource assessment, quality improvement, patient care improvement, organization plans, risk management, effective interaction, and compliance as evidenced during an accreditation audit. "
        },
        "AMT2": {
          "courseID": "AMT2",
          "CCN": "HCM 6000",
          "name": "Service Line Development ",
          "CUs": "4",
          "description": "Service Line Development will address how to critically assess the competitive marketplace as well as the internal environment to establish a new line of business. Topics include needs assessment, international healthcare trends, service line management, revenue analysis, costs and productivity, communication, negotiation, health policy, health legislation, and facilities management, which are variables in the evaluation process. "
        },
        "C219": {
          "courseID": "C219",
          "CCN": "HCM 6900",
          "name": "MBA, Healthcare Management Capstone ",
          "CUs": "4",
          "description": "MBA Healthcare Management Capstone is the culminating course in the MBA HCM program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C494": {
          "courseID": "C494",
          "CCN": "NURS 2000",
          "name": "Advanced Standing for RN License ",
          "CUs": "50",
          "description": "Advanced Standing for RN License "
        },
        "D235": {
          "courseID": "D235",
          "CCN": "NURS 3114",
          "name": "Interprofessional Communication and Leadership in Healthcare ",
          "CUs": "2",
          "description": "Interprofessional Communication and Leadership in Healthcare is designed to help students prepare for success in the online environment at Whalley University and beyond. Student success starts with the social support and self-reflective awareness that will prepare them to handle the challenges of all academic programs. In this course, students will complete several individual assignments that are intended to give the student an opportunity to reflect on where they are and where they would like to be. The activities in the course are designed to give students several tools they can use to achieve success. This course is designed as a four-part intensive learning experience. Students will engage in activities that will help them understand their own educational journey and find support and inspiration in the journey of others. There are no prerequisites for this course. "
        },
        "D312": {
          "courseID": "D312",
          "CCN": "SCIE 1011",
          "name": "Anatomy and Physiology I with Lab ",
          "CUs": "4",
          "description": "This is Anatomy and Physiology I, a six-section, 4 CU course that enables students to develop an understanding of the relationships between the structures and function of the integumentary, skeletal, muscular, nervous and endocrine systems in the human body. This course will involve laboratory activities, simulated dissections, textbook material, models, and diagrams.    Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 40–60 hours on the course content. "
        },
        "D313": {
          "courseID": "D313",
          "CCN": "SCIE 1012",
          "name": "Anatomy and Physiology II with Lab ",
          "CUs": "4",
          "description": "This is Anatomy and Physiology II, a six section, four CEU course that enables students to develop an understanding of the relationships between the structures and functions of the cardiovascular, respiratory, digestive, urinary, reproductive, and lymphatic systems in the human body. This course will involve laboratory activities, simulated dissections, textbook material, models, and diagrams.   Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 40–60 hours on the course content. "
        },
        "D311": {
          "courseID": "D311",
          "CCN": "NURS 1010",
          "name": "Microbiology with Lab: A Fundamental Approach ",
          "CUs": "4",
          "description": "Microbiology with Lab: A Fundamental Approach explores the science that microorganisms are everywhere, and they have positive and negative effects on the community. The course examines the structure and function of microorganisms, disease transmission and progression, and immune responses and other interventions, and it identifies key global diseases. The course consists of an introduction and four major sections. Each section includes learning opportunities through readings, videos, and other relevant resources. Assessment activities with feedback also provide opportunities for students to check their learning, practice, and show how well they understand course content. To assist students in developing an applied, evidence-based understanding of microbiology, this course integrates several lab experiments to help determine the specific characteristic of an unknown microbial sample and a treatment plan. Because the course is self-paced, students may move through the material as quickly or as slowly as needed to gain proficiency in the four competencies that will be covered in the final assessment. Students who have no prior knowledge of or experience with this topic can expect to spend 48–60 hours on the course content. There are no prerequisites for this course. "
        },
        "D202": {
          "courseID": "D202",
          "CCN": "PSYC 1020",
          "name": "Human Growth and Development ",
          "CUs": "3",
          "description": "This is Human Growth and Development, a three-module course that examines the entire human lifetime, from conception to death. Presented chronologically, the course focuses on three key areas: physical, cognitive, and psychosocial growth, along with other important issues such as cultural influences, emotions, and resilience. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content. "
        },
        "D218": {
          "courseID": "D218",
          "CCN": "NURS 3600",
          "name": "Intrapersonal Leadership and Professional Growth ",
          "CUs": "3",
          "description": "Intrapersonal Leadership and Professional Growth fosters the development of professional identity. Building on the knowledge, skills, and attitudes gained through nursing practice, students in this course will explore the relationship of theories, professional competencies, standards of leadership, education, and professionalism. The course content will cover development of a nurse as a leader who is proficient in asserting control, influence, and power in professional and personal contexts. "
        },
        "D219": {
          "courseID": "D219",
          "CCN": "NURS 3610",
          "name": "Scholarship in Nursing Practice ",
          "CUs": "3",
          "description": "Scholarship in Nursing Practice teaches students how to design and conduct research to answer important questions about improving nursing practice and patient care delivery outcomes. This course introduces the basics of evidence-based practice, which students are expected to implement throughout their clinical experiences. Students of this course will graduate with more competence and confidence to become leaders in the healing environment. "
        },
        "D236": {
          "courseID": "D236",
          "CCN": "NURS 2508",
          "name": "Pathophysiology ",
          "CUs": "3",
          "description": "Pathophysiology is an overview of the pathology and treatment of diseases in the human body, tissues, glands and membranes, the integumentary system, the sensory system, skeletal and muscular systems, the digestive system, blood, vessels and circulation, lymphatic system, immunity and disease, heart and respiratory system, nervous, urinary and endocrine systems, and male and female reproductive systems. Prerequisites include all prior courses in this programmatic sequence. "
        },
        "D220": {
          "courseID": "D220",
          "CCN": "NURS 3620",
          "name": "Information Technology in Nursing Practice ",
          "CUs": "3",
          "description": "Information Technology in Nursing Practice provides a basic overview of information technology as it relates to the baccalaureate-prepared nurse. It is a foundational overview of nursing informatics with an emphasis on developing basic competency. This course teaches students that nursing informatics synthesizes nursing science, information science, and computer science through health applications to support decision-making in a dynamic healthcare environment. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D221": {
          "courseID": "D221",
          "CCN": "NURS 3630",
          "name": "Organizational Systems and Healthcare Transformation ",
          "CUs": "3",
          "description": "Course Description Organizational Systems and Healthcare Transformation  covers foundational knowledge, skills, and attitudes toward organizational leadership within healthcare systems that can help students be successful. This course focuses on the concepts of patient safety, improvement science, fiscal responsiveness, quality of care, value-based care, and patientcentered care. Additional topics of quality science and innovation, systems redesign, and interprofessional roles assist the student in building necessary skills for healthcare transformation. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D222": {
          "courseID": "D222",
          "CCN": "NURS 3640",
          "name": "Comprehensive Health Assessment ",
          "CUs": "3",
          "description": "Comprehensive Health Assessment builds upon students’ existing knowledge of nursing assessment. The course presents current and innovative assessment techniques of the physical, mental, emotional, and spiritual well-being of patients. Use of assessment data and shared decision-making are discussed throughout the course. This course also outlines the concepts of a head-to-toe assessment, providing students with an understanding of how to critically think about the different aspects of the assessment and analyze patient cues to determine the implications of findings. Students will also analyze lifestyle and cultural implications of health. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D223": {
          "courseID": "D223",
          "CCN": "NURS 2650",
          "name": "Healthcare Policy and Economics ",
          "CUs": "3",
          "description": "Healthcare Policy and Economics is a foundational course that introduces the concepts of value-based care and the role of the nurse. This course includes concepts related to financial responsiveness, shared decision-making, preference-sensitive care, leveraging data. In this course, students learn about cost and fee-for-service in terms of value to the client and patient rather than value to the healthcare system. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D224": {
          "courseID": "D224",
          "CCN": "NURS 3660",
          "name": "Global and Population Health ",
          "CUs": "4",
          "description": "Global and Population Health prepares students for the role of the nurse in preserving and promoting health among diverse populations. Additionally, basic principles of epidemiology, social determinants of health (SDOH), and resource allocation through value-based care are outlined. The course introduces planning, organization, and delivery of services for diverse populations in community settings, including illness prevention, disaster preparedness, and environmental health. All prior courses in the sequence for this program serve as prerequisites for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D225": {
          "courseID": "D225",
          "CCN": "NURS 3670",
          "name": "Emerging Professional Practice ",
          "CUs": "3",
          "description": "Emerging Professional Practice presents a variety of professional nursing specialty areas. Students explore various practice specialties, including palliative care, genetics and genomics, and others. The course provides pathways to specialized nursing practice. All prior courses in the sequence for this program serve as prerequisites for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D226": {
          "courseID": "D226",
          "CCN": "NURS 3660",
          "name": "BSNU Capstone ",
          "CUs": "3"
        },
        "D390": {
          "courseID": "D390",
          "CCN": "HLTH 2130",
          "name": "Introduction to Health and Human Services ",
          "CUs": "3",
          "description": "Introduction to Health and Human Services explores representative roles and responsibilities of health and human service professionals and key governmental entities involved in Health and Human Services delivery. The course also examines the importance of understanding clients’ illnesses and disabilities, building trust with clients, and engagement models that promote client outcomes. There are no prerequisites for this course. "
        },
        "D391": {
          "courseID": "D391",
          "CCN": "HLTH 2012",
          "name": "Healthcare Ecosystems ",
          "CUs": "3",
          "description": "Healthcare Ecosystems examines how the aims and elements of the healthcare ecosystem can affect client and patient outcomes. The course explores the main aims of healthcare access, affordability, and quality and how regulators, providers, producers, and funders (such as payors or purchasers) support those aims. The course also examines insurance regulations and reimbursement procedures that affect healthcare access and affordability and decision-making processes that support affordable, quality care for clients and communities. There are no prerequisites for this course. "
        },
        "D458": {
          "courseID": "D458",
          "CCN": "PHIL 1031",
          "name": "Introduction to Systems Thinking for Health Professionals ",
          "CUs": "3",
          "description": "Introduction to Systems Thinking for Health Professionals provides learners with the skills required to engage in a holistic systems-based approach to analyzing complex problems and solutions. This course introduces the foundational concepts and principles of systems thinking and provides opportunities to use a systems thinking approach to analyze and evaluate realworld case studies. The course will culminate with using systems thinking to develop a solution to an authentic complex problem. This course has no prerequisites, but general education math (C955 or C957) is preferred. Because the course is self-paced, learners may move through the material as quickly or as slowly as needed, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If learners have no prior knowledge of this material, they can expect to spend 30 to 40 hours on the course content.   "
        },
        "D392": {
          "courseID": "D392",
          "CCN": "HLTH 2140",
          "name": "Interdisciplinary Team Dynamics ",
          "CUs": "3",
          "description": "Interdisciplinary Team Dynamics explores interpersonal communication strategies, collaborative team interactions methods, and problem-solving techniques to promote effective communication and improve quality client outcomes in a team environment. There are no prerequisites for this course. "
        },
        "D393": {
          "courseID": "D393",
          "CCN": "HLTH 2160",
          "name": "History of Healthcare in America ",
          "CUs": "3",
          "description": "History of Healthcare in America will examine individuals such as Henrietta Lacks, Ryan White, Clara Barton, and Katie Beckett, who influenced healthcare in the United States, from its inception to the present day. This course examines how specific individuals and their contributions influenced healthcare delivery and the continued evolution of healthcare, teaching from a system or a value-based care perspective. The course also focuses on the way healthcare interacted with culture, politics, and society throughout U.S. history and evaluates current challenges we face in the U.S. healthcare system today. There are no prerequisites for this course. "
        },
        "D394": {
          "courseID": "D394",
          "CCN": "HLTH 2220",
          "name": "Care for Individuals and Families ",
          "CUs": "3",
          "description": "Care for Individuals and Families focuses on the holistic care of individuals, families, and populations with multifaceted healthcare needs. This course improves critical thinking and interdisciplinary communication skills to provide information to individuals or groups in a variety of settings. The focus of the course is on managing the transition of an individual, family, or group through a variety of healthcare settings, which can include acute care hospitals, extended stay facilities, ambulatory care clinics, home care, outreach, or wellness. This course helps students develop effective professional communication skills and appropriate behaviors to ensure an individual, family, or group is successful in meeting its healthcare goals. There are no prerequisites for this course. "
        },
        "D395": {
          "courseID": "D395",
          "CCN": "HLTH 2230",
          "name": "Cultural Awareness ",
          "CUs": "3",
          "description": "Cultural Awareness is a course for the healthcare professional providing learners with the understanding of what it means to have personal, explicit and implicit cultural biases and how they can affect client outcomes in the health and human services industry. The course also will explore strategies for responding to personal biases and for promoting cultural awareness in health and human services. Through critical readings, videos, and activities, the learner will gain knowledge in this essential subject. There are no prerequisites for this course. "
        },
        "D396": {
          "courseID": "D396",
          "CCN": "HLTH 2320",
          "name": "Evidence-Based Practice for Health and Human Services ",
          "CUs": "3",
          "description": "Evidence-Based Practice for Health and Human Services prepares the learner to apply evidence-based practice (EBP) to inform healthcare recommendations in out-patient, organizational, and other public health settings. Learners will be introduced to an EBP framework to guide them through the steps of EBP using real world scenarios. There are no prerequisites for this course.    "
        },
        "D397": {
          "courseID": "D397",
          "CCN": "HLTH 2330",
          "name": "Health Equity and Social Determinants of Health ",
          "CUs": "3",
          "description": "Health Equity and Social Determinants of Health examines the social determinants of health (SDOH) as underlying causes of health inequity in populations and communities and their effect on health outcomes. This course will help students understand the evidence-based strategies that address the negative impact of inequities caused by the SDOH and analyze approaches to promote health equity. There are no prerequisites for this course. "
        },
        "D399": {
          "courseID": "D399",
          "CCN": "HLTH 2500",
          "name": "Introduction to Gerontology ",
          "CUs": "3",
          "description": "Introduction to Gerontology will introduce learners to health issues that are typically associated with the older adult population so they can become familiar with health challenges this population may face. The learners will gain an understanding of the effects that policy and legislation have on the older adult population. Emphasis is placed on the importance of maintaining the dignity of older adults by focusing on cultural and communication needs, and by collaborating on care with older adults, families, and caregivers. There are no prerequisites for this course. "
        },
        "D400": {
          "courseID": "D400",
          "CCN": "HLTH 3300",
          "name": "End-of-Life Care ",
          "CUs": "3",
          "description": "End-of-Life Care focuses on the Connected Care model as it applies to the final stage of life. This course will explore ethnic and cultural factors that affect an individual’s response to death and dying. This course will cover planning and implementing ideal interventions to help individuals, families, and groups cope and agree on a common care goal. This course will also discuss empathy and compassion in healthcare. There are no prerequisites for this course. "
        },
        "D401": {
          "courseID": "D401",
          "CCN": "HLTH 3310",
          "name": "Introduction to Epidemiology ",
          "CUs": "3",
          "description": "Introduction to Epidemiology provides an overview of the determinants of communicable, viral, and chronic diseases. Students also will study various other conditions and the impact to public health. Using problem-based inquiry, students will analyze realworld public health problems by examining the distribution and patterns of data, selecting the methods to gather evidence, interpreting the information, and analyzing the trends to support decision making. There are no prerequisites to this course, but students are highly encouraged to adhere to the standard path, whose content is scaffolded to enhance the learning experience of this course. "
        },
        "D402": {
          "courseID": "D402",
          "CCN": "HLTH 3320",
          "name": "Community and Public Health ",
          "CUs": "4",
          "description": "Community and Public Health provides learners with an understanding of the benefits community health offers individuals and families. The course also will identify barriers that will impact health and healthcare access, leading to improved community health. There are no prerequisites for this course. "
        },
        "D403": {
          "courseID": "D403",
          "CCN": "HLTH 3330",
          "name": "Understanding Substance Abuse and Addiction ",
          "CUs": "3",
          "description": "Understanding Substance Abuse and Addiction provides an overview of the causes, signs and symptoms of substance abuse and addiction, and the impact on individuals, groups, and the community. Learners will evaluate educational prevention programs for a variety of target audiences and settings and evaluate evidence-based assessments and interventions for successful outcomes. There are no prerequisites for this course. "
        },
        "D404": {
          "courseID": "D404",
          "CCN": "HLTH 3340",
          "name": "Healthcare Values and Ethics ",
          "CUs": "3",
          "description": "Healthcare Values and Ethics requires students to synthesize an interdisciplinary approach to decisionmaking as it applies to health and human services. This course explores the contemporary issues facing health professionals, which include ethics, regulations and compliance, and handling protected healthcare information. In this course, learners will develop their ability to make ethical decisions in collaborative care environments and working within a team. There are no prerequisites for this course. "
        },
        "D405": {
          "courseID": "D405",
          "CCN": "HLTH 4416",
          "name": "Financial Resource Management ",
          "CUs": "and",
          "description": "Financial Resource Management and Healthcare Reimbursement examines financial practices and reimbursement types within the healthcare industry. This course covers the analysis of regulations required for health reimbursements. This course also covers the evaluation of effective revenue cycle management, focusing on the organization’s financial stability. There are no prerequisites for this course. "
        },
        "D406": {
          "courseID": "D406",
          "CCN": "HLTH 3350",
          "name": "Health Literacy for the Client and Family ",
          "CUs": "3",
          "description": "Health Literacy for the Client and Family helps students recognize the importance of health literacy in overcoming healthcare barriers and creating patient-focused changes through family and patient empowerment. This course demonstrates how education, research, and technology all integrate and serve as a foundation for students as they create effective resources to improve health literacy for patients and families. This course helps students become advocates for their patients and their patients’ families. There are no prerequisites for this course. "
        },
        "D407": {
          "courseID": "D407",
          "CCN": "HLTH 3420",
          "name": "Models of Care and Healthcare Trends ",
          "CUs": "3",
          "description": "Models of Care and Healthcare Trends is a course for health professionals in a variety of roles in the health and human services industry, which examines the unique characteristics of healthcare models in the United States and emerging trends created by social and political drivers. The course explores the evolution of healthcare models from fragmented systems to cohesive, quality-centric, and client-focused systems. The course also focuses on innovative trends, such as access to care, telemedicine, and subsequent shifts in the continuum of care as it relates to patient or client outcomes. There are no prerequisites for this course. "
        },
        "D408": {
          "courseID": "D408",
          "CCN": "HLTH 4430",
          "name": "Community Relations and Leadership ",
          "CUs": "3",
          "description": "Community Relations and Leadership focuses on analyzing community health and human services’ needs to create change. As emerging leaders, students will learn to engage in collaborative approaches with various stakeholders to achieve positive outcomes. This course helps students develop their abilities to interpret community health needs assessments, make decisions, and bring stakeholders together to advance access to health and human services. This course has no prerequisites. "
        },
        "D409": {
          "courseID": "D409",
          "CCN": "HLTH 4920",
          "name": "Health and Human Services Professional Field Experience ",
          "CUs": "3",
          "description": "The Health and Human Services Professional Field Experience course provides students with real-world experiences as a health services professional via the virtual world of simulation. The course allows students to conduct their field experience in a variety of different contexts they will find themselves, depending on their professional career choices in the health services’ industry. All program coursework leads to this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D410": {
          "courseID": "D410",
          "CCN": "HLTH 4921",
          "name": "Health & Human Services Professional Capstone ",
          "CUs": "3",
          "description": "Health Services Professional Capstone will provide learners the opportunity to demonstrate their ability to communicate in a professional manner that supports high quality, safe client services. Learners will also engage in career and professional development within the health and human services industry. All program coursework leads to this course. "
        },
        "D024": {
          "courseID": "D024",
          "CCN": "NURS 5201",
          "name": "Professional Presence and Influence ",
          "CUs": "2",
          "description": "Professional Presence and Influence is a masters-level course designed to guide students towards an enhanced state of presence, where therapeutic relationships are built between nurse and patient. Students will learn techniques for self-care practices that result in enhanced mental and physical wellbeing and that ensure ethically-generated patient care. Presence is an intrapersonal and interpersonal quality that allows the nurse to relate to others and to be aware of the world around them. The characteristics of presence, which include holism, intimacy, sensitivity and adaptability, create a heightened sense of awareness that fosters therapeutic relationships between the nurse and patient. Developing a mindful, authentic presence is central to health and spiritual practices in several cultures and a major element of leadership. Students will intentionally develop a focused mindfulness practice that will influence patient outcomes and lead to conditions that create joy in the workplace. "
        },
        "D115": {
          "courseID": "D115",
          "CCN": "NURS 5800",
          "name": "Advanced Pathophysiology for the Advanced Practice Nurse ",
          "CUs": "4",
          "description": "Advanced Pathophysiology for the Advanced Practice Nurse prepares the graduate nursing student for the role of an advanced practice nurse with the competencies and skills needed to recognize disease states, identify disease progression, and assess and evaluate symptoms for patients across the lifespan. This course will help the graduate nursing student gain a deeper understanding of pathophysiology from the cellular to the systems level and will provide graduate nursing students with the knowledge and skills to determine the etiology, underlying physiological changes, and the human affective responses to alterations in health. This course will also prepare the graduate nursing student to communicate the pathophysiology of disease processes to providers and patients. "
        },
        "D025": {
          "courseID": "D025",
          "CCN": "NURS 5202",
          "name": "Essentials of Advanced Nursing Roles and Interprofessional Practice ",
          "CUs": "2",
          "description": "Essentials of Advanced Nursing Roles and Interprofessional Practice explores essential characteristics of the advanced professional nurse in the role of leader, educator, practitioner, or informatics specialist. In this course, students will apply evidence-based strategies to facilitate interprofessional collaboration on teams. Students will explore the role of nurses in advocating for change at the bedside, as well as leading teams to advocate for health policy reform. Students will gather and analyze data to identify patients and populations at risk and recommend policy change to improve health outcomes in the community. "
        },
        "D026": {
          "courseID": "D026",
          "CCN": "NURS 5203",
          "name": "Quality Outcomes in a Culture of Value-Based Nursing Care ",
          "CUs": "2",
          "description": "Quality Outcomes in a Culture of Value-Based Nursing Care incorporates current standards of quality and safety within the context of value-based care. In a value-based healthcare system, the benefits are derived from measuring health outcomes against the cost of delivering the outcomes. These benefits are then extended to patients, providers, payers, suppliers, and society as a whole. This course introduces new healthcare delivery models, which stress a team-oriented approach to patient care and sharing of patient data so that care is coordinated, and outcomes can be measured easily. Emphasis is placed on performance and quality improvement methods that underlie value-based nursing care. The nurse in advanced practice today must exemplify the standards of quality and safety and be prepared to lead the delivery of value-based patient-centered care. "
        },
        "D116": {
          "courseID": "D116",
          "CCN": "NURS 6800",
          "name": "Advanced Pharmacology for the Advanced Practice Nurse ",
          "CUs": "4",
          "description": "Advanced Pharmacology for the Advanced Practice Nurse prepares the graduate nursing student for the role of an advanced practice nurse with the competencies and skills for prescribing and monitoring medication safely and effectively. This course will prepare the graduate nursing student to apply pharmacotherapeutics in primary care settings by utilizing the pivotal basis of pharmacokinetics and pharmacodynamics. This course will also prepare the graduate nursing student to select the correct medication, describe the rationale for that selection to the patient, family, and other providers, and to effectively monitor the patient to promote positive drug outcomes. "
        },
        "D029": {
          "courseID": "D029",
          "CCN": "NURS 5206",
          "name": "Informatics for Transforming Nursing Care ",
          "CUs": "3",
          "description": "Informatics for Transforming Nursing Care integrates nursing science with multiple information and analytical sciences to identify, define, manage, and communicate data, information, knowledge, and wisdom in nursing practice. Students will acquire knowledge and skills to apply informatics concepts, communications, and data that are critical to facilitating interprofessional data-driven decision-making. It is designed to build competence in the use of patient- and population-based applications that inform and support the transformation of nursing care delivery toward a future of value-based quality nursing care that improves health outcomes. This course aligns theoretical concepts with practical applications of informatics and is consistent with the functional areas and responsibilities of informatics nurses as defined by the American Nurses Association Scope and Standards for nursing informatics. "
        },
        "D030": {
          "courseID": "D030",
          "CCN": "NURS 5207",
          "name": "Leadership and Management in Complex Healthcare Systems ",
          "CUs": "3",
          "description": "Leadership and Management in Complex Healthcare Systems prepares graduate nurses to be thoughtful strategists and informed decision-makers who serve as strong leaders in high-performing healthcare systems. Students develop competencies for managing diverse teams in complex systems, monitoring and measuring organizational performance, allocating financial and human resources, and leading change towards a transformed healthcare system. Additionally, students acquire the knowledge and skills to become full partners with other healthcare professionals by demonstrating nurse contributions toward high-quality care to patients and populations, while working collaboratively with interprofessional teams. There are no prerequisites for this course. "
        },
        "D117": {
          "courseID": "D117",
          "CCN": "NURS 6810",
          "name": "Advanced Health Assessment for the Advanced Practice Nurse ",
          "CUs": "4",
          "description": "Advanced Health Assessment prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies necessary to provide primary health care to patients and families of diverse populations. Students will develop the skills needed for systematically collecting and analyzing subjective and objective patient data. Through simulation and clinical experiences, students will use data to determine current and ongoing patient health status, predict health risks, and identify health-promoting activities for patients across the lifespan. Advanced Health Assessment will prepare the nursing graduate with the critical thinking, clinical reasoning, and advanced diagnostic skills required for advanced practice nursing. Upon completion of Advanced Health Assessment, the graduate will be able to synthesize individual and systems level subjective and objective data to facilitate the differential diagnosis processes. Also, the graduate will be able to clearly describe to patients and providers the pertinent health assessment findings and rationale supporting the diagnostic process. "
        },
        "D118": {
          "courseID": "D118",
          "CCN": "NURS 6820",
          "name": "Adult Primary Care for the Advanced Practice Nurse ",
          "CUs": "3",
          "description": "Adult Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to adult patients and families. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; diagnosing, managing, and coordinating care for patients with acute and chronic conditions; and empowering patients to pursue positive health outcomes. This course will also prepare the graduate nursing student to collaborate with adult patients to develop effective plans of care that build patient self-efficacy in the process of preventing and treating disease. There are no prerequisites for this course. "
        },
        "D031": {
          "courseID": "D031",
          "CCN": "NURS 6308",
          "name": "Advancing Evidence-Based Innovation in Nursing Practice ",
          "CUs": "3",
          "description": "Advancing Evidence-Based Innovation in Nursing Practice introduces students to the dynamic union of healthcare innovation and evidence. Core competencies and behaviors required to be a nurse innovator are discussed. Strategies for measuring innovation at various system levels are presented, as well as techniques for synthesizing and disseminating evidence to advance innovation in healthcare. The skills needed to appraise the quality of diverse sources of evidence are presented within the framework of evidence-based practice. This course focuses on identifying new and emerging sources of evidence that can inform, translate, and scale the complexity of leading innovation in healthcare organizations. Students will experience building communities of practice for collaboratively developing innovative practices and policies designed to improve the health of populations and enhance the patient experience of care. "
        },
        "D119": {
          "courseID": "D119",
          "CCN": "NURS 6830",
          "name": "Pediatric Primary Care for the Advanced Practice Nurse ",
          "CUs": "3",
          "description": "Pediatric Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to pediatric patients, from infancy through adolescence, and their families in an outpatient setting. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; in diagnosing, managing, and coordinating care for pediatric patients with acute and chronic conditions; and in empowering patients and their families in pursuing positive health outcomes. This course will also prepare the graduate nursing student to collaborate with pediatric patients and their families in developing effective plans of care that build patient and family self-efficacy in the process of preventing and treating disease. There are no prerequisites for this course. "
        },
        "D120": {
          "courseID": "D120",
          "CCN": "NURS 6840",
          "name": "Special Populations Primary Care for the Advanced Practice Nurse ",
          "CUs": "3",
          "description": "Special Populations Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to unique patient populations in the outpatient setting. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; in diagnosing, managing, and coordinating care for patients with specific disease processes; and in empowering patients and their families in pursuing positive health outcomes. This course will also prepare the graduate nursing student to collaborate with unique patient populations and their families in developing effective plans of care that build self-efficacy in the process of preventing and treating specific disease processes. There are no prerequisites for this course. "
        },
        "D121": {
          "courseID": "D121",
          "CCN": "NURS 6820",
          "name": "Health Promotion of Patients and Populations Across the Lifespan ",
          "CUs": "3",
          "description": "Health Promotion of Patients and Populations Across the Lifespan prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide health promotion activities to individuals and populations. This course will prepare the graduate nursing student to incorporate individual characteristics, population factors, and social determinants of health (SDOH) in determining the most efficient use of finite resources in leading health promotion activities. This course will also prepare the graduate nursing student to lead health promotion activities for individuals and specific populations across the lifespan. All MSN Core courses, as well as Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse must be completed before taking this course. "
        },
        "D122": {
          "courseID": "D122",
          "CCN": "NURS 6830",
          "name": "Family Nurse Practitioner Clinical Internship I ",
          "CUs": "3",
          "description": "Family Nurse Practitioner Clinical Internship I prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups throughout the life span. Using the precepted clinical setting, this course will provide opportunities for the graduate nursing student to combine competencies developed in preparatory advanced practice coursework to deliver patient-centered healthcare. This course will also provide the graduate nursing student with opportunities to conduct advanced health assessments and use the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, determine correct diagnoses, and establish plans of care that include patient and population preferences. All MSN Core courses, as well as Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse and FNP Specialty courses must be completed before taking this course. "
        },
        "D123": {
          "courseID": "D123",
          "CCN": "NURS 6861",
          "name": "Family Nurse Practitioner Clinical Internship II ",
          "CUs": "3",
          "description": "Family Nurse Practitioner Clinical Internship II prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups throughout the life span. In the precepted clinical setting, the student will combine competencies developed in preparatory advanced practice coursework to deliver consumer-centered healthcare. The student will conduct advanced health assessments and use the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, determine correct diagnoses, and establish plans of care that include consumer and population preferences. All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse, FNP Specialty courses, and D122 Family Nurse Practitioner Internship I must be completed before taking this course. "
        },
        "D124": {
          "courseID": "D124",
          "CCN": "NURS 6850",
          "name": "Family Nurse Practitioner Clinical Internship III ",
          "CUs": "3",
          "description": "Family Nurse Practitioner Clinical Internship III prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups across throughout the lifespan. In the precepted clinical setting, the student will combine competencies developed in preparatory advanced practice coursework to deliver consumer-centered healthcare. Therefore, the graduate will conduct advanced health assessments and utilize the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish plans of care that include consumer and population preferences. All MSN Core courses, FNP Specialty course, and Family Nurse Practitioner Internship I and II must be completed before taking this course. "
        },
        "AOA2": {
          "courseName": "Number Sense and Functions ",
          "description": "Number Sense and Functions is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as number sense, patterns and functions, integers and order of operations, fractions, decimals, and percentages. "
        },
        "AUA2": {
          "courseName": "Graphing, Proportional Reasoning and Equations/Inequalities ",
          "description": "Graphing, Proportional Reasoning and Equations/Inequalities is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as coordinate pairs and graphing, ratios and proportional reasoning, and equations and inequalities. "
        },
        "AVA2": {
          "courseName": "Geometry and Statistics ",
          "description": "Geometry and Statistics is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as geometry and measurement, statistics and probability. "
        },
        "BYT2": {
          "courseName": "Physics: Mechanics ",
          "description": "Physics: Mechanics introduces foundational concepts of mechanics, including motion, gravitation, work and energy,  momentum and collisions, rotational motion, static equilibrium, fluids, and oscillation. "
        },
        "BZT2": {
          "courseName": "Physics: Waves and Optics ",
          "description": "Physics: Waves and Optics addresses foundational topics in the physics of waves and optics. Students will study basic wave motion and then apply that knowledge to the study of sound and light with even further applications to optical instruments. This course will also cover thermodynamics and theories governing the physics of gases. "
        },
        "C104": {
          "courseName": "Elementary Social Studies Methods ",
          "description": "Elementary Social Studies Methods helps students learn how to implement effective social studies instruction in the elementary classroom. Topics include social studies themes, promoting cultural diversity, integrated social studies across the curriculum, social studies learning environments, assessing social studies understanding, differentiated instruction for social studies, technology for social studies instruction, and standards-based social studies instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "C105": {
          "courseName": "Elementary Visual and Performing Arts Methods ",
          "description": "Elementary Visual and Performing Arts Methods helps students learn how to implement effective visual and performing arts instruction in the elementary classroom. Topics include integrating arts across the curriculum, music education, visual arts, dance and movement, dramatic arts, differentiating instruction for visual and performing arts, and promoting cultural diversity through visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C107": {
          "courseName": "Anatomy and Physiology I ",
          "description": "Anatomy and Physiology I examines the structures and functions of the human body. The course is designed to provide students with a thorough understanding of human anatomy and physiology, including the interdependent operational relationships among them. Students will use a dissection lab to study organ systems of the human body in their healthy state, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. By examining these organ systems in a healthy state, healthcare professionals are more adept at recognizing when something is functioning abnormally, which is a key component to providing effective care to patients. For nursing students, this is the first of two anatomy and physiology courses within the program of study. This course has no prerequisites. "
        },
        "C108": {
          "courseName": "Elementary Science Methods ",
          "description": "Elementary Science Methods helps students learn how to implement effective science instruction in the elementary classroom. Topics include processes of science, science inquiry, science learning environments, instructional strategies for science, differentiating instruction for science, assessing science understanding, technology for science instruction, standards-based science instruction, integrating science across the curriculum, and science beyond the classroom. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "C172": {
          "courseName": "Network and Security ",
          "description": "Foundations - Network and Security - Foundations introduces students to the components of a computer network and the concept and role of communication protocols. The course covers widely used categorical classifications of networks (e.g., LAN, MAN, WAN, WLAN, PAN, SAN, CAN, and VPN) as well as network topologies, physical devices, and layered abstraction. The course also introduces students to basic concepts of security, covering vulnerabilities of networks and mitigation techniques, security of physical media, and security policies and procedures. This course has no prerequisites. "
        },
        "C175": {
          "courseName": "Data Management ",
          "description": "Foundations - This course introduces students to the concepts and terminology used in the field of data management. Students will be introduced to Structured Query Language (SQL) and will learn how to use Data Definition Language (DDL) and Data Manipulation Language (DML) commands to define, retrieve, and manipulate data. This course covers differentiations of data—structured vs. unstructured and quasi-structured (relational, hierarchical, XML, textual, visual, etc); it also covers aspects of data management (quality, policy, storage methodologies). Foundational concepts of data security are included. "
        },
        "C179": {
          "courseName": "Business of IT ",
          "description": "Applications - This course introduces IT students to information systems (IS). The course includes important topics related to the management of information systems (MIS), such as system development and business continuity. The course also provides an overview of management tools and issue tracking systems. "
        },
        "C181": {
          "courseName": "Survey of United States Constitution and Government ",
          "description": "Ready to work on Constitution and Government? Please contact your program mentor to be moved to the correct course. "
        },
        "C182": {
          "courseName": "Introduction to IT ",
          "description": "Introduction to IT examines information technology as a discipline and the various roles and functions of the IT department as business support. Students are presented with various IT disciplines including systems and services, network and security, scripting and programming, data management, and business of IT, with a survey of technologies in every area and how they relate to each other and to the business. "
        },
        "C217": {
          "courseName": "Human Growth and Development Across the Lifespan ",
          "description": "This course introduces candidates to human development across the lifespan. This will include an introductory survey of cognitive, psychological, and physical growth. Candidates will gain an understanding of the emergence of personality, identity, gender and sexuality, social relationships, emotion, language, and moral development through life. This will include milestones such as education, achievement, work, dying, and death. "
        },
        "C226": {
          "courseName": "Research Design and Analysis ",
          "description": "The Research Design and Analysis course focuses on applying strategies for effective design of empirical research studies. Particular emphasis is placed on selecting or constructing the design that will provide the most valid results, analyzing the kind of data that would be obtained, and making defensible interpretations and drawing appropriate conclusions based on the data. Research Questions and Literature Review is a prerequisite for this course. "
        },
        "C227": {
          "courseName": "Research Proposals ",
          "description": "Research Proposals focuses on planning and writing a well-organized and complete research proposal. The relationship of the sections in a research proposal to the sections in a research report will be highlighted. Research Design and Analysis is a prerequisite for this course. "
        },
        "C228": {
          "courseName": "Community Health and Population-Focused Nursing ",
          "description": "Community Health and Population-Focused Nursing will assist students in becoming familiar with foundational theories and models of health promotion applicable to the community health nursing environment. Students will develop an understanding of how policies and resources influence the health of populations. Focus is concentrated on learning the importance of a community assessment to improve or resolve a community health issue. This course introduces students to the relationships between cultures and communities and the steps necessary to create community collaboration with the goal to improve or resolve community health issues in a variety of settings. Students will gain a greater understanding of health systems in the United States, global health issues, quality-of-life issues, cultural influences, community collaboration, and emergency preparedness.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C229": {
          "courseName": "Community Health and Population-Focused Nursing Field Experience ",
          "description": "This course will assist students to become familiar with clinical aspects of health promotion and disease prevention, applicable to the community health nursing environment. Students will practice skills based on clinical priorities, methodology, and resources that positively influence the health of populations. Students will demonstrate critical thinking skills by applying principles of community health nursing in a variety of settings. Students will design, implement and evaluate a project in community health. Students will develop health promotion and disease prevention strategies for population groups.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C234": {
          "courseName": "Workforce Planning: Recruitment and Selection ",
          "description": "This course focuses on building a highly skilled workforce by using effective strategies and tactics for recruiting, selecting, hiring, and retaining employees. "
        },
        "C266": {
          "courseName": "The Ocean Systems ",
          "description": "This course investigates the complex ocean system by looking at the way its components—atmosphere, biosphere, geosphere, hydrosphere—interact. Specific topics include the origins of Earth’s oceans and the early history of life; physical characteristics and geologic processes of the ocean floor; chemistry of the water molecule; energy flow between air and water and how ocean surface currents and deep circulation patterns affect weather and climate; marine biology and why ecosystems are an integral part of the ocean system; the effects of human activity; and the role of professional educators in teaching about ocean systems. "
        },
        "C304": {
          "courseName": "Professional Roles and Values ",
          "description": "This course explores the unique role nurses play in healthcare, beginning with the history and evolution of the nursing profession. The responsibilities and accountability of professional nurses are covered, including cultural competency, advocacy for patient rights, and the legal and ethical issues related to supervision and delegation. Professional conduct, leadership, the public image of nursing, the work environment, and issues of social justice are also addressed. "
        },
        "C339": {
          "courseName": "Cohort Seminar ",
          "description": "Cohort Seminar provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C349": {
          "courseName": "Health Assessment ",
          "description": "The Health Assessment course is designed to enhance students’ knowledge and skills in health promotion, the early detection of illness, and prevention of disease. The course provides the relevant content and skills necessary to perform a comprehensive physical assessment of patients throughout the lifespan. Students are engaged in these processes through interviewing, history taking, and demonstrating an advanced-level physical examination. Dominant models, theories, and perspectives related to evidence-based wellness practices and health education strategies also are included in this challenging course. "
        },
        "C361": {
          "courseName": "Evidence Based Practice and Applied Nursing Research ",
          "description": "The Evidence Based Practice course will help you to learn how to design and conduct research to answer important questions about improving nursing practice and patient care delivery outcomes. After you are introduced to the basics of evidence-based practice, you will continue to implement the principles throughout your clinical experience. This will allow you to graduate with more competence and confidence to become a leader in the healthcare environment. "
        },
        "C363": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in the slope of a curve and covers the knowledge and skills necessary to apply differential calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include functions, limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, sum, product, and quotient rules applied to polynomial, trigonometric, exponential, and logarithmic functions; implicit differentiation, position, velocity, and acceleration; optimization, related rates, curve sketching, and L'Hopital's rule. Precalculus is a prerequisite for this course. "
        },
        "C367": {
          "courseName": "Elementary Physical Education and Health Methods ",
          "description": "Elementary Physical Education and Health Methods helps students learn how to implement effective physical and health education instruction in the elementary classroom. Topics include healthy lifestyles, student safety, student nutrition, physical education, differentiated instruction for physical and health education, physical education across the curriculum, and public policy in health and physical education. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C381": {
          "courseName": "Elementary Mathematics Methods ",
          "description": "Elementary Mathematics Methods helps students learn to implement effective mathematics instruction in the elementary classroom. Topics include differentiated mathematics instruction, mathematical communication, mathematical tools for instruction, assessing mathematics understanding, integrating mathematics across the curriculum, critical thinking development, standards based mathematics instruction, and mathematical models and representation. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "C382": {
          "courseName": "Elementary Science Methods ",
          "description": "Elementary Science Methods helps students learn how to implement effective science instruction in the elementary classroom. Topics include processes of science, science inquiry, science learning environments, instructional strategies for science, differentiated instruction for science, assessing science understanding, technology for science instruction, standards based science instruction, integrating science across curriculum, and science beyond the classroom. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "C396": {
          "courseName": "English Pedagogy ",
          "description": "English Pedagogy examines pedagogical applications for the teaching of reading, literature, composition, and related English Language Arts (ELA) content and skills for middle and secondary schools. Focused on fostering and developing pedagogical content knowledge in the aforementioned areas, students will analyze assessment strategies and incorporate methods of literacy instruction into their instructional planning to meet the needs of diverse learners. This course helps students prepare and develop skills for classroom practice, lesson planning, and working in school settings. C397 Preclinical Experiences in English is a prerequisite. "
        },
        "C405": {
          "courseName": "Anatomy and Physiology II ",
          "description": "This course introduces advanced concepts of human anatomy and physiology through the investigation of the structures and functions of the body's organ systems. Students will have the opportunity to explore the body through laboratory experience and apply the concepts covered in this course. For nursing students, this is the second of two anatomy and physiology courses within the program of study. "
        },
        "C453": {
          "courseName": "Clinical Microbiology ",
          "description": "Clinical Microbiology introduces general concepts, methods, and applications of microbiology from a health sciences perspective. The course is designed to provide healthcare professionals with a basic understanding of how various diseases are transmitted and controlled. Students will examine the structure and function of microorganisms, including the roles that they play in causing major diseases. The course also explores immunological, pathological, and epidemiological factors associated with disease. To assist students in developing an applied, patient-focused understanding of microbiology, this course is complimented by several lab experiments that allow students to: practice aseptic techniques, grow bacteria and fungi, identify characteristics of bacteria and yeast based on biochemical and environmental tests, determine antibiotic susceptibility, discover the microorganisms growing on objects and surfaces, and determine the Gram characteristic of bacteria. This course has no prerequisites. "
        },
        "C468": {
          "courseName": "Information Management and the Application of Technology ",
          "description": "Information Management and the Application of Technology helps the candidate learn how to identify and implement the unique responsibilities of nurses related to the application of technology and the management of patient information. This includes understanding the evolving role of nurse informaticists; demonstrating the skills needed to use electronic health records; identifying nurse-sensitive outcomes that lead to quality improvement measures; supporting the contributions of nurses to patient care; examining workflow changes related to the implementation of computerized management systems; and learning to analyze the implications of new technology on security, practice, and research. "
        },
        "C475": {
          "courseName": "Care of the Older Adult ",
          "description": "Care of the Older Adult adapts the concepts from prior coursework to the care of older adults. An understanding of the effects that policy and legislation have on how healthcare systems treat aging patients sets a foundation for improving their care. Students will apply health assessment skills and evidence-based standards in such a way to account for the specific needs of older adults. Emphasis is placed on the importance of maintaining the dignity of older adults by focusing on cultural, religious, spiritual, and communication needs, and by collaborating on care with older adults, families, and caregivers. "
        },
        "C489": {
          "courseName": "Organizational Systems and Quality Leadership ",
          "description": "Nurses serve as clinicians, managers, and mentors to shape the future of healthcare and affect patient care outcomes in positive ways. This course will help students be more confident and better prepared to assume leadership roles regardless of their position in the healthcare delivery system. This advanced leadership course focuses on the concepts of patient safety; improvement science; balancing cost, quality, and access through the triple aim; and leadership and patient/family-centered care. Students will develop mastery of advanced competencies, particularly in patient safety in quality improvement science. "
        },
        "C493": {
          "courseName": "Leadership and Professional Image ",
          "description": "Nursing is a practice discipline that includes direct and indirect care activities that affect health outcomes. Baccalaureate nursing students are developing new competencies in leadership, and in order to achieve mastery, must apply those competencies to live practice experiences and situations. In this course students will complete a Leadership Learning Experience (LLE) and develop their own personal professional portfolio. The professional portfolio is a collection of artifacts from BSN coursework as well as a resume and personal statement. "
        },
        "C612": {
          "courseName": "Mathematics: Content Knowledge ",
          "description": "Mathematics: Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful secondary mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, PreCalculus, Calculus I, and Calculus II. Linear Algebra, and Calculus III are recommended. "
        },
        "C613": {
          "courseName": "Middle School Mathematics: Content Knowledge ",
          "description": "Mathematics: Middle School Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful middle school mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, and Pre-Calculus. "
        },
        "C614": {
          "courseName": "Biology: Content Knowledge ",
          "description": "This comprehensive course examines a student’s conceptual understanding of a broad range of biology topics. High school biology teachers must help students make connections between isolated topics. This course starts with macromolecules that make up cellular components and continues with understanding the many cellular processes that allow life to exist. Connections are then made between genetics and evolution. Classification of organisms leads into plant and animal development that study the organ systems and their role in maintaining homeostasis. The course finishes by studying ecology and the effect humans have on the environment. "
        },
        "C615": {
          "courseName": "Physics: Content Knowledge ",
          "description": "Physics: Content Knowledge covers the advanced content knowledge that a secondary physics teacher is expected to know and understand. Topics include nature and impact of science and engineering, principle and models of matter and energy, mechanics, electricity and magnetism, waves, and science teaching and pedagogy. "
        },
        "C616": {
          "courseName": "Middle School Science: Content Knowledge ",
          "description": "This course covers the content knowledge that a middle-level science teacher is expected to know and understand. Topics include scientific methodologies, history of science, basic science principles, physical sciences, life sciences, earth and space sciences, and the role of science and technology and their impact on society. "
        },
        "C618": {
          "courseName": "Earth Science: Content Knowledge ",
          "description": "This course covers the advanced content knowledge that a secondary earth science teacher is expected to know and understand. Topics include basic scientific principles of earth and space sciences, tectonics and internal earth processes, earth materials and surface processes, history of the Earth and its life-forms, Earth's atmosphere and hydrosphere, and astronomy. "
        },
        "C635": {
          "courseName": "MA, Mathematics Education (K-6) Capstone ",
          "description": "MA, Mathematics Education (K-6) Capstone Written Project takes the student through the steps of planning and conducting research on a topic or issue related to the students' practice setting. The result is expected to be a significant piece of research, culminating in a written research report, including sections describing a literature review, methodology, and detailed analysis and reporting of results. Prerequisite Courses: Research Foundations (C224), Research Questions and Literature Review (C225), Research Design and Analysis (C226), and Research Proposals (C227) or permission of a faculty manager. Additionally, students wishing to add the Capstone with fewer than eight weeks remaining in the term must receive permission of the faculty manager. "
        },
        "C645": {
          "courseName": "Science Methods ",
          "description": "Science Methods provides an introduction to science teaching methods for graduate students seeking initial licensure or an additional endorsement in secondary biology, chemistry, geosciences, physics, or middle grades general science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Students seeking initial licensure should complete Curriculum, Instruction, and Assessment before this course. There are no prerequisites for students seeking an endorsement in a new content area. "
        },
        "C647": {
          "courseName": "Trigonometry and Precalculus ",
          "description": "Trigonometry and Precalculus covers the knowledge and skills necessary to apply trigonometry, complex numbers, systems of equations, vectors and matrices, and sequences and series, and to use appropriate technology to model and solve real-life problems. Topics include degrees; radians and arcs; reference angles and right triangle trigonometry; applying, graphing and transforming trigonometric functions and their inverses; solving trigonometric equations; using and proving trigonometric identities; geometric, rectangular, and polar approaches to complex numbers; DeMoivre's Theorem; systems of linear equations and matrix-vector equations; systems of nonlinear equations; systems of inequalities; and arithmetic and geometric sequences and series. College Algebra is a prerequisite for this course. "
        },
        "C650": {
          "courseName": "Geology I: Physical ",
          "description": "Geology I: Physical provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to minerals and rocks, the physical features of the Earth, and the internal and surface processes that shape those features. This course has no prerequisites. "
        },
        "C653": {
          "courseName": "Heredity and Genetics ",
          "description": "Heredity and Genetics is an introductory course for graduate students seeking initial licensure or endorsement and/or students earning their MA degree in secondary or middle grade science education. This course addresses the basic principles of heredity and the function of molecular genetics. Topics include Mendelian and non-Mendelian inheritance and population genetics. This course has no prerequisites. "
        },
        "C655": {
          "courseName": "Zoology ",
          "description": "Zoology provides graduate students seeking licensure or endorsement and/or their MA degree in secondary science education with an introduction to the field of zoology. Zoology includes the study of major animal phyla emphasizing characteristics, variations in anatomy, life cycles, adaptations, and relationships among the animal kingdom. A prerequisite for this course is Introduction to Biology. "
        },
        "C657": {
          "courseName": "Calculus III ",
          "description": "Calculus III is the study of calculus conducted in three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply calculus of multiple variables while using the appropriate technology to model and solve real-life problems. Topics include: infinite series and convergence tests (integral, comparison, ratio, root, and alternating), power series,taylor polynomials, vectors, lines and planes in three dimensions, dot and cross products, multivariable functions, limits, and continuity, partial derivatives, directional derivatives, gradients, tangent planes, normal lines, and extreme values. Calculus II is a prerequisite for this course. "
        },
        "C659": {
          "courseName": "Conceptual Physics ",
          "description": "Conceptual Physics provides a broad, conceptual overview of the main principles of physics, including mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. Problem-solving activities and laboratory experiments provide students with opportunities to apply these main principles, creating a strong foundation for future studies in physics. There are no prerequisites for this course. "
        },
        "C732": {
          "courseName": "Elementary Disciplinary Literacy ",
          "description": "Elementary Disciplinary Literacy examines teaching strategies designed to help learners in grades K–6 develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help learners distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction  to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special Education. "
        },
        "C737": {
          "courseName": "Evolution ",
          "description": "This course addresses why evolution is the fundamental concept that underlies all life sciences and how it contributes to advances in medicine, public health, and conservation. This course helps participants gain a firm understanding of the basic mechanisms of evolution including the process of speciation and how these systems have given rise to the great diversity of life in the world today. This course also explore how new ideas, discoveries, and technologies are modifying prior evolutionary concepts. Ultimately, the course will explain how evolution works and how we know what we know. "
        },
        "C739": {
          "courseName": "Space, Time and Motion ",
          "description": "This course begins with a quick tour of discovery and exploration in physics, from the ancient Greek philosophers on to Galileo Galilei, Isaac Newton, and Albert Einstein. Einstein’s work then serves as the departure point for a detailed look at the properties of motion, time, space, matter, and energy. The course considers Einstein’s special theory of relativity, his photon hypothesis, wave-particle duality, his general theory of relativity and its implications for astrophysics and cosmology, as well as his three-decade quest for a unified field theory. It also looks at Einstein as a social and political figure and his contributions as a social and political force. Scientist-authored essays, online interaction, videos, and web resources enable learners to trace this historic path of discovery and explore implications of technology for society, energy production in stars, black holes, the Big Bang, and the role of the scientist in modern society. "
        },
        "C787": {
          "courseName": "Health and Wellness Through Nutritional Science ",
          "description": "Nutritional ignorance or misunderstandings are at the root of the health problems that most Americans face today. Nurses need to be armed with the most current information available about nutrition science, including how to understand nutritional content of food; implications of exercise and activity on food consumption and weight management, and management of community or population specific nutritional challenges. The Health and Wellness Through Nutritional Science course should prepare nurses to provide support, guidance, and teaching about incorporation of sound nutritional principles into daily life for health promotion. This course covers nutrition to support wellness; healthy nutritional choices; nutrition and physical activity; nutrition through the lifecycle; safety and security of food; and nutrition and global health environments. "
        },
        "C790": {
          "courseName": "Foundations in Nursing Informatics ",
          "description": "This course addresses the integration of technology to improve and support nursing practice. It provides nurses with a foundational understanding of nursing informatics theory, practice, and applications. Topics include the role of nursing in informatics; use of computer technology for clinical documentation, communication, and workflows; problem identification; project implementation; and best practices. "
        },
        "C792": {
          "courseName": "Data Modeling and Database Management Systems ",
          "description": "This graduate course is designed to engage the student in planning, analyzing, and designing a relational database management system (DBMS) for use by nurse administrators, clinicians, educators, and informaticists. This experience will provide the knowledge needed to advocate for nursing informatics needs within the field of healthcare. "
        },
        "C797": {
          "courseName": "Data Science and Analytics ",
          "description": "This course addresses the interdisciplinary and emerging field of data science in healthcare. Candidates learn to combine tools and techniques from statistics, computer science, data visualization, and the social sciences to solve problems using data. Topics include data analysis; database management; inferential and descriptive statistics; statistical inference; and process improvement. "
        },
        "C798": {
          "courseName": "Informatics System Analysis and Design ",
          "description": "In Informatics System Analysis and Design, a broad understanding of data systems is covered to build upon the Foundations in Nursing Informatics course. The importance of effective interoperability, functionality, data access, and user satisfaction are addressed. The student will be analyzing reports and integrating federal regulations, research principles, and principles of environmental health in the construction of a real-world systems analysis and design project. This course will be directly applicable to healthcare settings as electronic records management has become compulsory for healthcare providers. All of the information in this course will be directly tied to the delivery of quality patient care and patient safety. Foundations in Nursing Informatics is recommended as a prerequisite. "
        },
        "C820": {
          "courseName": "Professional Leadership and Communication for Healthcare ",
          "description": "The Professional Communication and Leadership in Healthcare course is designed to help students prepare for success in the online environment at Whalley University and beyond. Student success starts with the social support and self-reflective awareness that will prepare students to weather the challenges of academic programs. In this course students will participate in group activities and complete several individual assignments. The group activities are aimed at finding support and gaining insight from other students. The assignments are intended to give the student an opportunity to reflect about where they are and where they would like to be. The activities in each group meeting are designed to give students several tools they can use to achieve success. This course is designed as a five-part intensive learning experience. Students will attend five group meetings during the term. At each meeting students will engage in activities that help them understand their own educational journey and find support and inspiration in the journey of others.   "
        },
        "C833": {
          "courseName": "Chemistry with Lab ",
          "description": "Chemistry with Lab for graduates provides already licensed teachers seeking an additional license or endorsement in middle grades science or secondary physics, biological science, or earth science with an introduction to the field of chemistry. Designed for those not majoring in chemistry education, this course highlights how the topics covered can be applied within various branches of science. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science for graduates is a prerequisite for this course. "
        },
        "C853": {
          "courseName": "Teacher Performance Assessment in English ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during a student’s time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of their content, planning, instructional, and reflective skills.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C854": {
          "courseName": "Nursing Informatics Field Experience ",
          "description": "Nursing Informatics Field Experience requires students to complete clinical/practice experiences while engaging in authentic activities relevant to the role of an informatics nurse. To help students develop competency in this area, this course gives students opportunities to apply methods and solutions to support clinical decisions. They will be prepared to improve health outcomes by analyzing an existing health information system to determine the need for a system optimization that will improve an organization’s ability to measure and report Triple Aim objectives. All MSN Core and Specialty courses, with the exclusion of the Capstone course, are prerequisites to this course and must be completed before taking this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C855": {
          "courseName": "Nursing Informatics Capstone ",
          "description": "Nursing Informatics Capstone requires students to complete clinical/practice experiences (CPE) and finalize their system optimization proposal paper, which addresses the Institute of Health’s Triple Aim initiative. During this course, students will plan the final phase of their system development life cycle (SDLC), which consists of proposing the processes, methods, and tasks for monitoring, maintaining, supporting, and evaluating their system optimization. The knowledge and skills that students acquire during the CPE in this course will prepare them to complete their system optimization proposal paper. This is a culminating course that provides students an opportunity to demonstrate the competencies acquired during this program. All MSN Core and Specialty courses, including the Field Experience course, are prerequisites to this course and must be completed before taking this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C867": {
          "courseName": "Scripting and Programming ",
          "description": "Applications - Scripting and Programming - Applications for undergraduates explores the various aspects of the C++ programming language by examining its syntax, the development environment, and tools and techniques to solve some real-world problems. "
        },
        "C870": {
          "courseName": "Human Anatomy and Physiology ",
          "description": "This course examines the structures and functions of the human body and covers anatomical terminology, cells and tissues, and organ systems. Students will study the healthy state of the organ systems of the human body, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. There are no prerequisites for this course. "
        },
        "C873": {
          "courseName": "Teacher Performance Assessment in Elementary Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills in this professional assessment.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C874": {
          "courseName": "MA, Mathematics Education (5-12) Teacher Performance Assessment ",
          "description": "MA, Mathematics Education (5-12) Teacher Performance Assessment contains a comprehensive, original, research based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) Contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
        },
        "C878": {
          "courseName": "Mathematical Modeling and Applications ",
          "description": "Mathematical Modeling and Applications applies mathematics, such as differential equations, discrete structures, and statistics to formulate models and solve real-world problems. This course emphasizes improving students’ critical thinking to help them understand the process and application of mathematical modeling. Probability and Statistics II and Calculus II are prerequisites. "
        },
        "C880": {
          "courseName": "Algebra for Secondary Mathematics Teaching ",
          "description": "Algebra for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of algebra. Secondary teachers should have an understanding of the following: algebra as an extension of number, operation, and quantity; various ideas of equivalence as it pertains to algebraic structures; patterns of change as covariation between quantities; connections between representations (tables, graphs, equations, geometric models, context); and the historical development of content and perspectives from diverse cultures. In particular, the course focuses on deeper understanding of rational numbers, ratios and proportions, meaning and use of variables, functions (e.g., exponential, logarithmic, polynomials, rational, quadratic), and inverses. Calculus I is a prerequisite for this course. "
        },
        "C882": {
          "courseName": "Geometry for Secondary Mathematics Teaching ",
          "description": "Geometry for Secondary Mathematics Teaching explores important conceptual underpinnings, common student misconceptions and ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of geometry. Students in this course will develop a deep understanding of constructions and transformations, congruence and similarity, analytic geometry, solid geometry, conics, trigonometry, and the historical development of content. Calculus I is a prerequisite for this course. "
        },
        "C884": {
          "courseName": "Statistics and Probability for Secondary Mathematics Teaching ",
          "description": "Statistics and Probability for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of statistics and probability. Secondary teachers should have a deep understanding of summarizing and representing data, study design and sampling, probability, testing claims and drawing conclusions, and the historical development of content and perspectives from diverse cultures. Calculus I is a prerequisite for this course. "
        },
        "C886": {
          "courseName": "Advanced Calculus ",
          "description": "Advanced Calculus examines rigorous reconsideration and proofs involving calculus. Topics include real-number systems, sequences, limits, continuity, differentiation, and integration. This course emphasizes students’ ability to apply critical thinking to concepts to analyze the connections between definitions and properties. Calculus III and Linear Algebra are prerequisites. "
        },
        "C887": {
          "courseName": "MA, Mathematics Education (5-9) Teacher Performance Assessment ",
          "description": "MA, Mathematics Education (5-9) Teacher Performance Assessment contains a comprehensive, original, research based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
        },
        "C889": {
          "courseName": "Molecular and Cellular Biology ",
          "description": "Molecular and Cellular Biology provides graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary science education with an introduction to the area of molecular and cellular biology. This course examines the cell as an organism emphasizing molecular basis of cell structure and functions of biological macromolecules, subcellular organelles, intracellular transport, cell division, and biological reactions. A prerequisite for this course is Introduction to Biology. "
        },
        "C891": {
          "courseName": "Ecology and Environmental Science ",
          "description": "Ecology and Environmental Science is an introductory course for graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary or middle grade science education. The course explores the relationships between organisms and their environment, including population ecology, communities, adaptations, distributions, interactions, and the environmental factors controlling these relationships. This course has no prerequisites. "
        },
        "C893": {
          "courseName": "Geology II: Earth Systems ",
          "description": "Geology II: Earth Systems provides graduate students seeking licensure or endorsement and/or to earn their MA degree in secondary science education with an examination of the geosphere, atmosphere, hydrosphere, and biosphere and the dynamic equilibrium of these systems over geologic time. This course also examines the history of Earth and its lifeforms, with an emphasis in meteorology. A prerequisite for this course is Geology I: Physical. "
        },
        "C895": {
          "courseName": "Astronomy ",
          "description": "Astronomy provides graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary or middle grade science education with essential knowledge of astronomy. This course explores Western history and basic physics of astronomy; phases of the moon and seasons; composition and properties of solar system bodies; stellar evolution and remnants; properties and scale of objects and distances within the universe; and introductory cosmology. A prerequisite for this course is General Physics. "
        },
        "C904": {
          "courseName": "Teacher Performance Assessment in Science ",
          "description": "The Teacher Performance Assessment in Science course is culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C907": {
          "courseName": "Introduction to Biology ",
          "description": "This course is a foundational introduction to the biological sciences. This course explores the overarching theories of life from biological research as well as the fundamental concepts and principles of the study of living organisms and their interaction with the environment. Key concepts include how living organisms use and produce energy; how life grows, develops, and reproduces; how life responds to the environment to maintain internal stability; and how life evolves and adapts to the environment. "
        },
        "C912": {
          "courseName": "College Algebra ",
          "description": "This course provides further application and analysis of algebraic concepts and functions through mathematical modeling of real-world situations. Topics include real numbers, algebraic expressions, equations and inequalities, graphs and functions, polynomial and rational functions, exponential and logarithmic functions, and systems of linear equations. "
        },
        "C914": {
          "courseName": "Teacher Performance Assessment in Mathematics Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C918": {
          "courseName": "Evolving Roles of Nurse Educators in Diverse Environments ",
          "description": "Evolving Roles of Nurse Educators in Diverse Environments examines the multidimensional roles of a contemporary academic nurse educator. This course explores the roles and responsibilities of the nurse educator as a teacher, leader, change agent, and curriculum innovator. Students will also examine the importance of personal and professional development by developing strategies that promote academic integrity, cultural sensitivity, social justice, and ethical/legal values in diverse environments. The course emphasizes the responsibility of nurse educators to utilize communication, collaboration, and leadership in mitigating challenges in academic nursing education. "
        },
        "C919": {
          "courseName": "Facilitation of Context-Based Student-Centered Learning ",
          "description": "Facilitation of Context-Based Student-Centered Learning explores how the nurse educator will incorporate authentic experiences into the creation of course plans that facilitate scholarly inquiry, collaboration, and knowledge acquisition in varied educational environments. Emphasis is placed on innovative, transformational, and experiential teaching and learning strategies to facilitate student development of professional, context-based nursing principles, knowledge, skills, and behavior. Evolving Roles of Nurse Educators in Diverse Environments is a prerequisite to this course. "
        },
        "C920": {
          "courseName": "Contemporary Curriculum Design and Development in Nursing Education ",
          "description": "Contemporary Curriculum Design and Development in Nursing Education analyzes the concepts of creating curriculum based on national nursing accreditation standards and instructional design best practices. Nurse educator students will create course content that supports learning in diverse, real-world environments where nurse educators facilitate learning. Instructional design strategies for delivering course content will reflect the mission of academic institution programs, contemporary trends in nursing education, and the needs of key stakeholders in nursing education and practice. Facilitation of Context-Based Student-Centered Learning is a prerequisite to this course. "
        },
        "C921": {
          "courseName": "Assessment and Evaluation Strategies for Measuring Student Learning ",
          "description": "Assessment and Evaluation Strategies for Measuring Student Learning addresses the academic nurse educator's role in the design, development, implementation, and evaluation of student achievement outcomes in nursing education programs. This course requires students to integrate best practices from nursing theory and theories of learning to assess student learning in diverse educational settings. Topics include validity, reliability, and practicality of assessments, interpreting item difficulty and discrimination test results, and analyzing student achievement and learning outcomes data. This course has no prerequisites. "
        },
        "C922": {
          "courseName": "Emerging Trends and Challenges in 21st Century Nursing Education ",
          "description": "Emerging Trends and Challenges in 21st Century Nursing Education analyzes the emerging trends, technologies, and challenges that academic nurse educators encounter when facilitating learning in diverse healthcare settings. Students will focus on the necessity of interprofessional collaboration and the barriers and facilitators to overcoming the challenges associated with teaching and learning in nursing. Topics include the impact of emerging technology, challenges in nursing practice, and the role of the academic nurse educator as a scholar and a nursing education policy advocate. This course has no prerequisites. "
        },
        "C926": {
          "courseName": "Earth: Inside and Out ",
          "description": "Earth: Inside and Out explores the ways in which our dynamic planet evolved, and the processes and systems that continue to shape it. Though the geologic record is incredibly ancient, it has only been studied intensely since the end of the 19th century. Since then, research in fields such as geologic time, plate tectonics, climate change, exploration of the deep-sea floor, and the inner earth have vastly increased our understanding of geological processes. "
        },
        "C931": {
          "courseName": "Preclinical Experiences in Mathematics ",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C933": {
          "courseName": "Preclinical Experiences in Mathematics ",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C934": {
          "courseName": "Preclinical Experiences in Elementary and Special Education ",
          "description": "Preclinical Experiences in Elementary and Special Education provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C935": {
          "courseName": "Preclinical Experiences in Elementary Education ",
          "description": "Preclinical Experiences in Elementary provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C936": {
          "courseName": "Preclinical Experiences in Elementary Education ",
          "description": "Preclinical Experiences in Elementary provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C938": {
          "courseName": "Preclinical Experiences in Science ",
          "description": "Preclinical Experiences in Science provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C945": {
          "courseName": "Preclinical Experiences in English ",
          "description": "Preclinical Experiences in English provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C946": {
          "courseName": "Nursing Education Field Experience ",
          "description": "The Nursing Education Field Experience provides the academic nurse educator student an opportunity to work collaboratively with academic mentors and interprofessional stakeholders to analyze the need-gap for a curriculum change, innovation, or improvement. Based on the identified need-gap, the graduate student will design and develop a course that reflects evidence-based instructional design and assessment principles and practices. This course prepares students for the role of an Academic Nurse Educator, as an agent for change and quality improvement in nursing education. "
        },
        "C947": {
          "courseName": "Nursing Education Capstone ",
          "description": "The Nursing Education Capstone course provides the Nurse Educator student an opportunity to apply previous course work towards the completion of an evidence-based curriculum proposal project. During this course students will build on previous work during their Nursing Education Field Experience course by planning the implementation and evaluation phases of their proposed curriculum change, innovation or improvement. The capstone project represents a synthesis of competencies across the Masters Science of Nursing—Nursing Education degree program, which prepares them to lead, manage, and transform nursing education in diverse and complex settings.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C966": {
          "courseName": "Teaching in the Middle School ",
          "description": "Teaching in Middle School examines the guiding principles and best teaching practices for educating middle school students. The course explores the history of middle school; the philosophy, theory, and rationale behind middle school organization; and the differences between elementary, middle, and secondary schools. The course also examines the unique needs of middle school students and teaching methods used to meet the needs of these learners. This course has no prerequisites. "
        },
        "C968": {
          "courseName": "Software I – C# ",
          "description": "Software I - C# builds object-oriented programming expertise and introduces powerful new tools for C# application development. You will learn about and put into action: class design, exception handling, and other object-oriented principles and constructs to develop software that meets business requirements. This course requires foundational knowledge of object-oriented programming. Scripting and Programming: Foundations and Scripting and Programming: Applications are prerequisites for this course. "
        },
        "C969": {
          "courseName": "Software II – Advanced C# ",
          "description": "Software II - Advanced C# refines object-oriented programming expertise and builds database and file server application development skills. You will learn about and put into action lambda expressions, collections, and input/output to develop software with C# that meets business requirements. This course requires intermediate expertise in object-oriented programming and the C# language. The prerequisite for this course is Software I - C#. "
        },
        "C971": {
          "courseName": "Mobile Application Development Using C# ",
          "description": "Mobile Application Development Using C# introduces students to programming for mobile devices. Building on students’ previous programming knowledge in C#, this course explores a broad range of topics, including mobile user interface design and development; building applications that adapt to different mobile devices and platforms; managing data using a local database; and consuming REST-based web services. In this course, students will focus on developing skills using the latest framework designed to provide a more modern and streamlined development experience. This framework will help students design and code cross-platform applications that work on a range of mobile devices. There are several prerequisites for this course: Software I and II, and UI Design. "
        },
        "C975": {
          "courseName": "Science Methods—Middle Grades General Science ",
          "description": "Science Methods—Middle Grades General Science focuses on teaching methods specific to science for graduate students seeking an endorsement in middle school science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C976": {
          "courseName": "Science Methods—Secondary Biology ",
          "description": "Science Methods—Secondary Biology focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary biology. This course focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C978": {
          "courseName": "Science Methods—Secondary Earth Science ",
          "description": "Science Methods—Secondary Earth Science focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary earth science. Course content focuses on the design and teaching of standardsbased lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C979": {
          "courseName": "Science Methods—Secondary Physics ",
          "description": "Science Methods—Secondary Physics focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary physics. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C992": {
          "courseName": "College Geometry ",
          "description": "College Geometry covers the knowledge and skills necessary to use dynamic technology to explore geometry, to use axiomatic reasoning to prove statements about geometry, and to apply geometric models to solve real-life problems. Topics include axiomatic systems, analytic proofs, coordinate geometry, plane and solid Euclidean geometry, non-Euclidean geometries, constructions, transformations, deductive reasoning, and dynamic technology. College Algebra as well as Trigonometry and Precalculus are prerequisites. "
        },
        "CQC2": {
          "courseName": "Calculus II ",
          "description": "Calculus II is the study of the accumulation of change in relation to the area under a curve. It covers the knowledge and skills necessary to apply integral calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include antiderivatives; indefinite integrals; the substitution rule; Riemann sums; the fundamental theorem of calculus; definite integrals; acceleration, velocity, position, and initial values; integration by parts; integration by trigonometric substitution; integration by partial fractions; numerical integration; improper integration; area between curves; volumes and surface areas of revolution; arc length; work; center of mass; separable differential equations; direction fields; growth and decay problems; and sequences. Calculus I is a prerequisite for this course. "
        },
        "D027": {
          "courseName": "Advanced Pathopharmacological Foundations ",
          "description": "Advanced Pathopharmacological Foundations provides advanced practice nurses foundational knowledge in the many pathologies encountered in practice today. Advancing from the cellular to the body system level, this course examines the pathologies of common conditions seen in healthcare today. Consideration is also given to the human affective response to alterations in health. There are no prerequisites for this course. "
        },
        "D028": {
          "courseName": "Advanced Health Assessment for Patients and Populations ",
          "description": "Advanced Health Assessment of Patients and Populations builds on prior physical health assessment knowledge and skills acquired during undergraduate studies by focusing on the advanced assessment of biopsychosocial and sociocultural contexts in patients and populations across the life span. This course emphasizes the use of a comprehensive health promotion, disease prevention, and health restoration model to address health concerns in patients and communities. Students will acquire advanced assessment knowledge and skills for clinical interviewing, focused history taking, critical diagnostic reasoning, and clinical decision-making using a problemfocused framework that integrates authentic experiences with practical knowledge of health patterns in patients and communities. There are no prerequisites for this course. "
        },
        "D128": {
          "courseName": "Mathematics for Elementary Educators ",
          "description": "Mathematics for Elementary Educators engages preservice elementary teachers in important concepts in geometry, measurement, data analysis and statistics, and probability. "
        },
        "D146": {
          "courseName": "Teacher Performance Assessment in Elementary Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during a student’s time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of their content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D147": {
          "courseName": "Teacher Performance Assessment in Elementary and Special Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D152": {
          "courseName": "Inclusive Classroom ",
          "description": "Inclusive Classroom introduces and prepares candidates to use a repertoire of evidence-based instructional strategies to advance the learning of elementary students with mild to moderate exceptionalities. The beginning of the course focuses on multitiered systems of support. Strategies for intensifying and individualizing instructional interventions, such as making instructional decisions based on progress monitoring data and collaborating with the special education teacher, are targeted. The second portion of the course provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math and literacy lesson plans based on learner characteristics, performance data, and Individualized Education Program (IEP) goals. This course is designed to be taken by candidates after they have completed D091, Introduction to Curriculum, Instruction and Assessment; C365 Lang. Arts Instruction and Intervention; C909, Elementary Reading Methods and Interventions; and C109, Elementary Mathematics Methods. "
        },
        "D155": {
          "courseName": "Leading with Personal Mastery ",
          "description": "Leading with Personal Mastery prepares the advanced professional nurse to demonstrate self-awareness, self-management, executive function, and social awareness skills while leading and managing in diverse healthcare settings. In this course, students will learn how to incorporate these skills when developing personal relationships and building teams. Developing both social and emotional intelligence as a nurse leader will ensure that students have the ability develop strong relationships and make wise decisions when interacting with others. Increasing personal mastery will provide students with a set of tools and strategies to improve healthcare by producing high-quality results. Understanding their strengths and weaknesses, as a leader in healthcare will help students create a vision for success that includes making choices that will help balance their work life more effectively. "
        },
        "D156": {
          "courseName": "Business Case Analysis for Healthcare Improvement ",
          "description": "Business Case Analysis for Healthcare Improvement provides learning experiences that help students develop essential skills for proposing changes that improve and enhance healthcare outcomes. In this course, students will develop a business case during the early stages of a project by assessing the need for the project and the feasibility of initiating a project. Understanding the techniques used to develop a business case will provide students with the skills to obtain buy-in from key stakeholders and determine the best value strategy. Writing a strong business case presents the benefits, challenges, costs, and risks of moving forward with the project or maintaining status quo. It compares the current situation to a future vision so key stakeholders can make data-driven decisions to move forward with the project. During the development of a business case in this course, students will collaborate with internal and external stakeholders to initiate a healthcare improvement project (HIP) that is grounded in project management principles and influenced by stakeholder perspectives.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D157": {
          "courseName": "Managing Resources in an Era of Disruption ",
          "description": "Managing Human and Financial Resources in an Era of Disruption examines the main premise of people and fiscal leadership. This includes the promotion of healthy work environments through the development of programs in support of mitigating behavior problems for the betterment of work-life balance. Students will analyze business model budgets, revenue streams, and human and financial resource allocation, develop training programs to evaluate compliance and regulatory requirement, and create team building experiences to promote high performing teams by improving engagement, establishing trust, and achieving common goals. Students will assess an organization’s mission, vision, and values to establish alignment between healthcare improvement and an organization’s principles for management. Changes in  healthcare are inevitable, as the business success strategies used in the past are not sufficient for surviving in an era of persistent disruption. This course will help students develop the skills nurse leaders need to become partners in recommending innovative strategies that promote value-based healthcare for the future. "
        },
        "D158": {
          "courseName": "Strategically Planning the Execution of a Healthcare Improvement Project ",
          "description": "Strategically Planning the Execution of a Healthcare Improvement Project will help students develop the skills for systems thinking, problem-solving, and data-driven decision-making. In this course, students will plan the implementation of a healthcare improvement project by identifying people, processes, and procedures that need to be in place for implementation. In addition, sociodemographic data on the population that may be affected by the healthcare improvement project will be analyzed to determine risks and opportunities. During this phase, students will perform an assessment of the forces for and against implementing the project. They will also identify short-term objectives and create action plans to align to the vision, mission, and values of the organization where the project will be implemented. Students will also examine the evolution of existing policies, procedures, and processes at the systems level for the purpose of advocating change that will support a healthcare improvement project. During this course, students will plan the implementation of their healthcare improvement project through the use of sociodemographic and health data, strategic planning, and a comprehensive integration of quality and safety concepts.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D159": {
          "courseName": "Evidence-Based Measures for Evaluating Healthcare Improvements ",
          "description": "Evidence-based measures for evaluating healthcare improvements is an essential component of the planning phase of the healthcare improvement project. In this course, students will determine key performance indicators and metrics used to determine the success of a healthcare improvement project (HIP). The student will develop collaborative partnerships and build consensus with stakeholders to determine how specific data will be collected, managed, and analyzed. This is also an opportunity to discuss data issues and technologies needed for the project. To accomplish this phase, students will also determine the parameters, procedures, and technologies needed for data collection, management, analysis, and reporting.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D160": {
          "courseName": "Nursing Leadership and Management Field Experience ",
          "description": "The Nursing Leadership and Management Field Experience course provides an opportunity for students to apply the knowledge and skills they developed in previous courses toward the successful implementation of their healthcare improvement project (HIP). This phase puts into action all the components of project management that were planned and developed while working collaboratively with key stakeholders to establish the need and feasibility of the HIP, analyzing the organizational readiness for change, and planning the implementation and evaluation phases. In this phase, students will develop and implement a training plan for staff, managers, and leaders. They will also implement the communication plan they developed in a previous course. They will also manage the implementation process by applying organizational standards and practices. Students will demonstrate strong leadership skills when meeting with stakeholders to report the status of the implementation phase and collaboratively problem-solve risks. Completion of the specialty courses is a pre-requisite for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D161": {
          "courseName": "Nursing Leadership and Management Capstone ",
          "description": "The Nursing Leadership and Management Capstone provides students with an opportunity to evaluate and close their capstone project. This is the final course in the MSN Leadership and Management program. Students will evaluate the success of their healthcare improvement project (HIP) by analyzing results, using the key performance indicators and metrics that were identified while planning the evaluation phase. Students will present the results of the improvement project in a final report and presentation with a focus on lessons learned throughout each of the phases: initiation, planning, implementation, and evaluation. Reflective and analytic thinking are essential aspects of a capstone project, as students reflect and report on the successes and challenges encountered in each phase. Nursing Leadership and Management Field Experience is a prerequisite for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D162": {
          "courseName": "Secondary Disciplinary Literacy ",
          "description": "Secondary Disciplinary Literacy examines teaching strategies designed to help learners in middle and high school improve upon the literacy skills required to read, write, and think critically while engaging content in different academic disciplines. Themes include exploring how language structures, text features, vocabulary, and context influence reading comprehension across the curriculum. Course content highlights strategies and tools designed to help teachers assess the reading comprehension and writing proficiency of learners and provides strategies to support student reading and writing success in all curriculum areas. This course has no prerequisites. "
        },
        "D164": {
          "courseName": "Elementary Disciplinary Literacy ",
          "description": "Elementary Disciplinary Literacy examines teaching strategies designed to help learners in grades K–6 develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help learners distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity are also addressed. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR  Instructional Planning and Presentation in Elementary Education. "
        },
        "D165": {
          "courseName": "Children’s Literature ",
          "description": "Children's Literature is an introduction to and exploration of children's literature. Students will consider and analyze children's literature as a lens through which to view the world. Students will experience multiple genres, historical perspectives, cultural representations, and current applications in the field of children's literature. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "D166": {
          "courseName": "Foundations of Education ",
          "description": "Foundations of Education is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to foundational knowledge about the teaching profession in the current educational context and the historical and cultural influences on P-12 education in the United States. This course addresses important topics that affect educators today including state standards-based curriculum, legal and ethical requirements, and professionalism. This course will culminate in evidence-based, practical application of current strategies, theories, or philosophical perspectives related to becoming an effective educator within the current school context. Candidates will engage in five hours of preclinical experiences, which include virtual observations of learning environments in multiple school settings, and an interview with an educator to gain insight on how these topics affect and inform teaching practice. Cross-cutting themes of technology and diversity are introduced for further development throughout the candidate’s programs. "
        },
        "D172": {
          "courseName": "Assessing Student Learning ",
          "description": "Assessing Student Learning is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with methods and best practices for using assessment to monitor student progress and to evaluate the effectiveness of instruction. This course focuses on implementing a balanced approach to assessment using multiple assessment types such as formative, summative, standardized, and common assessments. Also covered are data literacy skills for interpreting and analyzing individual learner and classroom data to improve instruction and support academic success for all learners. The course will culminate in evidence-based, practical application of strategies for assessment practices in P-12 schools. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D291": {
          "courseName": "Learning Experience Design Foundations I ",
          "description": "Learning Experience Design Foundations I provides an introduction to the field of learning experience design (LxD) and the role of the learning experience designer, which combines best practices from the fields of instructional design and user experience design, with the goal of creating human centered, goal-oriented learning experiences. This first of two foundational courses introduces Design Thinking and instructional design models, processes, and approaches. This course demonstrates how learning theories and instructional frameworks can be applied to facilitate deep learning, motivation, and engagement. This course also teaches the process for analyzing learners and their needs, as well as defining the instructional problem and goals. There are no prerequisites for this learning experience design course.   "
        },
        "D292": {
          "courseName": "Learning Experience Design Foundations II ",
          "description": "Learning Experience Design Foundations II is the second of two foundational courses that provide the foundational knowledge and skills learning experience designers need to create human-centered, goal-oriented learning experiences. Continuing to the third, fourth, and final phases of the Design Thinking Process, this course teaches the process and importance of ideation as well as rapid prototyping. It includes techniques for creating e-learning storyboards, which communicate content plans and instructional design strategies and “look and feel” mockups, which incorporate visual design principles and usability best practices. Finally, this course introduces usability testing methods and provides guidelines for planning usability tests for e-learning solutions. Learning Experience Design Foundations I is a prerequisite for this course. "
        },
        "D293": {
          "courseName": "Assessment and Learning Analytics ",
          "description": "Assessment and Learning Analytics focuses specifically on applying assessment and learning analytics practices to gauge learner progress through e-learning products. This course is an introduction to assessment models, including competency and skills-based methods, as well as culturally responsive and Universal Design for Learning (UDL) approaches in assessment, rubric, and feedback design. Finally, this course introduces learning analytics, specifically how they can add an additional layer of validation and visibility on learner progress. Learning Experience Design Foundations II is a prerequisite for this course. "
        },
        "D294": {
          "courseName": "Learning Technology ",
          "description": "Learning Technology provides opportunities for learners to research emerging learning technologies and see how they are changing current teaching and learning practices. This course also teaches strategies for evaluating learning technologies and their ability to facilitate deep learning and help learners achieve their learning goals, as well as their ability to accommodate learner differences and ensure access for all learners. This course covers techniques that learning experience designers can use to implement technology safely, legally, and ethically in a variety of environments. Additionally, this course explores the types of learning analytics that various technologies generate and the ways in which they can be used to better understand learner progress and optimize the learning experience. Assessment and Learning Analytics is a prerequisite for this course. "
        },
        "D295": {
          "courseName": "Designing and Facilitating E-Learning Experiences for K–12 Students ",
          "description": "Designing and Facilitating E-Learning Experiences for K–12 Students is the first of two courses in the K-12 Learning Designer pathway. This course teaches skills needed to plan units of study that leverage virtual settings and achieve academic standards while promoting digital citizenship. This course provides strategies for explaining essential concepts and demonstrating examples for students in K–12 virtual settings. It also provides strategies for using technology to facilitate meaningful collaboration among K–12 students. Finally, this course explains how to design effective practice and assessment opportunities for K–12 students in virtual settings and provides strategies for ensuring students get the feedback they need to improve learning. Learning Technology is a prerequisite for this course. "
        },
        "D296": {
          "courseName": "Quality and Impact of K–12 E-Learning Solutions ",
          "description": "Quality and Impact of K–12 E-Learning Solutions is the second of two courses in the K– 12 Learning Designer pathway. This course provides an introduction to the challenges K–12 students face in e-learning environments. It also directs learners to professional and academic resources where they can find current research related to issues and innovations learning experience designers implement to solve challenges to K–12 students in e-learning environments. This course also outlines a quality framework for evaluating e-learning solutions for K–12 students and provides opportunities for learners to apply that framework. Lastly, this course provides examples of how learning analytics can be used to determine the impact of e-learning for K–12 students. Through this course, learners will analyze data about K–12 learners to determine the impact an e-learning solution has had on engagement, effort, and learning. This course teaches learners how insights gained from data about K–12 learners can be used to optimize e-learning. Designing E-Learning Experiences for K–12 students is a prerequisite for this course. "
        },
        "D297": {
          "courseName": "Designing E-Learning Experiences for Adults ",
          "description": "Designing E-Learning Experiences for Adults is the first of two courses in the adult learning designer pathway. This course teaches best practices for supporting adult learners as they acquire knowledge and learn new skills and dispositions. This course explains effective approaches to designing learning experiences for adult learners that are collaborative, experiential, and transformative in nature. This course also explores problem-based and competency-based approaches to designing learning experiences for adults. Each evidencebased approach is defined and supported by theory and research. The course also includes best practices for designing each type of learning experience and provides real examples of each approach. Learning Technology is a prerequisite for this course. "
        },
        "D298": {
          "courseName": "Quality and Impact of Adult E-Learning Solutions ",
          "description": "Quality and Impact of Adult E-Learning Solutions is the second of two courses in the Adult Learning Designer pathway. This course introduces the issues learning experience designers often encounter when designing e-learning experiences for adults. It also directs learners to resources about current research related to issues and innovations in designing online learning experiences for adults. This course also outlines a quality framework for evaluating e-learning solutions for adults and provides opportunities for learners to apply that framework. Lastly, this course provides examples of how learning analytics can be used to determine the impact of e-learning solutions for adults. Learners will analyze dashboard data and determine the impact an e-learning solution has had on learner engagement, effort, and learning and how insights gained from data about learners and the learning experience can be used to optimize learning and the environments in which it occurs. Designing E-Learning Experiences for Adults is a prerequisite for this course. "
        },
        "D299": {
          "courseName": "Learning Experience Design Lab ",
          "description": "Learning Experience Design Lab requires learners to apply foundational learning experience design strategies to create an instructional solution in the form of an e-learning module. In the course, learners will identify an instructional problem and then design and develop a functional prototype of an e-learning solution. Learning Experience Design Lab provides an environment for learners to apply foundational knowledge and skills, experiment with various e-learning design tools and techniques, provide helpful quality feedback to peers, and receive quality feedback from peers about their own e-learning module. Finally, Learning Experience Design Lab teaches the importance of obtaining user feedback and incorporating that feedback to continuously improve the learning experience. Degree-seeking learners must complete the Learning Experience Design foundations series and two pathway courses prior to completing this course. "
        },
        "D300": {
          "courseName": "Identifying Learner Needs and a Research Problem ",
          "description": "Identifying Learner Needs and a Research Problem is the first of three capstone courses in the MSLxDET program. This course provides an introduction to design-based research and focuses specifically on the first two phases of the design-based research process: identifying and analyzing the learning problem and reviewing the literature. This course also requires that learners continue applying Design Thinking as they empathize with learners and define the instructional problem that their research will help them understand and address. Finally, this course teaches learners how to conduct a literature review to determine what research has already been done and what is unknown about their research topic. Learning Experience Design Lab is a prerequisite for this course. "
        },
        "D301": {
          "courseName": "Developing an E-Learning Solution and Research Methodology ",
          "description": "Developing an E-Learning Solution and Research Methodology is the second of three capstone courses in the MSLxDET program. This course focuses on the next two phases of the design-based research process: designing and developing an e-learning solution and designing a research methodology to test how well the solution addressed the instructional problem. This course also requires that learners continue applying Design Thinking as they ideate potential solutions to the instructional problem and begin prototyping a module of instruction. Finally, this course teaches learners how to design research studies that ensure the safety of human subjects and the ethical collection, storage, and reporting of data. The course Identifying Learner Needs and a Research Problem is a prerequisite for this course. "
        },
        "D302": {
          "courseName": "Implementing and Evaluating E-Learning Solutions ",
          "description": "Implementing and Evaluating E-Learning Solutions is the third of three capstone courses in the MSLxDET program. This course focuses on the final steps of the Design-Based Research process: implement, test, refine, reflect, and report. This course also requires that learners continue applying Design Thinking as they test and refine the solution identified during the prototyping phase. The course requires learners to test and refine their implementation strategies, use data to evaluate the effectiveness of their e-learning solution, redesign or enhance their e-learning design based on their interpretation of the data, and summarize their design-based action research study. Developing an E-Learning Solution and Research Methodology is a prerequisite for this course. "
        },
        "D303": {
          "courseName": "Azure Fundamentals ",
          "description": "Azure Fundamentals provides the learner with skills needed to describe the following concepts: cloud concepts; core Azure services; core solutions and management tools on Azure; general security and network security features; identity, governance, privacy, and compliance features; and Azure cost management and Service Level Agreements. Learners will gain foundational knowledge of cloud services and how those services are provided with Microsoft Azure. This course is intended for students who are just beginning to work with cloud-based solutions and services or are new to Azure. Competency in this course is demonstrated by successfully completing the Microsoft Azure Fundamentals certification exam (AZ-900). There are no prerequisites to this course. "
        },
        "D304": {
          "courseName": "Azure DevOps Solutions ",
          "description": "Azure DevOps Solutions provides the learner with skills to accomplish the following technical tasks: Designing and implementing strategies for collaboration, code, infrastructure, source control, security, compliance, continuous integration, testing, delivery, monitoring, and feedback. This course expects candidates to have intermediate-level skills for administering Azure and understand Azure development and DevOps processes. The following courses are prerequisites: Networks and Security—Foundations; Networks; Networks and Security—Applications; Cloud Foundations; Cloud Platform Solutions; Azure Fundamentals; and Azure Developer Associate "
        },
        "D305": {
          "courseName": "Azure Data Engineer ",
          "description": "Azure Data Engineer prepares the learner for integrating, transforming, and consolidating data from various structured and unstructured data systems into structures that are suitable for building analytics solutions. Learners will be provided with skills to accomplish the following technical tasks: design and implement data storage, design and develop data processing, design and implement data security, and monitor and optimize data storage and data processing. Candidates must have solid knowledge of data processing languages, such as SQL, Python, or Scala, and they need to understand parallel processing and data architecture patterns. The following courses are prerequisites: Introduction to Programming in Python, Azure Fundamentals, and Azure Developer Associate. "
        },
        "D306": {
          "courseName": "Azure Developer Associate ",
          "description": "Azure Developer Associate provides the learner with subject matter knowledge in designing, building, testing, and maintaining cloud applications and services on Microsoft Azure. Learners will be provided with the ability to program in a language supported by Azure and proficiency in Azure SDKs, Azure PowerShell, Azure CLI, data storage options, data connections, APIs, app authentication and authorization, compute and container deployment, debugging, performance tuning, and monitoring. The following course is a prerequisite: Azure Fundamentals. "
        },
        "D314": {
          "courseName": "Essentials of Academic Writing ",
          "description": "The learner will explore professional communication by applying the principles of academic writing to their discipline. Learners will incorporate these skills into the development of an evidence-based scholarly paper in their specialty area. As learners develop a scholarly paper, they will acquire a deeper understanding of the research topic selected and analyze whether initiatives and interventions have been effective or ineffective. "
        },
        "D343": {
          "courseName": "Foundations of Advanced Psychiatric Mental Health Practice ",
          "description": "Foundations of Advanced Psychiatric Mental Health Practice guides students through the process of learning about mental health and mental illness. This course presents the history of psychiatric care, along with cultural components that influence individual attitudes and behaviors. This course introduces conceptual models and theories related to practice that provide the basis for understanding the development of psychopathology to apply appropriate therapeutic strategies. This course includes clinical practice guidelines using the DSM-5-TR (Diagnostic Statistical Manual of Mental Disorders) as a basis for diagnostic consistency across the lifespan. This course also includes relevant advanced practice issues, legal and ethical components, and barriers to practice that a mental health psychiatric nurse practitioner may encounter. Various psychological responses to stress are also discussed. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D344": {
          "courseName": "The Assessment and Diagnostic Process of Psychiatric Nurse Practitioner Practice ",
          "description": "The Assessment and Diagnostic Processes for Advanced Psychiatric Mental Health Practice guides students when examining determinants to the role of the psychiatric mental health nurse practitioner. This course guides students in building a therapeutic relationship with patients through interviewing skills, conducting a structured assessment, milieu, types of therapy, and various care strategies, including technology usage. This course guides students through exploring their leadership role in collaborating with the interprofessional community as a mental health nurse practitioner. Pathways of quality improvement, practice evaluation, and healthcare reform are also considered. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D345": {
          "courseName": "Psychopharmacology for Advanced Psychiatric Mental Health Practice ",
          "description": "Psychopharmacology for Advanced Psychiatric Mental Health Practice explains the knowledge of advanced pharmacotherapeutics and why it is important to safely and appropriately prescribe agents to manage common chronic and acute mental health problems of diverse populations. This includes differences between experimental and clinical psychopharmacology. This course covers the principles of pharmacokinetics and pharmacodynamics in administration, along with patient education. This course discusses factors of addiction and substance use, including prevalence, clinical manifestations, and treatment of various disorders. Collaborative clinical services, such as group counseling, therapeutic communities, and medication support, are explored. The foundational information in psychopharmacology in this course guides students in planning individualized mental health drug management for individuals across the life span based on setting, context, and professional ethics. The following courses are prerequisites: all MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D346": {
          "courseName": "Advanced Psychiatric Mental Health Care of Adults and Older Adults Across Care Settings ",
          "description": "Advanced Psychiatric Care of Adults and Older Adults Across Care Settings prepares students to provide evidence-based mental healthcare for adults, older adults, and families. This course guides students through the application of age and developmentally appropriate advanced practice health assessment knowledge and diagnostic reasoning skills for adults, older adults, and families experiencing complex mental health issues. This course helps students develop treatment plans using psychotherapeutic treatment modalities, psychopharmacology, and community resources to manage specific mental health disorders for adults, older adults, and families. This course also includes the influences of family dynamics and societal norms on mental health progression and recovery. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D347": {
          "courseName": "Advanced Psychiatric Mental Health Care of Children and Adolescents Across Care Settings ",
          "description": "Advanced Psychiatric Mental Health Care of Children and Adolescents across Care Settings prepares students to provide evidence-based mental healthcare for children, adolescents, and families. This course guides students through the application of age and developmentally appropriate advanced practice health assessment knowledge and diagnostic reasoning skills for children, adolescents, and families experiencing complex mental health issues. This course helps students develop treatment plans to manage specific mental health disorders through the use of psychotherapeutic treatment modalities, psychopharmacology, and community resources. This course also covers the influences of family dynamics and societal norms on mental health progression and recovery. The following are prerequisites for this course: all MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D348": {
          "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship I ",
          "description": "Through precepted clinical experiences, the learner will develop the competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course.   "
        },
        "D349": {
          "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship II ",
          "description": "Through precepted clinical experiences, the learner will develop competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course. "
        },
        "D350": {
          "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship III ",
          "description": "Through precepted clinical experiences, the learner will develop competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course. "
        },
        "D378": {
          "courseName": "Digital Marketing Science ",
          "description": "Digital Marketing Science provides learners with knowledge in the dynamic and evolving field of marketing that uses the internet and digital technologies to reach customers. This course provides foundational knowledge in digital marketing disciplines, including social media marketing, email marketing, content marketing, marketing automation, search engine optimization (SEO), paid search (PPC), conversion rate optimization (CRO), mobile marketing, and web analytics. Learners explore how a digital marketing strategy is developed and executed, along with how digital marketing is integrated within an organization's overall strategy. This course provides learners with the opportunity to complete the HubSpot Marketing Software certification to learn marketing automation software that is used in industry to assist marketers in their digital marketing execution. Emphasis throughout the course is placed on industry best practices, and the course content aligns with the Online Marketing Certified Associate (OMCA) certification to prepare students for this third-party certification. "
        },
        "D382": {
          "courseName": "Digital Marketing Analytics ",
          "description": "Digital Marketing Analytics teaches learners how to identify data sources, collect and analyze data, and manage marketing performance. Marketing requires an understanding of analytics and application of data to inform strategic decision-making. Through the use of a digital marketing analytics framework, measurement models, and various digital marketing technology tools, learners will analyze marketing performance across digital paid, owned, and earned channels. The latest marketing technology tools are explored to measure and optimize results using data-driven decisions. In this course, learners will have the opportunity to complete the Google Analytics certification to gain technical skills used in practice and to enhance marketability within the marketing profession. "
        },
        "D383": {
          "courseName": "Search Engine Optimization ",
          "description": "Search Engine Optimization, otherwise known as SEO, teaches on-page, off-page, and technical aspects of SEO for organically improving ranking and awareness. Digital marketing requires marketers to understand online consumer search behaviors; search engine optimization (SEO) is a key part of an organization’s digital marketing strategy. This includes processes and best practices used to increase their visibility in search engines. Learners will discover SEO strategies focusing on website structure, search engines, keyword research and mapping, and page-level optimization. Learners will gain practical experience using a simulation in which they will optimize ranking and visibility to consumers. In this course, learners will have the opportunity to complete HubSpot’s SEO certification to learn industry best practices and to enhance marketability within the marketing profession. "
        },
        "D412": {
          "courseName": "Network Analytics and Troubleshooting ",
          "description": "Network Analytics and Troubleshooting teaches students to use network monitoring and analytics tools and practices that are common in the workplace in order to troubleshoot and fix complex computer networks. Students will follow a customer service model in identifying, classifying, investigating, and repairing network outages or problems. This course is designed as a hands-on experience where students will implement these techniques in a virtual space in order to produce a secure and functional deployed network. "
        },
        "D413": {
          "courseName": "Telecomm and Wireless Communications ",
          "description": "Telecomm and Wireless Communications explores the science, technologies, and standards that enable wired and wireless data to be transmitted across different media. Topics include data encoding and decoding, and analog and digital transmissions via wired, fiber, wireless, cellular, and satellite technologies. "
        },
        "D414": {
          "courseName": "Cyber Operations Fundamentals ",
          "description": "In Cyber Operations Fundamentals, students will learn security concepts, security monitoring, host-based analysis, network intrusion analysis, and security policies and procedures using Cisco practices and technologies. This course prepares students for the Understanding Cisco Cybersecurity Operations Fundamentals (CBROPS) 200-201 exam.  "
        },
        "D415": {
          "courseName": "Software Defined Networking ",
          "description": "Software-Defined Networking (SDN) represents one of the fastest growing areas of network engineering. This course instructs learners on the SDN paradigm, which encompasses network automation, intent-based networking, and centralized network control. This course also teaches learners to view networking from a centralized and automated perspective rather than the traditional device-by-device model that is the legacy practice in many networks. "
        },
        "D416": {
          "courseName": "DevNet Fundamentals ",
          "description": "The DevNet Fundamentals course teaches students how to automate and deploy network solutions in the Cisco Environment. This course includes APIs, Scripting, Python programming, and software version control. This course prepares students for the Cisco DevNet Associate (DevNet) 200-901 exam. "
        },
        "D417": {
          "courseName": "Network Automation and Deployment ",
          "description": "Network Automation and Deployment leverages previous experience in networking, scripting, and programming with the SDN paradigm. Students will create programs and scripts that automate network configuration across large networks. This course is designed as a hands-on experience where students will implement these techniques in a virtual space in order to produce a secure and functional deployed network. "
        },
        "D418": {
          "courseName": "BSNES Capstone Project ",
          "description": "The BSNES Capstone Project consists of learners submitting a network design proposal, a virtual network implementation, and a post-implementation report describing their experience developing and implementing the capstone project. The capstone project and scope must be presented and approved by the capstone instructor prior to implementation in the virtual environment.  "
        },
        "D419": {
          "courseName": "Implementing and Administering Networking Solutions ",
          "description": "Implementing and Administering Networking Solutions expands on basic networking concepts and covers advanced network engineering skills including: Switch and router configuration, trouble shooting and maintenance on wired and wireless networks, Security, network automation and introduces Software Defined Networking. This course prepares students for the Cisco Certified Network Associate (CCNA) certification exam CCNA-200-301. "
        },
        "D422": {
          "courseName": "Discrete Math: Algorithms and Cryptography ",
          "description": "Discrete Math: Algorithms and Cryptography addresses discrete computational methods, including searching and sorting algorithms, big-O estimates, and number theory and cryptography. Discrete Math Functions and Relations is a prerequisite for this course. "
        },
        "D423": {
          "courseName": "Spreadsheets ",
          "description": "Introduction to Spreadsheets helps learners become proficient in using spreadsheets to analyze business problems. In this course, learners will demonstrate competency in spreadsheet development and analysis for business applications (e.g., using essential spreadsheet functions, formulas, tables, charts, etc.). There are no prerequisites for this course. "
        },
        "D425": {
          "courseName": "Introduction to Chemistry ",
          "description": "In Introduction to Chemistry, learners will discover the impact of chemistry on everyday life. They’ll learn about the structure of the atom, study periodic trends, analyze the structure of molecules and their properties, and describe the importance of common functional groups within the periodic table. They’ll identify balanced chemical equations, describe types of chemical reactions and predict their products, and examine intermolecular forces and describe their impact on the properties of substances. Finally, they’ll study the properties of acids, bases, and buffer systems, and properties unique to liquids and gases. "
        },
        "D439": {
          "courseName": "Foundations of Nursing  ",
          "description": "Foundations of Nursing introduces students to the nursing process, scope of practice, clinical judgment model and fundamental concepts of holistic nursing practice that will serve the needs of diverse adult patients across the lifespan. The course will focus on medical terminology, legal/ethical issues, basic care and comfort, oxygenation, safety & infection control, health and wellness, fluid & electrolytes, death and dying, therapeutic communication, patient education & advocacy.     Co-requisites: C957, D202, D236, and D441.    Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms. "
        },
        "D440": {
          "courseName": "Health and Wellness Through Nutritional Science ",
          "description": "The health and wellness through nutritional science course prepares nursing students to learn the basic principles of nutrition, nutrition throughout the life cycle, nutrition related to weight management and physical health, and nutrition related to patient conditions. Students will learn how nutrition influences a patient's overall health status across the life span. "
        },
        "D441": {
          "courseName": "Medical Dosage Calculations and Pharmacology  ",
          "description": "Medical Dosage Calculations and Pharmacology introduces an in-depth nursing approach to medication administration concepts, legal & ethical principles, pharmacological principles, variety of drug classifications, complementary & alternative therapies needed to care for diverse patients across the lifespan. "
        },
        "D442": {
          "courseName": "Basic Nursing Skills ",
          "description": "Basic Nursing Skills will introduce foundational principles of nursing process and the clinical judgement model, health assessment techniques, and communication skills needed to care for diverse adult patients across the lifespan. Skills will focus on the concepts of vital signs, medication administration, infection control, nutrition, elimination, mobility, oxygenation, and skin integrity. Students are required to be successful on lab assessments to progress to Adult Health1 clinical. Co-requisites: D443 and D444 "
        },
        "D443": {
          "courseName": "Health Assessment ",
          "description": "The Health Assessment course focuses on concepts and skills necessary to collect a comprehensive health history and perform a head-to-toe and focused assessments on diverse patients across the lifespan. The emphasis will be to differentiate between normal and abnormal findings of various body systems such as the following: integumentary, head & neck, eyes & ears, respiratory, cardiovascular, gastrointestinal, renal, musculoskeletal, nervous, and reproductive systems. Corequisites are D442 and D444. "
        },
        "D444": {
          "courseName": "Adult Health I ",
          "description": "Adult Health I prepares students to provide safe, equitable, high quality medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of common conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on medical surgical nursing care related to head & neck, skin, inflammation, infection, fluid & electrolytes, respiratory, gastrointestinal, hepatic, genitourinary, and reproductive systems. The nursing process and the clinical judgement model will be used as the foundation to navigate the management of care for patients. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisite: D443.   "
        },
        "D445": {
          "courseName": "Intermediate Nursing Skills ",
          "description": "Intermediate Nursing Skills will build on basic nursing skill concepts to develop intermediate medical surgical nursing practice, including peripheral intravenous access, blood administration, airway management, perioperative and postoperative care, and wound care management. The course focuses on nursing care of both adult and pediatric populations, including a focus on the care of women and the maternal care setting. Students will use simulation to apply the clinical judgment model to various diverse populations in various care settings. Students are required to be successful on course performance assessments to progress to Adult Health II clinical. Co-requisites: D446 & D447 "
        },
        "D446": {
          "courseName": "Adult Health II   ",
          "description": "Adult Health II prepares students to provide safe, equitable, high quality medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of both acute and chronic conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on medical surgical nursing care related to perioperative nursing, neurological, hematological, renal, cardiovascular, endocrine, and musculoskeletal systems. The nursing process and clinical judgement model will be used for clinical decision-making and fostering health promotion and maintenance. A variety of populations and settings are used in the experiential learning components of this course.      Co-requisite: D447.   Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms and D445. "
        },
        "D447": {
          "courseName": "Women's and Children's Nursing ",
          "description": "Women's and Children's Nursing prepares students to provide safe, equitable, high-quality nursing care, pharmacological care, and emotional support for diverse women and pediatric populations. This course focuses on antepartum, intrapartum, postpartum, neonatal clinical nursing, and women's health. This course builds on growth and development of children, nursing care for children and adolescents with acute and chronic alterations of the respiratory, cardiovascular, hematologic, endocrine, reproductive, gastrointestinal, renal, neurologic, musculoskeletal, and integumentary systems. This course explores how social determinants of health impact health risk and outcomes in women and pediatric populations. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisites: D445 and D446.   Prerequisites: All prelicensure nursing curriculum courses from previous terms. "
        },
        "D449": {
          "courseName": "Psychiatric and Mental Health Nursing ",
          "description": "Psychiatric and Mental Health Nursing prepares students to provide safe, equitable, high-quality care using modern concepts of psychiatric and mental health nursing. The student will utilize therapeutic communication to a diverse population of patients including those with maladaptive behaviors through the utilization of the nursing process by applying the principles of psychiatric and mental healthcare and the clinical judgement model. This course explores the nurse-client relationship, pharmacological management, cognitive conditions, bipolar and thought conditions, personality disorders, substance abuse, eating disorders, and self-harm. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisites: D450.   Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms and D445.   "
        },
        "D450": {
          "courseName": "Community Health and Population-Focused Nursing ",
          "description": "Community health and population focused nursing concentrates on the theory and concepts of community, public and global health nursing that impact diverse communities. Students learn the role of the community health nurse, learn to assess the community’s healthcare needs, available resources, epidemiology, substance abuse, disaster management, and how social determinants of health impact community and public health risk and outcomes. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisite: D449.   "
        },
        "D453": {
          "courseName": "Advanced Nursing Skills ",
          "description": "Advanced Nursing Skills will build on intermediate skills and focus on advanced skills related to critical care nursing practice, including closed chest drainage systems, electrocardiograms (EKGs), palliative care, ventilators, disaster management and transition to practice. Clinical judgement and problem solving are emphasized in the assessment of critically ill patients and prioritizing patients’ needs and nursing interventions. Students are required to be successful on course performance assessments to progress to Adult Health III clinical. Corequisites: D454 & D455 "
        },
        "D454": {
          "courseName": "Adult Health III ",
          "description": "Adult Health III prepares students to provide safe, equitable, high quality complex medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of both acute and chronic conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on caring for patients with potentially life-threatening alterations of the respiratory, cardiovascular, endocrine, and neurologic, renal, hepatic systems, end of life care (palliative), shock and transplants. Clinical judgment and problem solving are emphasized in the assessment of critically ill patients and prioritizing patient ‘s needs and nursing interventions. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisites: D455 "
        },
        "D455": {
          "courseName": "Professional Nursing Role Transition ",
          "description": "The Professional Nursing Role Transition course builds on the previous knowledge gained in all other nursing courses. The emphasis of this course is placed on the personal and professional strategies needed to make the transition from student to graduate nurse by highlighting the role and skills of bedside nurse leaders. The course will review content related to leadership and management, foundational nursing, advanced clinical, medical surgical, pediatric, women’s, and mental health concepts. The students will complete a professional portfolio that showcases their accomplishments, knowledge, and skills throughout the program. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisite: D454    "
        },
        "D481": {
          "courseName": "Security Foundations ",
          "description": "Security Foundations lays the foundation for understanding terminology, principles, processes, and information security best practices at local and global levels. This course further provides an overview of networking components, network security vulnerabilities, and countermeasures for protecting information assets through planning and administrative controls within an organization. "
        },
        "D482": {
          "courseName": "Secure Network Design ",
          "description": "Secure Network Design provides the foundational knowledge and skills to design secure physical and logical network architectures for wired and wireless networks. Course topics include the characteristics of a secure network, techniques to securely configure network devices, network segmentation strategies, root cause analysis, and mitigation approaches based on industry best practices. The course also offers hands-on experience in network vulnerability analysis and network configuration. "
        },
        "D483": {
          "courseName": "Security Operations ",
          "description": "Security Operations provides learners with the fundamental skills to handle and respond to computer security incidents in an information system. The course addresses various underlying principles and techniques for detecting and responding to current and emerging computer security threats. The course also helps learners explore strategies to leverage intelligence and threat detection techniques, analyze and interpret data, identify and address vulnerabilities, and suggest preventative measures. Methods are introduced to effectively respond to and recover from cybersecurity incidents, evaluate risk assessment methodologies, and apply incident handling laws and policies. "
        },
        "D484": {
          "courseName": "Penetration Testing ",
          "description": "Penetration Testing introduces learners to the skills necessary to perform penetration testing and vulnerability management within an organization. The course covers widely used penetration testing techniques and tools that focus on planning and scoping, information gathering, vulnerability identification, and attacks and exploits. In addition, it offers hands-on experience and a focus on penetration testing engagement plans. "
        },
        "D485": {
          "courseName": "Cloud Security ",
          "description": "Cloud Security prepares learners to design solutions for cloud-based platforms and operations that maintain data availability while protecting the confidentiality and integrity of information. Course topics include cloud service models, deployment methods, identity and access management (IAM) strategies, auditing and monitoring strategies, assessing and mitigating common cloud security threats, and managing compliance and regulation requirements. The course also offers hands-on experience deploying and assessing IAM controls in a cloud environment. "
        },
        "D486": {
          "courseName": "Governance, Risk, and Compliance ",
          "description": "Governance, Risk, and Compliance provides learners with advanced skills and knowledge to authorize and maintain information systems utilizing various risk management frameworks. The course focuses on the strategic and long-term alignment of an organization's information security program to regulatory requirements and organizational policies. Course topics include compliance and regulatory requirements, data classification and prioritization, security and privacy controls, compliance audits and remediation, and risk management plans. "
        },
        "D487": {
          "courseName": "Secure Software Design ",
          "description": "Secure Software Design focuses on the variety of elements needed to address and implement secure software acquisition and development throughout the software development life cycle (SDLC). The course addresses people, technology, tools, and processes to design and develop consistently secure applications from start to finish. Additionally, it underscores the importance and value of the Defense in Depth principle across the entire SDLC. The course also introduces techniques to adapt common security activities to modern software development practices such as Agile and DevSecOps. "
        },
        "D488": {
          "courseName": "Cybersecurity Architecture and Engineering ",
          "description": "Cybersecurity Architecture and Engineering provides learners with advanced skills and knowledge to design secure enterprise architecture solutions. The course focuses on assessing cybersecurity readiness and implementing enterprisewide solutions to protect data and comply with an organization's policies and frameworks. Course topics include integrating software applications, applying enterprise data security controls, evaluating cloud and virtualization solutions, analyzing threats and vulnerabilities, and responding to incidents. "
        },
        "D489": {
          "courseName": "Cybersecurity Management ",
          "description": "Cybersecurity Management prepares learners to develop organizational information security programs and policies that follow recognized standards, comply with all governing laws and regulations, and meet the needs of the company culture and management organization. The course covers how to perform risk management institutionally, how to manage compliance to information security requirements, and how to delegate compliance, risk, and security functions to specific roles within the organization. It also helps learners apply strategic decision-making as companies adapt to new technologies, processes, and people practices related to processing, managing, and protecting information resources. "
        },
        "D490": {
          "courseName": "Cybersecurity Graduate Capstone ",
          "description": "The Master of Science in Cybersecurity and Information Assurance (MSCSIA) Capstone project allows learners to demonstrate their capability to establish a durable cybersecurity and information assurance program. The capstone project challenges learners to integrate skills and knowledge from all program domains into one project that addresses a significant real-world cybersecurity problem. "
        },
        "D523": {
          "courseName": "Student Teaching I in Elementary Education ",
          "description": "Student Teaching I in Elementary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D524": {
          "courseName": "Student Teaching I in Elementary Education ",
          "description": "Student Teaching I in Elementary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D525": {
          "courseName": "Student Teaching II in Elementary Education ",
          "description": "Student Teaching II in Elementary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D526": {
          "courseName": "Student Teaching II in Elementary Education ",
          "description": "Student Teaching II in Elementary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D529": {
          "courseName": "Student Teaching I in Special Education ",
          "description": "Student Teaching I in Special Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D530": {
          "courseName": "Student Teaching I in Special Education ",
          "description": "Student Teaching I in Special Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D531": {
          "courseName": "Student Teaching II in Special Education ",
          "description": "Student Teaching II in Special Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D532": {
          "courseName": "Student Teaching II in Special Education ",
          "description": "Student Teaching II in Special Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D533": {
          "courseName": "Student Teaching I in Secondary Education ",
          "description": "Student Teaching I in Secondary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D534": {
          "courseName": "Student Teaching I in Secondary Education ",
          "description": "Student Teaching I in Secondary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D535": {
          "courseName": "Student Teaching II in Secondary Education ",
          "description": "Student Teaching II in Secondary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D536": {
          "courseName": "Student Teaching II in Secondary Education ",
          "description": "Student Teaching II in Secondary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "DPT2": {
          "courseName": "Physics: Electricity and Magnetism ",
          "description": "Physics: Electricity and Magnetism addresses principles related to the physics of electricity and magnetism. Students will study electric and magnetic forces and then apply that knowledge to the study of circuits with resistors and electromagnetic induction and waves. This course will focus on such topics as electric charge and electric field, electric currents and resistance, magnetism, electromagnetic induction and Faraday's law, and Maxwell's equation and electromagnetic waves. "
        },
        "DWP2": {
          "courseName": "Application of Elementary Social Studies Methods ",
          "description": "Application of Elementary Social Studies Methods helps students learn how to implement effective social studies instruction in the elementary classroom. Topics include social studies themes, promotion of cultural diversity, integrated social studies across the curriculum, social studies learning environments, assessment of social studies understanding, differentiated instruction for social studies, technology for social studies instruction, and standards-based social studies instruction. This course helps students apply, analyze, and reflect on effective elementary social studies instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "DZP2": {
          "courseName": "Application of Elementary Visual and Performing Arts Methods ",
          "description": "Application of Elementary Visual and Performing Arts Methods helps students learn how to implement effective visual and performing arts instruction in the elementary classroom. Topics include integrating arts across the curriculum, music education, visual arts, dance and movement, dramatic arts, differentiated instruction for visual and performing arts, and the promotion of cultural diversity through visual and performing arts instruction. This course helps students apply, analyze, and reflect on effective elementary visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "EBP2": {
          "courseName": "Application of Elementary Physical Education and Health Methods ",
          "description": "Applications of Elementary Physical Education and Health Methods helps students learn how to implement effective physical and health education instruction in the elementary classroom. Topics include healthy lifestyles, student safety, student nutrition, physical education, differentiated instruction for physical and health education, physical education across the curriculum, and public policy in health and physical education. This course helps students apply, analyze, and reflect on effective elementary visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "MFT2": {
          "courseName": "Mathematics (K-6) Portfolio Oral Defense ",
          "description": "Mathematics (K-6) Portfolio Oral Defense: Mathematics (K-6) Portfolio Defense focuses on a formal presentation. The student will present an overview of their teacher work sample (TWS) portfolio discussing the challenges they faced and how they determined whether their goals were accomplished. They will explain the process they went through to develop the TWS portfolio and reflect on the methodologies and outcomes of the strategies discussed in the TWS portfolio. Additionally, they will discuss the strengths and weaknesses of those strategies and how they can apply what they learned from the TWS portfolio in their professional work environment. "
        },
        "OOT2": {
          "courseName": "Mathematics History and Technology ",
          "description": "In Math History and Teaching, students will learn about a variety of technological tools for doing mathematics and develop a broad understanding of the historical development of mathematics. Mathematics is a very human subject that comes from the macro-level sweep of cultural and societal change as well as the micro-level actions of individuals with personal, professional, and philosophical motivations. This course will focus on the historical development of mathematics, including contributions of significant figures and diverse cultures. Students will learn to evaluate and apply technological tools and historical information to create an enriching student-centered mathematical learning environment. "
        },
        "OPT2": {
          "courseName": "Mathematics Learning and Teaching ",
          "description": "Mathematics Learning and Teaching will help students develop the knowledge and skills necessary to become a prospective and practicing educator. This course will help students use a variety of instructional strategies to effectively facilitate the learning of mathematics. It focuses on selecting appropriate resources, using multiple strategies, and instructional planning, with methods based on research and problem solving. A deep understanding of the knowledge, skills, and disposition of mathematics pedagogy is necessary to become an effective secondary mathematics educator. There are no prerequisites for this course. "
        },
        "PFIT": {
          "courseName": "Business ",
          "description": "IT Management Portfolio Requirement - Business - IT Management Portfolio Requirement is designed to help the learner complete the culminating Undergraduate Business Portfolio assessment; it focuses on developing a business portfolio containing a strengths essay, a career report, a reflection essay, a resume, and exhibits that support one’s strengths in the work place. "
        },
        "QDT2": {
          "courseName": "Abstract Algebra ",
          "description": "Abstract Algebra is the axiomatic and rigorous study of the underlying structure of algebra and arithmetic. It covers the knowledge and skills necessary to understand, apply, and prove theorems about numbers, groups, rings, and fields. Topics include the well-ordering principle, equivalence classes, the division algorithm, Euclid's algorithm, prime factorization, greatest common divisor, least common multiple, congruence, the Chinese remainder theorem, modular arithmetic, rings, integral domains, fields, groups, roots of unity, and homomorphisms. Linear Algebra is a prerequisite for this course. "
        },
        "QFT1": {
          "courseName": "Business ",
          "description": "IT Management Capstone Project - The capstone requires students to demonstrate the integration and synthesis of competencies in all domains required for the degree in Information Technology Management. The student produces a business plan for a start-up company that is selected and approved by the student and mentor. "
        },
        "QGT1": {
          "courseName": "Business Management Capstone Written Project ",
          "description": "For the Business Management Capstone Written Project students will integrate and synthesize competencies from across their degree program to demonstrate their ability to participate in and contribute value to their chosen professional field. A comprehensive business plan is developed for a company that plans to sell a product or service in a local market, national market, or on the Internet. The business plan includes a market analysis, financial statements and analysis, and specific strategic actions relevant to the chosen company. "
        },
        "QJT2": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in relation to the slope of a curve and covers the knowledge and skills necessary to use differential calculus of one variable and appropriate technology to solve basic problems. Topics include graphing functions and finding their domains and ranges; limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, and sum rules applied to polynomial and exponential functions, position and velocity; and L'Hopital's Rule. Candidates should have completed a course in Pre-Calculus before engaging in this course. "
        },
        "QTT2": {
          "courseName": "Finite Mathematics ",
          "description": "Finite Mathematics covers the knowledge and skills necessary to apply discrete mathematics and properties of number systems to model and solve real-life problems. Topics include sets and operations; prime and composite numbers; GCD and LCM; order of operations; ordering numbers; mathematical systems including modular arithmetic, arithmetic and geometric sequences, ratio and proportion, subsets of real numbers, logic and truth tables, graphs, and trees and networks. There are no prerequisites for this course. "
        },
        "RKT2": {
          "courseName": "Linear Algebra ",
          "description": "Linear Algebra is the study of the algebra of curve-free functions extended into three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply vectors, matrices, matrix theorems, and linear transformations and to use appropriate technology to model and solve real-life problems. It also covers properties of and proofs about vector spaces. Topics include linear equations and their matrix-vector representation Ax=b, row reduction, linear transformations and their matrix representations (shear, dilation, rotation, reflection), matrix operations, matrix inverses and invertible matrix characterizations, computing determinants, relating determinants to area and volume, and axiomatic and intuitive definitions of vector spaces and subspaces and how to prove theorems about them. College Geometry and Calculus II are prerequisites for this course. "
        },
        "RNT2": {
          "courseName": "General Physics ",
          "description": "This course provides a broad overview of the principles of mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism and invites students to apply them by solving problems, performing labs, and reflecting on concepts and ideas. "
        },
        "TOC2": {
          "courseName": "Probability and Statistics I ",
          "description": "Probability and Statistics I covers the knowledge and skills necessary to apply basic probability, descriptive statistics, and statistical reasoning and to use appropriate technology to model and solve real-life problems. It provides an introduction to the science of collecting, processing, analyzing, and interpreting data, including representations, constructions, and interpretation of graphical displays (e.g., box plots, histograms, cumulative frequency plots, scatter plots). Topics include creating and interpreting numerical summaries and visual displays of data; regression lines and correlation; evaluating sampling methods and their effect on possible conclusions; designing observational studies, controlled experiments, and surveys; and determining probabilities using simulations, diagrams, and probability rules. College Algebra is a prerequisite to this course. "
        },
        "TQC2": {
          "courseName": "Probability and Statistics II ",
          "description": "Probability and Statistics II covers the knowledge and skills necessary to apply random variables, sampling distributions, estimation, and hypothesis testing and to use appropriate technology to model and solve real-life problems. It provides tools for the science of analyzing and interpreting data and includes statistical variability and its sources and the role of randomness in statistical inference. Topics include discrete and continuous random variables, expected values, the central limit theorem, the identification of unusual samples, population parameters, point estimates, confidence intervals, influences on accuracy and precision, hypothesis testing and statistical tests (z mean, z proportion, one sample t, paired t, independent t, ANOVA, chi-squared, and significance of correlation). Calculus II and Probability and Stats I are prerequisites to this course. "
        }
      }

    // There are 531 courses in this list
    export const allCoursesObject = {
        "D322": {
          "courseID": "D322",
          "CCN": "ITEC 2002",
          "name": "Introduction to IT ",
          "CUs": "4"
        },
        "C955": {
          "courseID": "C955",
          "CCN": "MATH 1101",
          "name": "Applied Probability and Statistics ",
          "CUs": "3"
        },
        "D315": {
          "courseID": "D315",
          "CCN": "ITEC 2112",
          "name": "Network and Security - Foundations ",
          "CUs": "3"
        },
        "D278": {
          "courseID": "D278",
          "CCN": "ITSW 2113",
          "name": "Scripting and Programming - Foundations ",
          "CUs": "3"
        },
        "D426": {
          "courseID": "D426",
          "CCN": "ITEC 2116",
          "name": "Data Management - Foundations ",
          "CUs": "3"
        },
        "D276": {
          "CCN": "ITSW 2120",
          "courseID": "D276",
          "name": "Web Development Foundations",
          "description": "Introduces the fundamentals of web development, focusing on HTML, CSS, and basic web technologies.",
          "CUs": 3,
          "term": 2
        },
        "C958": {
          "CCN": "MATH 2100",
          "courseID": "C958",
          "name": "Calculus I",
          "description": "An introductory course in calculus, exploring the concepts of limits, derivatives, and integrals.",
          "CUs": 4,
          "term": 2
        },
        "D199": {
          "CCN": "GEOG 1312",
          "courseID": "D199",
          "name": "Introduction to Physical and Human Geography",
          "description": "Explores the physical aspects of the Earth and the human activities that shape our environment, emphasizing the relationship between nature and human society.",
          "CUs": 3,
          "term": 3
        },
        "C458": {
          "courseID": "C458",
          "CCN": "HLTH 1010",
          "name": "Health, Fitness, and Wellness ",
          "CUs": "4"
        },
        "C959": {
          "CCN": "MATH 2800",
          "courseID": "C959",
          "name": "Discrete Mathematics I",
          "description": "Introduces discrete mathematics, focusing on topics like logic, set theory, and combinatorics, foundational for computer science and engineering.",
          "CUs": 4,
          "term": 3
        },
        "D197": {
          "CCN": "ITSW 2110",
          "courseID": "D197",
          "name": "Version Control",
          "description": "Covers the basics of version control systems, essential for managing changes in digital projects, particularly in software development.",
          "CUs": 1,
          "term": 3
        },
        "C960": {
          "CCN": "MATH 2810",
          "courseID": "C960",
          "name": "Discrete Mathematics II",
          "description": "Continues from Discrete Mathematics I, delving deeper into topics such as graph theory, number theory, and algorithmic thinking.",
          "CUs": 4,
          "term": 4
        },
        "D268": {
          "courseID": "D268",
          "CCN": "COMM 3015",
          "name": "Introduction to Communication: Connecting with Others ",
          "CUs": "3"
        },
        "C952": {
          "CCN": "ICSC 3120",
          "courseID": "C952",
          "name": "Computer Architecture",
          "description": "Examines the structure and functioning of computer systems, including processors, memory, and input/output interfaces.",
          "CUs": 3,
          "term": 4
        },
        "D270": {
          "courseID": "D270",
          "CCN": "ENGL 1712",
          "name": "Composition: Successful Self-Expression ",
          "CUs": "3"
        },
        "D427": {
          "courseID": "D427",
          "CCN": "ITEC 2117",
          "name": "Data Management - Applications ",
          "CUs": "4"
        },
        "D198": {
          "courseNumber": "D198",
          "CCN": "HUMN 1020",
          "name": "Global Arts and Humanities",
          "description": "Provides an interdisciplinary overview of global artistic and humanistic traditions, examining their cultural, historical, and philosophical contexts.",
          "CUs": 3,
          "term": 5
        },
        "D286": {
          "courseNumber": "D286",
          "CCN": "ITSW 3172",
          "name": "Java Fundamentals",
          "description": "Covers the fundamental concepts and techniques of Java programming, focusing on object-oriented programming, data structures, and algorithm development.",
          "CUs": 3,
          "term": 5
        },
        "D287": {
          "courseNumber": "D287",
          "CCN": "ITSW 3173",
          "name": "Java Frameworks",
          "description": "Introduces popular Java frameworks and libraries, emphasizing their application in building robust and scalable software applications.",
          "CUs": 3,
          "term": 6
        },
        "C949": {
          "courseNumber": "C949",
          "CCN": "ICSC 2100",
          "name": "Data Structures and Algorithms I",
          "description": "Examines fundamental data structures and algorithms, focusing on their implementation, efficiency, and application in solving computational problems.",
          "CUs": 4,
          "term": 6
        },
        "D288": {
          "courseNumber": "D288",
          "CCN": "ITSW 3175",
          "name": "Back-End Programming",
          "description": "Focuses on server-side programming concepts and technologies, covering topics such as database connectivity, API development, and server scripting.",
          "CUs": 3,
          "term": 6
        },
        "D281": {
          "courseID": "D281",
          "CCN": "ITEC 3004",
          "name": "Linux Foundations ",
          "CUs": "3"
        },
        "D387": {
          "courseNumber": "D387",
          "CCN": "ITSW 3024",
          "name": "Advanced Java",
          "description": "Explores advanced concepts in Java programming, including multi-threading, networking, and GUI development, with a focus on enterprise-level applications.",
          "CUs": 3,
          "term": 7
        },
        "D333": {
          "courseID": "D333",
          "CCN": "HUMN 1101",
          "name": "Ethics in Technology ",
          "CUs": "3"
        },
        "D430": {
          "courseID": "D430",
          "CCN": "ITAS 2110",
          "name": "Fundamentals of Information Security ",
          "CUs": "3"
        },
        "C191": {
          "courseNumber": "C191",
          "CCN": "ITEC 2211",
          "name": "Operating Systems for Programmers",
          "description": "Focuses on the principles of operating systems with an emphasis on features important for programming, such as process management and file systems.",
          "CUs": 3,
          "term": 7
        },
        "C950": {
          "courseNumber": "C950",
          "CCN": "ICSC 3100",
          "name": "Data Structures and Algorithms II",
          "description": "Continues from Data Structures and Algorithms I, covering more complex algorithms and data structures, with a focus on algorithmic strategies and efficiency.",
          "CUs": 4,
          "term": 8
        },
        "C683": {
          "courseID": "C683",
          "CCN": "SCIE 1001",
          "name": "Natural Science Lab ",
          "CUs": "2"
        },
        "D336": {
          "courseID": "D336",
          "CCN": "ITEC 2113",
          "name": "Business of IT – Applications ",
          "CUs": "4"
        },
        "D284": {
          "courseNumber": "D284",
          "CCN": "ITSW 2226",
          "name": "Software Engineering",
          "description": "Covers the principles of software engineering, including software development life cycles, design patterns, and best practices in software design and testing.",
          "CUs": 4,
          "term": 8
        },
        "D326": {
          "courseNumber": "D326",
          "CCN": "DTMG 3179",
          "name": "Advanced Data Management",
          "description": "Delves into advanced topics in data management, including big data analytics, data integration, and the management of large-scale databases.",
          "CUs": 3,
          "term": 9
        },
        "D370": {
          "courseNumber": "D370",
          "CCN": "ITBU 2201",
          "name": "IT Leadership Foundations",
          "description": "Explores the foundational skills and concepts required for leadership roles in IT, including strategic planning, team management, and IT governance.",
          "CUs": 3,
          "term": 9
        },
        "D339": {
          "courseNumber": "D339",
          "CCN": "COMM 1115",
          "name": "Technical Communication",
          "description": "Focuses on the skills needed for effective communication in technical fields, covering technical writing, presentation skills, and communication strategies.",
          "CUs": 3,
          "term": 9
        },
        "C951": {
          "courseNumber": "C951",
          "CCN": "ICSC 3110",
          "name": "Introduction to Artificial Intelligence",
          "description": "Provides an overview of artificial intelligence concepts and applications, including machine learning, neural networks, and AI in problem-solving.",
          "CUs": 3,
          "term": 9
        },
        "D480": {
          "courseNumber": "D480",
          "CCN": "ITSW 3151",
          "name": "Software Design and Quality Assurance",
          "description": "Covers software design principles and quality assurance practices, focusing on creating reliable and maintainable software systems.",
          "CUs": 3,
          "term": 10
        },
        "C964": {
          "courseNumber": "C964",
          "CCN": "ICSC 3130",
          "name": "Computer Science Capstone",
          "description": "A culminating project that integrates and applies computer science knowledge and skills in a real-world scenario or research topic.",
          "CUs": 4,
          "term": 10
        },
        "D265": {
          "courseID": "D265",
          "CCN": "PHIL 1020",
          "name": "Critical Thinking: Reason and Evidence ",
          "CUs": "3"
        },
        "D317": {
          "courseID": "D317",
          "CCN": "ITEC 2023",
          "name": "IT Applications ",
          "CUs": "4"
        },
        "D316": {
          "courseID": "D316",
          "CCN": "ITEC 2013",
          "name": "IT Foundations ",
          "CUs": "4"
        },
        "C841": {
          "courseID": "C841",
          "CCN": "ITAS 3010",
          "name": "Legal Issues in Information Security ",
          "CUs": "4"
        },
        "D372": {
          "courseID": "D372",
          "CCN": "PHIL 1030",
          "name": "Introduction to Systems Thinking ",
          "CUs": "3"
        },
        "C957": {
          "courseID": "C957",
          "CCN": "MATH 1200",
          "name": "Applied Algebra ",
          "CUs": "3"
        },
        "D325": {
          "courseID": "D325",
          "CCN": "ITEC 3602",
          "name": "Networks ",
          "CUs": "4"
        },
        "C844": {
          "courseID": "C844",
          "CCN": "ITAS 3040",
          "name": "Emerging Technologies in Cybersecurity ",
          "CUs": "4"
        },
        "D329": {
          "courseID": "D329",
          "CCN": "ITEC 2112",
          "name": "Network and Security - Applications ",
          "CUs": "4"
        },
        "D431": {
          "courseID": "D431",
          "CCN": "ITAS 2140",
          "name": "Digital Forensics in Cybersecurity ",
          "CUs": "4"
        },
        "C845": {
          "courseID": "C845",
          "CCN": "ITAS 3050",
          "name": "Information Systems Security ",
          "CUs": "4"
        },
        "D334": {
          "courseID": "D334",
          "CCN": "ITAS 2141",
          "name": "Introduction to Cryptography ",
          "CUs": "4"
        },
        "D324": {
          "courseID": "D324",
          "CCN": "ITEC 2109",
          "name": "Business of IT - Project Management ",
          "CUs": "4"
        },
        "D335": {
          "courseID": "D335",
          "CCN": "ITSW 3126",
          "name": "Introduction to Programming in Python ",
          "CUs": "3"
        },
        "C963": {
          "courseID": "C963",
          "CCN": "POLS 1030",
          "name": "American Politics and the US Constitution ",
          "CUs": "3"
        },
        "C843": {
          "courseID": "C843",
          "CCN": "ITAS 3030",
          "name": "Managing Information Security ",
          "CUs": "6"
        },
        "D340": {
          "courseID": "D340",
          "CCN": "ITAS 3021",
          "name": "Cyber Defense and Countermeasures ",
          "CUs": "4"
        },
        "D320": {
          "courseID": "D320",
          "CCN": "ITCL 3202",
          "name": "Managing Cloud Security ",
          "CUs": "4"
        },
        "D332": {
          "courseID": "D332",
          "CCN": "ITAS 3080",
          "name": "Penetration Testing and Vulnerability Analysis ",
          "CUs": "4"
        },
        "C769": {
          "courseID": "C769",
          "CCN": "ITEC 4903",
          "name": "IT Capstone Written Project ",
          "CUs": "4"
        },
        "D491": {
          "courseID": "D491",
          "CCN": "DTAN 3100",
          "name": "Introduction to Analytics ",
          "CUs": "2"
        },
        "D388": {
          "courseID": "D388",
          "CCN": "BUS 2250",
          "name": "Fundamentals of Spreadsheets and Data Presentations ",
          "CUs": "3"
        },
        "C721": {
          "courseID": "C721",
          "CCN": "MGMT 4400",
          "name": "Change Management ",
          "CUs": "3"
        },
        "D428": {
          "courseID": "D428",
          "CCN": "PHIL 1110",
          "name": "Design Thinking for Business ",
          "CUs": "3"
        },
        "D269": {
          "courseID": "D269",
          "CCN": "ENGL 1711",
          "name": "Composition: Writing with a Strategy ",
          "CUs": "3"
        },
        "D282": {
          "courseID": "D282",
          "CCN": "ITEC 2119",
          "name": "Cloud Foundations ",
          "CUs": "3"
        },
        "D386": {
          "courseID": "D386",
          "CCN": "ITEC 2022",
          "name": "Hardware and Operating Systems Essentials ",
          "CUs": "3"
        },
        "D492": {
          "courseID": "D492",
          "CCN": "DTAN 3200",
          "name": "Data Analytics - Applications ",
          "CUs": "4"
        },
        "D420": {
          "courseID": "D420",
          "CCN": "MATH 2820",
          "name": "Discrete Math: Logic ",
          "CUs": "1"
        },
        "D421": {
          "courseID": "D421",
          "CCN": "MATH 2830",
          "name": "Discrete Math: Functions and Relations ",
          "CUs": "1"
        },
        "D493": {
          "courseID": "D493",
          "CCN": "ITSW 2135",
          "name": "Scripting and Programming - Applications ",
          "CUs": "3"
        },
        "D494": {
          "courseID": "D494",
          "CCN": "DTMG 3351",
          "name": "Data and Information Governance ",
          "CUs": "2"
        },
        "D495": {
          "courseID": "D495",
          "CCN": "DTAN 3205",
          "name": "Big Data Foundations ",
          "CUs": "4"
        },
        "D246": {
          "courseID": "D246",
          "CCN": "COMM 1721",
          "name": "Influential Communication through Visual ",
          "CUs": "Design"
        },
        "": {
          "courseID": "",
          "CCN": "4 8",
          "name": "",
          "CUs": ""
        },
        "D496": {
          "courseID": "D496",
          "CCN": "DTSC 3211",
          "name": "Introduction to Data Science ",
          "CUs": "4"
        },
        "D497": {
          "courseID": "D497",
          "CCN": "DTMG 3221",
          "name": "Data Wrangling ",
          "CUs": "3"
        },
        "D498": {
          "courseID": "D498",
          "CCN": "DTAN 3211",
          "name": "Data Analysis with R ",
          "CUs": "2"
        },
        "D499": {
          "courseID": "D499",
          "CCN": "DTSC 3221",
          "name": "Machine Learning ",
          "CUs": "3"
        },
        "D500": {
          "courseID": "D500",
          "CCN": "DTAN 3221",
          "name": "Data Visualization ",
          "CUs": "2"
        },
        "D501": {
          "courseID": "D501",
          "CCN": "DTSC 3300",
          "name": "Machine Learning DevOps ",
          "CUs": "2"
        },
        "D502": {
          "courseID": "D502",
          "CCN": "DTMG 3901",
          "name": "Data Analytics Capstone ",
          "CUs": "4"
        },
        "C165": {
          "courseID": "C165",
          "CCN": "SCIE 1020",
          "name": "Integrated Physical Sciences ",
          "CUs": "3"
        },
        "C483": {
          "courseID": "C483",
          "CCN": "BUS 2301",
          "name": "Principles of Management ",
          "CUs": "4"
        },
        "C484": {
          "courseID": "C484",
          "CCN": "BUS 2001",
          "name": "Organizational Behavior and Leadership ",
          "CUs": "3"
        },
        "C268": {
          "courseID": "C268",
          "CCN": "BUIT 2200",
          "name": "Spreadsheets ",
          "CUs": "3"
        },
        "C277": {
          "courseID": "C277",
          "CCN": "MATH 1709",
          "name": "Finite Mathematics ",
          "CUs": "4"
        },
        "C777": {
          "courseID": "C777",
          "CCN": "ITWD 3120",
          "name": "Web Development Applications ",
          "CUs": "6"
        },
        "C773": {
          "courseID": "C773",
          "CCN": "ITWD 3110",
          "name": "User Interface Design ",
          "CUs": "4"
        },
        "C724": {
          "courseID": "C724",
          "CCN": "BUIT 3000",
          "name": "Information Systems Management ",
          "CUs": "3"
        },
        "C850": {
          "courseID": "C850",
          "CCN": "ITEC 2950",
          "name": "Emerging Technologies ",
          "CUs": "2"
        },
        "D277": {
          "courseID": "D277",
          "CCN": "ITSW 2131",
          "name": "Front-End Web Development ",
          "CUs": "3"
        },
        "D280": {
          "courseID": "D280",
          "CCN": "ITSW 3151",
          "name": "JavaScript Programming ",
          "CUs": "3"
        },
        "D279": {
          "courseID": "D279",
          "CCN": "ITSW 3110",
          "name": "User Interface Design ",
          "CUs": "3"
        },
        "D479": {
          "courseID": "D479",
          "CCN": "ITSW 3111",
          "name": "User Experience Design ",
          "CUs": "3"
        },
        "D385": {
          "courseID": "D385",
          "CCN": "ITEC 2034",
          "name": "Software Security and Testing ",
          "CUs": "3"
        },
        "D308": {
          "courseID": "D308",
          "CCN": "ITSW 3034",
          "name": "Mobile Application Development (Android) ",
          "CUs": "3"
        },
        "D424": {
          "courseID": "D424",
          "CCN": "ITEC 4906",
          "name": "Software Engineering Capstone ",
          "CUs": "4"
        },
        "C190": {
          "courseID": "C190",
          "CCN": "BIO 1010",
          "name": "Introduction to Biology ",
          "CUs": "3"
        },
        "D318": {
          "courseID": "D318",
          "CCN": "ITEC 3601",
          "name": "Cloud Applications ",
          "CUs": "3"
        },
        "D330": {
          "courseID": "D330",
          "CCN": "DBMG 3380",
          "name": "Data Systems Administration ",
          "CUs": "3"
        },
        "D411": {
          "courseID": "D411",
          "CCN": "ITSW 3170",
          "name": "Scripting and Automation ",
          "CUs": "2"
        },
        "D338": {
          "courseID": "D338",
          "CCN": "ITCL 3204",
          "name": "Cloud Platform Solutions ",
          "CUs": "3"
        },
        "D319": {
          "courseID": "D319",
          "CCN": "ITCL 3201",
          "name": "AWS Cloud Architecture ",
          "CUs": "3"
        },
        "D321": {
          "courseID": "D321",
          "CCN": "ITCL 3203",
          "name": "AWS Developer ",
          "CUs": "3"
        },
        "D341": {
          "courseID": "D341",
          "CCN": "ITEC 3005",
          "name": "Cloud Deployment and Operations ",
          "CUs": "3"
        },
        "D337": {
          "courseID": "D337",
          "CCN": "ITEC 2114",
          "name": "Internet of Things (IoT) and Infrastructure ",
          "CUs": "3"
        },
        "D342": {
          "courseID": "D342",
          "CCN": "ITCL 4179",
          "name": "Cloud Computing Capstone ",
          "CUs": "4"
        },
        "C948": {
          "courseID": "C948",
          "CCN": "ITEC 6500",
          "name": "Technical Communication ",
          "CUs": "3"
        },
        "C954": {
          "courseID": "C954",
          "CCN": "ITIM 5530",
          "name": "Information Technology Management ",
          "CUs": "3"
        },
        "C962": {
          "courseID": "C962",
          "CCN": "INTE 5200",
          "name": "Current and Emerging Technology ",
          "CUs": "3"
        },
        "C783": {
          "courseID": "C783",
          "CCN": "ITEC 5320",
          "name": "Project Management ",
          "CUs": "4"
        },
        "LZT2": {
          "courseID": "LZT2",
          "CCN": "INTE 5300",
          "name": "Power, Influence and Leadership ",
          "CUs": "3"
        },
        "C929": {
          "courseID": "C929",
          "CCN": "ITIM 6520",
          "name": "IT Sourcing and Development in a Global Economy ",
          "CUs": "2"
        },
        "C927": {
          "courseID": "C927",
          "CCN": "ITIM 6500",
          "name": "Managing Technology Operations and Innovation ",
          "CUs": "3"
        },
        "C928": {
          "courseID": "C928",
          "CCN": "ITIM 6510",
          "name": "Financial Management for IT Professionals ",
          "CUs": "2"
        },
        "MBT2": {
          "courseID": "MBT2",
          "CCN": "ITEC 6400",
          "name": "Technological Globalization ",
          "CUs": "3"
        },
        "C498": {
          "courseID": "C498",
          "CCN": "ITEC 6901",
          "name": "MS, Information Technology Management Capstone ",
          "CUs": "4"
        },
        "D097": {
          "courseID": "D097",
          "CCN": "EDUC 2219",
          "name": "Educational Foundations ",
          "CUs": "2"
        },
        "C306": {
          "courseID": "C306",
          "CCN": "MATH 2708",
          "name": "Finite Mathematics ",
          "CUs": "4"
        },
        "C100": {
          "courseID": "C100",
          "CCN": "HUMN 1010",
          "name": "Introduction to Humanities ",
          "CUs": "3"
        },
        "C278": {
          "courseID": "C278",
          "CCN": "MATH 1015",
          "name": "College Algebra ",
          "CUs": "4"
        },
        "D094": {
          "courseID": "D094",
          "CCN": "EDUC 2216",
          "name": "Educational Psychology and Development of Children and Adolescents ",
          "CUs": "4"
        },
        "C121": {
          "courseID": "C121",
          "CCN": "HIST 1010",
          "name": "Survey of United States History ",
          "CUs": "3"
        },
        "C455": {
          "courseID": "C455",
          "CCN": "ENGL 1010",
          "name": "English Composition I ",
          "CUs": "3"
        },
        "D090": {
          "courseID": "D090",
          "CCN": "EDUC 2212",
          "name": "The School as a Community of Care ",
          "CUs": "3"
        },
        "C646": {
          "courseID": "C646",
          "CCN": "MATH 3321",
          "name": "Trigonometry and Precalculus ",
          "CUs": "4"
        },
        "D096": {
          "courseID": "D096",
          "CCN": "EDUC 2218",
          "name": "Fundamentals of Diverse Learners ",
          "CUs": "4"
        },
        "D095": {
          "courseID": "D095",
          "CCN": "EDUC 2217",
          "name": "Managing Engaging Learning Environments ",
          "CUs": "3"
        },
        "C280": {
          "courseID": "C280",
          "CCN": "MATH 2505",
          "name": "Probability and Statistics I ",
          "CUs": "4"
        },
        "D091": {
          "courseID": "D091",
          "CCN": "EDUC 2213",
          "name": "Introduction to Curriculum, Instruction, and Assessment ",
          "CUs": "3"
        },
        "D093": {
          "courseID": "D093",
          "CCN": "EDUC 2215",
          "name": "Assessing Impact on Student Learning ",
          "CUs": "3"
        },
        "C972": {
          "courseID": "C972",
          "CCN": "MATH 3610",
          "name": "College Geometry ",
          "CUs": "4"
        },
        "C903": {
          "courseID": "C903",
          "CCN": "MATH 4305",
          "name": "Middle School Mathematics: Content Knowledge ",
          "CUs": "2"
        },
        "C456": {
          "courseID": "C456",
          "CCN": "ENGL 1020",
          "name": "English Composition II ",
          "CUs": "3"
        },
        "C464": {
          "courseID": "C464",
          "CCN": "COMM 1011",
          "name": "Introduction to Communication ",
          "CUs": "3"
        },
        "D092": {
          "courseID": "D092",
          "CCN": "EDUC 2214",
          "name": "Educational Technology for Teaching and Learning ",
          "CUs": "3"
        },
        "C282": {
          "courseID": "C282",
          "CCN": "MATH 2405",
          "name": "Calculus I ",
          "CUs": "4"
        },
        "C285": {
          "courseID": "C285",
          "CCN": "EDUC 4305",
          "name": "Mathematics History and Technology ",
          "CUs": "4"
        },
        "C730": {
          "courseID": "C730",
          "CCN": "EDUC 3276",
          "name": "Secondary Reading Instruction and Interventions ",
          "CUs": "3"
        },
        "C728": {
          "courseID": "C728",
          "CCN": "EDUC 3275",
          "name": "Secondary Disciplinary Literacy ",
          "CUs": "3"
        },
        "C284": {
          "courseID": "C284",
          "CCN": "EDUC 4315",
          "name": "Mathematics Learning and Teaching ",
          "CUs": "4"
        },
        "C965": {
          "courseID": "C965",
          "CCN": "EDUC 4112",
          "name": "Teaching in the Middle School ",
          "CUs": "2"
        },
        "C879": {
          "courseID": "C879",
          "CCN": "EDUC 3101",
          "name": "Algebra for Secondary Mathematics Teaching ",
          "CUs": "3"
        },
        "C930": {
          "courseID": "C930",
          "CCN": "EDUC 3411",
          "name": "Preclinical Experiences in Mathematics ",
          "CUs": "3"
        },
        "C315": {
          "courseID": "C315",
          "CCN": "EDUC 4932",
          "name": "Supervised Demonstration Teaching in Mathematics, Obs 1 and 2 ",
          "CUs": "3"
        },
        "C316": {
          "courseID": "C316",
          "CCN": "EDUC 4933",
          "name": "Supervised Demonstration Teaching in Mathematics, Obs 3 and Midterm ",
          "CUs": "3"
        },
        "C317": {
          "courseID": "C317",
          "CCN": "EDUC 4934",
          "name": "Supervised Demonstration Teaching in Mathematics, Observations 4 and 5 ",
          "CUs": "3"
        },
        "C318": {
          "courseID": "C318",
          "CCN": "EDUC 4935",
          "name": "Supervised Demonstration Teaching in Mathematics, Observation 6 and Final ",
          "CUs": "3"
        },
        "D148": {
          "courseID": "D148",
          "CCN": "EDUC 4762",
          "name": "Teacher Performance Assessment in Mathematics Education ",
          "CUs": "3"
        },
        "D151": {
          "courseID": "D151",
          "CCN": "EDUC 4765",
          "name": "Professional Portfolio ",
          "CUs": "2"
        },
        "C341": {
          "courseID": "C341",
          "CCN": "EDUC 4990",
          "name": "Cohort Seminar ",
          "CUs": "3"
        },
        "C362": {
          "courseID": "C362",
          "CCN": "MATH 2000",
          "name": "Calculus I ",
          "CUs": "4"
        },
        "C283": {
          "courseID": "C283",
          "CCN": "MATH 2415",
          "name": "Calculus II ",
          "CUs": "4"
        },
        "TQC1": {
          "courseID": "TQC1",
          "CCN": "MATH 2520",
          "name": "Probability and Statistics II ",
          "CUs": "3"
        },
        "C656": {
          "courseID": "C656",
          "CCN": "MATH 3311",
          "name": "Calculus III ",
          "CUs": "3"
        },
        "C877": {
          "courseID": "C877",
          "CCN": "MATH 3100",
          "name": "Mathematical Modeling and Applications ",
          "CUs": "3"
        },
        "RKT1": {
          "courseID": "RKT1",
          "CCN": "MATH 3310",
          "name": "Linear Algebra ",
          "CUs": "3"
        },
        "C897": {
          "courseID": "C897",
          "CCN": "MATH 4315",
          "name": "Mathematics: Content Knowledge ",
          "CUs": "2"
        },
        "QDT1": {
          "courseID": "QDT1",
          "CCN": "MATH 3320",
          "name": "Abstract Algebra ",
          "CUs": "3"
        },
        "C885": {
          "courseID": "C885",
          "CCN": "MATH 3104",
          "name": "Advanced Calculus ",
          "CUs": "3"
        },
        "C881": {
          "courseID": "C881",
          "CCN": "EDUC 3102",
          "name": "Geometry for Secondary Mathematics Teaching ",
          "CUs": "3"
        },
        "C883": {
          "courseID": "C883",
          "CCN": "EDUC 3103",
          "name": "Statistics and Probability for Secondary Mathematics Teaching ",
          "CUs": "3"
        },
        "C932": {
          "courseID": "C932",
          "CCN": "EDUC 3414",
          "name": "Preclinical Experiences in Mathematics ",
          "CUs": "3"
        },
        "C371": {
          "courseID": "C371",
          "CCN": "SCIE 2025",
          "name": "Concepts in Science ",
          "CUs": "2"
        },
        "C832": {
          "courseID": "C832",
          "CCN": "CHEM 2111",
          "name": "Chemistry with Lab ",
          "CUs": "4"
        },
        "C890": {
          "courseID": "C890",
          "CCN": "GEOS 2102",
          "name": "Ecology and Environmental Science ",
          "CUs": "3"
        },
        "C652": {
          "courseID": "C652",
          "CCN": "BIO 3105",
          "name": "Heredity and Genetics ",
          "CUs": "3"
        },
        "RNT1": {
          "courseID": "RNT1",
          "CCN": "PHYS 2100",
          "name": "General Physics ",
          "CUs": "5"
        },
        "C894": {
          "courseID": "C894",
          "CCN": "GEOS 2104",
          "name": "Astronomy ",
          "CUs": "3"
        },
        "C925": {
          "courseID": "C925",
          "CCN": "GEOS 3513",
          "name": "Earth: Inside and Out ",
          "CUs": "4"
        },
        "C388": {
          "courseID": "C388",
          "CCN": "EDUC 4409",
          "name": "Science, Technology, and Society ",
          "CUs": "5"
        },
        "C902": {
          "courseID": "C902",
          "CCN": "SCIE 4405",
          "name": "Middle School Science: Content Knowledge ",
          "CUs": "2"
        },
        "C974": {
          "courseID": "C974",
          "CCN": "EDUC 4117",
          "name": "Science Methods—Middle Grades General Science ",
          "CUs": "4"
        },
        "C937": {
          "courseID": "C937",
          "CCN": "EDUC 3412",
          "name": "Preclinical Experiences in Science ",
          "CUs": "3"
        },
        "C319": {
          "courseID": "C319",
          "CCN": "EDUC 4945",
          "name": "Supervised Demonstration Teaching in Science, Observations 1 and 2 ",
          "CUs": "3"
        },
        "C320": {
          "courseID": "C320",
          "CCN": "EDUC 4946",
          "name": "Supervised Demonstration Teaching in Science, Observation 3 and Midterm ",
          "CUs": "3"
        },
        "C321": {
          "courseID": "C321",
          "CCN": "EDUC 4947",
          "name": "Supervised Demonstration Teaching in Science, Observations 4 and 5 ",
          "CUs": "3"
        },
        "C322": {
          "courseID": "C322",
          "CCN": "EDUC 4948",
          "name": "Supervised Demonstration Teaching in Science, Observation 6 and Final ",
          "CUs": "3"
        },
        "D150": {
          "courseID": "D150",
          "CCN": "EDUC 4764",
          "name": "Teacher Performance Assessment in Science ",
          "CUs": "3"
        },
        "C888": {
          "courseID": "C888",
          "CCN": "BIO 2102",
          "name": "Molecular and Cellular Biology ",
          "CUs": "4"
        },
        "C875": {
          "courseID": "C875",
          "CCN": "BIO 2012",
          "name": "Human Anatomy and Physiology ",
          "CUs": "4"
        },
        "C654": {
          "courseID": "C654",
          "CCN": "BIO 2101",
          "name": "Zoology ",
          "CUs": "3"
        },
        "C736": {
          "courseID": "C736",
          "CCN": "BIO 3261",
          "name": "Evolution ",
          "CUs": "4"
        },
        "C900": {
          "courseID": "C900",
          "CCN": "BIO 4405",
          "name": "Biology: Content Knowledge ",
          "CUs": "2"
        },
        "C940": {
          "courseID": "C940",
          "CCN": "EDUC 4113",
          "name": "Science Methods—Secondary Biology ",
          "CUs": "4"
        },
        "C373": {
          "courseID": "C373",
          "CCN": "CHEM 2110",
          "name": "General Chemistry I with Lab ",
          "CUs": "4"
        },
        "C374": {
          "courseID": "C374",
          "CCN": "CHEM 2210",
          "name": "General Chemistry II with Lab ",
          "CUs": "4"
        },
        "BVT1": {
          "courseID": "BVT1",
          "CCN": "CHEM 3310",
          "name": "Physical Chemistry ",
          "CUs": "3"
        },
        "BWT1": {
          "courseID": "BWT1",
          "CCN": "CHEM 3300",
          "name": "Inorganic Chemistry ",
          "CUs": "3"
        },
        "UQT1": {
          "courseID": "UQT1",
          "CCN": "CHEM 2300",
          "name": "Organic Chemistry ",
          "CUs": "3"
        },
        "C624": {
          "courseID": "C624",
          "CCN": "CHEM 3501",
          "name": "Biochemistry ",
          "CUs": "3"
        },
        "C264": {
          "courseID": "C264",
          "CCN": "EDUC 3512",
          "name": "Climate Change ",
          "CUs": "4"
        },
        "C915": {
          "courseID": "C915",
          "CCN": "CHEM 4405",
          "name": "Chemistry: Content Knowledge ",
          "CUs": "2"
        },
        "C941": {
          "courseID": "C941",
          "CCN": "EDUC 4114",
          "name": "Science Methods—Secondary Chemistry ",
          "CUs": "4"
        },
        "C876": {
          "courseID": "C876",
          "CCN": "PHYS 2102",
          "name": "Conceptual Physics ",
          "CUs": "5"
        },
        "BYT1": {
          "courseID": "BYT1",
          "CCN": "PHYS 2300",
          "name": "Physics: Mechanics ",
          "CUs": "3"
        },
        "BZT1": {
          "courseID": "BZT1",
          "CCN": "SCIE 2310",
          "name": "Physics: Waves and Optics ",
          "CUs": "3"
        },
        "DPT1": {
          "courseID": "DPT1",
          "CCN": "PHYS 2320",
          "name": "Physics: Electricity and Magnetism ",
          "CUs": "3"
        },
        "C738": {
          "courseID": "C738",
          "CCN": "PHYS 3262",
          "name": "Space, Time and Motion ",
          "CUs": "4"
        },
        "C901": {
          "courseID": "C901",
          "CCN": "PHYS 4405",
          "name": "Physics: Content Knowledge ",
          "CUs": "2"
        },
        "C943": {
          "courseID": "C943",
          "CCN": "EDUC 4116",
          "name": "Science Methods—Secondary Physics ",
          "CUs": "4"
        },
        "C649": {
          "courseID": "C649",
          "CCN": "GEOS 2101",
          "name": "Geology I: Physical ",
          "CUs": "4"
        },
        "C892": {
          "courseID": "C892",
          "CCN": "GEOS 2103",
          "name": "Geology II: Earth Systems ",
          "CUs": "4"
        },
        "C263": {
          "courseID": "C263",
          "CCN": "EDUC 3511",
          "name": "The Ocean Systems ",
          "CUs": "4"
        },
        "C898": {
          "courseID": "C898",
          "CCN": "GEOS 4405",
          "name": "Earth Science: Content Knowledge ",
          "CUs": "2"
        },
        "C942": {
          "courseID": "C942",
          "CCN": "EDUC 4115",
          "name": "Science Methods—Secondary Earth Science ",
          "CUs": "4"
        },
        "D125": {
          "courseID": "D125",
          "CCN": "MATH 1111",
          "name": "Mathematics for Elementary Educators I ",
          "CUs": "3"
        },
        "D267": {
          "courseID": "D267",
          "CCN": "HIST 1017",
          "name": "US History: Stories of American Democracy ",
          "CUs": "3"
        },
        "D126": {
          "courseID": "D126",
          "CCN": "MATH 1112",
          "name": "Mathematics for Elementary Educators II ",
          "CUs": "3"
        },
        "D002": {
          "courseID": "D002",
          "CCN": "SPED 3513",
          "name": "Professional, Ethical, and Legal Practices for Special Education ",
          "CUs": "3"
        },
        "D127": {
          "courseID": "D127",
          "CCN": "MATH 1113",
          "name": "Mathematics for Elementary Educators III ",
          "CUs": "3"
        },
        "D001": {
          "courseID": "D001",
          "CCN": "SPED 4512",
          "name": "Behavioral Support Strategies for K-12 Learners with Mild to Moderate Exceptionalities ",
          "CUs": "4"
        },
        "D003": {
          "courseID": "D003",
          "CCN": "SPED 4514",
          "name": "Assessment in Special Education ",
          "CUs": "3"
        },
        "D004": {
          "courseID": "D004",
          "CCN": "SPED 4515",
          "name": "Collaborating with Partners for Student Success ",
          "CUs": "3"
        },
        "C909": {
          "courseID": "C909",
          "CCN": "EDUC 4211",
          "name": "Elementary Reading Methods and Interventions ",
          "CUs": "3"
        },
        "C365": {
          "courseID": "C365",
          "CCN": "EDUC 4220",
          "name": "Language Arts Instruction and Intervention ",
          "CUs": "3"
        },
        "C109": {
          "courseID": "C109",
          "CCN": "EDUC 4230",
          "name": "Elementary Mathematics Methods ",
          "CUs": "3"
        },
        "D010": {
          "courseID": "D010",
          "CCN": "EDUC 3279",
          "name": "Disciplinary Literacy ",
          "CUs": "3"
        },
        "C970": {
          "courseID": "C970",
          "CCN": "EDUC 3211",
          "name": "Children’s Literature ",
          "CUs": "3"
        },
        "D005": {
          "courseID": "D005",
          "CCN": "SPED 4516",
          "name": "Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities ",
          "CUs": "3"
        },
        "D006": {
          "courseID": "D006",
          "CCN": "SPED 4517",
          "name": "Instructional Strategies and Technologies ",
          "CUs": "for"
        },
        "Mild": {
          "courseID": "Mild",
          "CCN": "Learners with",
          "name": "to Moderate Exceptionalit ",
          "CUs": "2"
        },
        "D007": {
          "courseID": "D007",
          "CCN": "SPED 4518",
          "name": "Instructional Strategies and Technologies ",
          "CUs": "for"
        },
        "D009": {
          "courseID": "D009",
          "CCN": "EDUC 3420",
          "name": "Preclinical Experiences in Special Education ",
          "CUs": "3"
        },
        "D012": {
          "courseID": "D012",
          "CCN": "EDUC 4047",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 1 and 2 ",
          "CUs": "3"
        },
        "D013": {
          "courseID": "D013",
          "CCN": "EDUC 4049",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 3 and Midterm ",
          "CUs": "3"
        },
        "D014": {
          "courseID": "D014",
          "CCN": "EDUC 4050",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 4 and 5 ",
          "CUs": "3"
        },
        "D015": {
          "courseID": "D015",
          "CCN": "EDUC 4051",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 6 and Final ",
          "CUs": "3"
        },
        "D149": {
          "courseID": "D149",
          "CCN": "EDUC 4763",
          "name": "Teacher Performance Assessment in Special Education ",
          "CUs": "3"
        },
        "C340": {
          "courseID": "C340",
          "CCN": "EDUC 4989",
          "name": "Cohort Seminar in Special Education ",
          "CUs": "3"
        },
        "D016": {
          "courseID": "D016",
          "CCN": "EDUC 5288",
          "name": "Leadership Foundations and Ethics ",
          "CUs": "3"
        },
        "D017": {
          "courseID": "D017",
          "CCN": "EDUC 5289",
          "name": "School Law ",
          "CUs": "3"
        },
        "D020": {
          "courseID": "D020",
          "CCN": "EDUC 5292",
          "name": "Cultural Competency and Social-Emotional Learning ",
          "CUs": "3"
        },
        "D019": {
          "courseID": "D019",
          "CCN": "EDUC 5291",
          "name": "Data Literacy and Evidence-Based Practices ",
          "CUs": "3"
        },
        "D021": {
          "courseID": "D021",
          "CCN": "EDUC 5293",
          "name": "Leadership of Curriculum Design and Instruction ",
          "CUs": "3"
        },
        "D018": {
          "courseID": "D018",
          "CCN": "EDUC 5290",
          "name": "Leading Inclusive Schools ",
          "CUs": "3"
        },
        "D022": {
          "courseID": "D022",
          "CCN": "EDUC 5294",
          "name": "People and Talent in Educational Leadership ",
          "CUs": "3"
        },
        "D023": {
          "courseID": "D023",
          "CCN": "EDUC 5295",
          "name": "School Financial Leadership ",
          "CUs": "3"
        },
        "D036": {
          "courseID": "D036",
          "CCN": "EDUC 5298",
          "name": "Practicum in Educational Leadership - Focus on Professional Practices ",
          "CUs": "3"
        },
        "D034": {
          "courseID": "D034",
          "CCN": "EDUC 5296",
          "name": "Systems Management and School Operations ",
          "CUs": "3"
        },
        "D035": {
          "courseID": "D035",
          "CCN": "EDUC 5297",
          "name": "Educational Inquiry ",
          "CUs": "3"
        },
        "D037": {
          "courseID": "D037",
          "CCN": "EDUC 5299",
          "name": "Practicum in Educational Leadership - Focus on Instruction and Operations ",
          "CUs": "3"
        },
        "D038": {
          "courseID": "D038",
          "CCN": "EDUC 5300",
          "name": "Educational Leadership Capstone ",
          "CUs": "3"
        },
        "D182": {
          "courseID": "D182",
          "CCN": "EDUC 5062",
          "name": "The Reflective Practitioner ",
          "CUs": "3"
        },
        "D183": {
          "courseID": "D183",
          "CCN": "EDUC 5063",
          "name": "Designing Curriculum and Instruction I ",
          "CUs": "3"
        },
        "D179": {
          "courseID": "D179",
          "CCN": "EDUC 5061",
          "name": "Data-Informed Practices ",
          "CUs": "3"
        },
        "D187": {
          "courseID": "D187",
          "CCN": "EDUC 6302",
          "name": "Differentiated Instruction ",
          "CUs": "3"
        },
        "D184": {
          "courseID": "D184",
          "CCN": "EDUC 5064",
          "name": "Standards-Based Assessment ",
          "CUs": "3"
        },
        "D185": {
          "courseID": "D185",
          "CCN": "EDUC 5065",
          "name": "Designing Curriculum and Instruction II ",
          "CUs": "3"
        },
        "D186": {
          "courseID": "D186",
          "CCN": "EDUC 5066",
          "name": "Learning as a Science ",
          "CUs": "3"
        },
        "D188": {
          "courseID": "D188",
          "CCN": "EDUC 6303",
          "name": "The Collaborative Leader ",
          "CUs": "3"
        },
        "D180": {
          "courseID": "D180",
          "CCN": "EDUC 6300",
          "name": "Educational Research ",
          "CUs": "3"
        },
        "D181": {
          "courseID": "D181",
          "CCN": "EDUC 6301",
          "name": "MSCIN Capstone ",
          "CUs": "5"
        },
        "D389": {
          "courseID": "D389",
          "CCN": "HLTH 2050",
          "name": "Learning Strategies in Higher Education ",
          "CUs": "4"
        },
        "C799": {
          "courseID": "C799",
          "CCN": "HIM 2011",
          "name": "Healthcare Ecosystems ",
          "CUs": "3"
        },
        "C784": {
          "courseID": "C784",
          "CCN": "MATH 1100",
          "name": "Applied Healthcare Statistics ",
          "CUs": "4"
        },
        "C804": {
          "courseID": "C804",
          "CCN": "HIM 2150",
          "name": "Medical Terminology ",
          "CUs": "3"
        },
        "D203": {
          "courseID": "D203",
          "CCN": "BIO 1100",
          "name": "Fundamentals of Anatomy and Physiology ",
          "CUs": "3"
        },
        "C816": {
          "courseID": "C816",
          "CCN": "HIM 3205",
          "name": "Healthcare System Applications ",
          "CUs": "4"
        },
        "C180": {
          "courseID": "C180",
          "CCN": "PSYC 1010",
          "name": "Introduction to Psychology ",
          "CUs": "3"
        },
        "C802": {
          "courseID": "C802",
          "CCN": "HLTH 3501",
          "name": "Foundations in Healthcare Information Management ",
          "CUs": "4"
        },
        "C803": {
          "courseID": "C803",
          "CCN": "HLTH 3315",
          "name": "Data Analytics and Information Governance ",
          "CUs": "4"
        },
        "D190": {
          "courseID": "D190",
          "CCN": "HIM 2002",
          "name": "Introduction to Healthcare IT Systems ",
          "CUs": "4"
        },
        "C810": {
          "courseID": "C810",
          "CCN": "HIM 2104",
          "name": "Foundations in Healthcare Data Management ",
          "CUs": "3"
        },
        "C801": {
          "courseID": "C801",
          "CCN": "HIM 2215",
          "name": "Health Information Law and Regulations ",
          "CUs": "4"
        },
        "C805": {
          "courseID": "C805",
          "CCN": "HIM 2507",
          "name": "Pathophysiology ",
          "CUs": "3"
        },
        "D398": {
          "courseID": "D398",
          "CCN": "HLTH 2422",
          "name": "Introduction to Pharmacology ",
          "CUs": "3"
        },
        "C815": {
          "courseID": "C815",
          "CCN": "HIM 4511",
          "name": "Quality and Performance Management and Methods ",
          "CUs": "4"
        },
        "C808": {
          "courseID": "C808",
          "CCN": "HIM 2515",
          "name": "Classification Systems ",
          "CUs": "4"
        },
        "D254": {
          "courseID": "D254",
          "CCN": "HLTH 2110",
          "name": "Introduction to Medical Coding ",
          "CUs": "3"
        },
        "C807": {
          "courseID": "C807",
          "CCN": "HIM 3215",
          "name": "Healthcare Compliance ",
          "CUs": "3"
        },
        "C811": {
          "courseID": "C811",
          "CCN": "HIM 3701",
          "name": "Healthcare Financial Resource Management ",
          "CUs": "4"
        },
        "C812": {
          "courseID": "C812",
          "CCN": "HIM 4610",
          "name": "Healthcare Reimbursement ",
          "CUs": "4"
        },
        "C813": {
          "courseID": "C813",
          "CCN": "HIM 4502",
          "name": "Healthcare Statistics and Research ",
          "CUs": "3"
        },
        "D033": {
          "courseID": "D033",
          "CCN": "HLTH 2100",
          "name": "Healthcare Information Systems Management ",
          "CUs": "3"
        },
        "D255": {
          "courseID": "D255",
          "CCN": "HLTH 4905",
          "name": "Professional Practice Experience I: Technical ",
          "CUs": "3"
        },
        "D257": {
          "courseID": "D257",
          "CCN": "HLTH 3000",
          "name": "Healthcare Project Management ",
          "CUs": "4"
        },
        "D256": {
          "courseID": "D256",
          "CCN": "HLTH 2120",
          "name": "Principles of Management in Health Information Management ",
          "CUs": "3"
        },
        "D258": {
          "courseID": "D258",
          "CCN": "HLTH 3100",
          "name": "Organizational Leadership in Healthcare ",
          "CUs": "3"
        },
        "D259": {
          "courseID": "D259",
          "CCN": "HLTH 4906",
          "name": "Professional Practice Experience II: Management ",
          "CUs": "4"
        },
        "D260": {
          "courseID": "D260",
          "CCN": "HIM 4507",
          "name": "Health Information Management Capstone ",
          "CUs": "4"
        },
        "CUA1": {
          "courseID": "CUA1",
          "CCN": "EDUC 5260",
          "name": "Culture ",
          "CUs": "3"
        },
        "LPA1": {
          "courseID": "LPA1",
          "CCN": "EDUC 5261",
          "name": "Language Production, Theory and Acquisition ",
          "CUs": "4"
        },
        "SLO1": {
          "courseID": "SLO1",
          "CCN": "EDUC 5262",
          "name": "Theories of Second Language Acquisition and Grammar ",
          "CUs": "3"
        },
        "ASA1": {
          "courseID": "ASA1",
          "CCN": "EDUC 5264",
          "name": "Assessment Theory and Practice ",
          "CUs": "3"
        },
        "NNA1": {
          "courseID": "NNA1",
          "CCN": "EDUC 5263",
          "name": "Planning, Implementing, Managing Instruction ",
          "CUs": "4"
        },
        "NMA1": {
          "courseID": "NMA1",
          "CCN": "EDUC 5265",
          "name": "Professional Role of the ELL Teacher ",
          "CUs": "2"
        },
        "ELO1": {
          "courseID": "ELO1",
          "CCN": "EDUC 6260",
          "name": "Subject Specific Pedagogy: ELL ",
          "CUs": "3"
        },
        "C224": {
          "courseID": "C224",
          "CCN": "EDUC 5111",
          "name": "Research Foundations ",
          "CUs": "2"
        },
        "C225": {
          "courseID": "C225",
          "CCN": "EDUC 5112",
          "name": "Research Questions and Literature Review ",
          "CUs": "2"
        },
        "FEA1": {
          "courseID": "FEA1",
          "CCN": "EDUC 6261",
          "name": "Field Experience for ELL ",
          "CUs": "3"
        },
        "C360": {
          "courseID": "C360",
          "CCN": "EDUC 6754",
          "name": "Teacher Work Sample in English Language Learning ",
          "CUs": "1"
        },
        "C670": {
          "courseID": "C670",
          "CCN": "SCIE 5408",
          "name": "Concepts in Science ",
          "CUs": "1"
        },
        "C908": {
          "courseID": "C908",
          "CCN": "SCIE 5020",
          "name": "Integrated Physical Sciences ",
          "CUs": "2"
        },
        "C672": {
          "courseID": "C672",
          "CCN": "CHEM 5409",
          "name": "General Chemistry I with Lab ",
          "CUs": "3"
        },
        "C673": {
          "courseID": "C673",
          "CCN": "CHEM 5410",
          "name": "General Chemistry II with Lab ",
          "CUs": "3"
        },
        "RXT2": {
          "courseID": "RXT2",
          "CCN": "MATH 5350",
          "name": "Precalculus and Calculus ",
          "CUs": "2"
        },
        "BVT2": {
          "courseID": "BVT2",
          "CCN": "CHEM 5310",
          "name": "Physical Chemistry ",
          "CUs": "2"
        },
        "BWT2": {
          "courseID": "BWT2",
          "CCN": "CHEM 5300",
          "name": "Inorganic Chemistry ",
          "CUs": "2"
        },
        "C389": {
          "courseID": "C389",
          "CCN": "EDUC 5409",
          "name": "Science, Technology, and Society ",
          "CUs": "2"
        },
        "AIT2": {
          "courseID": "AIT2",
          "CCN": "CHEM 5250",
          "name": "Organic Chemistry ",
          "CUs": "2"
        },
        "C625": {
          "courseID": "C625",
          "CCN": "SCIE 5501",
          "name": "Biochemistry ",
          "CUs": "2"
        },
        "C267": {
          "courseID": "C267",
          "CCN": "EDUC 5512",
          "name": "Climate Change ",
          "CUs": "3"
        },
        "C617": {
          "courseID": "C617",
          "CCN": "CHEM 6405",
          "name": "Chemistry: Content Knowledge ",
          "CUs": "1"
        },
        "C977": {
          "courseID": "C977",
          "CCN": "EDUC 5045",
          "name": "Science Methods—Secondary Chemistry ",
          "CUs": "3"
        },
        "C871": {
          "courseID": "C871",
          "CCN": "EDUC 6264",
          "name": "MA, Science Education Teacher Performance Assessment ",
          "CUs": "6"
        },
        "D307": {
          "courseID": "D307",
          "CCN": "EDUC 5074",
          "name": "Educational Psychology and Human Development of Children and Adolescents ",
          "CUs": "4"
        },
        "D168": {
          "courseID": "D168",
          "CCN": "EDUC 5055",
          "name": "Schools as Communities of Care ",
          "CUs": "2"
        },
        "D169": {
          "courseID": "D169",
          "CCN": "EDUC 5056",
          "name": "Essential Practices for Supporting Diverse Learners ",
          "CUs": "3"
        },
        "D228": {
          "courseID": "D228",
          "CCN": "SPED 5290",
          "name": "Special Education Practices: Professional, Ethical and Legal Guidelines ",
          "CUs": "2"
        },
        "D170": {
          "courseID": "D170",
          "CCN": "EDUC 5057",
          "name": "Creating and Managing Engaging Learning Environments ",
          "CUs": "2"
        },
        "D171": {
          "courseID": "D171",
          "CCN": "EDUC 5058",
          "name": "Curriculum, Instruction, and Assessment ",
          "CUs": "2"
        },
        "D237": {
          "courseID": "D237",
          "CCN": "SPED 5297",
          "name": "Mathematics Methods and Instruction for Students with Mild/Moderate Exceptionalities ",
          "CUs": "2"
        },
        "D229": {
          "courseID": "D229",
          "CCN": "SPED 5291",
          "name": "Management Strategies for Academic and Social Behavior ",
          "CUs": "3"
        },
        "D230": {
          "courseID": "D230",
          "CCN": "SPED 5292",
          "name": "Assessment and Evaluation Procedures in Special Education ",
          "CUs": "2"
        },
        "C380": {
          "courseID": "C380",
          "CCN": "EDUC 6380",
          "name": "Language Arts Instruction and Intervention ",
          "CUs": "2"
        },
        "C910": {
          "courseID": "C910",
          "CCN": "EDUC 6207",
          "name": "Elementary Reading Methods and Interventions ",
          "CUs": "2"
        },
        "D163": {
          "courseID": "D163",
          "CCN": "EDUC 5317",
          "name": "Secondary Reading Instruction and Interventions ",
          "CUs": "2"
        },
        "D244": {
          "courseID": "D244",
          "CCN": "SPED 5304",
          "name": "Disciplinary Literacy ",
          "CUs": "2"
        },
        "D173": {
          "courseID": "D173",
          "CCN": "EDUC 5060",
          "name": "Using Educational Technology for Teaching and Learning ",
          "CUs": "2"
        },
        "D231": {
          "courseID": "D231",
          "CCN": "SPED 5293",
          "name": "Collaborative Techniques with Partners for Effective IEPs ",
          "CUs": "2"
        },
        "D232": {
          "courseID": "D232",
          "CCN": "SPED 5294",
          "name": "Special Education Methods of Instruction and Intervention ",
          "CUs": "2"
        },
        "D233": {
          "courseID": "D233",
          "CCN": "SPED 5295",
          "name": "Designing Instruction for Elementary Learners with Mild to Moderate Exceptionalities ",
          "CUs": "2"
        },
        "D234": {
          "courseID": "D234",
          "CCN": "SPED 5296",
          "name": "Designing Instruction for Secondary Learners with Mild to Moderate Exceptionalities ",
          "CUs": "2"
        },
        "D238": {
          "courseID": "D238",
          "CCN": "SPED 5298",
          "name": "Preclinical Experiences in Special Education ",
          "CUs": "2"
        },
        "D239": {
          "courseID": "D239",
          "CCN": "SPED 5299",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 1 and 2 ",
          "CUs": "2"
        },
        "D240": {
          "courseID": "D240",
          "CCN": "SPED 5300",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 3 and Midterm ",
          "CUs": "2"
        },
        "D241": {
          "courseID": "D241",
          "CCN": "SPED 5301",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 4 and 5 ",
          "CUs": "2"
        },
        "D242": {
          "courseID": "D242",
          "CCN": "SPED 5302",
          "name": "Supervised Demonstration Teaching in Special Education, Obs 6 and Final ",
          "CUs": "2"
        },
        "D243": {
          "courseID": "D243",
          "CCN": "SPED 5303",
          "name": "Teacher Performance Assessment in Special Education ",
          "CUs": "1"
        },
        "C347": {
          "courseID": "C347",
          "CCN": "EDUC 5255",
          "name": "Professional Portfolio ",
          "CUs": "1"
        },
        "D245": {
          "courseID": "D245",
          "CCN": "SPED 5305",
          "name": "Cohort Seminar in Special Education ",
          "CUs": "1"
        },
        "C200": {
          "courseID": "C200",
          "CCN": "MGMT 5000",
          "name": "Managing Organizations and Leading People ",
          "CUs": "3"
        },
        "MGT2": {
          "courseID": "MGT2",
          "CCN": "ITM 5000",
          "name": "IT Project Management ",
          "CUs": "3"
        },
        "C202": {
          "courseID": "C202",
          "CCN": "HRM 5010",
          "name": "Managing Human Capital ",
          "CUs": "3"
        },
        "C212": {
          "courseID": "C212",
          "CCN": "MKTG 5000",
          "name": "Marketing ",
          "CUs": "3"
        },
        "MMT2": {
          "courseID": "MMT2",
          "CCN": "ITM 6000",
          "name": "IT Strategic Solutions ",
          "CUs": "4"
        },
        "C213": {
          "courseID": "C213",
          "CCN": "ACCT 5000",
          "name": "Accounting for Decision Makers ",
          "CUs": "3"
        },
        "C206": {
          "courseID": "C206",
          "CCN": "MGMT 6000",
          "name": "Ethical Leadership ",
          "CUs": "3"
        },
        "C214": {
          "courseID": "C214",
          "CCN": "FINC 6000",
          "name": "Financial Management ",
          "CUs": "3"
        },
        "C207": {
          "courseID": "C207",
          "CCN": "MGMT 6010",
          "name": "Data-Driven Decision Making ",
          "CUs": "3"
        },
        "C211": {
          "courseID": "C211",
          "CCN": "ECON 5000",
          "name": "Global Economics for Managers ",
          "CUs": "3"
        },
        "C218": {
          "courseID": "C218",
          "CCN": "ITM 6900",
          "name": "MBA, Information Technology Management Capstone ",
          "CUs": "4"
        },
        "D509": {
          "courseID": "D509",
          "CCN": "MHA 5110",
          "name": "Innovative Solutions in Healthcare Leadership ",
          "CUs": "3"
        },
        "D510": {
          "courseID": "D510",
          "CCN": "MHA 5220",
          "name": "Collaborative Leadership ",
          "CUs": "3"
        },
        "D511": {
          "courseID": "D511",
          "CCN": "MHA 5310",
          "name": "Healthcare Models and Systems ",
          "CUs": "2"
        },
        "D512": {
          "courseID": "D512",
          "CCN": "MHA 5410",
          "name": "Quality Improvement in Healthcare ",
          "CUs": "3"
        },
        "D513": {
          "courseID": "D513",
          "CCN": "MHA 5510",
          "name": "Healthcare Financial Management ",
          "CUs": "3"
        },
        "D514": {
          "courseID": "D514",
          "CCN": "MHA 5600",
          "name": "Analytical Methods of Healthcare Leaders ",
          "CUs": "2"
        },
        "D515": {
          "courseID": "D515",
          "CCN": "MHA 6210",
          "name": "Enterprise Risk Management ",
          "CUs": "3"
        },
        "D516": {
          "courseID": "D516",
          "CCN": "MHA 6310",
          "name": "Healthcare Information Technology ",
          "CUs": "2"
        },
        "D517": {
          "courseID": "D517",
          "CCN": "MHA 6410",
          "name": "Population Healthcare Coordination ",
          "CUs": "3"
        },
        "D518": {
          "courseID": "D518",
          "CCN": "MHA 6510",
          "name": "Challenges in Community Healthcare ",
          "CUs": "3"
        },
        "D519": {
          "courseID": "D519",
          "CCN": "MHA 6610",
          "name": "Integrated Healthcare Leadership and Administration ",
          "CUs": "3"
        },
        "D520": {
          "courseID": "D520",
          "CCN": "MHA 6910",
          "name": "Healthcare Leadership and Administration Capstone ",
          "CUs": "4"
        },
        "D204": {
          "courseID": "D204",
          "CCN": "DTAN 5101",
          "name": "The Data Analytics Journey ",
          "CUs": "2"
        },
        "D205": {
          "courseID": "D205",
          "CCN": "DTMG 5240",
          "name": "Data Acquisition ",
          "CUs": "3"
        },
        "D206": {
          "courseID": "D206",
          "CCN": "DTAN 5201",
          "name": "Data Cleaning ",
          "CUs": "3"
        },
        "D207": {
          "courseID": "D207",
          "CCN": "DTAN 5202",
          "name": "Exploratory Data Analysis ",
          "CUs": "3"
        },
        "D208": {
          "courseID": "D208",
          "CCN": "DTAN 5203",
          "name": "Predictive Modeling ",
          "CUs": "3"
        },
        "D209": {
          "courseID": "D209",
          "CCN": "DTAN 6250",
          "name": "Data Mining I ",
          "CUs": "3"
        },
        "D210": {
          "courseID": "D210",
          "CCN": "DTAN 6204",
          "name": "Representation and Reporting ",
          "CUs": "3"
        },
        "D211": {
          "courseID": "D211",
          "CCN": "DTMG 6240",
          "name": "Advanced Data Acquisition ",
          "CUs": "2"
        },
        "D212": {
          "courseID": "D212",
          "CCN": "DTAN 6250",
          "name": "Data Mining II ",
          "CUs": "3"
        },
        "D213": {
          "courseID": "D213",
          "CCN": "DTAN 6205",
          "name": "Advanced Data Analytics ",
          "CUs": "3"
        },
        "D214": {
          "courseID": "D214",
          "CCN": "DTAN 6520",
          "name": "Data Analytics Graduate Capstone ",
          "CUs": "3"
        },
        "C201": {
          "courseID": "C201",
          "CCN": "BUS 5000",
          "name": "Business Acumen ",
          "CUs": "3"
        },
        "D432": {
          "courseID": "D432",
          "CCN": "MHRM 5000",
          "name": "HR Compliance and Employee Relations ",
          "CUs": "3"
        },
        "C204": {
          "courseID": "C204",
          "CCN": "MGMT 5010",
          "name": "Management Communication ",
          "CUs": "3"
        },
        "D433": {
          "courseID": "D433",
          "CCN": "MHRM 6000",
          "name": "Talent Acquisition and Development ",
          "CUs": "3"
        },
        "D435": {
          "courseID": "D435",
          "CCN": "MHRM 6020",
          "name": "HR Technology and People Analytics ",
          "CUs": "3"
        },
        "D434": {
          "courseID": "D434",
          "CCN": "MHRM 6010",
          "name": "Future Focused Total Rewards ",
          "CUs": "3"
        },
        "D436": {
          "courseID": "D436",
          "CCN": "MHRM 6030",
          "name": "Inclusive Workplace Culture Capstone ",
          "CUs": "3"
        },
        "D263": {
          "courseID": "D263",
          "CCN": "MGMT 5040",
          "name": "Frameworks for Strategic Decision-Making ",
          "CUs": "3"
        },
        "D373": {
          "courseID": "D373",
          "CCN": "MKTG 5010",
          "name": "Marketing in the Digital Era ",
          "CUs": "3"
        },
        "D374": {
          "courseID": "D374",
          "CCN": "MKTG 5020",
          "name": "Market Research ",
          "CUs": "3"
        },
        "D375": {
          "courseID": "D375",
          "CCN": "MKTG 5030",
          "name": "Marketing Communications and Storytelling ",
          "CUs": "3"
        },
        "D376": {
          "courseID": "D376",
          "CCN": "MKTG 5040",
          "name": "Product, Price, and Customer Experience ",
          "CUs": "3"
        },
        "D377": {
          "courseID": "D377",
          "CCN": "MKTG 6000",
          "name": "Digital Marketing Foundations ",
          "CUs": "3"
        },
        "D379": {
          "courseID": "D379",
          "CCN": "MKTG 6020",
          "name": "Social Media Marketing ",
          "CUs": "3"
        },
        "D380": {
          "courseID": "D380",
          "CCN": "MKTG 6030",
          "name": "Email Marketing ",
          "CUs": "3"
        },
        "D381": {
          "courseID": "D381",
          "CCN": "MKTG 6040",
          "name": "E-Commerce and Marketing Analytics ",
          "CUs": "3"
        },
        "D384": {
          "courseID": "D384",
          "CCN": "MKTG 6070",
          "name": "Marketing Experiential Capstone ",
          "CUs": "3"
        },
        "C254": {
          "courseID": "C254",
          "CCN": "ACCT 6000",
          "name": "Fraud and Forensic Accounting ",
          "CUs": "3"
        },
        "D251": {
          "courseID": "D251",
          "CCN": "ACCT 6201",
          "name": "Advanced Auditing ",
          "CUs": "3"
        },
        "C253": {
          "courseID": "C253",
          "CCN": "ACCT 5300",
          "name": "Advanced Managerial Accounting ",
          "CUs": "3"
        },
        "C209": {
          "courseID": "C209",
          "CCN": "MGMT 6050",
          "name": "Strategic Management ",
          "CUs": "3"
        },
        "C243": {
          "courseID": "C243",
          "CCN": "ACCT 5100",
          "name": "Advanced Financial Accounting ",
          "CUs": "3"
        },
        "D252": {
          "courseID": "D252",
          "CCN": "ACCT 6301",
          "name": "Accounting Research and Critical Thinking ",
          "CUs": "3"
        },
        "D250": {
          "courseID": "D250",
          "CCN": "ACCT 5201",
          "name": "Governmental and Nonprofit Accounting ",
          "CUs": "3"
        },
        "C239": {
          "courseID": "C239",
          "CCN": "ACCT 6100",
          "name": "Advanced Tax Concepts ",
          "CUs": "3"
        },
        "C203": {
          "courseID": "C203",
          "CCN": "MGMT 5020",
          "name": "Becoming an Effective Leader ",
          "CUs": "3"
        },
        "C205": {
          "courseID": "C205",
          "CCN": "MGMT 5030",
          "name": "Leading Teams ",
          "CUs": "3"
        },
        "C208": {
          "courseID": "C208",
          "CCN": "MGMT 6040",
          "name": "Change Management and Innovation ",
          "CUs": "3"
        },
        "C210": {
          "courseID": "C210",
          "CCN": "MGMT 6910",
          "name": "Management and Leadership Capstone ",
          "CUs": "4"
        },
        "C215": {
          "courseID": "C215",
          "CCN": "MGMT 6020",
          "name": "Operations Management ",
          "CUs": "3"
        },
        "C216": {
          "courseID": "C216",
          "CCN": "MGMT 6900",
          "name": "MBA Capstone 4 ",
          "CUs": "4"
        },
        "C715": {
          "courseID": "C715",
          "CCN": "MGMT 3000",
          "name": "Organizational Behavior ",
          "CUs": "3"
        },
        "D072": {
          "courseID": "D072",
          "CCN": "BUS 2010",
          "name": "Fundamentals for Success in Business ",
          "CUs": "3"
        },
        "D468": {
          "courseID": "D468",
          "CCN": "BUS 2780",
          "name": "Discovering Data ",
          "CUs": "3"
        },
        "C722": {
          "courseID": "C722",
          "CCN": "MGMT 3400",
          "name": "Project Management ",
          "CUs": "3"
        },
        "D078": {
          "courseID": "D078",
          "CCN": "BUS 2060",
          "name": "Business Environment Applications I: Business Structures and Legal Environment ",
          "CUs": "2"
        },
        "D467": {
          "courseID": "D467",
          "CCN": "BUS 2770",
          "name": "Exploring Data ",
          "CUs": "3"
        },
        "D082": {
          "courseID": "D082",
          "CCN": "BUS 2090",
          "name": "Emotional and Cultural Intelligence ",
          "CUs": "3"
        },
        "D466": {
          "courseID": "D466",
          "CCN": "BUS 2760",
          "name": "Analyzing and Visualizing Data ",
          "CUs": "3"
        },
        "C273": {
          "courseID": "C273",
          "CCN": "SOCG 1010",
          "name": "Introduction to Sociology ",
          "CUs": "3"
        },
        "D196": {
          "courseID": "D196",
          "CCN": "ACCT 2020",
          "name": "Principles of Financial and Managerial Accounting ",
          "CUs": "3"
        },
        "D465": {
          "courseID": "D465",
          "CCN": "BUS 2750",
          "name": "Data Applications ",
          "CUs": "3"
        },
        "D089": {
          "courseID": "D089",
          "CCN": "ECON 2000",
          "name": "Principles of Economics ",
          "CUs": "3"
        },
        "C717": {
          "courseID": "C717",
          "CCN": "BUS 3000",
          "name": "Business Ethics ",
          "CUs": "3"
        },
        "D464": {
          "courseID": "D464",
          "CCN": "BUS 2740",
          "name": "Managing Operations ",
          "CUs": "3"
        },
        "D076": {
          "courseID": "D076",
          "CCN": "BUS 2040",
          "name": "Finance Skills for Managers ",
          "CUs": "3"
        },
        "D253": {
          "courseID": "D253",
          "CCN": "MGMT 2700",
          "name": "Values-Based Leadership ",
          "CUs": "3"
        },
        "D080": {
          "courseID": "D080",
          "CCN": "BUS 2070",
          "name": "Managing in a Global Business Environment ",
          "CUs": "3"
        },
        "D470": {
          "courseID": "D470",
          "CCN": "BUS 3900",
          "name": "Transportation, Logistics, and Distribution ",
          "CUs": "3"
        },
        "D361": {
          "courseID": "D361",
          "CCN": "BUS 2111",
          "name": "Business Simulation ",
          "CUs": "4"
        },
        "D471": {
          "courseID": "D471",
          "CCN": "BUS 3910",
          "name": "Global Supply Chain Management ",
          "CUs": "3"
        },
        "D469": {
          "courseID": "D469",
          "CCN": "BUS 3890",
          "name": "Quality, Continuous Improvement, and Lean Six Sigma ",
          "CUs": "3"
        },
        "D472": {
          "courseID": "D472",
          "CCN": "BUS 4900",
          "name": "21st Century Operations and Supply Chain ",
          "CUs": "3"
        },
        "D473": {
          "courseID": "D473",
          "CCN": "BUS 4910",
          "name": "Solutions Design and Visualization Capstone ",
          "CUs": "3"
        },
        "D363": {
          "courseID": "D363",
          "CCN": "FINC 2000",
          "name": "Personal Finance ",
          "CUs": "3"
        },
        "D351": {
          "courseID": "D351",
          "CCN": "HRM 2110",
          "name": "Functions of Human Resource Management ",
          "CUs": "3"
        },
        "D081": {
          "courseID": "D081",
          "CCN": "BUS 2080",
          "name": "Innovative and Strategic Thinking ",
          "CUs": "3"
        },
        "C723": {
          "courseID": "C723",
          "CCN": "BUS 3100",
          "name": "Quantitative Analysis For Business ",
          "CUs": "3"
        },
        "D075": {
          "courseID": "D075",
          "CCN": "BUS 2030",
          "name": "Information Technology Management Essentials ",
          "CUs": "3"
        },
        "D079": {
          "courseID": "D079",
          "CCN": "BUS 2061",
          "name": "Business Environment Applications II: Process, Logistics, and Operations ",
          "CUs": "2"
        },
        "D366": {
          "courseID": "D366",
          "CCN": "FINC 3103",
          "name": "Financial Statement Analysis ",
          "CUs": "3"
        },
        "D216": {
          "courseID": "D216",
          "CCN": "ACCT 3350",
          "name": "Business Law for Accountants ",
          "CUs": "3"
        },
        "D077": {
          "courseID": "D077",
          "CCN": "BUS 2050",
          "name": "Concepts in Marketing, Sales, and Customer Contact ",
          "CUs": "3"
        },
        "D362": {
          "courseID": "D362",
          "CCN": "FINC 3100",
          "name": "Corporate Finance ",
          "CUs": "3"
        },
        "D352": {
          "courseID": "D352",
          "CCN": "HRM 3110",
          "name": "Employment and Labor Law ",
          "CUs": "3"
        },
        "C720": {
          "courseID": "C720",
          "CCN": "MGMT 4100",
          "name": "Operations and Supply Chain Management ",
          "CUs": "3"
        },
        "D364": {
          "courseID": "D364",
          "CCN": "FINC 3101",
          "name": "Financial Management I ",
          "CUs": "3"
        },
        "D365": {
          "courseID": "D365",
          "CCN": "FINC 3102",
          "name": "Financial Management II ",
          "CUs": "3"
        },
        "D368": {
          "courseID": "D368",
          "CCN": "FINC 3105",
          "name": "Enterprise Risk Management ",
          "CUs": "3"
        },
        "D367": {
          "courseID": "D367",
          "CCN": "FINC 3104",
          "name": "Innovation in Finance ",
          "CUs": "3"
        },
        "D369": {
          "courseID": "D369",
          "CCN": "FINC 3106",
          "name": "Finance Capstone ",
          "CUs": "3"
        },
        "C430": {
          "courseID": "C430",
          "CCN": "HCM 3310",
          "name": "Healthcare Quality Improvement and Risk Management ",
          "CUs": "3"
        },
        "C168": {
          "courseID": "C168",
          "CCN": "PHIL 3010",
          "name": "Critical Thinking and Logic ",
          "CUs": "3"
        },
        "C425": {
          "courseID": "C425",
          "CCN": "HCM 2110",
          "name": "Healthcare Delivery Systems, Regulation, and Compliance ",
          "CUs": "3"
        },
        "C426": {
          "courseID": "C426",
          "CCN": "HCM 2210",
          "name": "Healthcare Values and Ethics ",
          "CUs": "3"
        },
        "C427": {
          "courseID": "C427",
          "CCN": "HCM 2310",
          "name": "Technology Applications in Healthcare ",
          "CUs": "3"
        },
        "D100": {
          "courseID": "D100",
          "CCN": "BUS 2140",
          "name": "Introduction to Spreadsheets ",
          "CUs": "1"
        },
        "C428": {
          "courseID": "C428",
          "CCN": "HCM 3110",
          "name": "Financial Resource Management in Healthcare ",
          "CUs": "3"
        },
        "C716": {
          "courseID": "C716",
          "CCN": "BUS 2600",
          "name": "Business Communication ",
          "CUs": "3"
        },
        "C429": {
          "courseID": "C429",
          "CCN": "HCM 3210",
          "name": "Healthcare Operations Management ",
          "CUs": "3"
        },
        "C431": {
          "courseID": "C431",
          "CCN": "HCM 3410",
          "name": "Healthcare Research and Statistics ",
          "CUs": "3"
        },
        "C236": {
          "courseID": "C236",
          "CCN": "HRM 3600",
          "name": "Compensation and Benefits ",
          "CUs": "3"
        },
        "C232": {
          "courseID": "C232",
          "CCN": "HRM 2100",
          "name": "Introduction to Human Resource Management ",
          "CUs": "3"
        },
        "C432": {
          "courseID": "C432",
          "CCN": "HCM 3510",
          "name": "Healthcare Management and Strategy ",
          "CUs": "3"
        },
        "C439": {
          "courseID": "C439",
          "CCN": "HCM 2910",
          "name": "Healthcare Management Capstone ",
          "CUs": "4"
        },
        "D102": {
          "courseID": "D102",
          "CCN": "ACCT 2313",
          "name": "Financial Accounting ",
          "CUs": "3"
        },
        "C237": {
          "courseID": "C237",
          "CCN": "ACCT 3630",
          "name": "Taxation I ",
          "CUs": "3"
        },
        "D101": {
          "courseID": "D101",
          "CCN": "ACCT 3314",
          "name": "Cost and Managerial Accounting ",
          "CUs": "3"
        },
        "D103": {
          "courseID": "D103",
          "CCN": "ACCT 3611",
          "name": "Intermediate Accounting I ",
          "CUs": "3"
        },
        "C233": {
          "courseID": "C233",
          "CCN": "HRM 3100",
          "name": "Employment Law ",
          "CUs": "3"
        },
        "D104": {
          "courseID": "D104",
          "CCN": "ACCT 3621",
          "name": "Intermediate Accounting II ",
          "CUs": "3"
        },
        "D217": {
          "courseID": "D217",
          "CCN": "ACCT 3360",
          "name": "Accounting Information Systems ",
          "CUs": "3"
        },
        "D105": {
          "courseID": "D105",
          "CCN": "ACCT 3650",
          "name": "Intermediate Accounting III ",
          "CUs": "3"
        },
        "D215": {
          "courseID": "D215",
          "CCN": "ACCT 3340",
          "name": "Auditing ",
          "CUs": "3"
        },
        "VZT1": {
          "courseID": "VZT1",
          "CCN": "BUSI 3731",
          "name": "Marketing Applications ",
          "CUs": "3"
        },
        "D174": {
          "courseID": "D174",
          "CCN": "MKTG 2150",
          "name": "Marketing Management ",
          "CUs": "3"
        },
        "D175": {
          "courseID": "D175",
          "CCN": "MKTG 3850",
          "name": "Consumer Behavior ",
          "CUs": "3"
        },
        "QHT1": {
          "courseID": "QHT1",
          "CCN": "BUS 4400",
          "name": "Business Management Tasks ",
          "CUs": "3"
        },
        "D098": {
          "courseID": "D098",
          "CCN": "BUS 3120",
          "name": "Digital Marketing ",
          "CUs": "3"
        },
        "D176": {
          "courseID": "D176",
          "CCN": "MKTG 3860",
          "name": "Content Marketing ",
          "CUs": "3"
        },
        "D177": {
          "courseID": "D177",
          "CCN": "MKTG 3870",
          "name": "Brand Management ",
          "CUs": "3"
        },
        "D266": {
          "courseID": "D266",
          "CCN": "HIST 1016",
          "name": "World History: Diverse Cultures and Global Connections ",
          "CUs": "3"
        },
        "D099": {
          "courseID": "D099",
          "CCN": "BUS 3130",
          "name": "Sales Management ",
          "CUs": "3"
        },
        "D178": {
          "courseID": "D178",
          "CCN": "BUS 3880",
          "name": "Marketing Strategy and Analytics ",
          "CUs": "3"
        },
        "D354": {
          "courseID": "D354",
          "CCN": "HRM 3520",
          "name": "Talent Acquisition ",
          "CUs": "3"
        },
        "D355": {
          "courseID": "D355",
          "CCN": "HRM 3530",
          "name": "Total Rewards ",
          "CUs": "3"
        },
        "D353": {
          "courseID": "D353",
          "CCN": "HRM 3510",
          "name": "Strategic Training and Development ",
          "CUs": "3"
        },
        "D356": {
          "courseID": "D356",
          "CCN": "HRM 3540",
          "name": "HR Technology ",
          "CUs": "3"
        },
        "D357": {
          "courseID": "D357",
          "CCN": "HRM 3550",
          "name": "Diversity, Equity, and Inclusion ",
          "CUs": "3"
        },
        "D358": {
          "courseID": "D358",
          "CCN": "HRM 3560",
          "name": "Global Human Resource Management ",
          "CUs": "3"
        },
        "D359": {
          "courseID": "D359",
          "CCN": "HRM 3570",
          "name": "Agile HR ",
          "CUs": "3"
        },
        "D360": {
          "courseID": "D360",
          "CCN": "HRM 3100",
          "name": "HRM Capstone ",
          "CUs": "3"
        },
        "AFT2": {
          "courseID": "AFT2",
          "CCN": "HCM 5000",
          "name": "Accreditation Audit ",
          "CUs": "4"
        },
        "AMT2": {
          "courseID": "AMT2",
          "CCN": "HCM 6000",
          "name": "Service Line Development ",
          "CUs": "4"
        },
        "C219": {
          "courseID": "C219",
          "CCN": "HCM 6900",
          "name": "MBA, Healthcare Management Capstone ",
          "CUs": "4"
        },
        "C494": {
          "courseID": "C494",
          "CCN": "NURS 2000",
          "name": "Advanced Standing for RN License ",
          "CUs": "50"
        },
        "D235": {
          "courseID": "D235",
          "CCN": "NURS 3114",
          "name": "Interprofessional Communication and Leadership in Healthcare ",
          "CUs": "2"
        },
        "D312": {
          "courseID": "D312",
          "CCN": "SCIE 1011",
          "name": "Anatomy and Physiology I with Lab ",
          "CUs": "4"
        },
        "D313": {
          "courseID": "D313",
          "CCN": "SCIE 1012",
          "name": "Anatomy and Physiology II with Lab ",
          "CUs": "4"
        },
        "D311": {
          "courseID": "D311",
          "CCN": "NURS 1010",
          "name": "Microbiology with Lab: A Fundamental Approach ",
          "CUs": "4"
        },
        "D202": {
          "courseID": "D202",
          "CCN": "PSYC 1020",
          "name": "Human Growth and Development ",
          "CUs": "3"
        },
        "D218": {
          "courseID": "D218",
          "CCN": "NURS 3600",
          "name": "Intrapersonal Leadership and Professional Growth ",
          "CUs": "3"
        },
        "D219": {
          "courseID": "D219",
          "CCN": "NURS 3610",
          "name": "Scholarship in Nursing Practice ",
          "CUs": "3"
        },
        "D236": {
          "courseID": "D236",
          "CCN": "NURS 2508",
          "name": "Pathophysiology ",
          "CUs": "3"
        },
        "D220": {
          "courseID": "D220",
          "CCN": "NURS 3620",
          "name": "Information Technology in Nursing Practice ",
          "CUs": "3"
        },
        "D221": {
          "courseID": "D221",
          "CCN": "NURS 3630",
          "name": "Organizational Systems and Healthcare Transformation ",
          "CUs": "3"
        },
        "D222": {
          "courseID": "D222",
          "CCN": "NURS 3640",
          "name": "Comprehensive Health Assessment ",
          "CUs": "3"
        },
        "D223": {
          "courseID": "D223",
          "CCN": "NURS 2650",
          "name": "Healthcare Policy and Economics ",
          "CUs": "3"
        },
        "D224": {
          "courseID": "D224",
          "CCN": "NURS 3660",
          "name": "Global and Population Health ",
          "CUs": "4"
        },
        "D225": {
          "courseID": "D225",
          "CCN": "NURS 3670",
          "name": "Emerging Professional Practice ",
          "CUs": "3"
        },
        "D226": {
          "courseID": "D226",
          "CCN": "NURS 3660",
          "name": "BSNU Capstone ",
          "CUs": "3"
        },
        "D390": {
          "courseID": "D390",
          "CCN": "HLTH 2130",
          "name": "Introduction to Health and Human Services ",
          "CUs": "3"
        },
        "D391": {
          "courseID": "D391",
          "CCN": "HLTH 2012",
          "name": "Healthcare Ecosystems ",
          "CUs": "3"
        },
        "D458": {
          "courseID": "D458",
          "CCN": "PHIL 1031",
          "name": "Introduction to Systems Thinking for Health Professionals ",
          "CUs": "3"
        },
        "D392": {
          "courseID": "D392",
          "CCN": "HLTH 2140",
          "name": "Interdisciplinary Team Dynamics ",
          "CUs": "3"
        },
        "D393": {
          "courseID": "D393",
          "CCN": "HLTH 2160",
          "name": "History of Healthcare in America ",
          "CUs": "3"
        },
        "D394": {
          "courseID": "D394",
          "CCN": "HLTH 2220",
          "name": "Care for Individuals and Families ",
          "CUs": "3"
        },
        "D395": {
          "courseID": "D395",
          "CCN": "HLTH 2230",
          "name": "Cultural Awareness ",
          "CUs": "3"
        },
        "D396": {
          "courseID": "D396",
          "CCN": "HLTH 2320",
          "name": "Evidence-Based Practice for Health and Human Services ",
          "CUs": "3"
        },
        "D397": {
          "courseID": "D397",
          "CCN": "HLTH 2330",
          "name": "Health Equity and Social Determinants of Health ",
          "CUs": "3"
        },
        "D399": {
          "courseID": "D399",
          "CCN": "HLTH 2500",
          "name": "Introduction to Gerontology ",
          "CUs": "3"
        },
        "D400": {
          "courseID": "D400",
          "CCN": "HLTH 3300",
          "name": "End-of-Life Care ",
          "CUs": "3"
        },
        "D401": {
          "courseID": "D401",
          "CCN": "HLTH 3310",
          "name": "Introduction to Epidemiology ",
          "CUs": "3"
        },
        "D402": {
          "courseID": "D402",
          "CCN": "HLTH 3320",
          "name": "Community and Public Health ",
          "CUs": "4"
        },
        "D403": {
          "courseID": "D403",
          "CCN": "HLTH 3330",
          "name": "Understanding Substance Abuse and Addiction ",
          "CUs": "3"
        },
        "D404": {
          "courseID": "D404",
          "CCN": "HLTH 3340",
          "name": "Healthcare Values and Ethics ",
          "CUs": "3"
        },
        "D405": {
          "courseID": "D405",
          "CCN": "HLTH 4416",
          "name": "Financial Resource Management ",
          "CUs": "and"
        },
        "D406": {
          "courseID": "D406",
          "CCN": "HLTH 3350",
          "name": "Health Literacy for the Client and Family ",
          "CUs": "3"
        },
        "D407": {
          "courseID": "D407",
          "CCN": "HLTH 3420",
          "name": "Models of Care and Healthcare Trends ",
          "CUs": "3"
        },
        "D408": {
          "courseID": "D408",
          "CCN": "HLTH 4430",
          "name": "Community Relations and Leadership ",
          "CUs": "3"
        },
        "D409": {
          "courseID": "D409",
          "CCN": "HLTH 4920",
          "name": "Health and Human Services Professional Field Experience ",
          "CUs": "3"
        },
        "D410": {
          "courseID": "D410",
          "CCN": "HLTH 4921",
          "name": "Health & Human Services Professional Capstone ",
          "CUs": "3"
        },
        "D024": {
          "courseID": "D024",
          "CCN": "NURS 5201",
          "name": "Professional Presence and Influence ",
          "CUs": "2"
        },
        "D115": {
          "courseID": "D115",
          "CCN": "NURS 5800",
          "name": "Advanced Pathophysiology for the Advanced Practice Nurse ",
          "CUs": "4"
        },
        "D025": {
          "courseID": "D025",
          "CCN": "NURS 5202",
          "name": "Essentials of Advanced Nursing Roles and Interprofessional Practice ",
          "CUs": "2"
        },
        "D026": {
          "courseID": "D026",
          "CCN": "NURS 5203",
          "name": "Quality Outcomes in a Culture of Value-Based Nursing Care ",
          "CUs": "2"
        },
        "D116": {
          "courseID": "D116",
          "CCN": "NURS 6800",
          "name": "Advanced Pharmacology for the Advanced Practice Nurse ",
          "CUs": "4"
        },
        "D029": {
          "courseID": "D029",
          "CCN": "NURS 5206",
          "name": "Informatics for Transforming Nursing Care ",
          "CUs": "3"
        },
        "D030": {
          "courseID": "D030",
          "CCN": "NURS 5207",
          "name": "Leadership and Management in Complex Healthcare Systems ",
          "CUs": "3"
        },
        "D117": {
          "courseID": "D117",
          "CCN": "NURS 6810",
          "name": "Advanced Health Assessment for the Advanced Practice Nurse ",
          "CUs": "4"
        },
        "D118": {
          "courseID": "D118",
          "CCN": "NURS 6820",
          "name": "Adult Primary Care for the Advanced Practice Nurse ",
          "CUs": "3"
        },
        "D031": {
          "courseID": "D031",
          "CCN": "NURS 6308",
          "name": "Advancing Evidence-Based Innovation in Nursing Practice ",
          "CUs": "3"
        },
        "D119": {
          "courseID": "D119",
          "CCN": "NURS 6830",
          "name": "Pediatric Primary Care for the Advanced Practice Nurse ",
          "CUs": "3"
        },
        "D120": {
          "courseID": "D120",
          "CCN": "NURS 6840",
          "name": "Special Populations Primary Care for the Advanced Practice Nurse ",
          "CUs": "3"
        },
        "D121": {
          "courseID": "D121",
          "CCN": "NURS 6820",
          "name": "Health Promotion of Patients and Populations Across the Lifespan ",
          "CUs": "3"
        },
        "D122": {
          "courseID": "D122",
          "CCN": "NURS 6830",
          "name": "Family Nurse Practitioner Clinical Internship I ",
          "CUs": "3"
        },
        "D123": {
          "courseID": "D123",
          "CCN": "NURS 6861",
          "name": "Family Nurse Practitioner Clinical Internship II ",
          "CUs": "3"
        },
        "D124": {
          "courseID": "D124",
          "CCN": "NURS 6850",
          "name": "Family Nurse Practitioner Clinical Internship III ",
          "CUs": "3"
        }
      }
    export const allCoursesObjectOld2 = {
        "D322": {
          "courseID": "D322",
          "CCN": "ITEC 2002",
          "name": "Introduction to IT ",
          "CUs": "4"
        },
        "C955": {
          "courseID": "C955",
          "CCN": "MATH 1101",
          "name": "Applied Probability and Statistics ",
          "CUs": "3"
        },
        "D315": {
          "courseID": "D315",
          "CCN": "ITEC 2112",
          "name": "Network and Security - Foundations ",
          "CUs": "3"
        },
        "D278": {
          "courseID": "D278",
          "CCN": "ITSW 2113",
          "name": "Scripting and Programming - Foundations ",
          "CUs": "3"
        },
        "D426": {
          "courseID": "D426",
          "CCN": "ITEC 2116",
          "name": "Data Management - Foundations ",
          "CUs": "3"
        },
        "D276": {
          "CCN": "ITSW 2120",
          "courseID": "D276",
          "name": "Web Development Foundations",
          "description": "Introduces the fundamentals of web development, focusing on HTML, CSS, and basic web technologies.",
          "CUs": 3,
          "term": 2
        },
        "C958": {
          "CCN": "MATH 2100",
          "courseID": "C958",
          "name": "Calculus I",
          "description": "An introductory course in calculus, exploring the concepts of limits, derivatives, and integrals.",
          "CUs": 4,
          "term": 2
        },
        "D199": {
          "CCN": "GEOG 1312",
          "courseID": "D199",
          "name": "Introduction to Physical and Human Geography",
          "description": "Explores the physical aspects of the Earth and the human activities that shape our environment, emphasizing the relationship between nature and human society.",
          "CUs": 3,
          "term": 3
        },
        "C458": {
          "courseID": "C458",
          "CCN": "HLTH 1010",
          "name": "Health, Fitness, and Wellness ",
          "CUs": "4"
        },
        "C959": {
          "CCN": "MATH 2800",
          "courseID": "C959",
          "name": "Discrete Mathematics I",
          "description": "Introduces discrete mathematics, focusing on topics like logic, set theory, and combinatorics, foundational for computer science and engineering.",
          "CUs": 4,
          "term": 3
        },
        "D197": {
          "CCN": "ITSW 2110",
          "courseID": "D197",
          "name": "Version Control",
          "description": "Covers the basics of version control systems, essential for managing changes in digital projects, particularly in software development.",
          "CUs": 1,
          "term": 3
        },
        "C960": {
          "CCN": "MATH 2810",
          "courseID": "C960",
          "name": "Discrete Mathematics II",
          "description": "Continues from Discrete Mathematics I, delving deeper into topics such as graph theory, number theory, and algorithmic thinking.",
          "CUs": 4,
          "term": 4
        },
        "D268": {
          "courseID": "D268",
          "CCN": "COMM 3015",
          "name": "Introduction to Communication: Connecting with Others ",
          "CUs": "3"
        },
        "C952": {
          "CCN": "ICSC 3120",
          "courseID": "C952",
          "name": "Computer Architecture",
          "description": "Examines the structure and functioning of computer systems, including processors, memory, and input/output interfaces.",
          "CUs": 3,
          "term": 4
        },
        "D270": {
          "courseID": "D270",
          "CCN": "ENGL 1712",
          "name": "Composition: Successful Self-Expression ",
          "CUs": "3"
        },
        "D427": {
          "courseID": "D427",
          "CCN": "ITEC 2117",
          "name": "Data Management - Applications ",
          "CUs": "4"
        },
        "D198": {
          "courseNumber": "D198",
          "CCN": "HUMN 1020",
          "name": "Global Arts and Humanities",
          "description": "Provides an interdisciplinary overview of global artistic and humanistic traditions, examining their cultural, historical, and philosophical contexts.",
          "CUs": 3,
          "term": 5
        },
        "D286": {
          "courseNumber": "D286",
          "CCN": "ITSW 3172",
          "name": "Java Fundamentals",
          "description": "Covers the fundamental concepts and techniques of Java programming, focusing on object-oriented programming, data structures, and algorithm development.",
          "CUs": 3,
          "term": 5
        },
        "D287": {
          "courseNumber": "D287",
          "CCN": "ITSW 3173",
          "name": "Java Frameworks",
          "description": "Introduces popular Java frameworks and libraries, emphasizing their application in building robust and scalable software applications.",
          "CUs": 3,
          "term": 6
        },
        "C949": {
          "courseNumber": "C949",
          "CCN": "ICSC 2100",
          "name": "Data Structures and Algorithms I",
          "description": "Examines fundamental data structures and algorithms, focusing on their implementation, efficiency, and application in solving computational problems.",
          "CUs": 4,
          "term": 6
        },
        "D288": {
          "courseNumber": "D288",
          "CCN": "ITSW 3175",
          "name": "Back-End Programming",
          "description": "Focuses on server-side programming concepts and technologies, covering topics such as database connectivity, API development, and server scripting.",
          "CUs": 3,
          "term": 6
        },
        "D281": {
          "courseID": "D281",
          "CCN": "ITEC 3004",
          "name": "Linux Foundations ",
          "CUs": "3"
        },
        "D387": {
          "courseNumber": "D387",
          "CCN": "ITSW 3024",
          "name": "Advanced Java",
          "description": "Explores advanced concepts in Java programming, including multi-threading, networking, and GUI development, with a focus on enterprise-level applications.",
          "CUs": 3,
          "term": 7
        },
        "D333": {
          "courseID": "D333",
          "CCN": "HUMN 1101",
          "name": "Ethics in Technology ",
          "CUs": "3"
        },
        "D430": {
          "courseID": "D430",
          "CCN": "ITAS 2110",
          "name": "Fundamentals of Information Security ",
          "CUs": "3"
        },
        "C191": {
          "courseNumber": "C191",
          "CCN": "ITEC 2211",
          "name": "Operating Systems for Programmers",
          "description": "Focuses on the principles of operating systems with an emphasis on features important for programming, such as process management and file systems.",
          "CUs": 3,
          "term": 7
        },
        "C950": {
          "courseNumber": "C950",
          "CCN": "ICSC 3100",
          "name": "Data Structures and Algorithms II",
          "description": "Continues from Data Structures and Algorithms I, covering more complex algorithms and data structures, with a focus on algorithmic strategies and efficiency.",
          "CUs": 4,
          "term": 8
        },
        "C683": {
          "courseID": "C683",
          "CCN": "SCIE 1001",
          "name": "Natural Science Lab ",
          "CUs": "2"
        },
        "D336": {
          "courseID": "D336",
          "CCN": "ITEC 2113",
          "name": "Business of IT – Applications ",
          "CUs": "4"
        },
        "D284": {
          "courseNumber": "D284",
          "CCN": "ITSW 2226",
          "name": "Software Engineering",
          "description": "Covers the principles of software engineering, including software development life cycles, design patterns, and best practices in software design and testing.",
          "CUs": 4,
          "term": 8
        },
        "D326": {
          "courseNumber": "D326",
          "CCN": "DTMG 3179",
          "name": "Advanced Data Management",
          "description": "Delves into advanced topics in data management, including big data analytics, data integration, and the management of large-scale databases.",
          "CUs": 3,
          "term": 9
        },
        "D370": {
          "courseNumber": "D370",
          "CCN": "ITBU 2201",
          "name": "IT Leadership Foundations",
          "description": "Explores the foundational skills and concepts required for leadership roles in IT, including strategic planning, team management, and IT governance.",
          "CUs": 3,
          "term": 9
        },
        "D339": {
          "courseNumber": "D339",
          "CCN": "COMM 1115",
          "name": "Technical Communication",
          "description": "Focuses on the skills needed for effective communication in technical fields, covering technical writing, presentation skills, and communication strategies.",
          "CUs": 3,
          "term": 9
        },
        "C951": {
          "courseNumber": "C951",
          "CCN": "ICSC 3110",
          "name": "Introduction to Artificial Intelligence",
          "description": "Provides an overview of artificial intelligence concepts and applications, including machine learning, neural networks, and AI in problem-solving.",
          "CUs": 3,
          "term": 9
        },
        "D480": {
          "courseNumber": "D480",
          "CCN": "ITSW 3151",
          "name": "Software Design and Quality Assurance",
          "description": "Covers software design principles and quality assurance practices, focusing on creating reliable and maintainable software systems.",
          "CUs": 3,
          "term": 10
        },
        "C964": {
          "courseNumber": "C964",
          "CCN": "ICSC 3130",
          "name": "Computer Science Capstone",
          "description": "A culminating project that integrates and applies computer science knowledge and skills in a real-world scenario or research topic.",
          "CUs": 4,
          "term": 10
        },
        "D265": {
          "courseID": "D265",
          "CCN": "PHIL 1020",
          "name": "Critical Thinking: Reason and Evidence ",
          "CUs": "3"
        },
        "D317": {
          "courseID": "D317",
          "CCN": "ITEC 2023",
          "name": "IT Applications ",
          "CUs": "4"
        },
        "D316": {
          "courseID": "D316",
          "CCN": "ITEC 2013",
          "name": "IT Foundations ",
          "CUs": "4"
        },
        "C841": {
          "courseID": "C841",
          "CCN": "ITAS 3010",
          "name": "Legal Issues in Information Security ",
          "CUs": "4"
        },
        "D372": {
          "courseID": "D372",
          "CCN": "PHIL 1030",
          "name": "Introduction to Systems Thinking ",
          "CUs": "3"
        },
        "C957": {
          "courseID": "C957",
          "CCN": "MATH 1200",
          "name": "Applied Algebra ",
          "CUs": "3"
        },
        "D325": {
          "courseID": "D325",
          "CCN": "ITEC 3602",
          "name": "Networks ",
          "CUs": "4"
        },
        "C844": {
          "courseID": "C844",
          "CCN": "ITAS 3040",
          "name": "Emerging Technologies in Cybersecurity ",
          "CUs": "4"
        },
        "D329": {
          "courseID": "D329",
          "CCN": "ITEC 2112",
          "name": "Network and Security - Applications ",
          "CUs": "4"
        },
        "D431": {
          "courseID": "D431",
          "CCN": "ITAS 2140",
          "name": "Digital Forensics in Cybersecurity ",
          "CUs": "4"
        },
        "C845": {
          "courseID": "C845",
          "CCN": "ITAS 3050",
          "name": "Information Systems Security ",
          "CUs": "4"
        },
        "D334": {
          "courseID": "D334",
          "CCN": "ITAS 2141",
          "name": "Introduction to Cryptography ",
          "CUs": "4"
        },
        "D324": {
          "courseID": "D324",
          "CCN": "ITEC 2109",
          "name": "Business of IT - Project Management ",
          "CUs": "4"
        },
        "D335": {
          "courseID": "D335",
          "CCN": "ITSW 3126",
          "name": "Introduction to Programming in Python ",
          "CUs": "3"
        },
        "C963": {
          "courseID": "C963",
          "CCN": "POLS 1030",
          "name": "American Politics and the US Constitution ",
          "CUs": "3"
        },
        "C843": {
          "courseID": "C843",
          "CCN": "ITAS 3030",
          "name": "Managing Information Security ",
          "CUs": "6"
        },
        "D340": {
          "courseID": "D340",
          "CCN": "ITAS 3021",
          "name": "Cyber Defense and Countermeasures ",
          "CUs": "4"
        },
        "D320": {
          "courseID": "D320",
          "CCN": "ITCL 3202",
          "name": "Managing Cloud Security ",
          "CUs": "4"
        },
        "D332": {
          "courseID": "D332",
          "CCN": "ITAS 3080",
          "name": "Penetration Testing and Vulnerability Analysis ",
          "CUs": "4"
        },
        "C769": {
          "courseID": "C769",
          "CCN": "ITEC 4903",
          "name": "IT Capstone Written Project ",
          "CUs": "4"
        },
        "D491": {
          "courseID": "D491",
          "CCN": "DTAN 3100",
          "name": "Introduction to Analytics ",
          "CUs": "2"
        },
        "D388": {
          "courseID": "D388",
          "CCN": "BUS 2250",
          "name": "Fundamentals of Spreadsheets and Data Presentations ",
          "CUs": "3"
        },
        "C721": {
          "courseID": "C721",
          "CCN": "MGMT 4400",
          "name": "Change Management ",
          "CUs": "3"
        },
        "D428": {
          "courseID": "D428",
          "CCN": "PHIL 1110",
          "name": "Design Thinking for Business ",
          "CUs": "3"
        },
        "D269": {
          "courseID": "D269",
          "CCN": "ENGL 1711",
          "name": "Composition: Writing with a Strategy ",
          "CUs": "3"
        },
        "D282": {
          "courseID": "D282",
          "CCN": "ITEC 2119",
          "name": "Cloud Foundations ",
          "CUs": "3"
        },
        "D386": {
          "courseID": "D386",
          "CCN": "ITEC 2022",
          "name": "Hardware and Operating Systems Essentials ",
          "CUs": "3"
        },
        "D492": {
          "courseID": "D492",
          "CCN": "DTAN 3200",
          "name": "Data Analytics - Applications ",
          "CUs": "4"
        },
        "D420": {
          "courseID": "D420",
          "CCN": "MATH 2820",
          "name": "Discrete Math: Logic ",
          "CUs": "1"
        },
        "D421": {
          "courseID": "D421",
          "CCN": "MATH 2830",
          "name": "Discrete Math: Functions and Relations ",
          "CUs": "1"
        },
        "D493": {
          "courseID": "D493",
          "CCN": "ITSW 2135",
          "name": "Scripting and Programming - Applications ",
          "CUs": "3"
        },
        "D494": {
          "courseID": "D494",
          "CCN": "DTMG 3351",
          "name": "Data and Information Governance ",
          "CUs": "2"
        },
        "D495": {
          "courseID": "D495",
          "CCN": "DTAN 3205",
          "name": "Big Data Foundations ",
          "CUs": "4"
        },
        "D246": {
          "courseID": "D246",
          "CCN": "COMM 1721",
          "name": "Influential Communication through Visual ",
          "CUs": "Design"
        },
        "": {
          "courseID": "",
          "CCN": " ",
          "name": "",
          "CUs": ""
        },
        "D496": {
          "courseID": "D496",
          "CCN": "DTSC 3211",
          "name": "Introduction to Data Science ",
          "CUs": "4"
        },
        "D497": {
          "courseID": "D497",
          "CCN": "DTMG 3221",
          "name": "Data Wrangling ",
          "CUs": "3"
        },
        "D498": {
          "courseID": "D498",
          "CCN": "DTAN 3211",
          "name": "Data Analysis with R ",
          "CUs": "2"
        },
        "D499": {
          "courseID": "D499",
          "CCN": "DTSC 3221",
          "name": "Machine Learning ",
          "CUs": "3"
        },
        "D500": {
          "courseID": "D500",
          "CCN": "DTAN 3221",
          "name": "Data Visualization ",
          "CUs": "2"
        },
        "D501": {
          "courseID": "D501",
          "CCN": "DTSC 3300",
          "name": "Machine Learning DevOps ",
          "CUs": "2"
        },
        "D502": {
          "courseID": "D502",
          "CCN": "DTMG 3901",
          "name": "Data Analytics Capstone ",
          "CUs": "4"
        },
        "C165": {
          "courseID": "C165",
          "CCN": "SCIE 1020",
          "name": "Integrated Physical Sciences ",
          "CUs": "3"
        },
        "C483": {
          "courseID": "C483",
          "CCN": "BUS 2301",
          "name": "Principles of Management ",
          "CUs": "4"
        },
        "C484": {
          "courseID": "C484",
          "CCN": "BUS 2001",
          "name": "Organizational Behavior and Leadership ",
          "CUs": "3"
        },
        "C268": {
          "courseID": "C268",
          "CCN": "BUIT 2200",
          "name": "Spreadsheets ",
          "CUs": "3"
        },
        "C277": {
          "courseID": "C277",
          "CCN": "MATH 1709",
          "name": "Finite Mathematics ",
          "CUs": "4"
        },
        "C777": {
          "courseID": "C777",
          "CCN": "ITWD 3120",
          "name": "Web Development Applications ",
          "CUs": "6"
        },
        "C773": {
          "courseID": "C773",
          "CCN": "ITWD 3110",
          "name": "User Interface Design ",
          "CUs": "4"
        },
        "C724": {
          "courseID": "C724",
          "CCN": "BUIT 3000",
          "name": "Information Systems Management ",
          "CUs": "3"
        },
        "C850": {
          "courseID": "C850",
          "CCN": "ITEC 2950",
          "name": "Emerging Technologies ",
          "CUs": "2"
        },
        "D277": {
          "courseID": "D277",
          "CCN": "ITSW 2131",
          "name": "Front-End Web Development ",
          "CUs": "3"
        },
        "D280": {
          "courseID": "D280",
          "CCN": "ITSW 3151",
          "name": "JavaScript Programming ",
          "CUs": "3"
        },
        "D281": {
          "courseID": "D280",
          "CCN": "ITSW 3151",
          "name": "JavaScript Programming 2",
          "CUs": "3"
        },
        "D279": {
          "courseID": "D279",
          "CCN": "ITSW 3110",
          "name": "User Interface Design ",
          "CUs": "3"
        },
        "D479": {
          "courseID": "D479",
          "CCN": "ITSW 3111",
          "name": "User Experience Design ",
          "CUs": "3"
        },
        "D385": {
          "courseID": "D385",
          "CCN": "ITEC 2034",
          "name": "Software Security and Testing ",
          "CUs": "3"
        },
        "D308": {
          "courseID": "D308",
          "CCN": "ITSW 3034",
          "name": "Mobile Application Development (Android) ",
          "CUs": "3"
        },
        "D424": {
          "courseID": "D424",
          "CCN": "ITEC 4906",
          "name": "Software Engineering Capstone ",
          "CUs": "4"
        },
        "C190": {
          "courseID": "C190",
          "CCN": "BIO 1010",
          "name": "Introduction to Biology ",
          "CUs": "3"
        },
        "D318": {
          "courseID": "D318",
          "CCN": "ITEC 3601",
          "name": "Cloud Applications ",
          "CUs": "3"
        },
        "D330": {
          "courseID": "D330",
          "CCN": "DBMG 3380",
          "name": "Data Systems Administration ",
          "CUs": "3"
        },
        "D411": {
          "courseID": "D411",
          "CCN": "ITSW 3170",
          "name": "Scripting and Automation ",
          "CUs": "2"
        },
        "D338": {
          "courseID": "D338",
          "CCN": "ITCL 3204",
          "name": "Cloud Platform Solutions ",
          "CUs": "3"
        },
        "D319": {
          "courseID": "D319",
          "CCN": "ITCL 3201",
          "name": "AWS Cloud Architecture ",
          "CUs": "3"
        },
        "D321": {
          "courseID": "D321",
          "CCN": "ITCL 3203",
          "name": "AWS Developer ",
          "CUs": "3"
        },
        "D341": {
          "courseID": "D341",
          "CCN": "ITEC 3005",
          "name": "Cloud Deployment and Operations ",
          "CUs": "3"
        },
        "D337": {
          "courseID": "D337",
          "CCN": "ITEC 2114",
          "name": "Internet of Things (IoT) and Infrastructure ",
          "CUs": "3"
        },
        "D342": {
          "courseID": "D342",
          "CCN": "ITCL 4179",
          "name": "Cloud Computing Capstone ",
          "CUs": "4"
        }
      }

    export const allCoursesObjectOld = {
        "D322": {
            "CCN": "ITEC 2002",
            "courseID": "D322",
            "name": "Introduction to IT",
            "description": "This course offers an overview of key information technology concepts, including the fundamentals of computer systems, software, and networks.",
            "CUs": 4,
            "term": 1
        },
        "C955": {
            "CCN": "MATH 1101",
            "courseID": "C955",
            "name": "Applied Probability and Statistics",
            "description": "Focuses on practical applications of probability and statistics in various fields, emphasizing statistical analysis and data interpretation.",
            "CUs": 3,
            "term": 1
        },
        "D315": {
            "CCN": "ITEC 2112",
            "courseID": "D315",
            "name": "Network and Security - Foundations",
            "description": "Introduces foundational concepts in networking and cybersecurity, covering basic network architecture and security principles.",
            "CUs": 3,
            "term": 1
        },
        "D278": {
            "CCN": "ITSW 2113",
            "courseID": "D278",
            "name": "Scripting and Programming - Foundations",
            "description": "Provides a foundational understanding of programming and scripting, focusing on algorithm development and basic programming skills.",
            "CUs": 3,
            "term": 1
        },
        "D426": {
            "CCN": "ITEC 2116",
            "courseID": "D426",
            "name": "Data Management - Foundations",
            "description": "Covers the basics of data management, including database design, data storage, and retrieval techniques.",
            "CUs": 3,
            "term": 2
        },
        "D276": {
            "CCN": "ITSW 2120",
            "courseID": "D276",
            "name": "Web Development Foundations",
            "description": "Introduces the fundamentals of web development, focusing on HTML, CSS, and basic web technologies.",
            "CUs": 3,
            "term": 2
        },
        "C958": {
            "CCN": "MATH 2100",
            "courseID": "C958",
            "name": "Calculus I",
            "description": "An introductory course in calculus, exploring the concepts of limits, derivatives, and integrals.",
            "CUs": 4,
            "term": 2
        },
        "D199": {
            "CCN": "GEOG 1312",
            "courseID": "D199",
            "name": "Introduction to Physical and Human Geography",
            "description": "Explores the physical aspects of the Earth and the human activities that shape our environment, emphasizing the relationship between nature and human society.",
            "CUs": 3,
            "term": 3
        },
        "C458": {
            "CCN": "HLTH 1010",
            "courseID": "C458",
            "name": "Health, Fitness, and Wellness",
            "description": "Focuses on principles and practices for maintaining health and wellness, covering topics such as nutrition, physical fitness, and stress management.",
            "CUs": 4,
            "term": 3
        },
        "C959": {
            "CCN": "MATH 2800",
            "courseID": "C959",
            "name": "Discrete Mathematics I",
            "description": "Introduces discrete mathematics, focusing on topics like logic, set theory, and combinatorics, foundational for computer science and engineering.",
            "CUs": 4,
            "term": 3
        },
        "D197": {
            "CCN": "ITSW 2110",
            "courseID": "D197",
            "name": "Version Control",
            "description": "Covers the basics of version control systems, essential for managing changes in digital projects, particularly in software development.",
            "CUs": 1,
            "term": 3
        },
        "C960": {
            "CCN": "MATH 2810",
            "courseID": "C960",
            "name": "Discrete Mathematics II",
            "description": "Continues from Discrete Mathematics I, delving deeper into topics such as graph theory, number theory, and algorithmic thinking.",
            "CUs": 4,
            "term": 4
        },
        "D268": {
            "CCN": "COMM 3015",
            "courseID": "D268",
            "name": "Introduction to Communication: Connecting with Others",
            "description": "Explores the fundamental concepts of human communication, focusing on improving interpersonal skills and understanding communication dynamics in various contexts.",
            "CUs": 3,
            "term": 4
        },
        "C952": {
            "CCN": "ICSC 3120",
            "courseID": "C952",
            "name": "Computer Architecture",
            "description": "Examines the structure and functioning of computer systems, including processors, memory, and input/output interfaces.",
            "CUs": 3,
            "term": 4
        },
        "D270": {
            "CCN": "ENGL 1712",
            "courseID": "D270",
            "name": "Composition: Successful Self-Expression",
            "description": "Focuses on developing effective writing skills, emphasizing clarity, coherence, and personal style in written communication.",
            "CUs": 3,
            "term": 4
        },
        "D427": {
            "courseNumber": "D427",
            "CCN": "ITEC 2117",
            "name": "Data Management - Applications",
            "description": "Focuses on advanced data management techniques and applications, including data warehousing, data mining, and database administration.",
            "CUs": 4,
            "term": 5
        },
        "D198": {
            "courseNumber": "D198",
            "CCN": "HUMN 1020",
            "name": "Global Arts and Humanities",
            "description": "Provides an interdisciplinary overview of global artistic and humanistic traditions, examining their cultural, historical, and philosophical contexts.",
            "CUs": 3,
            "term": 5
        },
        "D286": {
            "courseNumber": "D286",
            "CCN": "ITSW 3172",
            "name": "Java Fundamentals",
            "description": "Covers the fundamental concepts and techniques of Java programming, focusing on object-oriented programming, data structures, and algorithm development.",
            "CUs": 3,
            "term": 5
        },
        "D287": {
            "courseNumber": "D287",
            "CCN": "ITSW 3173",
            "name": "Java Frameworks",
            "description": "Introduces popular Java frameworks and libraries, emphasizing their application in building robust and scalable software applications.",
            "CUs": 3,
            "term": 6
        },
        "C949": {
            "courseNumber": "C949",
            "CCN": "ICSC 2100",
            "name": "Data Structures and Algorithms I",
            "description": "Examines fundamental data structures and algorithms, focusing on their implementation, efficiency, and application in solving computational problems.",
            "CUs": 4,
            "term": 6
        },
        "D288": {
            "courseNumber": "D288",
            "CCN": "ITSW 3175",
            "name": "Back-End Programming",
            "description": "Focuses on server-side programming concepts and technologies, covering topics such as database connectivity, API development, and server scripting.",
            "CUs": 3,
            "term": 6
        },
        "D281": {
            "courseNumber": "D281",
            "CCN": "ITEC 3004",
            "name": "Linux Foundations",
            "description": "Provides a comprehensive introduction to Linux operating systems, covering system installation, configuration, command line utilities, and basic scripting.",
            "CUs": 3,
            "term": 6
        },
        "D387": {
            "courseNumber": "D387",
            "CCN": "ITSW 3024",
            "name": "Advanced Java",
            "description": "Explores advanced concepts in Java programming, including multi-threading, networking, and GUI development, with a focus on enterprise-level applications.",
            "CUs": 3,
            "term": 7
        },
        "D333": {
            "courseNumber": "D333",
            "CCN": "HUMN 1101",
            "name": "Ethics in Technology",
            "description": "Examines ethical issues and challenges in the field of technology, emphasizing the responsible use of technology in various professional settings.",
            "CUs": 3,
            "term": 7
        },
        "D430": {
            "courseNumber": "D430",
            "CCN": "ITAS 2110",
            "name": "Fundamentals of Information Security",
            "description": "Introduces the core principles of information security, including risk management, cryptography, and security policies and procedures.",
            "CUs": 3,
            "term": 7
        },
        "C191": {
            "courseNumber": "C191",
            "CCN": "ITEC 2211",
            "name": "Operating Systems for Programmers",
            "description": "Focuses on the principles of operating systems with an emphasis on features important for programming, such as process management and file systems.",
            "CUs": 3,
            "term": 7
        },
        "C950": {
            "courseNumber": "C950",
            "CCN": "ICSC 3100",
            "name": "Data Structures and Algorithms II",
            "description": "Continues from Data Structures and Algorithms I, covering more complex algorithms and data structures, with a focus on algorithmic strategies and efficiency.",
            "CUs": 4,
            "term": 8
        },
        "C683": {
            "courseNumber": "C683",
            "CCN": "SCIE 1001",
            "name": "Natural Science Lab",
            "description": "Provides hands-on experience in scientific experimentation and research methodologies, reinforcing concepts studied in natural science courses.",
            "CUs": 2,
            "term": 8
        },
        "D336": {
            "courseNumber": "D336",
            "CCN": "ITEC 2113",
            "name": "Business of IT – Applications",
            "description": "Explores the application of IT principles in business settings, focusing on how technology can be used to solve business problems and improve efficiency.",
            "CUs": 4,
            "term": 8
        },
        "D284": {
            "courseNumber": "D284",
            "CCN": "ITSW 2226",
            "name": "Software Engineering",
            "description": "Covers the principles of software engineering, including software development life cycles, design patterns, and best practices in software design and testing.",
            "CUs": 4,
            "term": 8
        },
        "D326": {
            "courseNumber": "D326",
            "CCN": "DTMG 3179",
            "name": "Advanced Data Management",
            "description": "Delves into advanced topics in data management, including big data analytics, data integration, and the management of large-scale databases.",
            "CUs": 3,
            "term": 9
        },
        "D370": {
            "courseNumber": "D370",
            "CCN": "ITBU 2201",
            "name": "IT Leadership Foundations",
            "description": "Explores the foundational skills and concepts required for leadership roles in IT, including strategic planning, team management, and IT governance.",
            "CUs": 3,
            "term": 9
        },
        "D339": {
            "courseNumber": "D339",
            "CCN": "COMM 1115",
            "name": "Technical Communication",
            "description": "Focuses on the skills needed for effective communication in technical fields, covering technical writing, presentation skills, and communication strategies.",
            "CUs": 3,
            "term": 9
        },
        "C951": {
            "courseNumber": "C951",
            "CCN": "ICSC 3110",
            "name": "Introduction to Artificial Intelligence",
            "description": "Provides an overview of artificial intelligence concepts and applications, including machine learning, neural networks, and AI in problem-solving.",
            "CUs": 3,
            "term": 9
        },
        "D480": {
            "courseNumber": "D480",
            "CCN": "ITSW 3151",
            "name": "Software Design and Quality Assurance",
            "description": "Covers software design principles and quality assurance practices, focusing on creating reliable and maintainable software systems.",
            "CUs": 3,
            "term": 10
        },
        "C964": {
            "courseNumber": "C964",
            "CCN": "ICSC 3130",
            "name": "Computer Science Capstone",
            "description": "A culminating project that integrates and applies computer science knowledge and skills in a real-world scenario or research topic.",
            "CUs": 4,
            "term": 10
        },
        "D265": {
            "courseID": "D265",
            "CCN": "PHIL 1020",
            "name": "Critical Thinking: Reason and Evidence ",
            "CUs": "3"
        },
        "D322": {
            "courseID": "D322",
            "CCN": "ITEC 2002",
            "name": "Introduction to IT ",
            "CUs": "4"
        },
        "D270": {
            "courseID": "D270",
            "CCN": "ENGL 1712",
            "name": "Composition: Successful Self-Expression ",
            "CUs": "3"
        },
        "D430": {
            "courseID": "D430",
            "CCN": "ITAS 2110",
            "name": "Fundamentals of Information Security ",
            "CUs": "3"
        },
        "D317": {
            "courseID": "D317",
            "CCN": "ITEC 2023",
            "name": "IT Applications ",
            "CUs": "4"
        },
        "D316": {
            "courseID": "D316",
            "CCN": "ITEC 2013",
            "name": "IT Foundations ",
            "CUs": "4"
        },
        "C841": {
            "courseID": "C841",
            "CCN": "ITAS 3010",
            "name": "Legal Issues in Information Security ",
            "CUs": "4"
        },
        "C955": {
            "courseID": "C955",
            "CCN": "MATH 1101",
            "name": "Applied Probability and Statistics ",
            "CUs": "3"
        },
        "D372": {
            "courseID": "D372",
            "CCN": "PHIL 1030",
            "name": "Introduction to Systems Thinking ",
            "CUs": "3"
        },
        "C957": {
            "courseID": "C957",
            "CCN": "MATH 1200",
            "name": "Applied Algebra ",
            "CUs": "3"
        },
        "D315": {
            "courseID": "D315",
            "CCN": "ITEC 2112",
            "name": "Network and Security - Foundations ",
            "CUs": "3"
        },
        "C458": {
            "courseID": "C458",
            "CCN": "HLTH 1010",
            "name": "Health, Fitness, and Wellness ",
            "CUs": "4"
        },
        "D325": {
            "courseID": "D325",
            "CCN": "ITEC 3602",
            "name": "Networks ",
            "CUs": "4"
        },
        "C844": {
            "courseID": "C844",
            "CCN": "ITAS 3040",
            "name": "Emerging Technologies in Cybersecurity ",
            "CUs": "4"
        },
        "D329": {
            "courseID": "D329",
            "CCN": "ITEC 2112",
            "name": "Network and Security - Applications ",
            "CUs": "4"
        },
        "D431": {
            "courseID": "D431",
            "CCN": "ITAS 2140",
            "name": "Digital Forensics in Cybersecurity ",
            "CUs": "4"
        },
        "D336": {
            "courseID": "D336",
            "CCN": "ITEC 2113",
            "name": "Business of IT – Applications ",
            "CUs": "4"
        },
        "D426": {
            "courseID": "D426",
            "CCN": "ITEC 2116",
            "name": "Data Management - Foundations ",
            "CUs": "3"
        },
        "D427": {
            "courseID": "D427",
            "CCN": "ITEC 2117",
            "name": "Data Management - Applications ",
            "CUs": "4"
        },
        "C683": {
            "courseID": "C683",
            "CCN": "SCIE 1001",
            "name": "Natural Science Lab ",
            "CUs": "2"
        },
        "D281": {
            "courseID": "D281",
            "CCN": "ITEC 3004",
            "name": "Linux Foundations ",
            "CUs": "3"
        },
        "C845": {
            "courseID": "C845",
            "CCN": "ITAS 3050",
            "name": "Information Systems Security ",
            "CUs": "4"
        },
        "D334": {
            "courseID": "D334",
            "CCN": "ITAS 2141",
            "name": "Introduction to Cryptography ",
            "CUs": "4"
        },
        "D324": {
            "courseID": "D324",
            "CCN": "ITEC 2109",
            "name": "Business of IT - Project Management ",
            "CUs": "4"
        },
        "D278": {
            "courseID": "D278",
            "CCN": "ITSW 2113",
            "name": "Scripting and Programming - Foundations ",
            "CUs": "3"
        },
        "D335": {
            "courseID": "D335",
            "CCN": "ITSW 3126",
            "name": "Introduction to Programming in Python ",
            "CUs": "3"
        },
        "C963": {
            "courseID": "C963",
            "CCN": "POLS 1030",
            "name": "American Politics and the US Constitution ",
            "CUs": "3"
        },
        "D333": {
            "courseID": "D333",
            "CCN": "HUMN 1101",
            "name": "Ethics in Technology ",
            "CUs": "3"
        },
        "C843": {
            "courseID": "C843",
            "CCN": "ITAS 3030",
            "name": "Managing Information Security ",
            "CUs": "6"
        },
        "D340": {
            "courseID": "D340",
            "CCN": "ITAS 3021",
            "name": "Cyber Defense and Countermeasures ",
            "CUs": "4"
        },
        "D268": {
            "courseID": "D268",
            "CCN": "COMM 3015",
            "name": "Introduction to Communication: Connecting with Others ",
            "CUs": "3"
        },
        "D320": {
            "courseID": "D320",
            "CCN": "ITCL 3202",
            "name": "Managing Cloud Security ",
            "CUs": "4"
        },
        "D332": {
            "courseID": "D332",
            "CCN": "ITAS 3080",
            "name": "Penetration Testing and Vulnerability Analysis ",
            "CUs": "4"
        },
        "C769": {
            "courseID": "C769",
            "CCN": "ITEC 4903",
            "name": "IT Capstone Written Project ",
            "CUs": "4"
        },
    }


    export const allDescriptions = {
        "AFT2": {
          "courseName": "Accreditation Audit ",
          "description": "Accreditation Audit covers regulatory audits, resource assessment, quality improvement, patient care improvement, organization plans, risk management, effective interaction, and compliance as evidenced during an accreditation audit. "
        },
        "AIT2": {
          "courseName": "Organic Chemistry ",
          "description": "Organic Chemistry focuses on the study of compounds that contain carbon, much of which is learning how to organize and group organic compounds in order to predict their structure, behavior, and reactivity based on common bonds found within an organic compound. "
        },
        "AMT2": {
          "courseName": "Service Line Development ",
          "description": "Service Line Development will address how to critically assess the competitive marketplace as well as the internal environment to establish a new line of business. Topics include needs assessment, international healthcare trends, service line management, revenue analysis, costs and productivity, communication, negotiation, health policy, health legislation, and facilities management, which are variables in the evaluation process. "
        },
        "AOA2": {
          "courseName": "Number Sense and Functions ",
          "description": "Number Sense and Functions is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as number sense, patterns and functions, integers and order of operations, fractions, decimals, and percentages. "
        },
        "ASA1": {
          "courseName": "Assessment Theory and Practice ",
          "description": "Assessment Theory and Practice focuses on issues central to assessment in the ELL environment, including high-stakes testing, standardized tests, placement and exit assessment, formative and summative assessments, and making adaptations in assessments to meet the needs of ELL students. "
        },
        "AUA2": {
          "courseName": "Graphing, Proportional Reasoning and Equations/Inequalities ",
          "description": "Graphing, Proportional Reasoning and Equations/Inequalities is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as coordinate pairs and graphing, ratios and proportional reasoning, and equations and inequalities. "
        },
        "AVA2": {
          "courseName": "Geometry and Statistics ",
          "description": "Geometry and Statistics is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as geometry and measurement, statistics and probability. "
        },
        "BVT1": {
          "courseName": "Physical Chemistry ",
          "description": "Physical Chemistry introduces the study of chemistry in terms of physical concepts. It includes thermodynamics,  reaction kinetics, chemical equilibrium, electrochemistry, and matter. "
        },
        "BVT2": {
          "courseName": "Physical Chemistry ",
          "description": "Physical Chemistry introduces the study of chemistry in terms of physical concepts. It  includes  thermodynamics,  reaction kinetics, chemical equilibrium, electrochemistry, and matter. "
        },
        "BWT1": {
          "courseName": "Inorganic Chemistry ",
          "description": "Inorganic Chemistry introduces the concepts of inorganic chemistry—the branch of chemistry that studies the properties and behavior of any compound avoiding a specific focus on carbon. It will focus on the three most important areas of inorganic chemistry:  the structure, properties, and reactions of various groups of inorganic compounds. "
        },
        "BWT2": {
          "courseName": "Inorganic Chemistry ",
          "description": "Inorganic Chemistry introduces the concepts of inorganic chemistry—the branch of chemistry that studies the properties and behavior of any compound, avoiding a specific focus on carbon. It will focus on the three most important areas of inorganic chemistry:  the structure, properties, and reactions of various groups of inorganic compounds. "
        },
        "BYT1": {
          "courseName": "Physics: Mechanics ",
          "description": "Physics: Mechanics introduces foundational concepts of mechanics, including motion, gravitation, work and energy, momentum and collisions, rotational motion, static equilibrium, fluids, and oscillation. "
        },
        "BYT2": {
          "courseName": "Physics: Mechanics ",
          "description": "Physics: Mechanics introduces foundational concepts of mechanics, including motion, gravitation, work and energy,  momentum and collisions, rotational motion, static equilibrium, fluids, and oscillation. "
        },
        "BZT1": {
          "courseName": "Physics: Waves and Optics ",
          "description": "Physics: Waves and Optics addresses foundational topics in the physics of waves and optics. Students will study basic wave motion and then apply that knowledge to the study of sound and light with even further applications to optical instruments. They will also learn about thermodynamics and theories governing the physics of gases. "
        },
        "BZT2": {
          "courseName": "Physics: Waves and Optics ",
          "description": "Physics: Waves and Optics addresses foundational topics in the physics of waves and optics. Students will study basic wave motion and then apply that knowledge to the study of sound and light with even further applications to optical instruments. This course will also cover thermodynamics and theories governing the physics of gases. "
        },
        "C100": {
          "courseName": "Introduction to Humanities ",
          "description": "This introductory humanities course allows candidates to practice essential writing, communication, and critical thinking skills necessary to engage in civic and professional interactions as mature, informed adults. Whether through studying literature, visual and performing arts, or philosophy, all humanities courses stress the need to form reasoned, analytical, and articulate responses to cultural and creative works. Studying a wide variety of creative works allows candidates to more effectively enter the global community with a broad and enlightened perspective. "
        },
        "C104": {
          "courseName": "Elementary Social Studies Methods ",
          "description": "Elementary Social Studies Methods helps students learn how to implement effective social studies instruction in the elementary classroom. Topics include social studies themes, promoting cultural diversity, integrated social studies across the curriculum, social studies learning environments, assessing social studies understanding, differentiated instruction for social studies, technology for social studies instruction, and standards-based social studies instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "C105": {
          "courseName": "Elementary Visual and Performing Arts Methods ",
          "description": "Elementary Visual and Performing Arts Methods helps students learn how to implement effective visual and performing arts instruction in the elementary classroom. Topics include integrating arts across the curriculum, music education, visual arts, dance and movement, dramatic arts, differentiating instruction for visual and performing arts, and promoting cultural diversity through visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C107": {
          "courseName": "Anatomy and Physiology I ",
          "description": "Anatomy and Physiology I examines the structures and functions of the human body. The course is designed to provide students with a thorough understanding of human anatomy and physiology, including the interdependent operational relationships among them. Students will use a dissection lab to study organ systems of the human body in their healthy state, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. By examining these organ systems in a healthy state, healthcare professionals are more adept at recognizing when something is functioning abnormally, which is a key component to providing effective care to patients. For nursing students, this is the first of two anatomy and physiology courses within the program of study. This course has no prerequisites. "
        },
        "C108": {
          "courseName": "Elementary Science Methods ",
          "description": "Elementary Science Methods helps students learn how to implement effective science instruction in the elementary classroom. Topics include processes of science, science inquiry, science learning environments, instructional strategies for science, differentiating instruction for science, assessing science understanding, technology for science instruction, standards-based science instruction, integrating science across the curriculum, and science beyond the classroom. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "C109": {
          "courseName": "Elementary Mathematics Methods ",
          "description": "Elementary Mathematics Methods helps students learn how to implement effective math instruction in the elementary classroom. Topics include differentiated math instruction, mathematical communication, mathematical tools for instruction, assessing math understanding, integrating math across the curriculum, critical thinking development, standards-based mathematics instruction, and mathematical models and representation. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C121": {
          "courseName": "Survey of United States History ",
          "description": "This course presents a broad and thematic survey of U.S. history from European colonization to the midtwentieth century. Students will explore how historical events and major themes in American history have affected a diverse population. "
        },
        "C165": {
          "courseName": "Integrated Physical Sciences ",
          "description": "This course provides students with an overview of the basic principles and unifying ideas of the physical sciences: physics, chemistry, and earth sciences. Course materials focus on scientific reasoning and practical, everyday applications of physical science concepts to help students integrate conceptual knowledge with practical skills. "
        },
        "C168": {
          "courseName": "Critical Thinking and Logic ",
          "description": "Reasoning and Problem Solving helps candidates internalize a systematic process for exploring issues that takes them beyond an unexamined point of view and encourages them to become more self-aware thinkers by applying principles of problem identification and clarification, planning and information gathering, identifying assumptions and values, analyzing and interpreting information and data, reaching well-founded conclusions, and identifying the role of critical thinking in disciplines and professions. "
        },
        "C172": {
          "courseName": "Network and Security ",
          "description": "Foundations - Network and Security - Foundations introduces students to the components of a computer network and the concept and role of communication protocols. The course covers widely used categorical classifications of networks (e.g., LAN, MAN, WAN, WLAN, PAN, SAN, CAN, and VPN) as well as network topologies, physical devices, and layered abstraction. The course also introduces students to basic concepts of security, covering vulnerabilities of networks and mitigation techniques, security of physical media, and security policies and procedures. This course has no prerequisites. "
        },
        "C175": {
          "courseName": "Data Management ",
          "description": "Foundations - This course introduces students to the concepts and terminology used in the field of data management. Students will be introduced to Structured Query Language (SQL) and will learn how to use Data Definition Language (DDL) and Data Manipulation Language (DML) commands to define, retrieve, and manipulate data. This course covers differentiations of data—structured vs. unstructured and quasi-structured (relational, hierarchical, XML, textual, visual, etc); it also covers aspects of data management (quality, policy, storage methodologies). Foundational concepts of data security are included. "
        },
        "C179": {
          "courseName": "Business of IT ",
          "description": "Applications - This course introduces IT students to information systems (IS). The course includes important topics related to the management of information systems (MIS), such as system development and business continuity. The course also provides an overview of management tools and issue tracking systems. "
        },
        "C180": {
          "courseName": "Introduction to Psychology ",
          "description": "In this course, students will develop an understanding of psychology and how it helps them better understand others and themselves. Students will learn general theories about psychological development, the structure of the brain, and how psychologists study behavior. They will gain an understanding of both normal and disordered psychological behaviors, as well as general applications of the science of psychology in society (such as personality typing and counseling). "
        },
        "C181": {
          "courseName": "Survey of United States Constitution and Government ",
          "description": "Ready to work on Constitution and Government? Please contact your program mentor to be moved to the correct course. "
        },
        "C182": {
          "courseName": "Introduction to IT ",
          "description": "Introduction to IT examines information technology as a discipline and the various roles and functions of the IT department as business support. Students are presented with various IT disciplines including systems and services, network and security, scripting and programming, data management, and business of IT, with a survey of technologies in every area and how they relate to each other and to the business. "
        },
        "C190": {
          "courseName": "Introduction to Biology ",
          "description": "This course is a foundational introduction to the biological sciences. The overarching theories of life from biological research are explored as well as the fundamental concepts and principles of the study of living organisms and their interaction with the environment. Key concepts include how living organisms use and produce energy; how life grows, develops, and reproduces; how life responds to the environment to maintain internal stability; and how life evolves and adapts to the environment. "
        },
        "C191": {
          "courseName": "Operating Systems for Programmers ",
          "description": "This course covers operating systems from the perspective of a programmer, including the placement of the operating system in the layered application development model. Primarily, OSs provide memory management, task scheduling, and CPU allocation. Secondarily, OSs provide tools for file storage/access, permission control, event handling, network access, and cross-process interaction. OSs also provide tools for debugging problems within a single process or within groups of programs. There are no prerequisites for this course. "
        },
        "C200": {
          "courseName": "Managing Organizations and Leading People ",
          "description": "This course covers principles of effective management and leadership that maximize organizational performance. The following topics are included: the role and functions of a manager, analysis of personal leadership styles, approaches to self-awareness and self-assessment, and application of foundational leadership and management skills. "
        },
        "C201": {
          "courseName": "Business Acumen ",
          "description": "The Business Acumen course introduces you to the operation of the business enterprise and the role of management in directing the activities of the business. You will examine the roles of management in the context of business functions such as marketing, operations, accounting, and finance. "
        },
        "C202": {
          "courseName": "Managing Human Capital ",
          "description": "This course focuses on strategies and tools that managers use to maximize employee contribution and create organizational excellence. You will learn talent management strategies to motivate and develop employees as well as best practices to manage performance for added value. "
        },
        "C203": {
          "courseName": "Becoming an Effective Leader ",
          "description": "This course explores major theories and approaches to leadership, leadership style evaluation, and personal leadership development while focusing on motivation, development, and achievement of others. You will learn how to influence followers, manage organizational culture, and enhance your effectiveness as a leader. "
        },
        "C204": {
          "courseName": "Management Communication ",
          "description": "This course prepares students for the communication challenges in organizations. Topics examined include theories and strategies of communication, persuasion, conflict management, and ethics that enhance communication to various audiences. "
        },
        "C205": {
          "courseName": "Leading Teams ",
          "description": "This course helps students establish team objectives, align the team purpose with organizational goals, build credibility and trust, and develop the talents of individuals to enhance team performance. "
        },
        "C206": {
          "courseName": "Ethical Leadership ",
          "description": "This course examines the ethical issues and dilemmas managers face. This course provides a framework for analysis of management-related ethical issues and decision-making action required for satisfactory resolution of these issues. "
        },
        "C207": {
          "courseName": "Data-Driven Decision Making ",
          "description": "This course presents critical problem-solving methodologies, including field research and data collection methods that enhance organizational performance. Topics include quantitative analysis, statistical and quality tools. You will improve your ability to use data to make informed decisions. "
        },
        "C208": {
          "courseName": "Change Management and Innovation ",
          "description": "This course provides an overview of change theories and innovation practices. This course will emphasize the role of leadership in influencing and managing change in response to challenges and opportunities facing organizations. "
        },
        "C209": {
          "courseName": "Strategic Management ",
          "description": "This course focuses on models and practices of strategic management including developing and implementing both short- and long-term strategy and evaluating performance to achieve strategic goals and objectives. "
        },
        "C210": {
          "courseName": "Management and Leadership Capstone ",
          "description": "This course is the culminating assessment of the MSML curriculum that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of management and leadership development and practices. "
        },
        "C211": {
          "courseName": "Global Economics for Managers ",
          "description": "This course examines how economic tools, techniques, and indicators can be used for solving organizational problems related to competitiveness, productivity, and growth. You will explore the management implications of a variety of economic concepts and effective strategies to make decisions within a global context. "
        },
        "C212": {
          "courseName": "Marketing ",
          "description": "Marketing Fundamentals introduces students to principles of the marketing environment, social media, consumer behavior, marketing research, and market segmentation. Students will also explore marketing strategies that are related to products and services, distribution channels, promotions, sales, and pricing. "
        },
        "C213": {
          "courseName": "Accounting for Decision Makers ",
          "description": "This course provides you with the accounting knowledge and skills to assess and manage a business. Topics include the accounting cycle, financial statements, taxes, and budgeting. This course will improve students’ ability to understand reports and use accounting information to plan and make sound business decisions. "
        },
        "C214": {
          "courseName": "Financial Management ",
          "description": "This course covers practical approaches to analysis and decision-making in the administration of corporate funds, including capital budgeting, working capital management, and cost of capital. Topics include financial planning, management of working capital, analysis of investment opportunities, sources of long-term financing, government regulations, and global influences. This course will improve students’ ability to interpret financial statements and manage corporate finances. "
        },
        "C215": {
          "courseName": "Operations Management ",
          "description": "This course focuses on the strategic importance of operations management to overall performance. This course also emphasizes principles of supply chain management relevant to a variety of business operations ranging from manufacturing goods to retail services. You will examine the various planning, control, and decision-making tools and techniques of the operations function. "
        },
        "C216": {
          "courseName": "MBA Capstone ",
          "description": "MBA Capstone is the culminating course in the MBA program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C217": {
          "courseName": "Human Growth and Development Across the Lifespan ",
          "description": "This course introduces candidates to human development across the lifespan. This will include an introductory survey of cognitive, psychological, and physical growth. Candidates will gain an understanding of the emergence of personality, identity, gender and sexuality, social relationships, emotion, language, and moral development through life. This will include milestones such as education, achievement, work, dying, and death. "
        },
        "C218": {
          "courseName": "MBA, Information Technology Management Capstone ",
          "description": "MBA Information Technology Management Capstone is the culminating course in the MBA ITM program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C219": {
          "courseName": "MBA, Healthcare Management Capstone ",
          "description": "MBA Healthcare Management Capstone is the culminating course in the MBA HCM program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C224": {
          "courseName": "Research Foundations ",
          "description": "The Research Foundations course focuses on the essential concepts in educational research, including quantitative, qualitative, mixed, and action research. This course also teaches students concepts about measurement and assessment, as well as strategies for obtaining warranted research results. "
        },
        "C225": {
          "courseName": "Research Questions and Literature Review ",
          "description": "The Research Questions and Literature Reviews course focuses on how to conduct a thorough literature review that addresses and identifies important educational research topics, problems, and questions, and helps determine the appropriate kind of research and data needed to answer one's research questions and hypotheses. Research Foundations is a prerequisite for this course. "
        },
        "C226": {
          "courseName": "Research Design and Analysis ",
          "description": "The Research Design and Analysis course focuses on applying strategies for effective design of empirical research studies. Particular emphasis is placed on selecting or constructing the design that will provide the most valid results, analyzing the kind of data that would be obtained, and making defensible interpretations and drawing appropriate conclusions based on the data. Research Questions and Literature Review is a prerequisite for this course. "
        },
        "C227": {
          "courseName": "Research Proposals ",
          "description": "Research Proposals focuses on planning and writing a well-organized and complete research proposal. The relationship of the sections in a research proposal to the sections in a research report will be highlighted. Research Design and Analysis is a prerequisite for this course. "
        },
        "C228": {
          "courseName": "Community Health and Population-Focused Nursing ",
          "description": "Community Health and Population-Focused Nursing will assist students in becoming familiar with foundational theories and models of health promotion applicable to the community health nursing environment. Students will develop an understanding of how policies and resources influence the health of populations. Focus is concentrated on learning the importance of a community assessment to improve or resolve a community health issue. This course introduces students to the relationships between cultures and communities and the steps necessary to create community collaboration with the goal to improve or resolve community health issues in a variety of settings. Students will gain a greater understanding of health systems in the United States, global health issues, quality-of-life issues, cultural influences, community collaboration, and emergency preparedness.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C229": {
          "courseName": "Community Health and Population-Focused Nursing Field Experience ",
          "description": "This course will assist students to become familiar with clinical aspects of health promotion and disease prevention, applicable to the community health nursing environment. Students will practice skills based on clinical priorities, methodology, and resources that positively influence the health of populations. Students will demonstrate critical thinking skills by applying principles of community health nursing in a variety of settings. Students will design, implement and evaluate a project in community health. Students will develop health promotion and disease prevention strategies for population groups.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C232": {
          "courseName": "Introduction to Human Resource Management ",
          "description": "This course provides an introduction to the management of human resources, the function within an organization that focuses on recruitment, management, and direction for the people who work in the organization. Students will be introduced to topics such as strategic workforce planning and employment; compensation and benefits; training and development; employee and labor relations; and occupational health, safety, and security. "
        },
        "C233": {
          "courseName": "Employment Law ",
          "description": "This course reviews the legal and regulatory framework surrounding employment, including recruitment, termination, and discrimination law. The course topics include employment-at-will, EEO, ADA, OSHA, and other laws affecting the workplace. This course covers how to analyze current trends and issues in employment law and apply this knowledge to manage risk effectively in the employment relationship. "
        },
        "C234": {
          "courseName": "Workforce Planning: Recruitment and Selection ",
          "description": "This course focuses on building a highly skilled workforce by using effective strategies and tactics for recruiting, selecting, hiring, and retaining employees. "
        },
        "C236": {
          "courseName": "Compensation and Benefits ",
          "description": "Compensation and Benefits develops competence in the design and implementation of compensation and benefits systems in an organization. The total rewards perspective integrates tangible rewards (e.g., salary, bonuses) with employee benefits (e.g., health insurance, retirement plan) and intangible rewards (e.g., location, work environment). This perspective allows students to use all forms of rewards fairly and effectively to enable job satisfaction and organizational performance. There are no prerequisites. "
        },
        "C237": {
          "courseName": "Taxation I ",
          "description": "This course focuses on the taxation of individuals. It provides an overview of income taxes of both individuals and business entities in order to enhance awareness of the complexities and sources of tax law and to measure and analyze the effect of various tax options. The course will introduce taxation of sole proprietorships. Students will learn principles of individual taxation and how to develop effective personal tax strategies for individuals. Students will also be introduced to tax research of complex taxation issues. "
        },
        "C239": {
          "courseName": "Advanced Tax Concepts ",
          "description": "This course is designed to enhance awareness of the complexities and sources of tax law and to measure and analyze the effect of various tax options. This course provides an overview of income taxes on individuals, corporations, associations, and corporate distributions, while emphasizing the role of taxes in business decisions and business strategy. Also examined will be federal tax laws applicable to individuals and corporations (and shareholders), including tax research, tax compliance, and tax planning. *Retired "
        },
        "C243": {
          "courseName": "Advanced Financial Accounting ",
          "description": "This course builds upon your accounting knowledge by focusing on advanced financial accounting topics such as consolidations, partnership accounting, and international accounting. "
        },
        "C253": {
          "courseName": "Advanced Managerial Accounting ",
          "description": "This course introduces the complexity and functionality of managerial accounting systems within an organization. It covers the topics of product costing (including activity-based costing), decision-making (including capital budgeting), profitability analysis, budgeting, performance evaluation, and reporting related to managerial decision-making. This course provides the opportunity for a detailed study of how managerial accounting information supports the operational and strategic needs of an organization and how managers use accounting information for decision-making, planning, and controlling activities within organizations. "
        },
        "C254": {
          "courseName": "Fraud and Forensic Accounting ",
          "description": "This course provides a framework for detecting and preventing financial statement fraud. Topics include the profession’s focus and legislation of fraud, revenue- and inventory-related fraud, and liability, asset, and inadequate disclosure fraud. "
        },
        "C263": {
          "courseName": "The Ocean Systems ",
          "description": "In this course, learners investigate the complex ocean system by looking at the way its components—atmosphere, biosphere, geosphere, and hydrosphere—interact. Specific topics include: origins of Earth's oceans and the early history of life; physical characteristics and geologic processes of the ocean floor; chemistry of the water molecule; energy flow between air and water, and how ocean surface currents and deep circulation patterns affect weather and climate; marine biology and why ecosystems are an integral part of the ocean system; the effects of human activity; and the role of professional educators in teaching about ocean systems. "
        },
        "C264": {
          "courseName": "Climate Change ",
          "description": "This course explores the science of climate change. Students will learn how the climate system works; what factors cause climate to change across different time scales and how those factors interact; how climate has changed in the past; how scientists use models, observations, and theory to make predictions about future climate; and the possible consequences of climate change for our planet. The course explores evidence for changes in ocean temperature, sea level, and acidity due to global warming. Students will learn how climate change today is different from past climate cycles and how satellites and other technologies are revealing the global signals of a changing climate. Finally, the course looks at the connection between human activity and the current warming trend and considers some of the potential social, economic, and environmental consequences of climate change. "
        },
        "C266": {
          "courseName": "The Ocean Systems ",
          "description": "This course investigates the complex ocean system by looking at the way its components—atmosphere, biosphere, geosphere, hydrosphere—interact. Specific topics include the origins of Earth’s oceans and the early history of life; physical characteristics and geologic processes of the ocean floor; chemistry of the water molecule; energy flow between air and water and how ocean surface currents and deep circulation patterns affect weather and climate; marine biology and why ecosystems are an integral part of the ocean system; the effects of human activity; and the role of professional educators in teaching about ocean systems. "
        },
        "C267": {
          "courseName": "Climate Change ",
          "description": "This course explores the science of climate change and covers how the climate system works; what factors cause climate to change across different time scales and how those factors interact; how climate has changed in the past; how scientists use models, observations, and theory to make predictions about future climate; and the possible consequences of climate change for our planet. The course explores evidence for changes in ocean temperature, sea level, and acidity due to global warming. It covers how climate change today is different from past climate cycles and how satellites and other technologies are revealing the global signals of a changing climate. Finally, the course looks at the connection between human activity and the current warming trend and considers some of the potential social, economic, and environmental consequences of climate change. "
        },
        "C268": {
          "courseName": "Spreadsheets ",
          "description": "The Spreadsheets course will help students become proficient in using spreadsheets to analyze business problems. Students will demonstrate competency in spreadsheet development and analysis for business/accounting applications (e.g., using essential spreadsheet functions, formulas, charts, etc.) "
        },
        "C273": {
          "courseName": "Introduction to Sociology ",
          "description": "This course teaches students to think like sociologists, or, in other words, to see and understand the hidden rules, or norms, by which people live, and how they free or restrain behavior. Students will learn about socializing institutions, such as schools and families, as well as workplace organizations and governments. Participants will also learn how people deviate from the rules by challenging norms and how such behavior may result in social change, either on a large scale or within small groups. "
        },
        "C277": {
          "courseName": "Finite Mathematics ",
          "description": "Finite Mathematics covers the knowledge and skills necessary to apply discrete mathematics and properties of number systems to model and solve real-life problems. Topics include sets and operations; prime and composite numbers; GCD and LCM; order of operations; ordering numbers; mathematical systems including modular arithmetic, arithmetic and geometric sequences, ratio and proportion, subsets of real numbers, logic and truth tables, graphs, and trees and networks. There are no prerequisites for this course. "
        },
        "C278": {
          "courseName": "College Algebra ",
          "description": "This course provides further application and analysis of algebraic concepts and functions through mathematical modeling of real-world situations. Topics include: real numbers, algebraic expressions, equations and inequalities, graphs and functions, polynomial and rational functions, exponential and logarithmic functions, and systems of linear equations. "
        },
        "C280": {
          "courseName": "Probability and Statistics I ",
          "description": "Probability and Statistics I covers the knowledge and skills necessary to apply basic probability, descriptive statistics, and statistical reasoning, and to use appropriate technology to model and solve real-life problems. It provides an introduction to the science of collecting, processing, analyzing, and interpreting data, including representations, constructions and interpretation of graphical displays (e.g., box plots, histograms, cumulative frequency plots, scatter plots). Topics include creating and interpreting numerical summaries and visual displays of data; regression lines and correlation; evaluating sampling methods and their effect on possible conclusions; designing observational studies, controlled experiments, and surveys; and determining probabilities using simulations, diagrams, and probability rules. Candidates should have completed a course in College Algebra before engaging in this course. "
        },
        "C282": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in the slope of a curve and covers the knowledge and skills necessary to use differential calculus of one variable and technology to solve basic problems. Topics include graphing functions and finding their domains and ranges; limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, and sum rules applied to polynomial and exponential functions, position and velocity; and L'Hopital's Rule. Precalculus is a prerequisite for this course. "
        },
        "C283": {
          "courseName": "Calculus II ",
          "description": "Calculus II is the study of the accumulation of change in the area under a curve. It covers the knowledge and skills necessary to apply integral calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include antiderivatives; indefinite integrals; the substitution rule; Riemann sums; the Fundamental Theorem of Calculus; definite integrals; acceleration, velocity, position, and initial values; integration by parts; integration by trigonometric substitution; integration by partial fractions; numerical integration; improper integration; area between curves; volumes and surface areas of revolution; arc length; work; center of mass; separable differential equations; direction fields; growth and decay problems; and sequences. Calculus I is a prerequisite for this course. "
        },
        "C284": {
          "courseName": "Mathematics Learning and Teaching ",
          "description": "Mathematics Learning and Teaching will help students develop the knowledge and skills necessary to become prospective and practicing educators. Students will be able to use a variety of instructional strategies to effectively facilitate the learning of mathematics. This course focuses on selecting appropriate resources, using multiple strategies, and planning instruction, with methods based on research and problem solving. A deep understanding of the knowledge, skills, and disposition of mathematics pedagogy is necessary to become an effective secondary mathematics educator. There are no prerequisites for this course. "
        },
        "C285": {
          "courseName": "Mathematics History and Technology ",
          "description": "Mathematics History and Technology introduces a variety of technological tools for doing mathematics, and you will develop a broad understanding of the historical development of mathematics. You will come to understand that mathematics is a very human subject that comes from the macro-level sweep of cultural and societal change, as well as the micro-level actions of individuals with personal, professional, and philosophical motivations. Most importantly, you will learn to evaluate and apply technological tools and historical information to create an enriching student-centered mathematical learning environment. There are no prerequisites for this course. "
        },
        "C304": {
          "courseName": "Professional Roles and Values ",
          "description": "This course explores the unique role nurses play in healthcare, beginning with the history and evolution of the nursing profession. The responsibilities and accountability of professional nurses are covered, including cultural competency, advocacy for patient rights, and the legal and ethical issues related to supervision and delegation. Professional conduct, leadership, the public image of nursing, the work environment, and issues of social justice are also addressed. "
        },
        "C339": {
          "courseName": "Cohort Seminar ",
          "description": "Cohort Seminar provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C340": {
          "courseName": "Cohort Seminar in Special Education ",
          "description": "Cohort Seminar in Special Education provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C341": {
          "courseName": "Cohort Seminar ",
          "description": "Cohort Seminar provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C347": {
          "courseName": "Professional Portfolio ",
          "description": "Professional Portfolio requires candidates to create an online teaching portfolio that demonstrates professional beliefs, growth, and effective teaching practices from the Demonstration Teaching experience. The portfolio includes reflective essays (educational beliefs, professional growth, and collaboration with stakeholders) and professional artifacts (resume and artifacts with commentary on academic language, systems of student support, education technology, and professional communication with families) developed and acquired during Demonstration Teaching.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C349": {
          "courseName": "Health Assessment ",
          "description": "The Health Assessment course is designed to enhance students’ knowledge and skills in health promotion, the early detection of illness, and prevention of disease. The course provides the relevant content and skills necessary to perform a comprehensive physical assessment of patients throughout the lifespan. Students are engaged in these processes through interviewing, history taking, and demonstrating an advanced-level physical examination. Dominant models, theories, and perspectives related to evidence-based wellness practices and health education strategies also are included in this challenging course. "
        },
        "C360": {
          "courseName": "Teacher Work Sample in English Language Learning ",
          "description": "The Teacher Work Sample is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills in this professional assessment. "
        },
        "C361": {
          "courseName": "Evidence Based Practice and Applied Nursing Research ",
          "description": "The Evidence Based Practice course will help you to learn how to design and conduct research to answer important questions about improving nursing practice and patient care delivery outcomes. After you are introduced to the basics of evidence-based practice, you will continue to implement the principles throughout your clinical experience. This will allow you to graduate with more competence and confidence to become a leader in the healthcare environment. "
        },
        "C362": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in relation to the slope of a curve and covers the knowledge and skills necessary to apply differential calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include functions, limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative, the power, chain, sum, product, and quotient rules applied to polynomial, trigonometric, exponential, and logarithmic functions, implicit differentiation, position, velocity, and acceleration, optimization, related rates, curve sketching, and L'Hopital's Rule. Pre-Calculus is a pre-requisite for this course. "
        },
        "C363": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in the slope of a curve and covers the knowledge and skills necessary to apply differential calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include functions, limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, sum, product, and quotient rules applied to polynomial, trigonometric, exponential, and logarithmic functions; implicit differentiation, position, velocity, and acceleration; optimization, related rates, curve sketching, and L'Hopital's rule. Precalculus is a prerequisite for this course. "
        },
        "C365": {
          "courseName": "Language Arts Instruction and Intervention ",
          "description": "Language Arts Instruction and Intervention helps students learn how to implement effective language arts instruction and intervention in the elementary classroom. Topics include written and spoken English, expanding students' knowledge, literature rich environments, differentiated instruction, technology for reading and writing, assessment strategies for reading and writing, and strategies for developing academic language. There are no prerequisites for this course. "
        },
        "C367": {
          "courseName": "Elementary Physical Education and Health Methods ",
          "description": "Elementary Physical Education and Health Methods helps students learn how to implement effective physical and health education instruction in the elementary classroom. Topics include healthy lifestyles, student safety, student nutrition, physical education, differentiated instruction for physical and health education, physical education across the curriculum, and public policy in health and physical education. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C371": {
          "courseName": "Concepts in Science ",
          "description": "Concepts in Science for undergraduates provides students seeking a bachelor's degree and initial teacher licensure in science education with an introduction to essential science themes present within and across all science disciplines, including chemistry, physics, biology, and the geosciences. These themes include comprehending the magnitude of the physical and natural world, analyzing and converting measurements, understanding the basic nature and behavior of matter and energy, examining atomic structure, identifying and naming basic types of chemical bonds, and analyzing and interpreting scientific data. Concepts in Science provides a solid foundation for future, in-depth scientific studies and should be taken prior to any other science content course. There are no prerequisites for this course. "
        },
        "C373": {
          "courseName": "General Chemistry I with Lab ",
          "description": "General Chemistry I with Lab for undergraduates provides students seeking initial teacher licensure in secondary chemistry with an introduction to the field of chemistry, the branch of science that studies the composition, structure, properties, and behavior of matter. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science is a prerequisite for this course. "
        },
        "C374": {
          "courseName": "General Chemistry II with Lab ",
          "description": "C374: General Chemistry II with Lab for undergraduates continues the study of general chemistry for students seeking initial teacher licensure in secondary chemistry. Building on the topics covered in General Chemistry I, General Chemistry II examines the behavior of gases and solutions, reaction rates and equilibrium, acids and bases, and oxidation-reduction reactions. Also, this course provides an introduction to three sub-disciplines of chemistry: organic chemistry, biochemistry, and nuclear chemistry. Laboratory experiences reinforce the essential skills required for conducting successful scientific investigations. C373: General Chemistry I for undergraduates is a prerequisite for this course. "
        },
        "C380": {
          "courseName": "Language Arts Instruction and Intervention ",
          "description": "Language Arts Instruction and Intervention helps students learn how to implement effective language arts instruction and intervention in the elementary classroom. Topics include written and spoken English, expanding students' knowledge, literature-rich environments, differentiated instruction, technology for reading and writing, assessment strategies for reading and writing, and strategies for developing academic language. There are no prerequisites for this course. "
        },
        "C381": {
          "courseName": "Elementary Mathematics Methods ",
          "description": "Elementary Mathematics Methods helps students learn to implement effective mathematics instruction in the elementary classroom. Topics include differentiated mathematics instruction, mathematical communication, mathematical tools for instruction, assessing mathematics understanding, integrating mathematics across the curriculum, critical thinking development, standards based mathematics instruction, and mathematical models and representation. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "C382": {
          "courseName": "Elementary Science Methods ",
          "description": "Elementary Science Methods helps students learn how to implement effective science instruction in the elementary classroom. Topics include processes of science, science inquiry, science learning environments, instructional strategies for science, differentiated instruction for science, assessing science understanding, technology for science instruction, standards based science instruction, integrating science across curriculum, and science beyond the classroom. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "C388": {
          "courseName": "Science, Technology, and Society ",
          "description": "Science, Technology, and Society explores the ways in which science influences and is influenced by society and technology. Science is a humanistic and social endeavor and serves the needs of ever-changing societies by providing methods for observing, questioning, discovering, and communicating information about the physical and natural world. This course prepares educators to explain the nature and history of science, the various applications of science, and the scientific and engineering processes used to conduct investigations, make decisions, and solve problems. There are no prerequisites for this course. "
        },
        "C389": {
          "courseName": "Science, Technology, and Society ",
          "description": "Science, Technology, and Society explores the ways in which science influences and is influenced by society and technology. A humanistic and social endeavor, science serves the needs of ever-changing societies by providing methods for observing, questioning, discovering, and communicating information about the physical and natural world. This course prepares educators to explain the nature and history of science, the various applications of science, and the scientific and engineering processes used to conduct investigations, make decisions, and solve problems. There are no prerequisites for this course. "
        },
        "C396": {
          "courseName": "English Pedagogy ",
          "description": "English Pedagogy examines pedagogical applications for the teaching of reading, literature, composition, and related English Language Arts (ELA) content and skills for middle and secondary schools. Focused on fostering and developing pedagogical content knowledge in the aforementioned areas, students will analyze assessment strategies and incorporate methods of literacy instruction into their instructional planning to meet the needs of diverse learners. This course helps students prepare and develop skills for classroom practice, lesson planning, and working in school settings. C397 Preclinical Experiences in English is a prerequisite. "
        },
        "C405": {
          "courseName": "Anatomy and Physiology II ",
          "description": "This course introduces advanced concepts of human anatomy and physiology through the investigation of the structures and functions of the body's organ systems. Students will have the opportunity to explore the body through laboratory experience and apply the concepts covered in this course. For nursing students, this is the second of two anatomy and physiology courses within the program of study. "
        },
        "C425": {
          "courseName": "Healthcare Delivery Systems, Regulation, and Compliance ",
          "description": "This course provides an overview of the U.S. healthcare system and focuses on developing an understanding of the various sectors and roles involved in this complex industry. Policy and compliance issues are also addressed to facilitate an appreciation for the highly regulated nature of healthcare delivery. "
        },
        "C426": {
          "courseName": "Healthcare Values and Ethics ",
          "description": "This course explores ethical standards and considerations common to the healthcare environment such as access to care, confidentiality, the allocation of limited resources, and billing practices. This course also focuses on the distinct value system associated with the healthcare industry, as well as the values of professionalism. "
        },
        "C427": {
          "courseName": "Technology Applications in Healthcare ",
          "description": "This course explores how technology continues to change and influence the healthcare industry. Practical managerial applications are explored as well as the legal, ethical, and practical aspects of access to health and disease information. Ensuring the protection of private health information is also emphasized. "
        },
        "C428": {
          "courseName": "Financial Resource Management in Healthcare ",
          "description": "Financial Resource Management in Healthcare   This course examines the financial environment of the healthcare industry including principles involved in managed care. It also explores the revenue and expense structures for different sectors within the industry while emphasizing funding and reimbursement practices of healthcare. "
        },
        "C429": {
          "courseName": "Healthcare Operations Management ",
          "description": "This course builds upon basic principles of management, organizational behavior, and leadership. Specific processes and business principles for managing operations in interdependent and multi-disciplinary healthcare organizations are explored. Marketing strategies, communication skills, and the ability to establish and maintain relationships while ensuring productivity that is efficient, safe, and meets the needs of all stakeholders is emphasized. "
        },
        "C430": {
          "courseName": "Healthcare Quality Improvement and Risk Management ",
          "description": "This course emphasizes principles of quality management and risk management in order to ensure safety, maximize patient outcomes, and continuously improve organizational outcomes. This course also examines the broader impact of organizational culture and its influence on productivity, quality, and risk. "
        },
        "C431": {
          "courseName": "Healthcare Research and Statistics ",
          "description": "This course builds upon an understanding of research methods and quantitative analysis. Concepts of population health, epidemiology, and evidence-based practices provide the foundation for understanding the importance of data for informing healthcare organizational decisions. "
        },
        "C432": {
          "courseName": "Healthcare Management and Strategy ",
          "description": "This course builds upon basic principles of strategic management and explores healthcare organizational structures and processes. The importance of the collaborative nature and interrelationships among business functions is emphasized. Creating a healthcare vision and designing business plans within a healthcare environment is also examined. "
        },
        "C439": {
          "courseName": "Healthcare Management Capstone ",
          "description": "This course is the culminating experience and assessment of healthcare business administration. This course requires the student to integrate and synthesize managerial skills with healthcare knowledge, resulting in a high quality final project that demonstrates professional managerial proficiency. "
        },
        "C453": {
          "courseName": "Clinical Microbiology ",
          "description": "Clinical Microbiology introduces general concepts, methods, and applications of microbiology from a health sciences perspective. The course is designed to provide healthcare professionals with a basic understanding of how various diseases are transmitted and controlled. Students will examine the structure and function of microorganisms, including the roles that they play in causing major diseases. The course also explores immunological, pathological, and epidemiological factors associated with disease. To assist students in developing an applied, patient-focused understanding of microbiology, this course is complimented by several lab experiments that allow students to: practice aseptic techniques, grow bacteria and fungi, identify characteristics of bacteria and yeast based on biochemical and environmental tests, determine antibiotic susceptibility, discover the microorganisms growing on objects and surfaces, and determine the Gram characteristic of bacteria. This course has no prerequisites. "
        },
        "C455": {
          "courseName": "English Composition I ",
          "description": "English Composition I introduces candidates to the types of writing and thinking that are valued in college and beyond. Candidates will practice writing in several genres with emphasis placed on writing and revising academic arguments. Instruction and exercises in grammar, mechanics, research documentation, and style are paired with each module so that writers can practice these skills as necessary. Composition I is a foundational course designed to help candidates prepare for success at the college level. There are no prerequisites for English Composition I. "
        },
        "C456": {
          "courseName": "English Composition II ",
          "description": "English Composition II introduces candidates to the types of research and writing that are valued in college and beyond. Candidates will practice writing, with emphasis placed on research, writing, and revising an academic argument. Instruction and exercises in grammar, mechanics, research documentation, and style are paired with each module so that writers can practice these skills as necessary. Composition II is a foundational course designed to help candidates prepare for success at the college level. Composition I is the prerequisite for Composition II. "
        },
        "C458": {
          "courseName": "Health, Fitness, and Wellness ",
          "description": "Health, Fitness, and Wellness focuses on the importance and foundations of good health and physical fitness—particularly for children and adolescents—addressing health, nutrition, fitness, and substance use and abuse. "
        },
        "C464": {
          "courseName": "Introduction to Communication ",
          "description": "This introductory communication course allows candidates to become familiar with the fundamental communication theories and practices necessary to engage in healthy professional and personal relationships. Candidates will survey human communication on multiple levels and critically apply the theoretical grounding of the course to interpersonal, intercultural, small group, and public presentational contexts. The course also encourages candidates to consider the influence of language, perception, culture, and media on their daily communicative interactions. In addition to theory, candidates will engage in the application of effective communication skills through systematically preparing and delivering an oral presentation. By practicing these fundamental skills in human communication, candidates become more competent communicators as they develop more flexible, useful, and discriminatory communicative practices in a variety of contexts. Note: There are references within this video to Taskstream. If Taskstream is not part of your student experience, please disregard, and locate your task(s) within your course. "
        },
        "C468": {
          "courseName": "Information Management and the Application of Technology ",
          "description": "Information Management and the Application of Technology helps the candidate learn how to identify and implement the unique responsibilities of nurses related to the application of technology and the management of patient information. This includes understanding the evolving role of nurse informaticists; demonstrating the skills needed to use electronic health records; identifying nurse-sensitive outcomes that lead to quality improvement measures; supporting the contributions of nurses to patient care; examining workflow changes related to the implementation of computerized management systems; and learning to analyze the implications of new technology on security, practice, and research. "
        },
        "C475": {
          "courseName": "Care of the Older Adult ",
          "description": "Care of the Older Adult adapts the concepts from prior coursework to the care of older adults. An understanding of the effects that policy and legislation have on how healthcare systems treat aging patients sets a foundation for improving their care. Students will apply health assessment skills and evidence-based standards in such a way to account for the specific needs of older adults. Emphasis is placed on the importance of maintaining the dignity of older adults by focusing on cultural, religious, spiritual, and communication needs, and by collaborating on care with older adults, families, and caregivers. "
        },
        "C483": {
          "courseName": "Principles of Management ",
          "description": "Principles of Management provides students with an introductory look at the discipline of management and its context within the business environment. Students of this course build on previously mastered competencies by taking a more in-depth look at management as a discipline and how it differs from leadership while further exploring the importance of communication within business. This course provides students with a business generalist overview in the areas of strategic planning, total quality, entrepreneurship, conflict and change, human resource management, diversity, and organizational structure. "
        },
        "C484": {
          "courseName": "Organizational Behavior and Leadership ",
          "description": "Organizational Behavior and Leadership explores how to lead and manage effectively in diverse business environments. The course requires students to demonstrate the ability to apply organizational leadership theories and management strategies in a series of scenario-based problems. "
        },
        "C489": {
          "courseName": "Organizational Systems and Quality Leadership ",
          "description": "Nurses serve as clinicians, managers, and mentors to shape the future of healthcare and affect patient care outcomes in positive ways. This course will help students be more confident and better prepared to assume leadership roles regardless of their position in the healthcare delivery system. This advanced leadership course focuses on the concepts of patient safety; improvement science; balancing cost, quality, and access through the triple aim; and leadership and patient/family-centered care. Students will develop mastery of advanced competencies, particularly in patient safety in quality improvement science. "
        },
        "C493": {
          "courseName": "Leadership and Professional Image ",
          "description": "Nursing is a practice discipline that includes direct and indirect care activities that affect health outcomes. Baccalaureate nursing students are developing new competencies in leadership, and in order to achieve mastery, must apply those competencies to live practice experiences and situations. In this course students will complete a Leadership Learning Experience (LLE) and develop their own personal professional portfolio. The professional portfolio is a collection of artifacts from BSN coursework as well as a resume and personal statement. "
        },
        "C494": {
          "courseName": "Advanced Standing for RN License ",
          "description": "Advanced Standing for RN License "
        },
        "C498": {
          "courseName": "MS, Information Technology Management Capstone ",
          "description": "MSITM Capstone course challenges students to demonstrate mastery of all the MSITM program outcomes. The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
        },
        "C612": {
          "courseName": "Mathematics: Content Knowledge ",
          "description": "Mathematics: Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful secondary mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, PreCalculus, Calculus I, and Calculus II. Linear Algebra, and Calculus III are recommended. "
        },
        "C613": {
          "courseName": "Middle School Mathematics: Content Knowledge ",
          "description": "Mathematics: Middle School Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful middle school mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, and Pre-Calculus. "
        },
        "C614": {
          "courseName": "Biology: Content Knowledge ",
          "description": "This comprehensive course examines a student’s conceptual understanding of a broad range of biology topics. High school biology teachers must help students make connections between isolated topics. This course starts with macromolecules that make up cellular components and continues with understanding the many cellular processes that allow life to exist. Connections are then made between genetics and evolution. Classification of organisms leads into plant and animal development that study the organ systems and their role in maintaining homeostasis. The course finishes by studying ecology and the effect humans have on the environment. "
        },
        "C615": {
          "courseName": "Physics: Content Knowledge ",
          "description": "Physics: Content Knowledge covers the advanced content knowledge that a secondary physics teacher is expected to know and understand. Topics include nature and impact of science and engineering, principle and models of matter and energy, mechanics, electricity and magnetism, waves, and science teaching and pedagogy. "
        },
        "C616": {
          "courseName": "Middle School Science: Content Knowledge ",
          "description": "This course covers the content knowledge that a middle-level science teacher is expected to know and understand. Topics include scientific methodologies, history of science, basic science principles, physical sciences, life sciences, earth and space sciences, and the role of science and technology and their impact on society. "
        },
        "C617": {
          "courseName": "Chemistry: Content Knowledge ",
          "description": "Chemistry: Content Knowledge provides advanced instruction in the main areas of chemistry for which secondary chemistry teachers are expected to demonstrate competency. Topics include matter and energy, thermochemistry, structure, bonding, reactivity, biochemistry and organic chemistry, solutions, the nature of science and technology, mathematics, and laboratory procedures. "
        },
        "C618": {
          "courseName": "Earth Science: Content Knowledge ",
          "description": "This course covers the advanced content knowledge that a secondary earth science teacher is expected to know and understand. Topics include basic scientific principles of earth and space sciences, tectonics and internal earth processes, earth materials and surface processes, history of the Earth and its life-forms, Earth's atmosphere and hydrosphere, and astronomy. "
        },
        "C624": {
          "courseName": "Biochemistry ",
          "description": "Biochemistry covers the structure and function of the four major polymers produced by living organisms. These include nucleic acids, proteins, carbohydrates, and lipids. This course focuses on application. Be sure to understand the underlying biochemistry in order to grasp how it is applied. By successfully completing this course, you will gain an introductory understanding of the chemicals and reactions that sustain life. You will also begin to see the importance of this subject matter to health. "
        },
        "C625": {
          "courseName": "Biochemistry ",
          "description": "Biochemistry covers the structure and function of the four major polymers produced by living organisms. These include nucleic acids, proteins, carbohydrates, and lipids. This course focuses on application and the underlying biochemistry in order to grasp how it is applied. This course will help students gain an introductory understanding of the chemicals and reactions that sustain life. Students will see the importance of this subject matter to health. "
        },
        "C635": {
          "courseName": "MA, Mathematics Education (K-6) Capstone ",
          "description": "MA, Mathematics Education (K-6) Capstone Written Project takes the student through the steps of planning and conducting research on a topic or issue related to the students' practice setting. The result is expected to be a significant piece of research, culminating in a written research report, including sections describing a literature review, methodology, and detailed analysis and reporting of results. Prerequisite Courses: Research Foundations (C224), Research Questions and Literature Review (C225), Research Design and Analysis (C226), and Research Proposals (C227) or permission of a faculty manager. Additionally, students wishing to add the Capstone with fewer than eight weeks remaining in the term must receive permission of the faculty manager. "
        },
        "C645": {
          "courseName": "Science Methods ",
          "description": "Science Methods provides an introduction to science teaching methods for graduate students seeking initial licensure or an additional endorsement in secondary biology, chemistry, geosciences, physics, or middle grades general science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Students seeking initial licensure should complete Curriculum, Instruction, and Assessment before this course. There are no prerequisites for students seeking an endorsement in a new content area. "
        },
        "C646": {
          "courseName": "Trigonometry and Precalculus ",
          "description": "Trigonometry and Precalculus covers the knowledge and skills necessary to apply trigonometry, complex numbers, systems of equations, vectors and matrices, sequence and series, and to use appropriate technology to model and solve real-life problems. Topics include degrees; radians and arcs; reference angles and right triangle trigonometry; applying, graphing and transforming trigonometric functions and their inverses; solving trigonometric equations; using and proving trigonometric identities; geometric, rectangular, and polar approaches to complex numbers; DeMoivre's Theorem; systems of linear equations and matrix-vector equations; systems of nonlinear equations; systems of inequalities; and arithmetic and geometric sequences and series. College Algebra is a prerequisite for this course. "
        },
        "C647": {
          "courseName": "Trigonometry and Precalculus ",
          "description": "Trigonometry and Precalculus covers the knowledge and skills necessary to apply trigonometry, complex numbers, systems of equations, vectors and matrices, and sequences and series, and to use appropriate technology to model and solve real-life problems. Topics include degrees; radians and arcs; reference angles and right triangle trigonometry; applying, graphing and transforming trigonometric functions and their inverses; solving trigonometric equations; using and proving trigonometric identities; geometric, rectangular, and polar approaches to complex numbers; DeMoivre's Theorem; systems of linear equations and matrix-vector equations; systems of nonlinear equations; systems of inequalities; and arithmetic and geometric sequences and series. College Algebra is a prerequisite for this course. "
        },
        "C649": {
          "courseName": "Geology I: Physical ",
          "description": "Geology I: Physical provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to minerals and rocks, the physical features of the Earth, and the internal and surface processes that shape those features. This course has no prerequisites. "
        },
        "C650": {
          "courseName": "Geology I: Physical ",
          "description": "Geology I: Physical provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to minerals and rocks, the physical features of the Earth, and the internal and surface processes that shape those features. This course has no prerequisites. "
        },
        "C652": {
          "courseName": "Heredity and Genetics ",
          "description": "Heredity and Genetics is an introductory course for undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education. This course addresses the basic principles of heredity and the function of molecular genetics. Topics include Mendelian and non-Mendelian inheritance and population genetics. This course has no prerequisites. "
        },
        "C653": {
          "courseName": "Heredity and Genetics ",
          "description": "Heredity and Genetics is an introductory course for graduate students seeking initial licensure or endorsement and/or students earning their MA degree in secondary or middle grade science education. This course addresses the basic principles of heredity and the function of molecular genetics. Topics include Mendelian and non-Mendelian inheritance and population genetics. This course has no prerequisites. "
        },
        "C654": {
          "courseName": "Zoology ",
          "description": "Zoology provides undergraduate students seeking licensure or endorsement in secondary science education with an introduction to the field of zoology. Zoology includes the study of major animal phyla emphasizing characteristics, variations in anatomy, life cycles, adaptations, and relationships among the animal kingdom. A prerequisite for this course is Introduction to Biology. "
        },
        "C655": {
          "courseName": "Zoology ",
          "description": "Zoology provides graduate students seeking licensure or endorsement and/or their MA degree in secondary science education with an introduction to the field of zoology. Zoology includes the study of major animal phyla emphasizing characteristics, variations in anatomy, life cycles, adaptations, and relationships among the animal kingdom. A prerequisite for this course is Introduction to Biology. "
        },
        "C656": {
          "courseName": "Calculus III ",
          "description": "Calculus III is the study of calculus conducted in three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply calculus of multiple variables while using the appropriate technology to model and solve real-life problems. Topics include: infinite series and convergence tests (integral, comparison, ratio, root, and alternating), power series, taylor polynomials, vectors, lines and planes in three dimensions, dot and cross products, multivariable functions, limits, and continuity, partial derivatives, directional derivatives, gradients, tangent planes, normal lines, and extreme values. Calculus II is a prerequisite for this course.  "
        },
        "C657": {
            "courseName": "Calculus III ",
            "description": "Calculus III is the study of calculus conducted in three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply calculus of multiple variables while using the appropriate technology to model and solve real-life problems. Topics include: infinite series and convergence tests (integral, comparison, ratio, root, and alternating), power series,taylor polynomials, vectors, lines and planes in three dimensions, dot and cross products, multivariable functions, limits, and continuity, partial derivatives, directional derivatives, gradients, tangent planes, normal lines, and extreme values. Calculus II is a prerequisite for this course. "
          },
          "C659": {
            "courseName": "Conceptual Physics ",
            "description": "Conceptual Physics provides a broad, conceptual overview of the main principles of physics, including mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. Problem-solving activities and laboratory experiments provide students with opportunities to apply these main principles, creating a strong foundation for future studies in physics. There are no prerequisites for this course. "
          },
          "C670": {
            "courseName": "Concepts in Science ",
            "description": "Concepts in Science for graduates provides already-licensed teachers seeking an additional license or endorsement in science education with an introduction to essential science themes present within and across all science disciplines, including chemistry, physics, biology, and the geosciences. These themes include comprehending the magnitude of the physical and natural world, analyzing and converting measurements, understanding the basic nature and behavior of matter and energy, examining atomic structure, identifying and naming basic types of chemical bonds, and analyzing and interpreting scientific data. Concepts in Science provides a solid foundation for future, in-depth, scientific studies and should be taken prior to any other science content course. There are no prerequisites for this course. "
          },
          "C672": {
            "courseName": "General Chemistry I with Lab ",
            "description": "General Chemistry I with Lab for graduates provides an introduction to the field of chemistry to alreadylicensed teachers seeking an additional license or endorsement in secondary chemistry. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science is a prerequisite for this course. "
          },
          "C673": {
            "courseName": "General Chemistry II with Lab ",
            "description": "General Chemistry II with Lab for graduates continues the study of general chemistry for already-licensed teachers seeking an additional license or endorsement in secondary chemistry. Building on the topics covered in General Chemistry I, General Chemistry II examines the behavior of gases and solutions, reaction rates and equilibrium, acids and bases, and oxidation-reduction reactions. Also, this course provides an introduction to three subdisciplines of chemistry: organic chemistry, biochemistry, and nuclear chemistry. Laboratory experiences reinforce the essential skills required for conducting successful scientific investigations. C672: General Chemistry I for graduates is a prerequisite for this course. "
          },
          "C683": {
            "courseName": "Natural Science Lab ",
            "description": "This course provides students an introduction to using the scientific method and engaging in scientific research to reach conclusions about the natural world. Students will design and carry out an experiment to investigate a hypothesis by gathering quantitative data. They will also research a specific ecosystem using academic sources and draw conclusions from their findings. "
          },
          "C715": {
            "courseName": "Organizational Behavior ",
            "description": "Organizational Behavior and Leadership explores how to lead and manage effectively in diverse business environments. Students are asked to demonstrate the ability to apply organizational leadership theories and management strategies in a series of scenario-based problems. "
          },
          "C716": {
            "courseName": "Business Communication ",
            "description": "Business Communication is a survey course of communication skills needed in the business environment. Course content includes writing messages, reports, and résumés and delivering oral presentations. The course emphasizes communication processes, writing skills, message types, and presentation of data. The development of these skills is integrated with the use of technology. "
          },
          "C717": {
            "courseName": "Business Ethics ",
            "description": "Business Ethics is designed to enable students to identify the ethical and socially responsible courses of action available through the exploration of various scenarios in business. Students will also learn to develop appropriate ethics guidelines for a business. This course has no prerequisites. "
          },
          "C720": {
            "courseName": "Operations and Supply Chain Management ",
            "description": "Operations and Supply Chain Management provides a streamlined introduction to how organizations efficiently produce goods and services, determine supply chain management strategies, and measure performance. Emphasis is placed on integrative topics essential for managers in all disciplines, such as supply chain management, product development, and capacity planning. This course will guide students in analyzing processes, managing quality for both services and products, and measuring performance while creating value along the supply chain in a global environment. Topics include forecasting, product and service design, process design and location analysis, capacity planning, management of quality and quality control, inventory management, scheduling, supply chain management, and performance measurement. "
          },
          "C721": {
            "courseName": "Change Management ",
            "description": "Change Management provides an understanding of change and an overview of successfully managing change using various methods and tools. Emphasizing change theories and various best practices, this course covers how to recognize and implement change using an array of other effective strategies, including those related to innovation and leadership. Other topics include approaches to change, diagnosing and planning for change, implementing change, and sustaining change. "
          },
          "C722": {
            "courseName": "Project Management ",
            "description": "Project Management prepares you to manage projects from start to finish within any organization structure. The course represents a view into different project-management methods and delves into topics such as project profiling and phases, constraints, building the project team, scheduling, and risk. You will be able to grasp the full scope of projects you may work with on in the future, and apply proper management approaches to complete a project. The course features practice in each of the project phases as you learn how to strategically apply project-management tools and techniques to help organizations achieve their goals. "
          },
          "C723": {
            "courseName": "Quantitative Analysis For Business ",
            "description": "Quantitative Analysis for Business explores various decision-making models, including expected value models, linear programming models, and inventory models. This course helps student learn to analyze data by using a variety of analytic tools and techniques to make better business decisions. In addition, it covers developing project schedules using the Critical Path Method. Other topics include calculating and evaluating formulas, measures of uncertainty, crash costs, and visual representation of decision-making models using electronic spreadsheets and graphs. This course has no prerequisites. "
          },
          "C724": {
            "courseName": "Information Systems Management ",
            "description": "Information Systems Management provides an overview of many facets of information systems applicable to business. The course explores the importance of viewing information technology (IT) as an organizational resource that must be managed, so that it supports or enables organizational strategy. "
          },
          "C728": {
            "courseName": "Secondary Disciplinary Literacy ",
            "description": "Secondary Disciplinary Literacy examines teaching strategies designed to help learners in middle and high school improve upon the literacy skills required to read, write, and think critically while engaging content in different academic disciplines. Themes include exploring how language structures, text features, vocabulary, and context influence reading comprehension across the curriculum. The course highlights strategies and tools designed to help teachers assess the reading comprehension and writing proficiency of learners and provides strategies to support students' reading and writing success in all curriculum areas. This course has no prerequisites. "
          },
          "C730": {
            "courseName": "Secondary Reading Instruction and Interventions ",
            "description": "Secondary Reading Instruction and Interventions explores the comprehensive, studentcentered response to intervention (RTI) model used to identify and address the needs of learners in middle school and high school who struggle with reading comprehension and/or information retention. Course content provides educators with effective strategies designed to scaffold instruction and help learners develop increased skill in the following areas: reading, vocabulary, text structures and genres, and logical reasoning related to the academic disciplines. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Special Education. "
          },
          "C732": {
            "courseName": "Elementary Disciplinary Literacy ",
            "description": "Elementary Disciplinary Literacy examines teaching strategies designed to help learners in grades K–6 develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help learners distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction  to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special Education. "
          },
          "C736": {
            "courseName": "Evolution ",
            "description": "Students will learn why evolution is the fundamental concept that underlies all life sciences and how it contributes to advances in medicine, public health, and conservation. Course participants will gain a firm understanding of the basic mechanisms of evolution, including the process of speciation, and how these systems have given rise to the great diversity of life in the world today. They will also explore how new ideas, discoveries, and technologies are modifying prior evolutionary concepts. Ultimately, the course will explain how evolution works and how we know what we know. "
          },
          "C737": {
            "courseName": "Evolution ",
            "description": "This course addresses why evolution is the fundamental concept that underlies all life sciences and how it contributes to advances in medicine, public health, and conservation. This course helps participants gain a firm understanding of the basic mechanisms of evolution including the process of speciation and how these systems have given rise to the great diversity of life in the world today. This course also explore how new ideas, discoveries, and technologies are modifying prior evolutionary concepts. Ultimately, the course will explain how evolution works and how we know what we know. "
          },
          "C738": {
            "courseName": "Space, Time and Motion ",
            "description": "Throughout history, humans have grappled with questions about the origin, workings, and behavior of the universe. This seminar begins with a quick tour of discovery and exploration in physics, from the ancient Greek philosophers on to Galileo Galilei, Isaac Newton, and Albert Einstein. Einstein's work then serves as the departure point for a detailed look at the properties of motion, time, space, matter, and energy. The course considers Einstein's special theory of relativity, his photon hypothesis, wave-particle duality, his general theory of relativity and its implications for astrophysics and cosmology, as well as his three-decade quest for a unified field theory. It also looks at Einstein as a social and political figure and his contributions as a social and political force. Scientist-authored essays, online interaction, videos, and web resources enable learners to trace this historic path of discovery and explore implications of technology for society, energy production in stars, black holes, the big bang, and the role of the scientist in modern society. "
          },
          "C739": {
            "courseName": "Space, Time and Motion ",
            "description": "This course begins with a quick tour of discovery and exploration in physics, from the ancient Greek philosophers on to Galileo Galilei, Isaac Newton, and Albert Einstein. Einstein’s work then serves as the departure point for a detailed look at the properties of motion, time, space, matter, and energy. The course considers Einstein’s special theory of relativity, his photon hypothesis, wave-particle duality, his general theory of relativity and its implications for astrophysics and cosmology, as well as his three-decade quest for a unified field theory. It also looks at Einstein as a social and political figure and his contributions as a social and political force. Scientist-authored essays, online interaction, videos, and web resources enable learners to trace this historic path of discovery and explore implications of technology for society, energy production in stars, black holes, the Big Bang, and the role of the scientist in modern society. "
          },
          "C769": {
            "courseName": "IT Capstone Written Project ",
            "description": "The capstone project consists of a technical work proposal, the proposal’s implementation, and a postimplementation report that describes the graduate’s experience in developing and implementing the capstone project. The capstone project should be presented and approved by the course instructor in relation to the graduate’s technical emphasis. "
          },
          "C773": {
            "courseName": "User Interface Design ",
            "description": "This course covers tools and techniques employed in user interface design, including web and mobile applications. Concepts of clarity, usability, and detectability are included in this course, as well as other design elements such as color schemes, typography, and layout. Techniques like wireframing, usability testing, and SEO optimization are also covered. "
          },
          "C777": {
            "courseName": "Web Development Applications ",
            "description": "This course builds upon a student's manual coding skills by teaching how to develop web documents and pages using the web development trifecta: Hypertext Markup Language version 5 (HTML5), Cascading Style Sheets version 3 (CSS3), and JavaScript. Students will utilize the skills learned in this course to create web documents and pages that easily adapt to display on both traditional and mobile devices. In addition, students will learn techniques for code validation and testing, form creation, inline form field validation, and mobile design for browsers and apps, including Responsive Web Design (RWD). "
          },
          "C783": {
            "courseName": "Project Management ",
            "description": "Project Management is a thorough exploration of the inputs, tools, techniques, and outputs across the five process groups and 10 knowledge areas identified in the Project Management Body of Knowledge (PMBOK) Guide. The essential concepts and practical scenarios included enable students to build the competencies of an effective project manager. "
          },
          "C784": {
            "courseName": "Applied Healthcare Statistics ",
            "description": "Applied Healthcare Probability and Statistics is designed to help develop competence in the fundamental concepts of basic mathematics, introductory algebra, and statistics and probability. These concepts include basic arithmetic with fractions and signed numbers; introductory algebra and graphing; descriptive statistics; regression and correlation; and probability. Statistical data and probability are now commonplace in the healthcare field. This course will help candidates make informed decisions about which studies and results are valid, which are not, and how those results affect your decisions. This course will give candidates background in what constitutes sound research design and how to appropriately model phenomena using statistical data. Additionally, this course guides candidates in calculating simple probabilities based on events which occur in the healthcare profession. This course will prepare candidates for studies at Whalley, as well as in the healthcare profession. "
          },
          "C787": {
            "courseName": "Health and Wellness Through Nutritional Science ",
            "description": "Nutritional ignorance or misunderstandings are at the root of the health problems that most Americans face today. Nurses need to be armed with the most current information available about nutrition science, including how to understand nutritional content of food; implications of exercise and activity on food consumption and weight management, and management of community or population specific nutritional challenges. The Health and Wellness Through Nutritional Science course should prepare nurses to provide support, guidance, and teaching about incorporation of sound nutritional principles into daily life for health promotion. This course covers nutrition to support wellness; healthy nutritional choices; nutrition and physical activity; nutrition through the lifecycle; safety and security of food; and nutrition and global health environments. "
          },
          "C790": {
            "courseName": "Foundations in Nursing Informatics ",
            "description": "This course addresses the integration of technology to improve and support nursing practice. It provides nurses with a foundational understanding of nursing informatics theory, practice, and applications. Topics include the role of nursing in informatics; use of computer technology for clinical documentation, communication, and workflows; problem identification; project implementation; and best practices. "
          },
          "C792": {
            "courseName": "Data Modeling and Database Management Systems ",
            "description": "This graduate course is designed to engage the student in planning, analyzing, and designing a relational database management system (DBMS) for use by nurse administrators, clinicians, educators, and informaticists. This experience will provide the knowledge needed to advocate for nursing informatics needs within the field of healthcare. "
          },
          "C797": {
            "courseName": "Data Science and Analytics ",
            "description": "This course addresses the interdisciplinary and emerging field of data science in healthcare. Candidates learn to combine tools and techniques from statistics, computer science, data visualization, and the social sciences to solve problems using data. Topics include data analysis; database management; inferential and descriptive statistics; statistical inference; and process improvement. "
          },
          "C798": {
            "courseName": "Informatics System Analysis and Design ",
            "description": "In Informatics System Analysis and Design, a broad understanding of data systems is covered to build upon the Foundations in Nursing Informatics course. The importance of effective interoperability, functionality, data access, and user satisfaction are addressed. The student will be analyzing reports and integrating federal regulations, research principles, and principles of environmental health in the construction of a real-world systems analysis and design project. This course will be directly applicable to healthcare settings as electronic records management has become compulsory for healthcare providers. All of the information in this course will be directly tied to the delivery of quality patient care and patient safety. Foundations in Nursing Informatics is recommended as a prerequisite. "
          },
          "C799": {
            "courseName": "Healthcare Ecosystems ",
            "description": "Healthcare Ecosystems explores the history and state of healthcare organizations in an ever-changing environment. This course covers how agencies influence healthcare delivery through legal, licensure, certification, and accreditation standards. The course will also discuss how new technologies and trends keep healthcare delivery innovative and current. "
          },
          "C801": {
            "courseName": "Health Information Law and Regulations ",
            "description": "Health Information Law and Regulations prepares students to manage health information in compliance with legal guidelines and teaches how to respond to questions and challenges when legal issues occur. This course presents the types of situations occurring in health information management that could result in ethical dilemmas and establishes a foundation for work based on legal and ethical guidelines. "
          },
          "C802": {
            "courseName": "Foundations in Healthcare Information Management ",
            "description": "Foundations in Healthcare Information Management applies theories from business, IT, management, medicine, and consumer-centered healthcare skills. Students will learn to evaluate and analyze health information systems for implementation in health information management. There are no prerequisites for this course. "
          },
          "C803": {
            "courseName": "Data Analytics and Information Governance ",
            "description": "Data Analytics and Information Governance explores the structure, methods, and approaches for using health information in the healthcare industry. By focusing on quality data collection, analytics, and industry regulations, students will examine tools that ensure quality data collection as well as to use data to improve quality of care. This course has no prerequisites. "
          },
          "C804": {
            "courseName": "Medical Terminology ",
            "description": "Medical Terminology focuses on the basic components of medical terminology and how terminology is used when discussing various body structures and systems. Proper use of medical terminology is critical for accurate and clear communication among medical staff, health professionals, and patients. In addition to the systems of the body, this course will discuss immunity, infections, mental health, and cancer. "
          },
          "C805": {
            "courseName": "Pathophysiology ",
            "description": "Pathophysiology is an overview of the pathology and treatment of diseases in the human body and its systems. This course will explain the processes in the body that result in the signs and symptoms of disease, as well as therapeutic procedures in managing or curing the disease. The content draws on a knowledge of anatomy and physiology to understand how diseases manifest themselves and how they affect the body. "
          },
          "C807": {
            "courseName": "Healthcare Compliance ",
            "description": "Healthcare Compliance examines the role of the coding professional within healthcare information management. The course covers compliance plans, issues that arise with noncompliance, and management of internal and external audits. "
          },
          "C808": {
            "courseName": "Classification Systems ",
            "description": "Classification Systems provides a comprehensive approach to learning about medical coding classification, coding audits, and quality standards. Candidates will be exposed to electronic health record systems and leadership principles as they relate to management of ICD and CPT codes. There are no prerequisites for this course. "
          },
          "C810": {
            "courseName": "Foundations in Healthcare Data Management ",
            "description": "Foundations in Healthcare Data Management introduces students to the concepts and terminology used in health data and health information management. This course teaches students how to apply data management and governance principles in the healthcare environment. The student will learn about electronic health records (EHR), legal considerations, information governance, data management, health information management (HIM), and secondary data sources. In addition to the e-text and numerous additional articles and video resources, the student will engage with case studies and knowledge checks to assist with learning. There are no prerequisites for this course. "
          },
          "C811": {
            "courseName": "Healthcare Financial Resource Management ",
            "description": "Healthcare Financial Resource Management examines financial practices within healthcare industries to promote effective management at department and organization levels. Focusing on financial processes associated with facility operations in the healthcare field, this course will analyze the impact of strategic financial planning and regulatory control processes. This course has no prerequisites. "
          },
          "C812": {
            "courseName": "Healthcare Reimbursement ",
            "description": "Healthcare Reimbursement explores financial practices within the healthcare industry as they relate to reimbursement policies. This course identifies how reimbursement systems impact the revenue cycle and a health information manager's role. This course has no prerequisites. "
          },
          "C813": {
            "courseName": "Healthcare Statistics and Research ",
            "description": "Healthcare Statistics and Research explores the use of statistical data to support process improvement through health information research. Health information management (HIM) professionals use information systems to gather, analyze, and present data in response to administrative and clinical needs. This course has no prerequisites. "
          },
          "C815": {
            "courseName": "Quality and Performance Management and Methods ",
            "description": "Quality and Performance Management and Methods examines quality initiatives within healthcare. Quality issues cover human resource management, employee performance, and patient safety. This course focuses on quality improvement initiatives and performance improvement with the health information management perspective. "
          },
          "C816": {
            "courseName": "Healthcare System Applications ",
            "description": "Healthcare System Applications introduces students to information systems. This course includes important topics related to management of information systems (MIS), such as system development and business continuity. The course also provides an overview of management tools and issue tracking systems. This course has no prerequisites. "
          },
          "C820": {
            "courseName": "Professional Leadership and Communication for Healthcare ",
            "description": "The Professional Communication and Leadership in Healthcare course is designed to help students prepare for success in the online environment at Whalley University and beyond. Student success starts with the social support and self-reflective awareness that will prepare students to weather the challenges of academic programs. In this course students will participate in group activities and complete several individual assignments. The group activities are aimed at finding support and gaining insight from other students. The assignments are intended to give the student an opportunity to reflect about where they are and where they would like to be. The activities in each group meeting are designed to give students several tools they can use to achieve success. This course is designed as a five-part intensive learning experience. Students will attend five group meetings during the term. At each meeting students will engage in activities that help them understand their own educational journey and find support and inspiration in the journey of others.   "
          },
          "C832": {
            "courseName": "Chemistry with Lab ",
            "description": "Chemistry with Lab for undergraduates provides students seeking initial teacher licensure in middle grades science or secondary physics, biological science, or earth science with an introduction to the field of chemistry, the branch of science that studies the composition, structure, properties, and behavior of matter. Designed for those not majoring in chemistry education, this course highlights how the topics covered can be applied within various branches of science. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science for undergraduates is a prerequisite for this course. "
          },
          "C833": {
            "courseName": "Chemistry with Lab ",
            "description": "Chemistry with Lab for graduates provides already licensed teachers seeking an additional license or endorsement in middle grades science or secondary physics, biological science, or earth science with an introduction to the field of chemistry. Designed for those not majoring in chemistry education, this course highlights how the topics covered can be applied within various branches of science. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science for graduates is a prerequisite for this course. "
          },
          "C841": {
            "courseName": "Legal Issues in Information Security ",
            "description": "Security information professionals have the role and responsibility for knowing and applying ethical and legal principles and processes that define specific needs and demands to assure data integrity within an organization. This course addresses the laws, regulations, authorities, and directives that inform the development of operational policies, best practices, and training to assure legal compliance and to minimize internal and external threats. Students analyze legal constraints and liability concerns that threaten information security within an organization and develop disaster recovery plans to assure business continuity. "
          },
          "C843": {
            "courseName": "Managing Information Security ",
            "description": "This course expands on fundamentals of information security by providing an in-depth analysis of the relationship between an information security program and broader business goals and objectives. Students develop knowledge and experience in the development and management of an information security program essential to ongoing education, career progression, and value delivery to enterprises. Students apply best practices to develop an information security governance framework, analyze mitigation in the context of compliance requirements, align security programs with security strategies and best practices, and recommend procedures for managing security strategies that minimize risk to an organization. "
          },
          "C844": {
            "courseName": "Emerging Technologies in Cybersecurity ",
            "description": "The continual evolution of technology means that cybersecurity professionals must be able to analyze and evaluate new technologies in information security such as wireless, mobile, and internet technologies. Students review the adoption process that prepares an organization for the risks and challenges of implementing new technologies. This course focuses on comparison of evolving technologies to address the security requirements of an organization. Students learn underlying principles critical to the operation of secure networks and adoption of new technologies. "
          },
          "C845": {
            "courseName": "Information Systems Security ",
            "description": "IT security professionals must be prepared for the operational demands and responsibilities of security practitioners including authentication, security testing, intrusion detection and prevention, incident response and recovery, attacks and countermeasures, cryptography, and malicious code countermeasures. This course provides a comprehensive, up-to-date global body of knowledge that ensures students have the right information, security knowledge, and skills to be successful in IT operational roles to mitigate security concerns and guard against the impact of malicious activity. Students demonstrate how to manage and restrict access control systems; administer policies, procedures, and guidelines that are ethical and compliant with laws and regulations; implement risk management and incident handling processes; execute cryptographic systems to protect data; manage network security; and analyze common attack vectors and countermeasures to assure information integrity and confidentiality in various systems. This course prepares students for the Systems Security Certified Practitioner (ISC2 SSCP) certification exam. "
          },
          "C850": {
            "courseName": "Emerging Technologies ",
            "description": "The Emerging Technologies course examines emerging technologies, identifies the benefits and drawbacks of technology adoption, and provides students with a process to evaluate technologies. The course will examine three technologies that may have an impact on Information Technology services in the coming years. "
          },
          "C853": {
            "courseName": "Teacher Performance Assessment in English ",
            "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during a student’s time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of their content, planning, instructional, and reflective skills.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "C854": {
            "courseName": "Nursing Informatics Field Experience ",
            "description": "Nursing Informatics Field Experience requires students to complete clinical/practice experiences while engaging in authentic activities relevant to the role of an informatics nurse. To help students develop competency in this area, this course gives students opportunities to apply methods and solutions to support clinical decisions. They will be prepared to improve health outcomes by analyzing an existing health information system to determine the need for a system optimization that will improve an organization’s ability to measure and report Triple Aim objectives. All MSN Core and Specialty courses, with the exclusion of the Capstone course, are prerequisites to this course and must be completed before taking this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "C855": {
            "courseName": "Nursing Informatics Capstone ",
            "description": "Nursing Informatics Capstone requires students to complete clinical/practice experiences (CPE) and finalize their system optimization proposal paper, which addresses the Institute of Health’s Triple Aim initiative. During this course, students will plan the final phase of their system development life cycle (SDLC), which consists of proposing the processes, methods, and tasks for monitoring, maintaining, supporting, and evaluating their system optimization. The knowledge and skills that students acquire during the CPE in this course will prepare them to complete their system optimization proposal paper. This is a culminating course that provides students an opportunity to demonstrate the competencies acquired during this program. All MSN Core and Specialty courses, including the Field Experience course, are prerequisites to this course and must be completed before taking this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "C867": {
            "courseName": "Scripting and Programming ",
            "description": "Applications - Scripting and Programming - Applications for undergraduates explores the various aspects of the C++ programming language by examining its syntax, the development environment, and tools and techniques to solve some real-world problems. "
          },
          "C870": {
            "courseName": "Human Anatomy and Physiology ",
            "description": "This course examines the structures and functions of the human body and covers anatomical terminology, cells and tissues, and organ systems. Students will study the healthy state of the organ systems of the human body, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. There are no prerequisites for this course. "
          },
          "C871": {
            "courseName": "MA, Science Education Teacher Performance Assessment ",
            "description": "MA, Science Education Teacher Performance Assessment contains a comprehensive, original, research-based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision-making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
          },
          "C873": {
            "courseName": "Teacher Performance Assessment in Elementary Education ",
            "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills in this professional assessment.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "C874": {
            "courseName": "MA, Mathematics Education (5-12) Teacher Performance Assessment ",
            "description": "MA, Mathematics Education (5-12) Teacher Performance Assessment contains a comprehensive, original, research based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) Contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
          },
          "C875": {
            "courseName": "Human Anatomy and Physiology ",
            "description": "This course examines the structures and functions of the human body and covers anatomical terminology, cells and tissues, and organ systems. Students will study the healthy state of the organ systems of the human body, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. There are no prerequisites for this course. "
          },
          "C876": {
            "courseName": "Conceptual Physics ",
            "description": "Conceptual Physics provides a broad, conceptual overview of the main principles of physics, including mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. Problem-solving activities and laboratory experiments provide students with opportunities to apply these main principles, creating a strong foundation for future studies in physics. There are no prerequisites for this course. "
          },
          "C877": {
            "courseName": "Mathematical Modeling and Applications ",
            "description": "Mathematical Modeling and Applications applies mathematics, such as differential equations, discrete structures, and statistics to formulate models and solve real-world problems. This course emphasizes improving students’ critical thinking to help them understand the process and application of mathematical modeling. Probability and Statistics II and Calculus II are prerequisites. "
          },
          "C878": {
            "courseName": "Mathematical Modeling and Applications ",
            "description": "Mathematical Modeling and Applications applies mathematics, such as differential equations, discrete structures, and statistics to formulate models and solve real-world problems. This course emphasizes improving students’ critical thinking to help them understand the process and application of mathematical modeling. Probability and Statistics II and Calculus II are prerequisites. "
          },
          "C879": {
            "courseName": "Algebra for Secondary Mathematics Teaching ",
            "description": "Algebra for Secondary Mathematics Teaching explores important conceptual underpinnings, common student misconceptions and ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of algebra. Secondary teachers should have an understanding of the following: algebra as an extension of number, operation, and quantity; various ideas of equivalence pertaining to algebraic structures; patterns of change as covariation between quantities; connections between representations (tables, graphs, equations, geometric models, context); and the historical development of content and perspectives from diverse cultures. In particular, this course focuses on deeper understanding of rational numbers, ratios and proportions, meaning and use of variables, functions (e.g., exponential, logarithmic, polynomials, rational, quadratic), and inverses. Calculus I is a prerequisite for this course. "
          },
          "C880": {
            "courseName": "Algebra for Secondary Mathematics Teaching ",
            "description": "Algebra for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of algebra. Secondary teachers should have an understanding of the following: algebra as an extension of number, operation, and quantity; various ideas of equivalence as it pertains to algebraic structures; patterns of change as covariation between quantities; connections between representations (tables, graphs, equations, geometric models, context); and the historical development of content and perspectives from diverse cultures. In particular, the course focuses on deeper understanding of rational numbers, ratios and proportions, meaning and use of variables, functions (e.g., exponential, logarithmic, polynomials, rational, quadratic), and inverses. Calculus I is a prerequisite for this course. "
          },
          "C881": {
            "courseName": "Geometry for Secondary Mathematics Teaching ",
            "description": "Geometry for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of geometry. Secondary teachers in this course will develop a deep understanding of constructions and transformations, congruence and similarity, analytic geometry, solid geometry, conics, trigonometry, and the historical development of content. Calculus I and College Geometry are prerequisites for this course.Calculus I and College Geometry are prerequisites for this course. "
          },
          "C882": {
            "courseName": "Geometry for Secondary Mathematics Teaching ",
            "description": "Geometry for Secondary Mathematics Teaching explores important conceptual underpinnings, common student misconceptions and ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of geometry. Students in this course will develop a deep understanding of constructions and transformations, congruence and similarity, analytic geometry, solid geometry, conics, trigonometry, and the historical development of content. Calculus I is a prerequisite for this course. "
          },
          "C883": {
            "courseName": "Statistics and Probability for Secondary Mathematics Teaching ",
            "description": "Statistics and Probability for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of statistics and probability. Secondary teachers should have a deep understanding of summarizing and representing data, study design and sampling, probability, testing claims and drawing conclusions, and the historical development of content and perspectives from diverse cultures. Calculus I and Probability and Statistics I and II are prerequisites for this course. "
          },
          "C884": {
            "courseName": "Statistics and Probability for Secondary Mathematics Teaching ",
            "description": "Statistics and Probability for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of statistics and probability. Secondary teachers should have a deep understanding of summarizing and representing data, study design and sampling, probability, testing claims and drawing conclusions, and the historical development of content and perspectives from diverse cultures. Calculus I is a prerequisite for this course. "
          },
          "C885": {
            "courseName": "Advanced Calculus ",
            "description": "Advanced Calculus examines rigorous reconsideration and proofs involving calculus. Topics include real-number systems, sequences, limits, continuity, differentiation, and integration. This course emphasizes using critical thinking to analyze the connections between definitions and properties. Calculus III and Linear Algebra are prerequisites. "
          },
          "C886": {
            "courseName": "Advanced Calculus ",
            "description": "Advanced Calculus examines rigorous reconsideration and proofs involving calculus. Topics include real-number systems, sequences, limits, continuity, differentiation, and integration. This course emphasizes students’ ability to apply critical thinking to concepts to analyze the connections between definitions and properties. Calculus III and Linear Algebra are prerequisites. "
          },
          "C887": {
            "courseName": "MA, Mathematics Education (5-9) Teacher Performance Assessment ",
            "description": "MA, Mathematics Education (5-9) Teacher Performance Assessment contains a comprehensive, original, research based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
          },
          "C888": {
            "courseName": "Molecular and Cellular Biology ",
            "description": "Molecular and Cellular Biology provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to the area of molecular and cellular biology. This course examines the cell as an organism, emphasizing the molecular basis of cell structure and functions of biological macromolecules, subcellular organelles, intracellular transport, cell division, and biological reactions. Introduction to Biology is a prerequisite for this course. "
          },
          "C889": {
            "courseName": "Molecular and Cellular Biology ",
            "description": "Molecular and Cellular Biology provides graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary science education with an introduction to the area of molecular and cellular biology. This course examines the cell as an organism emphasizing molecular basis of cell structure and functions of biological macromolecules, subcellular organelles, intracellular transport, cell division, and biological reactions. A prerequisite for this course is Introduction to Biology. "
          },
          "C890": {
            "courseName": "Ecology and Environmental Science ",
            "description": "Ecology and Environmental Science is an introductory course for undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education. The course explores the relationships between organisms and their environment, including population ecology, communities, adaptations, distributions, interactions, and the environmental factors controlling these relationships. This course has no prerequisites. "
          },
          "C891": {
            "courseName": "Ecology and Environmental Science ",
            "description": "Ecology and Environmental Science is an introductory course for graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary or middle grade science education. The course explores the relationships between organisms and their environment, including population ecology, communities, adaptations, distributions, interactions, and the environmental factors controlling these relationships. This course has no prerequisites. "
          },
          "C892": {
            "courseName": "Geology II: Earth Systems ",
            "description": "Geology II: Earth Systems provides undergraduate students seeking licensure or endorsement in secondary science education with an examination of the geosphere, atmosphere, hydrosphere, biosphere, and the dynamic equilibrium of these systems over geologic time. This course also examines the history of Earth and its life-forms, with an emphasis in meteorology. Geology I: Physical is a prerequisite for this course. "
          },
          "C893": {
            "courseName": "Geology II: Earth Systems ",
            "description": "Geology II: Earth Systems provides graduate students seeking licensure or endorsement and/or to earn their MA degree in secondary science education with an examination of the geosphere, atmosphere, hydrosphere, and biosphere and the dynamic equilibrium of these systems over geologic time. This course also examines the history of Earth and its lifeforms, with an emphasis in meteorology. A prerequisite for this course is Geology I: Physical. "
          },
          "C894": {
            "courseName": "Astronomy ",
            "description": "Astronomy provides undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education with essential knowledge of astronomy. It explores Western history and basic physics of astronomy, phases of the moon and seasons, composition and properties of solar system bodies, stellar evolution and remnants, properties and scale of objects and distances within the universe, and introductory cosmology. General Physics is a prerequisite for this course. "
          },
          "C895": {
            "courseName": "Astronomy ",
            "description": "Astronomy provides graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary or middle grade science education with essential knowledge of astronomy. This course explores Western history and basic physics of astronomy; phases of the moon and seasons; composition and properties of solar system bodies; stellar evolution and remnants; properties and scale of objects and distances within the universe; and introductory cosmology. A prerequisite for this course is General Physics. "
          },
          "C897": {
            "courseName": "Mathematics: Content Knowledge ",
            "description": "Mathematics: Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful secondary mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, PreCalculus, Calculus I, and Calculus II. Linear Algebra, and Calculus III are recommended. "
          },
          "C898": {
            "courseName": "Earth Science: Content Knowledge ",
            "description": "This course covers the advanced content knowledge that a secondary earth science teacher is expected to know and understand. Topics include basic scientific principles of earth and space sciences, tectonics and internal earth processes, earth materials and surface processes, history of the Earth and its life-forms, Earth's atmosphere and hydrosphere, and astronomy. "
          },
          "C900": {
            "courseName": "Biology: Content Knowledge ",
            "description": "This comprehensive course examines a student’s conceptual understanding of a broad range of biology topics. High school biology teachers must help students make connections between isolated topics. This course starts with macromolecules that make up cellular components and continues with understanding the many cellular processes that allow life to exist. Connections are then made between genetics and evolution. Classification of organisms leads into plant and animal development that study the organ systems and their role in maintaining homeostasis. The course finishes by studying ecology and the effect humans have on the environment. "
          },
          "C901": {
            "courseName": "Physics: Content Knowledge ",
            "description": "Physics: Content Knowledge covers the advanced content knowledge that a secondary physics teacher is expected to know and understand. Topics include nature and impact of science and engineering, principle and models of matter and energy, mechanics, electricity and magnetism, waves, and science teaching and pedagogy. "
          },
          "C902": {
            "courseName": "Middle School Science: Content Knowledge ",
            "description": "This course covers the content knowledge that a middle-level science teacher is expected to know and understand. Topics include scientific methodologies, history of science, basic science principles, physical sciences, life sciences, earth and space sciences, and the role of science and technology and their impact on society. "
          },
          "C903": {
            "courseName": "Middle School Mathematics: Content Knowledge ",
            "description": "Mathematics: Middle School Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful middle school mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, and Pre-Calculus. "
          },
          "C904": {
            "courseName": "Teacher Performance Assessment in Science ",
            "description": "The Teacher Performance Assessment in Science course is culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "C907": {
            "courseName": "Introduction to Biology ",
            "description": "This course is a foundational introduction to the biological sciences. This course explores the overarching theories of life from biological research as well as the fundamental concepts and principles of the study of living organisms and their interaction with the environment. Key concepts include how living organisms use and produce energy; how life grows, develops, and reproduces; how life responds to the environment to maintain internal stability; and how life evolves and adapts to the environment. "
          },
          "C908": {
            "courseName": "Integrated Physical Sciences ",
            "description": "This course provides students with an overview of the basic principles and unifying ideas of the physical sciences: physics, chemistry, and Earth sciences. Course materials focus on scientific reasoning and practical and everyday applications of physical science concepts to help students integrate conceptual knowledge with practical skills. "
          },
          "C909": {
            "courseName": "Elementary Reading Methods and Interventions ",
            "description": "Elementary Reading Methods and Interventions provides candidates with an in-depth look at best practices for developing reading and writing skills. Course content examines the science of reading, the stages of and approaches to literacy development, differentiation, technology integration, literacy assessment, and Multitiered Systems of Supports (MTSS), including the comprehensive response to intervention (RTI) model used to identify and address the needs of learners who struggle with reading comprehension. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment. "
          },
          "C910": {
            "courseName": "Elementary Reading Methods and Interventions ",
            "description": "Elementary Reading Methods and Interventions provides candidates with an in-depth look at best practices for developing reading and writing skills. Course content examines the science of reading, the stages of and approaches to literacy development, differentiation, technology integration, literacy assessment, and Multitiered Systems of Supports (MTSS), including the comprehensive response to intervention (RTI) model used to identify and address the needs of learners who struggle with reading comprehension. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment. "
          },
          "C912": {
            "courseName": "College Algebra ",
            "description": "This course provides further application and analysis of algebraic concepts and functions through mathematical modeling of real-world situations. Topics include real numbers, algebraic expressions, equations and inequalities, graphs and functions, polynomial and rational functions, exponential and logarithmic functions, and systems of linear equations. "
          },
          "C914": {
            "courseName": "Teacher Performance Assessment in Mathematics Education ",
            "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "C915": {
            "courseName": "Chemistry: Content Knowledge ",
            "description": "Chemistry: Content Knowledge provides advanced instruction in the main areas of chemistry for which secondary chemistry teachers are expected to demonstrate competency. Topics include matter and energy, thermochemistry, structure, bonding, reactivity, biochemistry and organic chemistry, solutions, the nature of science and technology, mathematics, and laboratory procedures. "
          },
          "C918": {
            "courseName": "Evolving Roles of Nurse Educators in Diverse Environments ",
            "description": "Evolving Roles of Nurse Educators in Diverse Environments examines the multidimensional roles of a contemporary academic nurse educator. This course explores the roles and responsibilities of the nurse educator as a teacher, leader, change agent, and curriculum innovator. Students will also examine the importance of personal and professional development by developing strategies that promote academic integrity, cultural sensitivity, social justice, and ethical/legal values in diverse environments. The course emphasizes the responsibility of nurse educators to utilize communication, collaboration, and leadership in mitigating challenges in academic nursing education. "
          },
          "C919": {
            "courseName": "Facilitation of Context-Based Student-Centered Learning ",
            "description": "Facilitation of Context-Based Student-Centered Learning explores how the nurse educator will incorporate authentic experiences into the creation of course plans that facilitate scholarly inquiry, collaboration, and knowledge acquisition in varied educational environments. Emphasis is placed on innovative, transformational, and experiential teaching and learning strategies to facilitate student development of professional, context-based nursing principles, knowledge, skills, and behavior. Evolving Roles of Nurse Educators in Diverse Environments is a prerequisite to this course. "
          },
          "C920": {
            "courseName": "Contemporary Curriculum Design and Development in Nursing Education ",
            "description": "Contemporary Curriculum Design and Development in Nursing Education analyzes the concepts of creating curriculum based on national nursing accreditation standards and instructional design best practices. Nurse educator students will create course content that supports learning in diverse, real-world environments where nurse educators facilitate learning. Instructional design strategies for delivering course content will reflect the mission of academic institution programs, contemporary trends in nursing education, and the needs of key stakeholders in nursing education and practice. Facilitation of Context-Based Student-Centered Learning is a prerequisite to this course. "
          },
          "C921": {
            "courseName": "Assessment and Evaluation Strategies for Measuring Student Learning ",
            "description": "Assessment and Evaluation Strategies for Measuring Student Learning addresses the academic nurse educator's role in the design, development, implementation, and evaluation of student achievement outcomes in nursing education programs. This course requires students to integrate best practices from nursing theory and theories of learning to assess student learning in diverse educational settings. Topics include validity, reliability, and practicality of assessments, interpreting item difficulty and discrimination test results, and analyzing student achievement and learning outcomes data. This course has no prerequisites. "
          },
          "C922": {
            "courseName": "Emerging Trends and Challenges in 21st Century Nursing Education ",
            "description": "Emerging Trends and Challenges in 21st Century Nursing Education analyzes the emerging trends, technologies, and challenges that academic nurse educators encounter when facilitating learning in diverse healthcare settings. Students will focus on the necessity of interprofessional collaboration and the barriers and facilitators to overcoming the challenges associated with teaching and learning in nursing. Topics include the impact of emerging technology, challenges in nursing practice, and the role of the academic nurse educator as a scholar and a nursing education policy advocate. This course has no prerequisites. "
          },
          "C925": {
            "courseName": "Earth: Inside and Out ",
            "description": "Earth: Inside and Out explores the ways in which our dynamic planet evolved and the processes and systems that continue to shape it. Though the geologic record is incredibly ancient, it has only been studied intensely since the end of the nineteenth century. Since then, research in fields such as geologic time, plate tectonics, climate change, exploration of the deep seafloor, and the inner earth have vastly increased our understanding of geological processes. "
          },
          "C926": {
            "courseName": "Earth: Inside and Out ",
            "description": "Earth: Inside and Out explores the ways in which our dynamic planet evolved, and the processes and systems that continue to shape it. Though the geologic record is incredibly ancient, it has only been studied intensely since the end of the 19th century. Since then, research in fields such as geologic time, plate tectonics, climate change, exploration of the deep-sea floor, and the inner earth have vastly increased our understanding of geological processes. "
          },
          "C927": {
            "courseName": "Managing Technology Operations and Innovation ",
            "description": "Managing Technical Operations and Innovations explores the importance of innovation in the processes of operations management and business competitiveness. From the formulation of tactical operations plans from strategic objectives, IT executives need to create partnerships to drive innovation within an organization. This course provides students with the practical knowledge and understanding of operations management concepts, business models, methods, tools, applications and best practices used by successful organizations to improve their operations. This course has no prerequisites. "
          },
          "C928": {
            "courseName": "Financial Management for IT Professionals ",
            "description": "Financial Management for IT Professionals develops learners’ skills in financial management, budgeting, and procurement. This course teaches how to leverage financial know-how to improve workplace decision-making. This course also provides learners with the knowledge and skills necessary to spend money on the right projects and right equipment, while aligning operating budgets with strategic initiatives. There are no prerequisites for this course. "
          },
          "C929": {
            "courseName": "IT Sourcing and Development in a Global Economy ",
            "description": "IT Sourcing and Development in a Global Economy examines the practice of sourcing and developing global IT projects from a management perspective. In today’s organizations, leaders look for efficient and effective ways to deliver goods and services. This course will allow students to explore the strategic, operational, tactical, and security-related impacts on the organization of sourcing and supporting a global IT project. Students will cultivate a deep understanding of the documents, skills, and stakeholders needed for any given project and develop the ability to leverage these elements to achieve success. This course will also explore the ethical, cultural, and regulatory considerations surrounding sourcing and managing IT projects in a global space. There are no prerequisites for this course. "
          },
          "C930": {
            "courseName": "Preclinical Experiences in Mathematics ",
            "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C931": {
            "courseName": "Preclinical Experiences in Mathematics ",
            "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C932": {
            "courseName": "Preclinical Experiences in Mathematics ",
            "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document at least 75 hours of in-classroom observations. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C933": {
            "courseName": "Preclinical Experiences in Mathematics ",
            "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C934": {
            "courseName": "Preclinical Experiences in Elementary and Special Education ",
            "description": "Preclinical Experiences in Elementary and Special Education provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C935": {
            "courseName": "Preclinical Experiences in Elementary Education ",
            "description": "Preclinical Experiences in Elementary provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C936": {
            "courseName": "Preclinical Experiences in Elementary Education ",
            "description": "Preclinical Experiences in Elementary provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C937": {
            "courseName": "Preclinical Experiences in Science ",
            "description": "Preclinical Experiences in Science provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C938": {
            "courseName": "Preclinical Experiences in Science ",
            "description": "Preclinical Experiences in Science provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C940": {
            "courseName": "Science Methods—Secondary Biology ",
            "description": "Science Methods—Secondary Biology provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary biology. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
          },
          "C941": {
            "courseName": "Science Methods—Secondary Chemistry ",
            "description": "Science Methods—Secondary Chemistry provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary chemistry. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
          },
          "C942": {
            "courseName": "Science Methods—Secondary Earth Science ",
            "description": "Science Methods—Secondary Earth Science provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary earth science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
          },
          "C943": {
            "courseName": "Science Methods—Secondary Physics ",
            "description": "Science Methods—Secondary Physics provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary physics. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
          },
          "C945": {
            "courseName": "Preclinical Experiences in English ",
            "description": "Preclinical Experiences in English provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "C946": {
            "courseName": "Nursing Education Field Experience ",
            "description": "The Nursing Education Field Experience provides the academic nurse educator student an opportunity to work collaboratively with academic mentors and interprofessional stakeholders to analyze the need-gap for a curriculum change, innovation, or improvement. Based on the identified need-gap, the graduate student will design and develop a course that reflects evidence-based instructional design and assessment principles and practices. This course prepares students for the role of an Academic Nurse Educator, as an agent for change and quality improvement in nursing education. "
          },
          "C947": {
            "courseName": "Nursing Education Capstone ",
            "description": "The Nursing Education Capstone course provides the Nurse Educator student an opportunity to apply previous course work towards the completion of an evidence-based curriculum proposal project. During this course students will build on previous work during their Nursing Education Field Experience course by planning the implementation and evaluation phases of their proposed curriculum change, innovation or improvement. The capstone project represents a synthesis of competencies across the Masters Science of Nursing—Nursing Education degree program, which prepares them to lead, manage, and transform nursing education in diverse and complex settings.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "C948": {
            "courseName": "Technical Communication ",
            "description": "Technical Communication examines communication types and strategies that information technology executives will use to communicate effectively within an organization. As leaders, IT executives frequently contribute to business goals by designing and communicating specialized information in a variety of media to customers, clients, and other departments. In this course, students learn to communicate accurately, effectively, and ethically to a variety of audiences. Students choose, design, and deliver the communication product and assess the effectiveness to improve future communication. This course has no prerequisites. "
          },
          "C949": {
            "courseName": "Data Structures and Algorithms I ",
            "description": "Data Structures and Algorithms I covers the fundamentals of dynamic data structures, such as bags, lists, stacks, queues, trees, and hash tables, and their associated algorithms. This course discusses object-oriented design and abstract data types as design paradigms. The course emphasizes problem-solving and techniques for designing efficient, maintainable software applications. Students will implement simple applications using the techniques learned. This course has no prerequisites. "
          },
          "C950": {
            "courseName": "Data Structures and Algorithms II ",
            "description": "Data Structures and Algorithms II explores the analysis and implementation of high-performance data structures and supporting algorithms, including graphs, hashing, self-adjusting data structures, set representations, and dynamic programming. The course also introduces students to NP-complete problems. The course discusses how to use Python techniques to implement software solutions for problems of memory management and data compression. This course has two prerequisites: Data Structures and Algorithms I and Discrete Math II. "
          },
          "C951": {
            "courseName": "Introduction to Artificial Intelligence ",
            "description": "Introduction to Artificial Intelligence explores the foundational principles and practices of artificial intelligence (AI), machine learning, and robotics. The course prepares students to analyze relationships, build agents, and create models relevant to AI problems. The prerequisites for this course are Introduction to Probability and Statistics as well as Data Structures and Algorithms II. "
          },
          "C952": {
            "courseName": "Computer Architecture ",
            "description": "Computer Architecture introduces students to concepts and characteristics of organization and architecture applied to modern computer systems including performance, processor, memory, input/output, and multiprocessors to optimize system design, performance, and efficiency. "
          },
          "C954": {
            "courseName": "Information Technology Management ",
            "description": "IT Management introduces the key topics and skills needed to lead next-generation technology organizations. This course explores how common applications and innovation drive value and business needs. Ethical and regulatory compliance issues are discussed, including current practices for risk management, disaster recovery, and cybersecurity. Students will also analyze the key leadership skills and traits necessary to lead responsive, competitive, and innovative organizations. This course has no prerequisites. "
          },
          "C955": {
            "courseName": "Applied Probability and Statistics ",
            "description": "Applied Probability and Statistics is designed to help students develop competence in the fundamental concepts of basic statistics including: introductory algebra and graphing; descriptive statistics; regression and correlation; and probability. Statistical data and probability are often used in everyday life, science, business, information technology, and educational settings to make informed decisions about the validity of studies and the effect of data on decisions. This course discusses what constitutes sound research design and how to appropriately model phenomena using statistical data. Additionally, the content covers simple probability calculations, based on events that occur in the business and IT industries. No prerequisites are required for this course. "
          },
          "C957": {
            "courseName": "Applied Algebra ",
            "description": "Applied Algebra is designed to help you develop competence in working with functions, the algebra of functions, and using some applied properties of functions. You will start learning about how we can apply different kinds of functions to relevant, real-life examples. From there, the algebra of several families of functions will be explored, including linear, polynomial, exponential, and logistic functions. You will also learn about relevant, applicable mathematical properties of each family of functions, including rate of change, concavity, maximizing/minimizing, and asymptotes. These properties will be used to solve problems related to your major and make sense of everyday living problems. Students should complete Applied Probability and Statistics or its equivalent prior to engaging in Applied Algebra. "
          },
          "C958": {
            "courseName": "Calculus I ",
            "description": "This course guides candidates to apply theoretical concepts of calculus to real-world situations, demonstrating a developing mathematical mindset. This course focuses on limits, derivatives, integrals, and differential equations; it also prepares students for Discrete Mathematics. Prerequisites may include an entrance exam that assesses pre-calculus skills, or readiness; alternatively, completion of pre-calculus within the past 3 – 5 years. "
          },
          "C959": {
            "courseName": "Discrete Mathematics I ",
            "description": "Discrete Mathematics I helps candidates develop competence in the use of abstract, discrete structures fundamental to computer science. In particular, this course will introduce candidates to logic and proofs; Boolean algebra and functions; set theory; finite and infinite sequences and series; and relations, graphs, and trees. The course emphasizes applications in computer science. Calculus I is a prerequisite for this course. "
          },
          "C960": {
            "courseName": "Discrete Mathematics II ",
            "description": "Discrete Mathematics II addresses abstract, discrete, computational methods used in computer science. In particular, this class introduces searching and sorting algorithms; big-O estimates; number theory and cryptography; recursion and induction; counting and advanced counting techniques; discrete probability; and modeling computation. This course emphasizes applications in computer science. Discrete Mathematics I is a prerequisite for this course. "
          },
          "C962": {
            "courseName": "Current and Emerging Technology ",
            "description": "Current and Emerging Technologies explores organizational leadership trends, practices, processes, and technology in contemporary technology-intensive organizations. IT executives need to stay informed of technological trends to determine their relevance and implementation within an organization. This course requires students to read and evaluate academic literature pertaining to emerging IT topics. This course has no prerequisites. "
          },
          "C963": {
            "courseName": "American Politics and the US Constitution ",
            "description": "American Politics and the U.S. Constitution examines the evolution of representative government in the United States and the changing interpretations of the civil rights and civil liberties protected by the Constitution. This course will give candidates an understanding of the powers of the branches of the federal government, the continual tensions inherent in a federal system, the shifting relationship between state and federal governments, and the interactions between elected officials and the ever-changing electorate. This course will focus on such topics as the role of a free press in a democracy, the impact of changing demographics on American politics, and the debates over and expansion of civil rights. Upon completion of the course, candidates should be able to explain the basic functions of the federal government, describe the forces that shape American policy and politics, and be better prepared to participate in America’s civic institutions. This course has no prerequisite. "
          },
          "C964": {
            "courseName": "Computer Science Capstone ",
            "description": "The Computer Science Capstone course allows the student to demonstrate their application of the academic and professional abilities developed during the BSCS program. The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
          },
          "C965": {
            "courseName": "Teaching in the Middle School ",
            "description": "Teaching in the Middle School examines the guiding principles and best teaching practices for educating middle school students. The course explores the history of the middle school, the philosophy, theory, and rationale behind middle school organization; and the differences between elementary, middle, and secondary schools. The course also examines the unique needs of middle school students and teaching methods used to meet the needs of these learners. This course has no prerequisites. "
          },
          "C966": {
            "courseName": "Teaching in the Middle School ",
            "description": "Teaching in Middle School examines the guiding principles and best teaching practices for educating middle school students. The course explores the history of middle school; the philosophy, theory, and rationale behind middle school organization; and the differences between elementary, middle, and secondary schools. The course also examines the unique needs of middle school students and teaching methods used to meet the needs of these learners. This course has no prerequisites. "
          },
          "C968": {
            "courseName": "Software I – C# ",
            "description": "Software I - C# builds object-oriented programming expertise and introduces powerful new tools for C# application development. You will learn about and put into action: class design, exception handling, and other object-oriented principles and constructs to develop software that meets business requirements. This course requires foundational knowledge of object-oriented programming. Scripting and Programming: Foundations and Scripting and Programming: Applications are prerequisites for this course. "
          },
          "C969": {
            "courseName": "Software II – Advanced C# ",
            "description": "Software II - Advanced C# refines object-oriented programming expertise and builds database and file server application development skills. You will learn about and put into action lambda expressions, collections, and input/output to develop software with C# that meets business requirements. This course requires intermediate expertise in object-oriented programming and the C# language. The prerequisite for this course is Software I - C#. "
          },
          "C970": {
            "courseName": "Children’s Literature ",
            "description": "Children's Literature is an introduction to and exploration of children's literature. Students will consider and analyze children's literature as a lens through which to view the world. Students will experience multiple genres, historical perspectives, cultural representations, and current applications in the field of children's literature. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
          },
          "C971": {
            "courseName": "Mobile Application Development Using C# ",
            "description": "Mobile Application Development Using C# introduces students to programming for mobile devices. Building on students’ previous programming knowledge in C#, this course explores a broad range of topics, including mobile user interface design and development; building applications that adapt to different mobile devices and platforms; managing data using a local database; and consuming REST-based web services. In this course, students will focus on developing skills using the latest framework designed to provide a more modern and streamlined development experience. This framework will help students design and code cross-platform applications that work on a range of mobile devices. There are several prerequisites for this course: Software I and II, and UI Design. "
          },
          "C972": {
            "courseName": "College Geometry ",
            "description": "College Geometry covers the knowledge and skills necessary to use dynamic technology to explore geometry, to use axiomatic reasoning to prove statements about geometry, and to apply geometric models to solve real-life problems. Topics include axiomatic systems, analytic proofs, coordinate geometry, plane and solid Euclidean geometry, non-Euclidean geometries, constructions, transformations, deductive reasoning, and dynamic technology. College Algebra as well as Trigonometry and Precalculus are prerequisites. "
          },
          "C974": {
            "courseName": "Science Methods—Middle Grades General Science ",
            "description": "Science Methods—Middle Grades General Science provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in Middle School Science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
          },
          "C975": {
            "courseName": "Science Methods—Middle Grades General Science ",
            "description": "Science Methods—Middle Grades General Science focuses on teaching methods specific to science for graduate students seeking an endorsement in middle school science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
          },
          "C976": {
            "courseName": "Science Methods—Secondary Biology ",
            "description": "Science Methods—Secondary Biology focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary biology. This course focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
          },
          "C977": {
            "courseName": "Science Methods—Secondary Chemistry ",
            "description": "Science Methods—Secondary Chemistry focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary chemistry. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
          },
          "C978": {
            "courseName": "Science Methods—Secondary Earth Science ",
            "description": "Science Methods—Secondary Earth Science focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary earth science. Course content focuses on the design and teaching of standardsbased lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
          },
          "C979": {
            "courseName": "Science Methods—Secondary Physics ",
            "description": "Science Methods—Secondary Physics focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary physics. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
          },
          "C992": {
            "courseName": "College Geometry ",
            "description": "College Geometry covers the knowledge and skills necessary to use dynamic technology to explore geometry, to use axiomatic reasoning to prove statements about geometry, and to apply geometric models to solve real-life problems. Topics include axiomatic systems, analytic proofs, coordinate geometry, plane and solid Euclidean geometry, non-Euclidean geometries, constructions, transformations, deductive reasoning, and dynamic technology. College Algebra as well as Trigonometry and Precalculus are prerequisites. "
          },
          "CQC2": {
            "courseName": "Calculus II ",
            "description": "Calculus II is the study of the accumulation of change in relation to the area under a curve. It covers the knowledge and skills necessary to apply integral calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include antiderivatives; indefinite integrals; the substitution rule; Riemann sums; the fundamental theorem of calculus; definite integrals; acceleration, velocity, position, and initial values; integration by parts; integration by trigonometric substitution; integration by partial fractions; numerical integration; improper integration; area between curves; volumes and surface areas of revolution; arc length; work; center of mass; separable differential equations; direction fields; growth and decay problems; and sequences. Calculus I is a prerequisite for this course. "
          },
          "CUA1": {
            "courseName": "Culture ",
            "description": "Focuses on the nature and role of culture and the importance of cultural groups and cultural identity. "
          },
          "D001": {
            "courseName": "Behavioral Support Strategies for K-12 Learners with Mild to Moderate Exceptionalities ",
            "description": "Behavioral Support Strategies for K–12 Learners with Mild to Moderate Exceptionalities prepares candidates to work effectively with students exhibiting behavior in the classroom that is below age and cultural norms. This course provides an overview of behavior disorders and their causes, as well as appropriate research-based intervention strategies, including positive behavior intervention and supports, multi-tiered systems of support (MTSS), applied behavior analysis, replacement behavior and reward strategies, culturally responsive practices, and data collection and assessment methods. After completing this course candidates emerge prepared to strategize and recommend adjustments to the learning environment that support positive behavior and student success in the classroom and beyond. This course also examines behavioral assessment and analysis, including the creation of functional behavior assessment (FBA) and the creation and monitoring of behavioral improvement plans (BIP) in an authentic learning environment. This course is designed to be taken after successful completion of Fundamentals of Diverse Learners or Fundamentals of Diversity Inclusion, and Exceptional Learners, Professional, Ethical, and Legal Practices for Special Education, and Managing Engaging Learning Environments or Classroom Management, Engagement, and Motivation. "
          },
          "D002": {
            "courseName": "Professional, Ethical, and Legal Practices for Special Education ",
            "description": "Professional, Ethical, and Legal Practices for Special Education prepares candidates to practice within ethical and legal guidelines in day-to-day teaching, stakeholder interactions, and other complex situations. This course provides an overview of the professional ethics and standards from the Council for Exceptional Children (CEC), which guide candidates to act in a professionally conscientious manner. Candidates will explore the legal foundations and case laws related to special education to gain understanding of how legislation influences teaching and learning. This course is designed to be taken after successful completion of Fundamentals of Diverse Learners OR Fundamentals of Diversity, Inclusion, and Exceptional Learners. "
          },
          "D003": {
            "courseName": "Assessment in Special Education ",
            "description": "Assessment in Special Education prepares candidates to use multiple methods of assessment and data sources in making educational decisions about the student and the learning environment. This course is designed to help provide an understanding of how assessment data is used during screening in multitiered systems of support (MTSS), the eligibility process, the evaluation process, progress monitoring, and data-based instructional decision making. This course is designed to be taken after successful completion of Professional, Ethical, and Legal Practices for Special Education and Assessing Impact on Student Learning OR Educational Assessment. "
          },
          "D004": {
            "courseName": "Collaborating with Partners for Student Success ",
            "description": "Collaborating with Partners for Student Success prepares candidates to apply team processes and communication strategies to collaborate in a culturally responsive manner with families, paraeducators, and other professionals (within the school, other educational settings, and the community) to plan programs and access services for students with exceptionalities and their families. The course introduces ways to enhance parental involvement and family engagement while teaching families and students advocacy throughout the Individualized Education Program (IEP) and transition planning processes. This course also focuses on the components of the IEP and how the practice of effective communication and collaboration skills is key to the program's development and implementation. Candidates will engage in three hours of preclinical experiences that includes a simulated collaborative experience in which skills learned can be applied. This course is designed to be taken after successful completion of Professional, Ethical, and Legal Practices for Special Education OR Fundamentals of Diversity, Inclusion, and Exceptional Learners, and Assessment in Special Education. "
          },
          "D005": {
            "courseName": "Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities ",
            "description": "Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities introduces candidates to a repertoire of evidence-based instructional strategies to advance the learning of students with exceptionalities. The course focuses specifically on strategies for intensifying and individualizing instructional interventions; making instructional decisions based on progress-monitoring data; collaborating with general education teachers and paraeducators; teaching to mastery; promoting generalization of learning; and teaching students with exceptionalities how to use self-assessment, problem solving, and other cognitive strategies to organize critical content and meet their needs. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and Presentation AND Instructional Planning  and Presentation in Special Education. "
          },
          "D006": {
            "courseName": "Instructional Strategies and Technologies for Elementary Learners with Mild to Moderate Exceptionali ",
            "description": "Instructional Strategies and Technologies for Elementary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for elementary students receiving special education services. The course includes cognitive and metacognitive strategies that elementary students can use to acquire new content knowledge and generalize skills across learning environments. It also provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math and English language arts lesson plans based on learner characteristics, performance data, and individualized education program (IEP) goals. In addition to discussing how to make appropriate accommodations, the course teaches candidates how to assess student learning through progress monitoring and apply intensive interventions when warranted. This course is designed to be taken after successful completion of Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities. "
          },
          "D007": {
            "courseName": "Instructional Strategies and Technologies for Secondary Learners with Mild to Moderate Exceptionalit ",
            "description": "Instructional Strategies and Technologies for Secondary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for use with secondary students receiving special education services. Strategies taught in this course focus on intensive instruction and making accommodations to secondary lesson plans in order to develop critical thinking and problem-solving skills to enhance acquisition of ageappropriate secondary content across academic disciplines. This course also promotes the achievement of Individualized Education Program (IEP) and transition goals for independent living and career preparation through demonstration of strategies that increase students' self-awareness, selfregulation, self-management, self-control, and self-esteem. This course is designed to be taken after successful completion of Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities. "
          },
          "D009": {
            "courseName": "Preclinical Experiences in Special Education ",
            "description": "Pre-Clinical Experiences in Special Education provides candidates the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Candidates will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, candidates will be required to include a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "D010": {
            "courseName": "Disciplinary Literacy ",
            "description": "Disciplinary Literacy examines teaching strategies designed to help candidates to develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help candidates distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and  Presentation AND Instructional Planning and Presentation in Special Education. "
          },
          "D016": {
            "courseName": "Leadership Foundations and Ethics ",
            "description": "Leadership Foundations and Ethics presents candidates with a variety of leadership theories and strategies used by PK–12 educational leaders to develop, sustain, and evaluate a coherent system of academic and social supports that meet the full range of students' needs. Foundational knowledge addresses the importance of developing mission, vision, and core values in collaboration with faculty, staff, and the school community to advocate for student success. The course also covers communication strategies, interpersonal skills, and using data to build community, influence school culture, and manage change for continuous improvement. In addition, candidates are introduced to the significance of following professional ethical codes and the importance of modeling and advocating ethical behavior with all stakeholders. "
          },
          "D017": {
            "courseName": "School Law ",
            "description": "School Law prepares candidates to understand the appropriate application of laws, rights, policies, and regulations to promote student success. The course emphasizes the importance of understanding the history of and relationship between federal and state laws, legal decisions, local education policies, and practices at the local school level to ensure compliance. The course further focuses on understanding the legal rights and protections provided for all students, including those with disabilities, as well as school staff. It also addresses curriculum and instruction that help stakeholders understand the possible effects these rights may have on administrative decisions. Candidates are also provided the opportunity to demonstrate their capability to evaluate legal consequences of administrative decisions. "
          },
          "D018": {
            "courseName": "Leading Inclusive Schools ",
            "description": "Leading Inclusive Schools covers a variety of topics that directly affect students who have been assessed and determined to need additional support or services to ensure their academic success and well-being. The course prepares candidates to understand and comply with applicable laws, rights, policies, and regulations as appropriate to address matters of equity, fairness, and student marginalization based on culture and language, disability, or giftedness. These include types of special education classifications and their significance, working with English learners (ELs), working with gifted and talented students, and using Multi-Tiered System of Supports (MTSS) frameworks to ensure optimum learning environments for diverse learners. This course will guide candidates in building a strong repertoire of skills and knowledge related to exceptional students. It will help them ensure that each student has equitable access to effective teachers; learning opportunities; academic, social, and behavioral support; and other resources necessary for success. This course is designed to be taken after successful completion of the School Law course. "
          },
          "D019": {
            "courseName": "Data Literacy and Evidence-Based Practices ",
            "description": "Data Literacy and Evidence-Based Practices focuses on the development of data literacy skills educators need to improve the learning and development opportunities of K–12 students. Candidates will practice identifying educational problems and data types, generating data, analyzing data, making inferences and drawing conclusions, and creating action plans within their educational settings. Candidates will also learn best practices for data literacy, including continuous improvement planning, approaches to professional learning communities, and instructional decision-making processes. This course has no prerequisites. "
          },
          "D020": {
            "courseName": "Cultural Competency and Social-Emotional Learning ",
            "description": "Cultural Competency and Social-Emotional Learning focuses on fostering cultural competence among professional educators by increasing knowledge of diverse learner populations, implementing culturally responsive pedagogy, and ensuring social justice and equity in the educational setting. Candidates also will participate in learning experiences designed to ensure they can lead efforts to meet the social and emotional learning needs of all learners, contributing to a school environment that builds learners' personal agency and academic success. Advocacy strategies are learned in this course, ensuring candidates possess the tools to positively impact school environments both locally and globally. This course has no prerequisites and candidates are strongly encouraged to take this early in their program. "
          },
          "D021": {
            "courseName": "Leadership of Curriculum Design and Instruction ",
            "description": "Leadership of Curriculum Design and Instruction prepares candidates to evaluate and implement curricular programs and instructional methods observed at the school level. Candidates focus on the knowledge and skills needed to develop, align, and implement cohesive systems of curriculum, instruction, and assessment. Importance is placed on responding to student needs, embodying high expectations for student learning, aligning with academic standards within and across grade levels, and promoting students' academic success and social and emotional well-being. This course also covers the selection and use of appropriate technologies to monitor student progress and improve instruction support for assessment, data collection, management, and analysis. Candidates are prepared to build a professional culture of trust and collaboration to ensure they are able to work with school personnel in creating curricular programs and instructional methods that are engaging and challenging and relevant to student needs, experiences, and interests. This course is designed to be taken after successful completion of D017: School Law. "
          },
          "D022": {
            "courseName": "People and Talent in Educational Leadership ",
            "description": "People and Talent in Educational Leadership prepares candidates to understand and implement practices used to recruit, hire, and prepare school personnel to provide students with an optimal learning environment. Various school professional development practices, such as professional learning communities, collaborative learning communities, beginning teacher induction, and mentor programs, will be covered. Additionally the course covers methods to evaluate school personnel appropriately based on data-driven decisions; providing realistic and actionable feedback to school personnel to continuously drive improvement; engaging all school personnel in the use and evaluation of competing school-wide initiatives; creating and sustaining a professional culture of engagement and commitment by developing workplace conditions that promote employee development, well-being, and professional growth; and continuously supporting school personnel to improve their instructional practices through ongoing professional development. The candidate will also reflect on leadership standards in order to develop a personal professional growth plan. A prerequisite for this course is D017: School Law. "
          },
          "D023": {
            "courseName": "School Financial Leadership ",
            "description": "School Financial Leadership focuses on financial policies, practices, and issues connected to PK–12 school operations. The course describes various sources of school funding, the impact these sources can have on managing school budgets, and the challenges connected to finances that are often encountered by school leaders to ensure equitable financial support for all students. Candidates learn how to analyze different types of school budgets and understand the principal's role in the budgetary process to ensure alignment to the school's mission, vision, and values. This course also identifies and explains various types of commonly used accounting regulations, rules, and professional ethical principles used to create, maintain, and evaluate school budgets to ensure the equitable and ethical use of financial resources. This course is designed to be taken after successful completion of D017: School Law. "
          },
          "D024": {
            "courseName": "Professional Presence and Influence ",
            "description": "Professional Presence and Influence is a masters-level course designed to guide students towards an enhanced state of presence, where therapeutic relationships are built between nurse and patient. Students will learn techniques for self-care practices that result in enhanced mental and physical wellbeing and that ensure ethically-generated patient care. Presence is an intrapersonal and interpersonal quality that allows the nurse to relate to others and to be aware of the world around them. The characteristics of presence, which include holism, intimacy, sensitivity and adaptability, create a heightened sense of awareness that fosters therapeutic relationships between the nurse and patient. Developing a mindful, authentic presence is central to health and spiritual practices in several cultures and a major element of leadership. Students will intentionally develop a focused mindfulness practice that will influence patient outcomes and lead to conditions that create joy in the workplace. "
          },
          "D025": {
            "courseName": "Essentials of Advanced Nursing Roles and Interprofessional Practice ",
            "description": "Essentials of Advanced Nursing Roles and Interprofessional Practice explores essential characteristics of the advanced professional nurse in the role of leader, educator, practitioner, or informatics specialist. In this course, students will apply evidence-based strategies to facilitate interprofessional collaboration on teams. Students will explore the role of nurses in advocating for change at the bedside, as well as leading teams to advocate for health policy reform. Students will gather and analyze data to identify patients and populations at risk and recommend policy change to improve health outcomes in the community. "
          },
          "D026": {
            "courseName": "Quality Outcomes in a Culture of Value-Based Nursing Care ",
            "description": "Quality Outcomes in a Culture of Value-Based Nursing Care incorporates current standards of quality and safety within the context of value-based care. In a value-based healthcare system, the benefits are derived from measuring health outcomes against the cost of delivering the outcomes. These benefits are then extended to patients, providers, payers, suppliers, and society as a whole. This course introduces new healthcare delivery models, which stress a team-oriented approach to patient care and sharing of patient data so that care is coordinated, and outcomes can be measured easily. Emphasis is placed on performance and quality improvement methods that underlie value-based nursing care. The nurse in advanced practice today must exemplify the standards of quality and safety and be prepared to lead the delivery of value-based patient-centered care. "
          },
          "D027": {
            "courseName": "Advanced Pathopharmacological Foundations ",
            "description": "Advanced Pathopharmacological Foundations provides advanced practice nurses foundational knowledge in the many pathologies encountered in practice today. Advancing from the cellular to the body system level, this course examines the pathologies of common conditions seen in healthcare today. Consideration is also given to the human affective response to alterations in health. There are no prerequisites for this course. "
          },
          "D028": {
            "courseName": "Advanced Health Assessment for Patients and Populations ",
            "description": "Advanced Health Assessment of Patients and Populations builds on prior physical health assessment knowledge and skills acquired during undergraduate studies by focusing on the advanced assessment of biopsychosocial and sociocultural contexts in patients and populations across the life span. This course emphasizes the use of a comprehensive health promotion, disease prevention, and health restoration model to address health concerns in patients and communities. Students will acquire advanced assessment knowledge and skills for clinical interviewing, focused history taking, critical diagnostic reasoning, and clinical decision-making using a problemfocused framework that integrates authentic experiences with practical knowledge of health patterns in patients and communities. There are no prerequisites for this course. "
          },
          "D029": {
            "courseName": "Informatics for Transforming Nursing Care ",
            "description": "Informatics for Transforming Nursing Care integrates nursing science with multiple information and analytical sciences to identify, define, manage, and communicate data, information, knowledge, and wisdom in nursing practice. Students will acquire knowledge and skills to apply informatics concepts, communications, and data that are critical to facilitating interprofessional data-driven decision-making. It is designed to build competence in the use of patient- and population-based applications that inform and support the transformation of nursing care delivery toward a future of value-based quality nursing care that improves health outcomes. This course aligns theoretical concepts with practical applications of informatics and is consistent with the functional areas and responsibilities of informatics nurses as defined by the American Nurses Association Scope and Standards for nursing informatics. "
          },
          "D030": {
            "courseName": "Leadership and Management in Complex Healthcare Systems ",
            "description": "Leadership and Management in Complex Healthcare Systems prepares graduate nurses to be thoughtful strategists and informed decision-makers who serve as strong leaders in high-performing healthcare systems. Students develop competencies for managing diverse teams in complex systems, monitoring and measuring organizational performance, allocating financial and human resources, and leading change towards a transformed healthcare system. Additionally, students acquire the knowledge and skills to become full partners with other healthcare professionals by demonstrating nurse contributions toward high-quality care to patients and populations, while working collaboratively with interprofessional teams. There are no prerequisites for this course. "
          },
          "D031": {
            "courseName": "Advancing Evidence-Based Innovation in Nursing Practice ",
            "description": "Advancing Evidence-Based Innovation in Nursing Practice introduces students to the dynamic union of healthcare innovation and evidence. Core competencies and behaviors required to be a nurse innovator are discussed. Strategies for measuring innovation at various system levels are presented, as well as techniques for synthesizing and disseminating evidence to advance innovation in healthcare. The skills needed to appraise the quality of diverse sources of evidence are presented within the framework of evidence-based practice. This course focuses on identifying new and emerging sources of evidence that can inform, translate, and scale the complexity of leading innovation in healthcare organizations. Students will experience building communities of practice for collaboratively developing innovative practices and policies designed to improve the health of populations and enhance the patient experience of care. "
          },
          "D033": {
            "courseName": "Healthcare Information Systems Management ",
            "description": "Healthcare Information Systems Management provides an overview of many facets of information systems in healthcare. This course explores how information technology (IT) is an organizational resource that must be managed so that it supports or enables healthcare organizational strategy. This course will discuss how decision support and communication are securely facilitated in the healthcare marketplace. This course also explores current and continuously evolving technologies, strategic thinking, and issues at the intersection of health information management and technology. "
          },
          "D034": {
            "courseName": "Systems Management and School Operations ",
            "description": "Systems management and school operations instruct candidates on the operational aspects of school leadership that are essential to developing, monitoring, and evaluating school management, school systems, and services that address and support the needs of students and school personnel. Topics presented in this course include systems thinking; development, implementation, and evaluation of data-based strategic planning; and school improvement processes. Candidates will evaluate the use of appropriate operational technology and the development of communications systems that provide actionable information to internal and external stakeholders for use in classroom and school improvement and community engagement. Each of these topics emphasizes the importance of efficiently and effectively managing school resources to build, maintain, and evaluate a cohesive system of academic and organizational supports, services, extracurricular activities, and accommodations to meet the full range of needs for each student. Prerequisites for this course: Leadership Foundations and Ethics and School Law. "
          },
          "D035": {
            "courseName": "Educational Inquiry ",
            "description": "Educational Inquiry focuses on practical problem solving. This course teaches candidates to use scholarly literature to inform their own practice. It also teaches candidates to engage in their own action research processes, which empowers educators to recognize opportunities for improvement and to systematically implement and evaluate changes. This course prepares candidates to conduct research for the capstone. Prerequisites for this course: Data Literacy and Evidence-Based Practices. "
          },
          "D036": {
            "courseName": "Practicum in Educational Leadership ",
            "description": "Focus on Professional Practices - Practicum in Educational Leadership - Focus on Professional Practices provides candidates with an authentic, real-world work experience as an educational leader in a K–12 school environment. This is the first of a two-part experience designed to take place under the leadership and supervision of a practicing school principal or assistant principal at an approved practicum school site (K–12). This course includes an emphasis on the application of knowledge and skills to areas directly or indirectly affecting students. Collaboration within the school and local community is a focal point for this course. The course also includes the completion of assigned administrative duties in a K–12 setting, as defined by the candidate's state of residence, under the supervision of the cooperating administrator of the candidate's approved practicum site. Prior to enrolling in this practicum course, the candidate must complete a minimum of 18 CUs. "
          },
          "D037": {
            "courseName": "Practicum in Educational Leadership ",
            "description": "Focus on Instruction and Operations - Practicum in Educational Leadership - Focus on Instruction and Operations provides candidates with an authentic, real-world work experience as an educational leader in a K–12 school environment. This is the second of a two-part experience designed to take place under the leadership and supervision of a practicing school principal or assistant principal at an approved practicum school site (K–12). This course includes an emphasis on the application of knowledge and skills to areas affecting school operations and school personnel. The course also includes the completion of assigned administrative duties in a K–12 setting, as defined by the candidate's state of residence, under the supervision of the cooperating administrator of the candidate's approved practicum site. Prior to enrolling in this practicum course, the candidate must complete a minimum of 18 CUs. "
          },
          "D038": {
            "courseName": "Educational Leadership Capstone ",
            "description": "Educational Leadership Capstone serves as the culminating experience of this degree program, uniting content area knowledge with the execution of a problem-based learning project. Under the guidance of program faculty, candidates will apply their data literacy and research skills authentically and to topics appropriate to the candidate's degree program and future career goals. Projects will include action research or program evaluation and the qualitative or quantitative research methods necessitated by the project's purpose. Prerequisites include Data Literacy and Educational Inquiry, as well as all content area courses and field experiences prescribed in one's area of study. This course is designed to be taken after successful completion of all courses with the exception of Educational Inquiry, which may be taken concurrently. "
          },
          "D072": {
            "courseName": "Fundamentals for Success in Business ",
            "description": "This introductory course provides students with an overview of the field of business and a basic understanding of how management, organizational structure, communication, and leadership styles affect the business environment. It also introduces them to some of the power skills that help make successful business professionals, including time management, problem solving, emotional intelligence and innovation; while also teaching them the importance of ethics. This course gives students an opportunity to begin to explore their own strengths and passions in relation to the field while also acclimating them to the online competency-based environment. "
          },
          "D075": {
            "courseName": "Information Technology Management Essentials ",
            "description": "Information Technology Management Essentials includes topics such as information systems analysis, database resource management, spreadsheet literacy, and computer literacy concepts. This course will help students understand the importance of information technology in an organization and apply databases to solve business problems. "
          },
          "D076": {
            "courseName": "Finance Skills for Managers ",
            "description": "This course provides students with an introductory look at the discipline of finance and its context within the business environment. Students gain the knowledge to differentiate between personal and business finance and how they may overlap in a business environment. Students also gain a fundamental knowledge of financial forecasting and budgeting, statement analysis, and decision making. This course provides the student a business generalist overview of the field of finance and builds on previous acquired competencies related to using spreadsheets. "
          },
          "D077": {
            "courseName": "Concepts in Marketing, Sales, and Customer Contact ",
            "description": "Concepts in Marketing, Sales, and Customer Contact introduces students to the discipline of marketing and its role within the strategic and operational environments of a business. This course covers fundamental knowledge in the area of marketing planning, including the marketing mix, while also describing basic concepts of brand management, digital marketing, customer relationship management, and personal selling and negotiating. All of this helps students identify the role of marketing within an organization. This course provides students with a business generalist overview of the field of marketing and an exploration of the marketing major. "
          },
          "D078": {
            "courseName": "Business Environment Applications I: Business Structures and Legal Environment ",
            "description": "Business Environment Applications 1 provides students with a generalist overview of the business environment and a deeper look at a number of topics that make up the non-discipline areas of business which are required for a business person to be successful within any business environment. The first part of the course focuses on knowledge about organizations and how people operate within organizations, including the areas of organizational theory, structure, and effectiveness. The course then looks at business from a legal perspective with an overview of the legal environment of business. The course will prepare the student to consider specific legal situations and to make legal and ethical decisions related to those situations. "
          },
          "D079": {
            "courseName": "Business Environment Applications II: Process, Logistics, and Operations ",
            "description": "Business Environment II: Logistics, Process, and Operations provides students with a generalist overview of the business environment as they explore themes of ethics, problem-solving, and innovative thinking. This course adds to the students’ business skills and knowledge in a number of professional areas. The first part of the course uncovers a series of business processes like project and risk management. The second part gives an introductory-level look at the specialized areas of operations management, supply chains, and logistics. The course finishes with models of change management and how to use them to overcome barriers in organizations. "
          },
          "D080": {
            "courseName": "Managing in a Global Business Environment ",
            "description": "Managing in a Global Business Environment provides students with a generalist overview of business from a global perspective, while also developing basic skills and knowledge to help them make strategic decisions, communicate, and develop personal relationships in a global environment. Business today is by its very nature a global environment, and individuals working in business will experience the global nature of business as they progress through their careers. This course builds on previously acquired competencies by providing an overview of U.S. federal laws in relation to doing business in a global environment. "
          },
          "D081": {
            "courseName": "Innovative and Strategic Thinking ",
            "description": "This course covers an important part of being a business professional: the knowledge and skills used in building and implementing business strategy. The course helps students build on previously acquired competencies in the areas of management, innovative thinking, and risk management while introducing them to the concepts and theories underpinning business strategy as a general business perspective. The course will help students gain skills in analyzing different business environments and in using quantitative literacy and data analysis in business strategy development and implementation. This course helps to provide students with a generalist overview of the area of business strategy. "
          },
          "D082": {
            "courseName": "Emotional and Cultural Intelligence ",
            "description": "Emotional and Cultural Intelligence focuses on key personal awareness skills that businesses request when hiring personnel. Key among those abilities is communication. Students will increase their skills in written, verbal, and nonverbal communication skills. The course then looks at three areas of personal awareness including emotional intelligence (EI), cultural awareness, and ethical selfawareness – building on previously acquired competencies and adding new ones. This course helps start students on a road of self-discovery, cultivating awareness to improve both as a business professional and personally. "
          },
          "D089": {
            "courseName": "Principles of Economics ",
            "description": "Principles of Economics provides students with the knowledge they need to be successful managers, including basic economic theories related to markets and how markets function. This course starts by defining economics, differentiating between microeconomics and macroeconomics, and explaining the fundamental economic principles of each. It then looks at microeconomics and how it is used to make business and public policy decisions, including the principles of supply, demand, and elasticity, market efficiency, cost of production, and different market structures. The course finishes by looking at macroeconomics and how it is used to make business and public policy decisions, including measurement of macroeconomic variables, aggregate supply and demand, the concepts of an open economy, and how trade policies influence domestic and international markets. "
          },
          "D090": {
            "courseName": "The School as a Community of Care ",
            "description": "The School as a Community of Care is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to meet the social and emotional needs of learners, taking into account theories and philosophical perspectives on child and adolescent development and learning. Candidates learn to effectively collaborate with parents, families, caregivers, and other community stakeholders in each child's education, to build a strong foundation for academic and personal success. Emphasis is placed on family engagement as candidates gain knowledge of individual, cultural, and community assets that can be used to facilitate learner growth and development, as well as understand mental health and emotional differences among learners that may necessitate leveraging additional resources to support students' wellbeing. Issues of youth mental health, substance abuse, suicide awareness and prevention, and abuse within families will be addressed as will the importance of parent involvement. Candidates will engage in seven hours of preclinical experiences, which include visual observations of learning environments that involve parents and families in their children's' education while supporting the social and emotional learning (SEL) needs of learners and an interview with an educational professional to explore topics related to parent involvement, youth mental health issues, and professional responsibilities to ensure student wellbeing. Additionally, crosscutting themes of technology and diversity are interwoven for further development. "
          },
          "D091": {
            "courseName": "Introduction to Curriculum, Instruction, and Assessment ",
            "description": "Introduction to Curriculum, Instruction, and Assessment is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course provides candidates with the knowledge and skills necessary to create engaging and standards-aligned lessons that meet the needs of all learners. Candidates will learn to analyze learner needs based on a variety of inputs, including their state P–12 standards, assessment results, and knowledge of learner differences. This course will help candidates design, deliver, and modify instruction in accordance to needs and educational requirements. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. They also will record a short teaching segment, allowing for authentic teaching experience. Cross-cutting themes of technology and diversity are interwoven for continued development. "
          },
          "D092": {
            "courseName": "Educational Technology for Teaching and Learning ",
            "description": "Educational Technology for Teaching and Learning is a key component of Whalley's professional core and is a required course for all initial licensure candidates. This course prepares candidates to incorporate technology into their classroom practices in ways that improve teaching and learning. The ISTE standards will form the basis for their practice. The material will teach candidates to critically evaluate software and hardware options that may positively impact the classroom environment, while also increasing their awareness of ethical usage and considerations related to equity, access to technology, and appropriate use of technology by P–12 students. Assistive technologies to meet the needs of a diverse learner population also will be taught in this course. Candidates will engage in three hours of preclinical experience that include virtual observations of classroom practices incorporating technology to support educational goals. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D093": {
            "courseName": "Assessing Impact on Student Learning ",
            "description": "Assessing Impact on Student Learning is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course equips candidates to evaluate student learning and their own professional practice, ensuring candidates are prepared to ensure all learners' success. In this course, candidates learn multiple methods of assessment to ensure they are able to implement a balanced approach to assessment while monitoring their students’ progress. Assessments types such as formative, summative, standardized, and common assessments are addressed so candidates understand their purposes and can apply them within the context of a lesson to determine impact on learning. Data literacy skills are taught to ensure candidates interpret and analyze individual and classroom data and apply their knowledge in ways that support academic success. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D094": {
            "courseName": "Educational Psychology and Development of Children and Adolescents ",
            "description": "Educational Psychology and Development of Children and Adolescents is a key component of Whalley’s Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to support classroom practices grounded in research-validated principles from the areas of educational psychology and child/adolescent development. Candidates will be introduced to learning theories that equip them with the knowledge and skills necessary to support the diverse populations of students with whom they will interact. This course addresses theories of human development, spanning early childhood through adolescence, and candidates completing this course will be able to explain and analyze the guiding perspectives on linguistic, physical, cognitive, and social development. This course will also cover appropriate instructional and assessment strategies to support student learning and development. Candidates will engage in four hours of virtual classroom observations related to issues in educational psychology and learner development. Crosscutting themes of technology and diversity are interwoven for further development. "
          },
          "D095": {
            "courseName": "Managing Engaging Learning Environments ",
            "description": "Managing Engaging Learning Environments is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to establish and contribute to safe and productive learning environments that support the success of all learners by ensuring student engagement and motivation for learning. Candidates will learn strategies, such as incorporating consistent routines and expectations, to provide positive behavior supports, increase learner motivation, promote active learning and self-direction, and ensure a safe and productive classroom setting that fosters a sense of community through collaborative educational practices. The course will culminate in evidence-based, practical application of current strategies, theories, or philosophical perspectives related to motivating and engaging all students in a learning community. Candidates will engage in seven hours of preclinical experiences that include both virtual observations of classroom settings and time in a simulated classroom environment where theory can be put into practice. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D096": {
            "courseName": "Fundamentals of Diverse Learners ",
            "description": "Fundamentals of Diverse Learners is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to consider and address the wide range of learning needs in the classrooms of today. This course teaches candidates to identify and support the needs of diverse populations of learners, including, for example, students with disabilities (Including Dyslexia), students who are English language learners, and students who are gifted and talented. Practical strategies for differentiating instruction while creating a safe, inclusive, and culturally responsive learning environment are explored. This course helps candidates develop skills for partnering with parents and advocating for all students, particularly those impacted by provisions of IDEA and Section 504 of the Rehabilitation Act. Multitiered systems of support are addressed to prepare candidates for their future classrooms as they seek to select appropriate instructional practices and interventions to best serve their students. Candidates will engage in four hours of preclinical experiences that include a simulated teaching experience in which skills learned can be applied. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D097": {
            "courseName": "Educational Foundations ",
            "description": "Educational Foundations is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. The course provides candidates with early classroom experience where they observe multiple school settings at three different levels of schooling and interview an educator to learn how state standards and various legal and ethical issues affect classrooms today. The course also provides candidates with opportunities to gain foundational knowledge about what it means to be a teacher in the current educational context while exploring their future role within the larger landscape of historical and cultural influences. This course ensures candidates have a firm grasp on important issues affecting educators including state standards-based curriculum, legal and ethical requirements affecting educational opportunities, and professionalism, preparing them for subsequent coursework within the Professional Core and their content area major courses. Five preclinical hours are interwoven throughout this course, and cross-cutting themes of technology and diversity are introduced for further development throughout the candidate’s programs. "
          },
          "D098": {
            "courseName": "Digital Marketing ",
            "description": "This course provides students with a knowledge of digital marketing and an introduction to specializations within digital marketing. Foundational knowledge in the areas of content marketing, digital advertising, search engine optimization, social media, web development  and analysis, and marketing automation is provided. Students gain a broad overview of digital marketing and an opportunity to explore specific areas of specialization within the field of digital marketing to understand how digital marketing is integrated within a firm’s overall marketing strategy. "
          },
          "D099": {
            "courseName": "Sales Management ",
            "description": "This course provides students with knowledge on the sales profession, customer relationship management, and sales management functions. Students gain insights into the sales process, the relationship between sales and marketing, and the responsibilities of sales management within both business-to-consumer (B2C) and business-to-business (B2B) selling environments. "
          },
          "D100": {
            "courseName": "Introduction to Spreadsheets ",
            "description": "The Introduction to Spreadsheets course will help students become proficient in using spreadsheets to analyze business problems. Students will demonstrate competency in spreadsheet development and analysis for business applications (e.g., using essential spreadsheet functions, formulas, tables, charts, etc.). Introduction to Spreadsheets has no prerequisites. "
          },
          "D101": {
            "courseName": "Cost and Managerial Accounting ",
            "description": "Cost and Managerial Accounting focuses on the concepts and procedures needed to identify, collect, and interpret accounting data for management control and decision-making. Topics covered include budgeting, cost-volume-profit analysis, job costing, process costing, activity-based costing, standard costing, and differential analysis. Prerequisites include Principles of Accounting and Financial Accounting. "
          },
          "D102": {
            "courseName": "Financial Accounting ",
            "description": "Financial Accounting focuses on ways in which accounting principles are used in business operations. Students learn the basics of financial accounting, including how the accounting cycle is used to record business transactions under generally accepted accounting principles (GAAP). Students will also be introduced to the concepts of assets, liabilities, and equity. This course also presents bank reconciliation methods, balance sheets, and business ethics. Principles of Accounting is a prerequisite for this course. "
          },
          "D103": {
            "courseName": "Intermediate Accounting I ",
            "description": "Intermediate Accounting I is the first of three in-depth financial accounting courses for accounting majors. The course builds upon topics covered in Principles of Accounting and Financial Accounting. The course focuses on financial accounting and accounting standards; the conceptual framework of the U.S. generally accepted accounting principles (GAAP); the income statement, the statement of cash flows, and the balance sheet; cash and receivables; and inventory valuation. The prerequisite to this course is Financial Accounting. "
          },
          "D104": {
            "courseName": "Intermediate Accounting II ",
            "description": "Intermediate Accounting II is the second of three in-depth financial accounting courses for accounting majors. The course focuses on acquisition and disposition of noncurrent assets; depreciation, impairments, and depletion; intangible assets; current liabilities and contingencies; long-term obligations; stockholders' equity; dilutive securities; and time value of money concepts. The prerequisite to this course is Intermediate Accounting I. "
          },
          "D105": {
            "courseName": "Intermediate Accounting III ",
            "description": "Intermediate Accounting III provides comprehensive coverage of investments, revenue recognition, accounting for income taxes, pension plans, and leases. This course completes the intermediate accounting journey. The course explores further advanced topics, including accounting changes and error analysis, full disclosure requirements in financial reporting, and interpretation of the statement of cash flows. Intermediate Accounting I and II are the prerequisites for this course. "
          },
          "D115": {
            "courseName": "Advanced Pathophysiology for the Advanced Practice Nurse ",
            "description": "Advanced Pathophysiology for the Advanced Practice Nurse prepares the graduate nursing student for the role of an advanced practice nurse with the competencies and skills needed to recognize disease states, identify disease progression, and assess and evaluate symptoms for patients across the lifespan. This course will help the graduate nursing student gain a deeper understanding of pathophysiology from the cellular to the systems level and will provide graduate nursing students with the knowledge and skills to determine the etiology, underlying physiological changes, and the human affective responses to alterations in health. This course will also prepare the graduate nursing student to communicate the pathophysiology of disease processes to providers and patients. "
          },
          "D116": {
            "courseName": "Advanced Pharmacology for the Advanced Practice Nurse ",
            "description": "Advanced Pharmacology for the Advanced Practice Nurse prepares the graduate nursing student for the role of an advanced practice nurse with the competencies and skills for prescribing and monitoring medication safely and effectively. This course will prepare the graduate nursing student to apply pharmacotherapeutics in primary care settings by utilizing the pivotal basis of pharmacokinetics and pharmacodynamics. This course will also prepare the graduate nursing student to select the correct medication, describe the rationale for that selection to the patient, family, and other providers, and to effectively monitor the patient to promote positive drug outcomes. "
          },
          "D117": {
            "courseName": "Advanced Health Assessment for the Advanced Practice Nurse ",
            "description": "Advanced Health Assessment prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies necessary to provide primary health care to patients and families of diverse populations. Students will develop the skills needed for systematically collecting and analyzing subjective and objective patient data. Through simulation and clinical experiences, students will use data to determine current and ongoing patient health status, predict health risks, and identify health-promoting activities for patients across the lifespan. Advanced Health Assessment will prepare the nursing graduate with the critical thinking, clinical reasoning, and advanced diagnostic skills required for advanced practice nursing. Upon completion of Advanced Health Assessment, the graduate will be able to synthesize individual and systems level subjective and objective data to facilitate the differential diagnosis processes. Also, the graduate will be able to clearly describe to patients and providers the pertinent health assessment findings and rationale supporting the diagnostic process. "
          },
          "D118": {
            "courseName": "Adult Primary Care for the Advanced Practice Nurse ",
            "description": "Adult Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to adult patients and families. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; diagnosing, managing, and coordinating care for patients with acute and chronic conditions; and empowering patients to pursue positive health outcomes. This course will also prepare the graduate nursing student to collaborate with adult patients to develop effective plans of care that build patient self-efficacy in the process of preventing and treating disease. There are no prerequisites for this course. "
          },
          "D119": {
            "courseName": "Pediatric Primary Care for the Advanced Practice Nurse ",
            "description": "Pediatric Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to pediatric patients, from infancy through adolescence, and their families in an outpatient setting. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; in diagnosing, managing, and coordinating care for pediatric patients with acute and chronic conditions; and in empowering patients and their families in pursuing positive health outcomes. This course will also prepare the graduate nursing student to collaborate with pediatric patients and their families in developing effective plans of care that build patient and family self-efficacy in the process of preventing and treating disease. There are no prerequisites for this course. "
          },
          "D120": {
            "courseName": "Special Populations Primary Care for the Advanced Practice Nurse ",
            "description": "Special Populations Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to unique patient populations in the outpatient setting. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; in diagnosing, managing, and coordinating care for patients with specific disease processes; and in empowering patients and their families in pursuing positive health outcomes. This course will also prepare the graduate nursing student to collaborate with unique patient populations and their families in developing effective plans of care that build self-efficacy in the process of preventing and treating specific disease processes. There are no prerequisites for this course. "
          },
          "D121": {
            "courseName": "Health Promotion of Patients and Populations Across the Lifespan ",
            "description": "Health Promotion of Patients and Populations Across the Lifespan prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide health promotion activities to individuals and populations. This course will prepare the graduate nursing student to incorporate individual characteristics, population factors, and social determinants of health (SDOH) in determining the most efficient use of finite resources in leading health promotion activities. This course will also prepare the graduate nursing student to lead health promotion activities for individuals and specific populations across the lifespan. All MSN Core courses, as well as Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse must be completed before taking this course. "
          },
          "D122": {
            "courseName": "Family Nurse Practitioner Clinical Internship I ",
            "description": "Family Nurse Practitioner Clinical Internship I prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups throughout the life span. Using the precepted clinical setting, this course will provide opportunities for the graduate nursing student to combine competencies developed in preparatory advanced practice coursework to deliver patient-centered healthcare. This course will also provide the graduate nursing student with opportunities to conduct advanced health assessments and use the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, determine correct diagnoses, and establish plans of care that include patient and population preferences. All MSN Core courses, as well as Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse and FNP Specialty courses must be completed before taking this course. "
          },
          "D123": {
            "courseName": "Family Nurse Practitioner Clinical Internship II ",
            "description": "Family Nurse Practitioner Clinical Internship II prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups throughout the life span. In the precepted clinical setting, the student will combine competencies developed in preparatory advanced practice coursework to deliver consumer-centered healthcare. The student will conduct advanced health assessments and use the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, determine correct diagnoses, and establish plans of care that include consumer and population preferences. All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse, FNP Specialty courses, and D122 Family Nurse Practitioner Internship I must be completed before taking this course. "
          },
          "D124": {
            "courseName": "Family Nurse Practitioner Clinical Internship III ",
            "description": "Family Nurse Practitioner Clinical Internship III prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups across throughout the lifespan. In the precepted clinical setting, the student will combine competencies developed in preparatory advanced practice coursework to deliver consumer-centered healthcare. Therefore, the graduate will conduct advanced health assessments and utilize the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish plans of care that include consumer and population preferences. All MSN Core courses, FNP Specialty course, and Family Nurse Practitioner Internship I and II must be completed before taking this course. "
          },
          "D125": {
            "courseName": "Mathematics for Elementary Educators I ",
            "description": "Mathematics for Elementary Educators I guides preservice elementary teachers in an investigation of number systems, place value, number theory, and ratio and proportion. This is the first course in a three-course sequence.  There are  no prerequisites for this course. "
          },
          "D126": {
            "courseName": "Mathematics for Elementary Educators II ",
            "description": "Mathematics for Elementary Educators II engages preservice elementary school teachers in mathematical practices of algebraic reasoning. This course explores important algebraic topics such as patterns, expressions and equations, linear equations, inequalities, and functions. This is the second course in a three-course sequence.   "
          },
          "D127": {
            "courseName": "Mathematics for Elementary Educators III ",
            "description": "Mathematics for Elementary Educators III engages preservice elementary teachers in important concepts in geometry, measurement, data analysis and statistics, and probability. This is the third course in a three-course sequence.   "
          },
          "D128": {
            "courseName": "Mathematics for Elementary Educators ",
            "description": "Mathematics for Elementary Educators engages preservice elementary teachers in important concepts in geometry, measurement, data analysis and statistics, and probability. "
          },
          "D146": {
            "courseName": "Teacher Performance Assessment in Elementary Education ",
            "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during a student’s time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of their content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D147": {
            "courseName": "Teacher Performance Assessment in Elementary and Special Education ",
            "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D148": {
            "courseName": "Teacher Performance Assessment in Mathematics Education ",
            "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D149": {
            "courseName": "Teacher Performance Assessment in Special Education ",
            "description": "Teacher Performance Assessment in Special Education course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D150": {
            "courseName": "Teacher Performance Assessment in Science ",
            "description": "Teacher Performance Assessment in Science course is a culmination of the wide variety of skills learned in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D151": {
            "courseName": "Professional Portfolio ",
            "description": "Professional Portfolio requires candidates to create an online teaching portfolio that demonstrates professional beliefs, growth, and effective teaching practices from the Demonstration Teaching experience. The portfolio includes reflective essays (educational beliefs, professional growth, and collaboration with stakeholders) and professional artifacts (resume and artifacts with commentary on academic language, systems of student support, education technology, and professional communication with families) developed and acquired during Demonstration Teaching.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D152": {
            "courseName": "Inclusive Classroom ",
            "description": "Inclusive Classroom introduces and prepares candidates to use a repertoire of evidence-based instructional strategies to advance the learning of elementary students with mild to moderate exceptionalities. The beginning of the course focuses on multitiered systems of support. Strategies for intensifying and individualizing instructional interventions, such as making instructional decisions based on progress monitoring data and collaborating with the special education teacher, are targeted. The second portion of the course provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math and literacy lesson plans based on learner characteristics, performance data, and Individualized Education Program (IEP) goals. This course is designed to be taken by candidates after they have completed D091, Introduction to Curriculum, Instruction and Assessment; C365 Lang. Arts Instruction and Intervention; C909, Elementary Reading Methods and Interventions; and C109, Elementary Mathematics Methods. "
          },
          "D155": {
            "courseName": "Leading with Personal Mastery ",
            "description": "Leading with Personal Mastery prepares the advanced professional nurse to demonstrate self-awareness, self-management, executive function, and social awareness skills while leading and managing in diverse healthcare settings. In this course, students will learn how to incorporate these skills when developing personal relationships and building teams. Developing both social and emotional intelligence as a nurse leader will ensure that students have the ability develop strong relationships and make wise decisions when interacting with others. Increasing personal mastery will provide students with a set of tools and strategies to improve healthcare by producing high-quality results. Understanding their strengths and weaknesses, as a leader in healthcare will help students create a vision for success that includes making choices that will help balance their work life more effectively. "
          },
          "D156": {
            "courseName": "Business Case Analysis for Healthcare Improvement ",
            "description": "Business Case Analysis for Healthcare Improvement provides learning experiences that help students develop essential skills for proposing changes that improve and enhance healthcare outcomes. In this course, students will develop a business case during the early stages of a project by assessing the need for the project and the feasibility of initiating a project. Understanding the techniques used to develop a business case will provide students with the skills to obtain buy-in from key stakeholders and determine the best value strategy. Writing a strong business case presents the benefits, challenges, costs, and risks of moving forward with the project or maintaining status quo. It compares the current situation to a future vision so key stakeholders can make data-driven decisions to move forward with the project. During the development of a business case in this course, students will collaborate with internal and external stakeholders to initiate a healthcare improvement project (HIP) that is grounded in project management principles and influenced by stakeholder perspectives.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D157": {
            "courseName": "Managing Resources in an Era of Disruption ",
            "description": "Managing Human and Financial Resources in an Era of Disruption examines the main premise of people and fiscal leadership. This includes the promotion of healthy work environments through the development of programs in support of mitigating behavior problems for the betterment of work-life balance. Students will analyze business model budgets, revenue streams, and human and financial resource allocation, develop training programs to evaluate compliance and regulatory requirement, and create team building experiences to promote high performing teams by improving engagement, establishing trust, and achieving common goals. Students will assess an organization’s mission, vision, and values to establish alignment between healthcare improvement and an organization’s principles for management. Changes in  healthcare are inevitable, as the business success strategies used in the past are not sufficient for surviving in an era of persistent disruption. This course will help students develop the skills nurse leaders need to become partners in recommending innovative strategies that promote value-based healthcare for the future. "
          },
          "D158": {
            "courseName": "Strategically Planning the Execution of a Healthcare Improvement Project ",
            "description": "Strategically Planning the Execution of a Healthcare Improvement Project will help students develop the skills for systems thinking, problem-solving, and data-driven decision-making. In this course, students will plan the implementation of a healthcare improvement project by identifying people, processes, and procedures that need to be in place for implementation. In addition, sociodemographic data on the population that may be affected by the healthcare improvement project will be analyzed to determine risks and opportunities. During this phase, students will perform an assessment of the forces for and against implementing the project. They will also identify short-term objectives and create action plans to align to the vision, mission, and values of the organization where the project will be implemented. Students will also examine the evolution of existing policies, procedures, and processes at the systems level for the purpose of advocating change that will support a healthcare improvement project. During this course, students will plan the implementation of their healthcare improvement project through the use of sociodemographic and health data, strategic planning, and a comprehensive integration of quality and safety concepts.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D159": {
            "courseName": "Evidence-Based Measures for Evaluating Healthcare Improvements ",
            "description": "Evidence-based measures for evaluating healthcare improvements is an essential component of the planning phase of the healthcare improvement project. In this course, students will determine key performance indicators and metrics used to determine the success of a healthcare improvement project (HIP). The student will develop collaborative partnerships and build consensus with stakeholders to determine how specific data will be collected, managed, and analyzed. This is also an opportunity to discuss data issues and technologies needed for the project. To accomplish this phase, students will also determine the parameters, procedures, and technologies needed for data collection, management, analysis, and reporting.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D160": {
            "courseName": "Nursing Leadership and Management Field Experience ",
            "description": "The Nursing Leadership and Management Field Experience course provides an opportunity for students to apply the knowledge and skills they developed in previous courses toward the successful implementation of their healthcare improvement project (HIP). This phase puts into action all the components of project management that were planned and developed while working collaboratively with key stakeholders to establish the need and feasibility of the HIP, analyzing the organizational readiness for change, and planning the implementation and evaluation phases. In this phase, students will develop and implement a training plan for staff, managers, and leaders. They will also implement the communication plan they developed in a previous course. They will also manage the implementation process by applying organizational standards and practices. Students will demonstrate strong leadership skills when meeting with stakeholders to report the status of the implementation phase and collaboratively problem-solve risks. Completion of the specialty courses is a pre-requisite for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D161": {
            "courseName": "Nursing Leadership and Management Capstone ",
            "description": "The Nursing Leadership and Management Capstone provides students with an opportunity to evaluate and close their capstone project. This is the final course in the MSN Leadership and Management program. Students will evaluate the success of their healthcare improvement project (HIP) by analyzing results, using the key performance indicators and metrics that were identified while planning the evaluation phase. Students will present the results of the improvement project in a final report and presentation with a focus on lessons learned throughout each of the phases: initiation, planning, implementation, and evaluation. Reflective and analytic thinking are essential aspects of a capstone project, as students reflect and report on the successes and challenges encountered in each phase. Nursing Leadership and Management Field Experience is a prerequisite for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D162": {
            "courseName": "Secondary Disciplinary Literacy ",
            "description": "Secondary Disciplinary Literacy examines teaching strategies designed to help learners in middle and high school improve upon the literacy skills required to read, write, and think critically while engaging content in different academic disciplines. Themes include exploring how language structures, text features, vocabulary, and context influence reading comprehension across the curriculum. Course content highlights strategies and tools designed to help teachers assess the reading comprehension and writing proficiency of learners and provides strategies to support student reading and writing success in all curriculum areas. This course has no prerequisites. "
          },
          "D163": {
            "courseName": "Secondary Reading Instruction and Interventions ",
            "description": "Secondary Reading Instruction and Intervention explores the comprehensive, studentcentered Response to Intervention (RTI) assessment and intervention model used to identify and address the needs of learners in middle school and high school who struggle with reading comprehension and/or information retention. Course content provides educators with effective strategies designed to scaffold instruction and help learners develop increased skill in the following areas: reading, vocabulary, text structures and genres, and logical reasoning related to the academic disciplines. This course has no prerequisites. "
          },
          "D164": {
            "courseName": "Elementary Disciplinary Literacy ",
            "description": "Elementary Disciplinary Literacy examines teaching strategies designed to help learners in grades K–6 develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help learners distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity are also addressed. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR  Instructional Planning and Presentation in Elementary Education. "
          },
          "D165": {
            "courseName": "Children’s Literature ",
            "description": "Children's Literature is an introduction to and exploration of children's literature. Students will consider and analyze children's literature as a lens through which to view the world. Students will experience multiple genres, historical perspectives, cultural representations, and current applications in the field of children's literature. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
          },
          "D166": {
            "courseName": "Foundations of Education ",
            "description": "Foundations of Education is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to foundational knowledge about the teaching profession in the current educational context and the historical and cultural influences on P-12 education in the United States. This course addresses important topics that affect educators today including state standards-based curriculum, legal and ethical requirements, and professionalism. This course will culminate in evidence-based, practical application of current strategies, theories, or philosophical perspectives related to becoming an effective educator within the current school context. Candidates will engage in five hours of preclinical experiences, which include virtual observations of learning environments in multiple school settings, and an interview with an educator to gain insight on how these topics affect and inform teaching practice. Cross-cutting themes of technology and diversity are introduced for further development throughout the candidate’s programs. "
          },
          "D168": {
            "courseName": "Schools as Communities of Care ",
            "description": "Schools as Communities of Care is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to strategies for providing a culturally inclusive learning environment that meets the social and emotional needs of learners while taking into account theories and philosophical perspectives on child and adolescent development and learning. Emphasis is placed on fostering a collaborative relationship with families, caregivers, and community stakeholders, and on leveraging community resources to support each learner’s growth and well-being to build a strong foundation for their academic and personal success. Topics addressed include culturally responsive practice, social and emotional learning (SEL), youth mental health, substance abuse, suicide awareness and prevention, abuse within families, and professional responsibilities to ensure student wellbeing. The course will culminate in evidence-based, practical application of strategies that support the whole child in a community of care. Candidates will engage in seven hours of preclinical experiences, include virtual observations of learning environments that involve parents and families in their children's education and an interview with an educational professional. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D169": {
            "courseName": "Essential Practices for Supporting Diverse Learners ",
            "description": "Fundamentals of Diverse Learners is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to consider and address the wide range of learning needs in the classrooms of today. This course teaches candidates to identify and support the needs of diverse populations of learners, including, for example, students with disabilities (Including Dyslexia), students who are English language learners, and students who are gifted and talented. Practical strategies for differentiating instruction while creating a safe, inclusive, and culturally responsive learning environment are explored. This course helps candidates develop skills for partnering with parents and advocating for all students, particularly those impacted by provisions of IDEA and Section 504 of the Rehabilitation Act. Multitiered systems of support are addressed to prepare candidates for their future classrooms as they seek to select appropriate instructional practices and interventions to best serve their students. Candidates will engage in four hours of preclinical experiences that include a simulated teaching experience in which skills learned can be applied. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D170": {
            "courseName": "Creating and Managing Engaging Learning Environments ",
            "description": "Creating and Managing Engaging Learning Environments is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with researchbased strategies and approaches to establishing and maintaining a safe and productive learning environment that supports the success and wellbeing of all P-12 learners. Topics addressed include consistent routines and expectations, student engagement, positive behavior support, motivation and its effect on student achievement, active learning and self-direction, and fostering a sense of community through collaboration. Candidates will design a classroom management plan for their future classroom based on theory and high-leverage practices for meeting the diverse needs of learners in a productive and collaborative learning environment. The course will culminate in evidence-based, practical application of current strategies to motivate and engage students in specific content areas. Candidates will engage in seven hours of preclinical experiences that include both virtual observations of classroom settings and time in a simulated classroom environment where theory can be put into practice. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D171": {
            "courseName": "Curriculum, Instruction, and Assessment ",
            "description": "Curriculum, Instruction, & Assessment is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with theoretical foundations and strategies for creating engaging and standards-aligned lessons that meet the needs of all learners in the P-12 classroom. This course focuses on the interrelationship between curriculum, instruction, and assessment, with emphasis on the role of assessment and student data in planning, designing, delivering, and modifying instruction in accordance with diverse learner needs. This course will culminate in the application of evidence-based strategies related to the interdependence of and alignment among curriculum, instruction, and assessment in student-centered P-12 teaching and learning. Candidates will engage in three hours of preclinical experiences, which include conducting virtual classroom observations and recording a short teaching segment. Cross-cutting themes of technology and diversity are interwoven for continued development. "
          },
          "D172": {
            "courseName": "Assessing Student Learning ",
            "description": "Assessing Student Learning is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with methods and best practices for using assessment to monitor student progress and to evaluate the effectiveness of instruction. This course focuses on implementing a balanced approach to assessment using multiple assessment types such as formative, summative, standardized, and common assessments. Also covered are data literacy skills for interpreting and analyzing individual learner and classroom data to improve instruction and support academic success for all learners. The course will culminate in evidence-based, practical application of strategies for assessment practices in P-12 schools. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D173": {
            "courseName": "Using Educational Technology for Teaching and Learning ",
            "description": "Using Educational Technology for Teaching and Learning is a key component of Whalley's professional core and is a required course for all Master of Arts in Teaching candidates. This course presents strategies for integrating technology into classroom practices to improve instruction and student learning according to the International Society for Technology in Education (ISTE) standards. Candidates will evaluate digital tools and their potential classroom applications such as enhancing curriculum, enabling communication with students and families, and increasing student engagement. Topics covered include ethics, equity and access to technology, and appropriate use of technology by P–12 students. Assistive technologies to meet the needs of a diverse learner population also will be addressed. The course will culminate in evidence-based, practical application of current standards, strategies, theories, or philosophical perspectives related to the use of technology in teaching and learning. Candidates will engage in three hours of preclinical experience that include virtual observations of classroom practices incorporating technology to support educational goals. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D174": {
            "courseName": "Marketing Management ",
            "description": "Marketing Management examines foundational marketing concepts. Marketing is ever-present in our daily lives and this course will help students understand how organizations use marketing activities to create value for their customers. Students will study the strategic marketing planning process and the marketing mix of product, price, place, and promotion. Students will gain knowledge about the market research process and how data are used to inform marketing decisions. Emphasis will be placed on ethical and sustainable marketing practices, along with a focus on service marketing in today’s service economy. This course will provide students with a basic marketing understanding to prepare them for specialized major courses. "
          },
          "D175": {
            "courseName": "Consumer Behavior ",
            "description": "Consumer Behavior examines the buying behavior of consumers in the marketplace. Students will gain knowledge of consumer behavior theories and an understanding of how consumer behavior concepts apply to the consumer decision-making process. Students will learn how consumer insights are gained through the exploration of external social and cultural influences such as reference groups, family, and culture, as well as consumer influences such as needs, motivation, personality, and learning. The course also provides an interdisciplinary perspective, including psychology, sociology, anthropology, and economics, to better evaluate and predict consumer behavior. "
          },
          "D176": {
            "courseName": "Content Marketing ",
            "description": "Content Marketing examines how organizations create and distribute marketing communications to attract and retain customers. Students will gain knowledge of the content planning process and how content marketing supports brand and organizational goals by learning how to create, distribute, promote, and measure relevant and valuable content. Students will learn content ideation and will write compelling copy that creates relationships with customers to build trust and enhance an organization’s reputation and authority. "
          },
          "D177": {
            "courseName": "Brand Management ",
            "description": "Brand Management examines how brands provide value to both consumers and organizations. Brands are a part of a consumer’s everyday life and organization’s strategically plan, measure, and manage brands. In this course, students will apply the strategic brand management process using a customer-based brand equity model. Students will identify how brand strategies are used and how brand associations are leveraged to create a competitive advantage. Brand equity measurement systems are explored, including brand audits and tracking studies that use qualitative and quantitative brand research techniques. Students will construct a brand architecture strategy by identifying brand extension opportunities to develop an appropriate branding strategy in a global marketplace. Reputation-management strategies and crisis management techniques are also taught to assist in preserving and protecting an organization’s brand equity. "
          },
          "D178": {
            "courseName": "Marketing Strategy and Analytics ",
            "description": "Marketing Strategy and Analytics is the capstone course for the marketing major. The course provides students with the opportunity to demonstrate competencies developed throughout the program by engaging in the design, implementation, and analysis of a marketing strategy. Students are given business scenarios using simulations and case studies to apply critical-thinking and decisionmaking skills. Students will analyze the business environment and make decisions about market segmentation, buyer behavior, and the marketing mix. Students will demonstrate the relationship between strategy and analytics by using marketing analytics to report marketing campaign results and make recommendations. This course provides students with real-world application to prepare them for the marketing industry. "
          },
          "D179": {
            "courseName": "Data-Informed Practices ",
            "description": "Data-Informed Practices focuses on the development of data literacy skills. This course teaches candidates about the different types of data, the benefits and limitations of those data types, and how they can use data to identify and solve problems and inform decisions. The course also teaches candidates how to locate, collect, and analyze data from relevant and credible sources, and how to draw conclusions from data in order to drive continuous improvement. There are no prerequisites for this course. "
          },
          "D180": {
            "courseName": "Educational Research ",
            "description": "Educational Research focuses on practical problem solving. This course teaches candidates to use scholarly literature and current research to inform their own practice. It also empowers candidates to recognize opportunities for improvement and engage in action research to systematically implement and evaluate changes. This course prepares candidates to conduct research for the capstone. DataInformed Practices is a prerequisite for this course. "
          },
          "D181": {
            "courseName": "MSCIN Capstone ",
            "description": "The Master of Science in Curriculum and Instruction Capstone is the culminating course of the degree. It unites content area knowledge with the completion of a research project or study. This course teaches candidates, under the guidance of program faculty, to apply their data literacy and research skills to topics related to curriculum and instruction and to their career goals. Projects for this course include action research or applied research through the necessary qualitative, quantitative, or mixed research methods. Prerequisites for this course include DataInformed Practices and Educational Research, as well as all prescribed courses in the candidates' area of study. Additionally, students wishing to add the Capstone with fewer than eight weeks remaining in the term must receive permission from the Program Chair. "
          },
          "D182": {
            "courseName": "The Reflective Practitioner ",
            "description": "The Reflective Practitioner defines what reflective teaching is and how accomplished teachers reflect meaningfully on their pedagogical choices to improve their practice. During this course, candidates will examine their teaching to determine how they can more effectively plan, facilitate, and evaluate learning. Candidates will also develop a professional growth plan and incorporate evidence-based practices that support the achievement of their professional goals. There are no prerequisites for this course. "
          },
          "D183": {
            "courseName": "Designing Curriculum and Instruction I ",
            "description": "Designing Curriculum and Instruction I examines the influence that specific theories, design principles, and evaluation models have on the quality and effectiveness of a curriculum. During the course, candidates will conduct a needs analysis in order to determine the content that students need. The course requires candidates to learn how to define the scope and sequence of a curriculum to ensure vertical and horizontal alignment. This course will also teach how to map curriculum to address any gaps or unnecessary duplication within and across grade levels. There are no prerequisites for this course. "
          },
          "D184": {
            "courseName": "Standards-Based Assessment ",
            "description": "Standards-Based Assessment teaches candidates how to unpack academic standards to determine the essential learnings within the standards that should be assessed. This course teaches candidates how to determine, based on academic standards, which topics should be assessed and how to use proficiency statements to create and score standards-based assessments. This course also prepares candidates to analyze assessment data and develop a holistic assessment system for a specific subject and grade level. Differentiated Instruction is a prerequisite for this course. "
          },
          "D185": {
            "courseName": "Designing Curriculum and Instruction II ",
            "description": "Designing Curriculum and Instruction II examines commonly used curriculum and instructional models and demonstrates how they can be used during the design process to achieve curricular and instructional goals. This course demonstrates how to design curriculum and instruction that leverages digital tools to facilitate deep, authentic learning and provides strategies for ensuring successful curriculum implementation. Designing Curriculum and Instruction I is a prerequisite for this course. "
          },
          "D186": {
            "courseName": "Learning as a Science ",
            "description": "Learning as a Science examines how research from the field of learning sciences can be applied to improve teaching and learning. This course explains how teachers can create a sense of community by examining personal biases and establishing a culturally inclusive learning environment. The course also provides evidence-based strategies for improving motivation, increasing understanding and retention, and teaching social-emotional skills that students need to be successful socially and academically. There are no prerequisites for this course. "
          },
          "D187": {
            "courseName": "Differentiated Instruction ",
            "description": "Differentiated Instruction examines how the classroom environment and students’ readiness levels, interests, and learning profiles influence learning. K–12 educators taking this course will acquire a deep understanding of their students in order to differentiate their curriculum, instruction, and assessments in response to individual students’ needs. This course will allow students to also learn how to effectively monitor and communicate students’ progress toward standards and adjust their practice as needed to empower students and nurture their abilities and aptitudes. As a result of their learning in this course, K–12 teachers will be prepared to act as catalysts for differentiation within their schools and districts. There are no prerequisites for this course. "
          },
          "D188": {
            "courseName": "The Collaborative Leader ",
            "description": "The Collaborative Leader demonstrates strategies teacher leaders can use to collaborate with other professionals, families, and communities to build strong relationships and improve school effectiveness. This course examines models of collaboration and the benefits and challenges of collaboration. It also examines the characteristics of effective professional development and explains how to collaboratively design effective professional development opportunities for educators. Finally, this course demonstrates how accomplished teachers can build relationships with families and the community to create a positive learning experience for students. There are no prerequisites for this course. "
          },
          "D190": {
            "courseName": "Introduction to Healthcare IT Systems ",
            "description": "Introduction to Healthcare IT Systems introduces students to healthcare information technology as a discipline. Focusing on evaluating health information systems and collecting data, students will learn the various roles and functions of the health information manager in supporting the business of healthcare. This course introduces students to information technology as a discipline. This course also exposes students to the various roles and functions of the health information manager in supporting the business of healthcare. Students will learn through e-text readings, videos, case studies, several modules from LinkedIn Learning, knowledge checks, and unit quizzes. There are no prerequisites for this course. "
          },
          "D196": {
            "courseName": "Principles of Financial and Managerial Accounting ",
            "description": "Principles of Financial and Managerial Accounting provides students with an introduction to the discipline of accounting and its context within the business environment. In this course, students will learn to differentiate between financial, cost, and managerial accounting and where these accounting types fit into the business environment. This course will help students gain a fundamental knowledge of the budgeting process, how to analyze basic financial statements, and how to use spreadsheets to analyze data. This course provides students with a business generalist overview of the field of accounting and acts as a preview course for the accounting major. "
          },
          "D197": {
            "courseName": "Version Control ",
            "description": "Version control is critical to maintaining software and enabling scalability solutions. A best practice for any programming project that requires multiple files uses version control. Version control enables teams to have collaborative workflows and enhances the software development lifecycle. This course introduces students to the basics of publishing, retrieving, branching, and cloning. There are no prerequisites for this course. "
          },
          "D198": {
            "courseName": "Global Arts and Humanities ",
            "description": "This is a Global Arts and Humanities course that contains three modules with corresponding lessons. This course is an invitation to see the world through the humanities, examine the humanities during the Information Age, and explore the global origins of music—essentially questioning what makes us human, and how people are connected across culture and time. Each module includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check learning. With no prior knowledge or experience, a learner can expect to spend 30-40 hours on the course content. "
          },
          "D199": {
            "courseName": "Introduction to Physical and Human Geography ",
            "description": "This is Introduction to Physical and Human Geography, a three-module course that addresses the question of what geography really is in today's complex world; how migration affects—and has been affected by—geography; and one of the biggest present problems related to geography: climate change. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30–40 hours on the course content. "
          },
          "D202": {
            "courseName": "Human Growth and Development ",
            "description": "This is Human Growth and Development, a three-module course that examines the entire human lifetime, from conception to death. Presented chronologically, the course focuses on three key areas: physical, cognitive, and psychosocial growth, along with other important issues such as cultural influences, emotions, and resilience. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content. "
          },
          "D203": {
            "courseName": "Fundamentals of Anatomy and Physiology ",
            "description": "Fundamentals of Anatomy and Physiology provides an overview of the structures and functions of organs and systems of the human body. This course will explore how the parts of the body systems work together to produce movement, transport nutrients, eliminate wastes, protect vital tissues and organs, regulate bodily functions, and support reproduction and growth, through videos, readings, exploratory learning, and practice activities. "
          },
          "D204": {
            "courseName": "The Data Analytics Journey ",
            "description": "The Data Analytics Journey gives an overview of the entire analytics life cycle. Learners gain fluency in data analytics terminology, tools, and techniques. The course contextualizes the data analytics journey firmly with organizational metrics and requirements to position graduates to answer key questions for businesses and other employers. This course has no prerequisites. "
          },
          "D205": {
            "courseName": "Data Acquisition ",
            "description": "Data Acquisition builds proficiency in Structured Query Language (SQL) and the initial stages of the data analytics lifecycle. The course introduces relational databases. Students gain concrete skills in data transference and database manipulation. There are no prerequisites. "
          },
          "D206": {
            "courseName": "Data Cleaning ",
            "description": "Data Cleaning continues building proficiency in the data analytics life cycle with data preparation skills. This course addresses exploring, transforming, and imputing data as well as handling outliers. Learners write code to manipulate, structure, and clean data as well as to reduce features in data sets. The following courses are prerequisites: The Data Analytics Journey, and Data Acquisition. "
          },
          "D207": {
            "courseName": "Exploratory Data Analysis ",
            "description": "Exploratory Data Analysis covers statistical principles supporting the data analytics life cycle. Students in this course compute and interpret measures of central tendency, correlations, and variation. The course introduces hypothesis testing, focusing on application for parametric tests, and addresses communication skills and tools to explain an analyst’s findings to others within an organization. Data Cleaning is a required prerequisite for this course. "
          },
          "D208": {
            "courseName": "Predictive Modeling ",
            "description": "Predictive Modeling builds on initial data preparation, cleaning, and analysis, enabling students to make assertions vital to organizational needs. In this course, students conduct logistic regression and multiple regression to model the phenomena revealed by data. The course covers normality, homoscedasticity, and significance, preparing students to communicate findings and the limitations of those findings accurately to organizational leaders. Exploratory Data Analysis is a prerequisite for this course. "
          },
          "D209": {
            "courseName": "Data Mining I ",
            "description": "Data Mining I expands predictive modeling into nonlinear dimensions, enhancing the capabilities and effectiveness of the data analytics lifecycle. In this course, learners implement supervised models—specifically classification and prediction data mining models—to unearth relationships among variables that are not apparent with more surface-level techniques. The course provides frameworks for assessing models’ sensitivity and specificity. D208 Predictive Modeling is a prerequisite to this course. "
          },
          "D210": {
            "courseName": "Representation and Reporting ",
            "description": "Representation and Reporting focuses on communicating observations and patterns to diverse stakeholders, a key aspect of the data analytics life cycle. This course helps students gain communication and storytelling skills. It also covers data visualizations, audio representations, and interactive dashboards. The prerequisite for this course is Data Mining I. "
          },
          "D211": {
            "courseName": "Advanced Data Acquisition ",
            "description": "Advanced Data Acquisition enhances theoretical and SQL skills in furthering the data analytics life cycle. This  course covers advanced SQL operations, aggregating data, and acquiring data from various sources in support of core organizational needs. The  prerequisite for this course is Representation and Reporting. "
          },
          "D212": {
            "courseName": "Data Mining II ",
            "description": "Data Mining II adds vital tools to data analytics arsenal that incorporates unsupervised models. This course explains when,  how, and why to use these tools to best meet organizational needs. The prerequisite for this course is Advanced Data Acquisition. "
          },
          "D213": {
            "courseName": "Advanced Data Analytics ",
            "description": "Advanced Data Analytics prepares students for career-long growth in steadily advancing tools and techniques and provides emerging concepts in data analysis. This course hones the mental and theoretical flexibility that will be required of analysts in the coming decades while grounding their approach firmly in ethical and organizational-need-focused practice. Topics include machine learning, neural networks, randomness, and unconventional data sources. Data Mining II is a prerequisite for this course. "
          },
          "D214": {
            "courseName": "Data Analytics Graduate Capstone ",
            "description": "The Data Analytics Graduate Capstone allows students to apply the academic and professional abilities developed as a graduate student. This capstone challenges students to integrate skills and knowledge from several program domains into one project. Advanced Data Analytics is a prerequisite for this course. "
          },
          "D215": {
            "courseName": "Auditing ",
            "description": "Auditing covers the entire auditing process. This course will help students gain an understanding of the different assurance services, the AICPA Code of Professional Conduct, and the conceptual framework for members in public practice. The course will teach students how to assess for audit risk, develop an audit strategy, and gain an understanding of the audit client. Audit evidence and a client’s system of internal control will be discussed in depth. The course requires students to assess risk response by identifying and evaluating tests of controls and substantive procedures. In addition, the course will have students evaluate risk response using data analytics and audit sampling for substantive tests. The course concludes with the completion of the audit through subsequent events, engagement wrap-up and management representation, and reporting on the audit with an unqualified audit report or a modification of the audit report. The prerequisites to this course are Intermediate Accounting I, II, and III, Accounting Information Systems, and Business Law for Accountants. "
          },
          "D216": {
            "courseName": "Business Law for Accountants ",
            "description": "Business Law for Accountants is designed to provide the advanced accounting student an understanding of the legal environment and issues encountered in the profession. Topics include the Uniform Commercial Code (UCC), contracts, securities regulation, Sarbanes-Oxley Act, legal entities, ethics, agency, and bankruptcy. There are no prerequisites for the course. "
          },
          "D217": {
            "courseName": "Accounting Information Systems ",
            "description": "Accounting Information Systems (AIS for short) introduces students to AIS, with particular emphasis on the accountant’s role in management and financial reporting systems. Topics include transaction cycles and related information technology (IT) controls, data management, enterprise resource planning (ERP) and e-commerce systems, systems development and acquisition, documentation, and IT auditing. D103 Intermediate Accounting I and D104 Intermediate Accounting II are the prerequisites to this course. "
          },
          "D218": {
            "courseName": "Intrapersonal Leadership and Professional Growth ",
            "description": "Intrapersonal Leadership and Professional Growth fosters the development of professional identity. Building on the knowledge, skills, and attitudes gained through nursing practice, students in this course will explore the relationship of theories, professional competencies, standards of leadership, education, and professionalism. The course content will cover development of a nurse as a leader who is proficient in asserting control, influence, and power in professional and personal contexts. "
          },
          "D219": {
            "courseName": "Scholarship in Nursing Practice ",
            "description": "Scholarship in Nursing Practice teaches students how to design and conduct research to answer important questions about improving nursing practice and patient care delivery outcomes. This course introduces the basics of evidence-based practice, which students are expected to implement throughout their clinical experiences. Students of this course will graduate with more competence and confidence to become leaders in the healing environment. "
          },
          "D220": {
            "courseName": "Information Technology in Nursing Practice ",
            "description": "Information Technology in Nursing Practice provides a basic overview of information technology as it relates to the baccalaureate-prepared nurse. It is a foundational overview of nursing informatics with an emphasis on developing basic competency. This course teaches students that nursing informatics synthesizes nursing science, information science, and computer science through health applications to support decision-making in a dynamic healthcare environment. All prior courses in the sequence for this program serve as prerequisites for this course. "
          },
          "D221": {
            "courseName": "Organizational Systems and Healthcare Transformation ",
            "description": "Course Description Organizational Systems and Healthcare Transformation  covers foundational knowledge, skills, and attitudes toward organizational leadership within healthcare systems that can help students be successful. This course focuses on the concepts of patient safety, improvement science, fiscal responsiveness, quality of care, value-based care, and patientcentered care. Additional topics of quality science and innovation, systems redesign, and interprofessional roles assist the student in building necessary skills for healthcare transformation. All prior courses in the sequence for this program serve as prerequisites for this course. "
          },
          "D222": {
            "courseName": "Comprehensive Health Assessment ",
            "description": "Comprehensive Health Assessment builds upon students’ existing knowledge of nursing assessment. The course presents current and innovative assessment techniques of the physical, mental, emotional, and spiritual well-being of patients. Use of assessment data and shared decision-making are discussed throughout the course. This course also outlines the concepts of a head-to-toe assessment, providing students with an understanding of how to critically think about the different aspects of the assessment and analyze patient cues to determine the implications of findings. Students will also analyze lifestyle and cultural implications of health. All prior courses in the sequence for this program serve as prerequisites for this course. "
          },
          "D223": {
            "courseName": "Healthcare Policy and Economics ",
            "description": "Healthcare Policy and Economics is a foundational course that introduces the concepts of value-based care and the role of the nurse. This course includes concepts related to financial responsiveness, shared decision-making, preference-sensitive care, leveraging data. In this course, students learn about cost and fee-for-service in terms of value to the client and patient rather than value to the healthcare system. All prior courses in the sequence for this program serve as prerequisites for this course. "
          },
          "D224": {
            "courseName": "Global and Population Health ",
            "description": "Global and Population Health prepares students for the role of the nurse in preserving and promoting health among diverse populations. Additionally, basic principles of epidemiology, social determinants of health (SDOH), and resource allocation through value-based care are outlined. The course introduces planning, organization, and delivery of services for diverse populations in community settings, including illness prevention, disaster preparedness, and environmental health. All prior courses in the sequence for this program serve as prerequisites for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D225": {
            "courseName": "Emerging Professional Practice ",
            "description": "Emerging Professional Practice presents a variety of professional nursing specialty areas. Students explore various practice specialties, including palliative care, genetics and genomics, and others. The course provides pathways to specialized nursing practice. All prior courses in the sequence for this program serve as prerequisites for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D228": {
            "courseName": "Special Education Practices: Professional, Ethical and Legal Guidelines ",
            "description": "Special Education Practices: Professional, Ethical and Legal Guidelines prepares candidates to apply practice within ethical and legal guidelines in day-to-day teaching, stakeholder interactions, and other complex situations. This course provides an overview of the professional ethics and standards from the Council for Exceptional Children (CEC), which guide candidates to act in a professionally conscientious manner. This course also explores the transition planning requirements in IDEA, which include development of an individualized transition plan and ensures that planning is initiated in elementary (such as from K to elementary), middle school and continued through high school and post-secondary education. Candidates will explore the legal foundations and case laws related to special education to gain understanding of how legislation influences teaching and learning. Candidates will advocate for improved outcomes for students with exceptionalities and their families while addressing the unique needs of those with diverse social, cultural, and linguistic backgrounds. Candidates will engage in three hours of preclinical experiences, which include an interview with a special educator to gain insight on how these topics affect and inform teaching practice. This course is designed to be taken after successful completion of Essential Practices for Supporting Diverse Learners. "
          },
          "D229": {
            "courseName": "Management Strategies for Academic and Social Behavior ",
            "description": "Management Strategies for Academic and Social Behavior prepares candidates to work effectively with students exhibiting behavior in the classroom that is below age and cultural norms. This course provides an overview of behavior disorders and their causes, and appropriate research-based intervention strategies, including positive behavior intervention and supports, multitiered systems of support (MTSS), applied behavior analysis, replacement behavior and reward strategies, culturally responsive practices, and data collection and assessment methods. Candidates emerge prepared to strategize and recommend adjustments to the learning environment that support positive behavior and student success in the classroom and beyond. This course also examines behavioral assessment and analysis, including the creation of a functional behavior assessment (FBA) and the creation and monitoring of behavioral improvement plans (BIPs) in an authentic learning environment. The candidates will determine effective strategies to promote active student engagement, increase student motivation and opportunities to respond, and enhance self-regulation of student learning. This course is designed to be taken after successful completion of Creating and Managing Engaging Learning Environments. "
          },
          "D230": {
            "courseName": "Assessment and Evaluation Procedures in Special Education ",
            "description": "Assessment and Evaluation Procedures in Special Education prepares candidates to use multiple methods of assessment and data sources in making educational decisions about the student and the learning environment. This course is designed to help provide an understanding of how assessment data is used during screening in multitiered systems of support (MTSS), the eligibility process, the evaluation process, progress monitoring, and data-based instructional decision making. Candidates analyze informal assessments to determine how students access and demonstrate knowledge in the core curriculum. This course is designed to be taken by candidates after they have completed Special Education Practices: Professional, Ethical, and Legal Guidelines. "
          },
          "D231": {
            "courseName": "Collaborative Techniques with Partners for Effective IEPs ",
            "description": "Collaborative Techniques with Partners for Effective IEPs prepares candidates to apply team processes and communication strategies to collaborate in a culturally responsive manner with families, paraeducators, and other professionals (within the school, other educational settings, and the community) to plan programs and access services for students with exceptionalities and their families. The course introduces ways to enhance parental involvement and family engagement while teaching families and students advocacy throughout the Individualized Education Program (IEP) and transition planning processes. Candidates will develop plans for transition services that focus on a coordinated set of student-centered activities designed to facilitate the student's movement from school to postschool activities, including post-secondary education. This course also focuses on the components of the IEP and how the practice of effective communication and collaboration skills is key to the program's development and implementation. The candidates will actively seek information from and about families and take primary responsibility for maintaining respectful, ongoing, open communication to jointly identify and meet learning goals that are informed by assessment data. Candidates will engage in three hours of preclinical experiences that includes a simulated collaborative experience in which skills learned can be applied. This course is designed to be taken after successful completion of Special Education Practices: Professional, Ethical and Legal Guidelines. "
          },
          "D232": {
            "courseName": "Special Education Methods of Instruction and Intervention ",
            "description": "Special Education Methods of Instruction and Intervention introduces candidates to a repertoire of evidence-based instructional strategies to advance the learning of students with exceptionalities. The course focuses specifically on strategies for intensifying and individualizing instructional interventions; making instructional decisions based on progress-monitoring data; collaborating with general education teachers and paraeducators; teaching to mastery; promoting generalization of learning; and teaching students with exceptionalities how to use self-assessment, problem solving, and other cognitive strategies to organize critical content and meet their needs. This course will also focus on the interrelationship between curriculum, instruction, and assessment, with emphasis on the role of assessment and student data in planning, designing, delivering, and modifying instruction in accordance with diverse learner needs. Candidates will know and understand how learning occurs, how students construct knowledge, acquire skills, and develop disciplined thinking processes. This course is designed to be taken after successful completion of Curriculum, Instruction, and Assessment, Mathematics Methods and Instruction for Students with Mild/Moderate Disabilities, and Language Arts Instruction and Interventions. "
          },
          "D233": {
            "courseName": "Designing Instruction for Elementary Learners with Mild to Moderate Exceptionalities ",
            "description": "Designing Instruction for Elementary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for elementary students receiving special education services. The course includes cognitive and metacognitive strategies that elementary students can use to acquire new content knowledge and generalize skills across learning environments. It also provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math, reading, and language arts lesson plans based on learner characteristics, performance data, and individualized education program (IEP) goals. In addition to discussing how to make appropriate accommodations, the course teaches candidates how to assess student learning through progress monitoring and apply intensive interventions when warranted. Candidates apply their understanding of academic subject content specifically focusing on reading, writing, and math curricula of the general curriculum to inform instructional decisions for individual with exceptionalities. Candidates design appropriate learning and performance accommodations and modifications for individuals with exceptional learning needs in academic subject matter content of the general curriculum curricula. This course is designed to be taken after successful completion of Special Education Methods of Instruction and Intervention. "
          },
          "D234": {
            "courseName": "Designing Instruction for Secondary Learners with Mild to Moderate Exceptionalities ",
            "description": "Designing Instruction for Secondary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for use with secondary students receiving special education services. Strategies taught in this course focus on intensive instruction and making accommodations to secondary lesson plans in order to develop critical thinking and problem-solving skills to enhance acquisition of age-appropriate secondary content across academic disciplines in math, reading and English/language arts. This course also promotes the achievement of Individualized Education Program (IEP) and transition goals for independent living, post-secondary education and career preparation through demonstration of strategies that increase students' self-awareness, self-regulation, self-management, self-control, and self-esteem. Because of the significant role that content specific subject matter knowledge plays at the secondary level, candidates will demonstrate a solid understanding of the subject matter content specifically focusing on math, reading, English/language arts to sufficiently assure that students with exceptionalities can meet state curriculum standards. Candidates design appropriate learning and performance accommodations and modifications for individuals with exceptional learning needs in academic subject matter content of the general curriculum. This course is designed to be taken after successful completion of Curriculum, Instruction, and Assessment. "
          },
          "D235": {
            "courseName": "Interprofessional Communication and Leadership in Healthcare ",
            "description": "Interprofessional Communication and Leadership in Healthcare is designed to help students prepare for success in the online environment at Whalley University and beyond. Student success starts with the social support and self-reflective awareness that will prepare them to handle the challenges of all academic programs. In this course, students will complete several individual assignments that are intended to give the student an opportunity to reflect on where they are and where they would like to be. The activities in the course are designed to give students several tools they can use to achieve success. This course is designed as a four-part intensive learning experience. Students will engage in activities that will help them understand their own educational journey and find support and inspiration in the journey of others. There are no prerequisites for this course. "
          },
          "D236": {
            "courseName": "Pathophysiology ",
            "description": "Pathophysiology is an overview of the pathology and treatment of diseases in the human body, tissues, glands and membranes, the integumentary system, the sensory system, skeletal and muscular systems, the digestive system, blood, vessels and circulation, lymphatic system, immunity and disease, heart and respiratory system, nervous, urinary and endocrine systems, and male and female reproductive systems. Prerequisites include all prior courses in this programmatic sequence. "
          },
          "D237": {
            "courseName": "Mathematics Methods and Instruction for Students with Mild/Moderate Exceptionalities ",
            "description": "Mathematics Methods and Instruction for Students with Mild/Moderate Exceptionalities helps candidates learn how to implement effective math instruction in today’s diverse classrooms in both the elementary and secondary settings. Topics include differentiated math instruction, mathematical communication, mathematical tools for instruction, assessing math understanding, integrating math across the curriculum, critical thinking development, standards-based mathematics instruction, and mathematical models and representation for students with mild to moderate exceptionalities. "
          },
          "D238": {
            "courseName": "Preclinical Experiences in Special Education ",
            "description": "Pre-Clinical Experiences in Special Education provides candidates the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Candidates will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, candidates will be required to include a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
          },
          "D243": {
            "courseName": "Teacher Performance Assessment in Special Education ",
            "description": "Teacher Performance Assessment in Special Education course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D244": {
            "courseName": "Disciplinary Literacy ",
            "description": "Disciplinary Literacy examines teaching strategies designed to help candidates to develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help candidates distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and  Presentation AND Instructional Planning and Presentation in Special Education. "
          },
          "D245": {
            "courseName": "Cohort Seminar in Special Education ",
            "description": "Cohort Seminar in Special Education provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
          },
          "D246": {
            "courseName": "Influential Communication through Visual Design and Storytelling ",
            "description": "Influential Communication through Visual Design and Storytelling provides learners with foundational visual design and storytelling techniques to influence and create a lasting impression on audiences. Learners will first explore how human behavior is influenced by visuals and when to apply visual techniques to better communicate with audiences. Next, learners will learn techniques for creating compelling stories that create memorable images within the audience's mind. Ultimately, learners who master these skills will be well-positioned to apply their visual and storytelling techniques to not only better communicate their thoughts and ideas to an audience, but to also influence or motivate them.   "
          },
          "D250": {
            "courseName": "Governmental and Nonprofit Accounting ",
            "description": "Governmental and Nonprofit Accounting provides learners with the skills and knowledge required to practice accounting for governmental and nonprofit entities: analyzing and recording transactions, financial statement preparation in accordance with Governmental Accounting Standards Board (GASB) standards, and communication. "
          },
          "D251": {
            "courseName": "Advanced Auditing ",
            "description": "Advanced Auditing reviews basic auditing concepts, including (1) planning the audit: identifying, assessing, and    responding to the risk of material misstatement; (2) specialized audit tools: attributes sampling, monetary unit sampling, and data analytic tools; (3) completing a quality audit; and (4) reporting on financial statement audits. The second part of the course dives into an application of auditing through (1) understanding how to audit an acquisition and payment cycle and (2) applying the knowledge learned through the acquisition and payment cycle to the revenue cycle in a performance assessment. "
          },
          "D252": {
            "courseName": "Accounting Research and Critical Thinking ",
            "description": "Accounting Research and Critical Thinking provides learners the skills and knowledge to research and add validity to accounting reports, resolution of issues, and procedural arguments: critical thinking, communication, research strategies, and database resources. "
          },
          "D253": {
            "courseName": "Values-Based Leadership ",
            "description": "Values-Based Leadership guides students to learn by reflection, design, and scenario planning. Through a combination of theory, reflection, value alignment, and practice, the course helps students examine and understand values-based leadership and explore foundations in creating a culture of care. In this course, students are given the opportunity to identify and define their personal values through an assessment and reflection process. Students then evaluate business cases to practice mapping the influence of values on their own leadership. In this course, students also participate in scenario planning, where they can practice implementing their values in their daily routine (i.e., behaviors) and then in a leadership setting. The course illustrates how values-driven leadership is used in goal setting as well as problem-solving at an organizational level. There are no prerequisites for this course. "
          },
          "D254": {
            "courseName": "Introduction to Medical Coding ",
            "description": "Introduction to Medical Coding provides students with the foundation for translating medical terminology into correct diagnosis and procedure codes. The course focuses on how diagnosis and procedure codes are used to accurately document medical records and inform accurate medical billing. This course introduces the Current Procedural Terminology (CPT), International Classification of Diseases (ICD-10-CM), ICD-10-PCS, and Healthcare Common Procedure Coding System (HCPCS) code sets as well as ethical considerations throughout processes in medical coding. There are no prerequisites for this course. "
          },
          "D255": {
            "courseName": "Professional Practice Experience I: Technical ",
            "description": "The PPE I: Technical course allows you to use EHRGo, an electronic health record (EHR), to complete 42 structured activities to experience how an HIM professional uses an EHR. The selected activities meet AHIMA's Baccalaureate level competencies and by completing them you will earn 40 PPE hours.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D256": {
            "courseName": "Principles of Management in Health Information Management ",
            "description": "Principles of Management in HIM provides an introductory look at the discipline of management and its context within the health information management environment. This course provides an overview of management and leadership, management functions, human resource management, and communication strategies. The course gives students an opportunity to analyze how leadership and management principles are used to achieve department goals. This course has no prerequisites. "
          },
          "D257": {
            "courseName": "Healthcare Project Management ",
            "description": "Healthcare Project Management provides students with a comprehensive foundation for project management. The course focuses on project management methodologies, process improvement analysis, business case proposals, and creating project planning documents for health information management (HIM) projects. This course will prepare students to determine project scope and timelines, complete interdepartmental stakeholder analysis, identify project resources, examine constraints and risks, and contribute to positive project communication. "
          },
          "D258": {
            "courseName": "Organizational Leadership in Healthcare ",
            "description": "Organizational Leadership in Healthcare provides students with an overview of the principles and practices leaders need in healthcare environments. The course focuses on organizational leadership theory, behaviors, culture, and teamwork. This course prepares students to apply leadership theories, principles of organizational culture development, techniques for building and leading teams, and conflict resolution strategies to support organizational goals. This course has no prerequisites. "
          },
          "D259": {
            "courseName": "Professional Practice Experience II: Management ",
            "description": "The PPE II: Management course allows you to experience your future profession at the supervisory level. Any site where health information is used and you can be mentored by a department or facility manager is appropriate for PPE II.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D260": {
            "courseName": "Health Information Management Capstone ",
            "description": "The Health Information Management Capstone is the culmination of the student's degree program. The course is an opportunity for students to do an environmental scan focusing specifically on emerging issues and trends in the field of health information management (HIM) and to apply knowledge learned throughout the program to the problems and issues facing HIM professionals. The student will also develop a professional and educational development plan. At the end of the course, the student will complete an RHIA practice exam. This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D263": {
            "courseName": "Frameworks for Strategic Decision-Making ",
            "description": "Frameworks for Strategic Decision-Making challenges students to use logistical reasoning, root cause analysis, and various problem-solving skills to drive improvement, develop relationships, influence others, and make decisions. This course addresses how to evaluate business problems, develop stakeholder-oriented solutions, and influence key stakeholders. It also promotes strategiclevel thinking and connection between business disciplines to drive outcomes. There are no prerequisites. "
          },
          "D265": {
            "courseName": "Critical Thinking: Reason and Evidence ",
            "description": "In this course you will learn key critical thinking concepts and how to apply them in the analysis and evaluation of reasons and evidence. The course examines the basic components of an argument, the credibility of evidence sources, the impact of bias, and how to construct an argument that provides good support for a claim. The course consists of an introduction and four major sections. Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the four competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. "
          },
          "D266": {
            "courseName": "World History: Diverse Cultures and Global Connections ",
            "description": "This is World History: Diverse Cultures and Global Connections. In this course, you will focus on three main topics—cultural and religious diversity; pandemics; and the relationship of empires and nation states—as well as the skills of identifying root causes, explaining causes and effects, and analyzing complex systems. This course consists of an introduction and four major sections. Each section includes learning opportunities through reading, images, videos, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check how well you understand the content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content. "
          },
          "D267": {
            "courseName": "US History: Stories of American Democracy ",
            "description": "This course presents a broad survey of U.S. history from early colonization to the midtwentieth century. The course explores how historical events and major themes in American history have affected diverse populations, influenced changes in policy an established the American definition of democracy.  This course consists of an introduction and five major sections. Each section  includes learning opportunities through reading, images, videos, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check how well you understand the content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content.  "
          },
          "D268": {
            "courseName": "Introduction to Communication: Connecting with Others ",
            "description": "Welcome to Introduction to Communication: Connecting with Others! It may seem like common knowledge that communication skills are important, and that communicating with others is inescapable in our everyday lives. While this may appear simplistic, the study of communication is actually complex, dynamic, and multifaceted. Strong communication skills are invaluable to strengthening a multitude of aspects of life. Specifically, this course will focus on communication in the professional setting, and present material from multiple vantage points, including communicating with others in a variety of contexts, across situations, and with diverse populations. Upon completion, you will have a deeper understanding of both your own and others’ communication behaviors, and a toolbox of effective behaviors to enhance your experience in the workplace. "
          },
          "D269": {
            "courseName": "Composition: Writing with a Strategy ",
            "description": "Welcome to Composition: Writing with a Strategy! In this course, you will focus on three main topics: understanding purpose, context, and audience, writing strategies and techniques, and editing and revising. In addition, the first section, will offer review on core elements of the writing process, cross-cultural communication, as well as working with words and common standards and practices.        Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the seven competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. "
          },
          "D270": {
            "courseName": "Composition: Successful Self-Expression ",
            "description": "Welcome to Composition: Successful Self-Expression! In this course, you will focus on four main topics: professional writing for a cross-cultural audience, narrowing research topics and questions, researching for content to support a topic, and referencing research sources. Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the seven competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. You will demonstrate competency through a performance assessment. There is no prerequisite for this course and there is no specific technical knowledge needed. "
          },
          "D276": {
            "courseName": "Web Development Foundations ",
            "description": "Web Development Foundations introduces students to web design and development using HTML,  XML, and Cascading Style Sheets (CSS), the foundational languages of the web. This course also covers how to troubleshoot problems using  developer tools and integrated development environments commonly employed in web development. There are no prerequisites for this course.  "
          },
          "D277": {
            "courseName": "Front-End Web Development ",
            "description": "Front-End Web Development builds upon web design and development skills to teach students how to organize websites with navigational schemes and create reactive user web interfaces using cascading style sheets (CSS). In this course, students will implement data entry and data storage capabilities in a web design, as well as implement best practices in design, including user-centered design and usability. Web Development Foundations is a prerequisite for this course. "
          },
          "D278": {
            "courseName": "Scripting and Programming ",
            "description": "Foundations - Scripting and Programming - Foundations introduces programming basics such as variables, data types, flow control, and design concepts. The course is language-agnostic in nature, ending in a survey of languages, and introduces the distinction between interpreted and compiled languages. Learners will gain skills in identifying scripts for computer program requirements and in using fundamental programming elements as part of common computer programming tasks. Learners will also gain an understanding of the logic and outcome of simple algorithms. "
          },
          "D279": {
            "courseName": "User Interface Design ",
            "description": "This course covers tools and techniques employed in user interface design, including web and mobile applications. Concepts of clarity, usability, and detectability are included in this course, as well as other design elements such as color schemes, typography, and layout. Techniques like wireframing, usability testing, and SEO optimization are also covered. "
          },
          "D280": {
            "courseName": "JavaScript Programming ",
            "description": "JavaScript Programming introduces students to programming with JavaScript, including how to use JavaScript to enhance a website. This course covers how to use existing frameworks, assets, and web content to enhance website functionality, as well as how to use application programming interfaces (APIs) and web services to add data capabilities to web applications. User Interface Design and Development is a prerequisite to this course. "
          },
          "D281": {
            "courseName": "Linux Foundations ",
            "description": "Linux Foundations prepares learners for the LPI Linux Essentials certification, and is an introduction to Linux as an operating system as well as an introduction to open-source concepts and the basics of the Linux command line. Learners will gain skills in identifying the fundamentals of open-source software and to develop resources for data access and security. "
          },
          "D282": {
            "courseName": "Cloud Foundations ",
            "description": "Cloud Foundations introduces learners to real-world issues and practical solutions to cloud computing. This course covers the business value of cloud computing, examining cloud types, the steps to successful cloud adoption, and the effect cloud adoption has on IT service management, as well as the risks and consequences of implementing cloud solutions. This course prepares learners for the AWS Certified Practitioner certification exam. There are no prerequisites for this course. "
          },
          "D284": {
            "courseName": "Software Engineering ",
            "description": "Software Engineering introduces the concepts of software engineering to students who have completed the core courses in programming and project management. The principles build on previously acquired concepts, switching the emphasis from programming simple routines to engineering robust and scalable software solutions. This course does not cover programming, but it provides an overview of software engineering processes and their challenging nature, focusing on the need for a disciplined approach to software engineering. A generic process framework provides the groundwork for formal process models. Prescriptive process models such as the Waterfall Model and Agile Development are included. This course also introduces the elements and phases of software engineering, including requirements engineering, design concepts, and software quality. There are no prerequisites for this course. "
          },
          "D286": {
            "courseName": "Java Fundamentals ",
            "description": "Java Fundamentals introduces you to object-oriented programming in the Java language. You will create and call methods, design Java classes, and other object-oriented principles and constructs to develop software that meets business requirements. This course requires foundational knowledge of programming including variables, type, program flow and debugging. "
          },
          "D287": {
            "courseName": "Java Frameworks ",
            "description": "Java Frameworks builds object-oriented programming expertise and introduces powerful new tools for Java application development. Students will execute exception handling, Java frameworks, and other object-oriented principles and constructs to develop a complete application including a user interface. This course requires foundational knowledge of object-oriented programming and the Java language. "
          },
          "D288": {
            "courseName": "Back-End Programming ",
            "description": "Back-End Programming introduces students to creating back-end components of a web application with the support of framework packages. This course also teaches students how to implement database functionality in a web application and how to create web services. This course requires intermediate expertise in object-oriented programming and the Java language. "
          },
          "D291": {
            "courseName": "Learning Experience Design Foundations I ",
            "description": "Learning Experience Design Foundations I provides an introduction to the field of learning experience design (LxD) and the role of the learning experience designer, which combines best practices from the fields of instructional design and user experience design, with the goal of creating human centered, goal-oriented learning experiences. This first of two foundational courses introduces Design Thinking and instructional design models, processes, and approaches. This course demonstrates how learning theories and instructional frameworks can be applied to facilitate deep learning, motivation, and engagement. This course also teaches the process for analyzing learners and their needs, as well as defining the instructional problem and goals. There are no prerequisites for this learning experience design course.   "
          },
          "D292": {
            "courseName": "Learning Experience Design Foundations II ",
            "description": "Learning Experience Design Foundations II is the second of two foundational courses that provide the foundational knowledge and skills learning experience designers need to create human-centered, goal-oriented learning experiences. Continuing to the third, fourth, and final phases of the Design Thinking Process, this course teaches the process and importance of ideation as well as rapid prototyping. It includes techniques for creating e-learning storyboards, which communicate content plans and instructional design strategies and “look and feel” mockups, which incorporate visual design principles and usability best practices. Finally, this course introduces usability testing methods and provides guidelines for planning usability tests for e-learning solutions. Learning Experience Design Foundations I is a prerequisite for this course. "
          },
          "D293": {
            "courseName": "Assessment and Learning Analytics ",
            "description": "Assessment and Learning Analytics focuses specifically on applying assessment and learning analytics practices to gauge learner progress through e-learning products. This course is an introduction to assessment models, including competency and skills-based methods, as well as culturally responsive and Universal Design for Learning (UDL) approaches in assessment, rubric, and feedback design. Finally, this course introduces learning analytics, specifically how they can add an additional layer of validation and visibility on learner progress. Learning Experience Design Foundations II is a prerequisite for this course. "
          },
          "D294": {
            "courseName": "Learning Technology ",
            "description": "Learning Technology provides opportunities for learners to research emerging learning technologies and see how they are changing current teaching and learning practices. This course also teaches strategies for evaluating learning technologies and their ability to facilitate deep learning and help learners achieve their learning goals, as well as their ability to accommodate learner differences and ensure access for all learners. This course covers techniques that learning experience designers can use to implement technology safely, legally, and ethically in a variety of environments. Additionally, this course explores the types of learning analytics that various technologies generate and the ways in which they can be used to better understand learner progress and optimize the learning experience. Assessment and Learning Analytics is a prerequisite for this course. "
          },
          "D295": {
            "courseName": "Designing and Facilitating E-Learning Experiences for K–12 Students ",
            "description": "Designing and Facilitating E-Learning Experiences for K–12 Students is the first of two courses in the K-12 Learning Designer pathway. This course teaches skills needed to plan units of study that leverage virtual settings and achieve academic standards while promoting digital citizenship. This course provides strategies for explaining essential concepts and demonstrating examples for students in K–12 virtual settings. It also provides strategies for using technology to facilitate meaningful collaboration among K–12 students. Finally, this course explains how to design effective practice and assessment opportunities for K–12 students in virtual settings and provides strategies for ensuring students get the feedback they need to improve learning. Learning Technology is a prerequisite for this course. "
          },
          "D296": {
            "courseName": "Quality and Impact of K–12 E-Learning Solutions ",
            "description": "Quality and Impact of K–12 E-Learning Solutions is the second of two courses in the K– 12 Learning Designer pathway. This course provides an introduction to the challenges K–12 students face in e-learning environments. It also directs learners to professional and academic resources where they can find current research related to issues and innovations learning experience designers implement to solve challenges to K–12 students in e-learning environments. This course also outlines a quality framework for evaluating e-learning solutions for K–12 students and provides opportunities for learners to apply that framework. Lastly, this course provides examples of how learning analytics can be used to determine the impact of e-learning for K–12 students. Through this course, learners will analyze data about K–12 learners to determine the impact an e-learning solution has had on engagement, effort, and learning. This course teaches learners how insights gained from data about K–12 learners can be used to optimize e-learning. Designing E-Learning Experiences for K–12 students is a prerequisite for this course. "
          },
          "D297": {
            "courseName": "Designing E-Learning Experiences for Adults ",
            "description": "Designing E-Learning Experiences for Adults is the first of two courses in the adult learning designer pathway. This course teaches best practices for supporting adult learners as they acquire knowledge and learn new skills and dispositions. This course explains effective approaches to designing learning experiences for adult learners that are collaborative, experiential, and transformative in nature. This course also explores problem-based and competency-based approaches to designing learning experiences for adults. Each evidencebased approach is defined and supported by theory and research. The course also includes best practices for designing each type of learning experience and provides real examples of each approach. Learning Technology is a prerequisite for this course. "
          },
          "D298": {
            "courseName": "Quality and Impact of Adult E-Learning Solutions ",
            "description": "Quality and Impact of Adult E-Learning Solutions is the second of two courses in the Adult Learning Designer pathway. This course introduces the issues learning experience designers often encounter when designing e-learning experiences for adults. It also directs learners to resources about current research related to issues and innovations in designing online learning experiences for adults. This course also outlines a quality framework for evaluating e-learning solutions for adults and provides opportunities for learners to apply that framework. Lastly, this course provides examples of how learning analytics can be used to determine the impact of e-learning solutions for adults. Learners will analyze dashboard data and determine the impact an e-learning solution has had on learner engagement, effort, and learning and how insights gained from data about learners and the learning experience can be used to optimize learning and the environments in which it occurs. Designing E-Learning Experiences for Adults is a prerequisite for this course. "
          },
          "D299": {
            "courseName": "Learning Experience Design Lab ",
            "description": "Learning Experience Design Lab requires learners to apply foundational learning experience design strategies to create an instructional solution in the form of an e-learning module. In the course, learners will identify an instructional problem and then design and develop a functional prototype of an e-learning solution. Learning Experience Design Lab provides an environment for learners to apply foundational knowledge and skills, experiment with various e-learning design tools and techniques, provide helpful quality feedback to peers, and receive quality feedback from peers about their own e-learning module. Finally, Learning Experience Design Lab teaches the importance of obtaining user feedback and incorporating that feedback to continuously improve the learning experience. Degree-seeking learners must complete the Learning Experience Design foundations series and two pathway courses prior to completing this course. "
          },
          "D300": {
            "courseName": "Identifying Learner Needs and a Research Problem ",
            "description": "Identifying Learner Needs and a Research Problem is the first of three capstone courses in the MSLxDET program. This course provides an introduction to design-based research and focuses specifically on the first two phases of the design-based research process: identifying and analyzing the learning problem and reviewing the literature. This course also requires that learners continue applying Design Thinking as they empathize with learners and define the instructional problem that their research will help them understand and address. Finally, this course teaches learners how to conduct a literature review to determine what research has already been done and what is unknown about their research topic. Learning Experience Design Lab is a prerequisite for this course. "
          },
          "D301": {
            "courseName": "Developing an E-Learning Solution and Research Methodology ",
            "description": "Developing an E-Learning Solution and Research Methodology is the second of three capstone courses in the MSLxDET program. This course focuses on the next two phases of the design-based research process: designing and developing an e-learning solution and designing a research methodology to test how well the solution addressed the instructional problem. This course also requires that learners continue applying Design Thinking as they ideate potential solutions to the instructional problem and begin prototyping a module of instruction. Finally, this course teaches learners how to design research studies that ensure the safety of human subjects and the ethical collection, storage, and reporting of data. The course Identifying Learner Needs and a Research Problem is a prerequisite for this course. "
          },
          "D302": {
            "courseName": "Implementing and Evaluating E-Learning Solutions ",
            "description": "Implementing and Evaluating E-Learning Solutions is the third of three capstone courses in the MSLxDET program. This course focuses on the final steps of the Design-Based Research process: implement, test, refine, reflect, and report. This course also requires that learners continue applying Design Thinking as they test and refine the solution identified during the prototyping phase. The course requires learners to test and refine their implementation strategies, use data to evaluate the effectiveness of their e-learning solution, redesign or enhance their e-learning design based on their interpretation of the data, and summarize their design-based action research study. Developing an E-Learning Solution and Research Methodology is a prerequisite for this course. "
          },
          "D303": {
            "courseName": "Azure Fundamentals ",
            "description": "Azure Fundamentals provides the learner with skills needed to describe the following concepts: cloud concepts; core Azure services; core solutions and management tools on Azure; general security and network security features; identity, governance, privacy, and compliance features; and Azure cost management and Service Level Agreements. Learners will gain foundational knowledge of cloud services and how those services are provided with Microsoft Azure. This course is intended for students who are just beginning to work with cloud-based solutions and services or are new to Azure. Competency in this course is demonstrated by successfully completing the Microsoft Azure Fundamentals certification exam (AZ-900). There are no prerequisites to this course. "
          },
          "D304": {
            "courseName": "Azure DevOps Solutions ",
            "description": "Azure DevOps Solutions provides the learner with skills to accomplish the following technical tasks: Designing and implementing strategies for collaboration, code, infrastructure, source control, security, compliance, continuous integration, testing, delivery, monitoring, and feedback. This course expects candidates to have intermediate-level skills for administering Azure and understand Azure development and DevOps processes. The following courses are prerequisites: Networks and Security—Foundations; Networks; Networks and Security—Applications; Cloud Foundations; Cloud Platform Solutions; Azure Fundamentals; and Azure Developer Associate "
          },
          "D305": {
            "courseName": "Azure Data Engineer ",
            "description": "Azure Data Engineer prepares the learner for integrating, transforming, and consolidating data from various structured and unstructured data systems into structures that are suitable for building analytics solutions. Learners will be provided with skills to accomplish the following technical tasks: design and implement data storage, design and develop data processing, design and implement data security, and monitor and optimize data storage and data processing. Candidates must have solid knowledge of data processing languages, such as SQL, Python, or Scala, and they need to understand parallel processing and data architecture patterns. The following courses are prerequisites: Introduction to Programming in Python, Azure Fundamentals, and Azure Developer Associate. "
          },
          "D306": {
            "courseName": "Azure Developer Associate ",
            "description": "Azure Developer Associate provides the learner with subject matter knowledge in designing, building, testing, and maintaining cloud applications and services on Microsoft Azure. Learners will be provided with the ability to program in a language supported by Azure and proficiency in Azure SDKs, Azure PowerShell, Azure CLI, data storage options, data connections, APIs, app authentication and authorization, compute and container deployment, debugging, performance tuning, and monitoring. The following course is a prerequisite: Azure Fundamentals. "
          },
          "D307": {
            "courseName": "Educational Psychology and Human Development of Children and Adolescents ",
            "description": "Educational Psychology and Human Development of Children and Adolescents is a key component of Whalley’s Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to research-validated theories of human development and psychology, spanning from early childhood through adolescence, and their applications in teaching practice. Candidates will explore how linguistic, physical, cognitive, and social development influence the learning process and inform educational approaches. This course will also cover appropriate instructional and assessment strategies that can be used to support learning for developmentally diverse student populations. The course will culminate in analysis of learning theories related to educational psychology in order to develop a personal educational philosophy. Candidates will engage in four hours of preclinical experiences, which include virtual classroom observations from the perspective of educational psychology and learner development. Cross-cutting themes of technology and diversity are interwoven for further development. "
          },
          "D308": {
            "courseName": "Mobile Application Development (Android) ",
            "description": "Mobile Application Development introduces students to programming for mobile devices using a software development kit (SDK). Students with previous knowledge of programming will learn how to install and use an SDK, build a basic mobile application, build a mobile application using a graphical user interface (GUI), adapt applications to different mobile devices, save data, execute and debug mobile applications using emulators, and deploy a mobile application. "
          },
          "D311": {
            "courseName": "Microbiology with Lab: A Fundamental Approach ",
            "description": "Microbiology with Lab: A Fundamental Approach explores the science that microorganisms are everywhere, and they have positive and negative effects on the community. The course examines the structure and function of microorganisms, disease transmission and progression, and immune responses and other interventions, and it identifies key global diseases. The course consists of an introduction and four major sections. Each section includes learning opportunities through readings, videos, and other relevant resources. Assessment activities with feedback also provide opportunities for students to check their learning, practice, and show how well they understand course content. To assist students in developing an applied, evidence-based understanding of microbiology, this course integrates several lab experiments to help determine the specific characteristic of an unknown microbial sample and a treatment plan. Because the course is self-paced, students may move through the material as quickly or as slowly as needed to gain proficiency in the four competencies that will be covered in the final assessment. Students who have no prior knowledge of or experience with this topic can expect to spend 48–60 hours on the course content. There are no prerequisites for this course. "
          },
          "D312": {
            "courseName": "Anatomy and Physiology I with Lab ",
            "description": "This is Anatomy and Physiology I, a six-section, 4 CU course that enables students to develop an understanding of the relationships between the structures and function of the integumentary, skeletal, muscular, nervous and endocrine systems in the human body. This course will involve laboratory activities, simulated dissections, textbook material, models, and diagrams.    Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 40–60 hours on the course content. "
          },
          "D313": {
            "courseName": "Anatomy and Physiology II with Lab ",
            "description": "This is Anatomy and Physiology II, a six section, four CEU course that enables students to develop an understanding of the relationships between the structures and functions of the cardiovascular, respiratory, digestive, urinary, reproductive, and lymphatic systems in the human body. This course will involve laboratory activities, simulated dissections, textbook material, models, and diagrams.   Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 40–60 hours on the course content. "
          },
          "D314": {
            "courseName": "Essentials of Academic Writing ",
            "description": "The learner will explore professional communication by applying the principles of academic writing to their discipline. Learners will incorporate these skills into the development of an evidence-based scholarly paper in their specialty area. As learners develop a scholarly paper, they will acquire a deeper understanding of the research topic selected and analyze whether initiatives and interventions have been effective or ineffective. "
          },
          "D315": {
            "courseName": "Network and Security ",
            "description": "Foundations - Network and Security - Foundations introduces learners to the basic network systems and concepts related to networking technologies. Learners will gain skills in applying network security concepts for business continuity, data access, and confidentiality, and in identifying solutions for compliance with security guidance. "
          },
          "D316": {
            "courseName": "IT Foundations ",
            "description": "IT Foundations provides learners with an understanding of personal computer components and their functions in a desktop system; a knowledge of computer data storage and retrieval; and skills in classifying, installing, configuring, optimizing, upgrading, and troubleshooting printers, laptops, portable devices, operating systems, networks, and system security. This course also gives learners the ability to recommend appropriate tools, diagnostic procedures, preventative maintenance, and troubleshooting techniques for personal computer components in a desktop system; strategies for identifying, preventing, and reporting safety hazards and environmental or human accidents in technological environments; and effective communication skills for interacting with colleagues and clients, including job-related professional behavior. The course prepares learners for the CompTIA A+ Core 1 certification exam. "
          },
          "D317": {
            "courseName": "IT Applications ",
            "description": "IT Applications introduces skills in identifying operating systems and their configurations and in implementing security principles across devices and networks. Learners will also gain skills in troubleshooting software, security, and malware issues, and in implementing basic operational procedures in documentation, change management, compliance, and communication. The course will introduce basic disaster recovery and business continuity procedures, scripting basics, and remote access technology solutions. The course prepares learners for the CompTIA A+ Core 2 certification exam. "
          },
          "D318": {
            "courseName": "Cloud Applications ",
            "description": "Cloud Applications prepares learners for the CompTIA Cloud+ certification exam. Learners will gain skills in designing cloud infrastructure and services and in recommending cloud security solutions, policies, and procedures. The course will also introduce skills in deploying cloud solutions for storage, networking, and security, and in managing cloud operations with processes, procedures, and improvements. Learners will also gain skills in troubleshooting cloud services issues in networking, security, and performance. "
          },
          "D319": {
            "courseName": "AWS Cloud Architecture ",
            "description": "AWS Cloud Architecture examines the skills and knowledge needed to effectively design structured cloud environments. Through practical application, students will gain experience in designing control measures for resilient architectures with cloud solutions and concepts, and to design high-performing and scalable architectures for software performance workloads. Students will also learn skills in designing security policies and access for cloud applications and architectures, and designing cost optimized storage, database and network architectures based on situational feedback. "
          },
          "D320": {
            "courseName": "Managing Cloud Security ",
            "description": "Managing Cloud Security prepares learners to safeguard cloud data with identity and access management and to implement secure solutions in cloud service models. Learners will be introduced to skills in identifying security policies and procedures for cloud applications and in implementing operational capabilities, procedures, and training in relation to organizational needs. Learners will also gain skills in conducting risk analysis and risk management in alignment with disaster recovery and business continuity plans and in identifying legal, compliance, and ethical concerns. "
          },
          "D321": {
            "courseName": "AWS Developer ",
            "description": "AWS Developer examines the skills and knowledge needed to effectively implement automated and continuous testing processes for software deployments with cloud solutions. Students will learn to design software with Amazon Web Services (AWS), software development kits (SDKs), and command line interface (CLI), and to implement authentication, encryption, and authorization within an AWS environment. Students will also learn to design cloud service deployments with AWS infrastructure services, platform services, and features. Students will learn skills to monitor automated testing for quality control and to perform root cause analysis on testing or production failures. "
          },
          "D322": {
            "courseName": "Introduction to IT ",
            "description": "Introduction to IT examines information technology as a discipline and the various roles and functions of the IT department as business support. Students are presented with various IT disciplines including systems and services, network and security, scripting and programming, data management, and business of IT, with a survey of technologies in every area and how they relate to each other and to the business. "
          },
          "D324": {
            "courseName": "Business of IT ",
            "description": "Project Management - In this course, students will build on industry standard concepts, techniques, and processes to develop a comprehensive foundation for project management activities. During a project's life cycle, students will develop the critical skills necessary to initiate, plan, execute, monitor, control, and close a project. Students will apply best practices in areas such as scope management, resource allocation, project planning, project scheduling, quality control, risk management, performance measurement, and project reporting. This course prepares students for the following certification exam: CompTIA Project+. "
          },
          "D325": {
            "courseName": "Networks ",
            "description": "Networks introduces skills in configuring networking components and a network infrastructure. Learners will gain skills in optimizing network operations for availability, performance, and security, and in troubleshooting network issues. The course prepares learners for the CompTIA Network+ certification exam. Network and Security - Foundations is a prerequisite for this course. "
          },
          "D326": {
            "courseName": "Advanced Data Management ",
            "description": "Advanced Data Management enables learners to extract and analyze raw data. Skillful data management allows organizations to discover and explore data in ways that uncover trends, issues, and their root causes. In turn, businesses are better equipped to capitalize on opportunities and more accurately plan for the future. As organizations continue to extract larger and more detailed volumes of data, the need is rapidly growing for IT professionals who possess data management skills. The skills gained in this course include performing advanced relational data modeling as well as designing data marts, lakes, and warehouses. This course will empower learners with the skills to build business logic at the database layer to employ more stability and higher data-processing speeds. Learners will gain the ability to automate common tasks to summarize and integrate data as they prepare it for analysis. Data Management - Foundations is a prerequisite for this course. "
          },
          "D329": {
            "courseName": "Network and Security ",
            "description": "Applications - Network and Security - Applications prepares learners for the CompTIA Security+ certification exam. The course introduces learners to skills in identifying threats, attacks, and vulnerabilities to organizational security. The learner will also gain skills in designing security solutions for enterprise infrastructures and architectures, as well as in implementing security solutions across hardware, applications, and network services. Learners will be able to execute operations and incident response with tools, policies, forensics, and mitigation techniques, and to analyze information security controls, governance, risk, and compliance. "
          },
          "D330": {
            "courseName": "Data Systems Administration ",
            "description": "Data System Administration provides learners with foundational skills to become a Database Administrator (DBA). This course illustrates how DBAs ensure businesses are able to leverage significant data to increase profitability and support key business functions. Topics include database management tools, account administration, recovery procedures, and maintenance through upgrades and migrations. "
          },
          "D332": {
            "courseName": "Penetration Testing and Vulnerability Analysis ",
            "description": "Penetration Testing and Vulnerability Analysis introduces learners to the skills necessary to perform penetration testing and vulnerability management within an organization. Learners will gain skills in defining the scope and planning for procurement of penetration testing engagements and in performing cyber reconnaissance for information gathering and vulnerability identification. Learners will also gain skills in developing penetration testing techniques in exploitation of physical, digital, and social vulnerabilities, and to simulate attacks and responses on an organization's security infrastructure. Lastly, learners will gain skills in reporting the results of cybersecurity assessments with recommended actions. "
          },
          "D333": {
            "courseName": "Ethics in Technology ",
            "description": "Ethics in Technology examines the ethical considerations of technology use in the 21st century and introduces students to a decision-making process informed by ethical frameworks. Students will study specific cases related to important topics such as surveillance, social media, hacking, data manipulation, plagiarism and piracy, artificial intelligence, responsible innovation, and the digital divide. This course has no prerequisites. "
          },
          "D334": {
            "courseName": "Introduction to Cryptography ",
            "description": "Introduction to Cryptography introduces skills in applying cryptography principles in alignment with organizational and information security guidelines. Students will determine requirements and techniques for cryptanalysis. This course builds skills in implementing encryption methods with symmetric and asymmetric algorithms. "
          },
          "D335": {
            "courseName": "Introduction to Programming in Python ",
            "description": "Introduction to Programming in Python introduces skills in creating Python scripts with basic programming concepts. Learners will be able to create control flow with functions and loops, and to implement code with packages, modules, and libraries. "
          },
          "D336": {
            "courseName": "Business of IT – Applications ",
            "description": "Business of IT - Applications examines Information Technology Infrastructure Library (ITIL®) terminology, structure, policies, and concepts. Focusing on the management of information technology (IT) infrastructure, development, and operations, learners will explore the core principles of ITIL practices for service management to prepare them for careers as IT professionals, business managers, and business process owners. This course has no prerequisites. "
          },
          "D337": {
            "courseName": "Internet of Things (IoT) and Infrastructure ",
            "description": "Internet of Things (IoT) and Infrastructure introduces students to emerging technologies connecting the internet to a variety of physical objects. The course reviews the business requirements for sensors and securely storing, transmitting, and processing the data they generate. As new use cases emerge, ethical and privacy issues become relevant aspects of business development. There are no prerequisites for this course. "
          },
          "D338": {
            "courseName": "Cloud Platform Solutions ",
            "description": "Cloud Platform Solutions examines skills in identifying cloud system administration tasks related to user access groups, single sign-on (SSO), and server deployments. Students will gain skills in determining machine access for cloud storage solutions and in explaining the configuration of virtual machines for availability, scalability, performance, and security. Students will also be introduced to implementing virtual networking services and machine image monitoring. The following courses are prerequisites: Network and Security - Foundations, Network and Security - Applications, Networks, and Cloud Applications. "
          },
          "D339": {
            "courseName": "Technical Communication ",
            "description": "Technical Communication introduces skills in editing professional communications, evaluating the impact of professional etiquette in digital environments, and in creating artifacts that are persuasive, informational, and research-based. The course also introduces skills in delivering multimedia presentations using professional verbal communication skills. "
          },
          "D340": {
            "courseName": "Cyber Defense and Countermeasures ",
            "description": "Traditional defenses—such as firewalls, security protocols, and encryption—sometimes fail to stop attackers determined to access and compromise data. This course provides the fundamental skills to handle and respond to computer security incidents in an information system. The course addresses various underlying principles and techniques for detecting and responding to current and emerging computer security threats. Students learn how to leverage intelligence and threat detection techniques; analyze and interpret data; identify and address vulnerabilities; suggest preventative measures; effectively respond to and recover from incidents; and handle various types of incidents, risk assessment methodologies, and various laws and policies related to incident handling. This course prepares students for the CompTIA Cybersecurity Analyst (CySA+) certification exam. The following courses are prerequisites: Networks and Network and Security – Applications. "
          },
          "D341": {
            "courseName": "Cloud Deployment and Operations ",
            "description": "Cloud Deployment and Operations provides students with technical skills in the deployment, management, and operations of cloud services. This course allows students to examine stability and scalability, backup and recovery processes, and deployment best practices. Provisioning of cloud resources, monitoring of cloud resources, and managing connectivity are also examined. The following courses are prerequisites: Cloud Applications and AWS Cloud Architecture. "
          },
          "D342": {
            "courseName": "Cloud Computing Capstone ",
            "description": "The Cloud Computing Capstone offers learners opportunities to demonstrate the culmination of their skills learned within the Cloud Computing program. In this course, learners will show their skills by defining system components and creating implementation plans for cloud solutions. The course also offers learners ways to demonstrate their skills in determining configurations for API, performing system administration tasks, and creating test plans for cloud solutions. "
          },
          "D343": {
            "courseName": "Foundations of Advanced Psychiatric Mental Health Practice ",
            "description": "Foundations of Advanced Psychiatric Mental Health Practice guides students through the process of learning about mental health and mental illness. This course presents the history of psychiatric care, along with cultural components that influence individual attitudes and behaviors. This course introduces conceptual models and theories related to practice that provide the basis for understanding the development of psychopathology to apply appropriate therapeutic strategies. This course includes clinical practice guidelines using the DSM-5-TR (Diagnostic Statistical Manual of Mental Disorders) as a basis for diagnostic consistency across the lifespan. This course also includes relevant advanced practice issues, legal and ethical components, and barriers to practice that a mental health psychiatric nurse practitioner may encounter. Various psychological responses to stress are also discussed. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
          },
          "D344": {
            "courseName": "The Assessment and Diagnostic Process of Psychiatric Nurse Practitioner Practice ",
            "description": "The Assessment and Diagnostic Processes for Advanced Psychiatric Mental Health Practice guides students when examining determinants to the role of the psychiatric mental health nurse practitioner. This course guides students in building a therapeutic relationship with patients through interviewing skills, conducting a structured assessment, milieu, types of therapy, and various care strategies, including technology usage. This course guides students through exploring their leadership role in collaborating with the interprofessional community as a mental health nurse practitioner. Pathways of quality improvement, practice evaluation, and healthcare reform are also considered. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
          },
          "D345": {
            "courseName": "Psychopharmacology for Advanced Psychiatric Mental Health Practice ",
            "description": "Psychopharmacology for Advanced Psychiatric Mental Health Practice explains the knowledge of advanced pharmacotherapeutics and why it is important to safely and appropriately prescribe agents to manage common chronic and acute mental health problems of diverse populations. This includes differences between experimental and clinical psychopharmacology. This course covers the principles of pharmacokinetics and pharmacodynamics in administration, along with patient education. This course discusses factors of addiction and substance use, including prevalence, clinical manifestations, and treatment of various disorders. Collaborative clinical services, such as group counseling, therapeutic communities, and medication support, are explored. The foundational information in psychopharmacology in this course guides students in planning individualized mental health drug management for individuals across the life span based on setting, context, and professional ethics. The following courses are prerequisites: all MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
          },
          "D346": {
            "courseName": "Advanced Psychiatric Mental Health Care of Adults and Older Adults Across Care Settings ",
            "description": "Advanced Psychiatric Care of Adults and Older Adults Across Care Settings prepares students to provide evidence-based mental healthcare for adults, older adults, and families. This course guides students through the application of age and developmentally appropriate advanced practice health assessment knowledge and diagnostic reasoning skills for adults, older adults, and families experiencing complex mental health issues. This course helps students develop treatment plans using psychotherapeutic treatment modalities, psychopharmacology, and community resources to manage specific mental health disorders for adults, older adults, and families. This course also includes the influences of family dynamics and societal norms on mental health progression and recovery. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
          },
          "D347": {
            "courseName": "Advanced Psychiatric Mental Health Care of Children and Adolescents Across Care Settings ",
            "description": "Advanced Psychiatric Mental Health Care of Children and Adolescents across Care Settings prepares students to provide evidence-based mental healthcare for children, adolescents, and families. This course guides students through the application of age and developmentally appropriate advanced practice health assessment knowledge and diagnostic reasoning skills for children, adolescents, and families experiencing complex mental health issues. This course helps students develop treatment plans to manage specific mental health disorders through the use of psychotherapeutic treatment modalities, psychopharmacology, and community resources. This course also covers the influences of family dynamics and societal norms on mental health progression and recovery. The following are prerequisites for this course: all MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
          },
          "D348": {
            "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship I ",
            "description": "Through precepted clinical experiences, the learner will develop the competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course.   "
          },
          "D349": {
            "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship II ",
            "description": "Through precepted clinical experiences, the learner will develop competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course. "
          },
          "D350": {
            "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship III ",
            "description": "Through precepted clinical experiences, the learner will develop competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course. "
          },
          "D351": {
            "courseName": "Functions of Human Resource Management ",
            "description": "This course provides an introduction to the management of human resources, which is the function within an organization that focuses on recruitment, management, and direction for the people who work in the organization. Students will be introduced to topics such as strategic workforce planning and talent acquisition; compensation and benefits; training and development; employee and labor relations; and occupational health, safety, and security. "
          },
          "D352": {
            "courseName": "Employment and Labor Law ",
            "description": "Employment and Labor Law reviews the legal and regulatory framework surrounding employment, including recruitment, termination, and discrimination law. The course topics include employment-at-will, EEO, ADA, OSHA, and other laws affecting the workplace. Students will learn to analyze current trends and issues in employment law and apply this knowledge to manage risk in employment relationships effectively. Functions of Human Resources and Introduction to Human Resources are recommended prior to Employment and Labor Law. "
          },
          "D353": {
            "courseName": "Strategic Training and Development ",
            "description": "Strategic Training and Development focuses on the development of human capital (i.e., growing talent) by applying effective learning theories and practices for training and developing employees. The course will help develop essential skills for improving and empowering organizations through high-caliber training and development processes. "
          },
          "D354": {
            "courseName": "Talent Acquisition ",
            "description": "Talent Acquisition focuses on building a highly skilled workforce that meets organizational staffing needs by using effective strategies and tactics for recruiting, selecting, and onboarding employees. The learner will develop competency in critical skills related to talent acquisition, such as workforce planning, developing strategic recruiting plans, and ensuring effective selection strategies. Talent acquisition is a top skill for HR professionals, and successful talent acquisition practices lend to individual, team, and organizational success. "
          },
          "D355": {
            "courseName": "Total Rewards ",
            "description": "This course develops competence in the design and implementation of total rewards approaches in an organization. The total rewards perspective integrates tangible rewards (e.g., salary, bonuses) with employee benefits (e.g., health insurance, retirement plan) and intangible rewards (e.g., location, work environment). This perspective allows learners to use all forms of rewards fairly and effectively to enable job satisfaction and organizational performance. "
          },
          "D356": {
            "courseName": "HR Technology ",
            "description": "HR Technology focuses on the usage of technology for strategic human resource management. The learner will develop competency in critical skills related to analyzing the value and application of the different types of human resource information systems (HRIS), managing HRIS implementations, electronic human resource management, and future trends and application of HR technology. HR professionals must be familiar with HR technology in order to provide effective and efficient HR practices for their organization and recommendations to leadership to invest in technology. An understanding of HR technology is also an in-demand skill for HR professionals across all industries. "
          },
          "D357": {
            "courseName": "Diversity, Equity, and Inclusion ",
            "description": "Diversity, Equity, and Inclusion examines the importance and impact of diversity in organizations through an understanding of the theoretical, background, and legislative foundations of diversity. This course will explore specific groups and categories of diversity, as well as global diversity and career paths in diversity. "
          },
          "D358": {
            "courseName": "Global Human Resource Management ",
            "description": "Global Human Resource Management explores the rapidly changing field of international human resource management (HRM) and examines a global perspective in relation to staffing, personnel management, strategy, and communications in a cross-cultural context. This course will help learners examine critical skills such as application of international employment law, labor standards, and ethics, as well as international application of human resources (HR) best practices in areas such as employee relations, global talent management, and future trends of international HRM. These skills lend to the success of HR professionals working to support organizations that operate in or within an international context and cross-culturally, as well as expanding the skillsets for those HR professionals interested in seeking a career as a global HR professional. There are no prerequisites for this course. "
          },
          "D359": {
            "courseName": "Agile HR ",
            "description": "Agile HR explores the concepts of Agile operations and Agile project management from the human resource management perspective. Learners will focus on design thinking, building value for employees, change management, adaptability, and strategic prioritization as part of the Agile skills in this course. Adaptability and resilience, while delivering value in a constantly changing world, are all critical skills for successful HR professionals and leaders. "
          },
          "D360": {
            "courseName": "HRM Capstone ",
            "description": "The learner synthesizes skills from across the human resource management (HRM) industry to demonstrate the ability to participate in and contribute value to the HR field. "
          },
          "D361": {
            "courseName": "Business Simulation ",
            "description": "This course ties together all the skills and knowledge covered in the business courses and allows the student to prove their mastery of the competencies by applying them in a simulated business environment. This course will help take the student's knowledge and skills from the theoretical to applicable. "
          },
          "D362": {
            "courseName": "Corporate Finance ",
            "description": "Corporate Finance is about business structures that set the environment for the day-to-day operations of a business. This course teaches learners about the common forms of business structures, the factors that business owners consider when they choose which structure to use, and the roles of shareholders and stakeholders. This course also teaches that managing the financial function involves capitalizing the company and evaluating capital budget techniques, including those that use the time value of money. Through this course, learners will learn how to calculate the cost to finance a business using the weighted average cost of capital, how to value stocks and bonds, and how to determine the value of the firm. The prerequisites for this course are D089 Principles of Economics, D366 Financial Statement Analysis, D216 Business Law for Accountants "
          },
          "D363": {
            "courseName": "Personal Finance ",
            "description": "Personal Finance provides learners with an introduction to the discipline of finance from the perspective of the person, or family, rather than from the viewpoint of a business. In this course, learners will gain an understanding of financial literacy concepts, including personal budgeting and how to apply financial principles to achieve personal financial goals. Learners will identify various strategies to manage risks, to enhance postretirement income, and to accumulate and transfer wealth. Topics include record keeping, credit principles, cash flow, investment philosophy, monetary asset management, housing, and estate planning. This course provides learners with a general overview of personal finance and acts as a preview course for the finance major. There are no prerequisites for this course. "
          },
          "D364": {
            "courseName": "Financial Management I ",
            "description": "This course covers basic financial management principles primarily targeted to the operations part of a business. The learner gains an understanding about the basic finance organization in an enterprise in support of the company's primary goal to increase corporate value for shareholders in an ethical way. Tools a finance professional might use in managing the cash and current assets are discussed along with cash budgeting and financial strategic planning. The Dupont equation is reviewed as the basis for analyzing and improving the performance of the enterprise to improve value. The learner will acquire knowledge about how forecasting models and financial instruments are used to optimize the working capital investment portfolio. Prerequisite for Financial Management I is Corporate Finance. "
          },
          "D365": {
            "courseName": "Financial Management II ",
            "description": "This course covers capital budgeting and long-term funding strategies. The course will delve into more advanced financial management principles primarily targeted toward corporate investment and capital planning. This course also explores an enterprise’s capital structure and how equity and long-term debt are used to finance and sustain long-term fixed asset projects. Decision methods, such as net present value, internal rate of return, and payback period, are discussed as techniques a finance professional might use in identifying and structuring the optimal capital budget. The learner will gain an understanding about equity capital, will assess financial markets, and will examine the differences in shareholder classifications and bonds. The course will teach how the dividend policy is devised and discover how the organization uses its corporate investment strategy to increase not only shareholder value but also corporate value for the shareholder. Prerequisites for Financial Management II are D196, Principles of Financial and Managerial Accounting; D076, Finance Skills for Managers; D363, Personal Finance; D362, Corporate Finance; D364, Financial Management I; and D366, Financial Statement Analysis. "
          },
          "D366": {
            "courseName": "Financial Statement Analysis ",
            "description": "Financial Statement Analysis discusses the concepts and provides tools for financial analysts to evaluate the financial elements of the firm as well as external factors to ultimately arrive at a valuation. You will learn a process to analyze data and the concepts where you can determine the quality of that data. This process provides a structure where ratios and company results are not looked at individually but as a whole in determining the worth of an enterprise, leading to an analysis-based valuation of the firm. "
          },
          "D367": {
            "courseName": "Innovation in Finance ",
            "description": "Innovation in Finance provides students with an introduction to the technologies and product solutions that have disrupted the financial services industry. In this course, students will learn about the emerging financial technologies contributing to the evolution of lending, payments, wealth management, financial planning, and the insurance industry. This course will examine the role financial technology (FinTech) firms serve as financial disruptors and how these organizations are developed and supported, from start-up to scale. Throughout the course, students will identify the impact emerging technologies and FinTechs have on businesses, individuals, and society as a whole. Topics include emerging technology products and services, incubators, accelerator programs, FinTech ecosystems, and technologies that enable and facilitate disruption by emerging technologies. This course provides students with a general overview of financial innovation and serves as an integral component of the finance major. D076: Principles of Finance is a prerequisite for this course. "
          },
          "D368": {
            "courseName": "Enterprise Risk Management ",
            "description": "Enterprise Risk Management provides learners with an introduction to the discipline of risk management from the perspective of an organization rather than from the viewpoint of a person. In this course, learners will learn risk management concepts, including risk tolerance, risk appetite, and how to utilize governance and compliance resources to achieve an effective risk management strategy. Throughout this course, learners will determine various strategies to identify, assess, monitor, and control risks and other threats to an organization. Topics include approaches to risk mitigation, generally accepted frameworks and standards adopted to manage risk, current environmental, societal, and governance matters of risk interest to an organization, disaster recovery plans, and insurance products. "
          },
          "D369": {
            "courseName": "Finance Capstone ",
            "description": "This course is designed as a synthesis of the knowledge learners have acquired throughout the program. The course culminates in a performance assessment that requires learners to apply the competencies gained throughout the finance program. In this course, learners will draw upon the concepts and techniques introduced in the undergraduate finance program to perform a comprehensive financial analysis of an enterprise. In completing the course, learners will perform analyses with spreadsheet software to simulate a real-world experience of a finance career professional. "
          },
          "D370": {
            "courseName": "IT Leadership Foundations ",
            "description": "IT Leadership Foundations is an introductory course that provides students with an overview of organizational structures, communication, and leadership styles specific to information technology in organizations. It also introduces students to some of the power skills that help make successful IT professionals, including time management, problem solving, and emotional intelligence. Students in this course explore their own strengths and passions in relation to the field. There are no prerequisites for this course. "
          },
          "D372": {
            "courseName": "Introduction to Systems Thinking ",
            "description": "Introduction to Systems Thinking provides learners with the skills required to engage in a holistic systems-based approach to analyzing complex problems and solutions. This course introduces the foundational concepts and principles of systems thinking and provides opportunities to use a systems thinking approach to analyze and evaluate real-world case studies. The course will culminate with using systems thinking to develop a solution to an authentic complex problem. This course has no prerequisites, but general education math (C955 or C957) is preferred. Because the course is self-paced, learners may move through the material as quickly or as slowly as needed, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If learners have no prior knowledge of this material, they can expect to spend 30 to 40 hours on the course content. "
          },
          "D373": {
            "courseName": "Marketing in the Digital Era ",
            "description": "Marketing in the Digital Era examines the marketing skills needed to be an effective marketer in the 21st century. This course provides a company and consumer perspective to learn how consumer value is created while achieving organizational goals. Learners will gain knowledge in the core areas of marketing, including branding and products, consumers, communications, and technology. This course introduces learners to strategic marketing tools used to develop effective strategies for a relevant target market. Marketing in the Digital Era teaches learners about emerging topics, such as marketing automation, artificial intelligence, and data-driven communications, as well as skills needed to continue evolving as a successful marketing professional in the dynamic, ever-changing marketing environment. "
          },
          "D374": {
            "courseName": "Market Research ",
            "description": "Market Research provides learners with a knowledge of the role of marketing research in strategic decision-making. Marketers need to systematically collect and analyze data to develop insights and make decisions. Learners demonstrate proficiency in the fundamentals of market research by practicing statistical methods for analyzing and acting on market data. This course teaches sampling techniques, survey development, data analysis, ethical considerations, and hypothesis testing. Learners will apply their knowledge using a market research simulation. Emphasis is placed on the interpretation and use of results to demonstrate how to communicate information. In this course, learners will also develop awareness for continually monitoring emerging and competitive trends given the dynamic digital marketing landscape. "
          },
          "D375": {
            "courseName": "Marketing Communications and Storytelling ",
            "description": "Marketing Communications and Storytelling introduces learners to the principles of integrative marketing communications with an emphasis on storytelling and creativity. Storytelling skills enable marketers to build relationships by making connections with their intended audience. Digital storytelling integrates the use of technology to create a cohesive narrative across various platforms that evokes emotions and feelings about a brand. These efforts can lead to customer acquisition or conversion outcomes. In this course, the creative storytelling process begins with a clear SMART objective that drives strategy development, the creative direction, and the execution of an integrative marketing communication campaign. Campaign types, media channels, messages, timelines, and market development considerations are taught within the context of both digital and traditional application. Learners will also have the opportunity to complete the HubSpot Inbound Marketing certification to learn industry best practices and to enhance marketability within the marketing profession. "
          },
          "D376": {
            "courseName": "Product, Price, and Customer Experience ",
            "description": "Product, Price, & Customer Experience teaches core marketing concepts used to create integrative marketing strategies that meet the needs of an organization and its customers. This course teaches topics of new product development, product management, value proposition, and customer experience management. The voice of the customer is taught to ensure marketers create a customer-centric culture within their organization to meet the needs, wants, and preference of their target market. Customer experience management practices are key focal points of this course to ensure meaningful customer interactions across the entire customer journey. "
          },
          "D377": {
            "courseName": "Digital Marketing Foundations ",
            "description": "Digital Marketing Foundations provides learners with knowledge in the dynamic and evolving field of marketing that uses the internet and digital technologies to reach customers. This course provides foundational knowledge in digital marketing disciplines, including social media marketing, content marketing, marketing automation, search engine optimization (SEO), paid search (PPC), conversion rate optimization (CRO), mobile marketing, and web analytics. Learners explore how a digital marketing strategy is developed and executed, along with how digital marketing is integrated within an organization's overall strategy. This course provides learners with the opportunity to complete the HubSpot Marketing Software certification to learn marketing automation software that is used in industry to assist marketers in their digital marketing execution. Emphasis throughout the course is placed on industry best practices, and the course content aligns with the Online Marketing Certified Associate (OMCA) certification to prepare students for this third-party certification. "
          },
          "D378": {
            "courseName": "Digital Marketing Science ",
            "description": "Digital Marketing Science provides learners with knowledge in the dynamic and evolving field of marketing that uses the internet and digital technologies to reach customers. This course provides foundational knowledge in digital marketing disciplines, including social media marketing, email marketing, content marketing, marketing automation, search engine optimization (SEO), paid search (PPC), conversion rate optimization (CRO), mobile marketing, and web analytics. Learners explore how a digital marketing strategy is developed and executed, along with how digital marketing is integrated within an organization's overall strategy. This course provides learners with the opportunity to complete the HubSpot Marketing Software certification to learn marketing automation software that is used in industry to assist marketers in their digital marketing execution. Emphasis throughout the course is placed on industry best practices, and the course content aligns with the Online Marketing Certified Associate (OMCA) certification to prepare students for this third-party certification. "
          },
          "D379": {
            "courseName": "Social Media Marketing ",
            "description": "Social Media Marketing teaches learners about social media from a business perspective and how social media can be used to increase a company’s brand awareness, generate leads, and build meaningful relationships with customers. Throughout this course, learners will develop a social media marketing strategy across various social platforms to create and distribute valuable and relevant content to specific audiences. Social Media Marketing teaches learners influencer marketing, employee advocacy, social selling, and social media analytics. Learners will gain hands-on experience using a simulation to create targeted social media advertisements, perform demographic targeting, implement social media content promotion strategies, and schedule content. In this course, learners will have the opportunity to complete HubSpot's Social Media Marketing certification to learn industry best practices in social media marketing and to enhance marketability within the marketing profession. "
          },
          "D380": {
            "courseName": "Email Marketing ",
            "description": "Email Marketing examines the principles and techniques used to effectively manage email marketing campaigns. Email is used daily in both personal and business settings to communicate with others. In this course, learners will examine industry best practices of how to use email marketing as a digital strategy to achieve marketing goals. Learners will explore how to design an email marketing strategy, how to use email marketing for lead generation, how to design a lead nurture campaign, and how automation is used to manage email marketing distribution and campaigns. This course provides learners with an opportunity to explore how effective email messages are crafted and distributed. Industry best practices will be explored and learners have the opportunity to complete the HubSpot Email Marketing certification to enhance marketability within the marketing profession. "
          },
          "D381": {
            "courseName": "E-Commerce and Marketing Analytics ",
            "description": "E-Commerce and Marketing Analytics teaches learners how to sell online, how to reach customers online, and how to measure campaign and website performance. Businesses and consumers actively engage in buying and selling products over the internet. Learners gain skills through the practical application of building and optimizing a Shopify website designed to promote and sell products to customers. The course explores the latest technology platforms with an emphasis on Google applications for hands-on experience. Learners have the opportunity to complete the Google Search Ads certification to gain skills used in practice and to enhance marketability within the marketing profession. "
          },
          "D382": {
            "courseName": "Digital Marketing Analytics ",
            "description": "Digital Marketing Analytics teaches learners how to identify data sources, collect and analyze data, and manage marketing performance. Marketing requires an understanding of analytics and application of data to inform strategic decision-making. Through the use of a digital marketing analytics framework, measurement models, and various digital marketing technology tools, learners will analyze marketing performance across digital paid, owned, and earned channels. The latest marketing technology tools are explored to measure and optimize results using data-driven decisions. In this course, learners will have the opportunity to complete the Google Analytics certification to gain technical skills used in practice and to enhance marketability within the marketing profession. "
          },
          "D383": {
            "courseName": "Search Engine Optimization ",
            "description": "Search Engine Optimization, otherwise known as SEO, teaches on-page, off-page, and technical aspects of SEO for organically improving ranking and awareness. Digital marketing requires marketers to understand online consumer search behaviors; search engine optimization (SEO) is a key part of an organization’s digital marketing strategy. This includes processes and best practices used to increase their visibility in search engines. Learners will discover SEO strategies focusing on website structure, search engines, keyword research and mapping, and page-level optimization. Learners will gain practical experience using a simulation in which they will optimize ranking and visibility to consumers. In this course, learners will have the opportunity to complete HubSpot’s SEO certification to learn industry best practices and to enhance marketability within the marketing profession. "
          },
          "D384": {
            "courseName": "Marketing Experiential Capstone ",
            "description": "Marketing Experiential Capstone is the capstone course for the program that provides learners with realworld applications to prepare them for the marketing industry. In this course, learners will integrate and apply marketing skills gained throughout the program by working with an organization on a marketing project. Marketers must effectively manage many relationships throughout their career with clients and team members in an organization, an agency, or their own marketing firm. This course allows students to apply their technical knowledge while also developing competencies in effective communication, collaboration, conflict management, project management, and time management power skills. Learners will explore their professional goals and develop a personal branding strategy to enhance their marketability and to strategically plan for their marketing career. "
          },
          "D385": {
            "courseName": "Software Security and Testing ",
            "description": "This course prepares you to recognize security vulnerabilities in software, to plan interventions to address security vulnerabilities where they exist, and to develop and test these interventions. The course covers topics in Web security, permissions, and  identity security; debugging; log file analysis; API security; and encryption and cryptography concepts.   "
          },
          "D386": {
            "courseName": "Hardware and Operating Systems Essentials ",
            "description": "Hardware and Operating Systems prepares learners for concepts in software engineering by providing a foundation of understanding in computer architecture, the history of computing architectures, and operating systems. Additional topics covered include hardware and software stacks and how to choose appropriate hardware and software solutions to meet both functional and nonfunctional business requirements. "
          },
          "D387": {
            "courseName": "Advanced Java ",
            "description": "Advanced Java refines object-oriented programming expertise and skills. You will implement multithreaded, object-oriented code with the features of Java necessary to develop software that meets business requirements. Additionally, you will determine how to deploy software applications using cloud services. This course requires intermediate expertise in object-oriented programming and the Java language. "
          },
          "D388": {
            "courseName": "Fundamentals of Spreadsheets and Data Presentations ",
            "description": "Fundamentals of Spreadsheets and Data Presentations offers learners an overview of the use of spreadsheet functions and methods for presenting data within spreadsheets. Learners will have the opportunity to explore features and uses of MS Excel and apply the tools to situations they may encounter while studying in their program. They will also be introduced to real world uses and tools to collect, organize and present data. "
          },
          "D389": {
            "courseName": "Learning Strategies in Higher Education ",
            "description": "Learning Strategies in Higher Education provides students with a toolbox of skills that will support student academic growth as they advance in their academic journey. Students will be introduced to the Whalley Library; how to use it and best practices for research strategies. Students will learn how to be professional in written communication and how to correctly use current APA format. In this course, students also will learn about setting goals, time-management, study strategies, making and keeping appointments, professional decorum, and test-taking skills. Learning these skills, strategies, and methods will establish an academic foundation for students to be successful in higher education. There are no prerequisites for this course. "
          },
          "D390": {
            "courseName": "Introduction to Health and Human Services ",
            "description": "Introduction to Health and Human Services explores representative roles and responsibilities of health and human service professionals and key governmental entities involved in Health and Human Services delivery. The course also examines the importance of understanding clients’ illnesses and disabilities, building trust with clients, and engagement models that promote client outcomes. There are no prerequisites for this course. "
          },
          "D391": {
            "courseName": "Healthcare Ecosystems ",
            "description": "Healthcare Ecosystems examines how the aims and elements of the healthcare ecosystem can affect client and patient outcomes. The course explores the main aims of healthcare access, affordability, and quality and how regulators, providers, producers, and funders (such as payors or purchasers) support those aims. The course also examines insurance regulations and reimbursement procedures that affect healthcare access and affordability and decision-making processes that support affordable, quality care for clients and communities. There are no prerequisites for this course. "
          },
          "D392": {
            "courseName": "Interdisciplinary Team Dynamics ",
            "description": "Interdisciplinary Team Dynamics explores interpersonal communication strategies, collaborative team interactions methods, and problem-solving techniques to promote effective communication and improve quality client outcomes in a team environment. There are no prerequisites for this course. "
          },
          "D393": {
            "courseName": "History of Healthcare in America ",
            "description": "History of Healthcare in America will examine individuals such as Henrietta Lacks, Ryan White, Clara Barton, and Katie Beckett, who influenced healthcare in the United States, from its inception to the present day. This course examines how specific individuals and their contributions influenced healthcare delivery and the continued evolution of healthcare, teaching from a system or a value-based care perspective. The course also focuses on the way healthcare interacted with culture, politics, and society throughout U.S. history and evaluates current challenges we face in the U.S. healthcare system today. There are no prerequisites for this course. "
          },
          "D394": {
            "courseName": "Care for Individuals and Families ",
            "description": "Care for Individuals and Families focuses on the holistic care of individuals, families, and populations with multifaceted healthcare needs. This course improves critical thinking and interdisciplinary communication skills to provide information to individuals or groups in a variety of settings. The focus of the course is on managing the transition of an individual, family, or group through a variety of healthcare settings, which can include acute care hospitals, extended stay facilities, ambulatory care clinics, home care, outreach, or wellness. This course helps students develop effective professional communication skills and appropriate behaviors to ensure an individual, family, or group is successful in meeting its healthcare goals. There are no prerequisites for this course. "
          },
          "D395": {
            "courseName": "Cultural Awareness ",
            "description": "Cultural Awareness is a course for the healthcare professional providing learners with the understanding of what it means to have personal, explicit and implicit cultural biases and how they can affect client outcomes in the health and human services industry. The course also will explore strategies for responding to personal biases and for promoting cultural awareness in health and human services. Through critical readings, videos, and activities, the learner will gain knowledge in this essential subject. There are no prerequisites for this course. "
          },
          "D396": {
            "courseName": "Evidence-Based Practice for Health and Human Services ",
            "description": "Evidence-Based Practice for Health and Human Services prepares the learner to apply evidence-based practice (EBP) to inform healthcare recommendations in out-patient, organizational, and other public health settings. Learners will be introduced to an EBP framework to guide them through the steps of EBP using real world scenarios. There are no prerequisites for this course.    "
          },
          "D397": {
            "courseName": "Health Equity and Social Determinants of Health ",
            "description": "Health Equity and Social Determinants of Health examines the social determinants of health (SDOH) as underlying causes of health inequity in populations and communities and their effect on health outcomes. This course will help students understand the evidence-based strategies that address the negative impact of inequities caused by the SDOH and analyze approaches to promote health equity. There are no prerequisites for this course. "
          },
          "D398": {
            "courseName": "Introduction to Pharmacology ",
            "description": "Introduction to Pharmacology will introduce learners to medication and supplement regulations and safety protocols. It provides an overview of the use, benefits, effects, and contraindications of commonly used drugs to treat conditions of the cardiovascular, respiratory, endocrine, nervous, and renal body systems. It also explores the types of anti-infective, antineoplastic, psychotropic drugs, and dietary supplements and their effects on the body. "
          },
          "D399": {
            "courseName": "Introduction to Gerontology ",
            "description": "Introduction to Gerontology will introduce learners to health issues that are typically associated with the older adult population so they can become familiar with health challenges this population may face. The learners will gain an understanding of the effects that policy and legislation have on the older adult population. Emphasis is placed on the importance of maintaining the dignity of older adults by focusing on cultural and communication needs, and by collaborating on care with older adults, families, and caregivers. There are no prerequisites for this course. "
          },
          "D400": {
            "courseName": "End-of-Life Care ",
            "description": "End-of-Life Care focuses on the Connected Care model as it applies to the final stage of life. This course will explore ethnic and cultural factors that affect an individual’s response to death and dying. This course will cover planning and implementing ideal interventions to help individuals, families, and groups cope and agree on a common care goal. This course will also discuss empathy and compassion in healthcare. There are no prerequisites for this course. "
          },
          "D401": {
            "courseName": "Introduction to Epidemiology ",
            "description": "Introduction to Epidemiology provides an overview of the determinants of communicable, viral, and chronic diseases. Students also will study various other conditions and the impact to public health. Using problem-based inquiry, students will analyze realworld public health problems by examining the distribution and patterns of data, selecting the methods to gather evidence, interpreting the information, and analyzing the trends to support decision making. There are no prerequisites to this course, but students are highly encouraged to adhere to the standard path, whose content is scaffolded to enhance the learning experience of this course. "
          },
          "D402": {
            "courseName": "Community and Public Health ",
            "description": "Community and Public Health provides learners with an understanding of the benefits community health offers individuals and families. The course also will identify barriers that will impact health and healthcare access, leading to improved community health. There are no prerequisites for this course. "
          },
          "D403": {
            "courseName": "Understanding Substance Abuse and Addiction ",
            "description": "Understanding Substance Abuse and Addiction provides an overview of the causes, signs and symptoms of substance abuse and addiction, and the impact on individuals, groups, and the community. Learners will evaluate educational prevention programs for a variety of target audiences and settings and evaluate evidence-based assessments and interventions for successful outcomes. There are no prerequisites for this course. "
          },
          "D404": {
            "courseName": "Healthcare Values and Ethics ",
            "description": "Healthcare Values and Ethics requires students to synthesize an interdisciplinary approach to decisionmaking as it applies to health and human services. This course explores the contemporary issues facing health professionals, which include ethics, regulations and compliance, and handling protected healthcare information. In this course, learners will develop their ability to make ethical decisions in collaborative care environments and working within a team. There are no prerequisites for this course. "
          },
          "D405": {
            "courseName": "Financial Resource Management and Healthcare Reimbursement ",
            "description": "Financial Resource Management and Healthcare Reimbursement examines financial practices and reimbursement types within the healthcare industry. This course covers the analysis of regulations required for health reimbursements. This course also covers the evaluation of effective revenue cycle management, focusing on the organization’s financial stability. There are no prerequisites for this course. "
          },
          "D406": {
            "courseName": "Health Literacy for the Client and Family ",
            "description": "Health Literacy for the Client and Family helps students recognize the importance of health literacy in overcoming healthcare barriers and creating patient-focused changes through family and patient empowerment. This course demonstrates how education, research, and technology all integrate and serve as a foundation for students as they create effective resources to improve health literacy for patients and families. This course helps students become advocates for their patients and their patients’ families. There are no prerequisites for this course. "
          },
          "D407": {
            "courseName": "Models of Care and Healthcare Trends ",
            "description": "Models of Care and Healthcare Trends is a course for health professionals in a variety of roles in the health and human services industry, which examines the unique characteristics of healthcare models in the United States and emerging trends created by social and political drivers. The course explores the evolution of healthcare models from fragmented systems to cohesive, quality-centric, and client-focused systems. The course also focuses on innovative trends, such as access to care, telemedicine, and subsequent shifts in the continuum of care as it relates to patient or client outcomes. There are no prerequisites for this course. "
          },
          "D408": {
            "courseName": "Community Relations and Leadership ",
            "description": "Community Relations and Leadership focuses on analyzing community health and human services’ needs to create change. As emerging leaders, students will learn to engage in collaborative approaches with various stakeholders to achieve positive outcomes. This course helps students develop their abilities to interpret community health needs assessments, make decisions, and bring stakeholders together to advance access to health and human services. This course has no prerequisites. "
          },
          "D409": {
            "courseName": "Health and Human Services Professional Field Experience ",
            "description": "The Health and Human Services Professional Field Experience course provides students with real-world experiences as a health services professional via the virtual world of simulation. The course allows students to conduct their field experience in a variety of different contexts they will find themselves, depending on their professional career choices in the health services’ industry. All program coursework leads to this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
          },
          "D410": {
            "courseName": "Health & Human Services Professional Capstone ",
            "description": "Health Services Professional Capstone will provide learners the opportunity to demonstrate their ability to communicate in a professional manner that supports high quality, safe client services. Learners will also engage in career and professional development within the health and human services industry. All program coursework leads to this course. "
          },
          "D411": {
            "courseName": "Scripting and Automation ",
            "description": "Scripting and Automation is the foundation for automating tasks in operating systems. Students will learn how to create PowerShell scripts that take tedious and repetitious tasks and turn them into programs that will save time. Students will learn PowerShell, an automation and configuration management tool based on a command-line shell and .NET Framework. "
          },
          "D412": {
            "courseName": "Network Analytics and Troubleshooting ",
            "description": "Network Analytics and Troubleshooting teaches students to use network monitoring and analytics tools and practices that are common in the workplace in order to troubleshoot and fix complex computer networks. Students will follow a customer service model in identifying, classifying, investigating, and repairing network outages or problems. This course is designed as a hands-on experience where students will implement these techniques in a virtual space in order to produce a secure and functional deployed network. "
          },
          "D413": {
            "courseName": "Telecomm and Wireless Communications ",
            "description": "Telecomm and Wireless Communications explores the science, technologies, and standards that enable wired and wireless data to be transmitted across different media. Topics include data encoding and decoding, and analog and digital transmissions via wired, fiber, wireless, cellular, and satellite technologies. "
          },
          "D414": {
            "courseName": "Cyber Operations Fundamentals ",
            "description": "In Cyber Operations Fundamentals, students will learn security concepts, security monitoring, host-based analysis, network intrusion analysis, and security policies and procedures using Cisco practices and technologies. This course prepares students for the Understanding Cisco Cybersecurity Operations Fundamentals (CBROPS) 200-201 exam.  "
          },
          "D415": {
            "courseName": "Software Defined Networking ",
            "description": "Software-Defined Networking (SDN) represents one of the fastest growing areas of network engineering. This course instructs learners on the SDN paradigm, which encompasses network automation, intent-based networking, and centralized network control. This course also teaches learners to view networking from a centralized and automated perspective rather than the traditional device-by-device model that is the legacy practice in many networks. "
          },
          "D416": {
            "courseName": "DevNet Fundamentals ",
            "description": "The DevNet Fundamentals course teaches students how to automate and deploy network solutions in the Cisco Environment. This course includes APIs, Scripting, Python programming, and software version control. This course prepares students for the Cisco DevNet Associate (DevNet) 200-901 exam. "
          },
          "D417": {
            "courseName": "Network Automation and Deployment ",
            "description": "Network Automation and Deployment leverages previous experience in networking, scripting, and programming with the SDN paradigm. Students will create programs and scripts that automate network configuration across large networks. This course is designed as a hands-on experience where students will implement these techniques in a virtual space in order to produce a secure and functional deployed network. "
          },
          "D418": {
            "courseName": "BSNES Capstone Project ",
            "description": "The BSNES Capstone Project consists of learners submitting a network design proposal, a virtual network implementation, and a post-implementation report describing their experience developing and implementing the capstone project. The capstone project and scope must be presented and approved by the capstone instructor prior to implementation in the virtual environment.  "
          },
          "D419": {
            "courseName": "Implementing and Administering Networking Solutions ",
            "description": "Implementing and Administering Networking Solutions expands on basic networking concepts and covers advanced network engineering skills including: Switch and router configuration, trouble shooting and maintenance on wired and wireless networks, Security, network automation and introduces Software Defined Networking. This course prepares students for the Cisco Certified Network Associate (CCNA) certification exam CCNA-200-301. "
          },
          "D420": {
            "courseName": "Discrete Math: Logic ",
            "description": "Discrete Math-Logic is designed to help students develop competence in the use of logic and proofs and Boolean Algebra and Boolean functions. Applied Probability and Statistics and Applied Algebra are prerequisites for this course. "
          },
          "D421": {
            "courseName": "Discrete Math: Functions and Relations ",
            "description": "Discrete Math: Functions and Relations is designed to help students develop competence in the use of abstract discrete structures fundamental to systems networking. In particular, this course will introduce students to set theory, finite sequences, series, and relations. Discrete Math: Logic, Applied Probability and Statistics, and Applied Algebra are prerequisites for this course. "
          },
          "D422": {
            "courseName": "Discrete Math: Algorithms and Cryptography ",
            "description": "Discrete Math: Algorithms and Cryptography addresses discrete computational methods, including searching and sorting algorithms, big-O estimates, and number theory and cryptography. Discrete Math Functions and Relations is a prerequisite for this course. "
          },
          "D423": {
            "courseName": "Spreadsheets ",
            "description": "Introduction to Spreadsheets helps learners become proficient in using spreadsheets to analyze business problems. In this course, learners will demonstrate competency in spreadsheet development and analysis for business applications (e.g., using essential spreadsheet functions, formulas, tables, charts, etc.). There are no prerequisites for this course. "
          },
          "D424": {
            "courseName": "Software Engineering Capstone ",
            "description": "The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
          },
          "D425": {
            "courseName": "Introduction to Chemistry ",
            "description": "In Introduction to Chemistry, learners will discover the impact of chemistry on everyday life. They’ll learn about the structure of the atom, study periodic trends, analyze the structure of molecules and their properties, and describe the importance of common functional groups within the periodic table. They’ll identify balanced chemical equations, describe types of chemical reactions and predict their products, and examine intermolecular forces and describe their impact on the properties of substances. Finally, they’ll study the properties of acids, bases, and buffer systems, and properties unique to liquids and gases. "
          },
          "D426": {
            "courseName": "Data Management ",
            "description": "Foundations - Data Management Foundations offers an introduction in creating conceptual, logical and physical data models.  Students gain skills in creating databases and tables in SQL-enabled database management systems, as well as skills in normalizing  databases. No prerequisites are required for this course "
          },
          "D427": {
            "courseName": "Data Management ",
            "description": "Applications - Data Management - Applications covers conceptual data modeling and introduces MySQL. Students will learn how to create simple to complex SELECT queries, including subqueries and joins, and how to use SQL to update and delete data. Topics covered in this course include exposure to MySQL; creating and modifying databases, tables, views, foreign keys and primary keys (FKs and PKs), and indexes; populating tables; and developing simple Select-From-Where (SFW) queries to complex 3+ table join queries. The following course is a prerequisite: Data Management - Foundations. "
          },
          "D428": {
            "courseName": "Design Thinking for Business ",
            "description": "Design Thinking for Business examines the design thinking methodology for solving complex problems. This course introduces students to design thinking as a human-centered approach to problem-solving and innovation that draws upon empathy and creativity to develop solutions to complex problems. Students will explore the principles and stages of design thinking and analyze the use of design thinking in developing solutions through real-world scenarios. "
          },
          "D430": {
            "courseName": "Fundamentals of Information Security ",
            "description": "This course lays the foundation for understanding terminology, principles, processes, and best practices of information security at local and global levels. It further provides an overview of basic security vulnerabilities and countermeasures for protecting information assets through planning and administrative controls within an organization. This course has no prerequisites. "
          },
          "D431": {
            "courseName": "Digital Forensics in Cybersecurity ",
            "description": "Digital Forensics in Cyber Security examines the relationships between incident categories, evidence handling, and incident management. This course teaches students to identify consequences associated with cyber threats and security laws using a variety of tools to recognize and recover from unauthorized, malicious activities and how to seek evidence that reveals who, what, when, where, and how threats compromise information. "
          },
          "D432": {
            "courseName": "HR Compliance and Employee Relations ",
            "description": "HR Compliance and Employee Relations provides students with an in-depth understanding of the relevant laws, regulations, and ethical issues related to human resource (HR) compliance and risk management. It also explores how to resolve and improve employee relations issues to maintain a positive organizational culture in a diverse workplace. Topics include business laws and ethical considerations, employment and labor laws and regulations, and employee relations strategies to build and maintain a positive, healthy, and respectful work environment. "
          },
          "D433": {
            "courseName": "Talent Acquisition and Development ",
            "description": "Talent Acquisition and Development provides an in-depth look at the strategies used to attract, retain, and develop qualified talent in an organization. Students discover how to hire the right talent to meet the needs of the organization, how to orient and onboard new employees, and how to ensure employee excellence through learning and development and performance management strategies. "
          },
          "D434": {
            "courseName": "Future Focused Total Rewards ",
            "description": "Future Focused Total Rewards examines discretionary and legally required approaches to compensation and benefits practices that compose an organization’s total rewards system. Students explore how to develop and communicate the components of a competitive total rewards strategy to prospective and existing employees while adhering to employment laws and aligning to an organization’s strategic goals and culture. "
          },
          "D435": {
            "courseName": "HR Technology and People Analytics ",
            "description": "HR Technology and People Analytics introduces students to the types of human resource information systems (HRISs), applications, and platforms used to capture and manage employee data and the analytics used to make strategic decisions based on that data. Students will discover how to plan for the implementation of new human resource (HR) technology, present the plan to stakeholders to gain buy-in and support for the change, and train employees in the new systems. Students will also gain an understanding of how to pull and use data and people analytics for effective storytelling, decision-making, and leadership influence. "
          },
          "D436": {
            "courseName": "Inclusive Workplace Culture Capstone ",
            "description": "Inclusive Workplace Culture Capstone provides students with the opportunity to work through the SHRM Inclusive Workplace Culture specialty credential course content, studying and analyzing how human resource (HR) professionals can integrate an inclusive approach to all HR functions such as talent acquisition, training and development, total rewards, and more. Students will complete a capstone project that synthesizes an inclusive approach to strategic HR practices for an organization to create an environment of true belonging for all employees, while simultaneously being prepared to complete the SHRM Inclusive Workplace Culture exam independently to earn a specialty credential and badge from SHRM. "
          },
          "D439": {
            "courseName": "Foundations of Nursing  ",
            "description": "Foundations of Nursing introduces students to the nursing process, scope of practice, clinical judgment model and fundamental concepts of holistic nursing practice that will serve the needs of diverse adult patients across the lifespan. The course will focus on medical terminology, legal/ethical issues, basic care and comfort, oxygenation, safety & infection control, health and wellness, fluid & electrolytes, death and dying, therapeutic communication, patient education & advocacy.     Co-requisites: C957, D202, D236, and D441.    Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms. "
          },
          "D440": {
            "courseName": "Health and Wellness Through Nutritional Science ",
            "description": "The health and wellness through nutritional science course prepares nursing students to learn the basic principles of nutrition, nutrition throughout the life cycle, nutrition related to weight management and physical health, and nutrition related to patient conditions. Students will learn how nutrition influences a patient's overall health status across the life span. "
          },
          "D441": {
            "courseName": "Medical Dosage Calculations and Pharmacology  ",
            "description": "Medical Dosage Calculations and Pharmacology introduces an in-depth nursing approach to medication administration concepts, legal & ethical principles, pharmacological principles, variety of drug classifications, complementary & alternative therapies needed to care for diverse patients across the lifespan. "
          },
          "D442": {
            "courseName": "Basic Nursing Skills ",
            "description": "Basic Nursing Skills will introduce foundational principles of nursing process and the clinical judgement model, health assessment techniques, and communication skills needed to care for diverse adult patients across the lifespan. Skills will focus on the concepts of vital signs, medication administration, infection control, nutrition, elimination, mobility, oxygenation, and skin integrity. Students are required to be successful on lab assessments to progress to Adult Health1 clinical. Co-requisites: D443 and D444 "
          },
          "D443": {
            "courseName": "Health Assessment ",
            "description": "The Health Assessment course focuses on concepts and skills necessary to collect a comprehensive health history and perform a head-to-toe and focused assessments on diverse patients across the lifespan. The emphasis will be to differentiate between normal and abnormal findings of various body systems such as the following: integumentary, head & neck, eyes & ears, respiratory, cardiovascular, gastrointestinal, renal, musculoskeletal, nervous, and reproductive systems. Corequisites are D442 and D444. "
          },
          "D444": {
            "courseName": "Adult Health I ",
            "description": "Adult Health I prepares students to provide safe, equitable, high quality medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of common conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on medical surgical nursing care related to head & neck, skin, inflammation, infection, fluid & electrolytes, respiratory, gastrointestinal, hepatic, genitourinary, and reproductive systems. The nursing process and the clinical judgement model will be used as the foundation to navigate the management of care for patients. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisite: D443.   "
          },
          "D445": {
            "courseName": "Intermediate Nursing Skills ",
            "description": "Intermediate Nursing Skills will build on basic nursing skill concepts to develop intermediate medical surgical nursing practice, including peripheral intravenous access, blood administration, airway management, perioperative and postoperative care, and wound care management. The course focuses on nursing care of both adult and pediatric populations, including a focus on the care of women and the maternal care setting. Students will use simulation to apply the clinical judgment model to various diverse populations in various care settings. Students are required to be successful on course performance assessments to progress to Adult Health II clinical. Co-requisites: D446 & D447 "
          },
          "D446": {
            "courseName": "Adult Health II   ",
            "description": "Adult Health II prepares students to provide safe, equitable, high quality medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of both acute and chronic conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on medical surgical nursing care related to perioperative nursing, neurological, hematological, renal, cardiovascular, endocrine, and musculoskeletal systems. The nursing process and clinical judgement model will be used for clinical decision-making and fostering health promotion and maintenance. A variety of populations and settings are used in the experiential learning components of this course.      Co-requisite: D447.   Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms and D445. "
          },
          "D447": {
            "courseName": "Women's and Children's Nursing ",
            "description": "Women's and Children's Nursing prepares students to provide safe, equitable, high-quality nursing care, pharmacological care, and emotional support for diverse women and pediatric populations. This course focuses on antepartum, intrapartum, postpartum, neonatal clinical nursing, and women's health. This course builds on growth and development of children, nursing care for children and adolescents with acute and chronic alterations of the respiratory, cardiovascular, hematologic, endocrine, reproductive, gastrointestinal, renal, neurologic, musculoskeletal, and integumentary systems. This course explores how social determinants of health impact health risk and outcomes in women and pediatric populations. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisites: D445 and D446.   Prerequisites: All prelicensure nursing curriculum courses from previous terms. "
          },
          "D449": {
            "courseName": "Psychiatric and Mental Health Nursing ",
            "description": "Psychiatric and Mental Health Nursing prepares students to provide safe, equitable, high-quality care using modern concepts of psychiatric and mental health nursing. The student will utilize therapeutic communication to a diverse population of patients including those with maladaptive behaviors through the utilization of the nursing process by applying the principles of psychiatric and mental healthcare and the clinical judgement model. This course explores the nurse-client relationship, pharmacological management, cognitive conditions, bipolar and thought conditions, personality disorders, substance abuse, eating disorders, and self-harm. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisites: D450.   Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms and D445.   "
          },
          "D450": {
            "courseName": "Community Health and Population-Focused Nursing ",
            "description": "Community health and population focused nursing concentrates on the theory and concepts of community, public and global health nursing that impact diverse communities. Students learn the role of the community health nurse, learn to assess the community’s healthcare needs, available resources, epidemiology, substance abuse, disaster management, and how social determinants of health impact community and public health risk and outcomes. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisite: D449.   "
          },
          "D453": {
            "courseName": "Advanced Nursing Skills ",
            "description": "Advanced Nursing Skills will build on intermediate skills and focus on advanced skills related to critical care nursing practice, including closed chest drainage systems, electrocardiograms (EKGs), palliative care, ventilators, disaster management and transition to practice. Clinical judgement and problem solving are emphasized in the assessment of critically ill patients and prioritizing patients’ needs and nursing interventions. Students are required to be successful on course performance assessments to progress to Adult Health III clinical. Corequisites: D454 & D455 "
          },
          "D454": {
            "courseName": "Adult Health III ",
            "description": "Adult Health III prepares students to provide safe, equitable, high quality complex medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of both acute and chronic conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on caring for patients with potentially life-threatening alterations of the respiratory, cardiovascular, endocrine, and neurologic, renal, hepatic systems, end of life care (palliative), shock and transplants. Clinical judgment and problem solving are emphasized in the assessment of critically ill patients and prioritizing patient ‘s needs and nursing interventions. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisites: D455 "
          },
          "D455": {
            "courseName": "Professional Nursing Role Transition ",
            "description": "The Professional Nursing Role Transition course builds on the previous knowledge gained in all other nursing courses. The emphasis of this course is placed on the personal and professional strategies needed to make the transition from student to graduate nurse by highlighting the role and skills of bedside nurse leaders. The course will review content related to leadership and management, foundational nursing, advanced clinical, medical surgical, pediatric, women’s, and mental health concepts. The students will complete a professional portfolio that showcases their accomplishments, knowledge, and skills throughout the program. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisite: D454    "
          },
          "D458": {
            "courseName": "Introduction to Systems Thinking for Health Professionals ",
            "description": "Introduction to Systems Thinking for Health Professionals provides learners with the skills required to engage in a holistic systems-based approach to analyzing complex problems and solutions. This course introduces the foundational concepts and principles of systems thinking and provides opportunities to use a systems thinking approach to analyze and evaluate realworld case studies. The course will culminate with using systems thinking to develop a solution to an authentic complex problem. This course has no prerequisites, but general education math (C955 or C957) is preferred. Because the course is self-paced, learners may move through the material as quickly or as slowly as needed, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If learners have no prior knowledge of this material, they can expect to spend 30 to 40 hours on the course content.   "
          },
          "D464": {
            "courseName": "Managing Operations ",
            "description": "Managing Operations examines management systems and processes to improve operating efficiency. In this course, students will be introduced to operations management concepts and will learn how analysis of these systems and processes can improve operating efficiency across the organization. Students will explore ethical and sustainable operations management strategies and will analyze operating processes for continuous improvement. "
          },
          "D465": {
            "courseName": "Data Applications ",
            "description": "Data Applications examines the principles and techniques used to effectively analyze data to answer questions and provides foundational knowledge of R and RStudio environments. This course teaches students how to aggregate; how to format and adjust data using spreadsheets and SQL; how to use formulas and functions to perform calculations in SQL; how to organize, transform, clean, and analyze data in R; and how to create visualizations in R Markdown. Upon completion of this course, students will earn the Whalley Business Analysis Professional certificate and may also choose to earn the Google Data Analytics Professional certificate. "
          },
          "D466": {
            "courseName": "Analyzing and Visualizing Data ",
            "description": "Analyzing and Visualizing Data examines the principles and techniques used to effectively analyze data to answer questions and share data through the art of visualization. This course explores how to use formulas and functions to perform calculations, how to design and create visualizations and dashboards in Tableau, and how to build an effective data presentation that considers limitations associated with the data and best practices for audience considerations. This course provides students with an opportunity to demonstrate an understanding of what is involved in the conversion and formatting of data and apply the use of functions and syntax to create SQL queries for combining data from multiple database tables. The course D467: Exploring Data, which also includes Google certificate materials, is a prerequisite. "
          },
          "D467": {
            "courseName": "Exploring Data ",
            "description": "Exploring Data builds proficiency with data, including the organization, preparation, transformation, cleaning, and verification of data. This course examines how to apply critical thinking, spreadsheet, and structured query language techniques to data management and decision-making. Students may simultaneously work toward the Google Data Analytics Professional Certificate and the Whalley Business Analysis Certificate. The course D468: Discovering Data, which also includes Google certificate materials, is a prerequisite. "
          },
          "D468": {
            "courseName": "Discovering Data ",
            "description": "Discovering Data introduces analytical concepts, processes, and tools used in the field of business analytics. This course explores the importance of asking effective questions to collect the right data. Students will examine using analytical thinking to organize, analyze, and share data to drive decision-making. This course provides students the opportunity to work toward the Google Data Analytics Professional Certificate and the Whalley Business Analysis Certificate. Introduction to Spreadsheets is a prerequisite for this course. "
          },
          "D469": {
            "courseName": "Quality, Continuous Improvement, and Lean Six Sigma ",
            "description": "Quality, Continuous Improvement, and Lean Six Sigma examines how organizations can measure and improve the quality of products, goods, and services. Students consider different dimensions and characteristics of quality and are introduced to a variety of strategies and tools that are used to manage quality and measure performance. This course explores how to apply continuous improvement processes by analyzing the Lean Six Sigma DMAIC (define, measure, analyze, improve, and control) method. C955: Applied Probability and Statistics is a prerequisite for this course. "
          },
          "D470": {
            "courseName": "Transportation, Logistics, and Distribution ",
            "description": "Transportation, Logistics, and Distribution examines logistics and the planning and management of transportation and distribution. This course introduces requirements and risks, facilities and inventory, strategy and supply chain synchronicity, efficiencies and costs, and laws and regulations related to transportation, distribution, and logistics. Students will explore warehousing and warehouse management, inventory and logistics management, distribution, and supply chain management from the lens of transportation, logistics, and distribution. This course is aligned with the Certified in Logistics, Transportation, and Distribution (CLTD) certification from the Association for Supply Chain Management. "
          },
          "D471": {
            "courseName": "Global Supply Chain Management ",
            "description": "Global Supply Chain Management introduces a broad range of supply chain management concepts. Students consider supply chains and supply chain management strategies, including planning and design, risk management, and global and sustainable supply chain networks and management. Students learn about managing important relationships and their interdependencies. Students also investigate how supply chain costs affect consumers, quality and continuous improvement, and the role of the sales and operations planning (S&OP) process.    Portions of this course are aligned with aspects of ASCM APICS certification programs, namely the CSCP and the CPIM. "
          },
          "D472": {
            "courseName": "21st Century Operations and Supply Chain ",
            "description": "21st Century Operations and Supply Chain explores modern issues in supply chain management. Building on the supply chain landscape introduced in prior courses, this course addresses more complex supply chain and operations issues. The course examines how disruptions transform operations and the supply chain and how to use qualitative and quantitative data to evaluate solutions. "
          },
          "D473": {
            "courseName": "Solutions Design and Visualization Capstone ",
            "description": "Solution Design and Visualization Capstone guides learners to synthesize and apply the skills learned throughout their business analytics, operations, and supply chain education. This course gives learners the opportunity to solve challenges in procurement, sales and operations planning (S&OP) processes, distribution, logistics and transportation faced by a fictional company. In the course capstone project, learners play the role of an operations and supply chain manager or consultant, redesigning the existing supply chain to implement lean processes and using Six Sigma methodology to improve efficiency and allow the company to bring new products or services to market faster. Learners in this course address a real operations and supply chain problem and design solutions, which they communicate in a report and a presentation. At the end of the course, learners will have an authentic experience they can add to their portfolio and show employers. Working through this capstone helps learners to understand how their knowledge interacts with real situations and how roles fit within the industry. The D472: 21st Century Operations and Supply Chain course is a prerequisite. "
          },
          "D479": {
            "courseName": "User Experience Design ",
            "description": "User Experience Design explores multiple tools and techniques used in user experience design. Students are presented with an in-depth view of activities involved in the design of user experience and have the opportunity to create several deliverables including persona profiles, information architectures, and prototypes of different levels of fidelity. In addition, the course also covers usability testing and the evaluation of quantitative and qualitative data derived from these and other experiments. "
          },
          "D480": {
            "courseName": "Software Design and Quality Assurance ",
            "description": "Software Design and Quality Assurance applies a QA focus to every phase of the software development life cycle. This course investigates best practices for quality analysis, quality planning, and testing strategies as they pertain to the everyday practice of software development. Students will come to understand how their work fits into the bigger picture: how QA, testing, and codewriting practices interact within specific process models; the potential impact of new code on existing code or on other applications; the importance of usability and the influence users have on the ultimate success of an application. Students will explore test plans, test cases, unit tests, integration tests, regression tests, usability tests, and test and review tools. "
          },
          "D481": {
            "courseName": "Security Foundations ",
            "description": "Security Foundations lays the foundation for understanding terminology, principles, processes, and information security best practices at local and global levels. This course further provides an overview of networking components, network security vulnerabilities, and countermeasures for protecting information assets through planning and administrative controls within an organization. "
          },
          "D482": {
            "courseName": "Secure Network Design ",
            "description": "Secure Network Design provides the foundational knowledge and skills to design secure physical and logical network architectures for wired and wireless networks. Course topics include the characteristics of a secure network, techniques to securely configure network devices, network segmentation strategies, root cause analysis, and mitigation approaches based on industry best practices. The course also offers hands-on experience in network vulnerability analysis and network configuration. "
          },
          "D483": {
            "courseName": "Security Operations ",
            "description": "Security Operations provides learners with the fundamental skills to handle and respond to computer security incidents in an information system. The course addresses various underlying principles and techniques for detecting and responding to current and emerging computer security threats. The course also helps learners explore strategies to leverage intelligence and threat detection techniques, analyze and interpret data, identify and address vulnerabilities, and suggest preventative measures. Methods are introduced to effectively respond to and recover from cybersecurity incidents, evaluate risk assessment methodologies, and apply incident handling laws and policies. "
          },
          "D484": {
            "courseName": "Penetration Testing ",
            "description": "Penetration Testing introduces learners to the skills necessary to perform penetration testing and vulnerability management within an organization. The course covers widely used penetration testing techniques and tools that focus on planning and scoping, information gathering, vulnerability identification, and attacks and exploits. In addition, it offers hands-on experience and a focus on penetration testing engagement plans. "
          },
          "D485": {
            "courseName": "Cloud Security ",
            "description": "Cloud Security prepares learners to design solutions for cloud-based platforms and operations that maintain data availability while protecting the confidentiality and integrity of information. Course topics include cloud service models, deployment methods, identity and access management (IAM) strategies, auditing and monitoring strategies, assessing and mitigating common cloud security threats, and managing compliance and regulation requirements. The course also offers hands-on experience deploying and assessing IAM controls in a cloud environment. "
          },
          "D486": {
            "courseName": "Governance, Risk, and Compliance ",
            "description": "Governance, Risk, and Compliance provides learners with advanced skills and knowledge to authorize and maintain information systems utilizing various risk management frameworks. The course focuses on the strategic and long-term alignment of an organization's information security program to regulatory requirements and organizational policies. Course topics include compliance and regulatory requirements, data classification and prioritization, security and privacy controls, compliance audits and remediation, and risk management plans. "
          },
          "D487": {
            "courseName": "Secure Software Design ",
            "description": "Secure Software Design focuses on the variety of elements needed to address and implement secure software acquisition and development throughout the software development life cycle (SDLC). The course addresses people, technology, tools, and processes to design and develop consistently secure applications from start to finish. Additionally, it underscores the importance and value of the Defense in Depth principle across the entire SDLC. The course also introduces techniques to adapt common security activities to modern software development practices such as Agile and DevSecOps. "
          },
          "D488": {
            "courseName": "Cybersecurity Architecture and Engineering ",
            "description": "Cybersecurity Architecture and Engineering provides learners with advanced skills and knowledge to design secure enterprise architecture solutions. The course focuses on assessing cybersecurity readiness and implementing enterprisewide solutions to protect data and comply with an organization's policies and frameworks. Course topics include integrating software applications, applying enterprise data security controls, evaluating cloud and virtualization solutions, analyzing threats and vulnerabilities, and responding to incidents. "
          },
          "D489": {
            "courseName": "Cybersecurity Management ",
            "description": "Cybersecurity Management prepares learners to develop organizational information security programs and policies that follow recognized standards, comply with all governing laws and regulations, and meet the needs of the company culture and management organization. The course covers how to perform risk management institutionally, how to manage compliance to information security requirements, and how to delegate compliance, risk, and security functions to specific roles within the organization. It also helps learners apply strategic decision-making as companies adapt to new technologies, processes, and people practices related to processing, managing, and protecting information resources. "
          },
          "D490": {
            "courseName": "Cybersecurity Graduate Capstone ",
            "description": "The Master of Science in Cybersecurity and Information Assurance (MSCSIA) Capstone project allows learners to demonstrate their capability to establish a durable cybersecurity and information assurance program. The capstone project challenges learners to integrate skills and knowledge from all program domains into one project that addresses a significant real-world cybersecurity problem. "
          },
          "D491": {
            "courseName": "Introduction to Analytics ",
            "description": "Analytics is the creative use of data and statistical modeling to tell a compelling story that not only drives strategic action, but also results in business value. Introduction to Analytics examines data analytics as a discipline and the various roles and functions within the field. You will expand your knowledge about what analytics is and develop a basic understanding of statistics, analysis, problem solving, and programming concepts. "
          },
          "D492": {
            "courseName": "Data Analytics ",
            "description": "Applications - Data Analytics Applications covers advanced concepts across the various phases of the data product lifecycle. You will learn to choose and apply appropriate techniques for data management and data manipulation, statistical analysis, visualization, and data governance concepts to satisfy business needs. "
          },
          "D493": {
            "courseName": "Scripting and Programming ",
            "description": "Applications - Scripting and Programming - Applications for undergraduates explores the various aspects of the Python programming language by examining its syntax, the development environment, and tools and techniques to solve some real-world problems. Introduction to Programming in Python is a prerequisite for this course.  "
          },
          "D494": {
            "courseName": "Data and Information Governance ",
            "description": "Data and Information Governance provides learners with the knowledge that establishing rules of engagement, policies, procedures, and data stewardship is essential to exercising organizational control over—and extracting maximum value from— its data assets. Good data governance helps an organization lower costs, create efficiencies, and achieve its strategic goals and objectives. Data governance provides a framework for properly managing information across the entire data lifecycle and establishes strategies in support of disaster recovery and continuity of operations. This course will prepare IT professionals to assist their organization in the definition and implementation of best practices related to the planning and implementation of managed systems that meet business, technical, security, auditing, disaster recovery, and business continuity requirements. "
          },
          "D495": {
            "courseName": "Big Data Foundations ",
            "description": "Big Data Foundations provides an in-depth introduction to big data concepts, terminology, and applications. You will learn the risks and challenges of working with extremely large data sets. The course introduces tools and techniques for working with big data. The course covers selection criteria for relational and non-relational data architectures and cloud-native data storage concepts. It also provides a historical perspective on the evolution of big data storage approaches. Data warehousing, data lakes, and data lakehouses are introduced, and design principles for each are explained. Learners design aspects of big data architecture and big data processing to address given business requirements. "
          },
          "D496": {
            "courseName": "Introduction to Data Science ",
            "description": "Introduction to Data Science introduces the data analysis process and common statistical techniques necessary for the analysis of data. Students will ask questions that can be solved with a given data set, set up experiments, use statistics and data wrangling to test hypotheses, find ways to speed up their data analysis code, make their data set easier to access, and communicate their findings. "
          },
          "D497": {
            "courseName": "Data Wrangling ",
            "description": "Data Wrangling elaborates on concepts covered in Introduction to Data Science, helping to develop skills crucial to the field of data science and analysis. It explores how to wrangle data from diverse sources and shape it to enable data-driven applications—a common activity in many data scientists' routine. Topics covered include gathering and extracting data from widely-used data formats, assessing the quality of data, and exploring best practices for data cleaning. "
          },
          "D498": {
            "courseName": "Data Analysis with R ",
            "description": "Data Analysis with R focuses on exploratory data analysis (EDA) utilizing R. EDA is an approach for summarizing and visualizing the important characteristics of a data set. In this course you will develop skills in R programming to acquire and load data sets, create appropriate statistical summaries of data, and create data visualizations to help uncover and communicate insights about data using R.  "
          },
          "D499": {
            "courseName": "Machine Learning ",
            "description": "Machine Learning presents the end-to-end process of investigating data through a machine learning lens. Topics covered include: supervised and unsupervised learning algorithms, features that best represent data, commonly-used machine learning algorithms, and methods for evaluating the performance of machine learning algorithms.   "
          },
          "D500": {
            "courseName": "Data Visualization ",
            "description": "Data Visualization covers the application of design principles, human perception, color theory, and effective storytelling in the context of data visualization. It addresses presenting data to others and advancing technology with visualization tools enabling data scientists to share their findings and support organizational decision-making processes. Additionally, this course focuses on how to visually encode and present data to an audience.  "
          },
          "D501": {
            "courseName": "Machine Learning DevOps ",
            "description": "Machine Learning DevOps focuses on the software engineering fundamentals needed to successfully streamline the deployment of data and machine learning models in a production-level environment. Students will build the DevOps skills required to automate the various aspects and stages of machine learning model building and monitoring over time. "
          },
          "D502": {
            "courseName": "Data Analytics Capstone ",
            "description": "The Data Analytics Undergraduate Capstone challenges students to demonstrate competencies supporting all BSDA program outcomes. Students will identify an organizational need, plan and develop a data analytics product to serve that need, and document the process in a project proposal and data project report.  "
          },
          "D509": {
            "courseName": "Innovative Solutions in Healthcare Leadership ",
            "description": "Innovative Solutions in Health Administration provides an opportunity to explore healthcare innovations through comparison research, the application of disruptive leadership concepts, and advanced technology applications. Students will apply strategic innovation concepts to improve critical patient dissatisfiers in a healthcare setting. This course has no prerequisites. "
          },
          "D510": {
            "courseName": "Collaborative Leadership ",
            "description": "Collaborative Leadership provides an opportunity to apply collaborative leadership skills to better serve diverse communities. Students will develop a process innovation with community leaders in a diverse population emphasizing a cultural competence. This course has no prerequisites.   "
          },
          "D511": {
            "courseName": "Healthcare Models and Systems ",
            "description": "Healthcare Models and Systems provides an opportunity to analyze the evolution of healthcare models and systems. Students will apply administration strategies to manage organizational changes and community affiliations. This course has no prerequisites. "
          },
          "D512": {
            "courseName": "Quality Improvement in Healthcare ",
            "description": "Quality Improvement in Healthcare provides an opportunity to apply quality improvement principles and strategies in a high-volume Level 1 trauma center. Students will apply disruptive leadership strategies to implement quality-improvement procedures in a fast-paced healthcare environment. This course has no prerequisites. "
          },
          "D513": {
            "courseName": "Healthcare Financial Management ",
            "description": "Healthcare Financial Management provides an opportunity to apply strategic change management principles through the application of fiscal management and data analysis in a healthcare environment. The student will examine strategies to increase value, sustainability, and productivity in a patient-centric environment. This course has no prerequisites. "
          },
          "D514": {
            "courseName": "Analytical Methods of Healthcare Leaders ",
            "description": "Analytical Methods of Healthcare Leaders provides an opportunity to explore the use of predictive analysis and forecasting techniques to develop evidence-based decision making. Students will apply quality research and analytical analysis to inform decisions in a health management environment. This course has no prerequisites. "
          },
          "D515": {
            "courseName": "Enterprise Risk Management ",
            "description": "Enterprise Risk Management provides an opportunity to examine risk exposure and response, and risk mitigation within an integrated care delivery model. Students will apply practices to identify risks and develop sustainable corrective action plans. This course has no prerequisites. "
          },
          "D516": {
            "courseName": "Healthcare Information Technology ",
            "description": "Healthcare Information Technology provides an opportunity to examine the use of technology in data analysis and applications to improve outcomes in a patient-centered care environment. Students will apply strategic analysis to improve technology function and interoperability within a community healthcare cooperative. This course has no prerequisites. "
          },
          "D517": {
            "courseName": "Population Healthcare Coordination ",
            "description": "Population Healthcare Coordination provides an opportunity to examine population healthcare strategies and community collaboration to impact at-risk demographic groups. Students will apply strategic change management and data analysis to develop health initiatives for a large-scale population. This course has no prerequisites. "
          },
          "D518": {
            "courseName": "Challenges in Community Healthcare ",
            "description": "Challenges in Community Healthcare provides an opportunity to explore organizational leadership and administration as well as problem-solving methods to collaborate with community leaders in a high-stakes healthcare environment. Students will apply collaborative leadership skills and evidence-based practices as they develop community relationships to resolve critical issues in community health management. This course has no prerequisites.   "
          },
          "D519": {
            "courseName": "Integrated Healthcare Leadership and Administration ",
            "description": "Integrated Healthcare Leadership and Administration provides an opportunity to examine integrated healthcare delivery systems and person-centered care models for innovative solutions to critical challenges. The student will apply principles of collaborative leadership, disruptive change, and catalyst evaluation to develop a holistic integrated healthcare system. This course has no prerequisites. "
          },
          "D520": {
            "courseName": "Healthcare Leadership and Administration Capstone ",
            "description": "The capstone is a student-designed project intended to illustrate your ability to effect change in the industry and demonstrate competence in all five program outcomes: transformational leader, value innovator, tactical manager, analyst,  and integrated systems expert. Students are required to collaborate with leaders in the healthcare industry to identify opportunities for improvement in healthcare, propose a solution, and perform a business analysis to evaluate its feasibility. In addition, the capstone encourages work in the healthcare industry that will be showcased in the student’s collection of work and help solidify professional relationships in the industry. This course has no prerequisites. "
          },
          "D523": {
            "courseName": "Student Teaching I in Elementary Education ",
            "description": "Student Teaching I in Elementary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
          },
          "D524": {
            "courseName": "Student Teaching I in Elementary Education ",
            "description": "Student Teaching I in Elementary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
          },
          "D525": {
            "courseName": "Student Teaching II in Elementary Education ",
            "description": "Student Teaching II in Elementary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
          },
          "D526": {
            "courseName": "Student Teaching II in Elementary Education ",
            "description": "Student Teaching II in Elementary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
          },
          "D529": {
            "courseName": "Student Teaching I in Special Education ",
            "description": "Student Teaching I in Special Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
          },
          "D530": {
            "courseName": "Student Teaching I in Special Education ",
            "description": "Student Teaching I in Special Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
          },
          "D531": {
            "courseName": "Student Teaching II in Special Education ",
            "description": "Student Teaching II in Special Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
          },
          "D532": {
            "courseName": "Student Teaching II in Special Education ",
            "description": "Student Teaching II in Special Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
          },
          "D533": {
            "courseName": "Student Teaching I in Secondary Education ",
            "description": "Student Teaching I in Secondary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
          },
          "D534": {
            "courseName": "Student Teaching I in Secondary Education ",
            "description": "Student Teaching I in Secondary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
          },
          "D535": {
            "courseName": "Student Teaching II in Secondary Education ",
            "description": "Student Teaching II in Secondary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
          },
          "D536": {
            "courseName": "Student Teaching II in Secondary Education ",
            "description": "Student Teaching II in Secondary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
          },
          "DPT1": {
            "courseName": "Physics: Electricity and Magnetism ",
            "description": "Physics: Electricity and Magnetism addresses principles related to the physics of electricity and magnetism. Students will study electric and magnetic forces and then apply that knowledge to the study of circuits with resistors and electromagnetic induction and waves, focusing on such topics as electric charge and electric field, electric currents and resistance, magnetism, electromagnetic induction and Faraday's law, and Maxwell's equation and electromagnetic waves. "
          },
          "DPT2": {
            "courseName": "Physics: Electricity and Magnetism ",
            "description": "Physics: Electricity and Magnetism addresses principles related to the physics of electricity and magnetism. Students will study electric and magnetic forces and then apply that knowledge to the study of circuits with resistors and electromagnetic induction and waves. This course will focus on such topics as electric charge and electric field, electric currents and resistance, magnetism, electromagnetic induction and Faraday's law, and Maxwell's equation and electromagnetic waves. "
          },
          "DWP2": {
            "courseName": "Application of Elementary Social Studies Methods ",
            "description": "Application of Elementary Social Studies Methods helps students learn how to implement effective social studies instruction in the elementary classroom. Topics include social studies themes, promotion of cultural diversity, integrated social studies across the curriculum, social studies learning environments, assessment of social studies understanding, differentiated instruction for social studies, technology for social studies instruction, and standards-based social studies instruction. This course helps students apply, analyze, and reflect on effective elementary social studies instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
          },
          "DZP2": {
            "courseName": "Application of Elementary Visual and Performing Arts Methods ",
            "description": "Application of Elementary Visual and Performing Arts Methods helps students learn how to implement effective visual and performing arts instruction in the elementary classroom. Topics include integrating arts across the curriculum, music education, visual arts, dance and movement, dramatic arts, differentiated instruction for visual and performing arts, and the promotion of cultural diversity through visual and performing arts instruction. This course helps students apply, analyze, and reflect on effective elementary visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
          },
          "EBP2": {
            "courseName": "Application of Elementary Physical Education and Health Methods ",
            "description": "Applications of Elementary Physical Education and Health Methods helps students learn how to implement effective physical and health education instruction in the elementary classroom. Topics include healthy lifestyles, student safety, student nutrition, physical education, differentiated instruction for physical and health education, physical education across the curriculum, and public policy in health and physical education. This course helps students apply, analyze, and reflect on effective elementary visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
          },
          "ELO1": {
            "courseName": "Subject Specific Pedagogy: ELL ",
            "description": "Subject Specific Pedagogy: ELL integrates aspects of pedagogy, assessment, and professionalism in English Language Learning (ELL). A student develops and assesses aspects of language curriculum development including second language instruction, methods of second language assessment, and legal policy issues.  "
          },
          "FEA1": {
            "courseName": "Field Experience for ELL ",
            "description": "Field Experience for ELL is the field experience component of the English Language Learning program. In this experience, students are required to complete a minimum of 15 hours of video observations for both elementary and secondary levels. Additionally, a supervised teaching experience that is face-to-face with English language learners (ELL) according to the minimum time requirements of the student's state is required. The purpose of this course is to assess the ability of students, including their engagement in field experience activities, ability to reflect on and then plan standards-based instruction in ELL, and their ability to locate and effectively use resources for teaching ELL to meet the needs of their individual learners. "
          },
          "LPA1": {
            "courseName": "Language Production, Theory and Acquisition ",
            "description": "Language Production, Theory and Acquisition focuses on describing and understanding language and the development of language. It includes the study of acquisition theory, error correction strategies, and applied phonology. "
          },
          "LZT2": {
            "courseName": "Power, Influence and Leadership ",
            "description": "Power, Influence, and Leadership focuses on the development of the critical leadership and soft skills necessary for success in information technology leadership and management. The course focuses specifically on skills such as cultivating effective leadership communication, building personal influence, enhancing emotional intelligence (soft skills), generating ideas and encouraging idea generation in others, conflict resolution, and positioning oneself as an influential change agent within different organizational cultures. There are no prerequisites for this course. "
          },
          "MBT2": {
            "courseName": "Technological Globalization ",
            "description": "Technological Globalization explores information and communication technologies used to meet business needs in global markets. IT executives must analyze their organization’s technological needs, develop internationally-capable strategic plans, and mitigate the operational challenges of each of the countries in which the organization does business. This course provides students with the practical knowledge and understanding of how to plan, evaluate, and successfully integrate effective and efficient technical communication solutions in the global business market. This course has no prerequisites. "
          },
          "MFT2": {
            "courseName": "Mathematics (K-6) Portfolio Oral Defense ",
            "description": "Mathematics (K-6) Portfolio Oral Defense: Mathematics (K-6) Portfolio Defense focuses on a formal presentation. The student will present an overview of their teacher work sample (TWS) portfolio discussing the challenges they faced and how they determined whether their goals were accomplished. They will explain the process they went through to develop the TWS portfolio and reflect on the methodologies and outcomes of the strategies discussed in the TWS portfolio. Additionally, they will discuss the strengths and weaknesses of those strategies and how they can apply what they learned from the TWS portfolio in their professional work environment. "
          },
          "MGT2": {
            "courseName": "IT Project Management ",
            "description": "IT Project Management provides an overview of the Project Management Institute’s project management methodology. Topics cover various process groups and knowledge areas and application of knowledge in case studies for planning a project that has not started yet and monitoring/controlling a project that is already underway. "
          },
          "MMT2": {
            "courseName": "IT Strategic Solutions ",
            "description": "IT Strategic Solutions guides students in identifying strategic opportunities and emerging technologies through research and deciding on a system to support a growing company. Topics will include technology strategy; gap analysis; researching new technology; strengths, opportunities, weaknesses, and threats; ethics; risk mitigation; data security, communication plans; and globalization. "
          },
          "NMA1": {
            "courseName": "Professional Role of the ELL Teacher ",
            "description": "The Professional Role of the ELL Teacher focuses on issues of professionalism for the English Language Learning teacher and leader. This includes program development, ethics, engagement in professional organizations, serving as a resource, and ELL advocacy. "
          },
          "NNA1": {
            "courseName": "Planning, Implementing, Managing Instruction ",
            "description": "Planning, Implementing, Managing Instruction focuses on a variety of philosophies and grade levels of English Language Learner (ELL) instruction. It includes the study of ELL listening and speaking, ELL reading and writing, specially designed academic instruction in English (SDAIE), and specific issues for various grade level instruction. "
          },
          "OOT2": {
            "courseName": "Mathematics History and Technology ",
            "description": "In Math History and Teaching, students will learn about a variety of technological tools for doing mathematics and develop a broad understanding of the historical development of mathematics. Mathematics is a very human subject that comes from the macro-level sweep of cultural and societal change as well as the micro-level actions of individuals with personal, professional, and philosophical motivations. This course will focus on the historical development of mathematics, including contributions of significant figures and diverse cultures. Students will learn to evaluate and apply technological tools and historical information to create an enriching student-centered mathematical learning environment. "
          },
          "OPT2": {
            "courseName": "Mathematics Learning and Teaching ",
            "description": "Mathematics Learning and Teaching will help students develop the knowledge and skills necessary to become a prospective and practicing educator. This course will help students use a variety of instructional strategies to effectively facilitate the learning of mathematics. It focuses on selecting appropriate resources, using multiple strategies, and instructional planning, with methods based on research and problem solving. A deep understanding of the knowledge, skills, and disposition of mathematics pedagogy is necessary to become an effective secondary mathematics educator. There are no prerequisites for this course. "
          },
          "PFIT": {
            "courseName": "Business ",
            "description": "IT Management Portfolio Requirement - Business - IT Management Portfolio Requirement is designed to help the learner complete the culminating Undergraduate Business Portfolio assessment; it focuses on developing a business portfolio containing a strengths essay, a career report, a reflection essay, a resume, and exhibits that support one’s strengths in the work place. "
          },
          "QDT1": {
            "courseName": "Abstract Algebra ",
            "description": "Abstract Algebra is the axiomatic and rigorous study of the underlying structure of algebra and arithmetic. It covers the knowledge and skills necessary to understand, apply, and prove theorems about numbers, groups, rings, and fields. Topics include the well-ordering principle, equivalence classes, the division algorithm, Euclid's algorithm, prime factorization, greatest common divisor, least common multiple, congruence, the Chinese remainder theorem, modular arithmetic, rings, integral domains, fields, groups, roots of unity, and homomorphisms. Candidates should have completed Linear Algebra before engaging in this course. "
          },
          "QDT2": {
            "courseName": "Abstract Algebra ",
            "description": "Abstract Algebra is the axiomatic and rigorous study of the underlying structure of algebra and arithmetic. It covers the knowledge and skills necessary to understand, apply, and prove theorems about numbers, groups, rings, and fields. Topics include the well-ordering principle, equivalence classes, the division algorithm, Euclid's algorithm, prime factorization, greatest common divisor, least common multiple, congruence, the Chinese remainder theorem, modular arithmetic, rings, integral domains, fields, groups, roots of unity, and homomorphisms. Linear Algebra is a prerequisite for this course. "
          },
          "QFT1": {
            "courseName": "Business ",
            "description": "IT Management Capstone Project - The capstone requires students to demonstrate the integration and synthesis of competencies in all domains required for the degree in Information Technology Management. The student produces a business plan for a start-up company that is selected and approved by the student and mentor. "
          },
          "QGT1": {
            "courseName": "Business Management Capstone Written Project ",
            "description": "For the Business Management Capstone Written Project students will integrate and synthesize competencies from across their degree program to demonstrate their ability to participate in and contribute value to their chosen professional field. A comprehensive business plan is developed for a company that plans to sell a product or service in a local market, national market, or on the Internet. The business plan includes a market analysis, financial statements and analysis, and specific strategic actions relevant to the chosen company. "
          },
          "QHT1": {
            "courseName": "Business Management Tasks ",
            "description": "Business Management Tasks addresses important concepts needed to effectively manage a business. Topics include understanding the cost-quality relationship, using various types of graphical charts in operations management, managing innovation, and developing strategies for working with individuals and groups. "
          },
          "QJT2": {
            "courseName": "Calculus I ",
            "description": "Calculus I is the study of rates of change in relation to the slope of a curve and covers the knowledge and skills necessary to use differential calculus of one variable and appropriate technology to solve basic problems. Topics include graphing functions and finding their domains and ranges; limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, and sum rules applied to polynomial and exponential functions, position and velocity; and L'Hopital's Rule. Candidates should have completed a course in Pre-Calculus before engaging in this course. "
          },
          "QTT2": {
            "courseName": "Finite Mathematics ",
            "description": "Finite Mathematics covers the knowledge and skills necessary to apply discrete mathematics and properties of number systems to model and solve real-life problems. Topics include sets and operations; prime and composite numbers; GCD and LCM; order of operations; ordering numbers; mathematical systems including modular arithmetic, arithmetic and geometric sequences, ratio and proportion, subsets of real numbers, logic and truth tables, graphs, and trees and networks. There are no prerequisites for this course. "
          },
          "RKT1": {
            "courseName": "Linear Algebra ",
            "description": "Linear Algebra is the study of the algebra of curve-free functions extended into three- or higher-dimensional space. It covers the knowledge and skills necessary to apply vectors, matrices, matrix theorems, and linear transformations and to use technology to model and solve real-life problems. It also covers properties of and proofs about vector spaces. Topics include linear equations and their matrix-vector representation Ax=b; row reduction; linear transformations and their matrix representations (shear, dilation, rotation, reflection); matrix operations matrix inverses and invertible matrix characterizations; computing determinants; relating determinants to area and volume; and axiomatic and intuitive definitions of vector spaces and subspaces; and proving theorems about them. College Geometry and Calculus II are prerequisites for this course. "
          },
          "RKT2": {
            "courseName": "Linear Algebra ",
            "description": "Linear Algebra is the study of the algebra of curve-free functions extended into three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply vectors, matrices, matrix theorems, and linear transformations and to use appropriate technology to model and solve real-life problems. It also covers properties of and proofs about vector spaces. Topics include linear equations and their matrix-vector representation Ax=b, row reduction, linear transformations and their matrix representations (shear, dilation, rotation, reflection), matrix operations, matrix inverses and invertible matrix characterizations, computing determinants, relating determinants to area and volume, and axiomatic and intuitive definitions of vector spaces and subspaces and how to prove theorems about them. College Geometry and Calculus II are prerequisites for this course. "
          },
          "RNT1": {
            "courseName": "General Physics ",
            "description": "This course provides a broad overview of the principles of mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. and invites students to apply them by solving problems, performing labs, and reflecting on concepts and ideas. "
          },
          "RNT2": {
            "courseName": "General Physics ",
            "description": "This course provides a broad overview of the principles of mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism and invites students to apply them by solving problems, performing labs, and reflecting on concepts and ideas. "
          },
          "RXT2": {
            "courseName": "Precalculus and Calculus ",
            "description": "Precalculus and Calculus provides instruction in precalculus and calculus and applies them to examples found in both mathematics and science. Topics in precalculus include principles of trigonometry, mathematical modeling, and logarithmic, exponential, polynomial, and rational functions. Topics in calculus include conceptual knowledge of limit, continuity, differentiability, and integration. "
          },
          "SLO1": {
            "courseName": "Theories of Second Language Acquisition and Grammar ",
            "description": "Theories of Second Language Learning Acquisition and Grammar covers content material in applied linguistics, including morphology, syntax, semantics, and grammar. Students will explore the role of dialect in the classroom, the connections between language and culture, and the theories of first and second language acquisition. "
          },
          "TOC2": {
            "courseName": "Probability and Statistics I ",
            "description": "Probability and Statistics I covers the knowledge and skills necessary to apply basic probability, descriptive statistics, and statistical reasoning and to use appropriate technology to model and solve real-life problems. It provides an introduction to the science of collecting, processing, analyzing, and interpreting data, including representations, constructions, and interpretation of graphical displays (e.g., box plots, histograms, cumulative frequency plots, scatter plots). Topics include creating and interpreting numerical summaries and visual displays of data; regression lines and correlation; evaluating sampling methods and their effect on possible conclusions; designing observational studies, controlled experiments, and surveys; and determining probabilities using simulations, diagrams, and probability rules. College Algebra is a prerequisite to this course. "
          },
          "TQC1": {
            "courseName": "Probability and Statistics II ",
            "description": "Probability and Statistics II covers the knowledge and skills necessary to apply random variables, sampling distributions, estimation, and hypothesis testing, and to use appropriate technology to model and solve real-life problems. It provides tools for the science of analyzing and interpreting data and includes statistical variability and its sources and the role of randomness in statistical inference. Topics include discrete and continuous random variables; expected values; the Central Limit Theorem; the identification of unusual samples; population parameters; point estimates; confidence intervals; influences on accuracy and precision; hypothesis testing; and statistical tests (z mean, z proportion, one sample t, paired t, independent t, ANOVA, chi-squared, and significance of correlation). Calculus II and Probability and Statistics I are prerequisites for this course. "
          },
          "TQC2": {
            "courseName": "Probability and Statistics II ",
            "description": "Probability and Statistics II covers the knowledge and skills necessary to apply random variables, sampling distributions, estimation, and hypothesis testing and to use appropriate technology to model and solve real-life problems. It provides tools for the science of analyzing and interpreting data and includes statistical variability and its sources and the role of randomness in statistical inference. Topics include discrete and continuous random variables, expected values, the central limit theorem, the identification of unusual samples, population parameters, point estimates, confidence intervals, influences on accuracy and precision, hypothesis testing and statistical tests (z mean, z proportion, one sample t, paired t, independent t, ANOVA, chi-squared, and significance of correlation). Calculus II and Probability and Stats I are prerequisites to this course. "
          },
          "UQT1": {
            "courseName": "Organic Chemistry ",
            "description": "This course focuses on the study of compounds that contain carbon, much of which is learning how to organize and group these compounds based on common bonds found within them in order to predict their structure, behavior, and reactivity. "
          },
          "VZT1": {
            "courseName": "Marketing Applications ",
            "description": "Marketing Applications allows students to apply their knowledge of core marketing principles by creating a comprehensive marketing plan. The plan will apply knowledge of the marketing planning process, market analysis, and the marketing mix (product, place, promotion, and price).  "
          },                
      }

      export const allDescriptionsCheck = {
        "AFT2": {
          "courseName": "Accreditation Audit ",
          "description": "Accreditation Audit covers regulatory audits, resource assessment, quality improvement, patient care improvement, organization plans, risk management, effective interaction, and compliance as evidenced during an accreditation audit. "
        },
        "AIT2": {
          "courseName": "Organic Chemistry ",
          "description": "Organic Chemistry focuses on the study of compounds that contain carbon, much of which is learning how to organize and group organic compounds in order to predict their structure, behavior, and reactivity based on common bonds found within an organic compound. "
        },
        "AMT2": {
          "courseName": "Service Line Development ",
          "description": "Service Line Development will address how to critically assess the competitive marketplace as well as the internal environment to establish a new line of business. Topics include needs assessment, international healthcare trends, service line management, revenue analysis, costs and productivity, communication, negotiation, health policy, health legislation, and facilities management, which are variables in the evaluation process. "
        },
        "AOA2": {
          "courseName": "Number Sense and Functions ",
          "description": "Number Sense and Functions is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as number sense, patterns and functions, integers and order of operations, fractions, decimals, and percentages. "
        },
        "ASA1": {
          "courseName": "Assessment Theory and Practice ",
          "description": "Assessment Theory and Practice focuses on issues central to assessment in the ELL environment, including high-stakes testing, standardized tests, placement and exit assessment, formative and summative assessments, and making adaptations in assessments to meet the needs of ELL students. "
        },
        "AUA2": {
          "courseName": "Graphing, Proportional Reasoning and Equations/Inequalities ",
          "description": "Graphing, Proportional Reasoning and Equations/Inequalities is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as coordinate pairs and graphing, ratios and proportional reasoning, and equations and inequalities. "
        },
        "AVA2": {
          "courseName": "Geometry and Statistics ",
          "description": "Geometry and Statistics is a performance-based assessment that evaluates a student's portfolio of work. This portfolio includes the student's responses to various prompts and an original lesson plan for each of the mathematics modules such as geometry and measurement, statistics and probability. "
        },
        "BVT1": {
          "courseName": "Physical Chemistry ",
          "description": "Physical Chemistry introduces the study of chemistry in terms of physical concepts. It includes thermodynamics,  reaction kinetics, chemical equilibrium, electrochemistry, and matter. "
        },
        "BVT2": {
          "courseName": "Physical Chemistry ",
          "description": "Physical Chemistry introduces the study of chemistry in terms of physical concepts. It  includes  thermodynamics,  reaction kinetics, chemical equilibrium, electrochemistry, and matter. "
        },
        "BWT1": {
          "courseName": "Inorganic Chemistry ",
          "description": "Inorganic Chemistry introduces the concepts of inorganic chemistry—the branch of chemistry that studies the properties and behavior of any compound avoiding a specific focus on carbon. It will focus on the three most important areas of inorganic chemistry:  the structure, properties, and reactions of various groups of inorganic compounds. "
        },
        "BWT2": {
          "courseName": "Inorganic Chemistry ",
          "description": "Inorganic Chemistry introduces the concepts of inorganic chemistry—the branch of chemistry that studies the properties and behavior of any compound, avoiding a specific focus on carbon. It will focus on the three most important areas of inorganic chemistry:  the structure, properties, and reactions of various groups of inorganic compounds. "
        },
        "BYT1": {
          "courseName": "Physics: Mechanics ",
          "description": "Physics: Mechanics introduces foundational concepts of mechanics, including motion, gravitation, work and energy, momentum and collisions, rotational motion, static equilibrium, fluids, and oscillation. "
        },
        "BYT2": {
          "courseName": "Physics: Mechanics ",
          "description": "Physics: Mechanics introduces foundational concepts of mechanics, including motion, gravitation, work and energy,  momentum and collisions, rotational motion, static equilibrium, fluids, and oscillation. "
        },
        "BZT1": {
          "courseName": "Physics: Waves and Optics ",
          "description": "Physics: Waves and Optics addresses foundational topics in the physics of waves and optics. Students will study basic wave motion and then apply that knowledge to the study of sound and light with even further applications to optical instruments. They will also learn about thermodynamics and theories governing the physics of gases. "
        },
        "BZT2": {
          "courseName": "Physics: Waves and Optics ",
          "description": "Physics: Waves and Optics addresses foundational topics in the physics of waves and optics. Students will study basic wave motion and then apply that knowledge to the study of sound and light with even further applications to optical instruments. This course will also cover thermodynamics and theories governing the physics of gases. "
        },
        "C100": {
          "courseName": "Introduction to Humanities ",
          "description": "This introductory humanities course allows candidates to practice essential writing, communication, and critical thinking skills necessary to engage in civic and professional interactions as mature, informed adults. Whether through studying literature, visual and performing arts, or philosophy, all humanities courses stress the need to form reasoned, analytical, and articulate responses to cultural and creative works. Studying a wide variety of creative works allows candidates to more effectively enter the global community with a broad and enlightened perspective. "
        },
        "C104": {
          "courseName": "Elementary Social Studies Methods ",
          "description": "Elementary Social Studies Methods helps students learn how to implement effective social studies instruction in the elementary classroom. Topics include social studies themes, promoting cultural diversity, integrated social studies across the curriculum, social studies learning environments, assessing social studies understanding, differentiated instruction for social studies, technology for social studies instruction, and standards-based social studies instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "C105": {
          "courseName": "Elementary Visual and Performing Arts Methods ",
          "description": "Elementary Visual and Performing Arts Methods helps students learn how to implement effective visual and performing arts instruction in the elementary classroom. Topics include integrating arts across the curriculum, music education, visual arts, dance and movement, dramatic arts, differentiating instruction for visual and performing arts, and promoting cultural diversity through visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C107": {
          "courseName": "Anatomy and Physiology I ",
          "description": "Anatomy and Physiology I examines the structures and functions of the human body. The course is designed to provide students with a thorough understanding of human anatomy and physiology, including the interdependent operational relationships among them. Students will use a dissection lab to study organ systems of the human body in their healthy state, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. By examining these organ systems in a healthy state, healthcare professionals are more adept at recognizing when something is functioning abnormally, which is a key component to providing effective care to patients. For nursing students, this is the first of two anatomy and physiology courses within the program of study. This course has no prerequisites. "
        },
        "C108": {
          "courseName": "Elementary Science Methods ",
          "description": "Elementary Science Methods helps students learn how to implement effective science instruction in the elementary classroom. Topics include processes of science, science inquiry, science learning environments, instructional strategies for science, differentiating instruction for science, assessing science understanding, technology for science instruction, standards-based science instruction, integrating science across the curriculum, and science beyond the classroom. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "C109": {
          "courseName": "Elementary Mathematics Methods ",
          "description": "Elementary Mathematics Methods helps students learn how to implement effective math instruction in the elementary classroom. Topics include differentiated math instruction, mathematical communication, mathematical tools for instruction, assessing math understanding, integrating math across the curriculum, critical thinking development, standards-based mathematics instruction, and mathematical models and representation. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C121": {
          "courseName": "Survey of United States History ",
          "description": "This course presents a broad and thematic survey of U.S. history from European colonization to the midtwentieth century. Students will explore how historical events and major themes in American history have affected a diverse population. "
        },
        "C165": {
          "courseName": "Integrated Physical Sciences ",
          "description": "This course provides students with an overview of the basic principles and unifying ideas of the physical sciences: physics, chemistry, and earth sciences. Course materials focus on scientific reasoning and practical, everyday applications of physical science concepts to help students integrate conceptual knowledge with practical skills. "
        },
        "C168": {
          "courseName": "Critical Thinking and Logic ",
          "description": "Reasoning and Problem Solving helps candidates internalize a systematic process for exploring issues that takes them beyond an unexamined point of view and encourages them to become more self-aware thinkers by applying principles of problem identification and clarification, planning and information gathering, identifying assumptions and values, analyzing and interpreting information and data, reaching well-founded conclusions, and identifying the role of critical thinking in disciplines and professions. "
        },
        "C172": {
          "courseName": "Network and Security ",
          "description": "Foundations - Network and Security - Foundations introduces students to the components of a computer network and the concept and role of communication protocols. The course covers widely used categorical classifications of networks (e.g., LAN, MAN, WAN, WLAN, PAN, SAN, CAN, and VPN) as well as network topologies, physical devices, and layered abstraction. The course also introduces students to basic concepts of security, covering vulnerabilities of networks and mitigation techniques, security of physical media, and security policies and procedures. This course has no prerequisites. "
        },
        "C175": {
          "courseName": "Data Management ",
          "description": "Foundations - This course introduces students to the concepts and terminology used in the field of data management. Students will be introduced to Structured Query Language (SQL) and will learn how to use Data Definition Language (DDL) and Data Manipulation Language (DML) commands to define, retrieve, and manipulate data. This course covers differentiations of data—structured vs. unstructured and quasi-structured (relational, hierarchical, XML, textual, visual, etc); it also covers aspects of data management (quality, policy, storage methodologies). Foundational concepts of data security are included. "
        },
        "C179": {
          "courseName": "Business of IT ",
          "description": "Applications - This course introduces IT students to information systems (IS). The course includes important topics related to the management of information systems (MIS), such as system development and business continuity. The course also provides an overview of management tools and issue tracking systems. "
        },
        "C180": {
          "courseName": "Introduction to Psychology ",
          "description": "In this course, students will develop an understanding of psychology and how it helps them better understand others and themselves. Students will learn general theories about psychological development, the structure of the brain, and how psychologists study behavior. They will gain an understanding of both normal and disordered psychological behaviors, as well as general applications of the science of psychology in society (such as personality typing and counseling). "
        },
        "C181": {
          "courseName": "Survey of United States Constitution and Government ",
          "description": "Ready to work on Constitution and Government? Please contact your program mentor to be moved to the correct course. "
        },
        "C182": {
          "courseName": "Introduction to IT ",
          "description": "Introduction to IT examines information technology as a discipline and the various roles and functions of the IT department as business support. Students are presented with various IT disciplines including systems and services, network and security, scripting and programming, data management, and business of IT, with a survey of technologies in every area and how they relate to each other and to the business. "
        },
        "C190": {
          "courseName": "Introduction to Biology ",
          "description": "This course is a foundational introduction to the biological sciences. The overarching theories of life from biological research are explored as well as the fundamental concepts and principles of the study of living organisms and their interaction with the environment. Key concepts include how living organisms use and produce energy; how life grows, develops, and reproduces; how life responds to the environment to maintain internal stability; and how life evolves and adapts to the environment. "
        },
        "C191": {
          "courseName": "Operating Systems for Programmers ",
          "description": "This course covers operating systems from the perspective of a programmer, including the placement of the operating system in the layered application development model. Primarily, OSs provide memory management, task scheduling, and CPU allocation. Secondarily, OSs provide tools for file storage/access, permission control, event handling, network access, and cross-process interaction. OSs also provide tools for debugging problems within a single process or within groups of programs. There are no prerequisites for this course. "
        },
        "C200": {
          "courseName": "Managing Organizations and Leading People ",
          "description": "This course covers principles of effective management and leadership that maximize organizational performance. The following topics are included: the role and functions of a manager, analysis of personal leadership styles, approaches to self-awareness and self-assessment, and application of foundational leadership and management skills. "
        },
        "C201": {
          "courseName": "Business Acumen ",
          "description": "The Business Acumen course introduces you to the operation of the business enterprise and the role of management in directing the activities of the business. You will examine the roles of management in the context of business functions such as marketing, operations, accounting, and finance. "
        },
        "C202": {
          "courseName": "Managing Human Capital ",
          "description": "This course focuses on strategies and tools that managers use to maximize employee contribution and create organizational excellence. You will learn talent management strategies to motivate and develop employees as well as best practices to manage performance for added value. "
        },
        "C203": {
          "courseName": "Becoming an Effective Leader ",
          "description": "This course explores major theories and approaches to leadership, leadership style evaluation, and personal leadership development while focusing on motivation, development, and achievement of others. You will learn how to influence followers, manage organizational culture, and enhance your effectiveness as a leader. "
        },
        "C204": {
          "courseName": "Management Communication ",
          "description": "This course prepares students for the communication challenges in organizations. Topics examined include theories and strategies of communication, persuasion, conflict management, and ethics that enhance communication to various audiences. "
        },
        "C205": {
          "courseName": "Leading Teams ",
          "description": "This course helps students establish team objectives, align the team purpose with organizational goals, build credibility and trust, and develop the talents of individuals to enhance team performance. "
        },
        "C206": {
          "courseName": "Ethical Leadership ",
          "description": "This course examines the ethical issues and dilemmas managers face. This course provides a framework for analysis of management-related ethical issues and decision-making action required for satisfactory resolution of these issues. "
        },
        "C207": {
          "courseName": "Data-Driven Decision Making ",
          "description": "This course presents critical problem-solving methodologies, including field research and data collection methods that enhance organizational performance. Topics include quantitative analysis, statistical and quality tools. You will improve your ability to use data to make informed decisions. "
        },
        "C208": {
          "courseName": "Change Management and Innovation ",
          "description": "This course provides an overview of change theories and innovation practices. This course will emphasize the role of leadership in influencing and managing change in response to challenges and opportunities facing organizations. "
        },
        "C209": {
          "courseName": "Strategic Management ",
          "description": "This course focuses on models and practices of strategic management including developing and implementing both short- and long-term strategy and evaluating performance to achieve strategic goals and objectives. "
        },
        "C210": {
          "courseName": "Management and Leadership Capstone ",
          "description": "This course is the culminating assessment of the MSML curriculum that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of management and leadership development and practices. "
        },
        "C211": {
          "courseName": "Global Economics for Managers ",
          "description": "This course examines how economic tools, techniques, and indicators can be used for solving organizational problems related to competitiveness, productivity, and growth. You will explore the management implications of a variety of economic concepts and effective strategies to make decisions within a global context. "
        },
        "C212": {
          "courseName": "Marketing ",
          "description": "Marketing Fundamentals introduces students to principles of the marketing environment, social media, consumer behavior, marketing research, and market segmentation. Students will also explore marketing strategies that are related to products and services, distribution channels, promotions, sales, and pricing. "
        },
        "C213": {
          "courseName": "Accounting for Decision Makers ",
          "description": "This course provides you with the accounting knowledge and skills to assess and manage a business. Topics include the accounting cycle, financial statements, taxes, and budgeting. This course will improve students’ ability to understand reports and use accounting information to plan and make sound business decisions. "
        },
        "C214": {
          "courseName": "Financial Management ",
          "description": "This course covers practical approaches to analysis and decision-making in the administration of corporate funds, including capital budgeting, working capital management, and cost of capital. Topics include financial planning, management of working capital, analysis of investment opportunities, sources of long-term financing, government regulations, and global influences. This course will improve students’ ability to interpret financial statements and manage corporate finances. "
        },
        "C215": {
          "courseName": "Operations Management ",
          "description": "This course focuses on the strategic importance of operations management to overall performance. This course also emphasizes principles of supply chain management relevant to a variety of business operations ranging from manufacturing goods to retail services. You will examine the various planning, control, and decision-making tools and techniques of the operations function. "
        },
        "C216": {
          "courseName": "MBA Capstone ",
          "description": "MBA Capstone is the culminating course in the MBA program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C217": {
          "courseName": "Human Growth and Development Across the Lifespan ",
          "description": "This course introduces candidates to human development across the lifespan. This will include an introductory survey of cognitive, psychological, and physical growth. Candidates will gain an understanding of the emergence of personality, identity, gender and sexuality, social relationships, emotion, language, and moral development through life. This will include milestones such as education, achievement, work, dying, and death. "
        },
        "C218": {
          "courseName": "MBA, Information Technology Management Capstone ",
          "description": "MBA Information Technology Management Capstone is the culminating course in the MBA ITM program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C219": {
          "courseName": "MBA, Healthcare Management Capstone ",
          "description": "MBA Healthcare Management Capstone is the culminating course in the MBA HCM program that provides an integrative experience with all competencies and assessment topics throughout the program. Students synthesize concepts from previously completed coursework and demonstrate an understanding of responsible practices for growing and running a business. This course promotes a meaningful connection between the academic work and career experience. "
        },
        "C224": {
          "courseName": "Research Foundations ",
          "description": "The Research Foundations course focuses on the essential concepts in educational research, including quantitative, qualitative, mixed, and action research. This course also teaches students concepts about measurement and assessment, as well as strategies for obtaining warranted research results. "
        },
        "C225": {
          "courseName": "Research Questions and Literature Review ",
          "description": "The Research Questions and Literature Reviews course focuses on how to conduct a thorough literature review that addresses and identifies important educational research topics, problems, and questions, and helps determine the appropriate kind of research and data needed to answer one's research questions and hypotheses. Research Foundations is a prerequisite for this course. "
        },
        "C226": {
          "courseName": "Research Design and Analysis ",
          "description": "The Research Design and Analysis course focuses on applying strategies for effective design of empirical research studies. Particular emphasis is placed on selecting or constructing the design that will provide the most valid results, analyzing the kind of data that would be obtained, and making defensible interpretations and drawing appropriate conclusions based on the data. Research Questions and Literature Review is a prerequisite for this course. "
        },
        "C227": {
          "courseName": "Research Proposals ",
          "description": "Research Proposals focuses on planning and writing a well-organized and complete research proposal. The relationship of the sections in a research proposal to the sections in a research report will be highlighted. Research Design and Analysis is a prerequisite for this course. "
        },
        "C228": {
          "courseName": "Community Health and Population-Focused Nursing ",
          "description": "Community Health and Population-Focused Nursing will assist students in becoming familiar with foundational theories and models of health promotion applicable to the community health nursing environment. Students will develop an understanding of how policies and resources influence the health of populations. Focus is concentrated on learning the importance of a community assessment to improve or resolve a community health issue. This course introduces students to the relationships between cultures and communities and the steps necessary to create community collaboration with the goal to improve or resolve community health issues in a variety of settings. Students will gain a greater understanding of health systems in the United States, global health issues, quality-of-life issues, cultural influences, community collaboration, and emergency preparedness.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C229": {
          "courseName": "Community Health and Population-Focused Nursing Field Experience ",
          "description": "This course will assist students to become familiar with clinical aspects of health promotion and disease prevention, applicable to the community health nursing environment. Students will practice skills based on clinical priorities, methodology, and resources that positively influence the health of populations. Students will demonstrate critical thinking skills by applying principles of community health nursing in a variety of settings. Students will design, implement and evaluate a project in community health. Students will develop health promotion and disease prevention strategies for population groups.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C232": {
          "courseName": "Introduction to Human Resource Management ",
          "description": "This course provides an introduction to the management of human resources, the function within an organization that focuses on recruitment, management, and direction for the people who work in the organization. Students will be introduced to topics such as strategic workforce planning and employment; compensation and benefits; training and development; employee and labor relations; and occupational health, safety, and security. "
        },
        "C233": {
          "courseName": "Employment Law ",
          "description": "This course reviews the legal and regulatory framework surrounding employment, including recruitment, termination, and discrimination law. The course topics include employment-at-will, EEO, ADA, OSHA, and other laws affecting the workplace. This course covers how to analyze current trends and issues in employment law and apply this knowledge to manage risk effectively in the employment relationship. "
        },
        "C234": {
          "courseName": "Workforce Planning: Recruitment and Selection ",
          "description": "This course focuses on building a highly skilled workforce by using effective strategies and tactics for recruiting, selecting, hiring, and retaining employees. "
        },
        "C236": {
          "courseName": "Compensation and Benefits ",
          "description": "Compensation and Benefits develops competence in the design and implementation of compensation and benefits systems in an organization. The total rewards perspective integrates tangible rewards (e.g., salary, bonuses) with employee benefits (e.g., health insurance, retirement plan) and intangible rewards (e.g., location, work environment). This perspective allows students to use all forms of rewards fairly and effectively to enable job satisfaction and organizational performance. There are no prerequisites. "
        },
        "C237": {
          "courseName": "Taxation I ",
          "description": "This course focuses on the taxation of individuals. It provides an overview of income taxes of both individuals and business entities in order to enhance awareness of the complexities and sources of tax law and to measure and analyze the effect of various tax options. The course will introduce taxation of sole proprietorships. Students will learn principles of individual taxation and how to develop effective personal tax strategies for individuals. Students will also be introduced to tax research of complex taxation issues. "
        },
        "C239": {
          "courseName": "Advanced Tax Concepts ",
          "description": "This course is designed to enhance awareness of the complexities and sources of tax law and to measure and analyze the effect of various tax options. This course provides an overview of income taxes on individuals, corporations, associations, and corporate distributions, while emphasizing the role of taxes in business decisions and business strategy. Also examined will be federal tax laws applicable to individuals and corporations (and shareholders), including tax research, tax compliance, and tax planning. *Retired "
        },
        "C243": {
          "courseName": "Advanced Financial Accounting ",
          "description": "This course builds upon your accounting knowledge by focusing on advanced financial accounting topics such as consolidations, partnership accounting, and international accounting. "
        },
        "C253": {
          "courseName": "Advanced Managerial Accounting ",
          "description": "This course introduces the complexity and functionality of managerial accounting systems within an organization. It covers the topics of product costing (including activity-based costing), decision-making (including capital budgeting), profitability analysis, budgeting, performance evaluation, and reporting related to managerial decision-making. This course provides the opportunity for a detailed study of how managerial accounting information supports the operational and strategic needs of an organization and how managers use accounting information for decision-making, planning, and controlling activities within organizations. "
        },
        "C254": {
          "courseName": "Fraud and Forensic Accounting ",
          "description": "This course provides a framework for detecting and preventing financial statement fraud. Topics include the profession’s focus and legislation of fraud, revenue- and inventory-related fraud, and liability, asset, and inadequate disclosure fraud. "
        },
        "C263": {
          "courseName": "The Ocean Systems ",
          "description": "In this course, learners investigate the complex ocean system by looking at the way its components—atmosphere, biosphere, geosphere, and hydrosphere—interact. Specific topics include: origins of Earth's oceans and the early history of life; physical characteristics and geologic processes of the ocean floor; chemistry of the water molecule; energy flow between air and water, and how ocean surface currents and deep circulation patterns affect weather and climate; marine biology and why ecosystems are an integral part of the ocean system; the effects of human activity; and the role of professional educators in teaching about ocean systems. "
        },
        "C264": {
          "courseName": "Climate Change ",
          "description": "This course explores the science of climate change. Students will learn how the climate system works; what factors cause climate to change across different time scales and how those factors interact; how climate has changed in the past; how scientists use models, observations, and theory to make predictions about future climate; and the possible consequences of climate change for our planet. The course explores evidence for changes in ocean temperature, sea level, and acidity due to global warming. Students will learn how climate change today is different from past climate cycles and how satellites and other technologies are revealing the global signals of a changing climate. Finally, the course looks at the connection between human activity and the current warming trend and considers some of the potential social, economic, and environmental consequences of climate change. "
        },
        "C266": {
          "courseName": "The Ocean Systems ",
          "description": "This course investigates the complex ocean system by looking at the way its components—atmosphere, biosphere, geosphere, hydrosphere—interact. Specific topics include the origins of Earth’s oceans and the early history of life; physical characteristics and geologic processes of the ocean floor; chemistry of the water molecule; energy flow between air and water and how ocean surface currents and deep circulation patterns affect weather and climate; marine biology and why ecosystems are an integral part of the ocean system; the effects of human activity; and the role of professional educators in teaching about ocean systems. "
        },
        "C267": {
          "courseName": "Climate Change ",
          "description": "This course explores the science of climate change and covers how the climate system works; what factors cause climate to change across different time scales and how those factors interact; how climate has changed in the past; how scientists use models, observations, and theory to make predictions about future climate; and the possible consequences of climate change for our planet. The course explores evidence for changes in ocean temperature, sea level, and acidity due to global warming. It covers how climate change today is different from past climate cycles and how satellites and other technologies are revealing the global signals of a changing climate. Finally, the course looks at the connection between human activity and the current warming trend and considers some of the potential social, economic, and environmental consequences of climate change. "
        },
        "C268": {
          "courseName": "Spreadsheets ",
          "description": "The Spreadsheets course will help students become proficient in using spreadsheets to analyze business problems. Students will demonstrate competency in spreadsheet development and analysis for business/accounting applications (e.g., using essential spreadsheet functions, formulas, charts, etc.) "
        },
        "C273": {
          "courseName": "Introduction to Sociology ",
          "description": "This course teaches students to think like sociologists, or, in other words, to see and understand the hidden rules, or norms, by which people live, and how they free or restrain behavior. Students will learn about socializing institutions, such as schools and families, as well as workplace organizations and governments. Participants will also learn how people deviate from the rules by challenging norms and how such behavior may result in social change, either on a large scale or within small groups. "
        },
        "C277": {
          "courseName": "Finite Mathematics ",
          "description": "Finite Mathematics covers the knowledge and skills necessary to apply discrete mathematics and properties of number systems to model and solve real-life problems. Topics include sets and operations; prime and composite numbers; GCD and LCM; order of operations; ordering numbers; mathematical systems including modular arithmetic, arithmetic and geometric sequences, ratio and proportion, subsets of real numbers, logic and truth tables, graphs, and trees and networks. There are no prerequisites for this course. "
        },
        "C278": {
          "courseName": "College Algebra ",
          "description": "This course provides further application and analysis of algebraic concepts and functions through mathematical modeling of real-world situations. Topics include: real numbers, algebraic expressions, equations and inequalities, graphs and functions, polynomial and rational functions, exponential and logarithmic functions, and systems of linear equations. "
        },
        "C280": {
          "courseName": "Probability and Statistics I ",
          "description": "Probability and Statistics I covers the knowledge and skills necessary to apply basic probability, descriptive statistics, and statistical reasoning, and to use appropriate technology to model and solve real-life problems. It provides an introduction to the science of collecting, processing, analyzing, and interpreting data, including representations, constructions and interpretation of graphical displays (e.g., box plots, histograms, cumulative frequency plots, scatter plots). Topics include creating and interpreting numerical summaries and visual displays of data; regression lines and correlation; evaluating sampling methods and their effect on possible conclusions; designing observational studies, controlled experiments, and surveys; and determining probabilities using simulations, diagrams, and probability rules. Candidates should have completed a course in College Algebra before engaging in this course. "
        },
        "C282": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in the slope of a curve and covers the knowledge and skills necessary to use differential calculus of one variable and technology to solve basic problems. Topics include graphing functions and finding their domains and ranges; limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, and sum rules applied to polynomial and exponential functions, position and velocity; and L'Hopital's Rule. Precalculus is a prerequisite for this course. "
        },
        "C283": {
          "courseName": "Calculus II ",
          "description": "Calculus II is the study of the accumulation of change in the area under a curve. It covers the knowledge and skills necessary to apply integral calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include antiderivatives; indefinite integrals; the substitution rule; Riemann sums; the Fundamental Theorem of Calculus; definite integrals; acceleration, velocity, position, and initial values; integration by parts; integration by trigonometric substitution; integration by partial fractions; numerical integration; improper integration; area between curves; volumes and surface areas of revolution; arc length; work; center of mass; separable differential equations; direction fields; growth and decay problems; and sequences. Calculus I is a prerequisite for this course. "
        },
        "C284": {
          "courseName": "Mathematics Learning and Teaching ",
          "description": "Mathematics Learning and Teaching will help students develop the knowledge and skills necessary to become prospective and practicing educators. Students will be able to use a variety of instructional strategies to effectively facilitate the learning of mathematics. This course focuses on selecting appropriate resources, using multiple strategies, and planning instruction, with methods based on research and problem solving. A deep understanding of the knowledge, skills, and disposition of mathematics pedagogy is necessary to become an effective secondary mathematics educator. There are no prerequisites for this course. "
        },
        "C285": {
          "courseName": "Mathematics History and Technology ",
          "description": "Mathematics History and Technology introduces a variety of technological tools for doing mathematics, and you will develop a broad understanding of the historical development of mathematics. You will come to understand that mathematics is a very human subject that comes from the macro-level sweep of cultural and societal change, as well as the micro-level actions of individuals with personal, professional, and philosophical motivations. Most importantly, you will learn to evaluate and apply technological tools and historical information to create an enriching student-centered mathematical learning environment. There are no prerequisites for this course. "
        },
        "C304": {
          "courseName": "Professional Roles and Values ",
          "description": "This course explores the unique role nurses play in healthcare, beginning with the history and evolution of the nursing profession. The responsibilities and accountability of professional nurses are covered, including cultural competency, advocacy for patient rights, and the legal and ethical issues related to supervision and delegation. Professional conduct, leadership, the public image of nursing, the work environment, and issues of social justice are also addressed. "
        },
        "C339": {
          "courseName": "Cohort Seminar ",
          "description": "Cohort Seminar provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C340": {
          "courseName": "Cohort Seminar in Special Education ",
          "description": "Cohort Seminar in Special Education provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C341": {
          "courseName": "Cohort Seminar ",
          "description": "Cohort Seminar provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C347": {
          "courseName": "Professional Portfolio ",
          "description": "Professional Portfolio requires candidates to create an online teaching portfolio that demonstrates professional beliefs, growth, and effective teaching practices from the Demonstration Teaching experience. The portfolio includes reflective essays (educational beliefs, professional growth, and collaboration with stakeholders) and professional artifacts (resume and artifacts with commentary on academic language, systems of student support, education technology, and professional communication with families) developed and acquired during Demonstration Teaching.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C349": {
          "courseName": "Health Assessment ",
          "description": "The Health Assessment course is designed to enhance students’ knowledge and skills in health promotion, the early detection of illness, and prevention of disease. The course provides the relevant content and skills necessary to perform a comprehensive physical assessment of patients throughout the lifespan. Students are engaged in these processes through interviewing, history taking, and demonstrating an advanced-level physical examination. Dominant models, theories, and perspectives related to evidence-based wellness practices and health education strategies also are included in this challenging course. "
        },
        "C360": {
          "courseName": "Teacher Work Sample in English Language Learning ",
          "description": "The Teacher Work Sample is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills in this professional assessment. "
        },
        "C361": {
          "courseName": "Evidence Based Practice and Applied Nursing Research ",
          "description": "The Evidence Based Practice course will help you to learn how to design and conduct research to answer important questions about improving nursing practice and patient care delivery outcomes. After you are introduced to the basics of evidence-based practice, you will continue to implement the principles throughout your clinical experience. This will allow you to graduate with more competence and confidence to become a leader in the healthcare environment. "
        },
        "C362": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in relation to the slope of a curve and covers the knowledge and skills necessary to apply differential calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include functions, limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative, the power, chain, sum, product, and quotient rules applied to polynomial, trigonometric, exponential, and logarithmic functions, implicit differentiation, position, velocity, and acceleration, optimization, related rates, curve sketching, and L'Hopital's Rule. Pre-Calculus is a pre-requisite for this course. "
        },
        "C363": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in the slope of a curve and covers the knowledge and skills necessary to apply differential calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include functions, limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, sum, product, and quotient rules applied to polynomial, trigonometric, exponential, and logarithmic functions; implicit differentiation, position, velocity, and acceleration; optimization, related rates, curve sketching, and L'Hopital's rule. Precalculus is a prerequisite for this course. "
        },
        "C365": {
          "courseName": "Language Arts Instruction and Intervention ",
          "description": "Language Arts Instruction and Intervention helps students learn how to implement effective language arts instruction and intervention in the elementary classroom. Topics include written and spoken English, expanding students' knowledge, literature rich environments, differentiated instruction, technology for reading and writing, assessment strategies for reading and writing, and strategies for developing academic language. There are no prerequisites for this course. "
        },
        "C367": {
          "courseName": "Elementary Physical Education and Health Methods ",
          "description": "Elementary Physical Education and Health Methods helps students learn how to implement effective physical and health education instruction in the elementary classroom. Topics include healthy lifestyles, student safety, student nutrition, physical education, differentiated instruction for physical and health education, physical education across the curriculum, and public policy in health and physical education. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special  Education. "
        },
        "C371": {
          "courseName": "Concepts in Science ",
          "description": "Concepts in Science for undergraduates provides students seeking a bachelor's degree and initial teacher licensure in science education with an introduction to essential science themes present within and across all science disciplines, including chemistry, physics, biology, and the geosciences. These themes include comprehending the magnitude of the physical and natural world, analyzing and converting measurements, understanding the basic nature and behavior of matter and energy, examining atomic structure, identifying and naming basic types of chemical bonds, and analyzing and interpreting scientific data. Concepts in Science provides a solid foundation for future, in-depth scientific studies and should be taken prior to any other science content course. There are no prerequisites for this course. "
        },
        "C373": {
          "courseName": "General Chemistry I with Lab ",
          "description": "General Chemistry I with Lab for undergraduates provides students seeking initial teacher licensure in secondary chemistry with an introduction to the field of chemistry, the branch of science that studies the composition, structure, properties, and behavior of matter. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science is a prerequisite for this course. "
        },
        "C374": {
          "courseName": "General Chemistry II with Lab ",
          "description": "C374: General Chemistry II with Lab for undergraduates continues the study of general chemistry for students seeking initial teacher licensure in secondary chemistry. Building on the topics covered in General Chemistry I, General Chemistry II examines the behavior of gases and solutions, reaction rates and equilibrium, acids and bases, and oxidation-reduction reactions. Also, this course provides an introduction to three sub-disciplines of chemistry: organic chemistry, biochemistry, and nuclear chemistry. Laboratory experiences reinforce the essential skills required for conducting successful scientific investigations. C373: General Chemistry I for undergraduates is a prerequisite for this course. "
        },
        "C380": {
          "courseName": "Language Arts Instruction and Intervention ",
          "description": "Language Arts Instruction and Intervention helps students learn how to implement effective language arts instruction and intervention in the elementary classroom. Topics include written and spoken English, expanding students' knowledge, literature-rich environments, differentiated instruction, technology for reading and writing, assessment strategies for reading and writing, and strategies for developing academic language. There are no prerequisites for this course. "
        },
        "C381": {
          "courseName": "Elementary Mathematics Methods ",
          "description": "Elementary Mathematics Methods helps students learn to implement effective mathematics instruction in the elementary classroom. Topics include differentiated mathematics instruction, mathematical communication, mathematical tools for instruction, assessing mathematics understanding, integrating mathematics across the curriculum, critical thinking development, standards based mathematics instruction, and mathematical models and representation. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "C382": {
          "courseName": "Elementary Science Methods ",
          "description": "Elementary Science Methods helps students learn how to implement effective science instruction in the elementary classroom. Topics include processes of science, science inquiry, science learning environments, instructional strategies for science, differentiated instruction for science, assessing science understanding, technology for science instruction, standards based science instruction, integrating science across curriculum, and science beyond the classroom. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "C388": {
          "courseName": "Science, Technology, and Society ",
          "description": "Science, Technology, and Society explores the ways in which science influences and is influenced by society and technology. Science is a humanistic and social endeavor and serves the needs of ever-changing societies by providing methods for observing, questioning, discovering, and communicating information about the physical and natural world. This course prepares educators to explain the nature and history of science, the various applications of science, and the scientific and engineering processes used to conduct investigations, make decisions, and solve problems. There are no prerequisites for this course. "
        },
        "C389": {
          "courseName": "Science, Technology, and Society ",
          "description": "Science, Technology, and Society explores the ways in which science influences and is influenced by society and technology. A humanistic and social endeavor, science serves the needs of ever-changing societies by providing methods for observing, questioning, discovering, and communicating information about the physical and natural world. This course prepares educators to explain the nature and history of science, the various applications of science, and the scientific and engineering processes used to conduct investigations, make decisions, and solve problems. There are no prerequisites for this course. "
        },
        "C396": {
          "courseName": "English Pedagogy ",
          "description": "English Pedagogy examines pedagogical applications for the teaching of reading, literature, composition, and related English Language Arts (ELA) content and skills for middle and secondary schools. Focused on fostering and developing pedagogical content knowledge in the aforementioned areas, students will analyze assessment strategies and incorporate methods of literacy instruction into their instructional planning to meet the needs of diverse learners. This course helps students prepare and develop skills for classroom practice, lesson planning, and working in school settings. C397 Preclinical Experiences in English is a prerequisite. "
        },
        "C405": {
          "courseName": "Anatomy and Physiology II ",
          "description": "This course introduces advanced concepts of human anatomy and physiology through the investigation of the structures and functions of the body's organ systems. Students will have the opportunity to explore the body through laboratory experience and apply the concepts covered in this course. For nursing students, this is the second of two anatomy and physiology courses within the program of study. "
        },
        "C425": {
          "courseName": "Healthcare Delivery Systems, Regulation, and Compliance ",
          "description": "This course provides an overview of the U.S. healthcare system and focuses on developing an understanding of the various sectors and roles involved in this complex industry. Policy and compliance issues are also addressed to facilitate an appreciation for the highly regulated nature of healthcare delivery. "
        },
        "C426": {
          "courseName": "Healthcare Values and Ethics ",
          "description": "This course explores ethical standards and considerations common to the healthcare environment such as access to care, confidentiality, the allocation of limited resources, and billing practices. This course also focuses on the distinct value system associated with the healthcare industry, as well as the values of professionalism. "
        },
        "C427": {
          "courseName": "Technology Applications in Healthcare ",
          "description": "This course explores how technology continues to change and influence the healthcare industry. Practical managerial applications are explored as well as the legal, ethical, and practical aspects of access to health and disease information. Ensuring the protection of private health information is also emphasized. "
        },
        "C428": {
          "courseName": "Financial Resource Management in Healthcare ",
          "description": "Financial Resource Management in Healthcare   This course examines the financial environment of the healthcare industry including principles involved in managed care. It also explores the revenue and expense structures for different sectors within the industry while emphasizing funding and reimbursement practices of healthcare. "
        },
        "C429": {
          "courseName": "Healthcare Operations Management ",
          "description": "This course builds upon basic principles of management, organizational behavior, and leadership. Specific processes and business principles for managing operations in interdependent and multi-disciplinary healthcare organizations are explored. Marketing strategies, communication skills, and the ability to establish and maintain relationships while ensuring productivity that is efficient, safe, and meets the needs of all stakeholders is emphasized. "
        },
        "C430": {
          "courseName": "Healthcare Quality Improvement and Risk Management ",
          "description": "This course emphasizes principles of quality management and risk management in order to ensure safety, maximize patient outcomes, and continuously improve organizational outcomes. This course also examines the broader impact of organizational culture and its influence on productivity, quality, and risk. "
        },
        "C431": {
          "courseName": "Healthcare Research and Statistics ",
          "description": "This course builds upon an understanding of research methods and quantitative analysis. Concepts of population health, epidemiology, and evidence-based practices provide the foundation for understanding the importance of data for informing healthcare organizational decisions. "
        },
        "C432": {
          "courseName": "Healthcare Management and Strategy ",
          "description": "This course builds upon basic principles of strategic management and explores healthcare organizational structures and processes. The importance of the collaborative nature and interrelationships among business functions is emphasized. Creating a healthcare vision and designing business plans within a healthcare environment is also examined. "
        },
        "C439": {
          "courseName": "Healthcare Management Capstone ",
          "description": "This course is the culminating experience and assessment of healthcare business administration. This course requires the student to integrate and synthesize managerial skills with healthcare knowledge, resulting in a high quality final project that demonstrates professional managerial proficiency. "
        },
        "C453": {
          "courseName": "Clinical Microbiology ",
          "description": "Clinical Microbiology introduces general concepts, methods, and applications of microbiology from a health sciences perspective. The course is designed to provide healthcare professionals with a basic understanding of how various diseases are transmitted and controlled. Students will examine the structure and function of microorganisms, including the roles that they play in causing major diseases. The course also explores immunological, pathological, and epidemiological factors associated with disease. To assist students in developing an applied, patient-focused understanding of microbiology, this course is complimented by several lab experiments that allow students to: practice aseptic techniques, grow bacteria and fungi, identify characteristics of bacteria and yeast based on biochemical and environmental tests, determine antibiotic susceptibility, discover the microorganisms growing on objects and surfaces, and determine the Gram characteristic of bacteria. This course has no prerequisites. "
        },
        "C455": {
          "courseName": "English Composition I ",
          "description": "English Composition I introduces candidates to the types of writing and thinking that are valued in college and beyond. Candidates will practice writing in several genres with emphasis placed on writing and revising academic arguments. Instruction and exercises in grammar, mechanics, research documentation, and style are paired with each module so that writers can practice these skills as necessary. Composition I is a foundational course designed to help candidates prepare for success at the college level. There are no prerequisites for English Composition I. "
        },
        "C456": {
          "courseName": "English Composition II ",
          "description": "English Composition II introduces candidates to the types of research and writing that are valued in college and beyond. Candidates will practice writing, with emphasis placed on research, writing, and revising an academic argument. Instruction and exercises in grammar, mechanics, research documentation, and style are paired with each module so that writers can practice these skills as necessary. Composition II is a foundational course designed to help candidates prepare for success at the college level. Composition I is the prerequisite for Composition II. "
        },
        "C458": {
          "courseName": "Health, Fitness, and Wellness ",
          "description": "Health, Fitness, and Wellness focuses on the importance and foundations of good health and physical fitness—particularly for children and adolescents—addressing health, nutrition, fitness, and substance use and abuse. "
        },
        "C464": {
          "courseName": "Introduction to Communication ",
          "description": "This introductory communication course allows candidates to become familiar with the fundamental communication theories and practices necessary to engage in healthy professional and personal relationships. Candidates will survey human communication on multiple levels and critically apply the theoretical grounding of the course to interpersonal, intercultural, small group, and public presentational contexts. The course also encourages candidates to consider the influence of language, perception, culture, and media on their daily communicative interactions. In addition to theory, candidates will engage in the application of effective communication skills through systematically preparing and delivering an oral presentation. By practicing these fundamental skills in human communication, candidates become more competent communicators as they develop more flexible, useful, and discriminatory communicative practices in a variety of contexts. Note: There are references within this video to Taskstream. If Taskstream is not part of your student experience, please disregard, and locate your task(s) within your course. "
        },
        "C468": {
          "courseName": "Information Management and the Application of Technology ",
          "description": "Information Management and the Application of Technology helps the candidate learn how to identify and implement the unique responsibilities of nurses related to the application of technology and the management of patient information. This includes understanding the evolving role of nurse informaticists; demonstrating the skills needed to use electronic health records; identifying nurse-sensitive outcomes that lead to quality improvement measures; supporting the contributions of nurses to patient care; examining workflow changes related to the implementation of computerized management systems; and learning to analyze the implications of new technology on security, practice, and research. "
        },
        "C475": {
          "courseName": "Care of the Older Adult ",
          "description": "Care of the Older Adult adapts the concepts from prior coursework to the care of older adults. An understanding of the effects that policy and legislation have on how healthcare systems treat aging patients sets a foundation for improving their care. Students will apply health assessment skills and evidence-based standards in such a way to account for the specific needs of older adults. Emphasis is placed on the importance of maintaining the dignity of older adults by focusing on cultural, religious, spiritual, and communication needs, and by collaborating on care with older adults, families, and caregivers. "
        },
        "C483": {
          "courseName": "Principles of Management ",
          "description": "Principles of Management provides students with an introductory look at the discipline of management and its context within the business environment. Students of this course build on previously mastered competencies by taking a more in-depth look at management as a discipline and how it differs from leadership while further exploring the importance of communication within business. This course provides students with a business generalist overview in the areas of strategic planning, total quality, entrepreneurship, conflict and change, human resource management, diversity, and organizational structure. "
        },
        "C484": {
          "courseName": "Organizational Behavior and Leadership ",
          "description": "Organizational Behavior and Leadership explores how to lead and manage effectively in diverse business environments. The course requires students to demonstrate the ability to apply organizational leadership theories and management strategies in a series of scenario-based problems. "
        },
        "C489": {
          "courseName": "Organizational Systems and Quality Leadership ",
          "description": "Nurses serve as clinicians, managers, and mentors to shape the future of healthcare and affect patient care outcomes in positive ways. This course will help students be more confident and better prepared to assume leadership roles regardless of their position in the healthcare delivery system. This advanced leadership course focuses on the concepts of patient safety; improvement science; balancing cost, quality, and access through the triple aim; and leadership and patient/family-centered care. Students will develop mastery of advanced competencies, particularly in patient safety in quality improvement science. "
        },
        "C493": {
          "courseName": "Leadership and Professional Image ",
          "description": "Nursing is a practice discipline that includes direct and indirect care activities that affect health outcomes. Baccalaureate nursing students are developing new competencies in leadership, and in order to achieve mastery, must apply those competencies to live practice experiences and situations. In this course students will complete a Leadership Learning Experience (LLE) and develop their own personal professional portfolio. The professional portfolio is a collection of artifacts from BSN coursework as well as a resume and personal statement. "
        },
        "C494": {
          "courseName": "Advanced Standing for RN License ",
          "description": "Advanced Standing for RN License "
        },
        "C498": {
          "courseName": "MS, Information Technology Management Capstone ",
          "description": "MSITM Capstone course challenges students to demonstrate mastery of all the MSITM program outcomes. The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
        },
        "C612": {
          "courseName": "Mathematics: Content Knowledge ",
          "description": "Mathematics: Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful secondary mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, PreCalculus, Calculus I, and Calculus II. Linear Algebra, and Calculus III are recommended. "
        },
        "C613": {
          "courseName": "Middle School Mathematics: Content Knowledge ",
          "description": "Mathematics: Middle School Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful middle school mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, and Pre-Calculus. "
        },
        "C614": {
          "courseName": "Biology: Content Knowledge ",
          "description": "This comprehensive course examines a student’s conceptual understanding of a broad range of biology topics. High school biology teachers must help students make connections between isolated topics. This course starts with macromolecules that make up cellular components and continues with understanding the many cellular processes that allow life to exist. Connections are then made between genetics and evolution. Classification of organisms leads into plant and animal development that study the organ systems and their role in maintaining homeostasis. The course finishes by studying ecology and the effect humans have on the environment. "
        },
        "C615": {
          "courseName": "Physics: Content Knowledge ",
          "description": "Physics: Content Knowledge covers the advanced content knowledge that a secondary physics teacher is expected to know and understand. Topics include nature and impact of science and engineering, principle and models of matter and energy, mechanics, electricity and magnetism, waves, and science teaching and pedagogy. "
        },
        "C616": {
          "courseName": "Middle School Science: Content Knowledge ",
          "description": "This course covers the content knowledge that a middle-level science teacher is expected to know and understand. Topics include scientific methodologies, history of science, basic science principles, physical sciences, life sciences, earth and space sciences, and the role of science and technology and their impact on society. "
        },
        "C617": {
          "courseName": "Chemistry: Content Knowledge ",
          "description": "Chemistry: Content Knowledge provides advanced instruction in the main areas of chemistry for which secondary chemistry teachers are expected to demonstrate competency. Topics include matter and energy, thermochemistry, structure, bonding, reactivity, biochemistry and organic chemistry, solutions, the nature of science and technology, mathematics, and laboratory procedures. "
        },
        "C618": {
          "courseName": "Earth Science: Content Knowledge ",
          "description": "This course covers the advanced content knowledge that a secondary earth science teacher is expected to know and understand. Topics include basic scientific principles of earth and space sciences, tectonics and internal earth processes, earth materials and surface processes, history of the Earth and its life-forms, Earth's atmosphere and hydrosphere, and astronomy. "
        },
        "C624": {
          "courseName": "Biochemistry ",
          "description": "Biochemistry covers the structure and function of the four major polymers produced by living organisms. These include nucleic acids, proteins, carbohydrates, and lipids. This course focuses on application. Be sure to understand the underlying biochemistry in order to grasp how it is applied. By successfully completing this course, you will gain an introductory understanding of the chemicals and reactions that sustain life. You will also begin to see the importance of this subject matter to health. "
        },
        "C625": {
          "courseName": "Biochemistry ",
          "description": "Biochemistry covers the structure and function of the four major polymers produced by living organisms. These include nucleic acids, proteins, carbohydrates, and lipids. This course focuses on application and the underlying biochemistry in order to grasp how it is applied. This course will help students gain an introductory understanding of the chemicals and reactions that sustain life. Students will see the importance of this subject matter to health. "
        },
        "C635": {
          "courseName": "MA, Mathematics Education (K-6) Capstone ",
          "description": "MA, Mathematics Education (K-6) Capstone Written Project takes the student through the steps of planning and conducting research on a topic or issue related to the students' practice setting. The result is expected to be a significant piece of research, culminating in a written research report, including sections describing a literature review, methodology, and detailed analysis and reporting of results. Prerequisite Courses: Research Foundations (C224), Research Questions and Literature Review (C225), Research Design and Analysis (C226), and Research Proposals (C227) or permission of a faculty manager. Additionally, students wishing to add the Capstone with fewer than eight weeks remaining in the term must receive permission of the faculty manager. "
        },
        "C645": {
          "courseName": "Science Methods ",
          "description": "Science Methods provides an introduction to science teaching methods for graduate students seeking initial licensure or an additional endorsement in secondary biology, chemistry, geosciences, physics, or middle grades general science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Students seeking initial licensure should complete Curriculum, Instruction, and Assessment before this course. There are no prerequisites for students seeking an endorsement in a new content area. "
        },
        "C646": {
          "courseName": "Trigonometry and Precalculus ",
          "description": "Trigonometry and Precalculus covers the knowledge and skills necessary to apply trigonometry, complex numbers, systems of equations, vectors and matrices, sequence and series, and to use appropriate technology to model and solve real-life problems. Topics include degrees; radians and arcs; reference angles and right triangle trigonometry; applying, graphing and transforming trigonometric functions and their inverses; solving trigonometric equations; using and proving trigonometric identities; geometric, rectangular, and polar approaches to complex numbers; DeMoivre's Theorem; systems of linear equations and matrix-vector equations; systems of nonlinear equations; systems of inequalities; and arithmetic and geometric sequences and series. College Algebra is a prerequisite for this course. "
        },
        "C647": {
          "courseName": "Trigonometry and Precalculus ",
          "description": "Trigonometry and Precalculus covers the knowledge and skills necessary to apply trigonometry, complex numbers, systems of equations, vectors and matrices, and sequences and series, and to use appropriate technology to model and solve real-life problems. Topics include degrees; radians and arcs; reference angles and right triangle trigonometry; applying, graphing and transforming trigonometric functions and their inverses; solving trigonometric equations; using and proving trigonometric identities; geometric, rectangular, and polar approaches to complex numbers; DeMoivre's Theorem; systems of linear equations and matrix-vector equations; systems of nonlinear equations; systems of inequalities; and arithmetic and geometric sequences and series. College Algebra is a prerequisite for this course. "
        },
        "C649": {
          "courseName": "Geology I: Physical ",
          "description": "Geology I: Physical provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to minerals and rocks, the physical features of the Earth, and the internal and surface processes that shape those features. This course has no prerequisites. "
        },
        "C650": {
          "courseName": "Geology I: Physical ",
          "description": "Geology I: Physical provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to minerals and rocks, the physical features of the Earth, and the internal and surface processes that shape those features. This course has no prerequisites. "
        },
        "C652": {
          "courseName": "Heredity and Genetics ",
          "description": "Heredity and Genetics is an introductory course for undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education. This course addresses the basic principles of heredity and the function of molecular genetics. Topics include Mendelian and non-Mendelian inheritance and population genetics. This course has no prerequisites. "
        },
        "C653": {
          "courseName": "Heredity and Genetics ",
          "description": "Heredity and Genetics is an introductory course for graduate students seeking initial licensure or endorsement and/or students earning their MA degree in secondary or middle grade science education. This course addresses the basic principles of heredity and the function of molecular genetics. Topics include Mendelian and non-Mendelian inheritance and population genetics. This course has no prerequisites. "
        },
        "C654": {
          "courseName": "Zoology ",
          "description": "Zoology provides undergraduate students seeking licensure or endorsement in secondary science education with an introduction to the field of zoology. Zoology includes the study of major animal phyla emphasizing characteristics, variations in anatomy, life cycles, adaptations, and relationships among the animal kingdom. A prerequisite for this course is Introduction to Biology. "
        },
        "C655": {
          "courseName": "Zoology ",
          "description": "Zoology provides graduate students seeking licensure or endorsement and/or their MA degree in secondary science education with an introduction to the field of zoology. Zoology includes the study of major animal phyla emphasizing characteristics, variations in anatomy, life cycles, adaptations, and relationships among the animal kingdom. A prerequisite for this course is Introduction to Biology. "
        },
        "C656": {
          "courseName": "Calculus III ",
          "description": "Calculus III is the study of calculus conducted in three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply calculus of multiple variables while using the appropriate technology to model and solve real-life problems. Topics include: infinite series and convergence tests (integral, comparison, ratio, root, and alternating), power series, taylor polynomials, vectors, lines and planes in three dimensions, dot and cross products, multivariable functions, limits, and continuity, partial derivatives, directional derivatives, gradients, tangent planes, normal lines, and extreme values. Calculus II is a prerequisite for this course. "
        },
        "C657": {
          "courseName": "Calculus III ",
          "description": "Calculus III is the study of calculus conducted in three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply calculus of multiple variables while using the appropriate technology to model and solve real-life problems. Topics include: infinite series and convergence tests (integral, comparison, ratio, root, and alternating), power series,taylor polynomials, vectors, lines and planes in three dimensions, dot and cross products, multivariable functions, limits, and continuity, partial derivatives, directional derivatives, gradients, tangent planes, normal lines, and extreme values. Calculus II is a prerequisite for this course. "
        },
        "C659": {
          "courseName": "Conceptual Physics ",
          "description": "Conceptual Physics provides a broad, conceptual overview of the main principles of physics, including mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. Problem-solving activities and laboratory experiments provide students with opportunities to apply these main principles, creating a strong foundation for future studies in physics. There are no prerequisites for this course. "
        },
        "C670": {
          "courseName": "Concepts in Science ",
          "description": "Concepts in Science for graduates provides already-licensed teachers seeking an additional license or endorsement in science education with an introduction to essential science themes present within and across all science disciplines, including chemistry, physics, biology, and the geosciences. These themes include comprehending the magnitude of the physical and natural world, analyzing and converting measurements, understanding the basic nature and behavior of matter and energy, examining atomic structure, identifying and naming basic types of chemical bonds, and analyzing and interpreting scientific data. Concepts in Science provides a solid foundation for future, in-depth, scientific studies and should be taken prior to any other science content course. There are no prerequisites for this course. "
        },
        "C672": {
          "courseName": "General Chemistry I with Lab ",
          "description": "General Chemistry I with Lab for graduates provides an introduction to the field of chemistry to alreadylicensed teachers seeking an additional license or endorsement in secondary chemistry. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science is a prerequisite for this course. "
        },
        "C673": {
          "courseName": "General Chemistry II with Lab ",
          "description": "General Chemistry II with Lab for graduates continues the study of general chemistry for already-licensed teachers seeking an additional license or endorsement in secondary chemistry. Building on the topics covered in General Chemistry I, General Chemistry II examines the behavior of gases and solutions, reaction rates and equilibrium, acids and bases, and oxidation-reduction reactions. Also, this course provides an introduction to three subdisciplines of chemistry: organic chemistry, biochemistry, and nuclear chemistry. Laboratory experiences reinforce the essential skills required for conducting successful scientific investigations. C672: General Chemistry I for graduates is a prerequisite for this course. "
        },
        "C683": {
          "courseName": "Natural Science Lab ",
          "description": "This course provides students an introduction to using the scientific method and engaging in scientific research to reach conclusions about the natural world. Students will design and carry out an experiment to investigate a hypothesis by gathering quantitative data. They will also research a specific ecosystem using academic sources and draw conclusions from their findings. "
        },
        "C715": {
          "courseName": "Organizational Behavior ",
          "description": "Organizational Behavior and Leadership explores how to lead and manage effectively in diverse business environments. Students are asked to demonstrate the ability to apply organizational leadership theories and management strategies in a series of scenario-based problems. "
        },
        "C716": {
          "courseName": "Business Communication ",
          "description": "Business Communication is a survey course of communication skills needed in the business environment. Course content includes writing messages, reports, and résumés and delivering oral presentations. The course emphasizes communication processes, writing skills, message types, and presentation of data. The development of these skills is integrated with the use of technology. "
        },
        "C717": {
          "courseName": "Business Ethics ",
          "description": "Business Ethics is designed to enable students to identify the ethical and socially responsible courses of action available through the exploration of various scenarios in business. Students will also learn to develop appropriate ethics guidelines for a business. This course has no prerequisites. "
        },
        "C720": {
          "courseName": "Operations and Supply Chain Management ",
          "description": "Operations and Supply Chain Management provides a streamlined introduction to how organizations efficiently produce goods and services, determine supply chain management strategies, and measure performance. Emphasis is placed on integrative topics essential for managers in all disciplines, such as supply chain management, product development, and capacity planning. This course will guide students in analyzing processes, managing quality for both services and products, and measuring performance while creating value along the supply chain in a global environment. Topics include forecasting, product and service design, process design and location analysis, capacity planning, management of quality and quality control, inventory management, scheduling, supply chain management, and performance measurement. "
        },
        "C721": {
          "courseName": "Change Management ",
          "description": "Change Management provides an understanding of change and an overview of successfully managing change using various methods and tools. Emphasizing change theories and various best practices, this course covers how to recognize and implement change using an array of other effective strategies, including those related to innovation and leadership. Other topics include approaches to change, diagnosing and planning for change, implementing change, and sustaining change. "
        },
        "C722": {
          "courseName": "Project Management ",
          "description": "Project Management prepares you to manage projects from start to finish within any organization structure. The course represents a view into different project-management methods and delves into topics such as project profiling and phases, constraints, building the project team, scheduling, and risk. You will be able to grasp the full scope of projects you may work with on in the future, and apply proper management approaches to complete a project. The course features practice in each of the project phases as you learn how to strategically apply project-management tools and techniques to help organizations achieve their goals. "
        },
        "C723": {
          "courseName": "Quantitative Analysis For Business ",
          "description": "Quantitative Analysis for Business explores various decision-making models, including expected value models, linear programming models, and inventory models. This course helps student learn to analyze data by using a variety of analytic tools and techniques to make better business decisions. In addition, it covers developing project schedules using the Critical Path Method. Other topics include calculating and evaluating formulas, measures of uncertainty, crash costs, and visual representation of decision-making models using electronic spreadsheets and graphs. This course has no prerequisites. "
        },
        "C724": {
          "courseName": "Information Systems Management ",
          "description": "Information Systems Management provides an overview of many facets of information systems applicable to business. The course explores the importance of viewing information technology (IT) as an organizational resource that must be managed, so that it supports or enables organizational strategy. "
        },
        "C728": {
          "courseName": "Secondary Disciplinary Literacy ",
          "description": "Secondary Disciplinary Literacy examines teaching strategies designed to help learners in middle and high school improve upon the literacy skills required to read, write, and think critically while engaging content in different academic disciplines. Themes include exploring how language structures, text features, vocabulary, and context influence reading comprehension across the curriculum. The course highlights strategies and tools designed to help teachers assess the reading comprehension and writing proficiency of learners and provides strategies to support students' reading and writing success in all curriculum areas. This course has no prerequisites. "
        },
        "C730": {
          "courseName": "Secondary Reading Instruction and Interventions ",
          "description": "Secondary Reading Instruction and Interventions explores the comprehensive, studentcentered response to intervention (RTI) model used to identify and address the needs of learners in middle school and high school who struggle with reading comprehension and/or information retention. Course content provides educators with effective strategies designed to scaffold instruction and help learners develop increased skill in the following areas: reading, vocabulary, text structures and genres, and logical reasoning related to the academic disciplines. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and Presentation AND Instructional Planning and Presentation in Special Education. "
        },
        "C732": {
          "courseName": "Elementary Disciplinary Literacy ",
          "description": "Elementary Disciplinary Literacy examines teaching strategies designed to help learners in grades K–6 develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help learners distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction  to Instructional Planning and Presentation AND Instructional Planning and Presentation in Elementary or Special Education. "
        },
        "C736": {
          "courseName": "Evolution ",
          "description": "Students will learn why evolution is the fundamental concept that underlies all life sciences and how it contributes to advances in medicine, public health, and conservation. Course participants will gain a firm understanding of the basic mechanisms of evolution, including the process of speciation, and how these systems have given rise to the great diversity of life in the world today. They will also explore how new ideas, discoveries, and technologies are modifying prior evolutionary concepts. Ultimately, the course will explain how evolution works and how we know what we know. "
        },
        "C737": {
          "courseName": "Evolution ",
          "description": "This course addresses why evolution is the fundamental concept that underlies all life sciences and how it contributes to advances in medicine, public health, and conservation. This course helps participants gain a firm understanding of the basic mechanisms of evolution including the process of speciation and how these systems have given rise to the great diversity of life in the world today. This course also explore how new ideas, discoveries, and technologies are modifying prior evolutionary concepts. Ultimately, the course will explain how evolution works and how we know what we know. "
        },
        "C738": {
          "courseName": "Space, Time and Motion ",
          "description": "Throughout history, humans have grappled with questions about the origin, workings, and behavior of the universe. This seminar begins with a quick tour of discovery and exploration in physics, from the ancient Greek philosophers on to Galileo Galilei, Isaac Newton, and Albert Einstein. Einstein's work then serves as the departure point for a detailed look at the properties of motion, time, space, matter, and energy. The course considers Einstein's special theory of relativity, his photon hypothesis, wave-particle duality, his general theory of relativity and its implications for astrophysics and cosmology, as well as his three-decade quest for a unified field theory. It also looks at Einstein as a social and political figure and his contributions as a social and political force. Scientist-authored essays, online interaction, videos, and web resources enable learners to trace this historic path of discovery and explore implications of technology for society, energy production in stars, black holes, the big bang, and the role of the scientist in modern society. "
        },
        "C739": {
          "courseName": "Space, Time and Motion ",
          "description": "This course begins with a quick tour of discovery and exploration in physics, from the ancient Greek philosophers on to Galileo Galilei, Isaac Newton, and Albert Einstein. Einstein’s work then serves as the departure point for a detailed look at the properties of motion, time, space, matter, and energy. The course considers Einstein’s special theory of relativity, his photon hypothesis, wave-particle duality, his general theory of relativity and its implications for astrophysics and cosmology, as well as his three-decade quest for a unified field theory. It also looks at Einstein as a social and political figure and his contributions as a social and political force. Scientist-authored essays, online interaction, videos, and web resources enable learners to trace this historic path of discovery and explore implications of technology for society, energy production in stars, black holes, the Big Bang, and the role of the scientist in modern society. "
        },
        "C769": {
          "courseName": "IT Capstone Written Project ",
          "description": "The capstone project consists of a technical work proposal, the proposal’s implementation, and a postimplementation report that describes the graduate’s experience in developing and implementing the capstone project. The capstone project should be presented and approved by the course instructor in relation to the graduate’s technical emphasis. "
        },
        "C773": {
          "courseName": "User Interface Design ",
          "description": "This course covers tools and techniques employed in user interface design, including web and mobile applications. Concepts of clarity, usability, and detectability are included in this course, as well as other design elements such as color schemes, typography, and layout. Techniques like wireframing, usability testing, and SEO optimization are also covered. "
        },
        "C777": {
          "courseName": "Web Development Applications ",
          "description": "This course builds upon a student's manual coding skills by teaching how to develop web documents and pages using the web development trifecta: Hypertext Markup Language version 5 (HTML5), Cascading Style Sheets version 3 (CSS3), and JavaScript. Students will utilize the skills learned in this course to create web documents and pages that easily adapt to display on both traditional and mobile devices. In addition, students will learn techniques for code validation and testing, form creation, inline form field validation, and mobile design for browsers and apps, including Responsive Web Design (RWD). "
        },
        "C783": {
          "courseName": "Project Management ",
          "description": "Project Management is a thorough exploration of the inputs, tools, techniques, and outputs across the five process groups and 10 knowledge areas identified in the Project Management Body of Knowledge (PMBOK) Guide. The essential concepts and practical scenarios included enable students to build the competencies of an effective project manager. "
        },
        "C784": {
          "courseName": "Applied Healthcare Statistics ",
          "description": "Applied Healthcare Probability and Statistics is designed to help develop competence in the fundamental concepts of basic mathematics, introductory algebra, and statistics and probability. These concepts include basic arithmetic with fractions and signed numbers; introductory algebra and graphing; descriptive statistics; regression and correlation; and probability. Statistical data and probability are now commonplace in the healthcare field. This course will help candidates make informed decisions about which studies and results are valid, which are not, and how those results affect your decisions. This course will give candidates background in what constitutes sound research design and how to appropriately model phenomena using statistical data. Additionally, this course guides candidates in calculating simple probabilities based on events which occur in the healthcare profession. This course will prepare candidates for studies at Whalley, as well as in the healthcare profession. "
        },
        "C787": {
          "courseName": "Health and Wellness Through Nutritional Science ",
          "description": "Nutritional ignorance or misunderstandings are at the root of the health problems that most Americans face today. Nurses need to be armed with the most current information available about nutrition science, including how to understand nutritional content of food; implications of exercise and activity on food consumption and weight management, and management of community or population specific nutritional challenges. The Health and Wellness Through Nutritional Science course should prepare nurses to provide support, guidance, and teaching about incorporation of sound nutritional principles into daily life for health promotion. This course covers nutrition to support wellness; healthy nutritional choices; nutrition and physical activity; nutrition through the lifecycle; safety and security of food; and nutrition and global health environments. "
        },
        "C790": {
          "courseName": "Foundations in Nursing Informatics ",
          "description": "This course addresses the integration of technology to improve and support nursing practice. It provides nurses with a foundational understanding of nursing informatics theory, practice, and applications. Topics include the role of nursing in informatics; use of computer technology for clinical documentation, communication, and workflows; problem identification; project implementation; and best practices. "
        },
        "C792": {
          "courseName": "Data Modeling and Database Management Systems ",
          "description": "This graduate course is designed to engage the student in planning, analyzing, and designing a relational database management system (DBMS) for use by nurse administrators, clinicians, educators, and informaticists. This experience will provide the knowledge needed to advocate for nursing informatics needs within the field of healthcare. "
        },
        "C797": {
          "courseName": "Data Science and Analytics ",
          "description": "This course addresses the interdisciplinary and emerging field of data science in healthcare. Candidates learn to combine tools and techniques from statistics, computer science, data visualization, and the social sciences to solve problems using data. Topics include data analysis; database management; inferential and descriptive statistics; statistical inference; and process improvement. "
        },
        "C798": {
          "courseName": "Informatics System Analysis and Design ",
          "description": "In Informatics System Analysis and Design, a broad understanding of data systems is covered to build upon the Foundations in Nursing Informatics course. The importance of effective interoperability, functionality, data access, and user satisfaction are addressed. The student will be analyzing reports and integrating federal regulations, research principles, and principles of environmental health in the construction of a real-world systems analysis and design project. This course will be directly applicable to healthcare settings as electronic records management has become compulsory for healthcare providers. All of the information in this course will be directly tied to the delivery of quality patient care and patient safety. Foundations in Nursing Informatics is recommended as a prerequisite. "
        },
        "C799": {
          "courseName": "Healthcare Ecosystems ",
          "description": "Healthcare Ecosystems explores the history and state of healthcare organizations in an ever-changing environment. This course covers how agencies influence healthcare delivery through legal, licensure, certification, and accreditation standards. The course will also discuss how new technologies and trends keep healthcare delivery innovative and current. "
        },
        "C801": {
          "courseName": "Health Information Law and Regulations ",
          "description": "Health Information Law and Regulations prepares students to manage health information in compliance with legal guidelines and teaches how to respond to questions and challenges when legal issues occur. This course presents the types of situations occurring in health information management that could result in ethical dilemmas and establishes a foundation for work based on legal and ethical guidelines. "
        },
        "C802": {
          "courseName": "Foundations in Healthcare Information Management ",
          "description": "Foundations in Healthcare Information Management applies theories from business, IT, management, medicine, and consumer-centered healthcare skills. Students will learn to evaluate and analyze health information systems for implementation in health information management. There are no prerequisites for this course. "
        },
        "C803": {
          "courseName": "Data Analytics and Information Governance ",
          "description": "Data Analytics and Information Governance explores the structure, methods, and approaches for using health information in the healthcare industry. By focusing on quality data collection, analytics, and industry regulations, students will examine tools that ensure quality data collection as well as to use data to improve quality of care. This course has no prerequisites. "
        },
        "C804": {
          "courseName": "Medical Terminology ",
          "description": "Medical Terminology focuses on the basic components of medical terminology and how terminology is used when discussing various body structures and systems. Proper use of medical terminology is critical for accurate and clear communication among medical staff, health professionals, and patients. In addition to the systems of the body, this course will discuss immunity, infections, mental health, and cancer. "
        },
        "C805": {
          "courseName": "Pathophysiology ",
          "description": "Pathophysiology is an overview of the pathology and treatment of diseases in the human body and its systems. This course will explain the processes in the body that result in the signs and symptoms of disease, as well as therapeutic procedures in managing or curing the disease. The content draws on a knowledge of anatomy and physiology to understand how diseases manifest themselves and how they affect the body. "
        },
        "C807": {
          "courseName": "Healthcare Compliance ",
          "description": "Healthcare Compliance examines the role of the coding professional within healthcare information management. The course covers compliance plans, issues that arise with noncompliance, and management of internal and external audits. "
        },
        "C808": {
          "courseName": "Classification Systems ",
          "description": "Classification Systems provides a comprehensive approach to learning about medical coding classification, coding audits, and quality standards. Candidates will be exposed to electronic health record systems and leadership principles as they relate to management of ICD and CPT codes. There are no prerequisites for this course. "
        },
        "C810": {
          "courseName": "Foundations in Healthcare Data Management ",
          "description": "Foundations in Healthcare Data Management introduces students to the concepts and terminology used in health data and health information management. This course teaches students how to apply data management and governance principles in the healthcare environment. The student will learn about electronic health records (EHR), legal considerations, information governance, data management, health information management (HIM), and secondary data sources. In addition to the e-text and numerous additional articles and video resources, the student will engage with case studies and knowledge checks to assist with learning. There are no prerequisites for this course. "
        },
        "C811": {
          "courseName": "Healthcare Financial Resource Management ",
          "description": "Healthcare Financial Resource Management examines financial practices within healthcare industries to promote effective management at department and organization levels. Focusing on financial processes associated with facility operations in the healthcare field, this course will analyze the impact of strategic financial planning and regulatory control processes. This course has no prerequisites. "
        },
        "C812": {
          "courseName": "Healthcare Reimbursement ",
          "description": "Healthcare Reimbursement explores financial practices within the healthcare industry as they relate to reimbursement policies. This course identifies how reimbursement systems impact the revenue cycle and a health information manager's role. This course has no prerequisites. "
        },
        "C813": {
          "courseName": "Healthcare Statistics and Research ",
          "description": "Healthcare Statistics and Research explores the use of statistical data to support process improvement through health information research. Health information management (HIM) professionals use information systems to gather, analyze, and present data in response to administrative and clinical needs. This course has no prerequisites. "
        },
        "C815": {
          "courseName": "Quality and Performance Management and Methods ",
          "description": "Quality and Performance Management and Methods examines quality initiatives within healthcare. Quality issues cover human resource management, employee performance, and patient safety. This course focuses on quality improvement initiatives and performance improvement with the health information management perspective. "
        },
        "C816": {
          "courseName": "Healthcare System Applications ",
          "description": "Healthcare System Applications introduces students to information systems. This course includes important topics related to management of information systems (MIS), such as system development and business continuity. The course also provides an overview of management tools and issue tracking systems. This course has no prerequisites. "
        },
        "C820": {
          "courseName": "Professional Leadership and Communication for Healthcare ",
          "description": "The Professional Communication and Leadership in Healthcare course is designed to help students prepare for success in the online environment at Whalley University and beyond. Student success starts with the social support and self-reflective awareness that will prepare students to weather the challenges of academic programs. In this course students will participate in group activities and complete several individual assignments. The group activities are aimed at finding support and gaining insight from other students. The assignments are intended to give the student an opportunity to reflect about where they are and where they would like to be. The activities in each group meeting are designed to give students several tools they can use to achieve success. This course is designed as a five-part intensive learning experience. Students will attend five group meetings during the term. At each meeting students will engage in activities that help them understand their own educational journey and find support and inspiration in the journey of others.   "
        },
        "C832": {
          "courseName": "Chemistry with Lab ",
          "description": "Chemistry with Lab for undergraduates provides students seeking initial teacher licensure in middle grades science or secondary physics, biological science, or earth science with an introduction to the field of chemistry, the branch of science that studies the composition, structure, properties, and behavior of matter. Designed for those not majoring in chemistry education, this course highlights how the topics covered can be applied within various branches of science. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science for undergraduates is a prerequisite for this course. "
        },
        "C833": {
          "courseName": "Chemistry with Lab ",
          "description": "Chemistry with Lab for graduates provides already licensed teachers seeking an additional license or endorsement in middle grades science or secondary physics, biological science, or earth science with an introduction to the field of chemistry. Designed for those not majoring in chemistry education, this course highlights how the topics covered can be applied within various branches of science. This course provides students with opportunities to examine the electronic structure of atoms, study periodic trends, name chemical compounds, write chemical formulas, determine the structure of molecules, balance chemical reactions, and discover the changing states of matter. Laboratory experiences facilitate the study of matter and the application of laboratory safety and maintenance procedures. Concepts in Science for graduates is a prerequisite for this course. "
        },
        "C841": {
          "courseName": "Legal Issues in Information Security ",
          "description": "Security information professionals have the role and responsibility for knowing and applying ethical and legal principles and processes that define specific needs and demands to assure data integrity within an organization. This course addresses the laws, regulations, authorities, and directives that inform the development of operational policies, best practices, and training to assure legal compliance and to minimize internal and external threats. Students analyze legal constraints and liability concerns that threaten information security within an organization and develop disaster recovery plans to assure business continuity. "
        },
        "C843": {
          "courseName": "Managing Information Security ",
          "description": "This course expands on fundamentals of information security by providing an in-depth analysis of the relationship between an information security program and broader business goals and objectives. Students develop knowledge and experience in the development and management of an information security program essential to ongoing education, career progression, and value delivery to enterprises. Students apply best practices to develop an information security governance framework, analyze mitigation in the context of compliance requirements, align security programs with security strategies and best practices, and recommend procedures for managing security strategies that minimize risk to an organization. "
        },
        "C844": {
          "courseName": "Emerging Technologies in Cybersecurity ",
          "description": "The continual evolution of technology means that cybersecurity professionals must be able to analyze and evaluate new technologies in information security such as wireless, mobile, and internet technologies. Students review the adoption process that prepares an organization for the risks and challenges of implementing new technologies. This course focuses on comparison of evolving technologies to address the security requirements of an organization. Students learn underlying principles critical to the operation of secure networks and adoption of new technologies. "
        },
        "C845": {
          "courseName": "Information Systems Security ",
          "description": "IT security professionals must be prepared for the operational demands and responsibilities of security practitioners including authentication, security testing, intrusion detection and prevention, incident response and recovery, attacks and countermeasures, cryptography, and malicious code countermeasures. This course provides a comprehensive, up-to-date global body of knowledge that ensures students have the right information, security knowledge, and skills to be successful in IT operational roles to mitigate security concerns and guard against the impact of malicious activity. Students demonstrate how to manage and restrict access control systems; administer policies, procedures, and guidelines that are ethical and compliant with laws and regulations; implement risk management and incident handling processes; execute cryptographic systems to protect data; manage network security; and analyze common attack vectors and countermeasures to assure information integrity and confidentiality in various systems. This course prepares students for the Systems Security Certified Practitioner (ISC2 SSCP) certification exam. "
        },
        "C850": {
          "courseName": "Emerging Technologies ",
          "description": "The Emerging Technologies course examines emerging technologies, identifies the benefits and drawbacks of technology adoption, and provides students with a process to evaluate technologies. The course will examine three technologies that may have an impact on Information Technology services in the coming years. "
        },
        "C853": {
          "courseName": "Teacher Performance Assessment in English ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during a student’s time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of their content, planning, instructional, and reflective skills.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C854": {
          "courseName": "Nursing Informatics Field Experience ",
          "description": "Nursing Informatics Field Experience requires students to complete clinical/practice experiences while engaging in authentic activities relevant to the role of an informatics nurse. To help students develop competency in this area, this course gives students opportunities to apply methods and solutions to support clinical decisions. They will be prepared to improve health outcomes by analyzing an existing health information system to determine the need for a system optimization that will improve an organization’s ability to measure and report Triple Aim objectives. All MSN Core and Specialty courses, with the exclusion of the Capstone course, are prerequisites to this course and must be completed before taking this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C855": {
          "courseName": "Nursing Informatics Capstone ",
          "description": "Nursing Informatics Capstone requires students to complete clinical/practice experiences (CPE) and finalize their system optimization proposal paper, which addresses the Institute of Health’s Triple Aim initiative. During this course, students will plan the final phase of their system development life cycle (SDLC), which consists of proposing the processes, methods, and tasks for monitoring, maintaining, supporting, and evaluating their system optimization. The knowledge and skills that students acquire during the CPE in this course will prepare them to complete their system optimization proposal paper. This is a culminating course that provides students an opportunity to demonstrate the competencies acquired during this program. All MSN Core and Specialty courses, including the Field Experience course, are prerequisites to this course and must be completed before taking this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C867": {
          "courseName": "Scripting and Programming ",
          "description": "Applications - Scripting and Programming - Applications for undergraduates explores the various aspects of the C++ programming language by examining its syntax, the development environment, and tools and techniques to solve some real-world problems. "
        },
        "C870": {
          "courseName": "Human Anatomy and Physiology ",
          "description": "This course examines the structures and functions of the human body and covers anatomical terminology, cells and tissues, and organ systems. Students will study the healthy state of the organ systems of the human body, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. There are no prerequisites for this course. "
        },
        "C871": {
          "courseName": "MA, Science Education Teacher Performance Assessment ",
          "description": "MA, Science Education Teacher Performance Assessment contains a comprehensive, original, research-based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision-making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
        },
        "C873": {
          "courseName": "Teacher Performance Assessment in Elementary Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills in this professional assessment.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C874": {
          "courseName": "MA, Mathematics Education (5-12) Teacher Performance Assessment ",
          "description": "MA, Mathematics Education (5-12) Teacher Performance Assessment contains a comprehensive, original, research based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) Contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
        },
        "C875": {
          "courseName": "Human Anatomy and Physiology ",
          "description": "This course examines the structures and functions of the human body and covers anatomical terminology, cells and tissues, and organ systems. Students will study the healthy state of the organ systems of the human body, including the digestive, skeletal, sensory, respiratory, reproductive, nervous, muscular, cardiovascular, lymphatic, integumentary, endocrine, and renal systems. There are no prerequisites for this course. "
        },
        "C876": {
          "courseName": "Conceptual Physics ",
          "description": "Conceptual Physics provides a broad, conceptual overview of the main principles of physics, including mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. Problem-solving activities and laboratory experiments provide students with opportunities to apply these main principles, creating a strong foundation for future studies in physics. There are no prerequisites for this course. "
        },
        "C877": {
          "courseName": "Mathematical Modeling and Applications ",
          "description": "Mathematical Modeling and Applications applies mathematics, such as differential equations, discrete structures, and statistics to formulate models and solve real-world problems. This course emphasizes improving students’ critical thinking to help them understand the process and application of mathematical modeling. Probability and Statistics II and Calculus II are prerequisites. "
        },
        "C878": {
          "courseName": "Mathematical Modeling and Applications ",
          "description": "Mathematical Modeling and Applications applies mathematics, such as differential equations, discrete structures, and statistics to formulate models and solve real-world problems. This course emphasizes improving students’ critical thinking to help them understand the process and application of mathematical modeling. Probability and Statistics II and Calculus II are prerequisites. "
        },
        "C879": {
          "courseName": "Algebra for Secondary Mathematics Teaching ",
          "description": "Algebra for Secondary Mathematics Teaching explores important conceptual underpinnings, common student misconceptions and ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of algebra. Secondary teachers should have an understanding of the following: algebra as an extension of number, operation, and quantity; various ideas of equivalence pertaining to algebraic structures; patterns of change as covariation between quantities; connections between representations (tables, graphs, equations, geometric models, context); and the historical development of content and perspectives from diverse cultures. In particular, this course focuses on deeper understanding of rational numbers, ratios and proportions, meaning and use of variables, functions (e.g., exponential, logarithmic, polynomials, rational, quadratic), and inverses. Calculus I is a prerequisite for this course. "
        },
        "C880": {
          "courseName": "Algebra for Secondary Mathematics Teaching ",
          "description": "Algebra for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of algebra. Secondary teachers should have an understanding of the following: algebra as an extension of number, operation, and quantity; various ideas of equivalence as it pertains to algebraic structures; patterns of change as covariation between quantities; connections between representations (tables, graphs, equations, geometric models, context); and the historical development of content and perspectives from diverse cultures. In particular, the course focuses on deeper understanding of rational numbers, ratios and proportions, meaning and use of variables, functions (e.g., exponential, logarithmic, polynomials, rational, quadratic), and inverses. Calculus I is a prerequisite for this course. "
        },
        "C881": {
          "courseName": "Geometry for Secondary Mathematics Teaching ",
          "description": "Geometry for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of geometry. Secondary teachers in this course will develop a deep understanding of constructions and transformations, congruence and similarity, analytic geometry, solid geometry, conics, trigonometry, and the historical development of content. Calculus I and College Geometry are prerequisites for this course.Calculus I and College Geometry are prerequisites for this course. "
        },
        "C882": {
          "courseName": "Geometry for Secondary Mathematics Teaching ",
          "description": "Geometry for Secondary Mathematics Teaching explores important conceptual underpinnings, common student misconceptions and ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of geometry. Students in this course will develop a deep understanding of constructions and transformations, congruence and similarity, analytic geometry, solid geometry, conics, trigonometry, and the historical development of content. Calculus I is a prerequisite for this course. "
        },
        "C883": {
          "courseName": "Statistics and Probability for Secondary Mathematics Teaching ",
          "description": "Statistics and Probability for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of statistics and probability. Secondary teachers should have a deep understanding of summarizing and representing data, study design and sampling, probability, testing claims and drawing conclusions, and the historical development of content and perspectives from diverse cultures. Calculus I and Probability and Statistics I and II are prerequisites for this course. "
        },
        "C884": {
          "courseName": "Statistics and Probability for Secondary Mathematics Teaching ",
          "description": "Statistics and Probability for Secondary Mathematics Teaching explores important conceptual underpinnings, common misconceptions and students’ ways of thinking, appropriate use of technology, and instructional practices to support and assess the learning of statistics and probability. Secondary teachers should have a deep understanding of summarizing and representing data, study design and sampling, probability, testing claims and drawing conclusions, and the historical development of content and perspectives from diverse cultures. Calculus I is a prerequisite for this course. "
        },
        "C885": {
          "courseName": "Advanced Calculus ",
          "description": "Advanced Calculus examines rigorous reconsideration and proofs involving calculus. Topics include real-number systems, sequences, limits, continuity, differentiation, and integration. This course emphasizes using critical thinking to analyze the connections between definitions and properties. Calculus III and Linear Algebra are prerequisites. "
        },
        "C886": {
          "courseName": "Advanced Calculus ",
          "description": "Advanced Calculus examines rigorous reconsideration and proofs involving calculus. Topics include real-number systems, sequences, limits, continuity, differentiation, and integration. This course emphasizes students’ ability to apply critical thinking to concepts to analyze the connections between definitions and properties. Calculus III and Linear Algebra are prerequisites. "
        },
        "C887": {
          "courseName": "MA, Mathematics Education (5-9) Teacher Performance Assessment ",
          "description": "MA, Mathematics Education (5-9) Teacher Performance Assessment contains a comprehensive, original, research based curriculum unit designed to meet an identified educational need. It provides direct evidence of the candidate’s ability to design and implement a multi-week, standards-based unit of instruction, assess student learning, and then reflect on the learning process. The Whalley Teacher Performance Assessment requires students to plan and teach a multi-week standards-based instructional unit consisting of seven components: 1) contextual factors, 2) learning goals, 3) assessment, 4) design for instruction, 5) instructional decision making, 6) analysis of student learning, and 7) self-evaluation and reflection. "
        },
        "C888": {
          "courseName": "Molecular and Cellular Biology ",
          "description": "Molecular and Cellular Biology provides undergraduate students seeking initial licensure or endorsement in secondary science education with an introduction to the area of molecular and cellular biology. This course examines the cell as an organism, emphasizing the molecular basis of cell structure and functions of biological macromolecules, subcellular organelles, intracellular transport, cell division, and biological reactions. Introduction to Biology is a prerequisite for this course. "
        },
        "C889": {
          "courseName": "Molecular and Cellular Biology ",
          "description": "Molecular and Cellular Biology provides graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary science education with an introduction to the area of molecular and cellular biology. This course examines the cell as an organism emphasizing molecular basis of cell structure and functions of biological macromolecules, subcellular organelles, intracellular transport, cell division, and biological reactions. A prerequisite for this course is Introduction to Biology. "
        },
        "C890": {
          "courseName": "Ecology and Environmental Science ",
          "description": "Ecology and Environmental Science is an introductory course for undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education. The course explores the relationships between organisms and their environment, including population ecology, communities, adaptations, distributions, interactions, and the environmental factors controlling these relationships. This course has no prerequisites. "
        },
        "C891": {
          "courseName": "Ecology and Environmental Science ",
          "description": "Ecology and Environmental Science is an introductory course for graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary or middle grade science education. The course explores the relationships between organisms and their environment, including population ecology, communities, adaptations, distributions, interactions, and the environmental factors controlling these relationships. This course has no prerequisites. "
        },
        "C892": {
          "courseName": "Geology II: Earth Systems ",
          "description": "Geology II: Earth Systems provides undergraduate students seeking licensure or endorsement in secondary science education with an examination of the geosphere, atmosphere, hydrosphere, biosphere, and the dynamic equilibrium of these systems over geologic time. This course also examines the history of Earth and its life-forms, with an emphasis in meteorology. Geology I: Physical is a prerequisite for this course. "
        },
        "C893": {
          "courseName": "Geology II: Earth Systems ",
          "description": "Geology II: Earth Systems provides graduate students seeking licensure or endorsement and/or to earn their MA degree in secondary science education with an examination of the geosphere, atmosphere, hydrosphere, and biosphere and the dynamic equilibrium of these systems over geologic time. This course also examines the history of Earth and its lifeforms, with an emphasis in meteorology. A prerequisite for this course is Geology I: Physical. "
        },
        "C894": {
          "courseName": "Astronomy ",
          "description": "Astronomy provides undergraduate students seeking initial licensure or endorsement in secondary or middle grade science education with essential knowledge of astronomy. It explores Western history and basic physics of astronomy, phases of the moon and seasons, composition and properties of solar system bodies, stellar evolution and remnants, properties and scale of objects and distances within the universe, and introductory cosmology. General Physics is a prerequisite for this course. "
        },
        "C895": {
          "courseName": "Astronomy ",
          "description": "Astronomy provides graduate students seeking initial licensure or endorsement and/or to earn their MA degree in secondary or middle grade science education with essential knowledge of astronomy. This course explores Western history and basic physics of astronomy; phases of the moon and seasons; composition and properties of solar system bodies; stellar evolution and remnants; properties and scale of objects and distances within the universe; and introductory cosmology. A prerequisite for this course is General Physics. "
        },
        "C897": {
          "courseName": "Mathematics: Content Knowledge ",
          "description": "Mathematics: Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful secondary mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, PreCalculus, Calculus I, and Calculus II. Linear Algebra, and Calculus III are recommended. "
        },
        "C898": {
          "courseName": "Earth Science: Content Knowledge ",
          "description": "This course covers the advanced content knowledge that a secondary earth science teacher is expected to know and understand. Topics include basic scientific principles of earth and space sciences, tectonics and internal earth processes, earth materials and surface processes, history of the Earth and its life-forms, Earth's atmosphere and hydrosphere, and astronomy. "
        },
        "C900": {
          "courseName": "Biology: Content Knowledge ",
          "description": "This comprehensive course examines a student’s conceptual understanding of a broad range of biology topics. High school biology teachers must help students make connections between isolated topics. This course starts with macromolecules that make up cellular components and continues with understanding the many cellular processes that allow life to exist. Connections are then made between genetics and evolution. Classification of organisms leads into plant and animal development that study the organ systems and their role in maintaining homeostasis. The course finishes by studying ecology and the effect humans have on the environment. "
        },
        "C901": {
          "courseName": "Physics: Content Knowledge ",
          "description": "Physics: Content Knowledge covers the advanced content knowledge that a secondary physics teacher is expected to know and understand. Topics include nature and impact of science and engineering, principle and models of matter and energy, mechanics, electricity and magnetism, waves, and science teaching and pedagogy. "
        },
        "C902": {
          "courseName": "Middle School Science: Content Knowledge ",
          "description": "This course covers the content knowledge that a middle-level science teacher is expected to know and understand. Topics include scientific methodologies, history of science, basic science principles, physical sciences, life sciences, earth and space sciences, and the role of science and technology and their impact on society. "
        },
        "C903": {
          "courseName": "Middle School Mathematics: Content Knowledge ",
          "description": "Mathematics: Middle School Content Knowledge is designed to help candidates refine and integrate the mathematics content knowledge and skills necessary to become successful middle school mathematics teachers. A high level of mathematical reasoning skills and the ability to solve problems are necessary to complete this course. Prerequisites for this course are College Geometry, Probability and Statistics I, and Pre-Calculus. "
        },
        "C904": {
          "courseName": "Teacher Performance Assessment in Science ",
          "description": "The Teacher Performance Assessment in Science course is culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C907": {
          "courseName": "Introduction to Biology ",
          "description": "This course is a foundational introduction to the biological sciences. This course explores the overarching theories of life from biological research as well as the fundamental concepts and principles of the study of living organisms and their interaction with the environment. Key concepts include how living organisms use and produce energy; how life grows, develops, and reproduces; how life responds to the environment to maintain internal stability; and how life evolves and adapts to the environment. "
        },
        "C908": {
          "courseName": "Integrated Physical Sciences ",
          "description": "This course provides students with an overview of the basic principles and unifying ideas of the physical sciences: physics, chemistry, and Earth sciences. Course materials focus on scientific reasoning and practical and everyday applications of physical science concepts to help students integrate conceptual knowledge with practical skills. "
        },
        "C909": {
          "courseName": "Elementary Reading Methods and Interventions ",
          "description": "Elementary Reading Methods and Interventions provides candidates with an in-depth look at best practices for developing reading and writing skills. Course content examines the science of reading, the stages of and approaches to literacy development, differentiation, technology integration, literacy assessment, and Multitiered Systems of Supports (MTSS), including the comprehensive response to intervention (RTI) model used to identify and address the needs of learners who struggle with reading comprehension. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment. "
        },
        "C910": {
          "courseName": "Elementary Reading Methods and Interventions ",
          "description": "Elementary Reading Methods and Interventions provides candidates with an in-depth look at best practices for developing reading and writing skills. Course content examines the science of reading, the stages of and approaches to literacy development, differentiation, technology integration, literacy assessment, and Multitiered Systems of Supports (MTSS), including the comprehensive response to intervention (RTI) model used to identify and address the needs of learners who struggle with reading comprehension. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment. "
        },
        "C912": {
          "courseName": "College Algebra ",
          "description": "This course provides further application and analysis of algebraic concepts and functions through mathematical modeling of real-world situations. Topics include real numbers, algebraic expressions, equations and inequalities, graphs and functions, polynomial and rational functions, exponential and logarithmic functions, and systems of linear equations. "
        },
        "C914": {
          "courseName": "Teacher Performance Assessment in Mathematics Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C915": {
          "courseName": "Chemistry: Content Knowledge ",
          "description": "Chemistry: Content Knowledge provides advanced instruction in the main areas of chemistry for which secondary chemistry teachers are expected to demonstrate competency. Topics include matter and energy, thermochemistry, structure, bonding, reactivity, biochemistry and organic chemistry, solutions, the nature of science and technology, mathematics, and laboratory procedures. "
        },
        "C918": {
          "courseName": "Evolving Roles of Nurse Educators in Diverse Environments ",
          "description": "Evolving Roles of Nurse Educators in Diverse Environments examines the multidimensional roles of a contemporary academic nurse educator. This course explores the roles and responsibilities of the nurse educator as a teacher, leader, change agent, and curriculum innovator. Students will also examine the importance of personal and professional development by developing strategies that promote academic integrity, cultural sensitivity, social justice, and ethical/legal values in diverse environments. The course emphasizes the responsibility of nurse educators to utilize communication, collaboration, and leadership in mitigating challenges in academic nursing education. "
        },
        "C919": {
          "courseName": "Facilitation of Context-Based Student-Centered Learning ",
          "description": "Facilitation of Context-Based Student-Centered Learning explores how the nurse educator will incorporate authentic experiences into the creation of course plans that facilitate scholarly inquiry, collaboration, and knowledge acquisition in varied educational environments. Emphasis is placed on innovative, transformational, and experiential teaching and learning strategies to facilitate student development of professional, context-based nursing principles, knowledge, skills, and behavior. Evolving Roles of Nurse Educators in Diverse Environments is a prerequisite to this course. "
        },
        "C920": {
          "courseName": "Contemporary Curriculum Design and Development in Nursing Education ",
          "description": "Contemporary Curriculum Design and Development in Nursing Education analyzes the concepts of creating curriculum based on national nursing accreditation standards and instructional design best practices. Nurse educator students will create course content that supports learning in diverse, real-world environments where nurse educators facilitate learning. Instructional design strategies for delivering course content will reflect the mission of academic institution programs, contemporary trends in nursing education, and the needs of key stakeholders in nursing education and practice. Facilitation of Context-Based Student-Centered Learning is a prerequisite to this course. "
        },
        "C921": {
          "courseName": "Assessment and Evaluation Strategies for Measuring Student Learning ",
          "description": "Assessment and Evaluation Strategies for Measuring Student Learning addresses the academic nurse educator's role in the design, development, implementation, and evaluation of student achievement outcomes in nursing education programs. This course requires students to integrate best practices from nursing theory and theories of learning to assess student learning in diverse educational settings. Topics include validity, reliability, and practicality of assessments, interpreting item difficulty and discrimination test results, and analyzing student achievement and learning outcomes data. This course has no prerequisites. "
        },
        "C922": {
          "courseName": "Emerging Trends and Challenges in 21st Century Nursing Education ",
          "description": "Emerging Trends and Challenges in 21st Century Nursing Education analyzes the emerging trends, technologies, and challenges that academic nurse educators encounter when facilitating learning in diverse healthcare settings. Students will focus on the necessity of interprofessional collaboration and the barriers and facilitators to overcoming the challenges associated with teaching and learning in nursing. Topics include the impact of emerging technology, challenges in nursing practice, and the role of the academic nurse educator as a scholar and a nursing education policy advocate. This course has no prerequisites. "
        },
        "C925": {
          "courseName": "Earth: Inside and Out ",
          "description": "Earth: Inside and Out explores the ways in which our dynamic planet evolved and the processes and systems that continue to shape it. Though the geologic record is incredibly ancient, it has only been studied intensely since the end of the nineteenth century. Since then, research in fields such as geologic time, plate tectonics, climate change, exploration of the deep seafloor, and the inner earth have vastly increased our understanding of geological processes. "
        },
        "C926": {
          "courseName": "Earth: Inside and Out ",
          "description": "Earth: Inside and Out explores the ways in which our dynamic planet evolved, and the processes and systems that continue to shape it. Though the geologic record is incredibly ancient, it has only been studied intensely since the end of the 19th century. Since then, research in fields such as geologic time, plate tectonics, climate change, exploration of the deep-sea floor, and the inner earth have vastly increased our understanding of geological processes. "
        },
        "C927": {
          "courseName": "Managing Technology Operations and Innovation ",
          "description": "Managing Technical Operations and Innovations explores the importance of innovation in the processes of operations management and business competitiveness. From the formulation of tactical operations plans from strategic objectives, IT executives need to create partnerships to drive innovation within an organization. This course provides students with the practical knowledge and understanding of operations management concepts, business models, methods, tools, applications and best practices used by successful organizations to improve their operations. This course has no prerequisites. "
        },
        "C928": {
          "courseName": "Financial Management for IT Professionals ",
          "description": "Financial Management for IT Professionals develops learners’ skills in financial management, budgeting, and procurement. This course teaches how to leverage financial know-how to improve workplace decision-making. This course also provides learners with the knowledge and skills necessary to spend money on the right projects and right equipment, while aligning operating budgets with strategic initiatives. There are no prerequisites for this course. "
        },
        "C929": {
          "courseName": "IT Sourcing and Development in a Global Economy ",
          "description": "IT Sourcing and Development in a Global Economy examines the practice of sourcing and developing global IT projects from a management perspective. In today’s organizations, leaders look for efficient and effective ways to deliver goods and services. This course will allow students to explore the strategic, operational, tactical, and security-related impacts on the organization of sourcing and supporting a global IT project. Students will cultivate a deep understanding of the documents, skills, and stakeholders needed for any given project and develop the ability to leverage these elements to achieve success. This course will also explore the ethical, cultural, and regulatory considerations surrounding sourcing and managing IT projects in a global space. There are no prerequisites for this course. "
        },
        "C930": {
          "courseName": "Preclinical Experiences in Mathematics ",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C931": {
          "courseName": "Preclinical Experiences in Mathematics ",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C932": {
          "courseName": "Preclinical Experiences in Mathematics ",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document at least 75 hours of in-classroom observations. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C933": {
          "courseName": "Preclinical Experiences in Mathematics ",
          "description": "Preclinical Experiences in Mathematics provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C934": {
          "courseName": "Preclinical Experiences in Elementary and Special Education ",
          "description": "Preclinical Experiences in Elementary and Special Education provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C935": {
          "courseName": "Preclinical Experiences in Elementary Education ",
          "description": "Preclinical Experiences in Elementary provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C936": {
          "courseName": "Preclinical Experiences in Elementary Education ",
          "description": "Preclinical Experiences in Elementary provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C937": {
          "courseName": "Preclinical Experiences in Science ",
          "description": "Preclinical Experiences in Science provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C938": {
          "courseName": "Preclinical Experiences in Science ",
          "description": "Preclinical Experiences in Science provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C940": {
          "courseName": "Science Methods—Secondary Biology ",
          "description": "Science Methods—Secondary Biology provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary biology. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C941": {
          "courseName": "Science Methods—Secondary Chemistry ",
          "description": "Science Methods—Secondary Chemistry provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary chemistry. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C942": {
          "courseName": "Science Methods—Secondary Earth Science ",
          "description": "Science Methods—Secondary Earth Science provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary earth science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C943": {
          "courseName": "Science Methods—Secondary Physics ",
          "description": "Science Methods—Secondary Physics provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in secondary physics. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C945": {
          "courseName": "Preclinical Experiences in English ",
          "description": "Preclinical Experiences in English provides students the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Students will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, students will be required to meet several requirements including a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "C946": {
          "courseName": "Nursing Education Field Experience ",
          "description": "The Nursing Education Field Experience provides the academic nurse educator student an opportunity to work collaboratively with academic mentors and interprofessional stakeholders to analyze the need-gap for a curriculum change, innovation, or improvement. Based on the identified need-gap, the graduate student will design and develop a course that reflects evidence-based instructional design and assessment principles and practices. This course prepares students for the role of an Academic Nurse Educator, as an agent for change and quality improvement in nursing education. "
        },
        "C947": {
          "courseName": "Nursing Education Capstone ",
          "description": "The Nursing Education Capstone course provides the Nurse Educator student an opportunity to apply previous course work towards the completion of an evidence-based curriculum proposal project. During this course students will build on previous work during their Nursing Education Field Experience course by planning the implementation and evaluation phases of their proposed curriculum change, innovation or improvement. The capstone project represents a synthesis of competencies across the Masters Science of Nursing—Nursing Education degree program, which prepares them to lead, manage, and transform nursing education in diverse and complex settings.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "C948": {
          "courseName": "Technical Communication ",
          "description": "Technical Communication examines communication types and strategies that information technology executives will use to communicate effectively within an organization. As leaders, IT executives frequently contribute to business goals by designing and communicating specialized information in a variety of media to customers, clients, and other departments. In this course, students learn to communicate accurately, effectively, and ethically to a variety of audiences. Students choose, design, and deliver the communication product and assess the effectiveness to improve future communication. This course has no prerequisites. "
        },
        "C949": {
          "courseName": "Data Structures and Algorithms I ",
          "description": "Data Structures and Algorithms I covers the fundamentals of dynamic data structures, such as bags, lists, stacks, queues, trees, and hash tables, and their associated algorithms. This course discusses object-oriented design and abstract data types as design paradigms. The course emphasizes problem-solving and techniques for designing efficient, maintainable software applications. Students will implement simple applications using the techniques learned. This course has no prerequisites. "
        },
        "C950": {
          "courseName": "Data Structures and Algorithms II ",
          "description": "Data Structures and Algorithms II explores the analysis and implementation of high-performance data structures and supporting algorithms, including graphs, hashing, self-adjusting data structures, set representations, and dynamic programming. The course also introduces students to NP-complete problems. The course discusses how to use Python techniques to implement software solutions for problems of memory management and data compression. This course has two prerequisites: Data Structures and Algorithms I and Discrete Math II. "
        },
        "C951": {
          "courseName": "Introduction to Artificial Intelligence ",
          "description": "Introduction to Artificial Intelligence explores the foundational principles and practices of artificial intelligence (AI), machine learning, and robotics. The course prepares students to analyze relationships, build agents, and create models relevant to AI problems. The prerequisites for this course are Introduction to Probability and Statistics as well as Data Structures and Algorithms II. "
        },
        "C952": {
          "courseName": "Computer Architecture ",
          "description": "Computer Architecture introduces students to concepts and characteristics of organization and architecture applied to modern computer systems including performance, processor, memory, input/output, and multiprocessors to optimize system design, performance, and efficiency. "
        },
        "C954": {
          "courseName": "Information Technology Management ",
          "description": "IT Management introduces the key topics and skills needed to lead next-generation technology organizations. This course explores how common applications and innovation drive value and business needs. Ethical and regulatory compliance issues are discussed, including current practices for risk management, disaster recovery, and cybersecurity. Students will also analyze the key leadership skills and traits necessary to lead responsive, competitive, and innovative organizations. This course has no prerequisites. "
        },
        "C955": {
          "courseName": "Applied Probability and Statistics ",
          "description": "Applied Probability and Statistics is designed to help students develop competence in the fundamental concepts of basic statistics including: introductory algebra and graphing; descriptive statistics; regression and correlation; and probability. Statistical data and probability are often used in everyday life, science, business, information technology, and educational settings to make informed decisions about the validity of studies and the effect of data on decisions. This course discusses what constitutes sound research design and how to appropriately model phenomena using statistical data. Additionally, the content covers simple probability calculations, based on events that occur in the business and IT industries. No prerequisites are required for this course. "
        },
        "C957": {
          "courseName": "Applied Algebra ",
          "description": "Applied Algebra is designed to help you develop competence in working with functions, the algebra of functions, and using some applied properties of functions. You will start learning about how we can apply different kinds of functions to relevant, real-life examples. From there, the algebra of several families of functions will be explored, including linear, polynomial, exponential, and logistic functions. You will also learn about relevant, applicable mathematical properties of each family of functions, including rate of change, concavity, maximizing/minimizing, and asymptotes. These properties will be used to solve problems related to your major and make sense of everyday living problems. Students should complete Applied Probability and Statistics or its equivalent prior to engaging in Applied Algebra. "
        },
        "C958": {
          "courseName": "Calculus I ",
          "description": "This course guides candidates to apply theoretical concepts of calculus to real-world situations, demonstrating a developing mathematical mindset. This course focuses on limits, derivatives, integrals, and differential equations; it also prepares students for Discrete Mathematics. Prerequisites may include an entrance exam that assesses pre-calculus skills, or readiness; alternatively, completion of pre-calculus within the past 3 – 5 years. "
        },
        "C959": {
          "courseName": "Discrete Mathematics I ",
          "description": "Discrete Mathematics I helps candidates develop competence in the use of abstract, discrete structures fundamental to computer science. In particular, this course will introduce candidates to logic and proofs; Boolean algebra and functions; set theory; finite and infinite sequences and series; and relations, graphs, and trees. The course emphasizes applications in computer science. Calculus I is a prerequisite for this course. "
        },
        "C960": {
          "courseName": "Discrete Mathematics II ",
          "description": "Discrete Mathematics II addresses abstract, discrete, computational methods used in computer science. In particular, this class introduces searching and sorting algorithms; big-O estimates; number theory and cryptography; recursion and induction; counting and advanced counting techniques; discrete probability; and modeling computation. This course emphasizes applications in computer science. Discrete Mathematics I is a prerequisite for this course. "
        },
        "C962": {
          "courseName": "Current and Emerging Technology ",
          "description": "Current and Emerging Technologies explores organizational leadership trends, practices, processes, and technology in contemporary technology-intensive organizations. IT executives need to stay informed of technological trends to determine their relevance and implementation within an organization. This course requires students to read and evaluate academic literature pertaining to emerging IT topics. This course has no prerequisites. "
        },
        "C963": {
          "courseName": "American Politics and the US Constitution ",
          "description": "American Politics and the U.S. Constitution examines the evolution of representative government in the United States and the changing interpretations of the civil rights and civil liberties protected by the Constitution. This course will give candidates an understanding of the powers of the branches of the federal government, the continual tensions inherent in a federal system, the shifting relationship between state and federal governments, and the interactions between elected officials and the ever-changing electorate. This course will focus on such topics as the role of a free press in a democracy, the impact of changing demographics on American politics, and the debates over and expansion of civil rights. Upon completion of the course, candidates should be able to explain the basic functions of the federal government, describe the forces that shape American policy and politics, and be better prepared to participate in America’s civic institutions. This course has no prerequisite. "
        },
        "C964": {
          "courseName": "Computer Science Capstone ",
          "description": "The Computer Science Capstone course allows the student to demonstrate their application of the academic and professional abilities developed during the BSCS program. The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
        },
        "C965": {
          "courseName": "Teaching in the Middle School ",
          "description": "Teaching in the Middle School examines the guiding principles and best teaching practices for educating middle school students. The course explores the history of the middle school, the philosophy, theory, and rationale behind middle school organization; and the differences between elementary, middle, and secondary schools. The course also examines the unique needs of middle school students and teaching methods used to meet the needs of these learners. This course has no prerequisites. "
        },
        "C966": {
          "courseName": "Teaching in the Middle School ",
          "description": "Teaching in Middle School examines the guiding principles and best teaching practices for educating middle school students. The course explores the history of middle school; the philosophy, theory, and rationale behind middle school organization; and the differences between elementary, middle, and secondary schools. The course also examines the unique needs of middle school students and teaching methods used to meet the needs of these learners. This course has no prerequisites. "
        },
        "C968": {
          "courseName": "Software I – C# ",
          "description": "Software I - C# builds object-oriented programming expertise and introduces powerful new tools for C# application development. You will learn about and put into action: class design, exception handling, and other object-oriented principles and constructs to develop software that meets business requirements. This course requires foundational knowledge of object-oriented programming. Scripting and Programming: Foundations and Scripting and Programming: Applications are prerequisites for this course. "
        },
        "C969": {
          "courseName": "Software II – Advanced C# ",
          "description": "Software II - Advanced C# refines object-oriented programming expertise and builds database and file server application development skills. You will learn about and put into action lambda expressions, collections, and input/output to develop software with C# that meets business requirements. This course requires intermediate expertise in object-oriented programming and the C# language. The prerequisite for this course is Software I - C#. "
        },
        "C970": {
          "courseName": "Children’s Literature ",
          "description": "Children's Literature is an introduction to and exploration of children's literature. Students will consider and analyze children's literature as a lens through which to view the world. Students will experience multiple genres, historical perspectives, cultural representations, and current applications in the field of children's literature. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Introduction to Instructional Planning and Presentation AND Instructional Planning and  Presentation in Elementary or Special Education. "
        },
        "C971": {
          "courseName": "Mobile Application Development Using C# ",
          "description": "Mobile Application Development Using C# introduces students to programming for mobile devices. Building on students’ previous programming knowledge in C#, this course explores a broad range of topics, including mobile user interface design and development; building applications that adapt to different mobile devices and platforms; managing data using a local database; and consuming REST-based web services. In this course, students will focus on developing skills using the latest framework designed to provide a more modern and streamlined development experience. This framework will help students design and code cross-platform applications that work on a range of mobile devices. There are several prerequisites for this course: Software I and II, and UI Design. "
        },
        "C972": {
          "courseName": "College Geometry ",
          "description": "College Geometry covers the knowledge and skills necessary to use dynamic technology to explore geometry, to use axiomatic reasoning to prove statements about geometry, and to apply geometric models to solve real-life problems. Topics include axiomatic systems, analytic proofs, coordinate geometry, plane and solid Euclidean geometry, non-Euclidean geometries, constructions, transformations, deductive reasoning, and dynamic technology. College Algebra as well as Trigonometry and Precalculus are prerequisites. "
        },
        "C974": {
          "courseName": "Science Methods—Middle Grades General Science ",
          "description": "Science Methods—Middle Grades General Science provides an introduction to teaching methods specific to science for undergraduate students seeking initial licensure or endorsement in Middle School Science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. A prerequisite for this course is Curriculum, Instruction, and Assessment. "
        },
        "C975": {
          "courseName": "Science Methods—Middle Grades General Science ",
          "description": "Science Methods—Middle Grades General Science focuses on teaching methods specific to science for graduate students seeking an endorsement in middle school science. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C976": {
          "courseName": "Science Methods—Secondary Biology ",
          "description": "Science Methods—Secondary Biology focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary biology. This course focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C977": {
          "courseName": "Science Methods—Secondary Chemistry ",
          "description": "Science Methods—Secondary Chemistry focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary chemistry. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C978": {
          "courseName": "Science Methods—Secondary Earth Science ",
          "description": "Science Methods—Secondary Earth Science focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary earth science. Course content focuses on the design and teaching of standardsbased lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C979": {
          "courseName": "Science Methods—Secondary Physics ",
          "description": "Science Methods—Secondary Physics focuses on teaching methods specific to science for graduate students seeking an endorsement in secondary physics. Course content focuses on the design and teaching of standards-based lessons using the three dimensions of science (science and engineering practices, crosscutting concepts, and disciplinary core ideas) and the appropriate integration of technology into those lessons. Students in this course work within their content areas to evaluate, enhance, and plan appropriate science instruction. This course includes laboratory safety training and certification, which includes safe laboratory practices and procedures for science classrooms and the proper use of personal protective equipment. Previous coursework in curriculum, instruction, and assessment is a prerequisite for this course. "
        },
        "C992": {
          "courseName": "College Geometry ",
          "description": "College Geometry covers the knowledge and skills necessary to use dynamic technology to explore geometry, to use axiomatic reasoning to prove statements about geometry, and to apply geometric models to solve real-life problems. Topics include axiomatic systems, analytic proofs, coordinate geometry, plane and solid Euclidean geometry, non-Euclidean geometries, constructions, transformations, deductive reasoning, and dynamic technology. College Algebra as well as Trigonometry and Precalculus are prerequisites. "
        },
        "CQC2": {
          "courseName": "Calculus II ",
          "description": "Calculus II is the study of the accumulation of change in relation to the area under a curve. It covers the knowledge and skills necessary to apply integral calculus of one variable and to use appropriate technology to model and solve real-life problems. Topics include antiderivatives; indefinite integrals; the substitution rule; Riemann sums; the fundamental theorem of calculus; definite integrals; acceleration, velocity, position, and initial values; integration by parts; integration by trigonometric substitution; integration by partial fractions; numerical integration; improper integration; area between curves; volumes and surface areas of revolution; arc length; work; center of mass; separable differential equations; direction fields; growth and decay problems; and sequences. Calculus I is a prerequisite for this course. "
        },
        "CUA1": {
          "courseName": "Culture ",
          "description": "Focuses on the nature and role of culture and the importance of cultural groups and cultural identity. "
        },
        "D001": {
          "courseName": "Behavioral Support Strategies for K-12 Learners with Mild to Moderate Exceptionalities ",
          "description": "Behavioral Support Strategies for K–12 Learners with Mild to Moderate Exceptionalities prepares candidates to work effectively with students exhibiting behavior in the classroom that is below age and cultural norms. This course provides an overview of behavior disorders and their causes, as well as appropriate research-based intervention strategies, including positive behavior intervention and supports, multi-tiered systems of support (MTSS), applied behavior analysis, replacement behavior and reward strategies, culturally responsive practices, and data collection and assessment methods. After completing this course candidates emerge prepared to strategize and recommend adjustments to the learning environment that support positive behavior and student success in the classroom and beyond. This course also examines behavioral assessment and analysis, including the creation of functional behavior assessment (FBA) and the creation and monitoring of behavioral improvement plans (BIP) in an authentic learning environment. This course is designed to be taken after successful completion of Fundamentals of Diverse Learners or Fundamentals of Diversity Inclusion, and Exceptional Learners, Professional, Ethical, and Legal Practices for Special Education, and Managing Engaging Learning Environments or Classroom Management, Engagement, and Motivation. "
        },
        "D002": {
          "courseName": "Professional, Ethical, and Legal Practices for Special Education ",
          "description": "Professional, Ethical, and Legal Practices for Special Education prepares candidates to practice within ethical and legal guidelines in day-to-day teaching, stakeholder interactions, and other complex situations. This course provides an overview of the professional ethics and standards from the Council for Exceptional Children (CEC), which guide candidates to act in a professionally conscientious manner. Candidates will explore the legal foundations and case laws related to special education to gain understanding of how legislation influences teaching and learning. This course is designed to be taken after successful completion of Fundamentals of Diverse Learners OR Fundamentals of Diversity, Inclusion, and Exceptional Learners. "
        },
        "D003": {
          "courseName": "Assessment in Special Education ",
          "description": "Assessment in Special Education prepares candidates to use multiple methods of assessment and data sources in making educational decisions about the student and the learning environment. This course is designed to help provide an understanding of how assessment data is used during screening in multitiered systems of support (MTSS), the eligibility process, the evaluation process, progress monitoring, and data-based instructional decision making. This course is designed to be taken after successful completion of Professional, Ethical, and Legal Practices for Special Education and Assessing Impact on Student Learning OR Educational Assessment. "
        },
        "D004": {
          "courseName": "Collaborating with Partners for Student Success ",
          "description": "Collaborating with Partners for Student Success prepares candidates to apply team processes and communication strategies to collaborate in a culturally responsive manner with families, paraeducators, and other professionals (within the school, other educational settings, and the community) to plan programs and access services for students with exceptionalities and their families. The course introduces ways to enhance parental involvement and family engagement while teaching families and students advocacy throughout the Individualized Education Program (IEP) and transition planning processes. This course also focuses on the components of the IEP and how the practice of effective communication and collaboration skills is key to the program's development and implementation. Candidates will engage in three hours of preclinical experiences that includes a simulated collaborative experience in which skills learned can be applied. This course is designed to be taken after successful completion of Professional, Ethical, and Legal Practices for Special Education OR Fundamentals of Diversity, Inclusion, and Exceptional Learners, and Assessment in Special Education. "
        },
        "D005": {
          "courseName": "Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities ",
          "description": "Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities introduces candidates to a repertoire of evidence-based instructional strategies to advance the learning of students with exceptionalities. The course focuses specifically on strategies for intensifying and individualizing instructional interventions; making instructional decisions based on progress-monitoring data; collaborating with general education teachers and paraeducators; teaching to mastery; promoting generalization of learning; and teaching students with exceptionalities how to use self-assessment, problem solving, and other cognitive strategies to organize critical content and meet their needs. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and Presentation AND Instructional Planning  and Presentation in Special Education. "
        },
        "D006": {
          "courseName": "Instructional Strategies and Technologies for Elementary Learners with Mild to Moderate Exceptionali ",
          "description": "Instructional Strategies and Technologies for Elementary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for elementary students receiving special education services. The course includes cognitive and metacognitive strategies that elementary students can use to acquire new content knowledge and generalize skills across learning environments. It also provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math and English language arts lesson plans based on learner characteristics, performance data, and individualized education program (IEP) goals. In addition to discussing how to make appropriate accommodations, the course teaches candidates how to assess student learning through progress monitoring and apply intensive interventions when warranted. This course is designed to be taken after successful completion of Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities. "
        },
        "D007": {
          "courseName": "Instructional Strategies and Technologies for Secondary Learners with Mild to Moderate Exceptionalit ",
          "description": "Instructional Strategies and Technologies for Secondary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for use with secondary students receiving special education services. Strategies taught in this course focus on intensive instruction and making accommodations to secondary lesson plans in order to develop critical thinking and problem-solving skills to enhance acquisition of ageappropriate secondary content across academic disciplines. This course also promotes the achievement of Individualized Education Program (IEP) and transition goals for independent living and career preparation through demonstration of strategies that increase students' self-awareness, selfregulation, self-management, self-control, and self-esteem. This course is designed to be taken after successful completion of Considerations for Instructional Planning for Learners with Mild to Moderate Exceptionalities. "
        },
        "D009": {
          "courseName": "Preclinical Experiences in Special Education ",
          "description": "Pre-Clinical Experiences in Special Education provides candidates the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Candidates will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, candidates will be required to include a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "D010": {
          "courseName": "Disciplinary Literacy ",
          "description": "Disciplinary Literacy examines teaching strategies designed to help candidates to develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help candidates distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and  Presentation AND Instructional Planning and Presentation in Special Education. "
        },
        "D016": {
          "courseName": "Leadership Foundations and Ethics ",
          "description": "Leadership Foundations and Ethics presents candidates with a variety of leadership theories and strategies used by PK–12 educational leaders to develop, sustain, and evaluate a coherent system of academic and social supports that meet the full range of students' needs. Foundational knowledge addresses the importance of developing mission, vision, and core values in collaboration with faculty, staff, and the school community to advocate for student success. The course also covers communication strategies, interpersonal skills, and using data to build community, influence school culture, and manage change for continuous improvement. In addition, candidates are introduced to the significance of following professional ethical codes and the importance of modeling and advocating ethical behavior with all stakeholders. "
        },
        "D017": {
          "courseName": "School Law ",
          "description": "School Law prepares candidates to understand the appropriate application of laws, rights, policies, and regulations to promote student success. The course emphasizes the importance of understanding the history of and relationship between federal and state laws, legal decisions, local education policies, and practices at the local school level to ensure compliance. The course further focuses on understanding the legal rights and protections provided for all students, including those with disabilities, as well as school staff. It also addresses curriculum and instruction that help stakeholders understand the possible effects these rights may have on administrative decisions. Candidates are also provided the opportunity to demonstrate their capability to evaluate legal consequences of administrative decisions. "
        },
        "D018": {
          "courseName": "Leading Inclusive Schools ",
          "description": "Leading Inclusive Schools covers a variety of topics that directly affect students who have been assessed and determined to need additional support or services to ensure their academic success and well-being. The course prepares candidates to understand and comply with applicable laws, rights, policies, and regulations as appropriate to address matters of equity, fairness, and student marginalization based on culture and language, disability, or giftedness. These include types of special education classifications and their significance, working with English learners (ELs), working with gifted and talented students, and using Multi-Tiered System of Supports (MTSS) frameworks to ensure optimum learning environments for diverse learners. This course will guide candidates in building a strong repertoire of skills and knowledge related to exceptional students. It will help them ensure that each student has equitable access to effective teachers; learning opportunities; academic, social, and behavioral support; and other resources necessary for success. This course is designed to be taken after successful completion of the School Law course. "
        },
        "D019": {
          "courseName": "Data Literacy and Evidence-Based Practices ",
          "description": "Data Literacy and Evidence-Based Practices focuses on the development of data literacy skills educators need to improve the learning and development opportunities of K–12 students. Candidates will practice identifying educational problems and data types, generating data, analyzing data, making inferences and drawing conclusions, and creating action plans within their educational settings. Candidates will also learn best practices for data literacy, including continuous improvement planning, approaches to professional learning communities, and instructional decision-making processes. This course has no prerequisites. "
        },
        "D020": {
          "courseName": "Cultural Competency and Social-Emotional Learning ",
          "description": "Cultural Competency and Social-Emotional Learning focuses on fostering cultural competence among professional educators by increasing knowledge of diverse learner populations, implementing culturally responsive pedagogy, and ensuring social justice and equity in the educational setting. Candidates also will participate in learning experiences designed to ensure they can lead efforts to meet the social and emotional learning needs of all learners, contributing to a school environment that builds learners' personal agency and academic success. Advocacy strategies are learned in this course, ensuring candidates possess the tools to positively impact school environments both locally and globally. This course has no prerequisites and candidates are strongly encouraged to take this early in their program. "
        },
        "D021": {
          "courseName": "Leadership of Curriculum Design and Instruction ",
          "description": "Leadership of Curriculum Design and Instruction prepares candidates to evaluate and implement curricular programs and instructional methods observed at the school level. Candidates focus on the knowledge and skills needed to develop, align, and implement cohesive systems of curriculum, instruction, and assessment. Importance is placed on responding to student needs, embodying high expectations for student learning, aligning with academic standards within and across grade levels, and promoting students' academic success and social and emotional well-being. This course also covers the selection and use of appropriate technologies to monitor student progress and improve instruction support for assessment, data collection, management, and analysis. Candidates are prepared to build a professional culture of trust and collaboration to ensure they are able to work with school personnel in creating curricular programs and instructional methods that are engaging and challenging and relevant to student needs, experiences, and interests. This course is designed to be taken after successful completion of D017: School Law. "
        },
        "D022": {
          "courseName": "People and Talent in Educational Leadership ",
          "description": "People and Talent in Educational Leadership prepares candidates to understand and implement practices used to recruit, hire, and prepare school personnel to provide students with an optimal learning environment. Various school professional development practices, such as professional learning communities, collaborative learning communities, beginning teacher induction, and mentor programs, will be covered. Additionally the course covers methods to evaluate school personnel appropriately based on data-driven decisions; providing realistic and actionable feedback to school personnel to continuously drive improvement; engaging all school personnel in the use and evaluation of competing school-wide initiatives; creating and sustaining a professional culture of engagement and commitment by developing workplace conditions that promote employee development, well-being, and professional growth; and continuously supporting school personnel to improve their instructional practices through ongoing professional development. The candidate will also reflect on leadership standards in order to develop a personal professional growth plan. A prerequisite for this course is D017: School Law. "
        },
        "D023": {
          "courseName": "School Financial Leadership ",
          "description": "School Financial Leadership focuses on financial policies, practices, and issues connected to PK–12 school operations. The course describes various sources of school funding, the impact these sources can have on managing school budgets, and the challenges connected to finances that are often encountered by school leaders to ensure equitable financial support for all students. Candidates learn how to analyze different types of school budgets and understand the principal's role in the budgetary process to ensure alignment to the school's mission, vision, and values. This course also identifies and explains various types of commonly used accounting regulations, rules, and professional ethical principles used to create, maintain, and evaluate school budgets to ensure the equitable and ethical use of financial resources. This course is designed to be taken after successful completion of D017: School Law. "
        },
        "D024": {
          "courseName": "Professional Presence and Influence ",
          "description": "Professional Presence and Influence is a masters-level course designed to guide students towards an enhanced state of presence, where therapeutic relationships are built between nurse and patient. Students will learn techniques for self-care practices that result in enhanced mental and physical wellbeing and that ensure ethically-generated patient care. Presence is an intrapersonal and interpersonal quality that allows the nurse to relate to others and to be aware of the world around them. The characteristics of presence, which include holism, intimacy, sensitivity and adaptability, create a heightened sense of awareness that fosters therapeutic relationships between the nurse and patient. Developing a mindful, authentic presence is central to health and spiritual practices in several cultures and a major element of leadership. Students will intentionally develop a focused mindfulness practice that will influence patient outcomes and lead to conditions that create joy in the workplace. "
        },
        "D025": {
          "courseName": "Essentials of Advanced Nursing Roles and Interprofessional Practice ",
          "description": "Essentials of Advanced Nursing Roles and Interprofessional Practice explores essential characteristics of the advanced professional nurse in the role of leader, educator, practitioner, or informatics specialist. In this course, students will apply evidence-based strategies to facilitate interprofessional collaboration on teams. Students will explore the role of nurses in advocating for change at the bedside, as well as leading teams to advocate for health policy reform. Students will gather and analyze data to identify patients and populations at risk and recommend policy change to improve health outcomes in the community. "
        },
        "D026": {
          "courseName": "Quality Outcomes in a Culture of Value-Based Nursing Care ",
          "description": "Quality Outcomes in a Culture of Value-Based Nursing Care incorporates current standards of quality and safety within the context of value-based care. In a value-based healthcare system, the benefits are derived from measuring health outcomes against the cost of delivering the outcomes. These benefits are then extended to patients, providers, payers, suppliers, and society as a whole. This course introduces new healthcare delivery models, which stress a team-oriented approach to patient care and sharing of patient data so that care is coordinated, and outcomes can be measured easily. Emphasis is placed on performance and quality improvement methods that underlie value-based nursing care. The nurse in advanced practice today must exemplify the standards of quality and safety and be prepared to lead the delivery of value-based patient-centered care. "
        },
        "D027": {
          "courseName": "Advanced Pathopharmacological Foundations ",
          "description": "Advanced Pathopharmacological Foundations provides advanced practice nurses foundational knowledge in the many pathologies encountered in practice today. Advancing from the cellular to the body system level, this course examines the pathologies of common conditions seen in healthcare today. Consideration is also given to the human affective response to alterations in health. There are no prerequisites for this course. "
        },
        "D028": {
          "courseName": "Advanced Health Assessment for Patients and Populations ",
          "description": "Advanced Health Assessment of Patients and Populations builds on prior physical health assessment knowledge and skills acquired during undergraduate studies by focusing on the advanced assessment of biopsychosocial and sociocultural contexts in patients and populations across the life span. This course emphasizes the use of a comprehensive health promotion, disease prevention, and health restoration model to address health concerns in patients and communities. Students will acquire advanced assessment knowledge and skills for clinical interviewing, focused history taking, critical diagnostic reasoning, and clinical decision-making using a problemfocused framework that integrates authentic experiences with practical knowledge of health patterns in patients and communities. There are no prerequisites for this course. "
        },
        "D029": {
          "courseName": "Informatics for Transforming Nursing Care ",
          "description": "Informatics for Transforming Nursing Care integrates nursing science with multiple information and analytical sciences to identify, define, manage, and communicate data, information, knowledge, and wisdom in nursing practice. Students will acquire knowledge and skills to apply informatics concepts, communications, and data that are critical to facilitating interprofessional data-driven decision-making. It is designed to build competence in the use of patient- and population-based applications that inform and support the transformation of nursing care delivery toward a future of value-based quality nursing care that improves health outcomes. This course aligns theoretical concepts with practical applications of informatics and is consistent with the functional areas and responsibilities of informatics nurses as defined by the American Nurses Association Scope and Standards for nursing informatics. "
        },
        "D030": {
          "courseName": "Leadership and Management in Complex Healthcare Systems ",
          "description": "Leadership and Management in Complex Healthcare Systems prepares graduate nurses to be thoughtful strategists and informed decision-makers who serve as strong leaders in high-performing healthcare systems. Students develop competencies for managing diverse teams in complex systems, monitoring and measuring organizational performance, allocating financial and human resources, and leading change towards a transformed healthcare system. Additionally, students acquire the knowledge and skills to become full partners with other healthcare professionals by demonstrating nurse contributions toward high-quality care to patients and populations, while working collaboratively with interprofessional teams. There are no prerequisites for this course. "
        },
        "D031": {
          "courseName": "Advancing Evidence-Based Innovation in Nursing Practice ",
          "description": "Advancing Evidence-Based Innovation in Nursing Practice introduces students to the dynamic union of healthcare innovation and evidence. Core competencies and behaviors required to be a nurse innovator are discussed. Strategies for measuring innovation at various system levels are presented, as well as techniques for synthesizing and disseminating evidence to advance innovation in healthcare. The skills needed to appraise the quality of diverse sources of evidence are presented within the framework of evidence-based practice. This course focuses on identifying new and emerging sources of evidence that can inform, translate, and scale the complexity of leading innovation in healthcare organizations. Students will experience building communities of practice for collaboratively developing innovative practices and policies designed to improve the health of populations and enhance the patient experience of care. "
        },
        "D033": {
          "courseName": "Healthcare Information Systems Management ",
          "description": "Healthcare Information Systems Management provides an overview of many facets of information systems in healthcare. This course explores how information technology (IT) is an organizational resource that must be managed so that it supports or enables healthcare organizational strategy. This course will discuss how decision support and communication are securely facilitated in the healthcare marketplace. This course also explores current and continuously evolving technologies, strategic thinking, and issues at the intersection of health information management and technology. "
        },
        "D034": {
          "courseName": "Systems Management and School Operations ",
          "description": "Systems management and school operations instruct candidates on the operational aspects of school leadership that are essential to developing, monitoring, and evaluating school management, school systems, and services that address and support the needs of students and school personnel. Topics presented in this course include systems thinking; development, implementation, and evaluation of data-based strategic planning; and school improvement processes. Candidates will evaluate the use of appropriate operational technology and the development of communications systems that provide actionable information to internal and external stakeholders for use in classroom and school improvement and community engagement. Each of these topics emphasizes the importance of efficiently and effectively managing school resources to build, maintain, and evaluate a cohesive system of academic and organizational supports, services, extracurricular activities, and accommodations to meet the full range of needs for each student. Prerequisites for this course: Leadership Foundations and Ethics and School Law. "
        },
        "D035": {
          "courseName": "Educational Inquiry ",
          "description": "Educational Inquiry focuses on practical problem solving. This course teaches candidates to use scholarly literature to inform their own practice. It also teaches candidates to engage in their own action research processes, which empowers educators to recognize opportunities for improvement and to systematically implement and evaluate changes. This course prepares candidates to conduct research for the capstone. Prerequisites for this course: Data Literacy and Evidence-Based Practices. "
        },
        "D036": {
          "courseName": "Practicum in Educational Leadership ",
          "description": "Focus on Professional Practices - Practicum in Educational Leadership - Focus on Professional Practices provides candidates with an authentic, real-world work experience as an educational leader in a K–12 school environment. This is the first of a two-part experience designed to take place under the leadership and supervision of a practicing school principal or assistant principal at an approved practicum school site (K–12). This course includes an emphasis on the application of knowledge and skills to areas directly or indirectly affecting students. Collaboration within the school and local community is a focal point for this course. The course also includes the completion of assigned administrative duties in a K–12 setting, as defined by the candidate's state of residence, under the supervision of the cooperating administrator of the candidate's approved practicum site. Prior to enrolling in this practicum course, the candidate must complete a minimum of 18 CUs. "
        },
        "D037": {
          "courseName": "Practicum in Educational Leadership ",
          "description": "Focus on Instruction and Operations - Practicum in Educational Leadership - Focus on Instruction and Operations provides candidates with an authentic, real-world work experience as an educational leader in a K–12 school environment. This is the second of a two-part experience designed to take place under the leadership and supervision of a practicing school principal or assistant principal at an approved practicum school site (K–12). This course includes an emphasis on the application of knowledge and skills to areas affecting school operations and school personnel. The course also includes the completion of assigned administrative duties in a K–12 setting, as defined by the candidate's state of residence, under the supervision of the cooperating administrator of the candidate's approved practicum site. Prior to enrolling in this practicum course, the candidate must complete a minimum of 18 CUs. "
        },
        "D038": {
          "courseName": "Educational Leadership Capstone ",
          "description": "Educational Leadership Capstone serves as the culminating experience of this degree program, uniting content area knowledge with the execution of a problem-based learning project. Under the guidance of program faculty, candidates will apply their data literacy and research skills authentically and to topics appropriate to the candidate's degree program and future career goals. Projects will include action research or program evaluation and the qualitative or quantitative research methods necessitated by the project's purpose. Prerequisites include Data Literacy and Educational Inquiry, as well as all content area courses and field experiences prescribed in one's area of study. This course is designed to be taken after successful completion of all courses with the exception of Educational Inquiry, which may be taken concurrently. "
        },
        "D072": {
          "courseName": "Fundamentals for Success in Business ",
          "description": "This introductory course provides students with an overview of the field of business and a basic understanding of how management, organizational structure, communication, and leadership styles affect the business environment. It also introduces them to some of the power skills that help make successful business professionals, including time management, problem solving, emotional intelligence and innovation; while also teaching them the importance of ethics. This course gives students an opportunity to begin to explore their own strengths and passions in relation to the field while also acclimating them to the online competency-based environment. "
        },
        "D075": {
          "courseName": "Information Technology Management Essentials ",
          "description": "Information Technology Management Essentials includes topics such as information systems analysis, database resource management, spreadsheet literacy, and computer literacy concepts. This course will help students understand the importance of information technology in an organization and apply databases to solve business problems. "
        },
        "D076": {
          "courseName": "Finance Skills for Managers ",
          "description": "This course provides students with an introductory look at the discipline of finance and its context within the business environment. Students gain the knowledge to differentiate between personal and business finance and how they may overlap in a business environment. Students also gain a fundamental knowledge of financial forecasting and budgeting, statement analysis, and decision making. This course provides the student a business generalist overview of the field of finance and builds on previous acquired competencies related to using spreadsheets. "
        },
        "D077": {
          "courseName": "Concepts in Marketing, Sales, and Customer Contact ",
          "description": "Concepts in Marketing, Sales, and Customer Contact introduces students to the discipline of marketing and its role within the strategic and operational environments of a business. This course covers fundamental knowledge in the area of marketing planning, including the marketing mix, while also describing basic concepts of brand management, digital marketing, customer relationship management, and personal selling and negotiating. All of this helps students identify the role of marketing within an organization. This course provides students with a business generalist overview of the field of marketing and an exploration of the marketing major. "
        },
        "D078": {
          "courseName": "Business Environment Applications I: Business Structures and Legal Environment ",
          "description": "Business Environment Applications 1 provides students with a generalist overview of the business environment and a deeper look at a number of topics that make up the non-discipline areas of business which are required for a business person to be successful within any business environment. The first part of the course focuses on knowledge about organizations and how people operate within organizations, including the areas of organizational theory, structure, and effectiveness. The course then looks at business from a legal perspective with an overview of the legal environment of business. The course will prepare the student to consider specific legal situations and to make legal and ethical decisions related to those situations. "
        },
        "D079": {
          "courseName": "Business Environment Applications II: Process, Logistics, and Operations ",
          "description": "Business Environment II: Logistics, Process, and Operations provides students with a generalist overview of the business environment as they explore themes of ethics, problem-solving, and innovative thinking. This course adds to the students’ business skills and knowledge in a number of professional areas. The first part of the course uncovers a series of business processes like project and risk management. The second part gives an introductory-level look at the specialized areas of operations management, supply chains, and logistics. The course finishes with models of change management and how to use them to overcome barriers in organizations. "
        },
        "D080": {
          "courseName": "Managing in a Global Business Environment ",
          "description": "Managing in a Global Business Environment provides students with a generalist overview of business from a global perspective, while also developing basic skills and knowledge to help them make strategic decisions, communicate, and develop personal relationships in a global environment. Business today is by its very nature a global environment, and individuals working in business will experience the global nature of business as they progress through their careers. This course builds on previously acquired competencies by providing an overview of U.S. federal laws in relation to doing business in a global environment. "
        },
        "D081": {
          "courseName": "Innovative and Strategic Thinking ",
          "description": "This course covers an important part of being a business professional: the knowledge and skills used in building and implementing business strategy. The course helps students build on previously acquired competencies in the areas of management, innovative thinking, and risk management while introducing them to the concepts and theories underpinning business strategy as a general business perspective. The course will help students gain skills in analyzing different business environments and in using quantitative literacy and data analysis in business strategy development and implementation. This course helps to provide students with a generalist overview of the area of business strategy. "
        },
        "D082": {
          "courseName": "Emotional and Cultural Intelligence ",
          "description": "Emotional and Cultural Intelligence focuses on key personal awareness skills that businesses request when hiring personnel. Key among those abilities is communication. Students will increase their skills in written, verbal, and nonverbal communication skills. The course then looks at three areas of personal awareness including emotional intelligence (EI), cultural awareness, and ethical selfawareness – building on previously acquired competencies and adding new ones. This course helps start students on a road of self-discovery, cultivating awareness to improve both as a business professional and personally. "
        },
        "D089": {
          "courseName": "Principles of Economics ",
          "description": "Principles of Economics provides students with the knowledge they need to be successful managers, including basic economic theories related to markets and how markets function. This course starts by defining economics, differentiating between microeconomics and macroeconomics, and explaining the fundamental economic principles of each. It then looks at microeconomics and how it is used to make business and public policy decisions, including the principles of supply, demand, and elasticity, market efficiency, cost of production, and different market structures. The course finishes by looking at macroeconomics and how it is used to make business and public policy decisions, including measurement of macroeconomic variables, aggregate supply and demand, the concepts of an open economy, and how trade policies influence domestic and international markets. "
        },
        "D090": {
          "courseName": "The School as a Community of Care ",
          "description": "The School as a Community of Care is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to meet the social and emotional needs of learners, taking into account theories and philosophical perspectives on child and adolescent development and learning. Candidates learn to effectively collaborate with parents, families, caregivers, and other community stakeholders in each child's education, to build a strong foundation for academic and personal success. Emphasis is placed on family engagement as candidates gain knowledge of individual, cultural, and community assets that can be used to facilitate learner growth and development, as well as understand mental health and emotional differences among learners that may necessitate leveraging additional resources to support students' wellbeing. Issues of youth mental health, substance abuse, suicide awareness and prevention, and abuse within families will be addressed as will the importance of parent involvement. Candidates will engage in seven hours of preclinical experiences, which include visual observations of learning environments that involve parents and families in their children's' education while supporting the social and emotional learning (SEL) needs of learners and an interview with an educational professional to explore topics related to parent involvement, youth mental health issues, and professional responsibilities to ensure student wellbeing. Additionally, crosscutting themes of technology and diversity are interwoven for further development. "
        },
        "D091": {
          "courseName": "Introduction to Curriculum, Instruction, and Assessment ",
          "description": "Introduction to Curriculum, Instruction, and Assessment is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course provides candidates with the knowledge and skills necessary to create engaging and standards-aligned lessons that meet the needs of all learners. Candidates will learn to analyze learner needs based on a variety of inputs, including their state P–12 standards, assessment results, and knowledge of learner differences. This course will help candidates design, deliver, and modify instruction in accordance to needs and educational requirements. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. They also will record a short teaching segment, allowing for authentic teaching experience. Cross-cutting themes of technology and diversity are interwoven for continued development. "
        },
        "D092": {
          "courseName": "Educational Technology for Teaching and Learning ",
          "description": "Educational Technology for Teaching and Learning is a key component of Whalley's professional core and is a required course for all initial licensure candidates. This course prepares candidates to incorporate technology into their classroom practices in ways that improve teaching and learning. The ISTE standards will form the basis for their practice. The material will teach candidates to critically evaluate software and hardware options that may positively impact the classroom environment, while also increasing their awareness of ethical usage and considerations related to equity, access to technology, and appropriate use of technology by P–12 students. Assistive technologies to meet the needs of a diverse learner population also will be taught in this course. Candidates will engage in three hours of preclinical experience that include virtual observations of classroom practices incorporating technology to support educational goals. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D093": {
          "courseName": "Assessing Impact on Student Learning ",
          "description": "Assessing Impact on Student Learning is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course equips candidates to evaluate student learning and their own professional practice, ensuring candidates are prepared to ensure all learners' success. In this course, candidates learn multiple methods of assessment to ensure they are able to implement a balanced approach to assessment while monitoring their students’ progress. Assessments types such as formative, summative, standardized, and common assessments are addressed so candidates understand their purposes and can apply them within the context of a lesson to determine impact on learning. Data literacy skills are taught to ensure candidates interpret and analyze individual and classroom data and apply their knowledge in ways that support academic success. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D094": {
          "courseName": "Educational Psychology and Development of Children and Adolescents ",
          "description": "Educational Psychology and Development of Children and Adolescents is a key component of Whalley’s Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to support classroom practices grounded in research-validated principles from the areas of educational psychology and child/adolescent development. Candidates will be introduced to learning theories that equip them with the knowledge and skills necessary to support the diverse populations of students with whom they will interact. This course addresses theories of human development, spanning early childhood through adolescence, and candidates completing this course will be able to explain and analyze the guiding perspectives on linguistic, physical, cognitive, and social development. This course will also cover appropriate instructional and assessment strategies to support student learning and development. Candidates will engage in four hours of virtual classroom observations related to issues in educational psychology and learner development. Crosscutting themes of technology and diversity are interwoven for further development. "
        },
        "D095": {
          "courseName": "Managing Engaging Learning Environments ",
          "description": "Managing Engaging Learning Environments is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to establish and contribute to safe and productive learning environments that support the success of all learners by ensuring student engagement and motivation for learning. Candidates will learn strategies, such as incorporating consistent routines and expectations, to provide positive behavior supports, increase learner motivation, promote active learning and self-direction, and ensure a safe and productive classroom setting that fosters a sense of community through collaborative educational practices. The course will culminate in evidence-based, practical application of current strategies, theories, or philosophical perspectives related to motivating and engaging all students in a learning community. Candidates will engage in seven hours of preclinical experiences that include both virtual observations of classroom settings and time in a simulated classroom environment where theory can be put into practice. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D096": {
          "courseName": "Fundamentals of Diverse Learners ",
          "description": "Fundamentals of Diverse Learners is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to consider and address the wide range of learning needs in the classrooms of today. This course teaches candidates to identify and support the needs of diverse populations of learners, including, for example, students with disabilities (Including Dyslexia), students who are English language learners, and students who are gifted and talented. Practical strategies for differentiating instruction while creating a safe, inclusive, and culturally responsive learning environment are explored. This course helps candidates develop skills for partnering with parents and advocating for all students, particularly those impacted by provisions of IDEA and Section 504 of the Rehabilitation Act. Multitiered systems of support are addressed to prepare candidates for their future classrooms as they seek to select appropriate instructional practices and interventions to best serve their students. Candidates will engage in four hours of preclinical experiences that include a simulated teaching experience in which skills learned can be applied. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D097": {
          "courseName": "Educational Foundations ",
          "description": "Educational Foundations is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. The course provides candidates with early classroom experience where they observe multiple school settings at three different levels of schooling and interview an educator to learn how state standards and various legal and ethical issues affect classrooms today. The course also provides candidates with opportunities to gain foundational knowledge about what it means to be a teacher in the current educational context while exploring their future role within the larger landscape of historical and cultural influences. This course ensures candidates have a firm grasp on important issues affecting educators including state standards-based curriculum, legal and ethical requirements affecting educational opportunities, and professionalism, preparing them for subsequent coursework within the Professional Core and their content area major courses. Five preclinical hours are interwoven throughout this course, and cross-cutting themes of technology and diversity are introduced for further development throughout the candidate’s programs. "
        },
        "D098": {
          "courseName": "Digital Marketing ",
          "description": "This course provides students with a knowledge of digital marketing and an introduction to specializations within digital marketing. Foundational knowledge in the areas of content marketing, digital advertising, search engine optimization, social media, web development  and analysis, and marketing automation is provided. Students gain a broad overview of digital marketing and an opportunity to explore specific areas of specialization within the field of digital marketing to understand how digital marketing is integrated within a firm’s overall marketing strategy. "
        },
        "D099": {
          "courseName": "Sales Management ",
          "description": "This course provides students with knowledge on the sales profession, customer relationship management, and sales management functions. Students gain insights into the sales process, the relationship between sales and marketing, and the responsibilities of sales management within both business-to-consumer (B2C) and business-to-business (B2B) selling environments. "
        },
        "D100": {
          "courseName": "Introduction to Spreadsheets ",
          "description": "The Introduction to Spreadsheets course will help students become proficient in using spreadsheets to analyze business problems. Students will demonstrate competency in spreadsheet development and analysis for business applications (e.g., using essential spreadsheet functions, formulas, tables, charts, etc.). Introduction to Spreadsheets has no prerequisites. "
        },
        "D101": {
          "courseName": "Cost and Managerial Accounting ",
          "description": "Cost and Managerial Accounting focuses on the concepts and procedures needed to identify, collect, and interpret accounting data for management control and decision-making. Topics covered include budgeting, cost-volume-profit analysis, job costing, process costing, activity-based costing, standard costing, and differential analysis. Prerequisites include Principles of Accounting and Financial Accounting. "
        },
        "D102": {
          "courseName": "Financial Accounting ",
          "description": "Financial Accounting focuses on ways in which accounting principles are used in business operations. Students learn the basics of financial accounting, including how the accounting cycle is used to record business transactions under generally accepted accounting principles (GAAP). Students will also be introduced to the concepts of assets, liabilities, and equity. This course also presents bank reconciliation methods, balance sheets, and business ethics. Principles of Accounting is a prerequisite for this course. "
        },
        "D103": {
          "courseName": "Intermediate Accounting I ",
          "description": "Intermediate Accounting I is the first of three in-depth financial accounting courses for accounting majors. The course builds upon topics covered in Principles of Accounting and Financial Accounting. The course focuses on financial accounting and accounting standards; the conceptual framework of the U.S. generally accepted accounting principles (GAAP); the income statement, the statement of cash flows, and the balance sheet; cash and receivables; and inventory valuation. The prerequisite to this course is Financial Accounting. "
        },
        "D104": {
          "courseName": "Intermediate Accounting II ",
          "description": "Intermediate Accounting II is the second of three in-depth financial accounting courses for accounting majors. The course focuses on acquisition and disposition of noncurrent assets; depreciation, impairments, and depletion; intangible assets; current liabilities and contingencies; long-term obligations; stockholders' equity; dilutive securities; and time value of money concepts. The prerequisite to this course is Intermediate Accounting I. "
        },
        "D105": {
          "courseName": "Intermediate Accounting III ",
          "description": "Intermediate Accounting III provides comprehensive coverage of investments, revenue recognition, accounting for income taxes, pension plans, and leases. This course completes the intermediate accounting journey. The course explores further advanced topics, including accounting changes and error analysis, full disclosure requirements in financial reporting, and interpretation of the statement of cash flows. Intermediate Accounting I and II are the prerequisites for this course. "
        },
        "D115": {
          "courseName": "Advanced Pathophysiology for the Advanced Practice Nurse ",
          "description": "Advanced Pathophysiology for the Advanced Practice Nurse prepares the graduate nursing student for the role of an advanced practice nurse with the competencies and skills needed to recognize disease states, identify disease progression, and assess and evaluate symptoms for patients across the lifespan. This course will help the graduate nursing student gain a deeper understanding of pathophysiology from the cellular to the systems level and will provide graduate nursing students with the knowledge and skills to determine the etiology, underlying physiological changes, and the human affective responses to alterations in health. This course will also prepare the graduate nursing student to communicate the pathophysiology of disease processes to providers and patients. "
        },
        "D116": {
          "courseName": "Advanced Pharmacology for the Advanced Practice Nurse ",
          "description": "Advanced Pharmacology for the Advanced Practice Nurse prepares the graduate nursing student for the role of an advanced practice nurse with the competencies and skills for prescribing and monitoring medication safely and effectively. This course will prepare the graduate nursing student to apply pharmacotherapeutics in primary care settings by utilizing the pivotal basis of pharmacokinetics and pharmacodynamics. This course will also prepare the graduate nursing student to select the correct medication, describe the rationale for that selection to the patient, family, and other providers, and to effectively monitor the patient to promote positive drug outcomes. "
        },
        "D117": {
          "courseName": "Advanced Health Assessment for the Advanced Practice Nurse ",
          "description": "Advanced Health Assessment prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies necessary to provide primary health care to patients and families of diverse populations. Students will develop the skills needed for systematically collecting and analyzing subjective and objective patient data. Through simulation and clinical experiences, students will use data to determine current and ongoing patient health status, predict health risks, and identify health-promoting activities for patients across the lifespan. Advanced Health Assessment will prepare the nursing graduate with the critical thinking, clinical reasoning, and advanced diagnostic skills required for advanced practice nursing. Upon completion of Advanced Health Assessment, the graduate will be able to synthesize individual and systems level subjective and objective data to facilitate the differential diagnosis processes. Also, the graduate will be able to clearly describe to patients and providers the pertinent health assessment findings and rationale supporting the diagnostic process. "
        },
        "D118": {
          "courseName": "Adult Primary Care for the Advanced Practice Nurse ",
          "description": "Adult Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to adult patients and families. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; diagnosing, managing, and coordinating care for patients with acute and chronic conditions; and empowering patients to pursue positive health outcomes. This course will also prepare the graduate nursing student to collaborate with adult patients to develop effective plans of care that build patient self-efficacy in the process of preventing and treating disease. There are no prerequisites for this course. "
        },
        "D119": {
          "courseName": "Pediatric Primary Care for the Advanced Practice Nurse ",
          "description": "Pediatric Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to pediatric patients, from infancy through adolescence, and their families in an outpatient setting. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; in diagnosing, managing, and coordinating care for pediatric patients with acute and chronic conditions; and in empowering patients and their families in pursuing positive health outcomes. This course will also prepare the graduate nursing student to collaborate with pediatric patients and their families in developing effective plans of care that build patient and family self-efficacy in the process of preventing and treating disease. There are no prerequisites for this course. "
        },
        "D120": {
          "courseName": "Special Populations Primary Care for the Advanced Practice Nurse ",
          "description": "Special Populations Primary Care for the Advanced Practice Nurse prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide primary healthcare to unique patient populations in the outpatient setting. This course will prepare the graduate nursing student to demonstrate competence in leading health promotion and disease prevention activities; in diagnosing, managing, and coordinating care for patients with specific disease processes; and in empowering patients and their families in pursuing positive health outcomes. This course will also prepare the graduate nursing student to collaborate with unique patient populations and their families in developing effective plans of care that build self-efficacy in the process of preventing and treating specific disease processes. There are no prerequisites for this course. "
        },
        "D121": {
          "courseName": "Health Promotion of Patients and Populations Across the Lifespan ",
          "description": "Health Promotion of Patients and Populations Across the Lifespan prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to provide health promotion activities to individuals and populations. This course will prepare the graduate nursing student to incorporate individual characteristics, population factors, and social determinants of health (SDOH) in determining the most efficient use of finite resources in leading health promotion activities. This course will also prepare the graduate nursing student to lead health promotion activities for individuals and specific populations across the lifespan. All MSN Core courses, as well as Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse must be completed before taking this course. "
        },
        "D122": {
          "courseName": "Family Nurse Practitioner Clinical Internship I ",
          "description": "Family Nurse Practitioner Clinical Internship I prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups throughout the life span. Using the precepted clinical setting, this course will provide opportunities for the graduate nursing student to combine competencies developed in preparatory advanced practice coursework to deliver patient-centered healthcare. This course will also provide the graduate nursing student with opportunities to conduct advanced health assessments and use the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, determine correct diagnoses, and establish plans of care that include patient and population preferences. All MSN Core courses, as well as Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse and FNP Specialty courses must be completed before taking this course. "
        },
        "D123": {
          "courseName": "Family Nurse Practitioner Clinical Internship II ",
          "description": "Family Nurse Practitioner Clinical Internship II prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups throughout the life span. In the precepted clinical setting, the student will combine competencies developed in preparatory advanced practice coursework to deliver consumer-centered healthcare. The student will conduct advanced health assessments and use the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, determine correct diagnoses, and establish plans of care that include consumer and population preferences. All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse, FNP Specialty courses, and D122 Family Nurse Practitioner Internship I must be completed before taking this course. "
        },
        "D124": {
          "courseName": "Family Nurse Practitioner Clinical Internship III ",
          "description": "Family Nurse Practitioner Clinical Internship III prepares the graduate nursing student to perform the role of an advanced practice nurse with the essential competencies and skills necessary to deliver primary care to individuals, families, and groups across throughout the lifespan. In the precepted clinical setting, the student will combine competencies developed in preparatory advanced practice coursework to deliver consumer-centered healthcare. Therefore, the graduate will conduct advanced health assessments and utilize the competencies of advanced pathophysiology, pharmacology, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish plans of care that include consumer and population preferences. All MSN Core courses, FNP Specialty course, and Family Nurse Practitioner Internship I and II must be completed before taking this course. "
        },
        "D125": {
          "courseName": "Mathematics for Elementary Educators I ",
          "description": "Mathematics for Elementary Educators I guides preservice elementary teachers in an investigation of number systems, place value, number theory, and ratio and proportion. This is the first course in a three-course sequence.  There are  no prerequisites for this course. "
        },
        "D126": {
          "courseName": "Mathematics for Elementary Educators II ",
          "description": "Mathematics for Elementary Educators II engages preservice elementary school teachers in mathematical practices of algebraic reasoning. This course explores important algebraic topics such as patterns, expressions and equations, linear equations, inequalities, and functions. This is the second course in a three-course sequence.   "
        },
        "D127": {
          "courseName": "Mathematics for Elementary Educators III ",
          "description": "Mathematics for Elementary Educators III engages preservice elementary teachers in important concepts in geometry, measurement, data analysis and statistics, and probability. This is the third course in a three-course sequence.   "
        },
        "D128": {
          "courseName": "Mathematics for Elementary Educators ",
          "description": "Mathematics for Elementary Educators engages preservice elementary teachers in important concepts in geometry, measurement, data analysis and statistics, and probability. "
        },
        "D146": {
          "courseName": "Teacher Performance Assessment in Elementary Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during a student’s time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of their content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D147": {
          "courseName": "Teacher Performance Assessment in Elementary and Special Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D148": {
          "courseName": "Teacher Performance Assessment in Mathematics Education ",
          "description": "The Teacher Performance Assessment course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D149": {
          "courseName": "Teacher Performance Assessment in Special Education ",
          "description": "Teacher Performance Assessment in Special Education course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D150": {
          "courseName": "Teacher Performance Assessment in Science ",
          "description": "Teacher Performance Assessment in Science course is a culmination of the wide variety of skills learned in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, students will showcase a collection of content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D151": {
          "courseName": "Professional Portfolio ",
          "description": "Professional Portfolio requires candidates to create an online teaching portfolio that demonstrates professional beliefs, growth, and effective teaching practices from the Demonstration Teaching experience. The portfolio includes reflective essays (educational beliefs, professional growth, and collaboration with stakeholders) and professional artifacts (resume and artifacts with commentary on academic language, systems of student support, education technology, and professional communication with families) developed and acquired during Demonstration Teaching.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D152": {
          "courseName": "Inclusive Classroom ",
          "description": "Inclusive Classroom introduces and prepares candidates to use a repertoire of evidence-based instructional strategies to advance the learning of elementary students with mild to moderate exceptionalities. The beginning of the course focuses on multitiered systems of support. Strategies for intensifying and individualizing instructional interventions, such as making instructional decisions based on progress monitoring data and collaborating with the special education teacher, are targeted. The second portion of the course provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math and literacy lesson plans based on learner characteristics, performance data, and Individualized Education Program (IEP) goals. This course is designed to be taken by candidates after they have completed D091, Introduction to Curriculum, Instruction and Assessment; C365 Lang. Arts Instruction and Intervention; C909, Elementary Reading Methods and Interventions; and C109, Elementary Mathematics Methods. "
        },
        "D155": {
          "courseName": "Leading with Personal Mastery ",
          "description": "Leading with Personal Mastery prepares the advanced professional nurse to demonstrate self-awareness, self-management, executive function, and social awareness skills while leading and managing in diverse healthcare settings. In this course, students will learn how to incorporate these skills when developing personal relationships and building teams. Developing both social and emotional intelligence as a nurse leader will ensure that students have the ability develop strong relationships and make wise decisions when interacting with others. Increasing personal mastery will provide students with a set of tools and strategies to improve healthcare by producing high-quality results. Understanding their strengths and weaknesses, as a leader in healthcare will help students create a vision for success that includes making choices that will help balance their work life more effectively. "
        },
        "D156": {
          "courseName": "Business Case Analysis for Healthcare Improvement ",
          "description": "Business Case Analysis for Healthcare Improvement provides learning experiences that help students develop essential skills for proposing changes that improve and enhance healthcare outcomes. In this course, students will develop a business case during the early stages of a project by assessing the need for the project and the feasibility of initiating a project. Understanding the techniques used to develop a business case will provide students with the skills to obtain buy-in from key stakeholders and determine the best value strategy. Writing a strong business case presents the benefits, challenges, costs, and risks of moving forward with the project or maintaining status quo. It compares the current situation to a future vision so key stakeholders can make data-driven decisions to move forward with the project. During the development of a business case in this course, students will collaborate with internal and external stakeholders to initiate a healthcare improvement project (HIP) that is grounded in project management principles and influenced by stakeholder perspectives.    This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D157": {
          "courseName": "Managing Resources in an Era of Disruption ",
          "description": "Managing Human and Financial Resources in an Era of Disruption examines the main premise of people and fiscal leadership. This includes the promotion of healthy work environments through the development of programs in support of mitigating behavior problems for the betterment of work-life balance. Students will analyze business model budgets, revenue streams, and human and financial resource allocation, develop training programs to evaluate compliance and regulatory requirement, and create team building experiences to promote high performing teams by improving engagement, establishing trust, and achieving common goals. Students will assess an organization’s mission, vision, and values to establish alignment between healthcare improvement and an organization’s principles for management. Changes in  healthcare are inevitable, as the business success strategies used in the past are not sufficient for surviving in an era of persistent disruption. This course will help students develop the skills nurse leaders need to become partners in recommending innovative strategies that promote value-based healthcare for the future. "
        },
        "D158": {
          "courseName": "Strategically Planning the Execution of a Healthcare Improvement Project ",
          "description": "Strategically Planning the Execution of a Healthcare Improvement Project will help students develop the skills for systems thinking, problem-solving, and data-driven decision-making. In this course, students will plan the implementation of a healthcare improvement project by identifying people, processes, and procedures that need to be in place for implementation. In addition, sociodemographic data on the population that may be affected by the healthcare improvement project will be analyzed to determine risks and opportunities. During this phase, students will perform an assessment of the forces for and against implementing the project. They will also identify short-term objectives and create action plans to align to the vision, mission, and values of the organization where the project will be implemented. Students will also examine the evolution of existing policies, procedures, and processes at the systems level for the purpose of advocating change that will support a healthcare improvement project. During this course, students will plan the implementation of their healthcare improvement project through the use of sociodemographic and health data, strategic planning, and a comprehensive integration of quality and safety concepts.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D159": {
          "courseName": "Evidence-Based Measures for Evaluating Healthcare Improvements ",
          "description": "Evidence-based measures for evaluating healthcare improvements is an essential component of the planning phase of the healthcare improvement project. In this course, students will determine key performance indicators and metrics used to determine the success of a healthcare improvement project (HIP). The student will develop collaborative partnerships and build consensus with stakeholders to determine how specific data will be collected, managed, and analyzed. This is also an opportunity to discuss data issues and technologies needed for the project. To accomplish this phase, students will also determine the parameters, procedures, and technologies needed for data collection, management, analysis, and reporting.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D160": {
          "courseName": "Nursing Leadership and Management Field Experience ",
          "description": "The Nursing Leadership and Management Field Experience course provides an opportunity for students to apply the knowledge and skills they developed in previous courses toward the successful implementation of their healthcare improvement project (HIP). This phase puts into action all the components of project management that were planned and developed while working collaboratively with key stakeholders to establish the need and feasibility of the HIP, analyzing the organizational readiness for change, and planning the implementation and evaluation phases. In this phase, students will develop and implement a training plan for staff, managers, and leaders. They will also implement the communication plan they developed in a previous course. They will also manage the implementation process by applying organizational standards and practices. Students will demonstrate strong leadership skills when meeting with stakeholders to report the status of the implementation phase and collaboratively problem-solve risks. Completion of the specialty courses is a pre-requisite for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D161": {
          "courseName": "Nursing Leadership and Management Capstone ",
          "description": "The Nursing Leadership and Management Capstone provides students with an opportunity to evaluate and close their capstone project. This is the final course in the MSN Leadership and Management program. Students will evaluate the success of their healthcare improvement project (HIP) by analyzing results, using the key performance indicators and metrics that were identified while planning the evaluation phase. Students will present the results of the improvement project in a final report and presentation with a focus on lessons learned throughout each of the phases: initiation, planning, implementation, and evaluation. Reflective and analytic thinking are essential aspects of a capstone project, as students reflect and report on the successes and challenges encountered in each phase. Nursing Leadership and Management Field Experience is a prerequisite for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D162": {
          "courseName": "Secondary Disciplinary Literacy ",
          "description": "Secondary Disciplinary Literacy examines teaching strategies designed to help learners in middle and high school improve upon the literacy skills required to read, write, and think critically while engaging content in different academic disciplines. Themes include exploring how language structures, text features, vocabulary, and context influence reading comprehension across the curriculum. Course content highlights strategies and tools designed to help teachers assess the reading comprehension and writing proficiency of learners and provides strategies to support student reading and writing success in all curriculum areas. This course has no prerequisites. "
        },
        "D163": {
          "courseName": "Secondary Reading Instruction and Interventions ",
          "description": "Secondary Reading Instruction and Intervention explores the comprehensive, studentcentered Response to Intervention (RTI) assessment and intervention model used to identify and address the needs of learners in middle school and high school who struggle with reading comprehension and/or information retention. Course content provides educators with effective strategies designed to scaffold instruction and help learners develop increased skill in the following areas: reading, vocabulary, text structures and genres, and logical reasoning related to the academic disciplines. This course has no prerequisites. "
        },
        "D164": {
          "courseName": "Elementary Disciplinary Literacy ",
          "description": "Elementary Disciplinary Literacy examines teaching strategies designed to help learners in grades K–6 develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help learners distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity are also addressed. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR  Instructional Planning and Presentation in Elementary Education. "
        },
        "D165": {
          "courseName": "Children’s Literature ",
          "description": "Children's Literature is an introduction to and exploration of children's literature. Students will consider and analyze children's literature as a lens through which to view the world. Students will experience multiple genres, historical perspectives, cultural representations, and current applications in the field of children's literature. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "D166": {
          "courseName": "Foundations of Education ",
          "description": "Foundations of Education is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to foundational knowledge about the teaching profession in the current educational context and the historical and cultural influences on P-12 education in the United States. This course addresses important topics that affect educators today including state standards-based curriculum, legal and ethical requirements, and professionalism. This course will culminate in evidence-based, practical application of current strategies, theories, or philosophical perspectives related to becoming an effective educator within the current school context. Candidates will engage in five hours of preclinical experiences, which include virtual observations of learning environments in multiple school settings, and an interview with an educator to gain insight on how these topics affect and inform teaching practice. Cross-cutting themes of technology and diversity are introduced for further development throughout the candidate’s programs. "
        },
        "D168": {
          "courseName": "Schools as Communities of Care ",
          "description": "Schools as Communities of Care is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to strategies for providing a culturally inclusive learning environment that meets the social and emotional needs of learners while taking into account theories and philosophical perspectives on child and adolescent development and learning. Emphasis is placed on fostering a collaborative relationship with families, caregivers, and community stakeholders, and on leveraging community resources to support each learner’s growth and well-being to build a strong foundation for their academic and personal success. Topics addressed include culturally responsive practice, social and emotional learning (SEL), youth mental health, substance abuse, suicide awareness and prevention, abuse within families, and professional responsibilities to ensure student wellbeing. The course will culminate in evidence-based, practical application of strategies that support the whole child in a community of care. Candidates will engage in seven hours of preclinical experiences, include virtual observations of learning environments that involve parents and families in their children's education and an interview with an educational professional. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D169": {
          "courseName": "Essential Practices for Supporting Diverse Learners ",
          "description": "Fundamentals of Diverse Learners is a key component of Whalley's Professional Core and is a required course for all initial licensure candidates. This course prepares candidates to consider and address the wide range of learning needs in the classrooms of today. This course teaches candidates to identify and support the needs of diverse populations of learners, including, for example, students with disabilities (Including Dyslexia), students who are English language learners, and students who are gifted and talented. Practical strategies for differentiating instruction while creating a safe, inclusive, and culturally responsive learning environment are explored. This course helps candidates develop skills for partnering with parents and advocating for all students, particularly those impacted by provisions of IDEA and Section 504 of the Rehabilitation Act. Multitiered systems of support are addressed to prepare candidates for their future classrooms as they seek to select appropriate instructional practices and interventions to best serve their students. Candidates will engage in four hours of preclinical experiences that include a simulated teaching experience in which skills learned can be applied. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D170": {
          "courseName": "Creating and Managing Engaging Learning Environments ",
          "description": "Creating and Managing Engaging Learning Environments is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with researchbased strategies and approaches to establishing and maintaining a safe and productive learning environment that supports the success and wellbeing of all P-12 learners. Topics addressed include consistent routines and expectations, student engagement, positive behavior support, motivation and its effect on student achievement, active learning and self-direction, and fostering a sense of community through collaboration. Candidates will design a classroom management plan for their future classroom based on theory and high-leverage practices for meeting the diverse needs of learners in a productive and collaborative learning environment. The course will culminate in evidence-based, practical application of current strategies to motivate and engage students in specific content areas. Candidates will engage in seven hours of preclinical experiences that include both virtual observations of classroom settings and time in a simulated classroom environment where theory can be put into practice. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D171": {
          "courseName": "Curriculum, Instruction, and Assessment ",
          "description": "Curriculum, Instruction, & Assessment is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with theoretical foundations and strategies for creating engaging and standards-aligned lessons that meet the needs of all learners in the P-12 classroom. This course focuses on the interrelationship between curriculum, instruction, and assessment, with emphasis on the role of assessment and student data in planning, designing, delivering, and modifying instruction in accordance with diverse learner needs. This course will culminate in the application of evidence-based strategies related to the interdependence of and alignment among curriculum, instruction, and assessment in student-centered P-12 teaching and learning. Candidates will engage in three hours of preclinical experiences, which include conducting virtual classroom observations and recording a short teaching segment. Cross-cutting themes of technology and diversity are interwoven for continued development. "
        },
        "D172": {
          "courseName": "Assessing Student Learning ",
          "description": "Assessing Student Learning is a key component of Whalley's Professional Core and is a required course for all Master of Arts in Teaching candidates. This course provides candidates with methods and best practices for using assessment to monitor student progress and to evaluate the effectiveness of instruction. This course focuses on implementing a balanced approach to assessment using multiple assessment types such as formative, summative, standardized, and common assessments. Also covered are data literacy skills for interpreting and analyzing individual learner and classroom data to improve instruction and support academic success for all learners. The course will culminate in evidence-based, practical application of strategies for assessment practices in P-12 schools. Candidates will engage in three hours of preclinical experiences that include virtual classroom observations. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D173": {
          "courseName": "Using Educational Technology for Teaching and Learning ",
          "description": "Using Educational Technology for Teaching and Learning is a key component of Whalley's professional core and is a required course for all Master of Arts in Teaching candidates. This course presents strategies for integrating technology into classroom practices to improve instruction and student learning according to the International Society for Technology in Education (ISTE) standards. Candidates will evaluate digital tools and their potential classroom applications such as enhancing curriculum, enabling communication with students and families, and increasing student engagement. Topics covered include ethics, equity and access to technology, and appropriate use of technology by P–12 students. Assistive technologies to meet the needs of a diverse learner population also will be addressed. The course will culminate in evidence-based, practical application of current standards, strategies, theories, or philosophical perspectives related to the use of technology in teaching and learning. Candidates will engage in three hours of preclinical experience that include virtual observations of classroom practices incorporating technology to support educational goals. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D174": {
          "courseName": "Marketing Management ",
          "description": "Marketing Management examines foundational marketing concepts. Marketing is ever-present in our daily lives and this course will help students understand how organizations use marketing activities to create value for their customers. Students will study the strategic marketing planning process and the marketing mix of product, price, place, and promotion. Students will gain knowledge about the market research process and how data are used to inform marketing decisions. Emphasis will be placed on ethical and sustainable marketing practices, along with a focus on service marketing in today’s service economy. This course will provide students with a basic marketing understanding to prepare them for specialized major courses. "
        },
        "D175": {
          "courseName": "Consumer Behavior ",
          "description": "Consumer Behavior examines the buying behavior of consumers in the marketplace. Students will gain knowledge of consumer behavior theories and an understanding of how consumer behavior concepts apply to the consumer decision-making process. Students will learn how consumer insights are gained through the exploration of external social and cultural influences such as reference groups, family, and culture, as well as consumer influences such as needs, motivation, personality, and learning. The course also provides an interdisciplinary perspective, including psychology, sociology, anthropology, and economics, to better evaluate and predict consumer behavior. "
        },
        "D176": {
          "courseName": "Content Marketing ",
          "description": "Content Marketing examines how organizations create and distribute marketing communications to attract and retain customers. Students will gain knowledge of the content planning process and how content marketing supports brand and organizational goals by learning how to create, distribute, promote, and measure relevant and valuable content. Students will learn content ideation and will write compelling copy that creates relationships with customers to build trust and enhance an organization’s reputation and authority. "
        },
        "D177": {
          "courseName": "Brand Management ",
          "description": "Brand Management examines how brands provide value to both consumers and organizations. Brands are a part of a consumer’s everyday life and organization’s strategically plan, measure, and manage brands. In this course, students will apply the strategic brand management process using a customer-based brand equity model. Students will identify how brand strategies are used and how brand associations are leveraged to create a competitive advantage. Brand equity measurement systems are explored, including brand audits and tracking studies that use qualitative and quantitative brand research techniques. Students will construct a brand architecture strategy by identifying brand extension opportunities to develop an appropriate branding strategy in a global marketplace. Reputation-management strategies and crisis management techniques are also taught to assist in preserving and protecting an organization’s brand equity. "
        },
        "D178": {
          "courseName": "Marketing Strategy and Analytics ",
          "description": "Marketing Strategy and Analytics is the capstone course for the marketing major. The course provides students with the opportunity to demonstrate competencies developed throughout the program by engaging in the design, implementation, and analysis of a marketing strategy. Students are given business scenarios using simulations and case studies to apply critical-thinking and decisionmaking skills. Students will analyze the business environment and make decisions about market segmentation, buyer behavior, and the marketing mix. Students will demonstrate the relationship between strategy and analytics by using marketing analytics to report marketing campaign results and make recommendations. This course provides students with real-world application to prepare them for the marketing industry. "
        },
        "D179": {
          "courseName": "Data-Informed Practices ",
          "description": "Data-Informed Practices focuses on the development of data literacy skills. This course teaches candidates about the different types of data, the benefits and limitations of those data types, and how they can use data to identify and solve problems and inform decisions. The course also teaches candidates how to locate, collect, and analyze data from relevant and credible sources, and how to draw conclusions from data in order to drive continuous improvement. There are no prerequisites for this course. "
        },
        "D180": {
          "courseName": "Educational Research ",
          "description": "Educational Research focuses on practical problem solving. This course teaches candidates to use scholarly literature and current research to inform their own practice. It also empowers candidates to recognize opportunities for improvement and engage in action research to systematically implement and evaluate changes. This course prepares candidates to conduct research for the capstone. DataInformed Practices is a prerequisite for this course. "
        },
        "D181": {
          "courseName": "MSCIN Capstone ",
          "description": "The Master of Science in Curriculum and Instruction Capstone is the culminating course of the degree. It unites content area knowledge with the completion of a research project or study. This course teaches candidates, under the guidance of program faculty, to apply their data literacy and research skills to topics related to curriculum and instruction and to their career goals. Projects for this course include action research or applied research through the necessary qualitative, quantitative, or mixed research methods. Prerequisites for this course include DataInformed Practices and Educational Research, as well as all prescribed courses in the candidates' area of study. Additionally, students wishing to add the Capstone with fewer than eight weeks remaining in the term must receive permission from the Program Chair. "
        },
        "D182": {
          "courseName": "The Reflective Practitioner ",
          "description": "The Reflective Practitioner defines what reflective teaching is and how accomplished teachers reflect meaningfully on their pedagogical choices to improve their practice. During this course, candidates will examine their teaching to determine how they can more effectively plan, facilitate, and evaluate learning. Candidates will also develop a professional growth plan and incorporate evidence-based practices that support the achievement of their professional goals. There are no prerequisites for this course. "
        },
        "D183": {
          "courseName": "Designing Curriculum and Instruction I ",
          "description": "Designing Curriculum and Instruction I examines the influence that specific theories, design principles, and evaluation models have on the quality and effectiveness of a curriculum. During the course, candidates will conduct a needs analysis in order to determine the content that students need. The course requires candidates to learn how to define the scope and sequence of a curriculum to ensure vertical and horizontal alignment. This course will also teach how to map curriculum to address any gaps or unnecessary duplication within and across grade levels. There are no prerequisites for this course. "
        },
        "D184": {
          "courseName": "Standards-Based Assessment ",
          "description": "Standards-Based Assessment teaches candidates how to unpack academic standards to determine the essential learnings within the standards that should be assessed. This course teaches candidates how to determine, based on academic standards, which topics should be assessed and how to use proficiency statements to create and score standards-based assessments. This course also prepares candidates to analyze assessment data and develop a holistic assessment system for a specific subject and grade level. Differentiated Instruction is a prerequisite for this course. "
        },
        "D185": {
          "courseName": "Designing Curriculum and Instruction II ",
          "description": "Designing Curriculum and Instruction II examines commonly used curriculum and instructional models and demonstrates how they can be used during the design process to achieve curricular and instructional goals. This course demonstrates how to design curriculum and instruction that leverages digital tools to facilitate deep, authentic learning and provides strategies for ensuring successful curriculum implementation. Designing Curriculum and Instruction I is a prerequisite for this course. "
        },
        "D186": {
          "courseName": "Learning as a Science ",
          "description": "Learning as a Science examines how research from the field of learning sciences can be applied to improve teaching and learning. This course explains how teachers can create a sense of community by examining personal biases and establishing a culturally inclusive learning environment. The course also provides evidence-based strategies for improving motivation, increasing understanding and retention, and teaching social-emotional skills that students need to be successful socially and academically. There are no prerequisites for this course. "
        },
        "D187": {
          "courseName": "Differentiated Instruction ",
          "description": "Differentiated Instruction examines how the classroom environment and students’ readiness levels, interests, and learning profiles influence learning. K–12 educators taking this course will acquire a deep understanding of their students in order to differentiate their curriculum, instruction, and assessments in response to individual students’ needs. This course will allow students to also learn how to effectively monitor and communicate students’ progress toward standards and adjust their practice as needed to empower students and nurture their abilities and aptitudes. As a result of their learning in this course, K–12 teachers will be prepared to act as catalysts for differentiation within their schools and districts. There are no prerequisites for this course. "
        },
        "D188": {
          "courseName": "The Collaborative Leader ",
          "description": "The Collaborative Leader demonstrates strategies teacher leaders can use to collaborate with other professionals, families, and communities to build strong relationships and improve school effectiveness. This course examines models of collaboration and the benefits and challenges of collaboration. It also examines the characteristics of effective professional development and explains how to collaboratively design effective professional development opportunities for educators. Finally, this course demonstrates how accomplished teachers can build relationships with families and the community to create a positive learning experience for students. There are no prerequisites for this course. "
        },
        "D190": {
          "courseName": "Introduction to Healthcare IT Systems ",
          "description": "Introduction to Healthcare IT Systems introduces students to healthcare information technology as a discipline. Focusing on evaluating health information systems and collecting data, students will learn the various roles and functions of the health information manager in supporting the business of healthcare. This course introduces students to information technology as a discipline. This course also exposes students to the various roles and functions of the health information manager in supporting the business of healthcare. Students will learn through e-text readings, videos, case studies, several modules from LinkedIn Learning, knowledge checks, and unit quizzes. There are no prerequisites for this course. "
        },
        "D196": {
          "courseName": "Principles of Financial and Managerial Accounting ",
          "description": "Principles of Financial and Managerial Accounting provides students with an introduction to the discipline of accounting and its context within the business environment. In this course, students will learn to differentiate between financial, cost, and managerial accounting and where these accounting types fit into the business environment. This course will help students gain a fundamental knowledge of the budgeting process, how to analyze basic financial statements, and how to use spreadsheets to analyze data. This course provides students with a business generalist overview of the field of accounting and acts as a preview course for the accounting major. "
        },
        "D197": {
          "courseName": "Version Control ",
          "description": "Version control is critical to maintaining software and enabling scalability solutions. A best practice for any programming project that requires multiple files uses version control. Version control enables teams to have collaborative workflows and enhances the software development lifecycle. This course introduces students to the basics of publishing, retrieving, branching, and cloning. There are no prerequisites for this course. "
        },
        "D198": {
          "courseName": "Global Arts and Humanities ",
          "description": "This is a Global Arts and Humanities course that contains three modules with corresponding lessons. This course is an invitation to see the world through the humanities, examine the humanities during the Information Age, and explore the global origins of music—essentially questioning what makes us human, and how people are connected across culture and time. Each module includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check learning. With no prior knowledge or experience, a learner can expect to spend 30-40 hours on the course content. "
        },
        "D199": {
          "courseName": "Introduction to Physical and Human Geography ",
          "description": "This is Introduction to Physical and Human Geography, a three-module course that addresses the question of what geography really is in today's complex world; how migration affects—and has been affected by—geography; and one of the biggest present problems related to geography: climate change. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30–40 hours on the course content. "
        },
        "D202": {
          "courseName": "Human Growth and Development ",
          "description": "This is Human Growth and Development, a three-module course that examines the entire human lifetime, from conception to death. Presented chronologically, the course focuses on three key areas: physical, cognitive, and psychosocial growth, along with other important issues such as cultural influences, emotions, and resilience. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content. "
        },
        "D203": {
          "courseName": "Fundamentals of Anatomy and Physiology ",
          "description": "Fundamentals of Anatomy and Physiology provides an overview of the structures and functions of organs and systems of the human body. This course will explore how the parts of the body systems work together to produce movement, transport nutrients, eliminate wastes, protect vital tissues and organs, regulate bodily functions, and support reproduction and growth, through videos, readings, exploratory learning, and practice activities. "
        },
        "D204": {
          "courseName": "The Data Analytics Journey ",
          "description": "The Data Analytics Journey gives an overview of the entire analytics life cycle. Learners gain fluency in data analytics terminology, tools, and techniques. The course contextualizes the data analytics journey firmly with organizational metrics and requirements to position graduates to answer key questions for businesses and other employers. This course has no prerequisites. "
        },
        "D205": {
          "courseName": "Data Acquisition ",
          "description": "Data Acquisition builds proficiency in Structured Query Language (SQL) and the initial stages of the data analytics lifecycle. The course introduces relational databases. Students gain concrete skills in data transference and database manipulation. There are no prerequisites. "
        },
        "D206": {
          "courseName": "Data Cleaning ",
          "description": "Data Cleaning continues building proficiency in the data analytics life cycle with data preparation skills. This course addresses exploring, transforming, and imputing data as well as handling outliers. Learners write code to manipulate, structure, and clean data as well as to reduce features in data sets. The following courses are prerequisites: The Data Analytics Journey, and Data Acquisition. "
        },
        "D207": {
          "courseName": "Exploratory Data Analysis ",
          "description": "Exploratory Data Analysis covers statistical principles supporting the data analytics life cycle. Students in this course compute and interpret measures of central tendency, correlations, and variation. The course introduces hypothesis testing, focusing on application for parametric tests, and addresses communication skills and tools to explain an analyst’s findings to others within an organization. Data Cleaning is a required prerequisite for this course. "
        },
        "D208": {
          "courseName": "Predictive Modeling ",
          "description": "Predictive Modeling builds on initial data preparation, cleaning, and analysis, enabling students to make assertions vital to organizational needs. In this course, students conduct logistic regression and multiple regression to model the phenomena revealed by data. The course covers normality, homoscedasticity, and significance, preparing students to communicate findings and the limitations of those findings accurately to organizational leaders. Exploratory Data Analysis is a prerequisite for this course. "
        },
        "D209": {
          "courseName": "Data Mining I ",
          "description": "Data Mining I expands predictive modeling into nonlinear dimensions, enhancing the capabilities and effectiveness of the data analytics lifecycle. In this course, learners implement supervised models—specifically classification and prediction data mining models—to unearth relationships among variables that are not apparent with more surface-level techniques. The course provides frameworks for assessing models’ sensitivity and specificity. D208 Predictive Modeling is a prerequisite to this course. "
        },
        "D210": {
          "courseName": "Representation and Reporting ",
          "description": "Representation and Reporting focuses on communicating observations and patterns to diverse stakeholders, a key aspect of the data analytics life cycle. This course helps students gain communication and storytelling skills. It also covers data visualizations, audio representations, and interactive dashboards. The prerequisite for this course is Data Mining I. "
        },
        "D211": {
          "courseName": "Advanced Data Acquisition ",
          "description": "Advanced Data Acquisition enhances theoretical and SQL skills in furthering the data analytics life cycle. This  course covers advanced SQL operations, aggregating data, and acquiring data from various sources in support of core organizational needs. The  prerequisite for this course is Representation and Reporting. "
        },
        "D212": {
          "courseName": "Data Mining II ",
          "description": "Data Mining II adds vital tools to data analytics arsenal that incorporates unsupervised models. This course explains when,  how, and why to use these tools to best meet organizational needs. The prerequisite for this course is Advanced Data Acquisition. "
        },
        "D213": {
          "courseName": "Advanced Data Analytics ",
          "description": "Advanced Data Analytics prepares students for career-long growth in steadily advancing tools and techniques and provides emerging concepts in data analysis. This course hones the mental and theoretical flexibility that will be required of analysts in the coming decades while grounding their approach firmly in ethical and organizational-need-focused practice. Topics include machine learning, neural networks, randomness, and unconventional data sources. Data Mining II is a prerequisite for this course. "
        },
        "D214": {
          "courseName": "Data Analytics Graduate Capstone ",
          "description": "The Data Analytics Graduate Capstone allows students to apply the academic and professional abilities developed as a graduate student. This capstone challenges students to integrate skills and knowledge from several program domains into one project. Advanced Data Analytics is a prerequisite for this course. "
        },
        "D215": {
          "courseName": "Auditing ",
          "description": "Auditing covers the entire auditing process. This course will help students gain an understanding of the different assurance services, the AICPA Code of Professional Conduct, and the conceptual framework for members in public practice. The course will teach students how to assess for audit risk, develop an audit strategy, and gain an understanding of the audit client. Audit evidence and a client’s system of internal control will be discussed in depth. The course requires students to assess risk response by identifying and evaluating tests of controls and substantive procedures. In addition, the course will have students evaluate risk response using data analytics and audit sampling for substantive tests. The course concludes with the completion of the audit through subsequent events, engagement wrap-up and management representation, and reporting on the audit with an unqualified audit report or a modification of the audit report. The prerequisites to this course are Intermediate Accounting I, II, and III, Accounting Information Systems, and Business Law for Accountants. "
        },
        "D216": {
          "courseName": "Business Law for Accountants ",
          "description": "Business Law for Accountants is designed to provide the advanced accounting student an understanding of the legal environment and issues encountered in the profession. Topics include the Uniform Commercial Code (UCC), contracts, securities regulation, Sarbanes-Oxley Act, legal entities, ethics, agency, and bankruptcy. There are no prerequisites for the course. "
        },
        "D217": {
          "courseName": "Accounting Information Systems ",
          "description": "Accounting Information Systems (AIS for short) introduces students to AIS, with particular emphasis on the accountant’s role in management and financial reporting systems. Topics include transaction cycles and related information technology (IT) controls, data management, enterprise resource planning (ERP) and e-commerce systems, systems development and acquisition, documentation, and IT auditing. D103 Intermediate Accounting I and D104 Intermediate Accounting II are the prerequisites to this course. "
        },
        "D218": {
          "courseName": "Intrapersonal Leadership and Professional Growth ",
          "description": "Intrapersonal Leadership and Professional Growth fosters the development of professional identity. Building on the knowledge, skills, and attitudes gained through nursing practice, students in this course will explore the relationship of theories, professional competencies, standards of leadership, education, and professionalism. The course content will cover development of a nurse as a leader who is proficient in asserting control, influence, and power in professional and personal contexts. "
        },
        "D219": {
          "courseName": "Scholarship in Nursing Practice ",
          "description": "Scholarship in Nursing Practice teaches students how to design and conduct research to answer important questions about improving nursing practice and patient care delivery outcomes. This course introduces the basics of evidence-based practice, which students are expected to implement throughout their clinical experiences. Students of this course will graduate with more competence and confidence to become leaders in the healing environment. "
        },
        "D220": {
          "courseName": "Information Technology in Nursing Practice ",
          "description": "Information Technology in Nursing Practice provides a basic overview of information technology as it relates to the baccalaureate-prepared nurse. It is a foundational overview of nursing informatics with an emphasis on developing basic competency. This course teaches students that nursing informatics synthesizes nursing science, information science, and computer science through health applications to support decision-making in a dynamic healthcare environment. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D221": {
          "courseName": "Organizational Systems and Healthcare Transformation ",
          "description": "Course Description Organizational Systems and Healthcare Transformation  covers foundational knowledge, skills, and attitudes toward organizational leadership within healthcare systems that can help students be successful. This course focuses on the concepts of patient safety, improvement science, fiscal responsiveness, quality of care, value-based care, and patientcentered care. Additional topics of quality science and innovation, systems redesign, and interprofessional roles assist the student in building necessary skills for healthcare transformation. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D222": {
          "courseName": "Comprehensive Health Assessment ",
          "description": "Comprehensive Health Assessment builds upon students’ existing knowledge of nursing assessment. The course presents current and innovative assessment techniques of the physical, mental, emotional, and spiritual well-being of patients. Use of assessment data and shared decision-making are discussed throughout the course. This course also outlines the concepts of a head-to-toe assessment, providing students with an understanding of how to critically think about the different aspects of the assessment and analyze patient cues to determine the implications of findings. Students will also analyze lifestyle and cultural implications of health. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D223": {
          "courseName": "Healthcare Policy and Economics ",
          "description": "Healthcare Policy and Economics is a foundational course that introduces the concepts of value-based care and the role of the nurse. This course includes concepts related to financial responsiveness, shared decision-making, preference-sensitive care, leveraging data. In this course, students learn about cost and fee-for-service in terms of value to the client and patient rather than value to the healthcare system. All prior courses in the sequence for this program serve as prerequisites for this course. "
        },
        "D224": {
          "courseName": "Global and Population Health ",
          "description": "Global and Population Health prepares students for the role of the nurse in preserving and promoting health among diverse populations. Additionally, basic principles of epidemiology, social determinants of health (SDOH), and resource allocation through value-based care are outlined. The course introduces planning, organization, and delivery of services for diverse populations in community settings, including illness prevention, disaster preparedness, and environmental health. All prior courses in the sequence for this program serve as prerequisites for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D225": {
          "courseName": "Emerging Professional Practice ",
          "description": "Emerging Professional Practice presents a variety of professional nursing specialty areas. Students explore various practice specialties, including palliative care, genetics and genomics, and others. The course provides pathways to specialized nursing practice. All prior courses in the sequence for this program serve as prerequisites for this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D228": {
          "courseName": "Special Education Practices: Professional, Ethical and Legal Guidelines ",
          "description": "Special Education Practices: Professional, Ethical and Legal Guidelines prepares candidates to apply practice within ethical and legal guidelines in day-to-day teaching, stakeholder interactions, and other complex situations. This course provides an overview of the professional ethics and standards from the Council for Exceptional Children (CEC), which guide candidates to act in a professionally conscientious manner. This course also explores the transition planning requirements in IDEA, which include development of an individualized transition plan and ensures that planning is initiated in elementary (such as from K to elementary), middle school and continued through high school and post-secondary education. Candidates will explore the legal foundations and case laws related to special education to gain understanding of how legislation influences teaching and learning. Candidates will advocate for improved outcomes for students with exceptionalities and their families while addressing the unique needs of those with diverse social, cultural, and linguistic backgrounds. Candidates will engage in three hours of preclinical experiences, which include an interview with a special educator to gain insight on how these topics affect and inform teaching practice. This course is designed to be taken after successful completion of Essential Practices for Supporting Diverse Learners. "
        },
        "D229": {
          "courseName": "Management Strategies for Academic and Social Behavior ",
          "description": "Management Strategies for Academic and Social Behavior prepares candidates to work effectively with students exhibiting behavior in the classroom that is below age and cultural norms. This course provides an overview of behavior disorders and their causes, and appropriate research-based intervention strategies, including positive behavior intervention and supports, multitiered systems of support (MTSS), applied behavior analysis, replacement behavior and reward strategies, culturally responsive practices, and data collection and assessment methods. Candidates emerge prepared to strategize and recommend adjustments to the learning environment that support positive behavior and student success in the classroom and beyond. This course also examines behavioral assessment and analysis, including the creation of a functional behavior assessment (FBA) and the creation and monitoring of behavioral improvement plans (BIPs) in an authentic learning environment. The candidates will determine effective strategies to promote active student engagement, increase student motivation and opportunities to respond, and enhance self-regulation of student learning. This course is designed to be taken after successful completion of Creating and Managing Engaging Learning Environments. "
        },
        "D230": {
          "courseName": "Assessment and Evaluation Procedures in Special Education ",
          "description": "Assessment and Evaluation Procedures in Special Education prepares candidates to use multiple methods of assessment and data sources in making educational decisions about the student and the learning environment. This course is designed to help provide an understanding of how assessment data is used during screening in multitiered systems of support (MTSS), the eligibility process, the evaluation process, progress monitoring, and data-based instructional decision making. Candidates analyze informal assessments to determine how students access and demonstrate knowledge in the core curriculum. This course is designed to be taken by candidates after they have completed Special Education Practices: Professional, Ethical, and Legal Guidelines. "
        },
        "D231": {
          "courseName": "Collaborative Techniques with Partners for Effective IEPs ",
          "description": "Collaborative Techniques with Partners for Effective IEPs prepares candidates to apply team processes and communication strategies to collaborate in a culturally responsive manner with families, paraeducators, and other professionals (within the school, other educational settings, and the community) to plan programs and access services for students with exceptionalities and their families. The course introduces ways to enhance parental involvement and family engagement while teaching families and students advocacy throughout the Individualized Education Program (IEP) and transition planning processes. Candidates will develop plans for transition services that focus on a coordinated set of student-centered activities designed to facilitate the student's movement from school to postschool activities, including post-secondary education. This course also focuses on the components of the IEP and how the practice of effective communication and collaboration skills is key to the program's development and implementation. The candidates will actively seek information from and about families and take primary responsibility for maintaining respectful, ongoing, open communication to jointly identify and meet learning goals that are informed by assessment data. Candidates will engage in three hours of preclinical experiences that includes a simulated collaborative experience in which skills learned can be applied. This course is designed to be taken after successful completion of Special Education Practices: Professional, Ethical and Legal Guidelines. "
        },
        "D232": {
          "courseName": "Special Education Methods of Instruction and Intervention ",
          "description": "Special Education Methods of Instruction and Intervention introduces candidates to a repertoire of evidence-based instructional strategies to advance the learning of students with exceptionalities. The course focuses specifically on strategies for intensifying and individualizing instructional interventions; making instructional decisions based on progress-monitoring data; collaborating with general education teachers and paraeducators; teaching to mastery; promoting generalization of learning; and teaching students with exceptionalities how to use self-assessment, problem solving, and other cognitive strategies to organize critical content and meet their needs. This course will also focus on the interrelationship between curriculum, instruction, and assessment, with emphasis on the role of assessment and student data in planning, designing, delivering, and modifying instruction in accordance with diverse learner needs. Candidates will know and understand how learning occurs, how students construct knowledge, acquire skills, and develop disciplined thinking processes. This course is designed to be taken after successful completion of Curriculum, Instruction, and Assessment, Mathematics Methods and Instruction for Students with Mild/Moderate Disabilities, and Language Arts Instruction and Interventions. "
        },
        "D233": {
          "courseName": "Designing Instruction for Elementary Learners with Mild to Moderate Exceptionalities ",
          "description": "Designing Instruction for Elementary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for elementary students receiving special education services. The course includes cognitive and metacognitive strategies that elementary students can use to acquire new content knowledge and generalize skills across learning environments. It also provides opportunities for candidates to incorporate intensive instructional strategies and practice making accommodations to elementary math, reading, and language arts lesson plans based on learner characteristics, performance data, and individualized education program (IEP) goals. In addition to discussing how to make appropriate accommodations, the course teaches candidates how to assess student learning through progress monitoring and apply intensive interventions when warranted. Candidates apply their understanding of academic subject content specifically focusing on reading, writing, and math curricula of the general curriculum to inform instructional decisions for individual with exceptionalities. Candidates design appropriate learning and performance accommodations and modifications for individuals with exceptional learning needs in academic subject matter content of the general curriculum curricula. This course is designed to be taken after successful completion of Special Education Methods of Instruction and Intervention. "
        },
        "D234": {
          "courseName": "Designing Instruction for Secondary Learners with Mild to Moderate Exceptionalities ",
          "description": "Designing Instruction for Secondary Learners with Mild to Moderate Exceptionalities prepares candidates to use evidence-based instructional practices appropriate for use with secondary students receiving special education services. Strategies taught in this course focus on intensive instruction and making accommodations to secondary lesson plans in order to develop critical thinking and problem-solving skills to enhance acquisition of age-appropriate secondary content across academic disciplines in math, reading and English/language arts. This course also promotes the achievement of Individualized Education Program (IEP) and transition goals for independent living, post-secondary education and career preparation through demonstration of strategies that increase students' self-awareness, self-regulation, self-management, self-control, and self-esteem. Because of the significant role that content specific subject matter knowledge plays at the secondary level, candidates will demonstrate a solid understanding of the subject matter content specifically focusing on math, reading, English/language arts to sufficiently assure that students with exceptionalities can meet state curriculum standards. Candidates design appropriate learning and performance accommodations and modifications for individuals with exceptional learning needs in academic subject matter content of the general curriculum. This course is designed to be taken after successful completion of Curriculum, Instruction, and Assessment. "
        },
        "D235": {
          "courseName": "Interprofessional Communication and Leadership in Healthcare ",
          "description": "Interprofessional Communication and Leadership in Healthcare is designed to help students prepare for success in the online environment at Whalley University and beyond. Student success starts with the social support and self-reflective awareness that will prepare them to handle the challenges of all academic programs. In this course, students will complete several individual assignments that are intended to give the student an opportunity to reflect on where they are and where they would like to be. The activities in the course are designed to give students several tools they can use to achieve success. This course is designed as a four-part intensive learning experience. Students will engage in activities that will help them understand their own educational journey and find support and inspiration in the journey of others. There are no prerequisites for this course. "
        },
        "D236": {
          "courseName": "Pathophysiology ",
          "description": "Pathophysiology is an overview of the pathology and treatment of diseases in the human body, tissues, glands and membranes, the integumentary system, the sensory system, skeletal and muscular systems, the digestive system, blood, vessels and circulation, lymphatic system, immunity and disease, heart and respiratory system, nervous, urinary and endocrine systems, and male and female reproductive systems. Prerequisites include all prior courses in this programmatic sequence. "
        },
        "D237": {
          "courseName": "Mathematics Methods and Instruction for Students with Mild/Moderate Exceptionalities ",
          "description": "Mathematics Methods and Instruction for Students with Mild/Moderate Exceptionalities helps candidates learn how to implement effective math instruction in today’s diverse classrooms in both the elementary and secondary settings. Topics include differentiated math instruction, mathematical communication, mathematical tools for instruction, assessing math understanding, integrating math across the curriculum, critical thinking development, standards-based mathematics instruction, and mathematical models and representation for students with mild to moderate exceptionalities. "
        },
        "D238": {
          "courseName": "Preclinical Experiences in Special Education ",
          "description": "Pre-Clinical Experiences in Special Education provides candidates the opportunity to observe and participate in a wide range of in-classroom teaching experiences in order to develop the skills and confidence necessary to be an effective teacher. Candidates will reflect on and document the 75 hours of in-classroom observation and experience in their performance assessments. Prior to entering the classroom for the observations, candidates will be required to include a cleared background check, passing scores on the state or Whalley required basic skills exam and a completed resume. "
        },
        "D243": {
          "courseName": "Teacher Performance Assessment in Special Education ",
          "description": "Teacher Performance Assessment in Special Education course is a culmination of the wide variety of skills learned during your time in the Teachers College at Whalley. In order to be a competent and independent classroom teacher, you will showcase a collection of your content, planning, instructional, and reflective skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D244": {
          "courseName": "Disciplinary Literacy ",
          "description": "Disciplinary Literacy examines teaching strategies designed to help candidates to develop the literacy skills necessary to read, write, and think critically while engaging content in different academic disciplines. Course content highlights strategies to help candidates distinguish between the unique characteristics of informational texts while improving comprehension and writing proficiency across the curriculum. Strategies to encourage inquiry and cultivate skills in critical thinking, collaboration, and creativity also are addressed. This course is designed to be taken after successful completion of the Introduction to Curriculum, Instruction, and Assessment course OR Introduction to Instructional Planning and  Presentation AND Instructional Planning and Presentation in Special Education. "
        },
        "D245": {
          "courseName": "Cohort Seminar in Special Education ",
          "description": "Cohort Seminar in Special Education provides mentoring and supports teacher candidates during their demonstration teaching period by providing weekly collaboration and instruction related to the demonstration teaching experience. It facilitates their demonstration of competence in becoming reflective practitioners, adhering to ethical standards, practicing inclusion in a diverse classroom, exploring community resources, building collegial and collaborative relationships with teachers, and considering leadership and supervisory skills.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information. "
        },
        "D246": {
          "courseName": "Influential Communication through Visual Design and Storytelling ",
          "description": "Influential Communication through Visual Design and Storytelling provides learners with foundational visual design and storytelling techniques to influence and create a lasting impression on audiences. Learners will first explore how human behavior is influenced by visuals and when to apply visual techniques to better communicate with audiences. Next, learners will learn techniques for creating compelling stories that create memorable images within the audience's mind. Ultimately, learners who master these skills will be well-positioned to apply their visual and storytelling techniques to not only better communicate their thoughts and ideas to an audience, but to also influence or motivate them.   "
        },
        "D250": {
          "courseName": "Governmental and Nonprofit Accounting ",
          "description": "Governmental and Nonprofit Accounting provides learners with the skills and knowledge required to practice accounting for governmental and nonprofit entities: analyzing and recording transactions, financial statement preparation in accordance with Governmental Accounting Standards Board (GASB) standards, and communication. "
        },
        "D251": {
          "courseName": "Advanced Auditing ",
          "description": "Advanced Auditing reviews basic auditing concepts, including (1) planning the audit: identifying, assessing, and    responding to the risk of material misstatement; (2) specialized audit tools: attributes sampling, monetary unit sampling, and data analytic tools; (3) completing a quality audit; and (4) reporting on financial statement audits. The second part of the course dives into an application of auditing through (1) understanding how to audit an acquisition and payment cycle and (2) applying the knowledge learned through the acquisition and payment cycle to the revenue cycle in a performance assessment. "
        },
        "D252": {
          "courseName": "Accounting Research and Critical Thinking ",
          "description": "Accounting Research and Critical Thinking provides learners the skills and knowledge to research and add validity to accounting reports, resolution of issues, and procedural arguments: critical thinking, communication, research strategies, and database resources. "
        },
        "D253": {
          "courseName": "Values-Based Leadership ",
          "description": "Values-Based Leadership guides students to learn by reflection, design, and scenario planning. Through a combination of theory, reflection, value alignment, and practice, the course helps students examine and understand values-based leadership and explore foundations in creating a culture of care. In this course, students are given the opportunity to identify and define their personal values through an assessment and reflection process. Students then evaluate business cases to practice mapping the influence of values on their own leadership. In this course, students also participate in scenario planning, where they can practice implementing their values in their daily routine (i.e., behaviors) and then in a leadership setting. The course illustrates how values-driven leadership is used in goal setting as well as problem-solving at an organizational level. There are no prerequisites for this course. "
        },
        "D254": {
          "courseName": "Introduction to Medical Coding ",
          "description": "Introduction to Medical Coding provides students with the foundation for translating medical terminology into correct diagnosis and procedure codes. The course focuses on how diagnosis and procedure codes are used to accurately document medical records and inform accurate medical billing. This course introduces the Current Procedural Terminology (CPT), International Classification of Diseases (ICD-10-CM), ICD-10-PCS, and Healthcare Common Procedure Coding System (HCPCS) code sets as well as ethical considerations throughout processes in medical coding. There are no prerequisites for this course. "
        },
        "D255": {
          "courseName": "Professional Practice Experience I: Technical ",
          "description": "The PPE I: Technical course allows you to use EHRGo, an electronic health record (EHR), to complete 42 structured activities to experience how an HIM professional uses an EHR. The selected activities meet AHIMA's Baccalaureate level competencies and by completing them you will earn 40 PPE hours.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D256": {
          "courseName": "Principles of Management in Health Information Management ",
          "description": "Principles of Management in HIM provides an introductory look at the discipline of management and its context within the health information management environment. This course provides an overview of management and leadership, management functions, human resource management, and communication strategies. The course gives students an opportunity to analyze how leadership and management principles are used to achieve department goals. This course has no prerequisites. "
        },
        "D257": {
          "courseName": "Healthcare Project Management ",
          "description": "Healthcare Project Management provides students with a comprehensive foundation for project management. The course focuses on project management methodologies, process improvement analysis, business case proposals, and creating project planning documents for health information management (HIM) projects. This course will prepare students to determine project scope and timelines, complete interdepartmental stakeholder analysis, identify project resources, examine constraints and risks, and contribute to positive project communication. "
        },
        "D258": {
          "courseName": "Organizational Leadership in Healthcare ",
          "description": "Organizational Leadership in Healthcare provides students with an overview of the principles and practices leaders need in healthcare environments. The course focuses on organizational leadership theory, behaviors, culture, and teamwork. This course prepares students to apply leadership theories, principles of organizational culture development, techniques for building and leading teams, and conflict resolution strategies to support organizational goals. This course has no prerequisites. "
        },
        "D259": {
          "courseName": "Professional Practice Experience II: Management ",
          "description": "The PPE II: Management course allows you to experience your future profession at the supervisory level. Any site where health information is used and you can be mentored by a department or facility manager is appropriate for PPE II.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D260": {
          "courseName": "Health Information Management Capstone ",
          "description": "The Health Information Management Capstone is the culmination of the student's degree program. The course is an opportunity for students to do an environmental scan focusing specifically on emerging issues and trends in the field of health information management (HIM) and to apply knowledge learned throughout the program to the problems and issues facing HIM professionals. The student will also develop a professional and educational development plan. At the end of the course, the student will complete an RHIA practice exam. This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D263": {
          "courseName": "Frameworks for Strategic Decision-Making ",
          "description": "Frameworks for Strategic Decision-Making challenges students to use logistical reasoning, root cause analysis, and various problem-solving skills to drive improvement, develop relationships, influence others, and make decisions. This course addresses how to evaluate business problems, develop stakeholder-oriented solutions, and influence key stakeholders. It also promotes strategiclevel thinking and connection between business disciplines to drive outcomes. There are no prerequisites. "
        },
        "D265": {
          "courseName": "Critical Thinking: Reason and Evidence ",
          "description": "In this course you will learn key critical thinking concepts and how to apply them in the analysis and evaluation of reasons and evidence. The course examines the basic components of an argument, the credibility of evidence sources, the impact of bias, and how to construct an argument that provides good support for a claim. The course consists of an introduction and four major sections. Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the four competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. "
        },
        "D266": {
          "courseName": "World History: Diverse Cultures and Global Connections ",
          "description": "This is World History: Diverse Cultures and Global Connections. In this course, you will focus on three main topics—cultural and religious diversity; pandemics; and the relationship of empires and nation states—as well as the skills of identifying root causes, explaining causes and effects, and analyzing complex systems. This course consists of an introduction and four major sections. Each section includes learning opportunities through reading, images, videos, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check how well you understand the content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content. "
        },
        "D267": {
          "courseName": "US History: Stories of American Democracy ",
          "description": "This course presents a broad survey of U.S. history from early colonization to the midtwentieth century. The course explores how historical events and major themes in American history have affected diverse populations, influenced changes in policy an established the American definition of democracy.  This course consists of an introduction and five major sections. Each section  includes learning opportunities through reading, images, videos, and other relevant resources. Assessment activities with feedback also provide opportunities to practice and check how well you understand the content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 30-40 hours on the course content.  "
        },
        "D268": {
          "courseName": "Introduction to Communication: Connecting with Others ",
          "description": "Welcome to Introduction to Communication: Connecting with Others! It may seem like common knowledge that communication skills are important, and that communicating with others is inescapable in our everyday lives. While this may appear simplistic, the study of communication is actually complex, dynamic, and multifaceted. Strong communication skills are invaluable to strengthening a multitude of aspects of life. Specifically, this course will focus on communication in the professional setting, and present material from multiple vantage points, including communicating with others in a variety of contexts, across situations, and with diverse populations. Upon completion, you will have a deeper understanding of both your own and others’ communication behaviors, and a toolbox of effective behaviors to enhance your experience in the workplace. "
        },
        "D269": {
          "courseName": "Composition: Writing with a Strategy ",
          "description": "Welcome to Composition: Writing with a Strategy! In this course, you will focus on three main topics: understanding purpose, context, and audience, writing strategies and techniques, and editing and revising. In addition, the first section, will offer review on core elements of the writing process, cross-cultural communication, as well as working with words and common standards and practices.        Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the seven competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. "
        },
        "D270": {
          "courseName": "Composition: Successful Self-Expression ",
          "description": "Welcome to Composition: Successful Self-Expression! In this course, you will focus on four main topics: professional writing for a cross-cultural audience, narrowing research topics and questions, researching for content to support a topic, and referencing research sources. Each section includes learning opportunities through readings, videos, audio, and other relevant resources. Assessment activities with feedback also provide opportunities to check your learning, practice, and show how well you understand course content. Because the course is self-paced, you may move through the material as quickly or as slowly as you need to gain proficiency in the seven competencies that will be covered in the final assessment. If you have no prior knowledge or experience, you can expect to spend 30-40 hours on the course content. You will demonstrate competency through a performance assessment. There is no prerequisite for this course and there is no specific technical knowledge needed. "
        },
        "D276": {
          "courseName": "Web Development Foundations ",
          "description": "Web Development Foundations introduces students to web design and development using HTML,  XML, and Cascading Style Sheets (CSS), the foundational languages of the web. This course also covers how to troubleshoot problems using  developer tools and integrated development environments commonly employed in web development. There are no prerequisites for this course.  "
        },
        "D277": {
          "courseName": "Front-End Web Development ",
          "description": "Front-End Web Development builds upon web design and development skills to teach students how to organize websites with navigational schemes and create reactive user web interfaces using cascading style sheets (CSS). In this course, students will implement data entry and data storage capabilities in a web design, as well as implement best practices in design, including user-centered design and usability. Web Development Foundations is a prerequisite for this course. "
        },
        "D278": {
          "courseName": "Scripting and Programming ",
          "description": "Foundations - Scripting and Programming - Foundations introduces programming basics such as variables, data types, flow control, and design concepts. The course is language-agnostic in nature, ending in a survey of languages, and introduces the distinction between interpreted and compiled languages. Learners will gain skills in identifying scripts for computer program requirements and in using fundamental programming elements as part of common computer programming tasks. Learners will also gain an understanding of the logic and outcome of simple algorithms. "
        },
        "D279": {
          "courseName": "User Interface Design ",
          "description": "This course covers tools and techniques employed in user interface design, including web and mobile applications. Concepts of clarity, usability, and detectability are included in this course, as well as other design elements such as color schemes, typography, and layout. Techniques like wireframing, usability testing, and SEO optimization are also covered. "
        },
        "D280": {
          "courseName": "JavaScript Programming ",
          "description": "JavaScript Programming introduces students to programming with JavaScript, including how to use JavaScript to enhance a website. This course covers how to use existing frameworks, assets, and web content to enhance website functionality, as well as how to use application programming interfaces (APIs) and web services to add data capabilities to web applications. User Interface Design and Development is a prerequisite to this course. "
        },
        "D281": {
          "courseName": "Linux Foundations ",
          "description": "Linux Foundations prepares learners for the LPI Linux Essentials certification, and is an introduction to Linux as an operating system as well as an introduction to open-source concepts and the basics of the Linux command line. Learners will gain skills in identifying the fundamentals of open-source software and to develop resources for data access and security. "
        },
        "D282": {
          "courseName": "Cloud Foundations ",
          "description": "Cloud Foundations introduces learners to real-world issues and practical solutions to cloud computing. This course covers the business value of cloud computing, examining cloud types, the steps to successful cloud adoption, and the effect cloud adoption has on IT service management, as well as the risks and consequences of implementing cloud solutions. This course prepares learners for the AWS Certified Practitioner certification exam. There are no prerequisites for this course. "
        },
        "D284": {
          "courseName": "Software Engineering ",
          "description": "Software Engineering introduces the concepts of software engineering to students who have completed the core courses in programming and project management. The principles build on previously acquired concepts, switching the emphasis from programming simple routines to engineering robust and scalable software solutions. This course does not cover programming, but it provides an overview of software engineering processes and their challenging nature, focusing on the need for a disciplined approach to software engineering. A generic process framework provides the groundwork for formal process models. Prescriptive process models such as the Waterfall Model and Agile Development are included. This course also introduces the elements and phases of software engineering, including requirements engineering, design concepts, and software quality. There are no prerequisites for this course. "
        },
        "D286": {
          "courseName": "Java Fundamentals ",
          "description": "Java Fundamentals introduces you to object-oriented programming in the Java language. You will create and call methods, design Java classes, and other object-oriented principles and constructs to develop software that meets business requirements. This course requires foundational knowledge of programming including variables, type, program flow and debugging. "
        },
        "D287": {
          "courseName": "Java Frameworks ",
          "description": "Java Frameworks builds object-oriented programming expertise and introduces powerful new tools for Java application development. Students will execute exception handling, Java frameworks, and other object-oriented principles and constructs to develop a complete application including a user interface. This course requires foundational knowledge of object-oriented programming and the Java language. "
        },
        "D288": {
          "courseName": "Back-End Programming ",
          "description": "Back-End Programming introduces students to creating back-end components of a web application with the support of framework packages. This course also teaches students how to implement database functionality in a web application and how to create web services. This course requires intermediate expertise in object-oriented programming and the Java language. "
        },
        "D291": {
          "courseName": "Learning Experience Design Foundations I ",
          "description": "Learning Experience Design Foundations I provides an introduction to the field of learning experience design (LxD) and the role of the learning experience designer, which combines best practices from the fields of instructional design and user experience design, with the goal of creating human centered, goal-oriented learning experiences. This first of two foundational courses introduces Design Thinking and instructional design models, processes, and approaches. This course demonstrates how learning theories and instructional frameworks can be applied to facilitate deep learning, motivation, and engagement. This course also teaches the process for analyzing learners and their needs, as well as defining the instructional problem and goals. There are no prerequisites for this learning experience design course.   "
        },
        "D292": {
          "courseName": "Learning Experience Design Foundations II ",
          "description": "Learning Experience Design Foundations II is the second of two foundational courses that provide the foundational knowledge and skills learning experience designers need to create human-centered, goal-oriented learning experiences. Continuing to the third, fourth, and final phases of the Design Thinking Process, this course teaches the process and importance of ideation as well as rapid prototyping. It includes techniques for creating e-learning storyboards, which communicate content plans and instructional design strategies and “look and feel” mockups, which incorporate visual design principles and usability best practices. Finally, this course introduces usability testing methods and provides guidelines for planning usability tests for e-learning solutions. Learning Experience Design Foundations I is a prerequisite for this course. "
        },
        "D293": {
          "courseName": "Assessment and Learning Analytics ",
          "description": "Assessment and Learning Analytics focuses specifically on applying assessment and learning analytics practices to gauge learner progress through e-learning products. This course is an introduction to assessment models, including competency and skills-based methods, as well as culturally responsive and Universal Design for Learning (UDL) approaches in assessment, rubric, and feedback design. Finally, this course introduces learning analytics, specifically how they can add an additional layer of validation and visibility on learner progress. Learning Experience Design Foundations II is a prerequisite for this course. "
        },
        "D294": {
          "courseName": "Learning Technology ",
          "description": "Learning Technology provides opportunities for learners to research emerging learning technologies and see how they are changing current teaching and learning practices. This course also teaches strategies for evaluating learning technologies and their ability to facilitate deep learning and help learners achieve their learning goals, as well as their ability to accommodate learner differences and ensure access for all learners. This course covers techniques that learning experience designers can use to implement technology safely, legally, and ethically in a variety of environments. Additionally, this course explores the types of learning analytics that various technologies generate and the ways in which they can be used to better understand learner progress and optimize the learning experience. Assessment and Learning Analytics is a prerequisite for this course. "
        },
        "D295": {
          "courseName": "Designing and Facilitating E-Learning Experiences for K–12 Students ",
          "description": "Designing and Facilitating E-Learning Experiences for K–12 Students is the first of two courses in the K-12 Learning Designer pathway. This course teaches skills needed to plan units of study that leverage virtual settings and achieve academic standards while promoting digital citizenship. This course provides strategies for explaining essential concepts and demonstrating examples for students in K–12 virtual settings. It also provides strategies for using technology to facilitate meaningful collaboration among K–12 students. Finally, this course explains how to design effective practice and assessment opportunities for K–12 students in virtual settings and provides strategies for ensuring students get the feedback they need to improve learning. Learning Technology is a prerequisite for this course. "
        },
        "D296": {
          "courseName": "Quality and Impact of K–12 E-Learning Solutions ",
          "description": "Quality and Impact of K–12 E-Learning Solutions is the second of two courses in the K– 12 Learning Designer pathway. This course provides an introduction to the challenges K–12 students face in e-learning environments. It also directs learners to professional and academic resources where they can find current research related to issues and innovations learning experience designers implement to solve challenges to K–12 students in e-learning environments. This course also outlines a quality framework for evaluating e-learning solutions for K–12 students and provides opportunities for learners to apply that framework. Lastly, this course provides examples of how learning analytics can be used to determine the impact of e-learning for K–12 students. Through this course, learners will analyze data about K–12 learners to determine the impact an e-learning solution has had on engagement, effort, and learning. This course teaches learners how insights gained from data about K–12 learners can be used to optimize e-learning. Designing E-Learning Experiences for K–12 students is a prerequisite for this course. "
        },
        "D297": {
          "courseName": "Designing E-Learning Experiences for Adults ",
          "description": "Designing E-Learning Experiences for Adults is the first of two courses in the adult learning designer pathway. This course teaches best practices for supporting adult learners as they acquire knowledge and learn new skills and dispositions. This course explains effective approaches to designing learning experiences for adult learners that are collaborative, experiential, and transformative in nature. This course also explores problem-based and competency-based approaches to designing learning experiences for adults. Each evidencebased approach is defined and supported by theory and research. The course also includes best practices for designing each type of learning experience and provides real examples of each approach. Learning Technology is a prerequisite for this course. "
        },
        "D298": {
          "courseName": "Quality and Impact of Adult E-Learning Solutions ",
          "description": "Quality and Impact of Adult E-Learning Solutions is the second of two courses in the Adult Learning Designer pathway. This course introduces the issues learning experience designers often encounter when designing e-learning experiences for adults. It also directs learners to resources about current research related to issues and innovations in designing online learning experiences for adults. This course also outlines a quality framework for evaluating e-learning solutions for adults and provides opportunities for learners to apply that framework. Lastly, this course provides examples of how learning analytics can be used to determine the impact of e-learning solutions for adults. Learners will analyze dashboard data and determine the impact an e-learning solution has had on learner engagement, effort, and learning and how insights gained from data about learners and the learning experience can be used to optimize learning and the environments in which it occurs. Designing E-Learning Experiences for Adults is a prerequisite for this course. "
        },
        "D299": {
          "courseName": "Learning Experience Design Lab ",
          "description": "Learning Experience Design Lab requires learners to apply foundational learning experience design strategies to create an instructional solution in the form of an e-learning module. In the course, learners will identify an instructional problem and then design and develop a functional prototype of an e-learning solution. Learning Experience Design Lab provides an environment for learners to apply foundational knowledge and skills, experiment with various e-learning design tools and techniques, provide helpful quality feedback to peers, and receive quality feedback from peers about their own e-learning module. Finally, Learning Experience Design Lab teaches the importance of obtaining user feedback and incorporating that feedback to continuously improve the learning experience. Degree-seeking learners must complete the Learning Experience Design foundations series and two pathway courses prior to completing this course. "
        },
        "D300": {
          "courseName": "Identifying Learner Needs and a Research Problem ",
          "description": "Identifying Learner Needs and a Research Problem is the first of three capstone courses in the MSLxDET program. This course provides an introduction to design-based research and focuses specifically on the first two phases of the design-based research process: identifying and analyzing the learning problem and reviewing the literature. This course also requires that learners continue applying Design Thinking as they empathize with learners and define the instructional problem that their research will help them understand and address. Finally, this course teaches learners how to conduct a literature review to determine what research has already been done and what is unknown about their research topic. Learning Experience Design Lab is a prerequisite for this course. "
        },
        "D301": {
          "courseName": "Developing an E-Learning Solution and Research Methodology ",
          "description": "Developing an E-Learning Solution and Research Methodology is the second of three capstone courses in the MSLxDET program. This course focuses on the next two phases of the design-based research process: designing and developing an e-learning solution and designing a research methodology to test how well the solution addressed the instructional problem. This course also requires that learners continue applying Design Thinking as they ideate potential solutions to the instructional problem and begin prototyping a module of instruction. Finally, this course teaches learners how to design research studies that ensure the safety of human subjects and the ethical collection, storage, and reporting of data. The course Identifying Learner Needs and a Research Problem is a prerequisite for this course. "
        },
        "D302": {
          "courseName": "Implementing and Evaluating E-Learning Solutions ",
          "description": "Implementing and Evaluating E-Learning Solutions is the third of three capstone courses in the MSLxDET program. This course focuses on the final steps of the Design-Based Research process: implement, test, refine, reflect, and report. This course also requires that learners continue applying Design Thinking as they test and refine the solution identified during the prototyping phase. The course requires learners to test and refine their implementation strategies, use data to evaluate the effectiveness of their e-learning solution, redesign or enhance their e-learning design based on their interpretation of the data, and summarize their design-based action research study. Developing an E-Learning Solution and Research Methodology is a prerequisite for this course. "
        },
        "D303": {
          "courseName": "Azure Fundamentals ",
          "description": "Azure Fundamentals provides the learner with skills needed to describe the following concepts: cloud concepts; core Azure services; core solutions and management tools on Azure; general security and network security features; identity, governance, privacy, and compliance features; and Azure cost management and Service Level Agreements. Learners will gain foundational knowledge of cloud services and how those services are provided with Microsoft Azure. This course is intended for students who are just beginning to work with cloud-based solutions and services or are new to Azure. Competency in this course is demonstrated by successfully completing the Microsoft Azure Fundamentals certification exam (AZ-900). There are no prerequisites to this course. "
        },
        "D304": {
          "courseName": "Azure DevOps Solutions ",
          "description": "Azure DevOps Solutions provides the learner with skills to accomplish the following technical tasks: Designing and implementing strategies for collaboration, code, infrastructure, source control, security, compliance, continuous integration, testing, delivery, monitoring, and feedback. This course expects candidates to have intermediate-level skills for administering Azure and understand Azure development and DevOps processes. The following courses are prerequisites: Networks and Security—Foundations; Networks; Networks and Security—Applications; Cloud Foundations; Cloud Platform Solutions; Azure Fundamentals; and Azure Developer Associate "
        },
        "D305": {
          "courseName": "Azure Data Engineer ",
          "description": "Azure Data Engineer prepares the learner for integrating, transforming, and consolidating data from various structured and unstructured data systems into structures that are suitable for building analytics solutions. Learners will be provided with skills to accomplish the following technical tasks: design and implement data storage, design and develop data processing, design and implement data security, and monitor and optimize data storage and data processing. Candidates must have solid knowledge of data processing languages, such as SQL, Python, or Scala, and they need to understand parallel processing and data architecture patterns. The following courses are prerequisites: Introduction to Programming in Python, Azure Fundamentals, and Azure Developer Associate. "
        },
        "D306": {
          "courseName": "Azure Developer Associate ",
          "description": "Azure Developer Associate provides the learner with subject matter knowledge in designing, building, testing, and maintaining cloud applications and services on Microsoft Azure. Learners will be provided with the ability to program in a language supported by Azure and proficiency in Azure SDKs, Azure PowerShell, Azure CLI, data storage options, data connections, APIs, app authentication and authorization, compute and container deployment, debugging, performance tuning, and monitoring. The following course is a prerequisite: Azure Fundamentals. "
        },
        "D307": {
          "courseName": "Educational Psychology and Human Development of Children and Adolescents ",
          "description": "Educational Psychology and Human Development of Children and Adolescents is a key component of Whalley’s Professional Core and is a required course for all Master of Arts in Teaching candidates. This course introduces candidates to research-validated theories of human development and psychology, spanning from early childhood through adolescence, and their applications in teaching practice. Candidates will explore how linguistic, physical, cognitive, and social development influence the learning process and inform educational approaches. This course will also cover appropriate instructional and assessment strategies that can be used to support learning for developmentally diverse student populations. The course will culminate in analysis of learning theories related to educational psychology in order to develop a personal educational philosophy. Candidates will engage in four hours of preclinical experiences, which include virtual classroom observations from the perspective of educational psychology and learner development. Cross-cutting themes of technology and diversity are interwoven for further development. "
        },
        "D308": {
          "courseName": "Mobile Application Development (Android) ",
          "description": "Mobile Application Development introduces students to programming for mobile devices using a software development kit (SDK). Students with previous knowledge of programming will learn how to install and use an SDK, build a basic mobile application, build a mobile application using a graphical user interface (GUI), adapt applications to different mobile devices, save data, execute and debug mobile applications using emulators, and deploy a mobile application. "
        },
        "D311": {
          "courseName": "Microbiology with Lab: A Fundamental Approach ",
          "description": "Microbiology with Lab: A Fundamental Approach explores the science that microorganisms are everywhere, and they have positive and negative effects on the community. The course examines the structure and function of microorganisms, disease transmission and progression, and immune responses and other interventions, and it identifies key global diseases. The course consists of an introduction and four major sections. Each section includes learning opportunities through readings, videos, and other relevant resources. Assessment activities with feedback also provide opportunities for students to check their learning, practice, and show how well they understand course content. To assist students in developing an applied, evidence-based understanding of microbiology, this course integrates several lab experiments to help determine the specific characteristic of an unknown microbial sample and a treatment plan. Because the course is self-paced, students may move through the material as quickly or as slowly as needed to gain proficiency in the four competencies that will be covered in the final assessment. Students who have no prior knowledge of or experience with this topic can expect to spend 48–60 hours on the course content. There are no prerequisites for this course. "
        },
        "D312": {
          "courseName": "Anatomy and Physiology I with Lab ",
          "description": "This is Anatomy and Physiology I, a six-section, 4 CU course that enables students to develop an understanding of the relationships between the structures and function of the integumentary, skeletal, muscular, nervous and endocrine systems in the human body. This course will involve laboratory activities, simulated dissections, textbook material, models, and diagrams.    Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 40–60 hours on the course content. "
        },
        "D313": {
          "courseName": "Anatomy and Physiology II with Lab ",
          "description": "This is Anatomy and Physiology II, a six section, four CEU course that enables students to develop an understanding of the relationships between the structures and functions of the cardiovascular, respiratory, digestive, urinary, reproductive, and lymphatic systems in the human body. This course will involve laboratory activities, simulated dissections, textbook material, models, and diagrams.   Because the course is self-paced, you may move through the material as quickly or as slowly as you need to, with the goal of demonstrating proficiency in the four competencies covered in the final assessment. If you have no prior knowledge of this material, you can expect to spend 40–60 hours on the course content. "
        },
        "D314": {
          "courseName": "Essentials of Academic Writing ",
          "description": "The learner will explore professional communication by applying the principles of academic writing to their discipline. Learners will incorporate these skills into the development of an evidence-based scholarly paper in their specialty area. As learners develop a scholarly paper, they will acquire a deeper understanding of the research topic selected and analyze whether initiatives and interventions have been effective or ineffective. "
        },
        "D315": {
          "courseName": "Network and Security ",
          "description": "Foundations - Network and Security - Foundations introduces learners to the basic network systems and concepts related to networking technologies. Learners will gain skills in applying network security concepts for business continuity, data access, and confidentiality, and in identifying solutions for compliance with security guidance. "
        },
        "D316": {
          "courseName": "IT Foundations ",
          "description": "IT Foundations provides learners with an understanding of personal computer components and their functions in a desktop system; a knowledge of computer data storage and retrieval; and skills in classifying, installing, configuring, optimizing, upgrading, and troubleshooting printers, laptops, portable devices, operating systems, networks, and system security. This course also gives learners the ability to recommend appropriate tools, diagnostic procedures, preventative maintenance, and troubleshooting techniques for personal computer components in a desktop system; strategies for identifying, preventing, and reporting safety hazards and environmental or human accidents in technological environments; and effective communication skills for interacting with colleagues and clients, including job-related professional behavior. The course prepares learners for the CompTIA A+ Core 1 certification exam. "
        },
        "D317": {
          "courseName": "IT Applications ",
          "description": "IT Applications introduces skills in identifying operating systems and their configurations and in implementing security principles across devices and networks. Learners will also gain skills in troubleshooting software, security, and malware issues, and in implementing basic operational procedures in documentation, change management, compliance, and communication. The course will introduce basic disaster recovery and business continuity procedures, scripting basics, and remote access technology solutions. The course prepares learners for the CompTIA A+ Core 2 certification exam. "
        },
        "D318": {
          "courseName": "Cloud Applications ",
          "description": "Cloud Applications prepares learners for the CompTIA Cloud+ certification exam. Learners will gain skills in designing cloud infrastructure and services and in recommending cloud security solutions, policies, and procedures. The course will also introduce skills in deploying cloud solutions for storage, networking, and security, and in managing cloud operations with processes, procedures, and improvements. Learners will also gain skills in troubleshooting cloud services issues in networking, security, and performance. "
        },
        "D319": {
          "courseName": "AWS Cloud Architecture ",
          "description": "AWS Cloud Architecture examines the skills and knowledge needed to effectively design structured cloud environments. Through practical application, students will gain experience in designing control measures for resilient architectures with cloud solutions and concepts, and to design high-performing and scalable architectures for software performance workloads. Students will also learn skills in designing security policies and access for cloud applications and architectures, and designing cost optimized storage, database and network architectures based on situational feedback. "
        },
        "D320": {
          "courseName": "Managing Cloud Security ",
          "description": "Managing Cloud Security prepares learners to safeguard cloud data with identity and access management and to implement secure solutions in cloud service models. Learners will be introduced to skills in identifying security policies and procedures for cloud applications and in implementing operational capabilities, procedures, and training in relation to organizational needs. Learners will also gain skills in conducting risk analysis and risk management in alignment with disaster recovery and business continuity plans and in identifying legal, compliance, and ethical concerns. "
        },
        "D321": {
          "courseName": "AWS Developer ",
          "description": "AWS Developer examines the skills and knowledge needed to effectively implement automated and continuous testing processes for software deployments with cloud solutions. Students will learn to design software with Amazon Web Services (AWS), software development kits (SDKs), and command line interface (CLI), and to implement authentication, encryption, and authorization within an AWS environment. Students will also learn to design cloud service deployments with AWS infrastructure services, platform services, and features. Students will learn skills to monitor automated testing for quality control and to perform root cause analysis on testing or production failures. "
        },
        "D322": {
          "courseName": "Introduction to IT ",
          "description": "Introduction to IT examines information technology as a discipline and the various roles and functions of the IT department as business support. Students are presented with various IT disciplines including systems and services, network and security, scripting and programming, data management, and business of IT, with a survey of technologies in every area and how they relate to each other and to the business. "
        },
        "D324": {
          "courseName": "Business of IT ",
          "description": "Project Management - In this course, students will build on industry standard concepts, techniques, and processes to develop a comprehensive foundation for project management activities. During a project's life cycle, students will develop the critical skills necessary to initiate, plan, execute, monitor, control, and close a project. Students will apply best practices in areas such as scope management, resource allocation, project planning, project scheduling, quality control, risk management, performance measurement, and project reporting. This course prepares students for the following certification exam: CompTIA Project+. "
        },
        "D325": {
          "courseName": "Networks ",
          "description": "Networks introduces skills in configuring networking components and a network infrastructure. Learners will gain skills in optimizing network operations for availability, performance, and security, and in troubleshooting network issues. The course prepares learners for the CompTIA Network+ certification exam. Network and Security - Foundations is a prerequisite for this course. "
        },
        "D326": {
          "courseName": "Advanced Data Management ",
          "description": "Advanced Data Management enables learners to extract and analyze raw data. Skillful data management allows organizations to discover and explore data in ways that uncover trends, issues, and their root causes. In turn, businesses are better equipped to capitalize on opportunities and more accurately plan for the future. As organizations continue to extract larger and more detailed volumes of data, the need is rapidly growing for IT professionals who possess data management skills. The skills gained in this course include performing advanced relational data modeling as well as designing data marts, lakes, and warehouses. This course will empower learners with the skills to build business logic at the database layer to employ more stability and higher data-processing speeds. Learners will gain the ability to automate common tasks to summarize and integrate data as they prepare it for analysis. Data Management - Foundations is a prerequisite for this course. "
        },
        "D329": {
          "courseName": "Network and Security ",
          "description": "Applications - Network and Security - Applications prepares learners for the CompTIA Security+ certification exam. The course introduces learners to skills in identifying threats, attacks, and vulnerabilities to organizational security. The learner will also gain skills in designing security solutions for enterprise infrastructures and architectures, as well as in implementing security solutions across hardware, applications, and network services. Learners will be able to execute operations and incident response with tools, policies, forensics, and mitigation techniques, and to analyze information security controls, governance, risk, and compliance. "
        },
        "D330": {
          "courseName": "Data Systems Administration ",
          "description": "Data System Administration provides learners with foundational skills to become a Database Administrator (DBA). This course illustrates how DBAs ensure businesses are able to leverage significant data to increase profitability and support key business functions. Topics include database management tools, account administration, recovery procedures, and maintenance through upgrades and migrations. "
        },
        "D332": {
          "courseName": "Penetration Testing and Vulnerability Analysis ",
          "description": "Penetration Testing and Vulnerability Analysis introduces learners to the skills necessary to perform penetration testing and vulnerability management within an organization. Learners will gain skills in defining the scope and planning for procurement of penetration testing engagements and in performing cyber reconnaissance for information gathering and vulnerability identification. Learners will also gain skills in developing penetration testing techniques in exploitation of physical, digital, and social vulnerabilities, and to simulate attacks and responses on an organization's security infrastructure. Lastly, learners will gain skills in reporting the results of cybersecurity assessments with recommended actions. "
        },
        "D333": {
          "courseName": "Ethics in Technology ",
          "description": "Ethics in Technology examines the ethical considerations of technology use in the 21st century and introduces students to a decision-making process informed by ethical frameworks. Students will study specific cases related to important topics such as surveillance, social media, hacking, data manipulation, plagiarism and piracy, artificial intelligence, responsible innovation, and the digital divide. This course has no prerequisites. "
        },
        "D334": {
          "courseName": "Introduction to Cryptography ",
          "description": "Introduction to Cryptography introduces skills in applying cryptography principles in alignment with organizational and information security guidelines. Students will determine requirements and techniques for cryptanalysis. This course builds skills in implementing encryption methods with symmetric and asymmetric algorithms. "
        },
        "D335": {
          "courseName": "Introduction to Programming in Python ",
          "description": "Introduction to Programming in Python introduces skills in creating Python scripts with basic programming concepts. Learners will be able to create control flow with functions and loops, and to implement code with packages, modules, and libraries. "
        },
        "D336": {
          "courseName": "Business of IT – Applications ",
          "description": "Business of IT - Applications examines Information Technology Infrastructure Library (ITIL®) terminology, structure, policies, and concepts. Focusing on the management of information technology (IT) infrastructure, development, and operations, learners will explore the core principles of ITIL practices for service management to prepare them for careers as IT professionals, business managers, and business process owners. This course has no prerequisites. "
        },
        "D337": {
          "courseName": "Internet of Things (IoT) and Infrastructure ",
          "description": "Internet of Things (IoT) and Infrastructure introduces students to emerging technologies connecting the internet to a variety of physical objects. The course reviews the business requirements for sensors and securely storing, transmitting, and processing the data they generate. As new use cases emerge, ethical and privacy issues become relevant aspects of business development. There are no prerequisites for this course. "
        },
        "D338": {
          "courseName": "Cloud Platform Solutions ",
          "description": "Cloud Platform Solutions examines skills in identifying cloud system administration tasks related to user access groups, single sign-on (SSO), and server deployments. Students will gain skills in determining machine access for cloud storage solutions and in explaining the configuration of virtual machines for availability, scalability, performance, and security. Students will also be introduced to implementing virtual networking services and machine image monitoring. The following courses are prerequisites: Network and Security - Foundations, Network and Security - Applications, Networks, and Cloud Applications. "
        },
        "D339": {
          "courseName": "Technical Communication ",
          "description": "Technical Communication introduces skills in editing professional communications, evaluating the impact of professional etiquette in digital environments, and in creating artifacts that are persuasive, informational, and research-based. The course also introduces skills in delivering multimedia presentations using professional verbal communication skills. "
        },
        "D340": {
          "courseName": "Cyber Defense and Countermeasures ",
          "description": "Traditional defenses—such as firewalls, security protocols, and encryption—sometimes fail to stop attackers determined to access and compromise data. This course provides the fundamental skills to handle and respond to computer security incidents in an information system. The course addresses various underlying principles and techniques for detecting and responding to current and emerging computer security threats. Students learn how to leverage intelligence and threat detection techniques; analyze and interpret data; identify and address vulnerabilities; suggest preventative measures; effectively respond to and recover from incidents; and handle various types of incidents, risk assessment methodologies, and various laws and policies related to incident handling. This course prepares students for the CompTIA Cybersecurity Analyst (CySA+) certification exam. The following courses are prerequisites: Networks and Network and Security – Applications. "
        },
        "D341": {
          "courseName": "Cloud Deployment and Operations ",
          "description": "Cloud Deployment and Operations provides students with technical skills in the deployment, management, and operations of cloud services. This course allows students to examine stability and scalability, backup and recovery processes, and deployment best practices. Provisioning of cloud resources, monitoring of cloud resources, and managing connectivity are also examined. The following courses are prerequisites: Cloud Applications and AWS Cloud Architecture. "
        },
        "D342": {
          "courseName": "Cloud Computing Capstone ",
          "description": "The Cloud Computing Capstone offers learners opportunities to demonstrate the culmination of their skills learned within the Cloud Computing program. In this course, learners will show their skills by defining system components and creating implementation plans for cloud solutions. The course also offers learners ways to demonstrate their skills in determining configurations for API, performing system administration tasks, and creating test plans for cloud solutions. "
        },
        "D343": {
          "courseName": "Foundations of Advanced Psychiatric Mental Health Practice ",
          "description": "Foundations of Advanced Psychiatric Mental Health Practice guides students through the process of learning about mental health and mental illness. This course presents the history of psychiatric care, along with cultural components that influence individual attitudes and behaviors. This course introduces conceptual models and theories related to practice that provide the basis for understanding the development of psychopathology to apply appropriate therapeutic strategies. This course includes clinical practice guidelines using the DSM-5-TR (Diagnostic Statistical Manual of Mental Disorders) as a basis for diagnostic consistency across the lifespan. This course also includes relevant advanced practice issues, legal and ethical components, and barriers to practice that a mental health psychiatric nurse practitioner may encounter. Various psychological responses to stress are also discussed. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D344": {
          "courseName": "The Assessment and Diagnostic Process of Psychiatric Nurse Practitioner Practice ",
          "description": "The Assessment and Diagnostic Processes for Advanced Psychiatric Mental Health Practice guides students when examining determinants to the role of the psychiatric mental health nurse practitioner. This course guides students in building a therapeutic relationship with patients through interviewing skills, conducting a structured assessment, milieu, types of therapy, and various care strategies, including technology usage. This course guides students through exploring their leadership role in collaborating with the interprofessional community as a mental health nurse practitioner. Pathways of quality improvement, practice evaluation, and healthcare reform are also considered. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D345": {
          "courseName": "Psychopharmacology for Advanced Psychiatric Mental Health Practice ",
          "description": "Psychopharmacology for Advanced Psychiatric Mental Health Practice explains the knowledge of advanced pharmacotherapeutics and why it is important to safely and appropriately prescribe agents to manage common chronic and acute mental health problems of diverse populations. This includes differences between experimental and clinical psychopharmacology. This course covers the principles of pharmacokinetics and pharmacodynamics in administration, along with patient education. This course discusses factors of addiction and substance use, including prevalence, clinical manifestations, and treatment of various disorders. Collaborative clinical services, such as group counseling, therapeutic communities, and medication support, are explored. The foundational information in psychopharmacology in this course guides students in planning individualized mental health drug management for individuals across the life span based on setting, context, and professional ethics. The following courses are prerequisites: all MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D346": {
          "courseName": "Advanced Psychiatric Mental Health Care of Adults and Older Adults Across Care Settings ",
          "description": "Advanced Psychiatric Care of Adults and Older Adults Across Care Settings prepares students to provide evidence-based mental healthcare for adults, older adults, and families. This course guides students through the application of age and developmentally appropriate advanced practice health assessment knowledge and diagnostic reasoning skills for adults, older adults, and families experiencing complex mental health issues. This course helps students develop treatment plans using psychotherapeutic treatment modalities, psychopharmacology, and community resources to manage specific mental health disorders for adults, older adults, and families. This course also includes the influences of family dynamics and societal norms on mental health progression and recovery. The following courses are prerequisites: All MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D347": {
          "courseName": "Advanced Psychiatric Mental Health Care of Children and Adolescents Across Care Settings ",
          "description": "Advanced Psychiatric Mental Health Care of Children and Adolescents across Care Settings prepares students to provide evidence-based mental healthcare for children, adolescents, and families. This course guides students through the application of age and developmentally appropriate advanced practice health assessment knowledge and diagnostic reasoning skills for children, adolescents, and families experiencing complex mental health issues. This course helps students develop treatment plans to manage specific mental health disorders through the use of psychotherapeutic treatment modalities, psychopharmacology, and community resources. This course also covers the influences of family dynamics and societal norms on mental health progression and recovery. The following are prerequisites for this course: all MSN Core courses, Advanced Pathophysiology for the Advanced Practice Nurse, Advanced Pharmacology for the Advanced Practice Nurse, and Advanced Health Assessment for the Advanced Practice Nurse. "
        },
        "D348": {
          "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship I ",
          "description": "Through precepted clinical experiences, the learner will develop the competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy, and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course.   "
        },
        "D349": {
          "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship II ",
          "description": "Through precepted clinical experiences, the learner will develop competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course. "
        },
        "D350": {
          "courseName": "Psychiatric Mental Health Nurse Practitioner Clinical Internship III ",
          "description": "Through precepted clinical experiences, the learner will develop competencies needed to provide comprehensive and holistic mental health care to individuals, families, and communities across the lifespan. In the precepted clinical setting, the learner will combine competencies developed in preparatory advanced practice coursework to deliver consumercentered mental health care. Therefore, the learner will conduct advanced mental health assessments and utilize the competencies of advanced pathophysiology, psychopharmacology, psychotherapy and health promotion for individuals and populations across the life span to build self-efficacy in individuals and groups, to determine correct diagnoses, and to establish mental health plans of care that include consumer and population preferences. All MSN Core Courses, NP Core courses, and PMHNP Specialty courses must be completed before taking this course. "
        },
        "D351": {
          "courseName": "Functions of Human Resource Management ",
          "description": "This course provides an introduction to the management of human resources, which is the function within an organization that focuses on recruitment, management, and direction for the people who work in the organization. Students will be introduced to topics such as strategic workforce planning and talent acquisition; compensation and benefits; training and development; employee and labor relations; and occupational health, safety, and security. "
        },
        "D352": {
          "courseName": "Employment and Labor Law ",
          "description": "Employment and Labor Law reviews the legal and regulatory framework surrounding employment, including recruitment, termination, and discrimination law. The course topics include employment-at-will, EEO, ADA, OSHA, and other laws affecting the workplace. Students will learn to analyze current trends and issues in employment law and apply this knowledge to manage risk in employment relationships effectively. Functions of Human Resources and Introduction to Human Resources are recommended prior to Employment and Labor Law. "
        },
        "D353": {
          "courseName": "Strategic Training and Development ",
          "description": "Strategic Training and Development focuses on the development of human capital (i.e., growing talent) by applying effective learning theories and practices for training and developing employees. The course will help develop essential skills for improving and empowering organizations through high-caliber training and development processes. "
        },
        "D354": {
          "courseName": "Talent Acquisition ",
          "description": "Talent Acquisition focuses on building a highly skilled workforce that meets organizational staffing needs by using effective strategies and tactics for recruiting, selecting, and onboarding employees. The learner will develop competency in critical skills related to talent acquisition, such as workforce planning, developing strategic recruiting plans, and ensuring effective selection strategies. Talent acquisition is a top skill for HR professionals, and successful talent acquisition practices lend to individual, team, and organizational success. "
        },
        "D355": {
          "courseName": "Total Rewards ",
          "description": "This course develops competence in the design and implementation of total rewards approaches in an organization. The total rewards perspective integrates tangible rewards (e.g., salary, bonuses) with employee benefits (e.g., health insurance, retirement plan) and intangible rewards (e.g., location, work environment). This perspective allows learners to use all forms of rewards fairly and effectively to enable job satisfaction and organizational performance. "
        },
        "D356": {
          "courseName": "HR Technology ",
          "description": "HR Technology focuses on the usage of technology for strategic human resource management. The learner will develop competency in critical skills related to analyzing the value and application of the different types of human resource information systems (HRIS), managing HRIS implementations, electronic human resource management, and future trends and application of HR technology. HR professionals must be familiar with HR technology in order to provide effective and efficient HR practices for their organization and recommendations to leadership to invest in technology. An understanding of HR technology is also an in-demand skill for HR professionals across all industries. "
        },
        "D357": {
          "courseName": "Diversity, Equity, and Inclusion ",
          "description": "Diversity, Equity, and Inclusion examines the importance and impact of diversity in organizations through an understanding of the theoretical, background, and legislative foundations of diversity. This course will explore specific groups and categories of diversity, as well as global diversity and career paths in diversity. "
        },
        "D358": {
          "courseName": "Global Human Resource Management ",
          "description": "Global Human Resource Management explores the rapidly changing field of international human resource management (HRM) and examines a global perspective in relation to staffing, personnel management, strategy, and communications in a cross-cultural context. This course will help learners examine critical skills such as application of international employment law, labor standards, and ethics, as well as international application of human resources (HR) best practices in areas such as employee relations, global talent management, and future trends of international HRM. These skills lend to the success of HR professionals working to support organizations that operate in or within an international context and cross-culturally, as well as expanding the skillsets for those HR professionals interested in seeking a career as a global HR professional. There are no prerequisites for this course. "
        },
        "D359": {
          "courseName": "Agile HR ",
          "description": "Agile HR explores the concepts of Agile operations and Agile project management from the human resource management perspective. Learners will focus on design thinking, building value for employees, change management, adaptability, and strategic prioritization as part of the Agile skills in this course. Adaptability and resilience, while delivering value in a constantly changing world, are all critical skills for successful HR professionals and leaders. "
        },
        "D360": {
          "courseName": "HRM Capstone ",
          "description": "The learner synthesizes skills from across the human resource management (HRM) industry to demonstrate the ability to participate in and contribute value to the HR field. "
        },
        "D361": {
          "courseName": "Business Simulation ",
          "description": "This course ties together all the skills and knowledge covered in the business courses and allows the student to prove their mastery of the competencies by applying them in a simulated business environment. This course will help take the student's knowledge and skills from the theoretical to applicable. "
        },
        "D362": {
          "courseName": "Corporate Finance ",
          "description": "Corporate Finance is about business structures that set the environment for the day-to-day operations of a business. This course teaches learners about the common forms of business structures, the factors that business owners consider when they choose which structure to use, and the roles of shareholders and stakeholders. This course also teaches that managing the financial function involves capitalizing the company and evaluating capital budget techniques, including those that use the time value of money. Through this course, learners will learn how to calculate the cost to finance a business using the weighted average cost of capital, how to value stocks and bonds, and how to determine the value of the firm. The prerequisites for this course are D089 Principles of Economics, D366 Financial Statement Analysis, D216 Business Law for Accountants "
        },
        "D363": {
          "courseName": "Personal Finance ",
          "description": "Personal Finance provides learners with an introduction to the discipline of finance from the perspective of the person, or family, rather than from the viewpoint of a business. In this course, learners will gain an understanding of financial literacy concepts, including personal budgeting and how to apply financial principles to achieve personal financial goals. Learners will identify various strategies to manage risks, to enhance postretirement income, and to accumulate and transfer wealth. Topics include record keeping, credit principles, cash flow, investment philosophy, monetary asset management, housing, and estate planning. This course provides learners with a general overview of personal finance and acts as a preview course for the finance major. There are no prerequisites for this course. "
        },
        "D364": {
          "courseName": "Financial Management I ",
          "description": "This course covers basic financial management principles primarily targeted to the operations part of a business. The learner gains an understanding about the basic finance organization in an enterprise in support of the company's primary goal to increase corporate value for shareholders in an ethical way. Tools a finance professional might use in managing the cash and current assets are discussed along with cash budgeting and financial strategic planning. The Dupont equation is reviewed as the basis for analyzing and improving the performance of the enterprise to improve value. The learner will acquire knowledge about how forecasting models and financial instruments are used to optimize the working capital investment portfolio. Prerequisite for Financial Management I is Corporate Finance. "
        },
        "D365": {
          "courseName": "Financial Management II ",
          "description": "This course covers capital budgeting and long-term funding strategies. The course will delve into more advanced financial management principles primarily targeted toward corporate investment and capital planning. This course also explores an enterprise’s capital structure and how equity and long-term debt are used to finance and sustain long-term fixed asset projects. Decision methods, such as net present value, internal rate of return, and payback period, are discussed as techniques a finance professional might use in identifying and structuring the optimal capital budget. The learner will gain an understanding about equity capital, will assess financial markets, and will examine the differences in shareholder classifications and bonds. The course will teach how the dividend policy is devised and discover how the organization uses its corporate investment strategy to increase not only shareholder value but also corporate value for the shareholder. Prerequisites for Financial Management II are D196, Principles of Financial and Managerial Accounting; D076, Finance Skills for Managers; D363, Personal Finance; D362, Corporate Finance; D364, Financial Management I; and D366, Financial Statement Analysis. "
        },
        "D366": {
          "courseName": "Financial Statement Analysis ",
          "description": "Financial Statement Analysis discusses the concepts and provides tools for financial analysts to evaluate the financial elements of the firm as well as external factors to ultimately arrive at a valuation. You will learn a process to analyze data and the concepts where you can determine the quality of that data. This process provides a structure where ratios and company results are not looked at individually but as a whole in determining the worth of an enterprise, leading to an analysis-based valuation of the firm. "
        },
        "D367": {
          "courseName": "Innovation in Finance ",
          "description": "Innovation in Finance provides students with an introduction to the technologies and product solutions that have disrupted the financial services industry. In this course, students will learn about the emerging financial technologies contributing to the evolution of lending, payments, wealth management, financial planning, and the insurance industry. This course will examine the role financial technology (FinTech) firms serve as financial disruptors and how these organizations are developed and supported, from start-up to scale. Throughout the course, students will identify the impact emerging technologies and FinTechs have on businesses, individuals, and society as a whole. Topics include emerging technology products and services, incubators, accelerator programs, FinTech ecosystems, and technologies that enable and facilitate disruption by emerging technologies. This course provides students with a general overview of financial innovation and serves as an integral component of the finance major. D076: Principles of Finance is a prerequisite for this course. "
        },
        "D368": {
          "courseName": "Enterprise Risk Management ",
          "description": "Enterprise Risk Management provides learners with an introduction to the discipline of risk management from the perspective of an organization rather than from the viewpoint of a person. In this course, learners will learn risk management concepts, including risk tolerance, risk appetite, and how to utilize governance and compliance resources to achieve an effective risk management strategy. Throughout this course, learners will determine various strategies to identify, assess, monitor, and control risks and other threats to an organization. Topics include approaches to risk mitigation, generally accepted frameworks and standards adopted to manage risk, current environmental, societal, and governance matters of risk interest to an organization, disaster recovery plans, and insurance products. "
        },
        "D369": {
          "courseName": "Finance Capstone ",
          "description": "This course is designed as a synthesis of the knowledge learners have acquired throughout the program. The course culminates in a performance assessment that requires learners to apply the competencies gained throughout the finance program. In this course, learners will draw upon the concepts and techniques introduced in the undergraduate finance program to perform a comprehensive financial analysis of an enterprise. In completing the course, learners will perform analyses with spreadsheet software to simulate a real-world experience of a finance career professional. "
        },
        "D370": {
          "courseName": "IT Leadership Foundations ",
          "description": "IT Leadership Foundations is an introductory course that provides students with an overview of organizational structures, communication, and leadership styles specific to information technology in organizations. It also introduces students to some of the power skills that help make successful IT professionals, including time management, problem solving, and emotional intelligence. Students in this course explore their own strengths and passions in relation to the field. There are no prerequisites for this course. "
        },
        "D372": {
          "courseName": "Introduction to Systems Thinking ",
          "description": "Introduction to Systems Thinking provides learners with the skills required to engage in a holistic systems-based approach to analyzing complex problems and solutions. This course introduces the foundational concepts and principles of systems thinking and provides opportunities to use a systems thinking approach to analyze and evaluate real-world case studies. The course will culminate with using systems thinking to develop a solution to an authentic complex problem. This course has no prerequisites, but general education math (C955 or C957) is preferred. Because the course is self-paced, learners may move through the material as quickly or as slowly as needed, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If learners have no prior knowledge of this material, they can expect to spend 30 to 40 hours on the course content. "
        },
        "D373": {
          "courseName": "Marketing in the Digital Era ",
          "description": "Marketing in the Digital Era examines the marketing skills needed to be an effective marketer in the 21st century. This course provides a company and consumer perspective to learn how consumer value is created while achieving organizational goals. Learners will gain knowledge in the core areas of marketing, including branding and products, consumers, communications, and technology. This course introduces learners to strategic marketing tools used to develop effective strategies for a relevant target market. Marketing in the Digital Era teaches learners about emerging topics, such as marketing automation, artificial intelligence, and data-driven communications, as well as skills needed to continue evolving as a successful marketing professional in the dynamic, ever-changing marketing environment. "
        },
        "D374": {
          "courseName": "Market Research ",
          "description": "Market Research provides learners with a knowledge of the role of marketing research in strategic decision-making. Marketers need to systematically collect and analyze data to develop insights and make decisions. Learners demonstrate proficiency in the fundamentals of market research by practicing statistical methods for analyzing and acting on market data. This course teaches sampling techniques, survey development, data analysis, ethical considerations, and hypothesis testing. Learners will apply their knowledge using a market research simulation. Emphasis is placed on the interpretation and use of results to demonstrate how to communicate information. In this course, learners will also develop awareness for continually monitoring emerging and competitive trends given the dynamic digital marketing landscape. "
        },
        "D375": {
          "courseName": "Marketing Communications and Storytelling ",
          "description": "Marketing Communications and Storytelling introduces learners to the principles of integrative marketing communications with an emphasis on storytelling and creativity. Storytelling skills enable marketers to build relationships by making connections with their intended audience. Digital storytelling integrates the use of technology to create a cohesive narrative across various platforms that evokes emotions and feelings about a brand. These efforts can lead to customer acquisition or conversion outcomes. In this course, the creative storytelling process begins with a clear SMART objective that drives strategy development, the creative direction, and the execution of an integrative marketing communication campaign. Campaign types, media channels, messages, timelines, and market development considerations are taught within the context of both digital and traditional application. Learners will also have the opportunity to complete the HubSpot Inbound Marketing certification to learn industry best practices and to enhance marketability within the marketing profession. "
        },
        "D376": {
          "courseName": "Product, Price, and Customer Experience ",
          "description": "Product, Price, & Customer Experience teaches core marketing concepts used to create integrative marketing strategies that meet the needs of an organization and its customers. This course teaches topics of new product development, product management, value proposition, and customer experience management. The voice of the customer is taught to ensure marketers create a customer-centric culture within their organization to meet the needs, wants, and preference of their target market. Customer experience management practices are key focal points of this course to ensure meaningful customer interactions across the entire customer journey. "
        },
        "D377": {
          "courseName": "Digital Marketing Foundations ",
          "description": "Digital Marketing Foundations provides learners with knowledge in the dynamic and evolving field of marketing that uses the internet and digital technologies to reach customers. This course provides foundational knowledge in digital marketing disciplines, including social media marketing, content marketing, marketing automation, search engine optimization (SEO), paid search (PPC), conversion rate optimization (CRO), mobile marketing, and web analytics. Learners explore how a digital marketing strategy is developed and executed, along with how digital marketing is integrated within an organization's overall strategy. This course provides learners with the opportunity to complete the HubSpot Marketing Software certification to learn marketing automation software that is used in industry to assist marketers in their digital marketing execution. Emphasis throughout the course is placed on industry best practices, and the course content aligns with the Online Marketing Certified Associate (OMCA) certification to prepare students for this third-party certification. "
        },
        "D378": {
          "courseName": "Digital Marketing Science ",
          "description": "Digital Marketing Science provides learners with knowledge in the dynamic and evolving field of marketing that uses the internet and digital technologies to reach customers. This course provides foundational knowledge in digital marketing disciplines, including social media marketing, email marketing, content marketing, marketing automation, search engine optimization (SEO), paid search (PPC), conversion rate optimization (CRO), mobile marketing, and web analytics. Learners explore how a digital marketing strategy is developed and executed, along with how digital marketing is integrated within an organization's overall strategy. This course provides learners with the opportunity to complete the HubSpot Marketing Software certification to learn marketing automation software that is used in industry to assist marketers in their digital marketing execution. Emphasis throughout the course is placed on industry best practices, and the course content aligns with the Online Marketing Certified Associate (OMCA) certification to prepare students for this third-party certification. "
        },
        "D379": {
          "courseName": "Social Media Marketing ",
          "description": "Social Media Marketing teaches learners about social media from a business perspective and how social media can be used to increase a company’s brand awareness, generate leads, and build meaningful relationships with customers. Throughout this course, learners will develop a social media marketing strategy across various social platforms to create and distribute valuable and relevant content to specific audiences. Social Media Marketing teaches learners influencer marketing, employee advocacy, social selling, and social media analytics. Learners will gain hands-on experience using a simulation to create targeted social media advertisements, perform demographic targeting, implement social media content promotion strategies, and schedule content. In this course, learners will have the opportunity to complete HubSpot's Social Media Marketing certification to learn industry best practices in social media marketing and to enhance marketability within the marketing profession. "
        },
        "D380": {
          "courseName": "Email Marketing ",
          "description": "Email Marketing examines the principles and techniques used to effectively manage email marketing campaigns. Email is used daily in both personal and business settings to communicate with others. In this course, learners will examine industry best practices of how to use email marketing as a digital strategy to achieve marketing goals. Learners will explore how to design an email marketing strategy, how to use email marketing for lead generation, how to design a lead nurture campaign, and how automation is used to manage email marketing distribution and campaigns. This course provides learners with an opportunity to explore how effective email messages are crafted and distributed. Industry best practices will be explored and learners have the opportunity to complete the HubSpot Email Marketing certification to enhance marketability within the marketing profession. "
        },
        "D381": {
          "courseName": "E-Commerce and Marketing Analytics ",
          "description": "E-Commerce and Marketing Analytics teaches learners how to sell online, how to reach customers online, and how to measure campaign and website performance. Businesses and consumers actively engage in buying and selling products over the internet. Learners gain skills through the practical application of building and optimizing a Shopify website designed to promote and sell products to customers. The course explores the latest technology platforms with an emphasis on Google applications for hands-on experience. Learners have the opportunity to complete the Google Search Ads certification to gain skills used in practice and to enhance marketability within the marketing profession. "
        },
        "D382": {
          "courseName": "Digital Marketing Analytics ",
          "description": "Digital Marketing Analytics teaches learners how to identify data sources, collect and analyze data, and manage marketing performance. Marketing requires an understanding of analytics and application of data to inform strategic decision-making. Through the use of a digital marketing analytics framework, measurement models, and various digital marketing technology tools, learners will analyze marketing performance across digital paid, owned, and earned channels. The latest marketing technology tools are explored to measure and optimize results using data-driven decisions. In this course, learners will have the opportunity to complete the Google Analytics certification to gain technical skills used in practice and to enhance marketability within the marketing profession. "
        },
        "D383": {
          "courseName": "Search Engine Optimization ",
          "description": "Search Engine Optimization, otherwise known as SEO, teaches on-page, off-page, and technical aspects of SEO for organically improving ranking and awareness. Digital marketing requires marketers to understand online consumer search behaviors; search engine optimization (SEO) is a key part of an organization’s digital marketing strategy. This includes processes and best practices used to increase their visibility in search engines. Learners will discover SEO strategies focusing on website structure, search engines, keyword research and mapping, and page-level optimization. Learners will gain practical experience using a simulation in which they will optimize ranking and visibility to consumers. In this course, learners will have the opportunity to complete HubSpot’s SEO certification to learn industry best practices and to enhance marketability within the marketing profession. "
        },
        "D384": {
          "courseName": "Marketing Experiential Capstone ",
          "description": "Marketing Experiential Capstone is the capstone course for the program that provides learners with realworld applications to prepare them for the marketing industry. In this course, learners will integrate and apply marketing skills gained throughout the program by working with an organization on a marketing project. Marketers must effectively manage many relationships throughout their career with clients and team members in an organization, an agency, or their own marketing firm. This course allows students to apply their technical knowledge while also developing competencies in effective communication, collaboration, conflict management, project management, and time management power skills. Learners will explore their professional goals and develop a personal branding strategy to enhance their marketability and to strategically plan for their marketing career. "
        },
        "D385": {
          "courseName": "Software Security and Testing ",
          "description": "This course prepares you to recognize security vulnerabilities in software, to plan interventions to address security vulnerabilities where they exist, and to develop and test these interventions. The course covers topics in Web security, permissions, and  identity security; debugging; log file analysis; API security; and encryption and cryptography concepts.   "
        },
        "D386": {
          "courseName": "Hardware and Operating Systems Essentials ",
          "description": "Hardware and Operating Systems prepares learners for concepts in software engineering by providing a foundation of understanding in computer architecture, the history of computing architectures, and operating systems. Additional topics covered include hardware and software stacks and how to choose appropriate hardware and software solutions to meet both functional and nonfunctional business requirements. "
        },
        "D387": {
          "courseName": "Advanced Java ",
          "description": "Advanced Java refines object-oriented programming expertise and skills. You will implement multithreaded, object-oriented code with the features of Java necessary to develop software that meets business requirements. Additionally, you will determine how to deploy software applications using cloud services. This course requires intermediate expertise in object-oriented programming and the Java language. "
        },
        "D388": {
          "courseName": "Fundamentals of Spreadsheets and Data Presentations ",
          "description": "Fundamentals of Spreadsheets and Data Presentations offers learners an overview of the use of spreadsheet functions and methods for presenting data within spreadsheets. Learners will have the opportunity to explore features and uses of MS Excel and apply the tools to situations they may encounter while studying in their program. They will also be introduced to real world uses and tools to collect, organize and present data. "
        },
        "D389": {
          "courseName": "Learning Strategies in Higher Education ",
          "description": "Learning Strategies in Higher Education provides students with a toolbox of skills that will support student academic growth as they advance in their academic journey. Students will be introduced to the Whalley Library; how to use it and best practices for research strategies. Students will learn how to be professional in written communication and how to correctly use current APA format. In this course, students also will learn about setting goals, time-management, study strategies, making and keeping appointments, professional decorum, and test-taking skills. Learning these skills, strategies, and methods will establish an academic foundation for students to be successful in higher education. There are no prerequisites for this course. "
        },
        "D390": {
          "courseName": "Introduction to Health and Human Services ",
          "description": "Introduction to Health and Human Services explores representative roles and responsibilities of health and human service professionals and key governmental entities involved in Health and Human Services delivery. The course also examines the importance of understanding clients’ illnesses and disabilities, building trust with clients, and engagement models that promote client outcomes. There are no prerequisites for this course. "
        },
        "D391": {
          "courseName": "Healthcare Ecosystems ",
          "description": "Healthcare Ecosystems examines how the aims and elements of the healthcare ecosystem can affect client and patient outcomes. The course explores the main aims of healthcare access, affordability, and quality and how regulators, providers, producers, and funders (such as payors or purchasers) support those aims. The course also examines insurance regulations and reimbursement procedures that affect healthcare access and affordability and decision-making processes that support affordable, quality care for clients and communities. There are no prerequisites for this course. "
        },
        "D392": {
          "courseName": "Interdisciplinary Team Dynamics ",
          "description": "Interdisciplinary Team Dynamics explores interpersonal communication strategies, collaborative team interactions methods, and problem-solving techniques to promote effective communication and improve quality client outcomes in a team environment. There are no prerequisites for this course. "
        },
        "D393": {
          "courseName": "History of Healthcare in America ",
          "description": "History of Healthcare in America will examine individuals such as Henrietta Lacks, Ryan White, Clara Barton, and Katie Beckett, who influenced healthcare in the United States, from its inception to the present day. This course examines how specific individuals and their contributions influenced healthcare delivery and the continued evolution of healthcare, teaching from a system or a value-based care perspective. The course also focuses on the way healthcare interacted with culture, politics, and society throughout U.S. history and evaluates current challenges we face in the U.S. healthcare system today. There are no prerequisites for this course. "
        },
        "D394": {
          "courseName": "Care for Individuals and Families ",
          "description": "Care for Individuals and Families focuses on the holistic care of individuals, families, and populations with multifaceted healthcare needs. This course improves critical thinking and interdisciplinary communication skills to provide information to individuals or groups in a variety of settings. The focus of the course is on managing the transition of an individual, family, or group through a variety of healthcare settings, which can include acute care hospitals, extended stay facilities, ambulatory care clinics, home care, outreach, or wellness. This course helps students develop effective professional communication skills and appropriate behaviors to ensure an individual, family, or group is successful in meeting its healthcare goals. There are no prerequisites for this course. "
        },
        "D395": {
          "courseName": "Cultural Awareness ",
          "description": "Cultural Awareness is a course for the healthcare professional providing learners with the understanding of what it means to have personal, explicit and implicit cultural biases and how they can affect client outcomes in the health and human services industry. The course also will explore strategies for responding to personal biases and for promoting cultural awareness in health and human services. Through critical readings, videos, and activities, the learner will gain knowledge in this essential subject. There are no prerequisites for this course. "
        },
        "D396": {
          "courseName": "Evidence-Based Practice for Health and Human Services ",
          "description": "Evidence-Based Practice for Health and Human Services prepares the learner to apply evidence-based practice (EBP) to inform healthcare recommendations in out-patient, organizational, and other public health settings. Learners will be introduced to an EBP framework to guide them through the steps of EBP using real world scenarios. There are no prerequisites for this course.    "
        },
        "D397": {
          "courseName": "Health Equity and Social Determinants of Health ",
          "description": "Health Equity and Social Determinants of Health examines the social determinants of health (SDOH) as underlying causes of health inequity in populations and communities and their effect on health outcomes. This course will help students understand the evidence-based strategies that address the negative impact of inequities caused by the SDOH and analyze approaches to promote health equity. There are no prerequisites for this course. "
        },
        "D398": {
          "courseName": "Introduction to Pharmacology ",
          "description": "Introduction to Pharmacology will introduce learners to medication and supplement regulations and safety protocols. It provides an overview of the use, benefits, effects, and contraindications of commonly used drugs to treat conditions of the cardiovascular, respiratory, endocrine, nervous, and renal body systems. It also explores the types of anti-infective, antineoplastic, psychotropic drugs, and dietary supplements and their effects on the body. "
        },
        "D399": {
          "courseName": "Introduction to Gerontology ",
          "description": "Introduction to Gerontology will introduce learners to health issues that are typically associated with the older adult population so they can become familiar with health challenges this population may face. The learners will gain an understanding of the effects that policy and legislation have on the older adult population. Emphasis is placed on the importance of maintaining the dignity of older adults by focusing on cultural and communication needs, and by collaborating on care with older adults, families, and caregivers. There are no prerequisites for this course. "
        },
        "D400": {
          "courseName": "End-of-Life Care ",
          "description": "End-of-Life Care focuses on the Connected Care model as it applies to the final stage of life. This course will explore ethnic and cultural factors that affect an individual’s response to death and dying. This course will cover planning and implementing ideal interventions to help individuals, families, and groups cope and agree on a common care goal. This course will also discuss empathy and compassion in healthcare. There are no prerequisites for this course. "
        },
        "D401": {
          "courseName": "Introduction to Epidemiology ",
          "description": "Introduction to Epidemiology provides an overview of the determinants of communicable, viral, and chronic diseases. Students also will study various other conditions and the impact to public health. Using problem-based inquiry, students will analyze realworld public health problems by examining the distribution and patterns of data, selecting the methods to gather evidence, interpreting the information, and analyzing the trends to support decision making. There are no prerequisites to this course, but students are highly encouraged to adhere to the standard path, whose content is scaffolded to enhance the learning experience of this course. "
        },
        "D402": {
          "courseName": "Community and Public Health ",
          "description": "Community and Public Health provides learners with an understanding of the benefits community health offers individuals and families. The course also will identify barriers that will impact health and healthcare access, leading to improved community health. There are no prerequisites for this course. "
        },
        "D403": {
          "courseName": "Understanding Substance Abuse and Addiction ",
          "description": "Understanding Substance Abuse and Addiction provides an overview of the causes, signs and symptoms of substance abuse and addiction, and the impact on individuals, groups, and the community. Learners will evaluate educational prevention programs for a variety of target audiences and settings and evaluate evidence-based assessments and interventions for successful outcomes. There are no prerequisites for this course. "
        },
        "D404": {
          "courseName": "Healthcare Values and Ethics ",
          "description": "Healthcare Values and Ethics requires students to synthesize an interdisciplinary approach to decisionmaking as it applies to health and human services. This course explores the contemporary issues facing health professionals, which include ethics, regulations and compliance, and handling protected healthcare information. In this course, learners will develop their ability to make ethical decisions in collaborative care environments and working within a team. There are no prerequisites for this course. "
        },
        "D405": {
          "courseName": "Financial Resource Management and Healthcare Reimbursement ",
          "description": "Financial Resource Management and Healthcare Reimbursement examines financial practices and reimbursement types within the healthcare industry. This course covers the analysis of regulations required for health reimbursements. This course also covers the evaluation of effective revenue cycle management, focusing on the organization’s financial stability. There are no prerequisites for this course. "
        },
        "D406": {
          "courseName": "Health Literacy for the Client and Family ",
          "description": "Health Literacy for the Client and Family helps students recognize the importance of health literacy in overcoming healthcare barriers and creating patient-focused changes through family and patient empowerment. This course demonstrates how education, research, and technology all integrate and serve as a foundation for students as they create effective resources to improve health literacy for patients and families. This course helps students become advocates for their patients and their patients’ families. There are no prerequisites for this course. "
        },
        "D407": {
          "courseName": "Models of Care and Healthcare Trends ",
          "description": "Models of Care and Healthcare Trends is a course for health professionals in a variety of roles in the health and human services industry, which examines the unique characteristics of healthcare models in the United States and emerging trends created by social and political drivers. The course explores the evolution of healthcare models from fragmented systems to cohesive, quality-centric, and client-focused systems. The course also focuses on innovative trends, such as access to care, telemedicine, and subsequent shifts in the continuum of care as it relates to patient or client outcomes. There are no prerequisites for this course. "
        },
        "D408": {
          "courseName": "Community Relations and Leadership ",
          "description": "Community Relations and Leadership focuses on analyzing community health and human services’ needs to create change. As emerging leaders, students will learn to engage in collaborative approaches with various stakeholders to achieve positive outcomes. This course helps students develop their abilities to interpret community health needs assessments, make decisions, and bring stakeholders together to advance access to health and human services. This course has no prerequisites. "
        },
        "D409": {
          "courseName": "Health and Human Services Professional Field Experience ",
          "description": "The Health and Human Services Professional Field Experience course provides students with real-world experiences as a health services professional via the virtual world of simulation. The course allows students to conduct their field experience in a variety of different contexts they will find themselves, depending on their professional career choices in the health services’ industry. All program coursework leads to this course.   This course is eligible for an In Progress grade. Please see the Grading Scale Policy for more information.   "
        },
        "D410": {
          "courseName": "Health & Human Services Professional Capstone ",
          "description": "Health Services Professional Capstone will provide learners the opportunity to demonstrate their ability to communicate in a professional manner that supports high quality, safe client services. Learners will also engage in career and professional development within the health and human services industry. All program coursework leads to this course. "
        },
        "D411": {
          "courseName": "Scripting and Automation ",
          "description": "Scripting and Automation is the foundation for automating tasks in operating systems. Students will learn how to create PowerShell scripts that take tedious and repetitious tasks and turn them into programs that will save time. Students will learn PowerShell, an automation and configuration management tool based on a command-line shell and .NET Framework. "
        },
        "D412": {
          "courseName": "Network Analytics and Troubleshooting ",
          "description": "Network Analytics and Troubleshooting teaches students to use network monitoring and analytics tools and practices that are common in the workplace in order to troubleshoot and fix complex computer networks. Students will follow a customer service model in identifying, classifying, investigating, and repairing network outages or problems. This course is designed as a hands-on experience where students will implement these techniques in a virtual space in order to produce a secure and functional deployed network. "
        },
        "D413": {
          "courseName": "Telecomm and Wireless Communications ",
          "description": "Telecomm and Wireless Communications explores the science, technologies, and standards that enable wired and wireless data to be transmitted across different media. Topics include data encoding and decoding, and analog and digital transmissions via wired, fiber, wireless, cellular, and satellite technologies. "
        },
        "D414": {
          "courseName": "Cyber Operations Fundamentals ",
          "description": "In Cyber Operations Fundamentals, students will learn security concepts, security monitoring, host-based analysis, network intrusion analysis, and security policies and procedures using Cisco practices and technologies. This course prepares students for the Understanding Cisco Cybersecurity Operations Fundamentals (CBROPS) 200-201 exam.  "
        },
        "D415": {
          "courseName": "Software Defined Networking ",
          "description": "Software-Defined Networking (SDN) represents one of the fastest growing areas of network engineering. This course instructs learners on the SDN paradigm, which encompasses network automation, intent-based networking, and centralized network control. This course also teaches learners to view networking from a centralized and automated perspective rather than the traditional device-by-device model that is the legacy practice in many networks. "
        },
        "D416": {
          "courseName": "DevNet Fundamentals ",
          "description": "The DevNet Fundamentals course teaches students how to automate and deploy network solutions in the Cisco Environment. This course includes APIs, Scripting, Python programming, and software version control. This course prepares students for the Cisco DevNet Associate (DevNet) 200-901 exam. "
        },
        "D417": {
          "courseName": "Network Automation and Deployment ",
          "description": "Network Automation and Deployment leverages previous experience in networking, scripting, and programming with the SDN paradigm. Students will create programs and scripts that automate network configuration across large networks. This course is designed as a hands-on experience where students will implement these techniques in a virtual space in order to produce a secure and functional deployed network. "
        },
        "D418": {
          "courseName": "BSNES Capstone Project ",
          "description": "The BSNES Capstone Project consists of learners submitting a network design proposal, a virtual network implementation, and a post-implementation report describing their experience developing and implementing the capstone project. The capstone project and scope must be presented and approved by the capstone instructor prior to implementation in the virtual environment.  "
        },
        "D419": {
          "courseName": "Implementing and Administering Networking Solutions ",
          "description": "Implementing and Administering Networking Solutions expands on basic networking concepts and covers advanced network engineering skills including: Switch and router configuration, trouble shooting and maintenance on wired and wireless networks, Security, network automation and introduces Software Defined Networking. This course prepares students for the Cisco Certified Network Associate (CCNA) certification exam CCNA-200-301. "
        },
        "D420": {
          "courseName": "Discrete Math: Logic ",
          "description": "Discrete Math-Logic is designed to help students develop competence in the use of logic and proofs and Boolean Algebra and Boolean functions. Applied Probability and Statistics and Applied Algebra are prerequisites for this course. "
        },
        "D421": {
          "courseName": "Discrete Math: Functions and Relations ",
          "description": "Discrete Math: Functions and Relations is designed to help students develop competence in the use of abstract discrete structures fundamental to systems networking. In particular, this course will introduce students to set theory, finite sequences, series, and relations. Discrete Math: Logic, Applied Probability and Statistics, and Applied Algebra are prerequisites for this course. "
        },
        "D422": {
          "courseName": "Discrete Math: Algorithms and Cryptography ",
          "description": "Discrete Math: Algorithms and Cryptography addresses discrete computational methods, including searching and sorting algorithms, big-O estimates, and number theory and cryptography. Discrete Math Functions and Relations is a prerequisite for this course. "
        },
        "D423": {
          "courseName": "Spreadsheets ",
          "description": "Introduction to Spreadsheets helps learners become proficient in using spreadsheets to analyze business problems. In this course, learners will demonstrate competency in spreadsheet development and analysis for business applications (e.g., using essential spreadsheet functions, formulas, tables, charts, etc.). There are no prerequisites for this course. "
        },
        "D424": {
          "courseName": "Software Engineering Capstone ",
          "description": "The capstone challenges students to integrate skills and knowledge from all program domains into one project. "
        },
        "D425": {
          "courseName": "Introduction to Chemistry ",
          "description": "In Introduction to Chemistry, learners will discover the impact of chemistry on everyday life. They’ll learn about the structure of the atom, study periodic trends, analyze the structure of molecules and their properties, and describe the importance of common functional groups within the periodic table. They’ll identify balanced chemical equations, describe types of chemical reactions and predict their products, and examine intermolecular forces and describe their impact on the properties of substances. Finally, they’ll study the properties of acids, bases, and buffer systems, and properties unique to liquids and gases. "
        },
        "D426": {
          "courseName": "Data Management ",
          "description": "Foundations - Data Management Foundations offers an introduction in creating conceptual, logical and physical data models.  Students gain skills in creating databases and tables in SQL-enabled database management systems, as well as skills in normalizing  databases. No prerequisites are required for this course "
        },
        "D427": {
          "courseName": "Data Management ",
          "description": "Applications - Data Management - Applications covers conceptual data modeling and introduces MySQL. Students will learn how to create simple to complex SELECT queries, including subqueries and joins, and how to use SQL to update and delete data. Topics covered in this course include exposure to MySQL; creating and modifying databases, tables, views, foreign keys and primary keys (FKs and PKs), and indexes; populating tables; and developing simple Select-From-Where (SFW) queries to complex 3+ table join queries. The following course is a prerequisite: Data Management - Foundations. "
        },
        "D428": {
          "courseName": "Design Thinking for Business ",
          "description": "Design Thinking for Business examines the design thinking methodology for solving complex problems. This course introduces students to design thinking as a human-centered approach to problem-solving and innovation that draws upon empathy and creativity to develop solutions to complex problems. Students will explore the principles and stages of design thinking and analyze the use of design thinking in developing solutions through real-world scenarios. "
        },
        "D430": {
          "courseName": "Fundamentals of Information Security ",
          "description": "This course lays the foundation for understanding terminology, principles, processes, and best practices of information security at local and global levels. It further provides an overview of basic security vulnerabilities and countermeasures for protecting information assets through planning and administrative controls within an organization. This course has no prerequisites. "
        },
        "D431": {
          "courseName": "Digital Forensics in Cybersecurity ",
          "description": "Digital Forensics in Cyber Security examines the relationships between incident categories, evidence handling, and incident management. This course teaches students to identify consequences associated with cyber threats and security laws using a variety of tools to recognize and recover from unauthorized, malicious activities and how to seek evidence that reveals who, what, when, where, and how threats compromise information. "
        },
        "D432": {
          "courseName": "HR Compliance and Employee Relations ",
          "description": "HR Compliance and Employee Relations provides students with an in-depth understanding of the relevant laws, regulations, and ethical issues related to human resource (HR) compliance and risk management. It also explores how to resolve and improve employee relations issues to maintain a positive organizational culture in a diverse workplace. Topics include business laws and ethical considerations, employment and labor laws and regulations, and employee relations strategies to build and maintain a positive, healthy, and respectful work environment. "
        },
        "D433": {
          "courseName": "Talent Acquisition and Development ",
          "description": "Talent Acquisition and Development provides an in-depth look at the strategies used to attract, retain, and develop qualified talent in an organization. Students discover how to hire the right talent to meet the needs of the organization, how to orient and onboard new employees, and how to ensure employee excellence through learning and development and performance management strategies. "
        },
        "D434": {
          "courseName": "Future Focused Total Rewards ",
          "description": "Future Focused Total Rewards examines discretionary and legally required approaches to compensation and benefits practices that compose an organization’s total rewards system. Students explore how to develop and communicate the components of a competitive total rewards strategy to prospective and existing employees while adhering to employment laws and aligning to an organization’s strategic goals and culture. "
        },
        "D435": {
          "courseName": "HR Technology and People Analytics ",
          "description": "HR Technology and People Analytics introduces students to the types of human resource information systems (HRISs), applications, and platforms used to capture and manage employee data and the analytics used to make strategic decisions based on that data. Students will discover how to plan for the implementation of new human resource (HR) technology, present the plan to stakeholders to gain buy-in and support for the change, and train employees in the new systems. Students will also gain an understanding of how to pull and use data and people analytics for effective storytelling, decision-making, and leadership influence. "
        },
        "D436": {
          "courseName": "Inclusive Workplace Culture Capstone ",
          "description": "Inclusive Workplace Culture Capstone provides students with the opportunity to work through the SHRM Inclusive Workplace Culture specialty credential course content, studying and analyzing how human resource (HR) professionals can integrate an inclusive approach to all HR functions such as talent acquisition, training and development, total rewards, and more. Students will complete a capstone project that synthesizes an inclusive approach to strategic HR practices for an organization to create an environment of true belonging for all employees, while simultaneously being prepared to complete the SHRM Inclusive Workplace Culture exam independently to earn a specialty credential and badge from SHRM. "
        },
        "D439": {
          "courseName": "Foundations of Nursing  ",
          "description": "Foundations of Nursing introduces students to the nursing process, scope of practice, clinical judgment model and fundamental concepts of holistic nursing practice that will serve the needs of diverse adult patients across the lifespan. The course will focus on medical terminology, legal/ethical issues, basic care and comfort, oxygenation, safety & infection control, health and wellness, fluid & electrolytes, death and dying, therapeutic communication, patient education & advocacy.     Co-requisites: C957, D202, D236, and D441.    Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms. "
        },
        "D440": {
          "courseName": "Health and Wellness Through Nutritional Science ",
          "description": "The health and wellness through nutritional science course prepares nursing students to learn the basic principles of nutrition, nutrition throughout the life cycle, nutrition related to weight management and physical health, and nutrition related to patient conditions. Students will learn how nutrition influences a patient's overall health status across the life span. "
        },
        "D441": {
          "courseName": "Medical Dosage Calculations and Pharmacology  ",
          "description": "Medical Dosage Calculations and Pharmacology introduces an in-depth nursing approach to medication administration concepts, legal & ethical principles, pharmacological principles, variety of drug classifications, complementary & alternative therapies needed to care for diverse patients across the lifespan. "
        },
        "D442": {
          "courseName": "Basic Nursing Skills ",
          "description": "Basic Nursing Skills will introduce foundational principles of nursing process and the clinical judgement model, health assessment techniques, and communication skills needed to care for diverse adult patients across the lifespan. Skills will focus on the concepts of vital signs, medication administration, infection control, nutrition, elimination, mobility, oxygenation, and skin integrity. Students are required to be successful on lab assessments to progress to Adult Health1 clinical. Co-requisites: D443 and D444 "
        },
        "D443": {
          "courseName": "Health Assessment ",
          "description": "The Health Assessment course focuses on concepts and skills necessary to collect a comprehensive health history and perform a head-to-toe and focused assessments on diverse patients across the lifespan. The emphasis will be to differentiate between normal and abnormal findings of various body systems such as the following: integumentary, head & neck, eyes & ears, respiratory, cardiovascular, gastrointestinal, renal, musculoskeletal, nervous, and reproductive systems. Corequisites are D442 and D444. "
        },
        "D444": {
          "courseName": "Adult Health I ",
          "description": "Adult Health I prepares students to provide safe, equitable, high quality medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of common conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on medical surgical nursing care related to head & neck, skin, inflammation, infection, fluid & electrolytes, respiratory, gastrointestinal, hepatic, genitourinary, and reproductive systems. The nursing process and the clinical judgement model will be used as the foundation to navigate the management of care for patients. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisite: D443.   "
        },
        "D445": {
          "courseName": "Intermediate Nursing Skills ",
          "description": "Intermediate Nursing Skills will build on basic nursing skill concepts to develop intermediate medical surgical nursing practice, including peripheral intravenous access, blood administration, airway management, perioperative and postoperative care, and wound care management. The course focuses on nursing care of both adult and pediatric populations, including a focus on the care of women and the maternal care setting. Students will use simulation to apply the clinical judgment model to various diverse populations in various care settings. Students are required to be successful on course performance assessments to progress to Adult Health II clinical. Co-requisites: D446 & D447 "
        },
        "D446": {
          "courseName": "Adult Health II   ",
          "description": "Adult Health II prepares students to provide safe, equitable, high quality medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of both acute and chronic conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on medical surgical nursing care related to perioperative nursing, neurological, hematological, renal, cardiovascular, endocrine, and musculoskeletal systems. The nursing process and clinical judgement model will be used for clinical decision-making and fostering health promotion and maintenance. A variety of populations and settings are used in the experiential learning components of this course.      Co-requisite: D447.   Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms and D445. "
        },
        "D447": {
          "courseName": "Women's and Children's Nursing ",
          "description": "Women's and Children's Nursing prepares students to provide safe, equitable, high-quality nursing care, pharmacological care, and emotional support for diverse women and pediatric populations. This course focuses on antepartum, intrapartum, postpartum, neonatal clinical nursing, and women's health. This course builds on growth and development of children, nursing care for children and adolescents with acute and chronic alterations of the respiratory, cardiovascular, hematologic, endocrine, reproductive, gastrointestinal, renal, neurologic, musculoskeletal, and integumentary systems. This course explores how social determinants of health impact health risk and outcomes in women and pediatric populations. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisites: D445 and D446.   Prerequisites: All prelicensure nursing curriculum courses from previous terms. "
        },
        "D449": {
          "courseName": "Psychiatric and Mental Health Nursing ",
          "description": "Psychiatric and Mental Health Nursing prepares students to provide safe, equitable, high-quality care using modern concepts of psychiatric and mental health nursing. The student will utilize therapeutic communication to a diverse population of patients including those with maladaptive behaviors through the utilization of the nursing process by applying the principles of psychiatric and mental healthcare and the clinical judgement model. This course explores the nurse-client relationship, pharmacological management, cognitive conditions, bipolar and thought conditions, personality disorders, substance abuse, eating disorders, and self-harm. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisites: D450.   Prerequisite Courses: All prelicensure nursing curriculum courses from previous terms and D445.   "
        },
        "D450": {
          "courseName": "Community Health and Population-Focused Nursing ",
          "description": "Community health and population focused nursing concentrates on the theory and concepts of community, public and global health nursing that impact diverse communities. Students learn the role of the community health nurse, learn to assess the community’s healthcare needs, available resources, epidemiology, substance abuse, disaster management, and how social determinants of health impact community and public health risk and outcomes. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisite: D449.   "
        },
        "D453": {
          "courseName": "Advanced Nursing Skills ",
          "description": "Advanced Nursing Skills will build on intermediate skills and focus on advanced skills related to critical care nursing practice, including closed chest drainage systems, electrocardiograms (EKGs), palliative care, ventilators, disaster management and transition to practice. Clinical judgement and problem solving are emphasized in the assessment of critically ill patients and prioritizing patients’ needs and nursing interventions. Students are required to be successful on course performance assessments to progress to Adult Health III clinical. Corequisites: D454 & D455 "
        },
        "D454": {
          "courseName": "Adult Health III ",
          "description": "Adult Health III prepares students to provide safe, equitable, high quality complex medical surgical nursing care for diverse adult populations across the lifespan in various health care settings. This includes health promotion and management of both acute and chronic conditions. This course explores how social determinants of health impact health risk and outcome. This course will focus on caring for patients with potentially life-threatening alterations of the respiratory, cardiovascular, endocrine, and neurologic, renal, hepatic systems, end of life care (palliative), shock and transplants. Clinical judgment and problem solving are emphasized in the assessment of critically ill patients and prioritizing patient ‘s needs and nursing interventions. A variety of populations and settings are used in the experiential learning components of this course.    Co-requisites: D455 "
        },
        "D455": {
          "courseName": "Professional Nursing Role Transition ",
          "description": "The Professional Nursing Role Transition course builds on the previous knowledge gained in all other nursing courses. The emphasis of this course is placed on the personal and professional strategies needed to make the transition from student to graduate nurse by highlighting the role and skills of bedside nurse leaders. The course will review content related to leadership and management, foundational nursing, advanced clinical, medical surgical, pediatric, women’s, and mental health concepts. The students will complete a professional portfolio that showcases their accomplishments, knowledge, and skills throughout the program. A variety of populations and settings are used in the experiential learning components of this course.   Co-requisite: D454    "
        },
        "D458": {
          "courseName": "Introduction to Systems Thinking for Health Professionals ",
          "description": "Introduction to Systems Thinking for Health Professionals provides learners with the skills required to engage in a holistic systems-based approach to analyzing complex problems and solutions. This course introduces the foundational concepts and principles of systems thinking and provides opportunities to use a systems thinking approach to analyze and evaluate realworld case studies. The course will culminate with using systems thinking to develop a solution to an authentic complex problem. This course has no prerequisites, but general education math (C955 or C957) is preferred. Because the course is self-paced, learners may move through the material as quickly or as slowly as needed, with the goal of demonstrating proficiency in the five competencies covered in the final assessment. If learners have no prior knowledge of this material, they can expect to spend 30 to 40 hours on the course content.   "
        },
        "D464": {
          "courseName": "Managing Operations ",
          "description": "Managing Operations examines management systems and processes to improve operating efficiency. In this course, students will be introduced to operations management concepts and will learn how analysis of these systems and processes can improve operating efficiency across the organization. Students will explore ethical and sustainable operations management strategies and will analyze operating processes for continuous improvement. "
        },
        "D465": {
          "courseName": "Data Applications ",
          "description": "Data Applications examines the principles and techniques used to effectively analyze data to answer questions and provides foundational knowledge of R and RStudio environments. This course teaches students how to aggregate; how to format and adjust data using spreadsheets and SQL; how to use formulas and functions to perform calculations in SQL; how to organize, transform, clean, and analyze data in R; and how to create visualizations in R Markdown. Upon completion of this course, students will earn the Whalley Business Analysis Professional certificate and may also choose to earn the Google Data Analytics Professional certificate. "
        },
        "D466": {
          "courseName": "Analyzing and Visualizing Data ",
          "description": "Analyzing and Visualizing Data examines the principles and techniques used to effectively analyze data to answer questions and share data through the art of visualization. This course explores how to use formulas and functions to perform calculations, how to design and create visualizations and dashboards in Tableau, and how to build an effective data presentation that considers limitations associated with the data and best practices for audience considerations. This course provides students with an opportunity to demonstrate an understanding of what is involved in the conversion and formatting of data and apply the use of functions and syntax to create SQL queries for combining data from multiple database tables. The course D467: Exploring Data, which also includes Google certificate materials, is a prerequisite. "
        },
        "D467": {
          "courseName": "Exploring Data ",
          "description": "Exploring Data builds proficiency with data, including the organization, preparation, transformation, cleaning, and verification of data. This course examines how to apply critical thinking, spreadsheet, and structured query language techniques to data management and decision-making. Students may simultaneously work toward the Google Data Analytics Professional Certificate and the Whalley Business Analysis Certificate. The course D468: Discovering Data, which also includes Google certificate materials, is a prerequisite. "
        },
        "D468": {
          "courseName": "Discovering Data ",
          "description": "Discovering Data introduces analytical concepts, processes, and tools used in the field of business analytics. This course explores the importance of asking effective questions to collect the right data. Students will examine using analytical thinking to organize, analyze, and share data to drive decision-making. This course provides students the opportunity to work toward the Google Data Analytics Professional Certificate and the Whalley Business Analysis Certificate. Introduction to Spreadsheets is a prerequisite for this course. "
        },
        "D469": {
          "courseName": "Quality, Continuous Improvement, and Lean Six Sigma ",
          "description": "Quality, Continuous Improvement, and Lean Six Sigma examines how organizations can measure and improve the quality of products, goods, and services. Students consider different dimensions and characteristics of quality and are introduced to a variety of strategies and tools that are used to manage quality and measure performance. This course explores how to apply continuous improvement processes by analyzing the Lean Six Sigma DMAIC (define, measure, analyze, improve, and control) method. C955: Applied Probability and Statistics is a prerequisite for this course. "
        },
        "D470": {
          "courseName": "Transportation, Logistics, and Distribution ",
          "description": "Transportation, Logistics, and Distribution examines logistics and the planning and management of transportation and distribution. This course introduces requirements and risks, facilities and inventory, strategy and supply chain synchronicity, efficiencies and costs, and laws and regulations related to transportation, distribution, and logistics. Students will explore warehousing and warehouse management, inventory and logistics management, distribution, and supply chain management from the lens of transportation, logistics, and distribution. This course is aligned with the Certified in Logistics, Transportation, and Distribution (CLTD) certification from the Association for Supply Chain Management. "
        },
        "D471": {
          "courseName": "Global Supply Chain Management ",
          "description": "Global Supply Chain Management introduces a broad range of supply chain management concepts. Students consider supply chains and supply chain management strategies, including planning and design, risk management, and global and sustainable supply chain networks and management. Students learn about managing important relationships and their interdependencies. Students also investigate how supply chain costs affect consumers, quality and continuous improvement, and the role of the sales and operations planning (S&OP) process.    Portions of this course are aligned with aspects of ASCM APICS certification programs, namely the CSCP and the CPIM. "
        },
        "D472": {
          "courseName": "21st Century Operations and Supply Chain ",
          "description": "21st Century Operations and Supply Chain explores modern issues in supply chain management. Building on the supply chain landscape introduced in prior courses, this course addresses more complex supply chain and operations issues. The course examines how disruptions transform operations and the supply chain and how to use qualitative and quantitative data to evaluate solutions. "
        },
        "D473": {
          "courseName": "Solutions Design and Visualization Capstone ",
          "description": "Solution Design and Visualization Capstone guides learners to synthesize and apply the skills learned throughout their business analytics, operations, and supply chain education. This course gives learners the opportunity to solve challenges in procurement, sales and operations planning (S&OP) processes, distribution, logistics and transportation faced by a fictional company. In the course capstone project, learners play the role of an operations and supply chain manager or consultant, redesigning the existing supply chain to implement lean processes and using Six Sigma methodology to improve efficiency and allow the company to bring new products or services to market faster. Learners in this course address a real operations and supply chain problem and design solutions, which they communicate in a report and a presentation. At the end of the course, learners will have an authentic experience they can add to their portfolio and show employers. Working through this capstone helps learners to understand how their knowledge interacts with real situations and how roles fit within the industry. The D472: 21st Century Operations and Supply Chain course is a prerequisite. "
        },
        "D479": {
          "courseName": "User Experience Design ",
          "description": "User Experience Design explores multiple tools and techniques used in user experience design. Students are presented with an in-depth view of activities involved in the design of user experience and have the opportunity to create several deliverables including persona profiles, information architectures, and prototypes of different levels of fidelity. In addition, the course also covers usability testing and the evaluation of quantitative and qualitative data derived from these and other experiments. "
        },
        "D480": {
          "courseName": "Software Design and Quality Assurance ",
          "description": "Software Design and Quality Assurance applies a QA focus to every phase of the software development life cycle. This course investigates best practices for quality analysis, quality planning, and testing strategies as they pertain to the everyday practice of software development. Students will come to understand how their work fits into the bigger picture: how QA, testing, and codewriting practices interact within specific process models; the potential impact of new code on existing code or on other applications; the importance of usability and the influence users have on the ultimate success of an application. Students will explore test plans, test cases, unit tests, integration tests, regression tests, usability tests, and test and review tools. "
        },
        "D481": {
          "courseName": "Security Foundations ",
          "description": "Security Foundations lays the foundation for understanding terminology, principles, processes, and information security best practices at local and global levels. This course further provides an overview of networking components, network security vulnerabilities, and countermeasures for protecting information assets through planning and administrative controls within an organization. "
        },
        "D482": {
          "courseName": "Secure Network Design ",
          "description": "Secure Network Design provides the foundational knowledge and skills to design secure physical and logical network architectures for wired and wireless networks. Course topics include the characteristics of a secure network, techniques to securely configure network devices, network segmentation strategies, root cause analysis, and mitigation approaches based on industry best practices. The course also offers hands-on experience in network vulnerability analysis and network configuration. "
        },
        "D483": {
          "courseName": "Security Operations ",
          "description": "Security Operations provides learners with the fundamental skills to handle and respond to computer security incidents in an information system. The course addresses various underlying principles and techniques for detecting and responding to current and emerging computer security threats. The course also helps learners explore strategies to leverage intelligence and threat detection techniques, analyze and interpret data, identify and address vulnerabilities, and suggest preventative measures. Methods are introduced to effectively respond to and recover from cybersecurity incidents, evaluate risk assessment methodologies, and apply incident handling laws and policies. "
        },
        "D484": {
          "courseName": "Penetration Testing ",
          "description": "Penetration Testing introduces learners to the skills necessary to perform penetration testing and vulnerability management within an organization. The course covers widely used penetration testing techniques and tools that focus on planning and scoping, information gathering, vulnerability identification, and attacks and exploits. In addition, it offers hands-on experience and a focus on penetration testing engagement plans. "
        },
        "D485": {
          "courseName": "Cloud Security ",
          "description": "Cloud Security prepares learners to design solutions for cloud-based platforms and operations that maintain data availability while protecting the confidentiality and integrity of information. Course topics include cloud service models, deployment methods, identity and access management (IAM) strategies, auditing and monitoring strategies, assessing and mitigating common cloud security threats, and managing compliance and regulation requirements. The course also offers hands-on experience deploying and assessing IAM controls in a cloud environment. "
        },
        "D486": {
          "courseName": "Governance, Risk, and Compliance ",
          "description": "Governance, Risk, and Compliance provides learners with advanced skills and knowledge to authorize and maintain information systems utilizing various risk management frameworks. The course focuses on the strategic and long-term alignment of an organization's information security program to regulatory requirements and organizational policies. Course topics include compliance and regulatory requirements, data classification and prioritization, security and privacy controls, compliance audits and remediation, and risk management plans. "
        },
        "D487": {
          "courseName": "Secure Software Design ",
          "description": "Secure Software Design focuses on the variety of elements needed to address and implement secure software acquisition and development throughout the software development life cycle (SDLC). The course addresses people, technology, tools, and processes to design and develop consistently secure applications from start to finish. Additionally, it underscores the importance and value of the Defense in Depth principle across the entire SDLC. The course also introduces techniques to adapt common security activities to modern software development practices such as Agile and DevSecOps. "
        },
        "D488": {
          "courseName": "Cybersecurity Architecture and Engineering ",
          "description": "Cybersecurity Architecture and Engineering provides learners with advanced skills and knowledge to design secure enterprise architecture solutions. The course focuses on assessing cybersecurity readiness and implementing enterprisewide solutions to protect data and comply with an organization's policies and frameworks. Course topics include integrating software applications, applying enterprise data security controls, evaluating cloud and virtualization solutions, analyzing threats and vulnerabilities, and responding to incidents. "
        },
        "D489": {
          "courseName": "Cybersecurity Management ",
          "description": "Cybersecurity Management prepares learners to develop organizational information security programs and policies that follow recognized standards, comply with all governing laws and regulations, and meet the needs of the company culture and management organization. The course covers how to perform risk management institutionally, how to manage compliance to information security requirements, and how to delegate compliance, risk, and security functions to specific roles within the organization. It also helps learners apply strategic decision-making as companies adapt to new technologies, processes, and people practices related to processing, managing, and protecting information resources. "
        },
        "D490": {
          "courseName": "Cybersecurity Graduate Capstone ",
          "description": "The Master of Science in Cybersecurity and Information Assurance (MSCSIA) Capstone project allows learners to demonstrate their capability to establish a durable cybersecurity and information assurance program. The capstone project challenges learners to integrate skills and knowledge from all program domains into one project that addresses a significant real-world cybersecurity problem. "
        },
        "D491": {
          "courseName": "Introduction to Analytics ",
          "description": "Analytics is the creative use of data and statistical modeling to tell a compelling story that not only drives strategic action, but also results in business value. Introduction to Analytics examines data analytics as a discipline and the various roles and functions within the field. You will expand your knowledge about what analytics is and develop a basic understanding of statistics, analysis, problem solving, and programming concepts. "
        },
        "D492": {
          "courseName": "Data Analytics ",
          "description": "Applications - Data Analytics Applications covers advanced concepts across the various phases of the data product lifecycle. You will learn to choose and apply appropriate techniques for data management and data manipulation, statistical analysis, visualization, and data governance concepts to satisfy business needs. "
        },
        "D493": {
          "courseName": "Scripting and Programming ",
          "description": "Applications - Scripting and Programming - Applications for undergraduates explores the various aspects of the Python programming language by examining its syntax, the development environment, and tools and techniques to solve some real-world problems. Introduction to Programming in Python is a prerequisite for this course.  "
        },
        "D494": {
          "courseName": "Data and Information Governance ",
          "description": "Data and Information Governance provides learners with the knowledge that establishing rules of engagement, policies, procedures, and data stewardship is essential to exercising organizational control over—and extracting maximum value from— its data assets. Good data governance helps an organization lower costs, create efficiencies, and achieve its strategic goals and objectives. Data governance provides a framework for properly managing information across the entire data lifecycle and establishes strategies in support of disaster recovery and continuity of operations. This course will prepare IT professionals to assist their organization in the definition and implementation of best practices related to the planning and implementation of managed systems that meet business, technical, security, auditing, disaster recovery, and business continuity requirements. "
        },
        "D495": {
          "courseName": "Big Data Foundations ",
          "description": "Big Data Foundations provides an in-depth introduction to big data concepts, terminology, and applications. You will learn the risks and challenges of working with extremely large data sets. The course introduces tools and techniques for working with big data. The course covers selection criteria for relational and non-relational data architectures and cloud-native data storage concepts. It also provides a historical perspective on the evolution of big data storage approaches. Data warehousing, data lakes, and data lakehouses are introduced, and design principles for each are explained. Learners design aspects of big data architecture and big data processing to address given business requirements. "
        },
        "D496": {
          "courseName": "Introduction to Data Science ",
          "description": "Introduction to Data Science introduces the data analysis process and common statistical techniques necessary for the analysis of data. Students will ask questions that can be solved with a given data set, set up experiments, use statistics and data wrangling to test hypotheses, find ways to speed up their data analysis code, make their data set easier to access, and communicate their findings. "
        },
        "D497": {
          "courseName": "Data Wrangling ",
          "description": "Data Wrangling elaborates on concepts covered in Introduction to Data Science, helping to develop skills crucial to the field of data science and analysis. It explores how to wrangle data from diverse sources and shape it to enable data-driven applications—a common activity in many data scientists' routine. Topics covered include gathering and extracting data from widely-used data formats, assessing the quality of data, and exploring best practices for data cleaning. "
        },
        "D498": {
          "courseName": "Data Analysis with R ",
          "description": "Data Analysis with R focuses on exploratory data analysis (EDA) utilizing R. EDA is an approach for summarizing and visualizing the important characteristics of a data set. In this course you will develop skills in R programming to acquire and load data sets, create appropriate statistical summaries of data, and create data visualizations to help uncover and communicate insights about data using R.  "
        },
        "D499": {
          "courseName": "Machine Learning ",
          "description": "Machine Learning presents the end-to-end process of investigating data through a machine learning lens. Topics covered include: supervised and unsupervised learning algorithms, features that best represent data, commonly-used machine learning algorithms, and methods for evaluating the performance of machine learning algorithms.   "
        },
        "D500": {
          "courseName": "Data Visualization ",
          "description": "Data Visualization covers the application of design principles, human perception, color theory, and effective storytelling in the context of data visualization. It addresses presenting data to others and advancing technology with visualization tools enabling data scientists to share their findings and support organizational decision-making processes. Additionally, this course focuses on how to visually encode and present data to an audience.  "
        },
        "D501": {
          "courseName": "Machine Learning DevOps ",
          "description": "Machine Learning DevOps focuses on the software engineering fundamentals needed to successfully streamline the deployment of data and machine learning models in a production-level environment. Students will build the DevOps skills required to automate the various aspects and stages of machine learning model building and monitoring over time. "
        },
        "D502": {
          "courseName": "Data Analytics Capstone ",
          "description": "The Data Analytics Undergraduate Capstone challenges students to demonstrate competencies supporting all BSDA program outcomes. Students will identify an organizational need, plan and develop a data analytics product to serve that need, and document the process in a project proposal and data project report.  "
        },
        "D509": {
          "courseName": "Innovative Solutions in Healthcare Leadership ",
          "description": "Innovative Solutions in Health Administration provides an opportunity to explore healthcare innovations through comparison research, the application of disruptive leadership concepts, and advanced technology applications. Students will apply strategic innovation concepts to improve critical patient dissatisfiers in a healthcare setting. This course has no prerequisites. "
        },
        "D510": {
          "courseName": "Collaborative Leadership ",
          "description": "Collaborative Leadership provides an opportunity to apply collaborative leadership skills to better serve diverse communities. Students will develop a process innovation with community leaders in a diverse population emphasizing a cultural competence. This course has no prerequisites.   "
        },
        "D511": {
          "courseName": "Healthcare Models and Systems ",
          "description": "Healthcare Models and Systems provides an opportunity to analyze the evolution of healthcare models and systems. Students will apply administration strategies to manage organizational changes and community affiliations. This course has no prerequisites. "
        },
        "D512": {
          "courseName": "Quality Improvement in Healthcare ",
          "description": "Quality Improvement in Healthcare provides an opportunity to apply quality improvement principles and strategies in a high-volume Level 1 trauma center. Students will apply disruptive leadership strategies to implement quality-improvement procedures in a fast-paced healthcare environment. This course has no prerequisites. "
        },
        "D513": {
          "courseName": "Healthcare Financial Management ",
          "description": "Healthcare Financial Management provides an opportunity to apply strategic change management principles through the application of fiscal management and data analysis in a healthcare environment. The student will examine strategies to increase value, sustainability, and productivity in a patient-centric environment. This course has no prerequisites. "
        },
        "D514": {
          "courseName": "Analytical Methods of Healthcare Leaders ",
          "description": "Analytical Methods of Healthcare Leaders provides an opportunity to explore the use of predictive analysis and forecasting techniques to develop evidence-based decision making. Students will apply quality research and analytical analysis to inform decisions in a health management environment. This course has no prerequisites. "
        },
        "D515": {
          "courseName": "Enterprise Risk Management ",
          "description": "Enterprise Risk Management provides an opportunity to examine risk exposure and response, and risk mitigation within an integrated care delivery model. Students will apply practices to identify risks and develop sustainable corrective action plans. This course has no prerequisites. "
        },
        "D516": {
          "courseName": "Healthcare Information Technology ",
          "description": "Healthcare Information Technology provides an opportunity to examine the use of technology in data analysis and applications to improve outcomes in a patient-centered care environment. Students will apply strategic analysis to improve technology function and interoperability within a community healthcare cooperative. This course has no prerequisites. "
        },
        "D517": {
          "courseName": "Population Healthcare Coordination ",
          "description": "Population Healthcare Coordination provides an opportunity to examine population healthcare strategies and community collaboration to impact at-risk demographic groups. Students will apply strategic change management and data analysis to develop health initiatives for a large-scale population. This course has no prerequisites. "
        },
        "D518": {
          "courseName": "Challenges in Community Healthcare ",
          "description": "Challenges in Community Healthcare provides an opportunity to explore organizational leadership and administration as well as problem-solving methods to collaborate with community leaders in a high-stakes healthcare environment. Students will apply collaborative leadership skills and evidence-based practices as they develop community relationships to resolve critical issues in community health management. This course has no prerequisites.   "
        },
        "D519": {
          "courseName": "Integrated Healthcare Leadership and Administration ",
          "description": "Integrated Healthcare Leadership and Administration provides an opportunity to examine integrated healthcare delivery systems and person-centered care models for innovative solutions to critical challenges. The student will apply principles of collaborative leadership, disruptive change, and catalyst evaluation to develop a holistic integrated healthcare system. This course has no prerequisites. "
        },
        "D520": {
          "courseName": "Healthcare Leadership and Administration Capstone ",
          "description": "The capstone is a student-designed project intended to illustrate your ability to effect change in the industry and demonstrate competence in all five program outcomes: transformational leader, value innovator, tactical manager, analyst,  and integrated systems expert. Students are required to collaborate with leaders in the healthcare industry to identify opportunities for improvement in healthcare, propose a solution, and perform a business analysis to evaluate its feasibility. In addition, the capstone encourages work in the healthcare industry that will be showcased in the student’s collection of work and help solidify professional relationships in the industry. This course has no prerequisites. "
        },
        "D523": {
          "courseName": "Student Teaching I in Elementary Education ",
          "description": "Student Teaching I in Elementary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D524": {
          "courseName": "Student Teaching I in Elementary Education ",
          "description": "Student Teaching I in Elementary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D525": {
          "courseName": "Student Teaching II in Elementary Education ",
          "description": "Student Teaching II in Elementary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D526": {
          "courseName": "Student Teaching II in Elementary Education ",
          "description": "Student Teaching II in Elementary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D529": {
          "courseName": "Student Teaching I in Special Education ",
          "description": "Student Teaching I in Special Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D530": {
          "courseName": "Student Teaching I in Special Education ",
          "description": "Student Teaching I in Special Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D531": {
          "courseName": "Student Teaching II in Special Education ",
          "description": "Student Teaching II in Special Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D532": {
          "courseName": "Student Teaching II in Special Education ",
          "description": "Student Teaching II in Special Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D533": {
          "courseName": "Student Teaching I in Secondary Education ",
          "description": "Student Teaching I in Secondary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D534": {
          "courseName": "Student Teaching I in Secondary Education ",
          "description": "Student Teaching I in Secondary Education is the first of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching I is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a mid-term evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. "
        },
        "D535": {
          "courseName": "Student Teaching II in Secondary Education ",
          "description": "Student Teaching II in Secondary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "D536": {
          "courseName": "Student Teaching II in Secondary Education ",
          "description": "Student Teaching II in Secondary Education is the second of two culminating experiences and is a required course for all initial licensure candidates. Student Teaching II is a supervised classroom-based activity in an authentic setting, which enables the candidate to demonstrate professional dispositions and ethics while collaborating with a practicing teacher and applying instructional strategies using co-teaching models. The candidate assumes increasing responsibilities while developing the skills and confidence necessary to be an effective teacher. Each candidate receives formative feedback through observations and a final evaluation on the relevance of required activities, how culturally engaging the activities are, and how successful each candidate is in teaching each student. Each candidate is also evaluated on the ability to think about, analyze, and modify classroom actions as needed, and on a willingness to take risks and experiment with materials and methods that may be new or that may challenge your cultural knowledge. The final evaluation in Student Teaching II is the determining factor in applying for licensure as a professional educator. "
        },
        "DPT1": {
          "courseName": "Physics: Electricity and Magnetism ",
          "description": "Physics: Electricity and Magnetism addresses principles related to the physics of electricity and magnetism. Students will study electric and magnetic forces and then apply that knowledge to the study of circuits with resistors and electromagnetic induction and waves, focusing on such topics as electric charge and electric field, electric currents and resistance, magnetism, electromagnetic induction and Faraday's law, and Maxwell's equation and electromagnetic waves. "
        },
        "DPT2": {
          "courseName": "Physics: Electricity and Magnetism ",
          "description": "Physics: Electricity and Magnetism addresses principles related to the physics of electricity and magnetism. Students will study electric and magnetic forces and then apply that knowledge to the study of circuits with resistors and electromagnetic induction and waves. This course will focus on such topics as electric charge and electric field, electric currents and resistance, magnetism, electromagnetic induction and Faraday's law, and Maxwell's equation and electromagnetic waves. "
        },
        "DWP2": {
          "courseName": "Application of Elementary Social Studies Methods ",
          "description": "Application of Elementary Social Studies Methods helps students learn how to implement effective social studies instruction in the elementary classroom. Topics include social studies themes, promotion of cultural diversity, integrated social studies across the curriculum, social studies learning environments, assessment of social studies understanding, differentiated instruction for social studies, technology for social studies instruction, and standards-based social studies instruction. This course helps students apply, analyze, and reflect on effective elementary social studies instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "DZP2": {
          "courseName": "Application of Elementary Visual and Performing Arts Methods ",
          "description": "Application of Elementary Visual and Performing Arts Methods helps students learn how to implement effective visual and performing arts instruction in the elementary classroom. Topics include integrating arts across the curriculum, music education, visual arts, dance and movement, dramatic arts, differentiated instruction for visual and performing arts, and the promotion of cultural diversity through visual and performing arts instruction. This course helps students apply, analyze, and reflect on effective elementary visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "EBP2": {
          "courseName": "Application of Elementary Physical Education and Health Methods ",
          "description": "Applications of Elementary Physical Education and Health Methods helps students learn how to implement effective physical and health education instruction in the elementary classroom. Topics include healthy lifestyles, student safety, student nutrition, physical education, differentiated instruction for physical and health education, physical education across the curriculum, and public policy in health and physical education. This course helps students apply, analyze, and reflect on effective elementary visual and performing arts instruction. This course is designed to be taken after successful completion of Introduction to Curriculum, Instruction, and Assessment OR Instructional Planning and Presentation in Elementary Education. "
        },
        "ELO1": {
          "courseName": "Subject Specific Pedagogy: ELL ",
          "description": "Subject Specific Pedagogy: ELL integrates aspects of pedagogy, assessment, and professionalism in English Language Learning (ELL). A student develops and assesses aspects of language curriculum development including second language instruction, methods of second language assessment, and legal policy issues. "
        },
        "FEA1": {
          "courseName": "Field Experience for ELL ",
          "description": "Field Experience for ELL is the field experience component of the English Language Learning program. In this experience, students are required to complete a minimum of 15 hours of video observations for both elementary and secondary levels. Additionally, a supervised teaching experience that is face-to-face with English language learners (ELL) according to the minimum time requirements of the student's state is required. The purpose of this course is to assess the ability of students, including their engagement in field experience activities, ability to reflect on and then plan standards-based instruction in ELL, and their ability to locate and effectively use resources for teaching ELL to meet the needs of their individual learners. "
        },
        "LPA1": {
          "courseName": "Language Production, Theory and Acquisition ",
          "description": "Language Production, Theory and Acquisition focuses on describing and understanding language and the development of language. It includes the study of acquisition theory, error correction strategies, and applied phonology. "
        },
        "LZT2": {
          "courseName": "Power, Influence and Leadership ",
          "description": "Power, Influence, and Leadership focuses on the development of the critical leadership and soft skills necessary for success in information technology leadership and management. The course focuses specifically on skills such as cultivating effective leadership communication, building personal influence, enhancing emotional intelligence (soft skills), generating ideas and encouraging idea generation in others, conflict resolution, and positioning oneself as an influential change agent within different organizational cultures. There are no prerequisites for this course. "
        },
        "MBT2": {
          "courseName": "Technological Globalization ",
          "description": "Technological Globalization explores information and communication technologies used to meet business needs in global markets. IT executives must analyze their organization’s technological needs, develop internationally-capable strategic plans, and mitigate the operational challenges of each of the countries in which the organization does business. This course provides students with the practical knowledge and understanding of how to plan, evaluate, and successfully integrate effective and efficient technical communication solutions in the global business market. This course has no prerequisites. "
        },
        "MFT2": {
          "courseName": "Mathematics (K-6) Portfolio Oral Defense ",
          "description": "Mathematics (K-6) Portfolio Oral Defense: Mathematics (K-6) Portfolio Defense focuses on a formal presentation. The student will present an overview of their teacher work sample (TWS) portfolio discussing the challenges they faced and how they determined whether their goals were accomplished. They will explain the process they went through to develop the TWS portfolio and reflect on the methodologies and outcomes of the strategies discussed in the TWS portfolio. Additionally, they will discuss the strengths and weaknesses of those strategies and how they can apply what they learned from the TWS portfolio in their professional work environment. "
        },
        "MGT2": {
          "courseName": "IT Project Management ",
          "description": "IT Project Management provides an overview of the Project Management Institute’s project management methodology. Topics cover various process groups and knowledge areas and application of knowledge in case studies for planning a project that has not started yet and monitoring/controlling a project that is already underway. "
        },
        "MMT2": {
          "courseName": "IT Strategic Solutions ",
          "description": "IT Strategic Solutions guides students in identifying strategic opportunities and emerging technologies through research and deciding on a system to support a growing company. Topics will include technology strategy; gap analysis; researching new technology; strengths, opportunities, weaknesses, and threats; ethics; risk mitigation; data security, communication plans; and globalization. "
        },
        "NMA1": {
          "courseName": "Professional Role of the ELL Teacher ",
          "description": "The Professional Role of the ELL Teacher focuses on issues of professionalism for the English Language Learning teacher and leader. This includes program development, ethics, engagement in professional organizations, serving as a resource, and ELL advocacy. "
        },
        "NNA1": {
          "courseName": "Planning, Implementing, Managing Instruction ",
          "description": "Planning, Implementing, Managing Instruction focuses on a variety of philosophies and grade levels of English Language Learner (ELL) instruction. It includes the study of ELL listening and speaking, ELL reading and writing, specially designed academic instruction in English (SDAIE), and specific issues for various grade level instruction. "
        },
        "OOT2": {
          "courseName": "Mathematics History and Technology ",
          "description": "In Math History and Teaching, students will learn about a variety of technological tools for doing mathematics and develop a broad understanding of the historical development of mathematics. Mathematics is a very human subject that comes from the macro-level sweep of cultural and societal change as well as the micro-level actions of individuals with personal, professional, and philosophical motivations. This course will focus on the historical development of mathematics, including contributions of significant figures and diverse cultures. Students will learn to evaluate and apply technological tools and historical information to create an enriching student-centered mathematical learning environment. "
        },
        "OPT2": {
          "courseName": "Mathematics Learning and Teaching ",
          "description": "Mathematics Learning and Teaching will help students develop the knowledge and skills necessary to become a prospective and practicing educator. This course will help students use a variety of instructional strategies to effectively facilitate the learning of mathematics. It focuses on selecting appropriate resources, using multiple strategies, and instructional planning, with methods based on research and problem solving. A deep understanding of the knowledge, skills, and disposition of mathematics pedagogy is necessary to become an effective secondary mathematics educator. There are no prerequisites for this course. "
        },
        "PFIT": {
          "courseName": "Business ",
          "description": "IT Management Portfolio Requirement - Business - IT Management Portfolio Requirement is designed to help the learner complete the culminating Undergraduate Business Portfolio assessment; it focuses on developing a business portfolio containing a strengths essay, a career report, a reflection essay, a resume, and exhibits that support one’s strengths in the work place. "
        },
        "QDT1": {
          "courseName": "Abstract Algebra ",
          "description": "Abstract Algebra is the axiomatic and rigorous study of the underlying structure of algebra and arithmetic. It covers the knowledge and skills necessary to understand, apply, and prove theorems about numbers, groups, rings, and fields. Topics include the well-ordering principle, equivalence classes, the division algorithm, Euclid's algorithm, prime factorization, greatest common divisor, least common multiple, congruence, the Chinese remainder theorem, modular arithmetic, rings, integral domains, fields, groups, roots of unity, and homomorphisms. Candidates should have completed Linear Algebra before engaging in this course. "
        },
        "QDT2": {
          "courseName": "Abstract Algebra ",
          "description": "Abstract Algebra is the axiomatic and rigorous study of the underlying structure of algebra and arithmetic. It covers the knowledge and skills necessary to understand, apply, and prove theorems about numbers, groups, rings, and fields. Topics include the well-ordering principle, equivalence classes, the division algorithm, Euclid's algorithm, prime factorization, greatest common divisor, least common multiple, congruence, the Chinese remainder theorem, modular arithmetic, rings, integral domains, fields, groups, roots of unity, and homomorphisms. Linear Algebra is a prerequisite for this course. "
        },
        "QFT1": {
          "courseName": "Business ",
          "description": "IT Management Capstone Project - The capstone requires students to demonstrate the integration and synthesis of competencies in all domains required for the degree in Information Technology Management. The student produces a business plan for a start-up company that is selected and approved by the student and mentor. "
        },
        "QGT1": {
          "courseName": "Business Management Capstone Written Project ",
          "description": "For the Business Management Capstone Written Project students will integrate and synthesize competencies from across their degree program to demonstrate their ability to participate in and contribute value to their chosen professional field. A comprehensive business plan is developed for a company that plans to sell a product or service in a local market, national market, or on the Internet. The business plan includes a market analysis, financial statements and analysis, and specific strategic actions relevant to the chosen company. "
        },
        "QHT1": {
          "courseName": "Business Management Tasks ",
          "description": "Business Management Tasks addresses important concepts needed to effectively manage a business. Topics include understanding the cost-quality relationship, using various types of graphical charts in operations management, managing innovation, and developing strategies for working with individuals and groups. "
        },
        "QJT2": {
          "courseName": "Calculus I ",
          "description": "Calculus I is the study of rates of change in relation to the slope of a curve and covers the knowledge and skills necessary to use differential calculus of one variable and appropriate technology to solve basic problems. Topics include graphing functions and finding their domains and ranges; limits, continuity, differentiability, visual, analytical, and conceptual approaches to the definition of the derivative; the power, chain, and sum rules applied to polynomial and exponential functions, position and velocity; and L'Hopital's Rule. Candidates should have completed a course in Pre-Calculus before engaging in this course. "
        },
        "QTT2": {
          "courseName": "Finite Mathematics ",
          "description": "Finite Mathematics covers the knowledge and skills necessary to apply discrete mathematics and properties of number systems to model and solve real-life problems. Topics include sets and operations; prime and composite numbers; GCD and LCM; order of operations; ordering numbers; mathematical systems including modular arithmetic, arithmetic and geometric sequences, ratio and proportion, subsets of real numbers, logic and truth tables, graphs, and trees and networks. There are no prerequisites for this course. "
        },
        "RKT1": {
          "courseName": "Linear Algebra ",
          "description": "Linear Algebra is the study of the algebra of curve-free functions extended into three- or higher-dimensional space. It covers the knowledge and skills necessary to apply vectors, matrices, matrix theorems, and linear transformations and to use technology to model and solve real-life problems. It also covers properties of and proofs about vector spaces. Topics include linear equations and their matrix-vector representation Ax=b; row reduction; linear transformations and their matrix representations (shear, dilation, rotation, reflection); matrix operations matrix inverses and invertible matrix characterizations; computing determinants; relating determinants to area and volume; and axiomatic and intuitive definitions of vector spaces and subspaces; and proving theorems about them. College Geometry and Calculus II are prerequisites for this course. "
        },
        "RKT2": {
          "courseName": "Linear Algebra ",
          "description": "Linear Algebra is the study of the algebra of curve-free functions extended into three-or-higher-dimensional space. It covers the knowledge and skills necessary to apply vectors, matrices, matrix theorems, and linear transformations and to use appropriate technology to model and solve real-life problems. It also covers properties of and proofs about vector spaces. Topics include linear equations and their matrix-vector representation Ax=b, row reduction, linear transformations and their matrix representations (shear, dilation, rotation, reflection), matrix operations, matrix inverses and invertible matrix characterizations, computing determinants, relating determinants to area and volume, and axiomatic and intuitive definitions of vector spaces and subspaces and how to prove theorems about them. College Geometry and Calculus II are prerequisites for this course. "
        },
        "RNT1": {
          "courseName": "General Physics ",
          "description": "This course provides a broad overview of the principles of mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism. and invites students to apply them by solving problems, performing labs, and reflecting on concepts and ideas. "
        },
        "RNT2": {
          "courseName": "General Physics ",
          "description": "This course provides a broad overview of the principles of mechanics, thermodynamics, wave motion, modern physics, and electricity and magnetism and invites students to apply them by solving problems, performing labs, and reflecting on concepts and ideas. "
        },
        "RXT2": {
          "courseName": "Precalculus and Calculus ",
          "description": "Precalculus and Calculus provides instruction in precalculus and calculus and applies them to examples found in both mathematics and science. Topics in precalculus include principles of trigonometry, mathematical modeling, and logarithmic, exponential, polynomial, and rational functions. Topics in calculus include conceptual knowledge of limit, continuity, differentiability, and integration. "
        },
        "SLO1": {
          "courseName": "Theories of Second Language Acquisition and Grammar ",
          "description": "Theories of Second Language Learning Acquisition and Grammar covers content material in applied linguistics, including morphology, syntax, semantics, and grammar. Students will explore the role of dialect in the classroom, the connections between language and culture, and the theories of first and second language acquisition. "
        },
        "TOC2": {
          "courseName": "Probability and Statistics I ",
          "description": "Probability and Statistics I covers the knowledge and skills necessary to apply basic probability, descriptive statistics, and statistical reasoning and to use appropriate technology to model and solve real-life problems. It provides an introduction to the science of collecting, processing, analyzing, and interpreting data, including representations, constructions, and interpretation of graphical displays (e.g., box plots, histograms, cumulative frequency plots, scatter plots). Topics include creating and interpreting numerical summaries and visual displays of data; regression lines and correlation; evaluating sampling methods and their effect on possible conclusions; designing observational studies, controlled experiments, and surveys; and determining probabilities using simulations, diagrams, and probability rules. College Algebra is a prerequisite to this course. "
        },
        "TQC1": {
          "courseName": "Probability and Statistics II ",
          "description": "Probability and Statistics II covers the knowledge and skills necessary to apply random variables, sampling distributions, estimation, and hypothesis testing, and to use appropriate technology to model and solve real-life problems. It provides tools for the science of analyzing and interpreting data and includes statistical variability and its sources and the role of randomness in statistical inference. Topics include discrete and continuous random variables; expected values; the Central Limit Theorem; the identification of unusual samples; population parameters; point estimates; confidence intervals; influences on accuracy and precision; hypothesis testing; and statistical tests (z mean, z proportion, one sample t, paired t, independent t, ANOVA, chi-squared, and significance of correlation). Calculus II and Probability and Statistics I are prerequisites for this course. "
        },
        "TQC2": {
          "courseName": "Probability and Statistics II ",
          "description": "Probability and Statistics II covers the knowledge and skills necessary to apply random variables, sampling distributions, estimation, and hypothesis testing and to use appropriate technology to model and solve real-life problems. It provides tools for the science of analyzing and interpreting data and includes statistical variability and its sources and the role of randomness in statistical inference. Topics include discrete and continuous random variables, expected values, the central limit theorem, the identification of unusual samples, population parameters, point estimates, confidence intervals, influences on accuracy and precision, hypothesis testing and statistical tests (z mean, z proportion, one sample t, paired t, independent t, ANOVA, chi-squared, and significance of correlation). Calculus II and Probability and Stats I are prerequisites to this course. "
        },
        "UQT1": {
          "courseName": "Organic Chemistry ",
          "description": "This course focuses on the study of compounds that contain carbon, much of which is learning how to organize and group these compounds based on common bonds found within them in order to predict their structure, behavior, and reactivity. "
        },
        "VZT1": {
          "courseName": "Marketing Applications ",
          "description": "Marketing Applications allows students to apply their knowledge of core marketing principles by creating a comprehensive marketing plan. The plan will apply knowledge of the marketing planning process, market analysis, and the marketing mix (product, place, promotion, and price).  "
        }
      }
    // #endregion Courses

  
