import React from 'react'
import FAQArea from './FAQArea'
import { Link } from 'react-router-dom'

function EventFAQ() {
    const faqData = [
        {
          title: `What will the event include?`,
          content: `You will be welcomed and signed in at our campus Reception, there will be presentations to find out more about what we offer/courses available/student experience. You will meet our student ambassadors, ask any questions that you may have, make an application, and receive a campus tour.\n\n`,
          // center: true,
        },
        {
          title: `How long do events typically run?`,
          content: `Events usually take place Saturday mornings with 2 one-hour session and Wednesday afternoon events with 3 one-hour session available to book. We suggest you allow for your visit to be no longer than 1 hour.\n\n`,
          // center: true,
        },
        {
          title: `I have already applied to Whalley, can I attend this event?`,
          content: `Yes. This event is a great opportunity for applicants to visit our campus and meet our staff.\n\n`,
          // center: true,
        },
        {
          title: `I haven’t yet applied to Whalley, can I attend this event?`,
          content: `Yes. Please use this event as a chance to get a feel for studying with us, find out what courses we offer and take a look around.\n\n`,
          // center: true,
        },
        {
          title: `I can’t attend on this date/time, can I still visit?`,
          content: (<>
            {`Yes. If you wish to enquire about booking an individual visit please contact us via the `}
            <Link to={"/contact"} className='inlineLink'>contact page</Link>
            {`\n\n`}
          </>),
          // center: true,
        },
        {
          title: `Do I have to bring my printed ticket to the event?`,
          content: `You don’t have to bring a printed copy of your ticket to the event, we will only ask for your full name when you arrive.\n\n`,
          // center: true,
        },
        {
          title: `Is there parking available?`,
          content: `Parking is available in the many carparks around the University Centre. If you are visiting us for an open day, the carparks are free after 6pm during the weekdays and all day at the weekends. The maximum cost during a weekday is £3.50 for the full day.\n\n`,
          // center: true,
        },
      ]

  return (
    <>
        <FAQArea 
            // title={"General Event Facts and Questions"}
            faqData={faqData} 
        ></FAQArea>
    </>
  )
}

export default EventFAQ