import React, { useEffect, useRef, useState } from 'react'
import CollapasableRow from '../CollapasableRow'

function FAQLine({title, content, center}) {
  
    const [open, setOpen] = useState()
    const [showContent, setShowContent] = useState()
    const [openContentHeight, setOpenContentHeight] = useState()
    const showTimeout = useRef()
    const heightTimeout = useRef()
    useEffect(()=>{
        openFunction()
    },[open])
    function openFunction(){
        clearTimeouts()
        if(open){
            setShowContent(true)
            heightTimeout.current = setTimeout(() => {
                setOpenContentHeight(true)
            }, 50);
        }else{
            setOpenContentHeight(false)
            showTimeout.current = setTimeout(() => {
                setShowContent(false)
            }, 1000);
        }

    }
    function clearTimeouts(){
        if(showTimeout.current)
            clearTimeout(showTimeout.current)
        if(heightTimeout.current)
            clearTimeout(heightTimeout.current)
    }

    return (
        <CollapasableRow title={title}>
            <pre style={{textAlign: (center ? "center":"")}}>
                {content}
            </pre>
        </CollapasableRow>
        // <div className={'faqLine '} onClick={()=>setOpen(!open)}>
        //     <div className='faqLineTitle'>{title + (open?" - ":" + ")}</div>
        //     {showContent &&
        //         <div className={'faqLineContent '+(openContentHeight ? "faqLineContentOpen":"")}>
        //             <pre style={{textAlign: (center ? "center":"")}}>
        //                 {content}
        //             </pre>
        //         </div>
        //     }
        // </div>
    )
}

export default FAQLine