import React from 'react'
import FAQArea from './FAQArea'

function HealthcareFAQ() {
    const faqData = [
        {
          "title": "What types of nursing degrees are there?",
          "content": "There are several levels of nursing degrees including:\n\n    + CNA (certified nursing assistant)\n    + LPN (licensed practical nurse)\n    + RN (registered nurse)\n    + APRN (advanced practice registered nurse)Prelicensure. These nursing programs prepare students for an RN license and a BSN at the same time.\n    + RN to BSN. These nursing programs are for current RNs who want to add a BSN to their résumé.\n    + RN to MSN. These nursing programs are for current RNs who want to earn both a BSN and MSN in a singular program.\n    + BSN to MSN. These nursing programs are for current RNs who already have a BSN, and are hoping to boost their credentials.\n    + MSN programs. MSN nursing programs can have a specific specialty such as leadership, education, or informatics.\n\n                    "
        },
        {
          "title": "Are there jobs in the healthcare industry?",
          "content": "Yes! the healthcare industry is experiencing rapid growth. According to a 2018 report by The Atlantic, “for the first time in history, healthcare has surpassed manufacturing and retail, the most significant job engines of the 20th century, to become the largest source of jobs in the U.S.”\n\nThis explosive growth is a result of both our aging population and our healthcare services becoming increasingly complex. For those with a passion for healthcare and good business acumen, a healthcare management degree is the ticket to a rewarding and secure career path in the health field.\n\n"
        },
        {
          "title": "What is the difference between healthcare administration and healthcare management",
          "content": "While healthcare administration professionals handle staffing, Healthcare management professionals run the show. Executives at hospitals, clinics, rehabilitation centers and nursing homes are all considered healthcare management positions. These positions generally require an advanced degree, such as an MA or MBA. Many executive positions will require a DHA (Doctor of Healthcare Administration).\n\n"
        },
        {
          "title": "What Can You Do with a Bachelor’s in Healthcare Management",
          "content": "There are many different programs that fall under the umbrella of a bachelor’s of healthcare management. Some focus more on administration, while some focus more on management.\n\nMany programs are tailored to specific areas within healthcare, such as informatics or insurance underwriting, while others are designed for students who want a broader education that they can use in a variety of settings. Here’s a breakdown of what you will learn and what types of jobs will be within your reach after graduation.\n\n"
        }
      ]

  return (
    <>
      <FAQArea 
        title={"Commonly Asked Questions About Healthcare Degrees"}
        faqData={faqData}
      ></FAQArea>
    </>
  )
}

export default HealthcareFAQ