import {initializeApp} from "firebase/app"
import { getAuth } from "@firebase/auth";
import { getDatabase } from "@firebase/database";


// Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyBNEXnKlK5cBhiP_XHkKHHCkE3bI06Mmtk",
        authDomain: "test-project-5dcc3.firebaseapp.com",
        databaseURL: "https://test-project-5dcc3-default-rtdb.firebaseio.com",
        projectId: "test-project-5dcc3",
        storageBucket: "test-project-5dcc3.appspot.com",
        messagingSenderId: "486884697012",
        appId: "1:486884697012:web:7728c0433d6d2aae47c8e1"
    };
  
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Connect to the necessary services
    export const auth = getAuth(app)
    export const db = getDatabase(app)
    // export const storage = getStorage(app)