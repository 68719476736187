import { BrowserRouter, Route, Router, RouterProvider, Routes } from 'react-router-dom';
import './App.css';
import TopNav from './Components/TopNav';
import Home from './Pages/Home/Home';
import BottomNav from './Components/BottomNav';
import Program from './Pages/Programs/Program';
import StudentPortal from './Pages/StudentPortal/StudentPortal';
import ApplyPage from './Pages/ApplyPage/ApplyPage';
import Parser from './DataProcessing/Parser';
import ComponentTest from './DataProcessing/ComponentTest';
import ProgramDetail from './Pages/Programs/ProgramDetail';
import ContactPage from './Pages/Contact/ContactPage';
import Events from './Pages/Events/Events';
import Event from './Pages/Events/Event';
import Tuition from './Pages/Tuition/Tuition';
import Programs from './Pages/Programs/Programs';
import FacultyResearch from './Pages/FacultyResearch/FacultyResearch';
import ApplyPage2 from './Pages/ApplyPage/ApplyPage2';
/*
  ================================================================================
  Pages to Add:
  ================================================================================


    Content:
      Events: unique dates, unique locations, some don't require a ticket, FAQs
      Program Detail Page: the hilights section changes based on the program type

    Images:
      student portal background
      :
        background of student portal: student building with logo and students walking in 
      homepage: the logo on the classroom wall, logon on welcome baloon arch
      events page: the building from the ucs page with the logo on it
      home page: the welcome banner with the logo on it
      faculty page: faculty with shirts with the logo on it, and me in a suit with the logo
                    and on stage with a bunch people, ted talk, graduation speech
      TUITION & FINANCIAL AID page banner
      
    Admissions page (or remove link and add to maybe list)

    404 page

    Review Content
      go through, read, and edit all of the text content, add as necessary with chat
      Events
          


    maybe:
      admissions page
      Catalogue (from wgu bottom bar, opens a pdf)
        could generate this pdf from the course cataloge and programs objects
      accreditation 
      about us page
      youtbe channel, has research videos, propo videos, would have to get a lot of views/subs or can use anouther service like vimeo, or load the videos into the project, or just have images and no videos

      Faculty Page (maybe same as research page)
        picture of me in a suit
        could have a video of me on a channel with a lot of subs
        and the video has a lot views
        will need to find a way to pay for lots of views

    Homepage
      Whalley Dates: 
        have a scroller with all of them
        tile css goes to 50% width before 1005 width
        pulls from the events data, not just hard coded
      add a few more sections
        what our graduates are saying (from wgu)
        online degrees form wgu
        meet our students 
          with images: people wearing the logo, holding up a banner with the logo, college pride events like a football game with athletes wearing logo, or flags with the logo
        good homepage example: https://www.sheffield.ac.uk/
          STUDY section instead of the top tiles
          search bar goes to programs page with the search input (can get it form the url)
        example: https://www.coloradotech.edu/
          putting application page in the homepage
          insights area with blog looking posts
          what students are saying area
        
    Event Detail
      remove faq section titles
      look at better description display
      top area styling on width resize
      remove titles from faq sections

    Contact
      use form box for the message
      the message sends somewhere maybe, or at least shows a message that it was sent
    
    form box
      check conditions on inputs: phone has at least 7 numerical characters, email has @ and ., maybe add ability ot customize it
      clear button

    CSS
      make as many global ones as possible, unless for a transferrable component then make it as self contained as possible

    components
      make them transferrable to other projects
      message form
        styling name updates
        half button option
        label shows option (like in apply page)
        use this component in the contatc page and event page for ticket

    Imgage
      Program Detail Page
        2 round images
        stats images
      Events
        main images
      Faculty
        in 
      Student portal background
        students walking into a bulding with the logo on it 
      Home page
        logo in classroom image: on wall, on sweatshirts, on laptop stickers
        logo on welcome baloon arch
      apply now with the actual website put on the computer
      student sitting with laptop and on the laptop is a sticker with the university logo 

    Text Content
      Program Detail Page
        courses (currently just "intro to courses")
        have an object with all course data
        in the program data link several courses by id
        can generate this
      add dates to the events
        maybe a function that moved it out by semester increments if the data has already passed

    Adds
    put out adds so it shows in search results when it is likely someone will be checking on it

  ================================================================================
  Links
  
  https://dashboard.render.com/static/srv-clcm14vgsrdc7388d6dg/settings
  https://dcc.godaddy.com/control/portfolio/whalley.education/settings?tab=dns&itc=mya_vh_buildwebsite_domain

  ================================================================================
  AI Generated apps
  ================================================================================

    https://www.youtube.com/watch?v=v6rMw8-kqr0
    https://www.quest.ai/

  ================================================================================
  Examples
  ================================================================================

    videos:
    https://www.youtube.com/playlist?list=PL9Q6NT5Ukv8Qf0zCd2MaYeq9CnFUhcAwP
    :
    uk
    U abertay
    https://www.youtube.com/@abertayTV/videos
    Aberystwyth University
    https://www.youtube.com/@aberystwythuni/playlists
    good playlist (from, Aberystwyth)
    https://www.youtube.com/watch?v=94eE40-0gSQ&list=PLJkk1nGZKnSC77yTDX9WZYOowEDG-P7Np

    https://www.tufts.edu/

    Healthcare Certificates from Penn State
    https://www.worldcampus.psu.edu/degrees-and-certificates/graduate-certificates

    not a college but good homepage
    https://wpengine.com/

    https://ischool.uw.edu/

    default page:
    https://www.ucf.edu/news/tag/research-in-60-seconds/asdf

    new one
    https://online.mica.edu/ux/?marketing_location=MICA&marketing_product=MPS-UXD&marketing_channel=paid_marketing&marketing_medium=paid_social&marketing_source=facebook&marketing_platform=meta_ads&utm_source=facebook&utm_medium=paid_social&utm_campaign=Lookalike&utm_content=fb&utm_term=Lookalike%20(US%2C%201%25)%20-%20Page%20Engagement%20-%20Joint%20Pixel

    https://www.stran.ac.uk/

    welcome open day block
    dates tiles
    copywright thing on bottom nav
    open events tiles on homepage from here
    https://www.southampton.ac.uk/open-days/undergraduate

    L R blocks
    https://www.uclan.ac.uk/business/develop-your-business/innovation

    topnav maybe banner
    https://www.derby.ac.uk/

    banner tiles
    degree program pages
    application page links
    images on banner tiles, maybe arrows
    https://www.wgu.edu/

    banner image, students in class
    https://www.bath.ac.uk/
    courses page
    https://www.bath.ac.uk/courses/

    tiles
    homepage looks good
    https://www.sheffield.ac.uk/
    program detail page
    https://ucr.rotherham.ac.uk/course/chartered-manager-degree-apprenticeship-2/

    could just put apply now on the program tiles instead of creating a whole detail page
    can have the title in the apply now area go to a course selector

    search bar
    https://ucr.rotherham.ac.uk/
    open events page
    https://ucr.rotherham.ac.uk/open-events/
    contact page
    https://ucr.rotherham.ac.uk/contact/

    UCF
    faculty area
    mews section, using those tiles on the homepage
    bottom nav section
    https://www.ucf.edu/
    program page
    https://www.ucf.edu/degree/computer-science-bs/
    Cost Page
    https://www.ucf.edu/financial-aid/cost/

    for youtube presence:
    buy
    https://www.outlookindia.com/outlook-spotlight/buy-youtube-subscribers-5-best-sites-to-buy-youtube-subscribers-news-246603
    https://app.bulkoid.com/order/YouTube
    :
    do it yourself
    https://github.com/topics/youtube-view-bot

    CSS with multiple uses
    contactPageTitle
    contactPageTitle

    degree program detail view
    https://www.coloradotech.edu/degrees/bachelors/business-administration

    https://www.rmcad.edu/
    https://www.rmcad.edu/admissions/art-institute-transfers/

    https://www.southuniversity.edu/

    story about a corperate college 
    https://www.americanprogress.org/article/college-accrediting-agency-failed-protect-students-decade-fraud/
    https://www.independence.edu/

    from fb adds

    https://professional.brown.edu/
    clear anti w organization
    holding flags
    https://professional.brown.edu/admissions
    https://www.facebook.com/BrownSPS
    interesting program, shows cert
    https://online-tech.professional.brown.edu/applied-ai-and-data-science-course?_gl=1%2A1drxtbn%2A_ga%2AMTU2MjQ3NTg5OS4xNjYwMDg3ODE4%2A_ga_L18K5ENW0J%2AMTY5NTM4OTAxNy44NjkuMS4xNjk1MzkxMzA3LjE4LjAuMA..

    https://www.open.ac.uk/
    https://www.facebook.com/theopenuniversity

    image on homepage
    https://discover.online.purdue.edu/

  ================================================================================
  Other Notes / Completed Notes
  ================================================================================

      Shadow of a University
      https://www.youtube.com/watch?v=1RWOpQXTltA

      1) compile all of the programs into a single object that has an array of program types that it should show for
       ) create a function that returns them when given program type and degree level
      2) add an array of object with course ID's and year that represent the courses the student would take that year for each program
      3) create an object with courses that have course IDs that correspond with the course IDs in the programs
         add a name, description, and number of credits for each course

    2) Tuition and fees page
        link from: Program Detail Page, bottom nav


    1) Event detail page
        example:
        https://ucr.rotherham.ac.uk/open-events/
          
          from this example:
          description area at top with image
          our upcoming events tile area (cliclable)
            use the example on the detail page of the example below, reuse it in the event detail view
          FAQ section
            maybe make faq from other page into a component so it can be re-used here

        event detail page example
        https://www.southampton.ac.uk/campaigns/first-look
          
          from this example:
          banner with title and image
          description 
          tiles for other events

        event detail page example 2
        https://ucr.rotherham.ac.uk/event/personal-tour/

        book ticket example
        https://www.eventbrite.co.uk/e/saturday-18th-november-2023-open-event-tickets-665137493147

      1) about course page
      exaple:
      https://www.wgu.edu/online-it-degrees/cybersecurity-information-assurance-bachelors-program.html
      
      https://www.wgu.edu/content/dam/wgu-65-assets/western-governors/documents/institutional-catalog/2023/Institutional-Catalog-%20October-2023.pdf


      First build UI with sample data 
      then setup structure of data and connect it
        define what data is needed, where it will be kept, how it will be structured
      then parse the data from the catalogue, making sure it is connecting correctly at regular intervals (expecially in beginning)
        create parser page
      can create 100s of pages pretty quickly this way

      top banner from programs page
      the name of the course where the banner text is
      the banner text can go in the description title
      will need a url for each degree
        maybe can parse from the name, output a list so can look and make sure they all look ok
      also parse name into a smaller name for the top of the courses section
      can copy the description from each one and put in a json (a web scraper would be cool for this)

      parser can be a page
      has course title input
      has courses table text area
      parse button
        parses, puts in a json, saves json in local storage
      page 173 has course descriptions that can be parsed into the course catalogue
      so parse the CCN as the key, create attributes of: course name, description, credit hours, and term
      put a list of CCN's in each degree json 

      Sections:
      
      top section:
        title will be specific to the degree, can put in the object in the programs page, or in an object in another file, will be a lot but can copy paste
        top pre string will be specific too, can put in an object in another file, will be a lot but can just copy and paste
        set image based on the degree, get from the url like with the programs page
        can be randomized, maybe based on program type (one of the 4)
        can even just use the small description in the course catalogue initially
        will have an object with name, description, and generaged courses json parsed from the courses table

        can create a parser that gets all of the unique course CCN's and shows that json so can add description for each or create a course catalogue with oue entry for each course
      
      courses section:
        copy their catalogue, create an array of keys that make those courses with description show in this section in collapasable components
        number of courses and credits calculated from that automatically
        test with a few to make sure everything is formatted correctly before spending a ton of time copying all of them
      
      Look in their catalogue, can parse the data from this for each one
        reads each line
        split into items
        each item matches to a key which is put in a json
      Maybe don't even need descriptions, can just put the courses and credit hours, at least initially

      Why Weiley section
      
      Admissions Requirements section
        can be by career type (only 4 instead of 100's or specific degrees)
      


*/
function App() {
  return (
    <div className="App">
      <TopNav></TopNav>
      <div className='pageContainer'>
        <Routes>
          {/* Dev */}
          <Route path='/' element={<Home></Home>}></Route>
          <Route path='/test' element={<ComponentTest></ComponentTest>}></Route>
          <Route path='/parser' element={<Parser></Parser>}></Route>
          {/* Pages */}
          <Route path='/apply' element={<ApplyPage2></ApplyPage2>}></Route>
          <Route path='/contact' element={<ContactPage></ContactPage>}></Route>
          <Route path='/student-portal' element={<StudentPortal></StudentPortal>}></Route>
          <Route path='/tuition' element={<Tuition></Tuition>}></Route>
          <Route path='/faculty-research' element={<FacultyResearch></FacultyResearch>}></Route>
          <Route path='/faculty-research/:researchDataKey' element={<FacultyResearch></FacultyResearch>}></Route>
          {/* Events */}
          <Route path='/events' element={<Events></Events>}></Route>
          <Route path='/event/:eventName' element={<Event></Event>}></Route>
          {/* Programs */}
          <Route path='/programs' element={<Programs></Programs>}></Route>
          <Route path='/programs/:program' element={<Programs></Programs>}></Route>
          <Route path='/programs/:program/:degreeType' element={<Programs></Programs>}></Route>
          <Route path='/program-detail' element={<ProgramDetail></ProgramDetail>}></Route>
          <Route path='/program-detail/:programKey' element={<ProgramDetail></ProgramDetail>}></Route>
        </Routes>
      </div>
      <BottomNav></BottomNav>
    </div>
  );
}

export default App;
