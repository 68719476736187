import React from 'react'
import { Link } from 'react-router-dom'

function SplitBanner({title, description, image, link}) {
  return (
    <>
        <div className='openDaysSection over1200'>
                <div className='openDaysSectionLeft'>
                    <div className='openDatTextArea'>
                        <div className='openDayTitle'>{title}</div>
                        <div className='openDayText'>
                            {description}
                        </div>
                        {link && 
                            <Link to={link}>
                                <button className='openDayButton'>
                                    Find Out More
                                </button>
                            </Link>
                        }
                    </div>
                </div>
                <div className='openDaysSectionRight'>
                    <img src={image}></img>
                </div>
        </div>
        <div className='openDaysSection under1200'>
            <div className='openDayTopTitle'>{title}</div>
            <div className='openDaysImage'>
                <img src={image}></img>
            </div>
            <div className='openDaysDescription'>
                <div className='openDayText'>
                    {description}
                </div>
                <div>
                    {link && 
                        <Link to={link}>
                            <button className='openDayButton'>
                                Find Out More
                            </button>
                        </Link>
                    }
                </div>
            </div>
        </div>
    </>
  )
}

export default SplitBanner