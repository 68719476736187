import React, { useEffect } from 'react'
import EventsFAQ from '../../Components/FAQ/EventsFAQ'
import openDayImage from "../../Images/openDayDateTile.jpg"
import virtualEvent from "../../Images/virtualEvent.png"
import globe from "../../Images/globe.jpg"
import EventTile from '../../Components/Events/EventTile'
import eventBaloonw from "../../Images/eventBaloons.jpeg"
import "./Events.css"
import { Link } from 'react-router-dom'
import { openEventsData } from '../../DataProcessing/EventsData'
import { scrollTop } from '../../Global/Functions'

function Events() {

  useEffect(()=>{
    scrollTop()
  },[])

  return (
    <div>

        <div className=' pageInnerWidth upcomingEventsArea'>
            <div className='contactPageTitle'>Open Events</div>
            <img className='eventsImg' src={eventBaloonw}></img>
            <div className='eventsPageTitle'>Discover Your Future</div>
            <p>Our Open Events provide a great chance to explore our dedicated Higher Education campus, speak to tutors in the subject areas you’re interested in studying, meet other prospective students and get a feel for the courses on offer.</p>
            <p>Our events offer one-hour bookable sessions for you to select the best time to suit you. We understand that seeing our campus, meeting staff, and getting a real feel for what we can offer you is key to deciding your next steps in to Higher Education.</p>
            <p>We look forward to showing you what great degree and higher-level training options are offered locally to you.</p>
            <div className='eventsPageTitle'>Can’t make our Open Events?</div>
            <p>
              {"We are happy to arrange a drop-in visit where you can take a look around, meet the tutors and find out more about the courses we offer. "} 
              <Link to={"/contact"} className='inlineLink'>Contact us</Link> to arrange a bespoke tour, individual talk or ask any other queries.
            </p>
        </div>
        
        <div className='pageInnerWidth'>
            <div className=''>
              <div className='contactPageTitle'>Upcoming Events</div>
                {Object.entries(openEventsData).map(([eventKey, eventData]) => (
                  <EventTile
                    key={eventKey}
                    eventKey={eventKey}
                  ></EventTile>
                ))}
            </div>
        </div>

        <div className='pageInnerWidth faqContainer'>
          <EventsFAQ></EventsFAQ>
        </div>
      
    </div>
  )
}

export default Events