import React from 'react'
import "./ContactPage.css"
import copyIcon from "../../Images/copy-icon.png"
import mapView from "../../Images/mapView3.png"

function ContactPage() {

    function copy(string){

    }

  return (
    <div className='contactPage innerPage'>
        
        {/* Page Title */}

        <div className='contactPageTitle'>CONTACT</div>

        <div>

            {/* Description */}

            <div className='contactArea'>
                <div className='contactAreaTitle'>
                    Get In Touch With Us
                </div>
                <div className='contactAreaText contactAreaTextSmaller'>
                    <p>
                        You can choose to contact us by email, phone, post or by filling in the online enquiry form via the button below.
                    </p>
                    <p>
                        Whichever way you choose, our staff will be pleased to help and will get back to you as soon as possible.
                    </p>
                </div>
            </div>

            {/* Contact Details */}

            <div className='contactArea'>
                <div className='contactAreaTitleConteiner'>
                    <div className='contactAreaTitle'>
                        Contact Details
                    </div>
                </div>
                <div className='contactAreaText contactAreaTextSmaller'>
                    <div className='contactMethodColumnConteinaesr'>
                        <div className='contactMethodColumn'>
                            <div className='contactMethodTitle'>Email:</div>
                            <div className='contactMethodTitle'>Phone:</div>
                        </div>
                        <div className='contactMethodColumn'>
                            <div className='contactMethod'>
                                <div href='mailto:info@Whalley.education'>info@Whalley.education</div>
                                {/* <div className='copyImageContainer'>
                                    <img className='copyImage' title={"Copy"} src={copyIcon} onClick={()=>copy("info@Whalley.education")}></img>
                                    <div className='copyImageLabel'>Copy</div>
                                </div> */}
                            </div>
                            <div className='contactMethod'>
                                <a href='tel:'>+41 405 503 3048</a>  
                                {/* <img className='copyImage' title={"Copy"} src={copyIcon} onClick={()=>copy("+41 405 503 3048")}></img> */}

                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div>

            {/* Message Box */}
            
            <div className='contactArea'>
                <div className='contactAreaTitle'>
                    Message Us Here
                </div>
                <div className='contactAreaText'>
                    <div className='messageBox'>
                        <div className='messageBoxTitle'>Message Form</div>
                        <input placeholder='Name'></input>
                        <input placeholder='Phone'></input>
                        <input placeholder='Email'></input>
                        <textarea placeholder='Message'></textarea>
                        <div className='submitMessageButtonArea'>
                            <button>Clear</button>
                            <button>Send</button>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className='contactArea'>
                <div className='contactAreaTitle'>
                    Visit Us
                </div>
                {/* <div className='contactAreaText'> */}
                <div className='contactAreaText'>
                    <div className='mapBox'>
                        <div className='mapBoxImg'>
                                <img src={mapView}></img>
                        </div>
                        <div className='mapBoxDescription'>
                                <div className='mapBoxDescriptionName'>Whalley University</div>
                                <div className='mapBoxDescriptionAddress'>
                                    <div>108 Springwood Drive</div>
                                    <div>Lancashire, England</div>
                                </div>
                        </div>
                        <div className='mapInfo'>
                            <div className='mapInfoTitle'>Parking information</div>
                            <div className='mapInfoDescription'>
                                Parking is available in the many carparks around the University Centre. If you are visiting us for an open day, the carparks are free after 6pm during the weekdays and all day at the weekends. The maximum cost during a weekday is £3.50 for the full day.

                            </div>
                        </div>
                    </div>

                </div>
                {/* </div> */}
            </div>

        </div>


    </div>
  )
}

export default ContactPage