import React, { useEffect, useRef, useState } from 'react'
import "./CollapasableRow.css"

function CollapasableRow(props) {

  const [open, setOpen] = useState()

  return (
    <div className={'cRow '+(open ? " cRowOpen" : "")} >
        <div className='cRowTitle' onClick={(e)=>{e.stopPropagation(); setOpen(!open)}}>
          {props.title}
          <div className='minMaxButton' title={open?"Close":"Expand"}>{open ? "-":"+"}</div>
        </div>
        <div className={'cRowInner ' + (open ? " cRowInnerOpen" : "")} >
            {props.children}
        </div>
    </div>
  )
}

export default CollapasableRow