import React from 'react'
import "./ComponentTest.css"
import styles from './ComponentTest.css';

function ComponentTest() {

    return (
        <div className="online-degree">
          <div className="header">
            <h1>Bachelor of Science</h1>
            <h2>Online Computer Science Degree</h2>
            <button className="apply-now-btn">Apply Now</button>
          </div>
          
          <div className="content">
            <div className="sidebar">
              <nav>
                <ul>
                  <li>Overview</li>
                  <li>Courses</li>
                  <li>IT Certifications</li>
                  <li>Cost & Time</li>
                  <li>Flexible Schedule</li>
                  <li>Career Outlook</li>
                  <li>Admissions & Transfers</li>
                </ul>
              </nav>
              <div className="start-date">
                <p>Next Start Date</p>
                <p>December 1</p>
                <button className="apply-today-btn">APPLY TODAY</button>
              </div>
            </div>
    
            <div className="main-content">
              <h3>OVERVIEW</h3>
              <h4>An Online Computer Science Degree That Prepares You for a Dynamic Career</h4>
              <p>Earn your bachelor's degree in computer science from WGU and increase your earning potential, job security, and opportunities for advancement. This degree prepares you to design, develop, and optimize systems that will meet industry needs, and gives you the opportunity to earn multiple industry-recognized certifications.</p>
              <p>Designed with input from industry experts, the ABET-accredited B.S. Computer Science degree program at WGU provides a solid foundation for the most in-demand skills:</p>
              <ul>
                <li>Logic</li>
                <li>Architecture and systems</li>
                <li>Data structures</li>
                <li>AI</li>
                <li>Computer theory</li>
                <li>Version Control</li>
                <li>Linux</li>
              </ul>
            </div>
          </div>
        </div>
      );
      
}

export default ComponentTest