import React, { useEffect, useState } from 'react'
import "./StudentPortal.css"
import { scrollTop } from '../../Global/Functions'
function StudentPortal() {

    useEffect(()=>{
        scrollTop()
    },[])

    const [message, setMessage] = useState()
    function attemptSignIn(){
        setMessage()
        let emailInputValue = document.getElementById("emailInput")?.value
        let passInputValue = document.getElementById("passInput")?.value
        if(!emailInputValue || emailInputValue.length < 6)
            setMessage(`Please enter a valid username or email
(6 characters mininmum)`)
        else if(!passInputValue || passInputValue.length < 6)
            setMessage(`Please enter a valid password
(6 characters mininmum)`)
        else
            setMessage("User not found, please try again or reset password below.")
    }

    const [resettingPassword, setResettingPassword] = useState()
    function toggleResettingPassword(){
        setMessage()
        setResettingPassword(!resettingPassword)
    }
    function submittedUsernameForPassReset(){
        setMessage()
        let emailInputValue = document.getElementById("emailInput")?.value
        if(!emailInputValue || emailInputValue.length < 6)
            setMessage(`Please enter a valid username or email
(6 characters mininmum)`)
        else
            setMessage("If an account exists for "+emailInputValue+" an email with password reset instructions has been sent.")
    }

  return (
    <div className='page studentPortalPage'>
        <div className='studentPortalColumn'>
            {/* <div className='studentPortalRow studentSigninLogo'>
                <img src={logo}></img>
            </div> */}
            <div className='studentPortalRow studentPortalInputFieldsBox'>
                {!resettingPassword ? 
                <>
                    <div className='studentPortalInputFieldsBoxTitle'>
                        Student Portal Sign In
                    </div>
                    <div>
                        <input placeholder='Username or Email' id='emailInput'></input>
                    </div>
                    <div>
                        <input placeholder='Password' type='password' id='passInput'></input>
                    </div>
                    {message && <div className='signInMessage'>
                        <pre>
                            {message}
                        </pre>
                    </div>}
                    <div>
                        <button onClick={attemptSignIn}>Sign In</button>
                    </div>
                    <div className='loginBottomText'>
                        Forgot password? <a onClick={toggleResettingPassword} className='inlineLink'>Reset it here.</a>
                    </div>
                </>
                :
                <>
                    <div className='studentPortalInputFieldsBoxTitle'>
                        Password Reset
                    </div>
                    <div>
                        <input placeholder='Username / Email' id='emailInput'></input>
                    </div>
                    <div className='signInMessage'>
                        <pre>
                            {message}
                        </pre>
                    </div>
                    <div>
                        <button onClick={submittedUsernameForPassReset}>Reset Password</button>
                    </div>
                    <div className='loginBottomText'>
                        Remembered password? <a onClick={toggleResettingPassword} className='inlineLink'>Sign In Here.</a>
                    </div>
                </>    
                }
            </div>
        </div>
    </div>
  )
}

export default StudentPortal