import React, { useEffect } from 'react'
import bannerImage from "../../Images/lectureHallWSticker.jpg"
import welcomeDayImage from "../../Images/welcomeDayEdited.jpg"
import whalleyApplicationLaptop from "../../Images/Whalley-Application-Laptop.jpg"
import "./Home.css"
import { Link } from 'react-router-dom'
import businessIcon from "../../Images/businessIcon.png"
import healthcareIcon from "../../Images/healthcareIcon.png"
import itIcon from "../../Images/itIcon.png"
import educationIcon from "../../Images/educationIcon.png"
import EventTile from '../../Components/Events/EventTile'
import SplitBanner from '../../Components/SplitBanner'
import { scrollTop } from '../../Global/Functions'
import WideTile from '../../Components/Tile/WideTile'
import { allResearchData } from '../../Data/Articles'

function Home() {

    useEffect(()=>{
        scrollTop()
    },[])

  return (
    <div className='homepage'>

        {/* Banner */}

        <div className='banner'>
            <img src={bannerImage}></img>
            <div className='bannerText'>Find Your Path</div>
        </div>
        <div className='bannerCover'></div>
        
        {/* Banner Tiles */}

        <div className='bannerTilesArea'>
            <div className='bannerTileRow'>
                <div className='bannerTile'>
                    <Link to={"/programs/it"}>
                        {/* <div className='bannerTileTitle'>Information Technology</div> */}
                        <div className='bannerTileType'>
                            <img src={itIcon}></img>
                            <div className='bannerTileTypeText'>Information Technology</div>
                        </div>
                    </Link>
                    <div className='bannerTileButtons'>
                        <Link to={"/programs/it/bachelors"}>
                            <div className='bannerTileButton'>Bachelor's Degrees</div>
                        </Link>

                        <Link to={"/programs/it/masters"}>
                            <div className='bannerTileButton'>Masters's Degrees</div>
                        </Link>

                        <Link to={"/programs/it/certificates"}>
                            <div className='bannerTileButton'>Certificates</div>
                        </Link>

                    </div>
                </div>
                <div className='bannerTile'>
                    <Link to={"/programs/business"}>
                        {/* <div className='bannerTileTitle'>Business</div> */}
                        <div className='bannerTileType'>
                            <img src={businessIcon}></img>
                            <div className='bannerTileTypeText'>Business</div>
                        </div>
                    </Link>
                    <div className='bannerTileButtons'>
                        <Link to={"/programs/business/bachelors"}>
                            <div className='bannerTileButton'>Bachelor's Degrees</div>
                        </Link>
                        <Link to={"/programs/business/masters"}>
                            <div className='bannerTileButton'>Masters Degrees</div>
                        </Link>
                        <Link to={"/programs/business/certificates"}>
                            <div className='bannerTileButton'>Certificates</div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='bannerTileRow'>
                <div className='bannerTile'>
                    <Link to={"/programs/healthcare"}>
                        {/* <div className='bannerTileTitle'>Health & Nursing</div> */}
                        <div className='bannerTileType'>
                            <img src={healthcareIcon}></img>
                            <div className='bannerTileTypeText'>Health & Nursing</div>
                        </div>
                    </Link>
                    <div className='bannerTileButtons'>
                        <Link to={"/programs/healthcare/bachelors"}>
                            <div className='bannerTileButton'>Bachelor's Degrees</div>
                        </Link>
                        <Link to={"/programs/healthcare/masters"}>
                            <div className='bannerTileButton'>Masters's Degrees</div>
                        </Link>
                        <Link to={"/programs/healthcare/certificates"}>
                            <div className='bannerTileButton'>Certificates</div>
                        </Link>
                    </div>
                </div>
                <div className='bannerTile'>
                    <Link to={"/programs/education"}>
                        {/* <div className='bannerTileTitle'>Education</div> */}
                        <div className='bannerTileType'>
                            <img src={educationIcon}></img>
                            <div className='bannerTileTypeText'>Education</div>
                        </div>
                    </Link>
                    <div className='bannerTileButtons'>
                        <Link to={"/programs/education/bachelors"}>
                            <div className='bannerTileButton'>Bachelor's Degrees</div>
                        </Link>
                        <Link to={"/programs/education/masters"}>
                            <div className='bannerTileButton'>Masters's Degrees</div>
                        </Link>
                        {/* <Link to={"/programs/education/certificates"}>
                            <div className='bannerTileButton'>Certificates</div>
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>

        {/* Dates */}
        
        <div className='pageInnerWidth2 homeSectionPad'>
            <div className='datesTitle contactPageTitle'>Open Events</div>
            <div>
                <EventTile 
                    eventKey={"virtual-first-look"}
                ></EventTile>
                <EventTile 
                    eventKey={"open-day"}
                ></EventTile>
                <EventTile 
                    eventKey={"application-workshop"}
                ></EventTile>
                <EventTile 
                    eventKey={"info-sessions"}
                    customClass={"sometimesHiddenTile"}
                ></EventTile>
            </div>
        </div>

        {/* Open Day */}

        <SplitBanner
            title={"Undergraduate open days"}
            description={"Visit us at an open day to find out more about your subject of interest and to see whether Whalley is the right place for you."}
            link={"/event/open-day"}
            image={welcomeDayImage}
        ></SplitBanner>

        {/* Faculty Stories */}

        <div className='pageInnerWidth2 homeSectionPad'>
            <div className='facultyTile facultyDescriptionTile'>
                <div className='facultyDescriptionTitle contactPageTitle'>Expert Faculty</div>
                <div className='facultyDescriptionText'>
                    Our faculty and researchers are focused on solving the biggest problems facing humanity. Discover their stories and explore the groundbreaking research that’s creating meaningful change for today, and for the future.
                </div>
            </div>
            {}
            {Object.entries(allResearchData).map(([key, data], index) => (
                <WideTile
                    key={key}
                    title={data.title}
                    image={data.tilePoster}
                    link={"/faculty-research/"+key}
                    description={data.descriptionShort}
                    customClass={index == (Object.entries(allResearchData).length -1) ? " sometimesHiddenTile":""}
                >
                </WideTile>
            ))}
            {/* <div className='facultyTile'>
                <img className='facultyTileImage' src={ladyHoldingMoon}></img>
                <div className='facultyTileText'>
                    <div className='facultyTileTitle'>Finding Water on the Moon</div>
                    <div className='facultyTileDescriptionText'>
                        Jillian Gloria ’22 explains how her research helps find water on the moon to better understand where astronauts can live and work safely.
                    </div>
                </div>
            </div>
            <div className='facultyTile'>
                <img className='facultyTileImage' src={srtipeShirtLady}></img>
                <div className='facultyTileText'>
                    <div className='facultyTileTitle'>Tiny Bubbles Big Solution</div>
                    <div className='facultyTileDescriptionText'>
                        Tara Pattilachan ’22’s innovative, non-invasive research may one day help treat the debilitating disease, osteoporosis.                    
                    </div>
                </div>
            </div>
            <div className='facultyTile'>
                <img className='facultyTileImage' src={beardedMan}></img>
                <div className='facultyTileText'>
                    <div className='facultyTileTitle'>Quantum Physics for the Future of Tech</div>
                    <div className='facultyTileDescriptionText'>                        
                        Enrique Del Barco, explains quantum physics that propel us toward future technologies – in a simple way!
                    </div>
                </div>
            </div> */}
        </div>

         {/* Application Deadlines */}

         <div className='applicationDeadlinesSection'>  
                <div className='applicationDeatlineTitle'>APPLICATION DEADLINES</div>
                <div className='programPageWidth'>
                    <div className='applicationDeadlineLeftDates'>
                        <div className='applicationDeadlineLeftDate'>
                            <div className='applicationDeadlineLeftDateTitle'>Fall</div>
                            <div className='applicationDeadlineLeftDateDate'>May 1</div>
                        </div>
                        <div className='applicationDeadlineLeftDate'>
                            <div className='applicationDeadlineLeftDateTitle'>Spring</div>
                            <div className='applicationDeadlineLeftDateDate'>November 1</div>
                        </div>
                        <div className='applicationDeadlineLeftDate'>
                            <div className='applicationDeadlineLeftDateTitle'>Summer</div>
                            <div className='applicationDeadlineLeftDateDate'>March 1</div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Application Section 2 */}

            <div className='applicationSection2'>
                <div className='applicationSection2PageWidth'>
                    <img src={whalleyApplicationLaptop}></img>
                    <div className='applicationSection2Text'>
                        <div className='applicationSection2Title'>START YOUR APPLICATION TODAY</div>
                        <div className='applicationSection2Title2'>
                            <div>
                                Short on time? 
                            </div>
                            {/* <div>
                                Take the next step to save your spot at Whalley.

                            </div> */}
                            <div className='applicationSection2Title3'>
                                You can start your application today and come back later to finish.
                            </div>
                            <div className='applicationSection2ButtonConteiner'>
                                <Link to={"/apply"}>
                                    <button className='applicationButton2'>Start Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
  )
}

export default Home