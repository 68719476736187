import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import "./Program.css"
import { programBannerURL, scrollTop } from '../../Global/Functions'
import percent95 from "../../Images/percent95_3.png"
import percent97 from "../../Images/percent97_3.png"
import percent99 from "../../Images/percent99_3.png"
import {businessBAProgramTiles, businessCEProgramTiles, businessMAProgramTiles, educationBAProgramTiles, educationMAProgramTiles, healthcareBAProgramTiles, healthcareCEProgramTiles, healthcareMAProgramTiles, itBAProgramTiles, itCEProgramTiles, itMAProgramTiles} from '../../DataProcessing/CourseTiles'
import BusinessFAQ from '../../Components/FAQ/BusinessFAQ'
import ITFAQ from '../../Components/FAQ/ITFAQ'
import EducationFAQ from '../../Components/FAQ/EducationFAQ'
import HealthcareFAQ from '../../Components/FAQ/HealthcareFAQ'
import { businessBAProgramTileKeys, businessCEProgramTileKeys, businessMAProgramTileKeys, combinedProgramData, educationBAProgramTileKeys, educationMAProgramTileKeys, healthcareBAProgramTileKeys, healthcareCEProgramTileKeys, healthcareMAProgramTileKeys, itBAProgramTileKeys, itCEProgramTileKeys, itMAProgramTileKeys } from '../../Data/ProgramData'
import ProgramTile from './ProgramTile'
// import { businessBAProgramTiles } from '../../DataProcessing/CourseTiles'

function Programs() {
    const {program, degreeType} = useParams()
        
    // ================================================================================
    // #region Initial Values: programTiles, bannerTitle, faqContent, loadBanner, scrollTop 

    const [programKeyArray, setProgramKeyArray] = useState([])
    useEffect(()=>{
        setProgramKeyArrayFunction()
        setBannerTitleFunction()
        scrollTop()
        loadBanner()
    },[])
    
    function setProgramKeyArrayFunction(){
        // setProgramKeyArray(filterProgramsFunctoin(combinedProgramData, program, degreeType, ))
        // return
        // setProgramKeyArray(filterPrograms2(consolidatedPrograms2, degreeType, program))
        // return
        // Business
        if(program === "business"){
            // BA
            if(degreeType === "bachelors"){
                setProgramKeyArray(businessBAProgramTileKeys)
            }
            // MA
            else if(degreeType === "masters"){
                setProgramKeyArray(businessMAProgramTileKeys)
            }
            // Certs
            else if(degreeType === "certificates"){
                setProgramKeyArray(businessCEProgramTileKeys)

            }
            // No Degree Type Specified
            else{
                setProgramKeyArray([...businessBAProgramTileKeys, ...businessMAProgramTileKeys, ...businessCEProgramTileKeys])

            }
        }
        // IT
        else if(program === "it"){
            // BA
            if(degreeType === "bachelors"){
                setProgramKeyArray(itBAProgramTileKeys)
            }
            // MA
            else if(degreeType === "masters"){
                setProgramKeyArray(itMAProgramTileKeys)

            }
            // Certs
            else if(degreeType === "certificates"){
                setProgramKeyArray(itCEProgramTileKeys)
            }
            // No Degree Type Specified
            else{
                setProgramKeyArray([...itBAProgramTileKeys, ...itMAProgramTileKeys, ...itCEProgramTileKeys])
            }
        }
        // Healthcare
        else if(program === "healthcare"){
            // BA
            if(degreeType === "bachelors"){
                setProgramKeyArray(healthcareBAProgramTileKeys)

            }
            // Masters
            if(degreeType === "masters"){
                setProgramKeyArray(healthcareMAProgramTileKeys)

            }
            // Certs
            else if(degreeType === "certificates"){
                setProgramKeyArray(healthcareCEProgramTileKeys)

            }
            // No Degree Type Specified
            else{
                setProgramKeyArray([...healthcareBAProgramTileKeys, ...healthcareMAProgramTileKeys, ...healthcareCEProgramTileKeys])

            }
        }
        // Education
        else if(program === "education"){
            // BA
            if(degreeType === "bachelors"){
                setProgramKeyArray(educationBAProgramTileKeys)

            }
            // Masters
            if(degreeType === "masters"){
                setProgramKeyArray(educationMAProgramTileKeys)

            }
            // Certs
            else if(degreeType === "certificates"){
                // setProgramKeyArray(educationCEProgramTiles)

            }
            // No Degree Type Specified
            else{
                setProgramKeyArray([...educationBAProgramTileKeys, ...educationMAProgramTileKeys])

            }
        }

        // No Program Specified
        else{
            setProgramKeyArray([...businessBAProgramTileKeys, ...businessMAProgramTileKeys, ...businessCEProgramTileKeys, ...itBAProgramTileKeys, ...itMAProgramTileKeys, ...itCEProgramTileKeys, ...healthcareBAProgramTileKeys, ...healthcareMAProgramTileKeys, ...healthcareCEProgramTileKeys, ...educationBAProgramTileKeys, ...educationMAProgramTileKeys])

        }

    }
    const [bannerTitle, setBannerTitle] = useState("The Bachelor's Degree in Business Designed to Help You Stand Out")
    function setBannerTitleFunction(){
        // Business
        if(program === "business"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelors's Degrees in Business Administration Designed to Help You Stand Out")
                
            }
            // MA
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Business Administration Designed to Help You Stand Out")
                
            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Business Administration Certificates Designed to Help You Stand Out")
                
            }
            // No Type Specified
            else{
                setBannerTitle("The Business Administration Programs Designed to Help You Stand Out")
            }
        }
        // IT
        else if(program === "it"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Information Technology Designed to Help You Stand Out")

            }
            // MA
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Information Technology Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Information Technology Certificates Designed to Help You Stand Out")

            }
            // BA
            else{
                setBannerTitle("The Information Technology Programs Designed to Help You Stand Out")
            }
        }
        // Education
        else if(program === "education"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Education Designed to Help You Stand Out")

            }
            // Masters
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Education Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Education Certificates Designed to Help You Stand Out")

            }
            // No Type Specified
            else{
                setBannerTitle("The Education Programs Designed to Help You Stand Out")
            }
        }
        else if(program === "healthcare"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Healthcare Designed to Help You Stand Out")

            }
            // Masters
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Healthcare Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Healthcare Certificates Designed to Help You Stand Out")

            }
            // No Type Specified
            else{
                setBannerTitle("The Healthcare Programs Designed to Help You Stand Out")
            }
        }
        // No Program Specified
        else{
            setBannerTitle("Degrees & Certificates Designed to Help You Stand Out")
        }
    }
    function faqContent(){
        // Business Q & A
        if(program === "business")
            return(<>
                <BusinessFAQ></BusinessFAQ>
            </>)
        // Information Technology Q & A
        else if (program === "it")
            return(<>
                <ITFAQ></ITFAQ>
            </>)
        // Education Q & A
        else if (program === "education")
            return(<>
                <EducationFAQ></EducationFAQ>
            </>)
        // Healthcare Q & A
        else if (program === "healthcare")
            return(<>
                <HealthcareFAQ></HealthcareFAQ>
            </>)
        // If not program is specified show all
        else
            return(<>
                <BusinessFAQ></BusinessFAQ>
                <ITFAQ></ITFAQ>
                <HealthcareFAQ></HealthcareFAQ>
                <EducationFAQ></EducationFAQ>
            </>)
    }
    const [bannerUrl, setBannerUrl] = useState()
    function loadBanner(){  
        setBannerUrl(programBannerURL())
    }

    // #endregion initial values

    // ================================================================================
    // #region Filter
    
    const [filteredProgramTileKeys, setFilteredProgramTileKeys] = useState([])
    useEffect(()=>{
        filterProgramTiles()
    },[programKeyArray])
    function filterProgramTiles(){
        let searchTerm = document.getElementById("searchInput")?.value?.toLowerCase()
        let tempFilteredTiles = []
        programKeyArray.forEach(programKey => {

            let programTileData = combinedProgramData[programKey]

            if(programTileData?.name?.toLowerCase()?.includes(searchTerm))
                tempFilteredTiles.push(programKey)
        })
        setFilteredProgramTileKeys(tempFilteredTiles)
    }
    
    // #endregion Filter

    return (
        <div className='innerPage'>
            
            {/* Banner */}
            
            <div className='programBanner'>
                {/* <img src={"../../"+programBannerURL()} className='programBannerImg'></img> */}
                <img src={bannerUrl} className='programBannerImg'></img>
                <div className='programBannerText'>
                    <div className='programBannerSubtitle'>DEGREES & PROGRAMS</div>
                    <div className='programBannerTitle'>
                        {bannerTitle}
                    </div>
                    <Link to={"/apply"}>
                        <button className='programBannerButton'>Apply Now</button>
                    </Link>
                </div>
            </div>

            {/* Search Bar */}

            <div className='searchBar'>
                <input className='searchBarInput' placeholder='Search Courses (ex: marketing or cybersecurity)' id='searchInput' onChange={filterProgramTiles}></input>
            </div>

            {/* Program Tiles */}

            <div className='programTiles'>
                {filteredProgramTileKeys.map((programKey, index) => (
                   <ProgramTile programKey={programKey} key={index+programKey}></ProgramTile>
                ))}
            </div>

            {/* Stats */}

            <div className='statsArea'>
                <div className='statsTitleArea'>
                    <div className='statsTitle'>
                        Prepared for Success on the Job
                    </div>
                    <div className='statsTitleDisclaimer'>
                        *From a 2022 poll of 200 employers of Whalley graduates.
                    </div>
                </div>
                <div className='statsBox'>
                    <img src={percent97}/>
                    <div className='statsBoxDescription'>
                        97% of employers said that Whalley graduates were prepared for their jobs.*
                    </div>
                </div>
                <div className='statsBox'>
                    <img src={percent95}/>
                    <div className='statsBoxDescription'>
                        95% of employers said that they would hire another Whalley grad.*
                    </div>
                </div>
                <div className='statsBox'>
                    <img src={percent99}/>
                    <div className='statsBoxDescription'>
                        99% of employers said Whalley graduates met or exceeded expectations.*
                    </div>
                </div>
            </div>

            {/* FAQ */}

            <div className='pageInnerWidth faqContainer'>
                {faqContent()}
            </div>

        </div>
    )
}

export default Programs