import React from 'react'
import FAQArea from './FAQArea'
import { Link } from 'react-router-dom'

function EventsFAQ() {
    const faqData = [
      {
          "title": "How can I find out about upcoming university events?",
          "content": "Our university maintains a calendar of events on our official website. You can also sign up for our newsletter, follow our social media channels, or contact the events office directly for the latest updates on upcoming activities."
      },
      {
          "title": "Do I need to register for events in advance?",
          "content": "Yes, we usually require registration for events to manage seating and resources. You can register on our website or by contacting the event organizer listed in the event details."
      },
      {
          "title": "Are there any costs associated with attending university events?",
          "content": "Most of our events are free, especially for students and staff. Some events, however, may have a fee to cover additional resources or materials. Please check the specific event details for information about any costs."
      },
      {
          "title": "How do I know if an event has been cancelled or rescheduled?",
          "content": "We will notify all registered attendees via email if an event is cancelled or rescheduled. Updates will also be posted on the event page and through our university's social media channels."
      },
      {
          "title": "Can I bring guests to university events?",
          "content": "Guests are welcome to many of our events, although some may be restricted to university staff and students only. Please check the event details for information on guest policies."
      },
      {
          "title": "Are university events accessible for individuals with disabilities?",
          "content": "We strive to make all events accessible. Please contact the event organizer in advance if you require special accommodations."
      },
      {
          "title": "What should I do if I have dietary restrictions for event catering?",
          "content": "If an event includes catering, we try to accommodate dietary restrictions. Please provide details of your requirements during the registration process or contact the event organizer directly."
      },
      {
          "title": "Where can I find parking for the event?",
          "content": "Parking information is typically provided on the event page. We often have designated parking for event attendees, and some events may offer valet services or special parking passes."
      },
      {
          "title": "Is photography or recording allowed during events?",
          "content": "Photography and recording policies vary by event. Some events may allow it freely, while others may have restrictions. Always check the event guidelines or ask an event organizer for permission."
      },
      {
          "title": "Who can I contact for more information about an event?",
          "content": "Each event page includes contact information for the event organizer or the university's events office. They can provide detailed information and answer any further questions you may have."
      }
  ]

  return (
    <>
      <FAQArea 
        title={"Events Facts and Questions"}
        faqData={faqData} 
      ></FAQArea>
    </>
  )
}

export default EventsFAQ