import React, { useEffect } from 'react'
import bannerUrl from "../../Images/campusLibrary.webp"
import { Link, useFetcher } from 'react-router-dom'
import "./Tuition.css"
import TuitionBox from './TuitionBox'
import CollapasableRow from '../../Components/CollapasableRow'
import Chart from '../../Components/Charts/Chart'
import { commaNumber, numberToCostPounds, scrollTop } from '../../Global/Functions'
import { tuitionCosts } from '../../Data/TuitionCosts'

function Tuition() {

    useEffect(()=>{
        scrollTop()
    },[])

  return (
    <div className=' page'>

        {/* Banner */}
            
        <div className='programBanner'>
            {/* <img src={"../../"+programBannerURL()} className='programBannerImg'></img> */}
            <img src={bannerUrl} className='programBannerImg'></img>
            <div className='programBannerText '>
                <div className='programBannerSubtitle goldBackground'>TUITION & FINANCIAL AID</div>
                <div className='programBannerTitle goldBackground'>
                    {"Learn More About Whalley Tuition & Financial Aid "}
                </div>
                {/* <Link to={"/apply"}>
                    <button className='programBannerButton'>Apply Now</button>
                </Link> */}
            </div>
        </div>

        <div className='pageInnerWidth'>

            {/* Page Title */}
            <div className='pageTitle left'>What is the cost to attend Whalley?</div>
            
            {/* Top info: description and tuition box */}

            <div>

                {/* Description */}

                <div className='width60'>
                    <div className='pageTitle3 left noTopPad'>2023–2024 DIRECT ANNUAL COSTS</div>
                    <div className='left'>
                        <p>
                            Direct costs to attend Whalley include tuition and fees as well as room and board. As an example, if you are a full-time, undergraduate student who is a Florida resident and live on or off-campus, the direct costs are $18,024. This is based on 28 credit hours, which is the average enrolled by students each academic year.
                        </p>
                        <p>
                            Indirect costs include books and supplies, transportation, and personal expenses. Your indirect costs may vary.
                        </p>
                    </div>
                </div>

                {/* Tuition Box */}

                <div className='width40'>
                    <TuitionBox></TuitionBox>
                </div>

            </div>

            {/* Cost breakdown area */}

            <div className='pageTitle3 left'>Let’s Break It Down</div>
            <div className='left'>
                <p>
                    The estimated cost of attendance has been developed as a guide to help students anticipate their costs at Whalley. The cost of attendance is different for undergraduate and graduate students, and within these two categories, the costs vary depending on where the student lives and their indirect consts. The Office of Student Financial Assistance uses these costs to develop standard student budgets for awarding financial aid funds.
                </p>
            </div>
            <CollapasableRow
                title="Undergraduate Students"
            >   
                <Chart
                    rowData={[
                        [
                            {
                                content: "Location",
                                bold: true,
                                // width: "200px",
                            },
                            {
                                content: "On Campus",
                                bold: true,
                                // width: "calc(((100% - 200px) / 7)",
                            },
                            {
                                content: "Off Campus",
                                bold: true
                            },
                            {
                                content: "Parent / Relative",
                                bold: true
                            },
                        ],
                        [
                            {
                                content: "Tuition & Fees",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition),
                            },
                        ],
                        [
                            {
                                content: "Books",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                        ],
                        [
                            {
                                content: "Housing",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housing),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingRelative),
                            },
                        ],
                        [
                            {
                                content: "Food",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.food),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodRelative),
                            },
                        ],
                        [
                            {
                                content: "Transportation",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportation),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                        ],
                        [
                            {
                                content: "Personal Expenses",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                        ],
                        [
                            {
                                content: "Total Costs",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition + tuitionCosts.base.books + tuitionCosts.base.housing + tuitionCosts.base.food + tuitionCosts.base.transportation + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition + tuitionCosts.base.books + tuitionCosts.base.housingOffCampus + tuitionCosts.base.foodOffCampus + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition + tuitionCosts.base.books + tuitionCosts.base.housingRelative + tuitionCosts.base.foodRelative + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                        ],
                        
                    ]}
                ></Chart>
            </CollapasableRow>
            <CollapasableRow
                title="Undergraduate Non-Resident Students"
            >   
                <Chart
                    rowData={[
                        [
                            {
                                content: "Location",
                                bold: true,
                                // width: "200px",
                            },
                            {
                                content: "On Campus",
                                bold: true,
                                // width: "calc(((100% - 200px) / 7)",
                            },
                            {
                                content: "Off Campus",
                                bold: true
                            },
                            {
                                content: "Parent / Relative",
                                bold: true
                            },
                        ],
                        [
                            {
                                content: "Tuition & Fees",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident),
                            },
                        ],
                        [
                            {
                                content: "Books",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                        ],
                        [
                            {
                                content: "Housing",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housing),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingRelative),
                            },
                        ],
                        [
                            {
                                content: "Food",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.food),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodRelative),
                            },
                        ],
                        [
                            {
                                content: "Transportation",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportation),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                        ],
                        [
                            {
                                content: "Personal Expenses",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                        ],
                        [
                            {
                                content: "Total Costs",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident) + tuitionCosts.base.books + tuitionCosts.base.housing + tuitionCosts.base.food + tuitionCosts.base.transportation + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition  * tuitionCosts.multipliers.nonresident) + tuitionCosts.base.books + tuitionCosts.base.housingOffCampus + tuitionCosts.base.foodOffCampus + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition  * tuitionCosts.multipliers.nonresident) + tuitionCosts.base.books + tuitionCosts.base.housingRelative + tuitionCosts.base.foodRelative + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                        ],
                        
                    ]}
                ></Chart>
            </CollapasableRow>
            <CollapasableRow
                title="Graduate Students"
            >   
                <Chart
                    rowData={[
                        [
                            {
                                content: "Location",
                                bold: true,
                                // width: "200px",
                            },
                            {
                                content: "On Campus",
                                bold: true,
                                // width: "calc(((100% - 200px) / 7)",
                            },
                            {
                                content: "Off Campus",
                                bold: true
                            },
                            {
                                content: "Parent / Relative",
                                bold: true
                            },
                        ],
                        [
                            {
                                content: "Tuition & Fees",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.graduate),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.graduate),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.graduate),
                            },
                        ],
                        [
                            {
                                content: "Books",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                        ],
                        [
                            {
                                content: "Housing",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housing),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingRelative),
                            },
                        ],
                        [
                            {
                                content: "Food",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.food),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodRelative),
                            },
                        ],
                        [
                            {
                                content: "Transportation",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportation),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                        ],
                        [
                            {
                                content: "Personal Expenses",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                        ],
                        [
                            {
                                content: "Total Costs",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition * tuitionCosts.multipliers.graduate) + tuitionCosts.base.books + tuitionCosts.base.housing + tuitionCosts.base.food + tuitionCosts.base.transportation + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition  * tuitionCosts.multipliers.graduate) + tuitionCosts.base.books + tuitionCosts.base.housingOffCampus + tuitionCosts.base.foodOffCampus + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition  * tuitionCosts.multipliers.graduate) + tuitionCosts.base.books + tuitionCosts.base.housingRelative + tuitionCosts.base.foodRelative + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                        ],
                        
                    ]}
                ></Chart>
            </CollapasableRow>
            <CollapasableRow
                title="Graduate Non-Resident Students"
            >   
                <Chart
                    rowData={[
                        [
                            {
                                content: "Location",
                                bold: true,
                                // width: "200px",
                            },
                            {
                                content: "On Campus",
                                bold: true,
                                // width: "calc(((100% - 200px) / 7)",
                            },
                            {
                                content: "Off Campus",
                                bold: true
                            },
                            {
                                content: "Parent / Relative",
                                bold: true
                            },
                        ],
                        [
                            {
                                content: "Tuition & Fees",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident * tuitionCosts.multipliers.graduate),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident * tuitionCosts.multipliers.graduate),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident * tuitionCosts.multipliers.graduate),
                            },
                        ],
                        [
                            {
                                content: "Books",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.books),
                            },
                        ],
                        [
                            {
                                content: "Housing",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housing),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.housingRelative),
                            },
                        ],
                        [
                            {
                                content: "Food",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.food),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.foodRelative),
                            },
                        ],
                        [
                            {
                                content: "Transportation",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportation),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.transportationOffCampus),
                            },
                        ],
                        [
                            {
                                content: "Personal Expenses",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds(tuitionCosts.base.personal),
                            },
                        ],
                        [
                            {
                                content: "Total Costs",
                                bold: true,
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition * tuitionCosts.multipliers.nonresident * tuitionCosts.multipliers.graduate) + tuitionCosts.base.books + tuitionCosts.base.housing + tuitionCosts.base.food + tuitionCosts.base.transportation + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition  * tuitionCosts.multipliers.nonresident * tuitionCosts.multipliers.graduate) + tuitionCosts.base.books + tuitionCosts.base.housingOffCampus + tuitionCosts.base.foodOffCampus + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                            {
                                content: numberToCostPounds((tuitionCosts.base.tuition  * tuitionCosts.multipliers.nonresident * tuitionCosts.multipliers.graduate) + tuitionCosts.base.books + tuitionCosts.base.housingRelative + tuitionCosts.base.foodRelative + tuitionCosts.base.transportationOffCampus + tuitionCosts.base.personal),
                            },
                        ],
                        
                    ]}
                ></Chart>
            </CollapasableRow>
        </div>
    </div>
    )
}

export default Tuition