import React from 'react'
import FAQLine from './FAQLine'
import "./FAQ.css"

function FAQArea({faqData, title}) {
  return (
    <div className='faqArea'>
        {title && <div className='pageTitle2 pageTitleUnderline left faqTitle'>{title}</div>}
        {faqData.map((faq, index) => (
          <FAQLine
            title={faq.title}
            content={faq.content}
            center={faq.center}
            key={faq.title+""+index}
          ></FAQLine>
        ))}
    </div>
  )
}
FAQArea.defaultProps = {
  faqData: [],
}
export default FAQArea