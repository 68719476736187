import React from 'react'
import "./Charts.css"

function Chart({title, rowData}) {
  return (
    <div className='chartOuter'>
        {title && <div className='chartTitle'>{title}</div>}
        <div className='chart'>
            {rowData.map(row => (
                <div className='chartRow'>
                    {row.map(chartItemData => (
                        <div className={"rowItem "+(chartItemData.bold?"rowItemBold":"")} style={{width: (chartItemData.width || ((100 / row.length)+"%"))}}>{chartItemData.content}</div>
                    ))}
                </div>
            ))}
        </div>
    </div>
  )
}

Chart.defaultProps = {
    rowData: [
        [
            {
                content: "Location",
                bold: true,
                // width: "200px",
            },
            {
                content: "On Campus",
                bold: true,
                // width: "calc(((100% - 200px) / 7)",
            },
            {
                content: "Off Campus",
                bold: true
            },
            {
                content: "Parent / Relative",
                bold: true
            },
        ],
        [
            {
                content: "Tuition & Fees",
                bold: true,
            },
            {
                content: 123.45,
            },
            {
                content: 123.45,
            },
            {
                content: 123.45,
            },
        ],
        
    ], 

}

export default Chart