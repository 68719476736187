import React, { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { allCoursesObject, allCoursesWithDescriptions } from '../../Data/ProgramData'

function CourseRow({courseID, left}) {
    const [courseData, setCourseData] = useState()
    const [open, setOpen] = useState()

    useEffect(()=>{
        getCourseData()
    },[courseID])
    function getCourseData(){
        setCourseData(allCoursesWithDescriptions[courseID])
    }
    
    return (
        <>
            {courseData?.CUs && 
                <div className={'courseRow courseRowLeft '+(left? "courseRowLeft":"")} onClick={(e)=>{e.stopPropagation(); setOpen(!open)}} title={open?"Close Course":"Expand Course"}>
                    <div className='courseRowTitle'>
                        <div>
                            {(open?" - ":"+ ") + courseData?.name + " ("+courseID+")"}
                        </div>
                        <div>
                            {"Credits: " + courseData?.CUs}
                        </div>
                    </div>
                    <div className={'courseRowTitleDescription '+(open ? "courseRowTitleDescriptionOpen":"")}>
                        {courseData?.description}
                    </div>
                </div>
            }
        </>
    )
}

export default CourseRow