import React from 'react'
import logo from "../Images/Whalley-Text-Logo.png"
import { Link } from 'react-router-dom'
import { scrollTop } from '../Global/Functions'

function BottomNav() {
  return (
    <div className='bottomNav'>
        <div className='bottomNavColumn bottomNavLogoColumn'>
            <Link to={"/"} title="Whalley University Home" onClick={scrollTop}>
                <img src={logo} className='logo'></img>
            </Link>
        </div>
        <div className='bottomNavColumn'>
            <div className='bottomNavColumnTitle'>Information</div>
            <div className='bottomNavColumnLinks'>
                <Link to={"/programs"}>Programs</Link>
                <Link to={"/faculty-research"}>Research</Link>
                <Link to={"/tuition"}>Tuition & Financial Aid</Link>
                <Link to={"/events"}>Events</Link>
                {/* <Link>Admissions</Link> */}
            </div>
        </div>
        <div className='bottomNavColumn'>
            <div className='bottomNavColumnTitle'>Access</div>
            <div className='bottomNavColumnLinks'>
                <Link to={"/student-portal"}>Student Portal</Link>
                <Link to={"/apply"}>Apply</Link>
                {/* <Link>Careers</Link> */}
                <a href='emailto:info@Whalley.education?subject=Whalley Contact Link'>info@Whalley.education</a>
                <a href='tel:+1-978-346-0056'>+41 405 503 3048</a>
            </div>
        </div>
        
    </div>
  )
}

export default BottomNav