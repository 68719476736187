export const tuitionCosts = {
    base: {
        tuition: 5954,
        housing: 7370,
        housingOffCampus: 8250,
        housingRelative: 3550,
        food: 4700,
        foodOffCampus: 5500,
        foodRelative: 2800,
        books: 1000,
        transportation: 500,
        transportationOffCampus: 3126,
        personal: 3104,

    }, 
    multipliers: {
        undergrad: 1,
        graduate: 1.1,
        resident: 1,
        nonresident: 4,
    }
}