import React from 'react'
import FAQArea from './FAQArea'

function ITFAQ() {
    const faqData = [
        {
          "title": "What jobs can you get with an IT degree?",
          "content": "There are a wide variety of jobs you can get with an IT degree. Some of the most popular are:\n\n    + Network engineer\n    + Software or web developer\n    + Information security or computer systems analyst\n    + Computer programmer\n    + Database or computer systems administrator\n    + IT manager\n    + Cybersecurity expert\n                    "
        },
        {
          "title": "What will I learn in a information technology degree program?",
          "content": "An information technology degree may teach you skills in many areas including programming, data management, and network security. You might also gain experience in project management and information systems management."
        },
        {
          "title": "Is a degree in information technology worth it?",
          "content": "Yes! The global information technology market is expected to reach $5 trillion this year. The Bureau of Labor Statistics projects a 12% growth rate between 2018 and 2028, with some jobs projected to grow as much as 22% from 2020 to 2030. Information technology careers are among the highest paid for entry level employees.\n                    "
        },
        {
          "title": "What kind of information technology program should I enroll in?",
          "content": "There are several different IT programs that will help you get an IT job. Here are some popular IT or computer degrees:\n\nGeneral purpose programs that will allow entry into a variety of roles\n    + Computer Science\n    + Information Technology\n\nPrograms focused on the creative and technical side of software development and scaling of existing systems\n    + Software Engineering\n    + Cloud Computing\n\nPrograms focused on keeping data and systems secure\n    + Cybersecurity and Information Assurance\n    + Network Engineering and Security\n\nPrograms focused on the management and analysis of data with specializations:\n    + Data Management/Data Analytics\n    + Health Information Management\n    + Information Technology Management\n\n                    "
        }
      ]

  return (
    <>
      <FAQArea
        title={"Commonly Asked Questions About Information Technology Degrees"} 
        faqData={faqData}
      ></FAQArea>
    </>
  )
}

export default ITFAQ