import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import EventFAQ from '../../Components/FAQ/EventFAQ'
import FAQArea from '../../Components/FAQ/FAQArea'
import SplitBanner from '../../Components/SplitBanner'
import { eventsData, openEventsData } from '../../DataProcessing/EventsData'
import InputForm from '../../Components/InputForm/InputForm'
import EventTicket from './EventTicket'
import { scrollTop } from '../../Global/Functions'

function Event() {
    // Use this to get data from an events json
    const {eventName} = useParams()

    const [eventData, setEventData] = useState()
    useEffect(()=>{
        getEventData()
    },[eventName])
    function getEventData(){
        let eventData = openEventsData[eventName] || eventsData["default"]
        return setEventData(eventData)
    }

    useEffect(()=>{
        scrollTop()
    },[])
    
    // The data used to generate the ticket information
    const [ticketData, setTicketData] = useState()
    const [loading, setLoading] = useState() 
    function setTicketDataFunction(inputData){
        if(!inputData){
            setTicketData()
            return
        }

        // Event name, event time, event location, ticketholder name, purchase date
        let urlEncodedData = "Ticket-Holder-Name:" + inputData?.firstName+"_"+inputData?.lastName+",Ticket-Holder-Email:"+inputData?.email+",Ticket-Holder-Phone:"+inputData?.phone+",Event-Name: "+eventData.title+",Event-Time:"+inputData.selectedDateTime+",Event-Location:"+eventData.location
        urlEncodedData = encodeURI(urlEncodedData)

        let date = new Date()

        // setLoading(true)
        fetch("http://api.qrserver.com/v1/create-qr-code/?data="+urlEncodedData+"!&size=180x180")
        .then(res =>{
            setLoading(false)

            let newTicketData = {
                ...inputData, 
                name: inputData?.firstName+" "+inputData?.lastName,
                ...eventData,
                purchaseDate: date.toString(),
                qrCodeImage: res.url
            }

            setTicketData(newTicketData)
        })

    }

    return (
        <div className=' '>
            <SplitBanner
                title={eventData?.title2}
                description={eventData?.description}
                image={eventData?.image}
            ></SplitBanner>
            <div className='pageInnerWidth eventDescription'>
                {/* {"eventName: "+eventName} */}
                <div className='pageTitle'>{eventData?.title}</div>
                <div className='eventDescription'>
                    <pre>
                        {eventData?.content}
                    </pre>
                </div>
            </div>
            <div className='upcomingEventsArea pageInnerWidth'>
                <div className='pageTitle pageTitleUnderline'>{"Get Your " + eventData?.title + " Ticket Now"}</div>
                <InputForm 
                    title={"Atendee information"}
                    customFormBoxClass={" ticketFormBox "}
                    inputs={
                        [
                            {
                                label: "First Name",
                                key: "firstName",
                                type: "text", // Default
                                class: "inputHalf", // Default
                                required: true,
                            },
                            {
                                label: "Last Name",
                                key: "lastName",
                                type: "text", // Default
                                class: "inputHalf", // Default
                                required: true,
                            },
                            {
                                label: "Phone",
                                key: "phone",
                                type: "text",
                                // The width of the input element
                                class: "inputHalf",
                                // Submit will not work and error message will show if this is rquired and user does no provide an input
                                required: true,
                                // Custom message that will show if the input does not meet requirements
                                inputAlert: "Please check phone input. (minimum 7 digits)"
                            },
                            {
                                label: "Email",
                                key: "email",
                                type: "text",
                                class: "inputHalf",
                                required: true,
                            },
                            {
                                label: "Select",
                                key: "selectedDateTime",
                                type: "select",
                                class: "inputFull",
                                required: true,
                                options: eventData?.dateTimes
                            },
                        ]
                    }
                    submitAction={setTicketDataFunction}
                    showOnSubmitElement={<EventTicket ticketData={ticketData}></EventTicket>}
                ></InputForm>
            </div>
            <div className='pageInnerWidth faqContainer'>
                <div className='pageTitle pageTitleUnderline'>{"Facts & Questions"}</div>
                <FAQArea faqData={eventData?.customFAQ}></FAQArea>
                <EventFAQ></EventFAQ>
            </div>
        </div>
    )
}

export default Event