import React from 'react'
import CourseRow from './CourseRow'
import CollapasableRow from '../../Components/CollapasableRow'

function CourseOverviewRow({courses, term, title}) {

    const yearCourses = [
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI, This is a test course used to show the UI, This is a test course used to show the UI, This is a test course used to show the UI, This is a test course used to show the UI'
        },
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI'
        },
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI'
        },
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI'
        },
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI'
        },
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI, This is a test course used to show the UI, This is a test course used to show the UI, This is a test course used to show the UI, This is a test course used to show the UI'
        },
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI'
        },
        {
            name: "Intro to Stuff",
            CUs: 3,
            description: 'This is a test course used to show the UI'
        },
    ]

  return (
    <div>
        <CollapasableRow
            title={title}
        >
            {yearCourses.map((courseData, index) => (
                <CourseRow courseData={courseData} left={(index % 2) == 0}></CourseRow>
            ))}
        </CollapasableRow>
    </div>
  )
}

export default CourseOverviewRow