import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { tuitionCosts } from '../../Data/TuitionCosts'
import { commaNumber, numberToCostPounds } from '../../Global/Functions'

function TuitionBox({showLink}) {

    const [tuitionLevelOption, setTuitionLevelOption] = useState("undergrad")
    const [tuitionResidentOption, setTuitionResidentOption] = useState("resident")

    const [numbers, setNumbers] = useState(tuitionCosts.base)
    function totalAmount(){
        let tempTotal = 0
        tempTotal += tuitionCosts.base.tuition
        tempTotal += tuitionCosts.base.food
        tempTotal += tuitionCosts.base.housing
        return tempTotal
        Object.entries(numbers).forEach(([key, value]) => {
            tempTotal += value
        })
        return tempTotal
    }

    function tuitionButtonClick(type, optionName){
        if(type === "level"){
            setTuitionLevelOption(optionName)
            setNumbers(calculateNewNumbers(optionName, tuitionResidentOption))
        }
        if(type === "resident"){
            setTuitionResidentOption(optionName)
            setNumbers(calculateNewNumbers(tuitionLevelOption, optionName))
        }

        // Set the option name for button selection
        // setTuitionLevelOption(optionName)

        // Set numbers for display 

    }
    function calculateNewNumbers(level, resident){
        let newNumbers = {...tuitionCosts.base}

        newNumbers.tuition *= tuitionCosts.multipliers[level]
        newNumbers.tuition *= tuitionCosts.multipliers[resident]
        // Object.entries(newNumbers).forEach(([key, value]) => {
        //     newNumbers[key] *= tuitionCosts.multipliers[level]
        //     newNumbers[key] *= tuitionCosts.multipliers[resident]
        // })

        return(newNumbers)
    }

  return (
    <div className='tuitionBox'>
        <div className=' goldBackground tuitionBoxTop'>
            <div className='tuitionBoxTitleArea'>
                <div className='titleFont'>
                    DIRECT ANNUAL COSTS
                </div>
                {showLink && 
                    
                    <Link to={"/tuition"}><div className='inlineLink'>View Fees & Tuition Page</div></Link>
                }
            </div>
            <div>
                <div>
                    <div className='flexRow tuitionButtonsRow'>
                        <div 
                            className={(tuitionLevelOption === "undergrad") ? ' tuitionButtonSelected':""} 
                            onClick={()=>tuitionButtonClick("level", "undergrad")}
                        >
                            Under-Grad
                        </div>
                        <div 
                            className={(tuitionLevelOption === "graduate") ? ' tuitionButtonSelected':""} 
                            onClick={()=>tuitionButtonClick("level", "graduate")}
                        >
                            Graduate
                        </div>
                    </div>
                    <div className='flexRow tuitionButtonsRow'>
                        <div 
                            className={(tuitionResidentOption === "resident") ? ' tuitionButtonSelected':""} 
                            onClick={()=>tuitionButtonClick("resident", "resident")}
                        >
                            Resident
                        </div>
                        <div 
                            className={(tuitionResidentOption === "nonresident") ? ' tuitionButtonSelected':""} 
                            onClick={()=>tuitionButtonClick("resident", "nonresident")}
                        >
                            Non-Resident
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='tuitionRatesRows'>
            <div className='flexRow'>
                <div className='boldFont'>Tuition & Fees</div>
                <div>{numberToCostPounds(numbers.tuition)}</div>
            </div>
            <div className='flexRow'>
                <div className='boldFont'>Housing</div>
                <div>{numberToCostPounds(numbers.housing)}</div>                	
            </div>
            <div className='flexRow'>
                <div className='boldFont'>Food</div>
                <div>{numberToCostPounds(numbers.food)}</div>                	
            </div>
            <div className='flexRow topBorder'>
                <div className='boldFont'>Total</div>
                <div>{numberToCostPounds(totalAmount())}</div>                	
            </div>
        </div>
    </div>
  )
}

export default TuitionBox