import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import "./Program.css"
import { programBannerURL, scrollTop } from '../../Global/Functions'
import "./ProgramDetail.css"
import "./CourseOverview.css"
import calendarIcon from "../../Images/calendar-icon.png"
import guyPointing from "../../Images/guyPointing.webp"
import twelvePercent from "../../Images/twelvePercent.webp"
import oneHundredK from "../../Images/oneHundredK.webp"
import WhalleyApplicationLaptop from "../../Images/Whalley-Application-Laptop.jpg"
import CourseOverviewRow from './CourseOverviewRow'
import TuitionBox from '../Tuition/TuitionBox'
import { allCoursesObject, combinedProgramData } from '../../Data/ProgramData'
import CollapasableRow from '../../Components/CollapasableRow'
import CourseRow from './CourseRow'

function ProgramDetail() {

    const {program, degreeType} = useParams()
    const {programKey} = useParams()

    const [programData, setProgramData] = useState({})
    useEffect(()=>{
        getProgramData()
    },[programKey])
    function getProgramData(){
        setProgramData(combinedProgramData[programKey])
    }

    useEffect(()=>{
        loadBanner()
        setBannerTitleFunction()
        scrollTop()
    },[])

    const [bannerUrl, setBannerUrl] = useState()
    function loadBanner(){  
        setBannerUrl(programBannerURL())
    }
    const [bannerTitle, setBannerTitle] = useState("The Bachelor's Degree in Business Designed to Help You Stand Out")
    function setBannerTitleFunction(){
        // Business
        if(program === "business"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelors's Degrees in Business Administration Designed to Help You Stand Out")
                
            }
            // MA
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Business Administration Designed to Help You Stand Out")
                
            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Business Administration Certificates Designed to Help You Stand Out")
                
            }
            // No Type Specified
            else{
                setBannerTitle("The Business Administration Programs Designed to Help You Stand Out")
            }
        }
        // IT
        else if(program === "it"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Information Technology Designed to Help You Stand Out")

            }
            // MA
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Information Technology Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Information Technology Certificates Designed to Help You Stand Out")

            }
            // BA
            else{
                setBannerTitle("The Information Technology Programs Designed to Help You Stand Out")
            }
        }
        // Education
        else if(program === "education"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Education Designed to Help You Stand Out")

            }
            // Masters
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Education Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Education Certificates Designed to Help You Stand Out")

            }
            // No Type Specified
            else{
                setBannerTitle("The Education Programs Designed to Help You Stand Out")
            }
        }
        else if(program === "healthcare"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Healthcare Designed to Help You Stand Out")

            }
            // Masters
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Healthcare Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Healthcare Certificates Designed to Help You Stand Out")

            }
            // No Type Specified
            else{
                setBannerTitle("The Healthcare Programs Designed to Help You Stand Out")
            }
        }
        // No Program Specified
        else{
            setBannerTitle("Degrees & Certificates Designed to Help You Stand Out")
        }
    }

    function degreeLevelDisplay(degreeLevel){
        if(degreeLevel === "masters")
            return "Masters"
        else if(degreeLevel === "bachelors")
            return "Bachelors"
        else if(degreeLevel === "certificate")
            return "Certificate"
        else return degreeLevel
    }
    function collegeTypeArrayDisplay(collegeTypes){
        if(!collegeTypes) return ""

        if(typeof collegeTypes === "string")
            return collegeTypeDisplay(collegeTypes)
    
        let returnString = ""
        collegeTypes.forEach(collegeType => {
            returnString += collegeTypeDisplay(collegeType)+", "
        })
        // Remove the last ", "
        returnString = returnString.substring(0, returnString.length-2)

        return returnString 
    }
    function collegeTypeDisplay(collegeType){
        if(collegeType === "business")
            return "Business"
        else if(collegeType === "it")
            return "Information Technology"
        else if(collegeType === "education")
            return "Education"
        else if(collegeType === "healthcare")
            return "Healthcare"
        else return collegeType
    }
    function jobGrowthDisplay(){
        if(!Array.isArray(programData.programTypes)) return

        /*

            The Bureau of Labor Statistics predicts that computer science employment will grow 15% from 2019 to 2029, which is a rapid rate of growth.
        
        */

        if(!Array.isArray(programData.programTypes)) 
            return (
                <div>no program type</div>
             )
        if(programData.programTypes.includes("it")){
            return (
                <div className='hilightRow'>
                    <img className='hilightRowImg' src={twelvePercent}></img>
                    <div className='highlightRowText'>The job outlook for computer science grads is expected to grow 12% over the next decade.</div>
                </div>
            )
        }
        if(programData.programTypes.includes("business")){
            return (
                <div className='hilightRow'>
                    <img className='hilightRowImg' src={twelvePercent}></img>
                    <div className='highlightRowText'>The job outlook for computer science grads is expected to grow 12% over the next decade.</div>
                </div>
            )
        }
        return
        if(programData.programTypes[0] === "it")
            return (
                <div className='hilightRow'>
                    <img className='hilightRowImg' src={twelvePercent}></img>
                    <div className='highlightRowText'>The job outlook for computer science grads is expected to grow 12% over the next decade.</div>
                </div>
            )
    }

  return (
    <>
        {/* Banner */}
        
        <div className='programBanner'>
            {/* <img src={"../../"+programBannerURL()} className='programBannerImg'></img> */}
            <img src={bannerUrl} className='programBannerImg'></img>
            <div className='programBannerText'>
                <div className='programBannerSubtitle'>PROGRAMS DETAILS</div>
                <div className='programBannerTitle'>
                    {programData?.name}
                </div>
                <Link to={"/apply"}>
                    <button className='programBannerButton'>Apply Now</button>
                </Link>
            </div>
        </div>
        
        <div className='pageInnerWidth'>
            
            {/* At A Glance */}
            
            <div className='atAGlanceSection'>
                <div className=''>
                    <div className='width60 atAGlance'>
                        <div className='atAGlanceTitle'>PROGRAM AT A GLANCE</div>
                        <div className='glanceItem'>
                            <div className='glanceItemGrey'>
                                PROGRAM
                            </div>
                            <div className='glanceItemText'>
                                {degreeLevelDisplay(programData?.degreeLevel)}
                            </div>
                        </div>
                        <div className='glanceItem'>
                            <div className='glanceItemGrey'>
                                COLLEGE(S)
                            </div>
                            <div className='glanceItemText'>
                                {collegeTypeArrayDisplay(programData?.programTypes)}
                            </div>
                        </div>
                        <div className='glanceItem'>
                            <div className='glanceItemGrey'>
                                PROGRAM CODE
                            </div>
                            <div className='glanceItemText'>
                                {programKey}
                            </div>
                        </div>
                        {/* <div className='glanceItem'>
                            <div className='glanceItemGrey'>
                                DEPARTMENT(S)
                            </div>
                            <div className='glanceItemText'>
                                Computer Science
                            </div>
                        </div> */}

                        {/* <div className='calendarBox'>
                            <img src={calendarIcon}></img>
                            <div className='calendarBoxNumber'>4</div>
                            <div className='calendarBoxText'>Years</div>
                        </div> */}
                    </div>

                    <div className='width40'>
                        <TuitionBox showLink></TuitionBox>
                    </div>
                </div>

            </div>

             {/* Dscription */}

            <div className='descriptionSection'>
                <div className=' textAlignLeft'>
                    <div className='descriptionLeft'>
                        <div className='descriptionLeftTitle'>
                            {programData?.detailInformation?.title}
                            {/* Combine Engineering, Science and Math to Create Software Solutions to Real-World Issues */}
                        </div>
                        <div className='descriptionLeftText'>
                            <pre>
                                {programData?.detailInformation?.description}
                            </pre>
                        </div>
                    </div>
                    <div className='descriptionRight'>
                        <div className='descriptionRightInner'>
                            {/* <img src={guyPointing} className='descriptionRightImg'></img> */}
                            <div className='descriptionRightText'>
                                <div className='descriptionRightTitle'>HIGHLIGHTS</div>
                                {jobGrowthDisplay()}
                                {/* <div className='hilightRow'>
                                    <img className='hilightRowImg' src={twelvePercent}></img>
                                    <div className='highlightRowText'>The job outlook for computer science grads is expected to grow 12% over the next decade.</div>
                                </div> */}
                                <div className='hilightRow'>
                                    <img className='hilightRowImg'src={oneHundredK}></img>
                                    <div className='highlightRowText'>The average annual salary of computer science grads is $103,000.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  Course Overview */}
            <>
                {(programData?.courses?.term1 || programData?.courses?.term2|| programData?.courses?.term3) &&
                    <div className='courseOverviewSection'>
                        <div className='courseOverviewTitle'>COURSE OVERVIEW</div>
                        <div>
                            {(programData?.courses?.term1 || programData?.courses?.term2|| programData?.courses?.term3) &&
                                <CollapasableRow title="Year 1">
                                    {programData?.courses?.term1 &&
                                        <div>
                                            <div className='boldUnderline'>Spring</div>
                                            <div>
                                                {programData?.courses?.term1?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                { programData?.courses?.term2 &&
                                    <div>
                                            <div className='boldUnderline'>Summer</div>
                                            <div>
                                                {programData?.courses?.term2?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {programData?.courses?.term3 &&
                                        <div>
                                            <div className='boldUnderline'>Fall</div>
                                            <div>
                                                {programData?.courses?.term3?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </CollapasableRow>
                            }
                            {(programData?.courses?.term4 || programData?.courses?.term5|| programData?.courses?.term6) &&
                                <CollapasableRow title="Year 2">
                                    {programData?.courses?.term4 &&
                                        <div>
                                            <div className='boldUnderline'>Spring</div>
                                            <div>
                                                {programData?.courses?.term4?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                { programData?.courses?.term5 &&
                                    <div>
                                            <div className='boldUnderline'>Summer</div>
                                            <div>
                                                {programData?.courses?.term5?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {programData?.courses?.term6 &&
                                        <div>
                                            <div className='boldUnderline'>Fall</div>
                                            <div>
                                                {programData?.courses?.term6?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </CollapasableRow>
                            }
                            {(programData?.courses?.term7 || programData?.courses?.term8|| programData?.courses?.term9) &&
                                <CollapasableRow title="Year 3">
                                    {programData?.courses?.term7 &&
                                        <div>
                                            <div className='boldUnderline'>Spring</div>
                                            <div>
                                                {programData?.courses?.term7?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                { programData?.courses?.term8 &&
                                    <div>
                                            <div className='boldUnderline'>Summer</div>
                                            <div>
                                                {programData?.courses?.term8?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {programData?.courses?.term9 &&
                                        <div>
                                            <div className='boldUnderline'>Fall</div>
                                            <div>
                                                {programData?.courses?.term9?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </CollapasableRow>
                            }
                            {(programData?.courses?.term10 || programData?.courses?.term11|| programData?.courses?.term12) &&
                                <CollapasableRow title="Year 4">
                                    {programData?.courses?.term10 &&
                                        <div>
                                            <div className='boldUnderline'>Spring</div>
                                            <div>
                                                {programData?.courses?.term10?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                {programData?.courses?.term11 &&
                                        <div>
                                            <div className='boldUnderline'>Summer</div>
                                            <div>
                                                {programData?.courses?.term11?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {programData?.courses?.term12 &&
                                        <div>
                                            <div className='boldUnderline'>Fall</div>
                                            <div>
                                                {programData?.courses?.term12?.map((courseID, index) => (
                                                    <CourseRow courseID={courseID} left={(index % 2) == 0}></CourseRow>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </CollapasableRow>
                            }
                            {/* <CourseOverviewRow title={"Year 4"}></CourseOverviewRow>
                            <CourseOverviewRow title={"Year 3"}></CourseOverviewRow>
                            <CourseOverviewRow title={"Year 2"}></CourseOverviewRow>
                            <CourseOverviewRow title={"Year 1"}></CourseOverviewRow> */}
                        </div>
                        <div className='courseOverviewDisclaimer'>
                            *Many courses have alternates and prerequisites and so each students course of study may vary. 
                            Please speak with one of our academic advisors to create an academic plan customized to your needs and interests. 
                        </div>
                    </div>
                }
            </>

        </div>

        {/* Application Deadlines */}

        <div className='applicationDeadlinesSection'>  
                <div className='applicationDeatlineTitle'>APPLICATION DEADLINES</div>
                <div className='programPageWidth'>
                    <div className='applicationDeadlineLeftDates'>
                        <div className='applicationDeadlineLeftDate'>
                            <div className='applicationDeadlineLeftDateTitle'>Fall</div>
                            <div className='applicationDeadlineLeftDateDate'>May 1</div>
                        </div>
                        <div className='applicationDeadlineLeftDate'>
                            <div className='applicationDeadlineLeftDateTitle'>Spring</div>
                            <div className='applicationDeadlineLeftDateDate'>November 1</div>
                        </div>
                        <div className='applicationDeadlineLeftDate'>
                            <div className='applicationDeadlineLeftDateTitle'>Summer</div>
                            <div className='applicationDeadlineLeftDateDate'>March 1</div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Application Section 2 */}

            <div className='applicationSection2'>
                <div className='applicationSection2PageWidth'>
                    <img src={WhalleyApplicationLaptop}></img>
                    <div className='applicationSection2Text'>
                        <div className='applicationSection2Title'>START YOUR APPLICATION TODAY</div>
                        <div className='applicationSection2Title2'>
                            <div>
                                Short on time? 
                            </div>
                            {/* <div>
                                Take the next step to save your spot at Whalley.

                            </div> */}
                            <div className='applicationSection2Title3'>
                                You can start your application today and come back later to finish.
                            </div>
                            <div className='applicationSection2ButtonConteiner'>
                                <Link to={"/apply"}>
                                    <button className='applicationButton2'>Start Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



    </>
  )
}

export default ProgramDetail