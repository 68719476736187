import React, { useState } from 'react'
import "./EventTicket.css"
import jsPDF from 'jspdf';
import * as htmlToImage from "html-to-image"

function EventTicket({ticketData}) {

      function downloadTicket() {
        htmlToImage.toPng(document.getElementById("ticketResult"))
        .then(function (dataUrl) {

            const pdf = new jsPDF();
            pdf.addImage(dataUrl, 'PDF', 25, 20);
            pdf.save("Event Ticket "+ticketData?.title+".pdf");

        });
      }

  return (
    <div className='eventTicketContainer'>
        <div id='ticketResult'>
            <div className='eventTicket' >
                <div className='eventTicketLeft'>
                    <div className='eventTicketEventName'>{ticketData?.title}</div>
                    <div>{ticketData?.selectedDateTime}</div>
                    <div>{ticketData?.location}</div>
                    <div>{"Ticket Holder: "+ticketData?.name}</div>
                    <div className='eventTicketBottomBox'>E-TICKET</div>
                </div>
                <div className='eventTicketCodeBox'>
                    <img src={ticketData?.qrCodeImage}></img>
                </div>
            </div>
            <div>
                <div className='ticketMetaData'>
                    <div className='ticketMetaDataTitle'>Ticket Holder</div>
                    <div>{ticketData?.name}</div>
                </div>
                <div className='ticketMetaData'>
                    <div className='ticketMetaDataTitle'>Purchase Date</div>
                    <div>{ticketData?.purchaseDate}</div>
                </div>
                <div className='ticketDisclaimer'>
                    <div className='ticketDisclaimerTitle'>TERMS & CONDIITONS FOR TICKET HOLDER</div>
                    <div className='ticketDisclaimerText'>This document contains private & confidential information including personal information, personal contact information, and any other information requested by Whalley University, the organizer of this event. The QR Code is secret, you understand that you, the ticket owner / buyer, hold sole responsibility for the confidentiality of this code.</div>
                </div>
            </div>
            <hr></hr>
        </div>
        <div>
            <div className='ticketDisclaimer'>
                <div className='ticketDisclaimerTitle'>*!* Please download or screenshot this ticket. The QR code contains information that will need to be scanned. *!*</div>
            </div>
            <div className='padTop20'>
                <button onClick={downloadTicket}>Download Ticket</button>
            </div>
        </div>
    </div>
  )
}

export default EventTicket