import virtualEvent from "../Images/virtualEvent.png"
import globe from "../Images/globe.jpg"
import applicationWorkshop from "../Images/applicationWorkshopWhalley2.webp"
import welcomeDayImage from "../Images/welcomeDayEdited.jpg"
import openDay from "../Images/eventBaloons.jpeg"
import campusTour from "../Images/campusTour.webp"
import fair from "../Images/techFair.jpg"
import infoSessoin from "../Images/infoSession.png"
import whalleyVirtualTourSphere from "../Images/whalleyVirtualTourSphere3.jpg"

export const openEventsData = {
    "virtual-first-look": {
        link: "/event/virtual-first-look",
        title: "Virtual First Look",
        title2: "Virtual First Look Event",
        image: whalleyVirtualTourSphere,
        dateTimes:[
            "Saturday December 12th 2023, 11:00 AM",
            "Saturday December 19th 2023, 11:00 AM",
            "Saturday December 26th 2023, 11:00 AM",
        ],
        location: "123 main st",
        description: "Take a virtual first look at the University of Whalley. Hear from our enthusiastic students and expert staff on all aspects of student life.",
        content: (
            <>
                <h3>Virtual First Look at the University of Whalley</h3>
                <p>Welcome to the virtual first look at the University of Whalley! Dive into an immersive online experience that brings our vibrant campus to your screen. Hear firsthand from our enthusiastic students and expert staff about the diverse aspects of student life, academic opportunities, and the unique culture that defines our university.</p>
                <p>Our virtual tour is designed to give you a comprehensive overview of the University of Whalley, from the comfort of your home. Explore our academic buildings, student facilities, and lush green spaces through high-quality videos and interactive media. Get a glimpse of the lively atmosphere that our students enjoy every day.</p>
                <p>This is your opportunity to understand what makes the University of Whalley a top choice for students from around the world. Learn about our innovative programs, supportive community, and the endless opportunities available for growth and discovery. Join us on this virtual journey and see why so many students choose to call the University of Whalley their home.</p>

                <h3>Meet Our Community: Students and Staff Insights</h3>
                <p>Hear directly from our community! Our virtual first look includes interviews and discussions with current students and expert staff members. They share their personal experiences, insights into academic life, and tips for making the most out of your time at the University of Whalley.</p>
                <p>Discover the diverse backgrounds and stories of our students, who come from all corners of the globe to study here. Learn about their academic pursuits, extracurricular activities, and how they balance their studies with personal interests. Their stories will give you a real sense of the student experience at our university.</p>
                <p>Our expert staff members also provide valuable information on our academic programs, support services, and campus resources. They highlight the unique aspects of the University of Whalley that help students thrive academically and personally. Whether you're interested in research opportunities, internships, or student support, our staff are here to guide you every step of the way.</p>
            </>
        ),
        customFAQ: [
            {
                title: `Is there parking available?`,
                content: `Parking is available in the many carparks around the University Centre. If you are visiting us for an open day, the carparks are free after 6pm during the weekdays and all day at the weekends. The maximum cost during a weekday is £3.50 for the full day.\n\n`,
            },
        ],
    },
    "open-day": {
        link: "/event/open-day",
        title: "Open Day",
        title2: "Visit Us On Open Day",
        description: "Discover the opportunities awaiting you at the University of Whalley. Our open day is the perfect way to get to know our campus and meet the faculty.",
        content: (
            <>
                <h3>Welcome to University of Whalley Open Day</h3>
                <p>Discover the vibrant campus life at the University of Whalley during our Open Day event! This is your opportunity to get a real feel for our university community, explore our state-of-the-art facilities, and find out more about our diverse range of courses.</p>
                <p>Engage with our passionate staff and students who will share their experiences and insights, offering you a genuine taste of what it's like to study with us. From interactive workshops to informative tours, the Open Day experience is designed to answer all your questions and ignite your excitement for higher education.</p>
                <p>Don't miss out on the chance to attend talks from our leading academics, who will give you an insider's perspective on our cutting-edge research and teaching methodologies. Find out how the University of Whalley can help you achieve your academic and career goals.</p>

                <h3>Programs and Activities</h3>
                <p>Our Open Day is packed with a variety of programs and activities tailored to prospective students. Start your day with our welcome address in the main auditorium, where you'll learn about the history of the University of Whalley and our vision for the future.</p>
                <p>Join our campus tours led by current students for a personal perspective on university life. You'll visit our libraries, laboratories, sports facilities, and student hubs. Along the way, you'll have the opportunity to speak with faculty members and attend subject-specific sessions to dive deeper into the areas you're most interested in.</p>
                <p>Wrap up your visit at our Information Fair, where you can talk to representatives from different departments, student services, and extracurricular clubs and societies. It's a great way to get all the information you need to make an informed decision about your university choice.</p>
            </>
        ),
        dateTimes:[
            "Saturday December 12th 2023, 11:00 AM",
            "Saturday December 19th 2023, 11:00 AM",
            "Saturday December 26th 2023, 11:00 AM",
        ],
        image: openDay,
        customFAQ: [
            {
                title: "Do I need to register to attend the Open Day?",
                content: "We recommend registering to help us plan for the day and ensure you receive all the information you need prior to the event."
            },
            {
                title: "What should I bring to the Open Day?",
                content: "Just bring your curiosity and any questions you have about the university! If you're interested in specific programs, you might want to bring a notepad to jot down important details."
            }
        ],
    },
    "campus-tours": {
        link: "/event/campus-tours",
        title: "Campus Tours",
        title2: "Discover Our Campus",
        description: "Join our guided tours to explore the University of Whalley's campus and get a feel for student life.",
        content: (
            <>
                <h3>Welcome to the University of Whalley</h3>
                <p>Embark on a journey through the vibrant and dynamic University of Whalley campus. Our tours provide a comprehensive glimpse into the life of our students, showcasing our state-of-the-art facilities, sprawling green spaces, and innovative learning environments. Discover why the University of Whalley is a celebrated hub of academic excellence and cultural diversity.</p>
                <p>From historic buildings that blend tradition with modernity to cutting-edge research labs, our campus is a testament to our commitment to fostering a stimulating academic atmosphere. Whether you're an aspiring student, a proud parent, or simply a curious visitor, our tours will offer an enlightening and enjoyable experience.</p>
                <p>Our knowledgeable tour guides, who are current students themselves, will share their personal experiences and insights, giving you a genuine perspective of student life here. Don't miss this opportunity to see firsthand why the University of Whalley is the perfect place to pursue your academic dreams.</p>

                <h3>Explore Academic and Social Spaces</h3>
                <p>Join us as we explore the heart of our academic life at the University of Whalley. Our tour will take you through various faculties, including our renowned science and humanities departments. Witness the interactive classrooms, state-of-the-art laboratories, and extensive libraries that empower our students to excel in their fields.</p>
                <p>But it's not all about academics! The University of Whalley is also a place where lifelong friendships are forged and diverse interests are pursued. Visit our student centers, vibrant cafeterias, and sprawling sports complexes. Experience the energy and enthusiasm that radiate from every corner of our campus.</p>
                <p>Our tours also include a visit to our arts and cultural facilities, where creativity flourishes. From art galleries showcasing student works to theaters where performances come to life, the University of Whalley is a place where talents are nurtured and celebrated. Come and see how our students balance academic rigor with creative expression and social engagement.</p>
            </>
        ),
        dateTimes:[
            "Saturday December 12th 2023, 11:00 AM",
            "Saturday December 19th 2023, 11:00 AM",
            "Saturday December 26th 2023, 11:00 AM",
        ],
        image: campusTour,
        customFAQ: [
            {
            title: "Do I need to sign up for the campus tour?",
            content: "Yes, we encourage you to register in advance to secure your spot. Please visit our website or contact our admissions office to book your tour.",
            },
            {
            title: "What should I bring to the campus tour?",
            content: (
                <>
                    <h3>Welcome to the University of Whalley</h3>
                    <p>Embark on a journey through the vibrant and dynamic University of Whalley campus. Our tours provide a comprehensive glimpse into the life of our students, showcasing our state-of-the-art facilities, sprawling green spaces, and innovative learning environments. Discover why the University of Whalley is a celebrated hub of academic excellence and cultural diversity.</p>
                    <p>From historic buildings that blend tradition with modernity to cutting-edge research labs, our campus is a testament to our commitment to fostering a stimulating academic atmosphere. Whether you're an aspiring student, a proud parent, or simply a curious visitor, our tours will offer an enlightening and enjoyable experience.</p>
                    <p>Our knowledgeable tour guides, who are current students themselves, will share their personal experiences and insights, giving you a genuine perspective of student life here. Don't miss this opportunity to see firsthand why the University of Whalley is the perfect place to pursue your academic dreams.</p>

                    <h3>Explore Academic and Social Spaces</h3>
                    <p>Join us as we explore the heart of our academic life at the University of Whalley. Our tour will take you through various faculties, including our renowned science and humanities departments. Witness the interactive classrooms, state-of-the-art laboratories, and extensive libraries that empower our students to excel in their fields.</p>
                    <p>But it's not all about academics! The University of Whalley is also a place where lifelong friendships are forged and diverse interests are pursued. Visit our student centers, vibrant cafeterias, and sprawling sports complexes. Experience the energy and enthusiasm that radiate from every corner of our campus.</p>
                    <p>Our tours also include a visit to our arts and cultural facilities, where creativity flourishes. From art galleries showcasing student works to theaters where performances come to life, the University of Whalley is a place where talents are nurtured and celebrated. Come and see how our students balance academic rigor with creative expression and social engagement.</p>
                </>
            ),
            }
        ],
    },
    "info-sessions": {
        link: "/event/info-sessions",
        title: "Information Sessions",
        title2: "Get to Know Us",
        description: "Attend our Information Sessions to learn more about the academic programs offered at the University of Whalley.",
        content: (
            <>
                <h3>Information Sessions at the University of Whalley</h3>
                <p>Join us at our Information Sessions to gain in-depth knowledge about the wide range of academic programs offered at the University of Whalley. These sessions are designed to provide prospective students and their families with valuable insights into our curriculum, faculty, and the unique opportunities available in our academic community.</p>
                <p>During these sessions, you will have the chance to meet with professors and current students who can share their experiences and answer your questions. From course structures to research opportunities, and career prospects, our Information Sessions cover all aspects of academic life at the University of Whalley.</p>
                <p>Whether you are interested in the arts, sciences, business, or any other field, our Information Sessions will help you understand what sets the University of Whalley apart. Discover the resources, support systems, and innovative teaching methods that we offer to ensure our students' success.</p>

                <h3>Explore Our Academic Programs</h3>
                <p>Get detailed information about each of our academic programs during these sessions. Learn about the unique aspects of our courses, the hands-on learning experiences we provide, and how our programs are designed to prepare you for the future.</p>
                <p>Our academic advisors and faculty members will be available to discuss individual programs, including admission requirements, course content, and specializations. This is a great opportunity to get a feel for what studying your chosen field will be like at the University of Whalley.</p>
                <p>Additionally, learn about the various extracurricular activities and student organizations that complement our academic programs. These Information Sessions are not just about the academic side of things but also about how you can engage with the university community, develop new skills, and build a well-rounded university experience.</p>

            </>
        ),
        dateTimes:[
            "Saturday December 12th 2023, 11:00 AM",
            "Saturday December 19th 2023, 11:00 AM",
            "Saturday December 26th 2023, 11:00 AM",
        ],
        image: infoSessoin,
        customFAQ: [
            {
                title: "Are Information Sessions specific to each department?",
                content: "Yes, we host sessions for each department. Check our schedule to find the session that aligns with your interests.",
            },
            {
                title: "Can I attend more than one Information Session?",
                content: "You are welcome to attend as many sessions as you find relevant. We recommend registering for each session you plan to attend.",
            }
        ],
    },
    "application-workshop": {
        link: "/event/application-workshop",
        title: "Application Workshop",
        title2: "Your Pathway to Admission",
        description: "Need help with your university application? Our Application Workshop is designed to guide you through the process.",
        content: (
            <>
                <h3>Application Workshop: Navigating Your University Application</h3>
                <p>Preparing your university application can be a daunting task, but our Application Workshop at the University of Whalley is here to guide you through every step. Designed for prospective students, this workshop offers comprehensive support and advice on how to effectively prepare and submit your university application.</p>
                <p>Our expert admissions team will walk you through the application process, highlighting key components such as personal statements, letters of recommendation, and academic transcripts. Gain valuable insights into what admissions committees look for and how to showcase your strengths and achievements.</p>
                <p>We understand that each student's journey is unique. Therefore, our workshop includes personalized guidance to address individual concerns and questions. Whether you're wondering about application deadlines, program-specific requirements, or financial aid, we're here to help you navigate these important decisions.</p>

                <h3>Your Pathway to Admission</h3>
                <p>"Your Pathway to Admission" is not just a workshop—it's your first step towards becoming a student at the University of Whalley. This section of the workshop focuses on helping you understand the admissions criteria and how to align your application with the university's expectations.</p>
                <p>Engage in interactive sessions that cover various aspects of the application, such as choosing the right program, understanding the importance of extracurricular activities, and preparing for any entrance assessments. Our goal is to demystify the application process and make it as straightforward as possible for you.</p>
                <p>Additionally, hear from current students about their application experiences. These firsthand accounts provide real-life insights and tips that can be incredibly valuable as you prepare your own application. Remember, applying to university is the start of an exciting journey, and our workshop is here to ensure you begin on the right foot.</p>
            </>
        ),
        dateTimes:[
            "Saturday December 12th 2023, 11:00 AM",
            "Saturday December 19th 2023, 11:00 AM",
            "Saturday December 26th 2023, 11:00 AM",
        ],
        image: applicationWorkshop,
        customFAQ: [
            {
                title: "What should I prepare before attending the workshop?",
                content: "Having a rough draft of your personal statement and a list of activities or achievements you'd like to include in your application will be beneficial.",
            },
            {
                title: "Will there be individual support available?",
                content: "Yes, our admissions counselors will be available to answer individual questions and provide personalized advice after the general presentation.",
            }
        ],
    },
    "science-and-tech-fair": {
            link: "/event/science-and-tech-fair",
            title: "Science and Technology Fair",
            title2: "Innovation and Discovery",
            description: "Explore the forefront of scientific and technological advancements with our faculty and student innovators.",
            content: (
                <>
                    <h3>Science and Technology Fair at the University of Whalley</h3>
                    <p>Experience the cutting-edge of innovation at the University of Whalley's Science and Technology Fair. This event showcases the latest advancements and research in various scientific and technological fields, brought to you by our distinguished faculty and inventive students. It's a vibrant platform for exploration, learning, and inspiration.</p>
                    <p>Walk through our exhibition halls filled with interactive displays, engaging demonstrations, and groundbreaking projects. These exhibits represent a wide array of disciplines, from robotics and AI to environmental science and biomedical engineering. It's an opportunity to see up close the future of science and technology being shaped by our academic community.</p>
                    <p>Engage in conversations with our student innovators and faculty researchers. Hear about their journey, the challenges they faced, and the breakthroughs they've achieved. Their stories are not just about scientific and technical excellence but also about the passion and perseverance that drive innovation.</p>

                    <h3>Innovation and Discovery</h3>
                    <p>"Innovation and Discovery" is the core theme of our fair. This section is dedicated to showcasing projects that push the boundaries of knowledge and application. Witness firsthand how ideas transform into tangible solutions that can impact society and the environment.</p>
                    <p>Participate in workshops and seminars led by leading experts and guest speakers. These sessions are designed to provide deeper insights into current trends in science and technology, and how they are shaping our world. Whether you're a student, a professional, or just a curious mind, these discussions will open new horizons of understanding and possibilities.</p>
                    <p>Don't miss the chance to connect with potential collaborators, mentors, and industry professionals. The Science and Technology Fair is not just an event; it's a hub of networking and opportunities. It's where curiosity meets expertise, fostering a community that's passionate about advancing the frontiers of science and technology.</p>
                </>
            ),
            dateTimes:[
                "Saturday December 12th 2023, 11:00 AM",
                "Saturday December 19th 2023, 11:00 AM",
                "Saturday December 26th 2023, 11:00 AM",
            ],
            image: fair,
            customFAQ: [
                {
                    title: "What kind of projects will be on display?",
                    content: "You can expect to see a wide range of projects from our engineering, computer science, biology, and chemistry departments, among others.",
                },
                {
                    title: "Can high school students participate in the fair?",
                    content: "Absolutely! High school students interested in science and technology are encouraged to attend and learn more about the potential career paths and academic programs we offer.",
                }
            ],
    },
  }

const unusedOpenEventsData = {
"alumni-networking-day": {
    title: "Alumni Networking Day",
    title2: "Connect with Our Graduates",
    description: "Network with our distinguished alumni and learn how the University of Whalley has propelled their careers.",
    content: `The Alumni Networking Day is a prime opportunity for prospective students to connect with graduates from the University of Whalley. Engage in meaningful conversations with our alumni and discover how their time at Whalley has shaped their professional and personal lives. Gain valuable insights into various industries, receive career advice, and build connections that will support your academic journey and beyond.\n\nDuring the event, you will hear from a panel of alumni speakers who have excelled in their fields, participate in networking sessions, and join interactive workshops designed to equip you with networking skills essential for today's job market.`,
    date: "2024-03-18",
    image: "alumniNetworkingDay.jpg",
    customFAQ: [
        {
        title: "Who can attend the Alumni Networking Day?",
        content: "The event is open to all prospective students who are interested in learning more about the University of Whalley and the long-term benefits of being a part of our alumni network.",
        },
        {
        title: "What should I prepare for the event?",
        content: "Prepare some questions you might have for our alumni, and feel free to bring a copy of your resume for review and feedback during the workshops.",
        }
    ],
},
"arts-and-humanities-day": {
    link: "/event/arts-and-humanities-day",
    title: "Arts and Humanities Day",
    title2: "Creative Expressions and Cultural Dialogues",
    description: "Immerse yourself in the creative world of the Arts and Humanities at the University of Whalley.",
    content: `Join us for an inspiring day where art meets history and culture meets creativity at the University of Whalley’s Arts and Humanities Day. This event is an open invitation for prospective students to explore our diverse range of programs and to engage with the rich tapestry of human experience as expressed through art, literature, history, and philosophy.\n\nParticipate in interactive workshops, attend guest lectures by renowned artists and scholars, and enjoy performances by our talented students. Our Arts and Humanities Day provides a platform to discuss big ideas, explore creative careers, and connect with faculty and students who share your passion for the arts and culture.`,
    date: "2024-04-22",
    image: globe,
    customFAQ: [
        {
        title: "What can I expect to see at the Arts and Humanities Day?",
        content: "Expect a vibrant display of student artwork, literary readings, historical exhibits, and philosophical debates. There will also be information sessions about our academic programs and career opportunities in the arts and humanities fields.",
        },
        {
        title: "Do I need to have a background in arts or humanities to attend?",
        content: "Not at all. The event is open to all who are interested in the creative arts and social sciences, regardless of your current field of study or expertise.",
        }
    ],
},
}

// Not Used...
export const eventsData = {
    "virtual-first-look": {
        title: "Virtual First Look A",
        title2: "In Country and Online Events",
        description: "Take a virtual first look at the University of Whalley. Hear from our enthusiastic students and expert staff on all aspects of student life.",
        location: "12 main st",
        image: virtualEvent,
        content: `
Embark on a digital voyage and uncover the vibrant academic community of the University of Whalley through our exclusive Virtual First Look event. This online experience offers you a unique opportunity to delve into the dynamic and diverse world of student life at one of the UK's leading research institutions, all from the comfort of your home.

As you navigate through this immersive journey, you will encounter the voices and stories of our passionate students, who are eager to share the ins and outs of their university experience. From the rich array of societies and clubs that cater to every interest imaginable to the supportive environment that fosters personal growth and academic excellence, you'll discover what makes the University of Whalley a cherished home away from home for students from all corners of the globe.

Our expert staff, renowned for their dedication to student success, will also be at hand. They will illuminate the path to academic enquiry, highlighting our commitment to cutting-edge research and teaching methodologies. You'll gain insights into how our educational programs are designed to equip you with the skills and knowledge required to excel in an ever-evolving world.

But it's not just about academics. You'll get a glimpse of the array of support services available to ensure your well-being and success, including mental health resources, career counseling, and international student support. Our Virtual First Look event is also the perfect occasion to explore our state-of-the-art facilities through interactive 360-degree tours. Wander through our libraries, laboratories, sports complexes, and student hubs to feel the pulse of Whalley's student life.

The event will feature live Q&A sessions, allowing you to probe deeper into areas of interest, including admissions, scholarship opportunities, and life in Whalley. Whether you're contemplating undergraduate study, a postgraduate program, or research opportunities, this event will serve as a beacon, guiding you toward making an informed decision about your future.

We can't wait to welcome you to our virtual shores and show you why the University of Whalley is not just a place to study, but a place to ignite your potential and shape the world. Join us for this enlightening experience and take the first step toward crafting your own Whalley story.
        `,

        customFAQ: [
            {
                title: `Is there parking available?`,
                content: `Parking is available in the many carparks around the University Centre. If you are visiting us for an open day, the carparks are free after 6pm during the weekdays and all day at the weekends. The maximum cost during a weekday is £3.50 for the full day.\n\n`,
            },
        ],
    },
    "first-look": {
        title: "First Look Virtual Event",
        content: (
            <>
                <div>First Look</div>
                <p>content</p>
            </>
        ),
        date: "12-12-2023",
        image: whalleyVirtualTourSphere,
        customFAQ: {},
        description: "Take a virtual first look at the University of Whalley. Hear from our enthusiastic students and expert staff on all aspects of student life."
    },
    "online-events": {
        title: "In Country and Online Events",
        description: "Take a virtual first look at the University of Whalley. Hear from our enthusiastic students and expert staff on all aspects of student life.",
        content: (
            <>
                <div>First Look</div>
                <p>content</p>
            </>
        ),
        date: "12-12-2023",
        image: whalleyVirtualTourSphere,
        customFAQ: {},
    },
    "welcome-day": {
        title: "First Look",
        content: (
            <>
                <div>First Look</div>
                <p>content line</p>
            </>
        ),
        date: "12-12-2023",
        image: welcomeDayImage,
        customFAQ: {},
    },
    "open-day": {
        title: "Open Day",
        title2: "What to expect on open day",
        description: "From facilities tours to student life talks, find out more about typical activities at our campus open days.",
        link: "event/open-day",
        content: (
            <>
                <div>First Look</div>
                <p>content</p>
            </>
        ),
        date: "12-12-2023",
        image: welcomeDayImage,
        customFAQ: {},
    },
}


