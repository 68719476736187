import React from 'react'
import FAQLine from './FAQLine'
import FAQArea from './FAQArea'

function BusinessFAQ() {

    const faqData = [
        {
            "title": "What types of business degrees are there?",
            "content": "There are many types of business degrees and specializations including the following:\n\n    + Business management degree\n    + IT management degree\n    + Healthcare management degree\n    + Human resources management degree\n    + Marketing degree\n    + Finance degree\n    + Accounting degree\n                    "
        },
        {
            "title": "Is a bachelor's in business a BA or BS?",
            "content": "Most bachelor's degrees in business are B.S. or bachelor of science degrees. B.S. degrees require you to learn highly applicable technical skills in areas like economics, entrepreneurship, and finance.\n                    "
        },
        {
            "title": "What kinds of jobs can you get with a degree in business?",
            "content": "There are many different degrees you can get in business. While jobs may vary, a degree in business might prepare you for jobs like the following:\n\n    + Marketer\n    + Loan officer\n    + Financial advisor\n    + Human resources manager\n    + Entrepreneur\n    + Sales manager\n    + Accountant\n                    "
        },
        {
            "title": "Is a business degree worth it?",
            "content": "Yes! A degree in business can help you advance in your career, improve your prospects for a new job, and likely increase your earning potential. For your career, a bachelor's degree in business is a great investment.\n                    "
        },
        {
            "title": "What can you do with a degree in business administration?",
            "content": "A degree in business administration can lead to a variety of career paths, depending on the concentration you choose. A business degree is useful in fields including business management, human resources, marketing, IT management, and healthcare professions. Whether you want to manage staff or start your own business, getting a degree in business is a great decision to make.\n                    "
        }
    ]

  return (
    <>
        <FAQArea
            title={"Commonly Asked Questions About Business Degrees"} 
            faqData={faqData}
        ></FAQArea>
    </>
  )
}

export default BusinessFAQ